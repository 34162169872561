import React from 'react';
import { Menu } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LockFilled } from "@ant-design/icons";
import { useDispatch } from 'react-redux';
const { SubMenu } = Menu;

const SidebarMenu = React.memo(({ routes, onMenuItemClick, onSubmenuItemClick })=>{
  // const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate();
  
    const renderMenuItemWithIcon = (icon, label, isLocked = false) => (
      <div style={{ height: "100%", width: '100%', display: 'flex', alignItems: 'center', padding: '8px 16px' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '24px' }}>
          {icon}
        </div>
        <div style={{ paddingLeft: '10px' }}>
          {label}
        </div>
        <div style={{ display: 'flex', paddingLeft: "20px", alignItems: 'center' }}>
          {isLocked && <LockFilled />}
        </div>
      </div>
    );
  
    const renderMenuItems = (menuItems) =>
      menuItems.map((item) =>
        item.children ? (
          <SubMenu
            key={item.key}
            title={renderMenuItemWithIcon(item.icon, item.label, item.isLocked)}
            style={{ backgroundColor: "#231F20", padding: '0' ,margin: '0' }}
            onTitleClick={() => navigate(item.path)} // Navigate when the title of the SubMenu is clicked
            >
            {renderMenuItems(item.children)}
          </SubMenu>
        ) : (
          <Menu.Item
            key={item.path}
            // className='mb-2'
            style={{
              backgroundColor: location.pathname === item.path ? "#F4F3EF" : "#231F20",
              color: location.pathname === item.path ? "#EF6B3E" : "#fff",
              margin: "0",
      
              padding: "0",
              width: "99%",
              marginLeft: "10px",
              marginBottom: "10px"
            }}

            onClick={onMenuItemClick}
          >
            <Link to={item.path} style={{ color: 'inherit', textDecoration: 'none' }}>
              {renderMenuItemWithIcon(item.icon, item.label, item.isLocked)}
            </Link>
          </Menu.Item>
        )
      );
  
    return (
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[location.pathname]}
        style={{
          backgroundColor: "#231F20",
          color: '#fff',
          borderTopRightRadius: "16px",
          fontSize: "16px",
          paddingLeft: '0',
          width: "100%",
          marginBottom: '100px'
          
        }}
        className="custom-sidebar-menu"
      >
        {renderMenuItems(routes)}
      </Menu>
    );
  
})

export default SidebarMenu;
