import React from "react";
import PropertyDetailsView from "Components/PropertyDetailsView/PropertyDetailsView";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
const PropertyDetails = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Property Details"}
        pop={true}
      />
      <PropertyDetailsView />
    </div>
  );
};

export default PropertyDetails;
