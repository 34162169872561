import { useEffect, useState } from "react";
import { Avatar, message } from "antd";
import settingIcon from "assets/three-dots.png";
import trashIconWhite from "assets/trash-icon-white.png";
import { Link, useNavigate } from "react-router-dom";
import pdfIcon from "assets/pdf.jpg";
import excelIcon from "assets/excel.jpg";
import wordIcon from "assets/word.jpg";
import { CustomTable, DeleteModal, FilterRow, RecordMenuPopup } from "Components/GeneralComponents";

const AllFiles = () => {
  const [selectionType, setSelectionType] = useState("checkbox");
  const [selectedTableItem, setSelectedTableItem] = useState([]);
  const [key, setKey] = useState(null);
  const [deleteId, setDeleteId] = useState("");
  const [fileData, setFileData] = useState([]);
  const [filtredFiles, setFiltredFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isOpenBulkDeleteModal, setIsOpenBulkDeleteModal] = useState(false);
  const [filter, setFilter] = useState({ search: "" });
  const navigate = useNavigate();

  useEffect(() => {
    const dummyData = Array.from({ length: 15 }, (_, index) => ({
      key: index + 1,
      id: `file-${index + 1}`,
      fileName: [
        index % 3 === 0 ? "pdf" : index % 3 === 1 ? "xlsx" : "docx",
        `File_${index + 1}.${index % 3 === 0 ? "pdf" : index % 3 === 1 ? "xlsx" : "docx"}`,
        `Document ${index + 1}`,
      ],
      name: `File ${index + 1}`,
      property: {
        Name: `Property ${index + 1}`,
        Address: `${index + 1} Example Street, City, State, 12345`,
      },
      owner: "John Doe",
      date: new Date().toLocaleDateString(),
    }));

    setFileData(dummyData);
    setFiltredFiles(dummyData);
  }, []);

  const handleResetFilter = () => {
    setFiltredFiles(fileData);
  };

  useEffect(() => {
    if (filter.search) {
      const filteredData = fileData.filter((data) => {
        return (
          data.name.toLowerCase().includes(filter.search.toLowerCase()) ||
          data.fileName[1].toLowerCase().includes(filter.search.toLowerCase()) ||
          data.property.Name.toLowerCase().includes(filter.search.toLowerCase()) ||
          data.property.Address.toLowerCase().includes(filter.search.toLowerCase()) ||
          data.owner.toLowerCase().includes(filter.search.toLowerCase()) ||
          data.date.toLowerCase().includes(filter.search.toLowerCase())
        );
      });
      setFiltredFiles(filteredData);
    } else {
      setFiltredFiles(fileData);
    }
  }, [filter.search, fileData]);

  const handleIconClick = (result) => {
    setKey(key === result ? null : result);
  };

  const handleDeleteFile = () => {
    setFileData((prevData) => prevData.filter((file) => file.id !== deleteId));
    setFiltredFiles((prevData) => prevData.filter((file) => file.id !== deleteId));
    message.success("File deleted successfully.");
    setOpenDeleteModal(false);
  };

  const handleBulkDeleteFile = () => {
    setFileData((prevData) => prevData.filter((file) => !selectedTableItem.includes(file.id)));
    setFiltredFiles((prevData) => prevData.filter((file) => !selectedTableItem.includes(file.id)));
    message.success("Selected files deleted successfully.");
    setIsOpenBulkDeleteModal(false);
    setSelectedTableItem([]);
  };

  const columns = [
    {
      title: "FILE NAME",
      dataIndex: "fileName",
      render: (text) => (
        <div className="table-file-container d-flex align-items-center gap-3">
          <div className="table-file-img">
            <img
              className="property-table-image mw_40 mh_40 me-2 rounded-5"
              src={
                text[0] === "pdf" ? pdfIcon : text[0] === "xlsx" ? excelIcon : wordIcon
              }
              alt=""
            />
          </div>
          <div className="table-file-text">
            <p className="m-0 all-files-table-name-text">{text[1]}</p>
            <p className="m-0 all-files-table-property-text">720 KB</p>
          </div>
        </div>
      ),
    },
    {
      title: "PROPERTIES",
      dataIndex: "property",
      render: (property) => (
        <div>
          <span className="tenant_table_properties_main_text">{property.Name}</span>
          <br />
          <span className="tenant_table_properties_sub_text">{property.Address}</span>
        </div>
      ),
    },
    {
      title: "OWNER",
      dataIndex: "owner",
      render: (text) => (
        <>
          <Avatar style={{ backgroundColor: "#EF6B3E", verticalAlign: "middle" }} size="large">
            {text[0]}
          </Avatar>
          <span className="tenant_table_name_text ms-3">{text}</span>
        </>
      ),
    },
    {
      title: "DATE UPLOADED",
      dataIndex: "date",
      render: (text) => <span className="tenant_table_properties_sub_text">{text}</span>,
    },
    {
      title: "",
      dataIndex: "setting",
      render: (_, setting) => (
        <div className="position-relative cursor text-center" onClick={() => handleIconClick(setting.key)}>
          <img src={settingIcon} alt="" />
          <RecordMenuPopup
            isOpen={setting.key === key}
            onClose={() => setKey(null)}
            fields={{ download: true, delete: true }}
            handler={{
              handleDeleteRecord: () => {
                setDeleteId(setting.id);
                setOpenDeleteModal(true);
              },
            }}
          />
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTableItem(selectedRows.map((item) => item.id));
    },
  };

  return (
    <>
      <DeleteModal
        isOpen={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        message={"Are you sure you want to delete this file?"}
        deleteCall={handleDeleteFile}
      />
      <DeleteModal
        isOpen={isOpenBulkDeleteModal}
        onClose={() => setIsOpenBulkDeleteModal(false)}
        message={"Are you sure you want to delete these files?"}
        deleteCall={handleBulkDeleteFile}
      />
      <div className="container-fluid bg-white p-3 ">
        <FilterRow
          filter={filter}
          setFilter={setFilter}
          btnTitle={"Add New File"}
          btnHandler={() => navigate("/new-files")}
          handleSubmit={() => setFiltredFiles(fileData)}
          handleReset={handleResetFilter}
          filterList={{ date: true, range: true, property: true }}
        />
        <div className="row mt-3">
          <div className="col-md-12">
            {selectedTableItem.length >= 1 && (
              <div className="table-delete-icon mt-3">
                <button
                  onClick={() => setIsOpenBulkDeleteModal(true)}
                  className="table-delete-btn next-btn-main"
                >
                  <img src={trashIconWhite} alt="" />
                  Delete
                </button>
              </div>
            )}
            <div className="table-container">
              <CustomTable
                pagination={true}
                rowSelection={{ type: selectionType, ...rowSelection }}
                columns={columns}
                dataSource={filtredFiles}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllFiles;
