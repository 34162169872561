import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import { selectMultiColumns } from "utils/dropdownData";
import { getUnit, updateUnit } from "network/landlord/property";
import {
  AmountField,
  Checkbox,
  InputField,
  MediaField,
  SelectMultipleColumns,
  SubmitButton,
  SuccessModal,
  TextAreaField,
} from "Components/GeneralComponents";

const PropertiesUnitsEdit = () => {
  const navigate = useNavigate();
  const { id, property_id } = UseUrlParamsHook();
  const [openModal, setOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [Images, setImages] = useState([]);
  // const [isUnitActive, setIsUnitActive] = useState(false);
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [form, setForm] = useState({
    property_title: "",
    unit_name: "",
    rent: "",
    bedroom: "",
    bathroom: "",
    area: "",
    amenities: [],
    description: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (fieldName, value) => {
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  const onOpenModal = () => {
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    // Get Property By Id
    getUnit(id).then((res) => {
      if (res.apiCallStatus === "success") {
        setForm({
          property_title: res.message.property_title,
          unit_name: res.message.name,
          rent: res.message.rent_amount,
          bedroom: res.message.bedroom,
          bathroom: res.message.bathroom,
          area: res.message.area,
          description: res.message.description,
          amenities: res.message.amenities,
        });
        setImages(res.message.images);
        setSelectedAmenities(res.message.amenities);
      }
    });
  }, []);

  const editUnit = () => {
    const formData = new FormData();
    formData.append("area", form.area);
    formData.append("bedroom", form.bedroom);
    formData.append("bathroom", form.bathroom);
    formData.append("rent_amount", form.rent);
    formData.append("description", form.description);
    Images.forEach((img) => {
      formData.append("images", img);
    });
    for (let i = 0; i < form.amenities.length; i++) {
      formData.append(`amenities[${[i]}]`, form.amenities[i]);
    }

    setLoader(true);
    updateUnit(id, formData).then((res) => {
      setLoader(false);
      if (res.apiCallStatus === "success") {
        onOpenModal();
      } else {
        console.log(res);
      }
    });
  };

  return (
    <>
      {openModal === true ? (
        <SuccessModal
          heading={"Unit Updated Successfully"}
          title={"Unit has been updated successfully check your units."}
          route={`property-details-view?id=${property_id}`}
          success={"Go to Property Details"}
          onClose={onCloseModal}
        />
      ) : (
        ""
      )}

      <div className="container-fluid my-5">
        <div className="container">
          <div className="row mt-3">
            <div className="col-md-12">
              <InputField
                name={"property_title"}
                required={true}
                label={"Property Title"}
                placeholder={"Property Title"}
                value={form.property_title}
                handler={(e) => handleChange("property_title", e.target.value)}
                errorMessage={errors.property_title}
                disabled={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mt-3">
              <InputField
                name={"unit_name"}
                required={true}
                label={"Unit Name"}
                placeholder={"Unit Name"}
                value={form.unit_name}
                handler={(e) => handleChange("unit_name", e.target.value)}
                errorMessage={errors.unit_name}
              />
            </div>
            <div className="col-md-6 mt-3">
              <AmountField
                name={"rent"}
                required={true}
                label={"Rent Amount"}
                placeholder={"Enter Rent Amount"}
                value={{ amount: form.rent }}
                handler={(value) => handleChange("rent", value.amount)}
                errorMessage={errors.rent}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mt-3">
              <InputField
                name={"bedroom"}
                required={true}
                type="number"
                label={"Bedrooms"}
                placeholder={"Bedrooms"}
                value={form.bedroom}
                handler={(e) => handleChange("bedroom", e.target.value)}
                errorMessage={errors.bedroom}
              />
            </div>
            <div className="col-md-4 mt-3">
              <InputField
                name={"bathroom"}
                required={true}
                type="number"
                label={"Bathrooms"}
                placeholder={"Bathrooms"}
                value={form.bathroom}
                handler={(e) => handleChange("bathroom", e.target.value)}
                errorMessage={errors.bathroom}
              />
            </div>
            <div className="col-md-4 mt-3">
              <InputField
                name={"area"}
                required={true}
                type="number"
                label={"SqFt"}
                placeholder={"SqFt"}
                value={form.area}
                handler={(e) => handleChange("area", e.target.value)}
                errorMessage={errors.area}
              />
            </div>
          </div>
          <SelectMultipleColumns
            label={"Select Amenities"}
            placeholder={"Select Amenities"}
            values={form.amenities}
            selectedAmenities={selectedAmenities}
            options={selectMultiColumns}
            handleChange={(e) => handleChange("amenities", e)}
          />
          <div className="row mt-3">
            <div className="col-md-12">
              <TextAreaField
                name={"description"}
                label={"Description"}
                placeholder={"Add your unit description here"}
                value={form.description}
                handler={(e) => handleChange("description", e.target.value)}
                rows={"10"}
                cols={"30"}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <MediaField
                label={"Upload Images"}
                Images={Images}
                setImages={setImages}
              />
            </div>
          </div>
          {/* <div className="row mt-3">
            <div className="col-md-12">
              <Checkbox
                label={"This unit is active"}
                onChange={(e) => setIsUnitActive(e.target.checked)}
              />
            </div>
          </div> */}
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="notes-btn d-flex align-items-center justify-content-center gap-3">
                <button
                  className="cancel-prev-btn"
                  onClick={() => {
                    navigate(`/property-details-view?id=${property_id}`);
                  }}
                >
                  Cancel
                </button>
                <SubmitButton handler={editUnit} isLoading={loader}>
                  Save
                </SubmitButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertiesUnitsEdit;
