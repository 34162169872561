import React, { useState, useEffect } from "react";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import * as Innercomponent from "./index";
import { getTaskById } from "network/tenant/task";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomTabs } from "Components/GeneralComponents";

const TenantTaskDetails = () => {
  const { id, tab } = UseUrlParamsHook();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [task, setTask] = useState("");
  const [activeTab, setActiveTab] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const items = [
    { key: "1", label: "Overview", children: <Innercomponent.OverviewTab task={task} isLoading={isLoading} /> },
    { key: "2", label: "Notes", children: <Innercomponent.NotesTab /> },
    { key: "3", label: "Files", children: <Innercomponent.FilesTab /> },
  ];

  useEffect(() => {
    setActiveTab(tab || "1");
  }, [tab]);

  const onChange = (key) => {
    setActiveTab(key);
    searchParams.set("tab", key);
    navigate({ search: searchParams.toString() });
  }

  useEffect(() => {
    setIsLoading(true);
    getTaskById(id).then((res) => {
      if (res.apiCallStatus === "success") {
        setTask(res.message.data);
        setIsLoading(false);
      }
    });
  }, [id]);

  return (
    <>
      <div className="container-fluid bg-white p-3 ">
      {activeTab && <CustomTabs
        items={items}
        defaultActiveKey={activeTab}
        onChange={onChange}
      />}
      </div>
    </>
  );
};

export default TenantTaskDetails;
