import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import TenantTaskDetails from "Components/TenantTaskDetails/TenantTaskDetails";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const TasksDetail = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Tasks Details"}
        back="all-task"
      />
      <TenantTaskDetails />
    </div>
  );
};

export default TasksDetail;
