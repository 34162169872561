import React, { useState } from "react";
import * as Icon from "assets/icons";

const AmountField = ({
  name,
  required = false,
  label = "",
  placeholder,
  value,
  handler,
  errorMessage = false,
  disabled,
  pattern,
  amountTypes = ["$"],
}) => {
  const [amountType, setAmountType] = useState(
    value?.type || (amountTypes.length > 1 ? amountTypes[0] : null)
  );

  const handleAmountType = (type) => {
    setAmountType(type);
    handler({ amount: value.amount, type });
  };

  const handleChange = (e) => {
    handler({ amount: e.target.value, type: amountType });
  };

  return (
    <div className="position-relative">
      {label && (
        <span className="tenant-personal-details-title">
          {label}{" "}
          <span className="sign-up-imp-star">{`${required ? "*" : ""}`}</span>
        </span>
      )}
      <div className="position-relative">
        <input
          name={name}
          type="number"
          value={value.amount}
          onChange={handleChange}
          className={`${errorMessage ? "error-input" : "form-control"}`}
          placeholder={placeholder}
          disabled={disabled}
          pattern={pattern}
          style={{ paddingLeft: "70px", height: "46px" }}
        />
        <div className="dollar-sign-box">
          {amountTypes.map((type, index) => {
            return (
              <React.Fragment key={index}>
                {index !== 0 && (
                  <span>
                    <Icon.LineIcon />
                  </span>
                )}
                <span
                  onClick={() => (disabled ? {} : handleAmountType(type))}
                  className={`${
                    amountType === type
                      ? "active-amount-type"
                      : "inactive-amount-type"
                  }`}
                  style={{ cursor: disabled ? "" : "pointer" }}
                >
                  {type}
                </span>
              </React.Fragment>
            );
          })}
        </div>
      </div>
      {errorMessage && (
        <div className="text-danger fw-bold mt-2" style={{ fontSize: "12px" }}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default AmountField;
