import React, { useState } from "react";
import * as Component from "Components/GeneralComponents";

const SettingsDeleteAccount = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handledelete = () => {
    isChecked ? setIsLoading(true) : setIsLoading(false);
  };

  return (
    <>
      <div style={{ height: "calc(100vh - 350px)" }}>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <div className="heading pt-4 pb-5 ps-2">DELETE YOUR ACCOUNT</div>
        </div>
        <div className="d-flex flex-column h-100">
          <div className="mt-3 ps-2 h-100">
            <p className="fs-5" style={{ color: "#6f747d" }}>
              When you delete your account, you will lose access to the portal
              and your personal data will be permanently deleted. You have 14
              days to cancel the deletion if you change your mind.
            </p>
            <div className="d-flex">
              <Component.Checkbox
                onChange={() => setIsChecked(!isChecked)} // Change this
              />
              <span className="fw-semibold" style={{ color: "#6f747d" }}>
                Yes, i want to delete my account
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex gap-4" style={{ paddingRight: "20px" }}>
          <div className="w-100">
            <Component.SubmitButton type="contained" handler={""}>
              Learn More
            </Component.SubmitButton>
          </div>
          <div className="w-100">
            <Component.SubmitButton
              handler={handledelete}
              isLoading={isLoading}
            >
              Delete Account
            </Component.SubmitButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsDeleteAccount;
