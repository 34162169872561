import React, { useEffect, useState } from "react";

const Countdown = ({ targetDate, from, to }) => {
  const [timeLeft, setTimeLeft] = useState({});
  const calculateTimeLeft = () => {
    if (!targetDate) return {};

    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);
  return (
    <>
      <div className="countdown_container text-start mb-4">
        {targetDate && (
          <>
            <p className="primary-text fw-bold font-italic">
              <span>
                <svg
                  width={25}
                  height={25}
                  fill="none"
                  stroke="#ef6b3e"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
                  <path d="M12 16v-4" />
                  <path d="M12 8h.01" />
                </svg>
              </span>{" "}
              Your subscription will be downgraded from{" "}
              <span className="text-dark">{from} Servive</span> to{" "}
              <span className="text-dark">{to} Service</span> in{" "}
              <span className="">{timeLeft.days}</span> days,{" "}
              <span className="">{timeLeft.hours}</span> hours,{" "}
              <span className="">{timeLeft.minutes}</span> minutes, and{" "}
              <span className="">{timeLeft.seconds}</span> seconds
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default Countdown;
