import React, { useState, useEffect } from "react";
import validateError from "assets/alert-circle-validate.png";

const formatPhoneNumber = (input) => {
  let formattedNumber = "";

  if (input.length > 0) {
    formattedNumber = `(${input.slice(0, 3)}`;

    if (input.length > 3) {
      formattedNumber += `) ${input.slice(3, 6)}`;
    }

    if (input.length > 6) {
      formattedNumber += `-${input.slice(6, 10)}`;
    }
  }

  return formattedNumber;
};

const PhoneNumberField = ({
  name,
  required = false,
  label = "",
  placeholder,
  value,
  handler,
  isError = false,
  errorMessage = false,
  disabled,
}) => {
  const [formattedNumber, setFormattedNumber] = useState("");

  const handlePhoneNumberChange = (event) => {
    const input = event.target.value.replace(/\D/g, "");
    const formatted = formatPhoneNumber(input);
    setFormattedNumber(formatted);
    const unformatted = input.slice(0, 10);
    handler(unformatted);
  }

  useEffect(() => {
    if (value) {
      const formatted = formatPhoneNumber(value);
      setFormattedNumber(formatted);
    }
  }, [value]);

  return (
    <div className="position-relative">
      {label && (
        <span className="tenant-personal-details-title">
          {label}{" "}
          <span className="sign-up-imp-star">{`${required ? "*" : ""}`}</span>
        </span>
      )}
      <div style={{ position: "relative" }}>
        <input
          name={name}
          type={"tel"}
          value={formattedNumber}
          onChange={handlePhoneNumberChange}
          className={isError || errorMessage ? "error-input" : "form-control"}
          placeholder={placeholder}
          disabled={disabled}
        />
        {isError && (
          <div className="validate-icon1">
            <img src={validateError} alt="" />
          </div>
        )}
      </div>
      {errorMessage && (
        <div className="text-danger fw-bold mt-2" style={{ fontSize: "12px" }}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default PhoneNumberField;
