import React from "react";
import {
  ButtonVariant1,
  InputField,
  PasswordField,
  PhoneNumberField,
} from "Components/GeneralComponents";
import { useState } from "react";
import { Link } from "react-router-dom";
import Or from "assets/login-or-image.png";
import { LoginSocialFacebook } from "reactjs-social-login";
import facebookIcon from "assets/fb login.png";
import googleIcon from "assets/google login.png";
import { PasswordValidate } from "Components/TenantSetPassword";
import { checkPasswordCriteria } from "utils";
import * as Icons from "assets/icons";
import { googleAuthUrl } from "network/tenant/auth";

const Signup = ({
  form,
  errors,
  handleChange,
  tenantSignUp,
  // googleAuth,
  setAgree,
  resolveFacebook,
  rejectFacebook,
}) => {
  const [agreeTermsChecked, setAgreeTermsChecked] = useState(false);

  setAgree(agreeTermsChecked);

  const googleAuth = () => {
    googleAuthUrl();
  };

  return (
    <div className="land-lord-sign-up">
      <h1 className="mt-5">Register as a Tenant</h1>
      {/* <p>Create your account if not registered yet</p> */}
      <div className="col-md-12 mt-3 not-a-member">
        Not a member yet?{" "}
        <Link className="primary-orange-text" to="/tenant/login">
          Login Now!
        </Link>
      </div>
      <Link className="primary-orange-text" to="/onboarding">
        <Icons.BackArrow />
        Back
      </Link>
      <div className="tenant-sign-up">
        <div className="row">
          <div className="col-md-6 mt-3">
            <InputField
              name={"first name"}
              type={"text"}
              placeholder={"First Name"}
              value={form.fname}
              isLabel={true}
              label="First Name"
              handler={(e) => handleChange("fname", e.target.value)}
            />
          </div>
          <div className="col-md-6 mt-3">
            <InputField
              name={"last name"}
              type={"text"}
              placeholder={"Last Name"}
              value={form.lname}
              isLabel={true}
              label="Last Name"
              handler={(e) => handleChange("lname", e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <InputField
              name={"email"}
              type={"email"}
              placeholder={"Email"}
              value={form.email}
              isLabel={true}
              label="Email"
              handler={(e) => handleChange("email", e.target.value)}
              required={true}
              errorMessage={errors.email}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mt-3">
            <PasswordField
              handler={(e) => handleChange("password", e.target.value)}
              name={"password"}
              type={"password"}
              placeholder={"Password"}
              value={form.password}
              isLabel={true}
              label="Password"
              required={true}
              errorMessage={errors.password}
            />
          </div>
          <div className="col-md-6 mt-3">
            <PasswordField
              handler={(e) => handleChange("cpassword", e.target.value)}
              name={"cpassword"}
              type={"password"}
              placeholder={"Confirm Password"}
              value={form.cpassword}
              isLabel={true}
              label="Confirm Password"
              required={true}
              errorMessage={errors.cpassword}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <PhoneNumberField
              name={"Phone No"}
              required={true}
              label={"Phone No"}
              placeholder={"Phone Number"}
              value={form.phone}
              handler={(value) => handleChange("phone", value)}
              errorMessage={errors.phone}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12 mt-3">
            <PasswordValidate validate={checkPasswordCriteria(form.password)} />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="sign-up-terms-checkbox d-flex align-items-center">
              <input
                onChange={(e) => {
                  setAgreeTermsChecked(e.target.checked);
                }}
                type="checkbox"
                name=""
                id=""
                checked={agreeTermsChecked}
                className={agreeTermsChecked ? "checked" : ""}
              />{" "}
              <span>
                I agree to all the{" "}
                <span className="primary-orange-text">Terms</span> and{" "}
                <span className="primary-orange-text">Privacy policy </span>
              </span>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="sign-up-buttons d-flex justify-content-center">
              <ButtonVariant1
                className="create-accoutn-btn w-100"
                handler={tenantSignUp}
              >
                Create Account
              </ButtonVariant1>
            </div>
          </div>
        </div>
        <div className="remember-me-checkBox d-flex justify-content-center mt-4">
          <p className="text-end">
            <img src={Or} alt="" style={{ width: "100%" }} />
          </p>
        </div>
        <div className="row mb-5 ">
          <div className="col-md-12">
            <div className="sign-up-buttons d-flex gap-4 justify-content-center">
              <button onClick={googleAuth} className="google-signup-btn">
                <img src={googleIcon} alt="" style={{ width: "140px" }} />
              </button>
              <LoginSocialFacebook
                appId="1129610314899200"
                autoLoad={false}
                onResolve={(response) => resolveFacebook(response)}
                onReject={(error) => rejectFacebook(error)}
              >
                <button className="facebook-signup-btn">
                  <img src={facebookIcon} alt="" style={{ width: "140px" }} />
                </button>
              </LoginSocialFacebook>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
