import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Component from "Components/GeneralComponents";
import * as Icon from "assets/icons";
import person from "assets/person.jpg";
import PaymentSuccessModal from "Modals/PaymentSuccessModal/PaymentSuccessModal";
import ServiceProfessionalPostUpdateModel from "Modals/ServiceProfessionalPostUpdateModal/ServiceProfessionalPostUpdateModel";
import ServiceProfessionalWorkUpdateModel from "Modals/ServiceProfessionalWorkUpdateModel/ServiceProfessionalWorkUpdateModel";

function ServiceProfessionalTasks() {
  const [taskId, setTaskId] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isDeleteTasksModal, setIsDeleteTasksModal] = useState(false);
  const [isDeleteTaskRender, setIsDeleteTaskRender] = useState(false);
  const [isDeleteTasksRender, setIsDeleteTasksRender] = useState(false);
  const [openModalPostUpdate, setOpenModalPostUpdate] = useState(false);
  const [openModalWorkOrderUpdate, setOpenModalWorkOrderUpdate] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalTask, setOpenModalTask] = useState(false);
  const [isRenderer, setIsRenderer] = useState(false);
  const [selectedTableItem, setSelectedTableItem] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteTasks, setDeleteTasks] = useState([]);
  const [key, setKey] = useState([]);
  const [taskData, setTaskData] = useState([
    {
      key: "66bb82b9d9068092ab44da22",
      title: "Electrical Wiring Inspection and Repairs",
      image:
        "https://digital-tenant-bucket.s3.amazonaws.com/uGplKklK7-electric wiring 2.jpg",
      assignees: [
        {
          _id: "66bb7dc0d9068092ab44d786",
          name: "Ali Ahmed",
          profileImage: "",
          type: "vendor",
        },
      ],
      due: {
        dueDate: "31/08/2024",
        priority: "Medium",
      },
      related: "456 Maple Avenue, Unit 1, Denver, Colorado, 80202",
      status: "Completed",
      propertyId: "66bb76ced9068092ab44cfd9",
      unitId: "66bb76cdd9068092ab44cfd3",
      updates: [
        {
          createdAt: "2024-10-21T22:01:42.332Z",
        },
        {
          inputDetails: "Muhammad Shafeeq created a new task.",
          createdAt: "2024-08-13T15:58:49.393Z",
        },
        {
          inputDetails: "Muhammad Tahir updated the task.",
          createdAt: "2024-08-28T14:51:30.530Z",
        },
      ],
    },
    {
      key: "77cc92b9d9068092ab44db34",
      title: "HVAC System Maintenance",
      image: "",
      assignees: [
        {
          _id: "77cc8ec0d9068092ab44d899",
          name: "John Smith",
          profileImage: {person},
          type: "technician",
        },
      ],
      due: {
        dueDate: "15/09/2024",
        priority: "High",
      },
      related: "789 Pine Street, Apt 4B, Boston, MA, 02118",
      status: "In Progress",
      propertyId: "77cc76ced9068092ab44dfe1",
      unitId: "77cc76cdd9068092ab44dfd4",
      updates: [
        {
          createdAt: "2024-10-10T10:20:30.123Z",
        },
        {
          inputDetails: "Sarah Connor updated the HVAC schedule.",
          createdAt: "2024-09-01T12:45:00.000Z",
        },
      ],
    },
    {
      key: "88dd12c9d9068092ab44ec56",
      title: "Plumbing Leak Repair",
      image: "",
      assignees: [
        {
          _id: "88dd3dc0d9068092ab44eabc",
          name: "Jessica Turner",
          profileImage: {person},
          type: "contractor",
        },
        {
          _id: "88dd4bc0d9068092ab44eadf",
          name: "Mike Ross",
          profileImage: "",
          type: "assistant",
        },
      ],
      due: {
        dueDate: "05/10/2024",
        priority: "Low",
      },
      related: "123 Elm Street, Suite 100, Seattle, WA, 98101",
      status: "Not Started",
      propertyId: "88dd76ced9068092ab44efd2",
      unitId: "88dd76cdd9068092ab44efb5",
      updates: [],
    },
  ]);
  const [selectedTaskData, setSelectedTaskData] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    range: "",
    service: "",
  });

  const navigate = useNavigate();

  // antd table columns
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      render: (text, task) => (
        <Link
          to={`/task-details?id=${task.key}`}
          // className="all-task-table-title-text"
          className="d-flex align-items-center gap-2">
          <div
            className="d-flex align-items-center justify-content-center flex-shrink-0"
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              overflow: "hidden",
              border: `0.5px solid ${task.image ? "#c9c9c9" : "#ef6b3e"}`,
              position: "relative",
              backgroundColor: "white",
            }}>
            {task.image ? (
              <img
                src={task.image}
                alt="task"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            ) : (
              <Icon.TaskIcon
                width={20}
                height={20}
              />
            )}
          </div>
          {text}
        </Link>
      ),
    },
    {
      title: "Assigned To",
      dataIndex: "assignees",
      render: (text, assignees) => (
        <>
          {" "}
          <Component.IconList
            properties={assignees.assignees}
            defaultimage={person}
            name={true}
          />
        </>
      ),
    },
    {
      title: "Due At",
      dataIndex: "due",
      render: (text) => (
        <>
          {text.priority ? (
            <div className="assign-date-container">
              <div
                className={
                  text.priority === "High"
                    ? "priority-text bg-error"
                    : text.priority === "Low"
                    ? "priority-text bg-grey"
                    : text.priority === "Medium"
                    ? "priority-text bg-yellow"
                    : text.priority === "Very High"
                    ? "priority-text bg-error"
                    : ""
                }>
                <span>{`${text.priority} Priority`}</span>
              </div>
              <br />
              <span className="assign-to-date">{text.dueDate}</span>
            </div>
          ) : (
            <span>Not Set</span>
          )}
        </>
      ),
    },
    {
      title: "Related To",
      dataIndex: "related",
      render: (text) => <div className="all-lease-property-text">{text}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <>
          <span
            className={
              status === "Completed"
                ? "completed-status text-white"
                : status === "In Progress"
                ? "in-progress-status text-white"
                : status === "Not Started"
                ? "not-started-status text-dark"
                : ""
            }>
            {status}
          </span>{" "}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}>
            <Icon.VerticalElipsisIcon
              width={23}
              height={23}
            />
            <Component.RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleView: () => navigate(`/task-details?id=${setting.key}`),
                handlePostAnUpdate: () => {
                  setSelectedTaskData(setting);
                  onOpenModalPostUpdate();
                  setTaskId(setting.key);
                },
                handleComplete: () => {
                  handleTaskComplete(setting.key);
                  setIsRenderer(!isRenderer);
                },
                workOrderUpdate:()=>{
                   setOpenModalWorkOrderUpdate(true);
                }
              }}
              fields={{
                view: true,
                postAnUpdate: true,
                complete: true,
                workOrderUpdate: true
              }}
            />
          </div>
        </>
      ),
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedTableItem([...selectedRowKeys]);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  // handle multiple delete tasks
  useEffect(() => {
    if (selectedTableItem.length > 0) {
      // call api to delete the task
      setSelectedTableItem([]);
      setIsDeleteTasksModal(false);
    }
  }, [isDeleteTasksRender]);

  // handle search
  useEffect(() => {
    if (filters.search) {
      const search = filters.search.toLowerCase();

      setFilteredTasks(
        taskData.filter((item) => {
          if (item.title.toLowerCase().includes(search)) return true;
          if (item.related.toLowerCase().includes(search)) return true;
          if (item.status.toLowerCase().includes(search)) return true;

          // Convert the date strings to Date objects
          const date1 = new Date(search);
          const date2 = new Date(item.due.dueDate);

          // Check if the dates are valid
          if (!(isNaN(date1) || isNaN(date2)) && date1 <= date2) {
            return true;
          }

          return false;
        })
      );
    } else {
      setFilteredTasks(taskData);
    }
  }, [filters.search, taskData]);

  // handlers
  const handleIconClick = (result) => {
    // Toggle the dropdownOpen state
    const filterData = taskData.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const handleTaskComplete = (id) => {
    console.log("Task completed: ", id);
  };

  const handleResetFilter = () => {
    setFilters({
      search: "",
      fromDate: "",
      toDate: "",
      range: "",
      property: "",
    });
  };

  // Modal Function
  const onOpenModalPostUpdate = () => {
    setOpenModalPostUpdate(true);
  };
  const onCloseModalPostUpdate = () => {
    setOpenModalPostUpdate(false);
  };
  const onOpenModelWorkUpdate = () => {
    setOpenModalWorkOrderUpdate(true);
  };
  const onCloseModelWorkUpdate = () => {
    setOpenModalWorkOrderUpdate(false);
  };

  const onOpenModalTask = () => {
    setOpenModalTask(true);
  };
  const onCloseModalTask = () => {
    setOpenModalTask(false);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Component.DeleteModal
        isOpen={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        message={"Are you sure you want to delete this task?"}
        deleteCall={() => setIsDeleteTaskRender(!isDeleteTaskRender)}
        isLoading={isLoading}
      />

      <Component.DeleteModal
        isOpen={isDeleteTasksModal}
        onClose={() => setIsDeleteTasksModal(false)}
        message={"Are you sure want to delete selected tasks?"}
        deleteCall={() => setIsDeleteTasksRender(!isDeleteTasksRender)}
        isLoading={isLoading}
      />

      <Component.FormModalLayout
        title={"Post Update"}
        isOpen={openModalPostUpdate}
        onClose={onCloseModalPostUpdate}>
        <ServiceProfessionalPostUpdateModel />
      </Component.FormModalLayout>
      <Component.FormModalLayout
        title={"Work Update"}
        isOpen={openModalWorkOrderUpdate}
        onClose={onCloseModelWorkUpdate}>
        <ServiceProfessionalWorkUpdateModel />
      </Component.FormModalLayout>

      {openModal && (
        <PaymentSuccessModal
          onClose={onCloseModal}
          message="Task details inserted successfully"
          success="All Tasks"
        />
      )}

      <div className="flex-fill p-3">
        <Component.FilterRow
          filter={filters}
          setFilter={setFilters}
          btnHandler={() => onOpenModalTask()}
          handleSubmit={() => setIsRenderer(!isRenderer)}
          handleReset={handleResetFilter}
          filterList={{
            range: true,
            date: true,
            property: true,
          }}
        />

        <div className="mt-3">
          <div className="col-md-12">
            {selectedTableItem.length >= 1 && (
              <div className="table-delete-icon mb-3">
                <button
                  onClick={() => setIsDeleteTasksModal(true)}
                  className="table-delete-btn next-btn-main">
                  <Icon.Trash />
                  Delete
                </button>
              </div>
            )}

            <div>
              <Component.CustomTable
                columns={columns}
                dataSource={filteredTasks}
                loading={isLoading}
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ServiceProfessionalTasks;
