import React, { useState, useEffect, useRef  } from "react";
import { debounce } from "lodash";

import settingIcon from "assets/three-dots.png";
import { useNavigate, Link } from "react-router-dom";
import { usePDF } from "react-to-pdf";
import viewIcon from "assets/Icon.png";
import trashIconWhite from "assets/trash-icon-white.png";
import { useSelector, useDispatch } from "react-redux";
import MileageDeleteModal from "Modals/MileageDeleteModal/MileageDeleteModal";
import MileageEditModal from "Modals/MileageEditModal/MileageEditModal";
import { DeleteMileage, fetchMileage, bulkDeleteMileages } from "network/landlord/mileage";
import * as Component from "Components/GeneralComponents";
import UseGetHook from "Hooks/UseGetHook";
import BulkDelete from "Hooks/BulkDelete";
import UserPermission from "libs/UserPermission";

const Mileage = () => {
  const { ROLE } = UserPermission();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // States
  const { toPDF, targetRef } = usePDF({ filename: "Mileage_Report.pdf" });
  const [selectedTableItem, setSelectedTableItem] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [openEditModalMileage, setOpenEditModalMileage] = useState(false);
  const [mileageId, setMileageId] = useState("");
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [MileageData, setMileageData] = useState([]);
  const [selectedMileageIds, setSelectedMileageIds] = useState([]);
  const [filter, setFilter] = useState({ toDate: "", fromDate: "", range: "" });

  const [totalRecords, setTotalRecords] = useState(0); // Track total tasks for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // Fetch Mileage Data
  const fetchMileageData = async (currentPage, pageSize, filter) => {
    setLoading(true);
    try {
      const response = await fetchMileage(currentPage, pageSize, filter);
      console.log("Response:", response);
      if (response && response.message) {
        setMileageData(response.message.data.list.map((e) => ({
          key: e.id,
          businessName: e.businessName,
          fromLocation: e.fromLocation,
          toLocation: e.toLocation,
          tripDate: e.tripDate ? new Date(e.tripDate).toLocaleDateString() : "N/A",
          miles: e.miles,
          amount: e.amount,
        }))
      );
        setTotalRecords(response.message.data.pagination.totalRecords); // Assuming totalTasks is in pagination object
      }
    } catch (error) {
      console.error("Error fetching mileage data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch mileage data on component mount and on update
  useEffect(() => {
    fetchMileageData(currentPage, pageSize, filter);
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (update) {
      fetchMileageData(currentPage, pageSize, filter);
      setUpdate(false);
    }
  }, [update]);
  const debounceTimeout = useRef(null);

  const handleSearchDebounce = (searchQuery) => {
    // Clear the previous timeout
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
  
    // Set a new timeout to make the API call
    debounceTimeout.current = setTimeout(() => {
      fetchMileageDataWithSearch(searchQuery);
    }, 500); // Delay in milliseconds
  };
  
  useEffect(() => {
    if (filter.search !== undefined) {
      handleSearchDebounce(filter.search);
    }
  }, [filter.search]);
  
  
  // Handle Delete Mileage Record
  const deleteMileageRecord = async () => {
    setLoading(true);
    try {
      await DeleteMileage(deleteId);
      fetchMileageData(currentPage,pageSize,filter);
      setOpenModal(false);
    } catch (error) {
      console.error("Delete Mileage Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilter = async () => {
    setLoading(true)
    const formattedFromDate = filter.fromDate ? new Date(filter.fromDate).toISOString() : "";
    const formattedToDate = filter.toDate ? new Date(filter.toDate).toISOString() : "";
  
    const updatedFilter = {
      ...filter,
      fromDate: formattedFromDate,
      toDate: formattedToDate,
    };
  
    console.log("Filter: ", updatedFilter);
    fetchMileageData(1, 10, updatedFilter);
    handlePageChange(1, 10)
  };
  const fetchMileageDataWithSearch  = debounce(async (searchQuery) => {
    const formattedFromDate = filter.fromDate ? new Date(filter.fromDate).toISOString() : "";
    const formattedToDate = filter.toDate ? new Date(filter.toDate).toISOString() : "";
    
    const updatedFilter = {
      ...filter,
      search: searchQuery,
      fromDate: formattedFromDate,
      toDate: formattedToDate,
    };
  
    setLoading(true);
    try {
      const response = await fetchMileage(1, 10, updatedFilter);
      if (response && response.message) {
        setMileageData(
          response.message.data.list.map((e) => ({
            key: e.id,
            businessName: e.businessName,
            fromLocation: e.fromLocation,
            toLocation: e.toLocation,
            tripDate: e.tripDate ? new Date(e.tripDate).toLocaleDateString() : "N/A",
            miles: e.miles,
            amount: e.amount,
          }))
        );
        setTotalRecords(response.message.data.pagination.totalRecords);
      }
    } catch (error) {
      console.error("Error fetching mileage data:", error);
    } finally {
      setLoading(false);
    }
  }, 500); // Adjust debounce delay time as needed (e.g., 500ms)
  
  const handleResetFilter = () => {
    setFilter({
      search: "",
      fromDate: "",
      toDate: "",
      range: "",
      property: "",
    });
    setCurrentPage(1); // Reset to the first page
    fetchMileageData(1, pageSize, {}); // Reset with an empty filter
  };

 const bulkDelete = async() => {
 await bulkDeleteMileages(selectedMileageIds)

  fetchMileageData(currentPage, pageSize, {}); // Reset with an empty filter

 }
  // Modal Handlers
  const onOpenModal = (id) => {
    setDeleteId(id);
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onOpenEditModalMileage = (id) => {
    setMileageId(id);
    setOpenEditModalMileage(true);
  };

  const onCloseEditModalMileage = () => {
    setOpenEditModalMileage(false);
    fetchMileageData(currentPage, pageSize, filter); // Reset with an empty filter
  };
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  // Table Data
  const Search = useSelector((state) => state.Search.value);

  const filteredData = MileageData.filter((item) =>
    item.businessName.toLowerCase().includes(Search.toLowerCase())
  );

  const tableData = filteredData.map((e) => ({
    key: e.id,
    businessName: e.businessName,
    fromLocation: e.fromLocation,
    toLocation: e.toLocation,
    tripDate: e.tripDate ? new Date(e.tripDate).toLocaleDateString() : "N/A",
    miles: e.miles,
    amount: e.amount,
  }));

  // Table Columns
  const columns = [
    {
      title: "Business Name",
      dataIndex: "businessName",
      render: (text, record) => (
        <Link to={`/mileage-view?id=${record.key}`}>{text}</Link>
      ),
    },
    {
      title: "From",
      dataIndex: "fromLocation",
    },
    {
      title: "To",
      dataIndex: "toLocation",
    },
    {
      title: "Trip Date",
      dataIndex: "tripDate",
    },
    {
      title: "Miles",
      dataIndex: "miles",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text) => `$${text}`,
    },
    {
      title: "Actions",
      render: (_, record) => (
        <div className="task-table-setting-container position-relative cursor">
          <img
            src={settingIcon}
            alt="settings"
            onClick={() => handleIconClick(record.key)}
          />
          <Component.RecordMenuPopup
            isOpen={record.key === selectedTableItem}
            onClose={() => setSelectedTableItem(null)}
            fields={{ edit: true, view: true, delete: true }}
            handler={{
              handleEdit: () => onOpenEditModalMileage(record.key),
              handleView: () => navigate(`/mileage-view?id=${record.key}`),
              handleDeleteRecord: () => onOpenModal(record.key),
            }}
          />
        </div>
      ),
    },
  ];

  // Handle Icon Click
  const handleIconClick = (key) => {
    setSelectedTableItem((prevKey) => (prevKey === key ? null : key));
  };

  return (
    <div className="container-fluid bg-white p-3">
      <Component.FilterRow
        filter={filter}
        setFilter={setFilter}
        btnTitle={"Add New Mileage"}
        handleSubmit={handleFilter}
        handleReset={handleResetFilter}
        btnHandler={() => navigate("/add-mileage")}
        filterList={{ date: true, range: false }}
      />
      <div className="mt-3">
        {selectedMileageIds.length > 0 && (
          <button
            className="table-delete-btn next-btn-main"
            onClick={bulkDelete}
          >
            <img src={trashIconWhite} alt="delete" />
            Delete
          </button>
        )}
        <div ref={targetRef}>
        <Component.MyCutomTable
            columns={columns}
            dataSource={MileageData}
            loading={loading}
            rowSelection={{
              selectedRowKeys: selectedMileageIds,
              onChange: setSelectedMileageIds,
            }}
            pagination={true}
            onPageChange={handlePageChange}
            total={totalRecords} // Pass total records for pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            // pageSize={pageSize}
            // taskType={currentP}
          />
        </div>
      </div>
      {openModal && (
        <Component.DeleteModal
          isOpen={openModal}
          onClose={onCloseModal}
          deleteCall={deleteMileageRecord}
          message="Do you confirm to delete this mileage record?"
          isLoading={loading}
        />
      )}
      {openEditModalMileage && (
        <Component.FormModalLayout
          onClose={onCloseEditModalMileage}
          isOpen={openEditModalMileage}
          title={"Edit Mileage"}
        >
          <MileageEditModal
            id={mileageId}
            onClose={onCloseEditModalMileage}
            setUpdate={setUpdate}
          />
        </Component.FormModalLayout>
      )}
    </div>
  );
};

export default Mileage;
