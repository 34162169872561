import React, { useEffect } from "react";
import accountingImg from "assets/fi_2830543.png";
import mileageImg from "assets/Group 1000004221.png";
import paymentsImg from "assets/fi_2845647.png";
import { Link } from "react-router-dom";
import UserPermission from "libs/UserPermission";

const AccountingMain = () => {
  const { FetchUserRole, role, ROLE } = UserPermission();
  useEffect(() => {
    FetchUserRole();
  }, []);
  return (
    <>
      <div className="container-fluid p-4">
        <div className="row">
          <div className="col-md-12 text-center">
            <p className="contact-page-title my-4">
              Select a Accounting Category: Bank Account, Mileage or Payments
            </p>
          </div>
        </div>
        <div className="container-xl mx-auto row justify-content-center row-gap-4 column-gap-4 px-0 px-md-2 px-lg-4">
          {localStorage.getItem("role") !== "landlord" &&
          localStorage.getItem("role") === "user" ? (
            <div className="col-12 col-md p-0">
              <div className="card contact-page-card-main h-100 mx-auto">
                <Link to="/all-accounts">
                  <div className="card-body px-3 py-4 p-lg-4">
                    <img
                      src={accountingImg}
                      alt=""
                      className="d-block mx-auto mb-3 contact-page-card-img"
                      style={{ height: "160px" }}
                    />
                    <p className="text-center contact-page-sub-title">
                      Bank Accounts
                    </p>
                    <p className="contact-page-sub-text text-center mb-0">
                      Details information related to financial accounts that are
                      connected to DigitalTenant.
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          ) : localStorage.getItem("role") === "landlord" ? (
            <div className="col-12 col-md p-0">
              <div className="card contact-page-card-main h-100 mx-auto">
                <Link to="/all-accounts">
                  <div className="card-body px-3 py-4 p-lg-4">
                    <img
                      src={accountingImg}
                      alt=""
                      className="d-block mx-auto mb-3 contact-page-card-img"
                      style={{ height: "160px" }}
                    />
                    <p className="text-center contact-page-sub-title">
                      Bank Accounts
                    </p>
                    <p className="contact-page-sub-text text-center mb-0">
                      Details information related to financial accounts that are
                      connected to DigitalTenant.
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          ) : (
            ""
          )}
          {localStorage.getItem("role") !== "landlord" &&
          localStorage.getItem("role") === "user" ? (
            <div className="col-12 col-md p-0">
              <div className="card contact-page-card-main h-100 mx-auto">
                <Link to="/mileage">
                  <div className="card-body px-3 py-4 p-lg-4">
                    <img
                      src={mileageImg}
                      alt=""
                      className="d-block mx-auto mb-3 contact-page-card-img"
                      style={{ height: "160px" }}
                    />
                    <p className="text-center contact-page-sub-title">
                      Mileage
                    </p>
                    <p className="contact-page-sub-text text-center mb-0">
                      A place to record travel distances for expense tracking
                      purposes.{" "}
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          ) : localStorage.getItem("role") === "landlord" ? (
            <div className="col-12 col-md p-0">
              <div className="card contact-page-card-main h-100 mx-auto">
                <Link to="/mileage">
                  <div className="card-body px-3 py-4 p-lg-4">
                    <img
                      src={mileageImg}
                      alt=""
                      className="d-block mx-auto mb-3 contact-page-card-img"
                      style={{ height: "160px" }}
                    />
                    <p className="text-center contact-page-sub-title">
                      Mileage
                    </p>
                    <p className="contact-page-sub-text text-center mb-0">
                      A place to record travel distances for expense tracking
                      purposes.{" "}
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="col-12 p-0">
            <div className="row justify-content-center px-0 px-md-4">
              {/* <div className="col-md-4"></div> */}
              <div className="col">
                <div className="card contact-page-card-main h-100 mx-auto">
                  <Link to="/payment">
                    <div className="card-body px-3 py-4 p-lg-4">
                      <img
                        src={paymentsImg}
                        alt=""
                        className="d-block mx-auto mb-3 contact-page-card-img"
                        style={{ height: "160px" }}
                      />
                      <p className="text-center contact-page-sub-title">
                        Payments
                      </p>
                      <p className="contact-page-sub-text text-center mb-0">
                        Records of financial transactions, including rent, fees,
                        and other charges.
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
              {/* <div className="col-md-4"></div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountingMain;
