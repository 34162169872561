// RecurringCheckout.js
import React, { useState } from "react";
import { Skeleton } from "antd"; // Import Skeleton from Ant Design
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import PaymentSuccessModal from "Modals/PaymentSuccessModal/PaymentSuccessModal";
import { postPayment } from "network/landlord/Account"; // Adjust this import for your actual API call
import "../TenantPassportProfile/PaymentForm.css"; // Use the same CSS as PassportCheckout

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CheckoutForm = ({ clientSecret, invoiceNo, amount, handleClose }) => {
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const name = localStorage.getItem("name");
  const [isProcessing, setIsProcessing] = useState(false); // Track processing state

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements || isProcessing) return;

    setIsProcessing(true);

    const cardNumberElement = elements.getElement(CardNumberElement);

    const { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: cardNumberElement,
          billing_details: { name: name },
        },
      }
    );

    if (error) {
      console.error("Payment error:", error);
      setMessage(`Payment failed: ${error.message}`);
      handleClose()
    } else if (paymentIntent?.status === "succeeded") {
      console.log("Payment succeeded!");
      setMessage("Payment was successful!");
      setPaymentSuccess(true);
      onclose()
      postPayment(invoiceNo); // Update payment status in your 
      handleClose()
    }

    setIsProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit} className="p-4">
      <h4 className="mb-3"> {`Payment for InvoiceNo:  ${invoiceNo.substring(
              invoiceNo.indexOf("INV")
            )}`} </h4>

      <div className="border rounded p-3 mb-4">
        <h6 className="mb-2">{`Amount: ${amount}`}</h6>
        <p className="small text-muted">
         Please make a payment for the invoice.
        </p>
      </div>

      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Name on card
        </label>
        <input
          type="text"
          className="form-control"
          id="name"
          placeholder="Olivia Rhye"
          required
        />
      </div>

      <div className="mb-3">
        <label htmlFor="card-number" className="form-label">
          Card number
        </label>
        <CardNumberElement
          id="card-number"
          options={{
            style: {
              base: {
                fontSize: "16px",
                color: "#424770",
                "::placeholder": { color: "#aab7c4" },
              },
              invalid: { color: "#9e2146" },
            },
          }}
          className="form-control"
        />
      </div>

      <div className="row">
        <div className="col-md-6 mb-3">
          <label htmlFor="card-expiry" className="form-label">
            Expiry
          </label>
          <CardExpiryElement
            id="card-expiry"
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": { color: "#aab7c4" },
                },
                invalid: { color: "#9e2146" },
              },
            }}
            className="form-control"
          />
        </div>
        <div className="col-md-6 mb-3">
          <label htmlFor="card-cvc" className="form-label">
            CVC
          </label>
          <CardCvcElement
            id="card-cvc"
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": { color: "#aab7c4" },
                },
                invalid: { color: "#9e2146" },
              },
            }}
            className="form-control"
          />
        </div>
      </div>

      <div className="form-check mb-3">
        <input type="checkbox" className="form-check-input" id="saveCard" />
        <label className="form-check-label" htmlFor="saveCard">
          Save my card details
        </label>
      </div>

      <button
        type="submit"
        className="btn btn-primary w-100  p-3"
        disabled={!stripe || isProcessing}
      >
        {isProcessing ? "Processing..." : "Pay Now"}
      </button>
    </form>
  );
};

const RecurringCheckout = ({ options, invoiceNo, amount, handleClose }) => {
  const { clientSecret } = options;

  if (!clientSecret) {
    return (
     <div className="p-4">
       <Skeleton active>
        <Skeleton.Input active size="large" className="mb-3" />
        <Skeleton.Input active size="large" className="mb-3" />
        <Skeleton.Input active size="large" className="mb-3" />
        <div className="row">
          <div className="col-md-6 mb-3">
            <Skeleton.Input active size="large" />
          </div>
          <div className="col-md-6 mb-3">
            <Skeleton.Input active size="large" />
          </div>
        </div>
        <Skeleton.Button active size="large" block />
      </Skeleton>
     </div>
    );
  }
  

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <CheckoutForm
        clientSecret={clientSecret}
        invoiceNo={invoiceNo}
        amount={amount}
        handleClose={handleClose}
      />
    </Elements>
  );
};

export default RecurringCheckout;
