export default function Leasehistorydata() {
  return (
    <>
      <div className="custom-bggray-color p-3 m-3">
        <div className="">02/03/23</div>
        <div className="">
          <span className="custom-orange-shade fw-bold"> Muhamad tahir </span>{" "}
          created the fix term lease from 00/00/0000hello how are you
        </div>
      </div>
    </>
  );
}
