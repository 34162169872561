import {
  Checkbox,
  SelectField,
  TextAreaField,
} from "Components/GeneralComponents";
import ModalLoader from "Components/GeneralComponents/ModalLoader";
import { useState } from "react";
import { selectTaskStatus } from "utils/dropdownData";

function ServiceProfessionalPostUpdateModel() {
  const [form, setForm] = useState({
    status: "",
    description: "",
  });
  const [notifyLandlord, setNotifyLandlord] = useState(false);

  // handlers
  const handleForm = (formField, value) => {
    setForm((prev) => {
      return {
        ...prev,
        [formField]: value,
      };
    });
  };

  return (
    <div
      className=""
      style={{ padding: "0 30px" }}>
      <div className="mt-3">
        <TextAreaField
          name={"description"}
          label={"Description"}
          placeholder={"Description"}
          handler={(e) => handleForm("description", e.target.value)}
          required={true}
          value={form.inputDetails}
          rows={"5"}
          cols={"30"}
        />
      </div>

      <div className="mt-3">
        <SelectField
          name={"status"}
          required={true}
          label={"Status"}
          placeholder={"Select Status"}
          value={form.status}
          options={selectTaskStatus}
          handler={(e) => handleForm("status", e)}
        />
      </div>

      <div className="mt-5 col-md-12 d-flex justify-content-between">
        <Checkbox
          label={"Notify Landlord"}
          checked={notifyLandlord}
          onChange={(e) => setNotifyLandlord(e.target.checked)}
        />

        <div className="post-update-btn">
          <button
            onClick={() => {
              console.log("Updating task", form);
            }}
            className="modal-post-update-btn">
            Post Update
          </button>
        </div>
      </div>
    </div>
  );
}
export default ServiceProfessionalPostUpdateModel;
