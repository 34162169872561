import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import AllTenants from "Components/AllTenants/AllTenants";
import NoAccessPage from "Pages/NoAccessMainPage/NoAccessPage";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";

const AllTenantsPage = () => {
  const storedRoles = localStorage.getItem("myUserRoles");
  const myUserRoles =
    storedRoles !== "undefined" ? JSON.parse(storedRoles) : [];
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar topBarTitle="All Tenants" />
      {!["Owner/Executive", "Property Manager", "Leasing Agent"].some((role) =>
        myUserRoles?.includes(role)
      ) && myUserRoles?.length !== 0 ? (
        <NoAccessPage />
      ) : (
        <AllTenants />
      )}
    </div>
  );
};

export default AllTenantsPage;
