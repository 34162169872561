import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import TenantPassport from "Components/TenantPassport/TenantPassport";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const Passport = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar topBarTitle={"Tenant Passport"} />
      <TenantPassport />
    </div>
  );
};

export default Passport;
