import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import NewLease from "Components/NewLease/NewLease";
import React from "react";

const LeasesNew = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail="New Lease"
        pop={true}
      />
      <NewLease />
    </div>
  );
};

export default LeasesNew;
