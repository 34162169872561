import React, { useEffect, useState } from "react";
import { message } from "antd";
import {
  validateLeaseInfoForm,
  validateLeaseTenantsForm,
} from "utils/validations";
import { leaseFormData } from "utils/FormDataStructure";
import { updateLease } from "network/landlord/lease";
import * as InnerComponents from "./index";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import TempModal from "Modals/TempModal/TempModal";
import * as API from "network/landlord/lease";
import Loader from "Helpers/Loader";
import { Stepper } from "Components/GeneralComponents";
import { draftCommercialLeaseDetails, draftLeaseDetails } from "utils/stepsData";

const EditdraftNewLease = () => {
  const { id } = UseUrlParamsHook();
  const [isLoader, setLoader] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [errors, setErrors] = useState({});
  const [selectedPropertyType, setSelectedPropertyType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [leaseFiles, setLeaseFiles] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [tenantEmails, setTenantEmails] = useState([]);
  const [form, setForm] = useState({
    property: {
      id: "",
      heading1: "",
      heading2: "",
      heading3: "",
    },
    unit: "",
    lease_term: "",
    lease_term_start_date: "",
    lease_term_end_date: "",
    first_rent_date: "",
    frequency: "",
    rent_amount: {
      amount: "",
    },
    security_deposit: "",
    security_amount: {
      amount: "",
    },
    late_fee: "",
    late_fee_charge: "",
    late_fee_frequency: "",
    late_fees_amount: {
      amount: "",
      type: "$",
    },
    renewLease: "",
    tenant: {
      _id: "",
      name: "",
      email: "",
      type: "",
    },
    coTenants: [],
  });
  const [dependentInfo, setDependentInfo] = useState([
    {
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      email: "",
      phone: "",
      relationship: "",
      description: "",
    },
  ]);
  const [pets, setPets] = useState([
    {
      petType: "",
      petName: "",
      petWeight: "",
      petBreed: "",
    },
  ]);
  const [vehicles, setVehicles] = useState([
    {
      vehicleType: "",
      vehicleMake: "",
      vehicleModel: "",
      vehicleYear: "",
      vehicleColor: "",
      vehicleLicense: "",
    },
  ]);
  const [documentInfo, setDocumentInfo] = useState({
    isSigned: "",
    newLeaseSign: "",
    documentId: "",
    documentName: "",
    documentUrl: "",
    documentStatus: "",
    invites: [],
  });

  const handleChange = (fieldName, value, index) => {
    if (fieldName === "coTenant") {
      const newCoTenants = [...form.coTenants];
      newCoTenants[index] = value;
      setForm({
        ...form,
        coTenants: newCoTenants,
      });
      return;
    }
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  useEffect(() => {
    setLoader(true);
    API.getLeaseById(id, { tab: "all" }).then((res) => {
      setLoader(false);
      if (res.apiCallStatus === "success") {
        setForm({
          property: {
            id: res.message.data.lease.property || "",
            heading1: "",
            heading2: "",
            heading3: "",
          },
          unit: res.message.data.lease.unit || "",
          lease_term: res.message.data.lease.lease_term || "",
          lease_term_start_date:
            res.message.data.lease.lease_term_start_date || "",
          lease_term_end_date: res.message.data.lease.lease_term_end_date || "",
          first_rent_date: res.message.data.lease.first_rent_date || "",
          frequency: res.message.data.lease.frequency || "",
          rent_amount: {
            amount: res.message.data.lease.rent_amount || "",
          },
          security_deposit: res.message.data.lease.deposit_amount
            ? "Yes"
            : "No",
          security_amount: {
            amount: res.message.data.lease.deposit_amount || "",
          },
          late_fee: res.message.data.lease.late_fees_amount ? "Yes" : "No",
          late_fee_charge: "",
          late_fee_frequency: "",
          late_fees_amount: {
            amount: res.message.data.lease.late_fees_amount || "",
            type: "$",
          },
          renewLease: "",
          tenant: {
            _id: res.message.data.tenants[0]?.id || "",
            name: res.message.data.tenants[0]?.name || "",
            email: res.message.data.tenants[0]?.email || "",
            type: res.message.data.tenants[0]?.type || "",
          },
          coTenants: res.message.data.tenants
            .filter((tenant, index) => index !== 0)
            .map((tenant) => ({
              _id: tenant.id || "",
              name: tenant.name || "",
              email: tenant.email || "",
              type: tenant.type || "",
            })),
        });
        res.message.data.dependents?.map((dependent, index) => {
          const data = {
            firstName: dependent.first_name || "",
            middleName: dependent.middle_name || "",
            lastName: dependent.last_name || "",
            dob: dependent.dob || "",
            email: dependent.email || "",
            phone: dependent.phone || "",
            relationship: dependent.relationship || "",
            description: "",
          };
          index === 0
            ? setDependentInfo([data])
            : setDependentInfo((prev) => [...prev, data]);
        });
        res.message.data.pets?.map((pet, index) => {
          const data = {
            petType: pet.pet_type || "",
            petName: pet.name || "",
            petWeight: pet.weight || "",
            petBreed: pet.breed || "",
          };
          index === 0 ? setPets([data]) : setPets((prev) => [...prev, data]);
        });
        res.message.data.vehicles?.map((vehicle, index) => {
          const data = {
            vehicleType: vehicle.vehicle_type || "",
            vehicleMake: vehicle.make || "",
            vehicleModel: vehicle.model || "",
            vehicleYear: vehicle.year || "",
            vehicleColor: vehicle.color || "",
            vehicleLicense: vehicle.license_plate || "",
          };
          index === 0
            ? setVehicles([data])
            : setVehicles((prev) => [...prev, data]);
        });
        setDocumentInfo({
          isSigned: res.message.data.lease.documents?.isSigned || "",
          newLeaseSign: res.message.data.lease.documents?.newLeaseSign || "",
          documentId: res.message.data.documents?.document_id || "",
          documentName: res.message.data.signnow_documents[0]?.name || "",
          documentUrl: res.message.data.signnow_documents[0]?.url || "",
          documentStatus: res.message.data.signnow_documents[0]?.status || "",
          invites: res.message.data.signnow_documents[0]?.invites || [],
        });
        setCurrentStep(res.message.data.lease.completed_steps);
        setTenantEmails(res.message.data.tenants.map((tenant) => tenant.email));
        setSelectedPropertyType(res.message.data.lease.property_type);
      }
    });
  }, [id]);

  useEffect(() => {
    if (form.security_deposit === "No") {
      setForm((prevForm) => ({
        ...prevForm,
        security_amount: {
          ...prevForm.security_amount,
          amount: "",
        },
      }));
    }
  }, [form.security_deposit]);

  useEffect(() => {
    if (form.late_fee === "No") {
      setForm((prevForm) => ({
        ...prevForm,
        late_fee_charge: "",
        late_fee_frequency: "",
        late_fees_amount: {
          ...prevForm.late_fees_amount,
          amount: "",
        },
      }));
    }
  }, [form.late_fee]);

  const handleDocumentInfoChange = (fieldName, value) => {
    if (fieldName === "object") {
      setDocumentInfo((prevDocumentInfo) => ({
        ...prevDocumentInfo,
        documentId: value.documentId,
        documentName: value.documentName,
        documentStatus: value.documentStatus,
        documentUrl: value.documentUrl,
      }));
      return;
    } else if (fieldName === "invites") {
      setDocumentInfo((prevDocumentInfo) => ({
        ...prevDocumentInfo,
        invites: value,
      }));
      return;
    }
    setDocumentInfo((prevDocumentInfo) => ({
      ...prevDocumentInfo,
      [fieldName]: value,
    }));
  };

  const handlePetChange = (field, index, value) => {
    const newPets = [...pets];
    newPets[index][field] = value;
    setPets(newPets);
  };

  const handleDependentChange = (field, index, value) => {
    const newDependents = [...dependentInfo];
    newDependents[index][field] = value;
    setDependentInfo(newDependents);
  };

  const addAnotherPet = () => {
    setPets([
      ...pets,
      { petType: "", petName: "", petWeight: "", petBreed: "" },
    ]);
  };

  const removePet = (indexToRemove) => {
    if (indexToRemove !== 0) {
      const updatedPets = pets.filter((pet, index) => index !== indexToRemove);
      setPets(updatedPets);
    }
  };

  const addAnotherDependent = () => {
    setDependentInfo([
      ...dependentInfo,
      {
        firstName: "",
        middleName: "",
        lastName: "",
        dob: "",
        email: "",
        phone: "",
        relationship: "",
        description: "",
      },
    ]);
  };

  const removeDependent = (indexToRemove) => {
    if (indexToRemove !== 0) {
      const updatedDependents = dependentInfo.filter(
        (dependent, index) => index !== indexToRemove
      );
      setDependentInfo(updatedDependents);
    }
  };

  const handleVehicleChange = (field, index, value) => {
    const newVehicles = [...vehicles];
    newVehicles[index][field] = value;
    setVehicles(newVehicles);
  };

  const addAnotherVehicle = () => {
    setVehicles([
      ...vehicles,
      {
        vehicleType: "",
        vehicleMake: "",
        vehicleModel: "",
        vehicleYear: "",
        vehicleColor: "",
        vehicleLicense: "",
      },
    ]);
  };

  const removeVehicle = (indexToRemove) => {
    if (indexToRemove !== 0) {
      const updatedVehicles = vehicles.filter(
        (vehicle, index) => index !== indexToRemove
      );
      setVehicles(updatedVehicles);
    }
  };

  const handleAddCoTenant = () => {
    setForm({
      ...form,
      coTenants: [
        ...form.coTenants,
        { _id: "", name: "", email: "", type: "" },
      ],
    });
  };

  const handleRemoveCoTenant = (index) => {
    const updatedCoTenants = form.coTenants.filter((tenant, i) => i !== index);
    setForm({
      ...form,
      coTenants: updatedCoTenants,
    });
  };

  const handleBackButton = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleLeaseInfo = () => {
    const newErrors = validateLeaseInfoForm(form);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      handleUpdateLease();
    }
  };

  const handleTenantInfo = () => {
    const newErrors = validateLeaseTenantsForm(form);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      handleUpdateLease();
    }
  };

  const handleUpdateLease = () => {
    const formData = leaseFormData(
      form,
      leaseFiles,
      dependentInfo,
      pets,
      vehicles,
      documentInfo,
      currentStep
    );
    setIsLoading(true);
    updateLease(id, formData).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        // setOpenModal(true);
        if (
          currentStep === 6 ||
          (selectedPropertyType === "commercial" && currentStep === 4)
        ) {
          setOpenModal(true);
        } else {
          setCurrentStep(currentStep + 1);
        }

        if (currentStep === 2) {
          setForm((prevForm) => ({
            ...prevForm,
            tenant: {
              ...prevForm.tenant,
              type: "Tenant",
            },
            coTenants: prevForm.coTenants.map((coTenant) => ({
              ...coTenant,
              type: "Tenant",
            })),
          }));
        }
      } else {
        message.error(res.error.message);
      }
    });
  };

  const handleNext = () => {
    switch (currentStep) {
      case 1:
        handleLeaseInfo();
        break;
      case 2:
        handleTenantInfo();
        break;
      case 3:
        handleUpdateLease();
        break;
      case 4:
        handleUpdateLease();
        break;
      case 5:
        handleUpdateLease();
        break;
      case 6:
        handleUpdateLease();
        break;
    }
  };

  return (
    <>
      {isLoader ? (
        <div className="mx-auto">
          <Loader />
        </div>
      ) : (
        <>
          {openModal && (
            <TempModal
              onClose={() => setOpenModal(false)}
              title={"Lease Created Successfully!"}
              success="Go To Leases"
              route="all-lease"
            />
          )}
          <div className="container-fluid p-3">
            <div className="container">
              {/* <InnerComponents.Stepper
                currentStep={currentStep}
                isCommercial={selectedPropertyType === "commercial"}
              /> */}
              <Stepper
                steps={selectedPropertyType === "commercial" ? draftCommercialLeaseDetails : draftLeaseDetails}
                activeStep={currentStep - 1}
                lockStep={false}
                maxSteps={4} // Customize if desired
                setIsDraft={(isDraft) => console.log("Draft status:", isDraft)}
              />
              <div className="stepper-content-container mt-4">
                {currentStep === 1 && (
                  <InnerComponents.LeaseInfoForm
                    form={form}
                    handleChange={handleChange}
                    errors={errors}
                    leaseFiles={leaseFiles}
                    setLeaseFiles={setLeaseFiles}
                    handlePropertyType={setSelectedPropertyType}
                    handleNext={handleNext}
                    isLoading={isLoading}
                    isDraftLoading={isLoader}
                  />
                )}
                {currentStep === 2 && (
                  <InnerComponents.TenantInfoForm
                    form={form}
                    handleChange={handleChange}
                    errors={errors}
                    handleAddCoTenant={handleAddCoTenant}
                    handleRemoveCoTenant={handleRemoveCoTenant}
                    setTenantEmails={setTenantEmails}
                    handleBackButton={handleBackButton}
                    handleNext={handleNext}
                    isLoading={isLoading}
                  />
                )}
                {selectedPropertyType !== "commercial" && currentStep === 3 && (
                  <InnerComponents.DependentInfoForm
                    dependentInfo={dependentInfo}
                    handleChange={handleDependentChange}
                    addAnotherDependent={addAnotherDependent}
                    removeDependent={removeDependent}
                    handleBackButton={handleBackButton}
                    handleNext={handleNext}
                    isLoading={isLoading}
                  />
                )}
                {selectedPropertyType !== "commercial" && currentStep === 4 && (
                  <InnerComponents.PetsForm
                    pets={pets}
                    handleChange={handlePetChange}
                    handleBackButton={handleBackButton}
                    handleNext={handleNext}
                    addAnotherPet={addAnotherPet}
                    removePet={removePet}
                    isLoading={isLoading}
                  />
                )}
                {((selectedPropertyType === "commercial" &&
                  currentStep === 3) ||
                  currentStep === 5) && (
                  <InnerComponents.VehicleForm
                    vehicles={vehicles}
                    handleChange={handleVehicleChange}
                    handleBackButton={handleBackButton}
                    handleNext={handleNext}
                    addAnotherVehicle={addAnotherVehicle}
                    removeVehicle={removeVehicle}
                    isLoading={isLoading}
                  />
                )}
                {((selectedPropertyType === "commercial" &&
                  currentStep === 4) ||
                  currentStep === 6) && (
                  <InnerComponents.DocumentInfoForm
                    form={documentInfo}
                    handleChange={handleDocumentInfoChange}
                    errors={errors}
                    leaseFiles={leaseFiles}
                    setLeaseFiles={setLeaseFiles}
                    handlePropertyType={setSelectedPropertyType}
                    handleBackButton={handleBackButton}
                    handleNext={handleNext}
                    isLoading={isLoading}
                    isSubmitForm={true}
                    tenantEmails={tenantEmails}
                    setSuccess={setOpenModal}
                    leaseId={id}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EditdraftNewLease;
