import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import PropertiesUnitsEdit from "Components/PropertiesUnitsEdit/PropertiesUnitsEdit";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";

const PropertiesUnitsEditPage = () => {
  const { property_id } = UseUrlParamsHook();
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar topBarTitleDetail={"Edit Unit Details"} back={`property-details-view?id=${property_id}&tab=2`} />
      <PropertiesUnitsEdit />
    </div>
  );
};

export default PropertiesUnitsEditPage;
