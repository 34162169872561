/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import searchIcon from "assets/search.png";
import activeDot from "assets/_Dot.png";
import inactiveDot from "assets/inactivedot.png";
import { Tooltip } from "antd";
import { useNavigate, Link } from "react-router-dom";
import { formatPhoneNumber } from "utils";
import helpIcon from "assets/help-circle.png";
import * as Icon from "assets/icons";
import * as Component from "Components/GeneralComponents";
import { getTenants } from "network/landlord/tenant";
import { TownHouse } from "assets/svg";

const TenantsTab = ({ propertyId, unitId }) => {
  const filter = {
    property: propertyId,
    unit: unitId,
  }
  const [key, setKey] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tenantData, setTenantData] = useState([]);
  const [filteredTenants, setFilteredTenants] = useState([]);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  // handle get tenants with filters
  useEffect(() => {
    setIsLoading(true);
    getTenants(filter).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        setTenantData(
          res.message.data
            .filter((e) => e.name.toLowerCase().includes(search.toLowerCase()))
            .map((e) => ({
              key: e.id,
              name: e.name,
              img: e?.profileImage,
              properties: e.properties,
              email: e.email,
              phone: e.phone,
              status: e.status,
            }))
        );
      } else {
        console.log("Error in fetching tenant data");
      }
    });
  }, []);

  // handle search
  useEffect(() => {
    if (search) {
      setFilteredTenants(
        tenantData.filter((item) => {
          if (item.name.toLowerCase().includes(search.toLowerCase()))
            return item;
          if (item.email.toLowerCase().includes(search.toLowerCase()))
            return item;
          if (item.phone.includes(search)) return item;
          if (item.status.toLowerCase().includes(search.toLowerCase()))
            return item;
          if (item.properties.includes(search)) return item;
        })
      );
    } else {
      setFilteredTenants(tenantData);
    }
  }, [search, tenantData]);

  // Data Table Functions
  const handleIconClick = (result) => {
    const filterData = tenantData.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const columns = [
    {
      title: "Tenant Name",
      dataIndex: "name",
      render: (text, name) => (
        <>
          <Link
            to={`/tenant-details-view?id=${name.key}`}
            className="d-flex align-items-center gap-2"
          >
            <div
              className="d-flex align-items-center justify-content-center flex-shrink-0"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                overflow: "hidden",
                border: `0.5px solid #c9c9c9`,
                position: "relative",
              }}
            >
              {name.img ? (
                <img
                  src={name.img}
                  alt="task"
                  loading="lazy"
                  style={{ width: "100%", height: "100%", objectFit: "cover",}}
                />
              ) : (
                <Component.FirstCharacterIcon name={name.name} />
              )}
            </div>
            <div className="">{text}</div>
          </Link>
        </>
      ),
    },
    {
      title: (
        <>
          Properties
          <Tooltip title={"Non-Expired Leases Properties"}>
            <img src={helpIcon} alt="" className="cursor ps-2" />
          </Tooltip>
        </>
      ),
      dataIndex: "properties",
      render: (text) => (
        <>
          <span className="tenants_table_properties_text">
            <Component.IconList
              properties={text}
              defaultimage={TownHouse}
            />
          </span>
        </>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      render: (text) => (
        <>
          <span className="tenants_table_email_text">{text}</span>
        </>
      ),
    },
    {
      title: (
        <>
          Phone No
          <Tooltip title={"Tenant Phone Number"}>
            <img src={helpIcon} alt="" className="cursor ps-2" />
          </Tooltip>
        </>
      ),
      dataIndex: "phone",
      render: (text) => (
        <>
          {" "}
          <span className="tenants_table_phone_text phone">
            {formatPhoneNumber(text)}
          </span>
        </>
      ),
    },
    {
      title: (
        <>
          Status
          <Tooltip title={"Shows if tenant account is created"}>
            <img src={helpIcon} alt="" className="cursor ps-2" />
          </Tooltip>
        </>
      ),
      dataIndex: "status",
      render: (text, status) => (
        <>
          <div
            className={
              text === "Active"
                ? "prospect-active-bar"
                : "prospect-inactive-bar"
            }
          >
            <img src={text === "Active" ? activeDot : inactiveDot} alt="" />{" "}
            <span>{text}</span>
          </div>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <Icon.VerticalElipsisIcon width={23} height={23} />
            <Component.RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleView: () => {
                  navigate(`/tenant-details-view?id=${setting.key}`);
                },
              }}
              fields={{
                view: true,
              }}
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="container-fluid bg-white">
        <div className="row mb-3">
          <div className="col-md-12">
            <div className="task-search-input position-relative">
              <input
                type="text"
                placeholder="Search"
                className="form-control search-form-control-task"
                onChange={(e) => setSearch(e.target.value)}
              />
              <div className="search-icon-task">
                <img src={searchIcon} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Component.CustomTable 
              columns={columns} 
              dataSource={filteredTenants} 
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TenantsTab;
