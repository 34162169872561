import { useState } from "react";
import {
  ButtonVariant1,
  CustomTable,
} from "Components/GeneralComponents";
import { useSelector, useDispatch } from "react-redux";
import trashIconWhite from "assets/trash-icon-white.png";
import { FilterValue } from "Store/Slices/FilterSlice";
import SearchBar from "Helpers/SearchBar";

const ListServiceProfessionalTab = ({
  data,
  columns,
  rowSelection,
  resetFilter,
}) => {
  const range = useSelector((state) => state.FilterValue.value);
  const property = useSelector((state) => state.FilterValue.property);
  const fromDate = useSelector((state) => state.FilterValue.fromDate);
  const toDate = useSelector((state) => state.FilterValue.toDate);

  const [selectionType] = useState("checkbox");
  const [selectedTableItem, setSelectedTableItem] = useState([]);

  return (
    <div className="container-fluid">
      <SearchBar serviceProfessionalFilter={true} />
      {range || property || fromDate || toDate ? (
        <ButtonVariant1 handler={resetFilter} children={"Reset"} />
      ) : null}
      <div className="row">
        {selectedTableItem.length > 0 && (
          <div className="table-delete-icon">
            <button className="table-delete-btn next-btn-main">
              <img src={trashIconWhite} />
              Delete
            </button>
          </div>
        )}
        <CustomTable
          pagination={true}
          columns={columns}
          dataSource={data}
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
        />
      </div>
    </div>
  );
};

export default ListServiceProfessionalTab;
