const config = {
  baseUrl:
    process.env.REACT_APP_ENVIRONMENT === "development"
      ? process.env.REACT_APP_BACKEND_URL_DEV
      : process.env.REACT_APP_BACKEND_URL,
  socketUrl: process.env.REACT_APP_SOCKET_URL,
  stripe_publish_key: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
};

export default config;
