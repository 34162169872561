import React, { useEffect, useState } from "react";
import { CustomTable } from "Components/GeneralComponents";
import { fetchTenantUnitTasks } from "network/tenant/Property";
import settingIcon from "assets/three-dots.png";
import { Link } from "react-router-dom";
import SearchBar from "Helpers/SearchBar";
import editIcon from "assets/edit-05.png";
import deleteIcon from "assets/trash-01.png";
import completeIcon from "assets/calendar-check-01.png";
import TenantTaskUnitModal from "Modals/TenantTaskUnitModal/TenantTaskUnitModal";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";

const TasksTab = ({ id }) => {
  const { property_id } = UseUrlParamsHook();
  const [key, setKey] = useState("");
  const [selectedTableItem, setSelectedTableItem] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [Search, setSearch] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [component, setcomponent] = useState("");
  const [selectionType, setSelectionType] = useState("checkbox");
  const [taskData, setTaskData] = useState([]);
  const [openModalTask, setOpenModalTask] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleIconClick = (result) => {
    // Toggle the dropdownOpen state
    const filterData = taskData?.filter((item) => {
      return item.key === result;
    });
    setKey(filterData.key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const onOpenModalTask = () => {
    setOpenModalTask(true);
  };
  const onCloseModalTask = () => {
    setOpenModalTask(false);
  };
  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchTenantUnitTasks(id).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        let temp = [];
        temp = res.message;

        console.log("task Success", temp);

        //     key: index + 1,
        //     id: e.id,
        //     title: e.title,
        //     img: e.image,
        //     assigned: e.assignedTo.map((e) => e.firstName).join(", "),
        //     assignedToMe: localStorage.getItem("name"),
        //     due: [
        //       e.dueDate ? new Date(e.dueDate).toLocaleDateString() : "N/A",
        //       e.priority || "N/A",
        //     ],
        //     related: e.property.title,
        //     status: e.status || "N/A",

        // setTaskData(
        //   temp.map((e, index) => ({
        //     key: index + 1,
        //     id: e.id,
        //     title: e.title,
        //     img: e.image,
        //     assigned: e.assignedTo.map((e) => e.firstName).join(", "),
        //     assignedToMe: localStorage.getItem("name"),
        //     due: [
        //       e.dueDate ? new Date(e.dueDate).toLocaleDateString() : "N/A",
        //       e.priority || "N/A",
        //     ],
        //     related: e.property.title,
        //     status: e.status || "N/A",
        //   }))
        // );
      } else {
        console.log("Error in fetching task data");
      }
    });
  }, []);

  const tasksColumns = [
    {
      title: "Title",
      dataIndex: "title",
      render: (text, task) => (
        <Link
          to={`/task-details?id=${task.id}`}
          className="all-task-table-title-text"
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Assigned To",
      dataIndex: "assigned",
      render: (text, assigned) => (
        <>
          {" "}
          {/* <img className="me-2" src={oval} alt="" /> */}
          {assigned.assignedToMe ? (
            <span>
              {text}
              {text ? ", " : ""}
              {assigned.assignedToMe} (YOU)
            </span>
          ) : (
            <span>{text}</span>
          )}
        </>
      ),
    },
    {
      title: "Due At",
      dataIndex: "due",
      render: (text) => (
        <>
          <div className="assign-date-container">
            <div
              className={
                text[1] === "High"
                  ? "priority-text bg-error"
                  : text[1] === "Low"
                  ? "priority-text bg-grey"
                  : text[1] === "Medium"
                  ? "priority-text bg-yellow"
                  : text[1] === "Very High"
                  ? "priority-text bg-error"
                  : ""
              }
            >
              <span>{`${text[1]} Priority`}</span>
            </div>
            <br />
            <span className="assign-to-date">{`${text[0]} Date`}</span>
          </div>
        </>
      ),
    },
    {
      title: "Related To",
      dataIndex: "related",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <>
          <span
            className={
              status === "Completed"
                ? "completed-status text-white"
                : status === "In Progress"
                ? "in-progress-status text-white"
                : status === "Not Started"
                ? "not-started-status text-dark"
                : ""
            }
          >
            {status}
          </span>{" "}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div className="task-table-setting-container position-relative cursor">
            <img
              src={settingIcon}
              alt=""
              onClick={() => handleIconClick(setting.key)}
            />
            {setting.key === key && (
              <div className="task-table-setting-dropdown-prospect bg-white box-shadow">
                <ul className="p-0 d-flex flex-column gap-3">
                  <Link to={`/task-details?id=${setting.key}`}>
                    <li className="list-style-none table-setting-dropdown-menu">
                      {" "}
                      <img src={editIcon} alt="" /> Edit
                    </li>
                  </Link>
                  <Link>
                    <li className="list-style-none table-setting-dropdown-menu">
                      {" "}
                      <img src={completeIcon} alt="" /> Complete
                    </li>
                  </Link>
                  <li
                    onClick={() => {
                      onOpenDeleteModal();
                      setDeleteId(setting.id);
                      setcomponent("task");
                    }}
                    className="list-style-none table-setting-dropdown-menu"
                  >
                    {" "}
                    <img src={deleteIcon} alt="" /> Delete
                  </li>
                </ul>
              </div>
            )}
          </div>
        </>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTableItem([...selectedRowKeys]);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div>
      {openModalTask === true && (
        <TenantTaskUnitModal
          id={id}
          property={property_id}
          onOpen={onOpenModal}
          onClose={onCloseModalTask}
        />
      )}
      <SearchBar
        onClick={onOpenModalTask}
        taskFilter={true}
        btnTitle="Add New Task"
      />
      {/* {range || property || fromDate || toDate ? (
        <div className="mt-2" style={{ width: "fit-content" }}>
          <ButtonVariant1 handler={resetFilter} children={"Reset"} />
        </div>
      ) : (
        ""
      )} */}
      <div className="task-table-container mt-3">
        <CustomTable
          loading={isLoading}
          pagination={true}
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          columns={tasksColumns}
          dataSource={taskData}
        />
      </div>
    </div>
  );
};

export default TasksTab;
