import { useCallback, useEffect, useState } from "react";
import CheckBoxActive from "assets/_Checkbox active.png";
import ProIcon from "assets/user-check-02.png";
import UseGetHook from "Hooks/UseGetHook";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import config from "Helpers/config.js";
import { loadStripe } from "@stripe/stripe-js";
import { AuthLayout, SuccessModal } from "Components/GeneralComponents";
import { subscribe } from "network/landlord/subscription";

const PaymentMethodSignUp = () => {
  // States start
  const [openModal, setOpenModal] = useState(false);
  // States end
  const { FetchLandlordPlans } = UseGetHook("landlord");
  const { interval, email, id, planName, price } = UseUrlParamsHook();

  useEffect(() => {
    FetchLandlordPlans();
  }, []);

  // Modal Function
  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
    window.location.href = "/";
  };

  // Stripe
  const stripeKey = loadStripe(config.stripe_publish_key);

  // Fetch Client Secret for Embedded Checkout
  const fetchClientSecret = useCallback(async () => {
    const res = await subscribe({
      userId: id,
      plan: planName,
      billingCycle: interval,
      paymentMethodId: null,
    });
    const Session = res.message.session;
    console.log("Subscribe Response", res);
    return Session.client_secret;
  }, [email, interval, planName]);

  // Handle Checkout Completion
  const handleCheckoutCompletion = async (event) => {
    onOpenModal();
  };

  const options = { fetchClientSecret, onComplete: handleCheckoutCompletion };

  // Stripe End
  return (
    <>
      {openModal === true && (
        <SuccessModal
          heading={"Successful Payment"}
          title={
            "Your payment has been processed and your account has been created successfully."
          }
          // route={"land-lord-dashboard"}
          handler={onCloseModal}
          success={"Go To Dashboard"}
          onClose={onCloseModal}
        />
      )}

      <AuthLayout>
        <div className="container-fluid">
          <div className="row">
            <div className="">
              <div className="container">
                <div className="land-lord-right-heading text-start mt-5 d-flex flex-column">
                  <span style={{ fontSize: "33px", fontWeight: "600" }}>
                    Add a Payment Method
                  </span>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#2D3748",
                    }}
                  >
                    Create your account if not registered yet
                  </span>
                </div>
                <div className="payment-select-boxes mt-4">
                  <div className="payment-select-box active-box">
                    <div className="payment-select-box-left d-flex gap-2 align-items-center">
                      <img
                        src={ProIcon}
                        className="img-fluid object-fit-contain"
                        alt=""
                      />
                      <div className="onboarding-box-text ">
                        <div>
                          <span
                            style={{
                              fontSize: "18px",
                              fontWeight: "500",
                              color: "#344054",
                            }}
                          >
                            Service {planName}
                          </span>{" "}
                          <span
                            className="ms-2"
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#475467",
                            }}
                          >{`$${price}/${interval}`}</span>
                        </div>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#667085",
                          }}
                        >
                          Includes up to 5 users, 10GB indiviual data and access
                          to all features.
                        </span>
                      </div>
                    </div>
                    <div className="onboarding-select-box-check-box">
                      <img src={CheckBoxActive} alt="" />
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <EmbeddedCheckoutProvider
                    stripe={stripeKey}
                    options={options}
                  >
                    <EmbeddedCheckout />
                  </EmbeddedCheckoutProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
};

export default PaymentMethodSignUp;
