import React, { useState, useEffect } from "react";
import validateError from "assets/alert-circle-validate.png";
import { Tooltip } from "antd";
import helpIcon from "assets/help-circle.png";

const formatEINWithMask = (ein) => {
  const cleanEIN = ein.replace(/\D/g, "");
  const maskedEIN = cleanEIN.replace(/\d/g, "*");
  const part1 = maskedEIN.slice(0, 2);
  const part2 = maskedEIN.slice(2, 9);

  if (maskedEIN.length > 2) return `${part1}-${part2}`;
  return part1;
};

function EINField({
  type = "text",
  name = "EIN",
  required = true,
  label = "EIN",
  placeholder = "XX-XXXXXXX",
  value,
  handler,
  errorMessage = false,
  TooltipTitle,
  disabled,
}) {
  const [displayValue, setDisplayValue] = useState(formatEINWithMask(value));
  const [originalValue, setOriginalValue] = useState(value);

  useEffect(() => {
    setDisplayValue(formatEINWithMask(value));
    setOriginalValue(value);
  }, [value]);

  const handleEINChange = (e) => {
    const inputValue = e.target.value;
    const cleanEIN = inputValue.replace(/\D/g, "");

    if (cleanEIN.length === 0) {
      const updatedValue = originalValue.slice(0, -1);
      setOriginalValue(updatedValue);
      handler && handler("ein", updatedValue);
    } else if (originalValue.length < 9) {
      const concatenatedValue = originalValue + cleanEIN;
      handler && handler("ein", concatenatedValue);
    }
  };

  return (
    <div className="position-relative">
      {label && (
        <span className="tenant-personal-details-title">
          {label}{" "}
          <span className="sign-up-imp-star">{required ? "*" : ""}</span>
          {TooltipTitle && (
            <Tooltip title={TooltipTitle}>
              <img src={helpIcon} alt="" className="cursor ps-2" />
            </Tooltip>
          )}
        </span>
      )}
      <div style={{ position: "relative" }}>
        <input
          name={name}
          type={type}
          value={displayValue}
          onChange={handleEINChange}
          className={errorMessage ? "error-input" : "form-control"}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete="off"
        />
      </div>
      {errorMessage && (
        <div className="text-danger fw-bold mt-2" style={{ fontSize: "12px" }}>
          {errorMessage}
        </div>
      )}
    </div>
  );
}

export default EINField;
