import React from "react";
import { useState, useEffect } from "react";
import { getPaymentStatus } from "network/landlord/Account";
import PaymentSuccessModal from "Modals/PaymentSuccessModal/PaymentSuccessModal";
import { postPayment } from "network/landlord/Account";
function PaymentSuccess() {
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [message, setMessage] = useState("");

  console.log("PaymentSuccess");
  const sessionId = new URLSearchParams(window.location.search).get(
    "session_id"
  );
  const invoiceNo = new URLSearchParams(window.location.search).get(
    "invoiceNo"
  );

  const fetchPaymentStatus = async () => {
    const response = await getPaymentStatus(sessionId);
    const data = response.message;
    console.log("PaymentSuccessIII", data);
    setPaymentStatus(data.paid);
    setPaymentSuccess(true);
    setMessage(
      "Your payment has been successfully processed again invoice No. " +
        invoiceNo
    );
  };

  useEffect(() => {
    if (sessionId) {
      fetchPaymentStatus();
      postPayment(sessionId, invoiceNo);
    }
  }, [sessionId]);

  console.log("PaymentSuccess", sessionId);
  return (
    <div>
      {paymentSuccess && (
        <PaymentSuccessModal
          paymentSuccess={paymentSuccess}
          success="Continue"
          onClose={() => setPaymentSuccess(false)} // Close the modal
          message={message}
          route="/payment" // Pass the route if needed
        />
      )}
    </div>
  );
}

export default PaymentSuccess;
