import { useEffect, useState } from "react";
import settingIconOrange from "assets/dots-vertical.png";
import { message } from "antd";
import "leaflet/dist/leaflet.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import UnitDeleteModal from "Modals/UnitDeleteModal/UnitDeleteModal";
import PropertyDetailsViewAddNewTaskModal from "Modals/PropertyDetailsViewAddNewTaskModal/PropertyDetailsViewAddNewTaskModal";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import EditTaskModal from "../EditTaskModal/EditTaskModal";
import AddPropertyEditModal from "Modals/AddPropertyEditModal/AddPropertyEditModal";
import AddPropertyConnectAccount from "Modals/AddPropertyConnectAccount/AddPropertyConnectAccount";
import { FilesTab, NotesTab } from "Components/TenantDetailsView/index.js";
import { OverviewTab } from "./OverviewTab";
import UnitTab from "./UnitTab";
import TasksTab from "./TasksTab.jsx";
import ConnectAccountTab from "./ConnectAccountTab.jsx";
import ReportsTab from "./ReportsTab.jsx";
import {
  CustomTabs,
  DeleteModal,
  FormModalLayout,
  RecordMenuPopup,
} from "Components/GeneralComponents";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteProperty, getProperty } from "network/landlord/property";
import TenantsTab from "./TenantsTab";
import { getRemainingUnits } from "network/landlord/property";

const PropertyDetailsView = () => {
  // States start
  const { id, tab } = UseUrlParamsHook();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [openModalTask, setOpenModalTask] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModalTask, setOpenEditModalTask] = useState(false);
  const [EditProperty, setEditProperty] = useState(false);
  const [update, setUpdate] = useState(false);
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteRender, setIsDeleteRender] = useState(false);
  const [propertyData, setPropertyData] = useState({});
  const [unitsData, setUnitsData] = useState([]);
  const [deleteMsg, setDeleteMsg] = useState("Are you sure you want to delete this property?");
  const [activeTab, setActiveTab] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [remainingUnits, setRemainingUnits] = useState(0);

  useEffect(() => {
    setActiveTab(tab || "1");
  }, [tab]);

  const onChange = (key) => {
    setActiveTab(key);
    searchParams.set("tab", key);
    navigate({ search: searchParams.toString() },{replace: true});
  };

  useEffect(() => {
    setIsLoading(true);
    getProperty(id).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        setPropertyData(res.message);
        setUnitsData(res.message.units);
      } else {
        console.log("Error in fetching property data");
      }
    });
  }, [id, update]);

  useEffect(() => {
    getRemainingUnits().then((res) => {
      if (res.apiCallStatus === "success") {
        // console.log(res.message.remainingPaidUnits);
        setRemainingUnits(res.message.remainingPaidUnits);
      }
    });
  }, []);

  const items = [
    { key: "1", label: "Overview", children: <OverviewTab id={id} propertyData={propertyData} isLoading={isLoading} /> },
    { key: "2", label: "Units", children: <UnitTab id={id} unitsData={unitsData} isLoading={isLoading} setUpdate={setUpdate} remainingUnits={remainingUnits} setRemainingUnits={setRemainingUnits} /> },
    { key: "3", label: "Tenants", children: <TenantsTab id={id} /> },
    { key: "4", label: "Tasks", children: <TasksTab id={id} /> },
    { key: "5", label: "Connect Account", children: <ConnectAccountTab id={id} /> },
    { key: "6", label: "Reports", children: <ReportsTab id={id} /> },
    { key: "7", label: "Files", children: <FilesTab category={"property"} id={id} title={propertyData?.title} /> },
    { key: "8", label: "Notes", children: <NotesTab category={"property"} id={id} /> }
  ]

  const [isDeleteDisabled, setIsDeleteDisabled] = useState(false);
  const onCloseEditModalTask = () => {
    setOpenEditModalTask(false);
  };
  const onOpenEditModalProperty = () => {
    setEditProperty(true);
  };
  const onCloseEditModalProperty = () => {
    setEditProperty(false);
  };

  const onCloseModalWorkOrder = () => {
    setOpenAccountModal(false);
  };
  // Modal Function

  const onCloseModalTask = () => {
    setOpenModalTask(false);
  };

  const onOpenModal = () => {
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleIconClickCustom = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  // handle delete property
  useEffect(() => {
    if (deleteId) {
      setIsLoading(true);
      deleteProperty(deleteId).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          setOpenDeleteModal(false);
          message.success("Property Deleted Successfully");
          setDeleteId("");
          navigate("/properties-dashboard");
        } else {
          setDeleteMsg(res.message);
          setIsDeleteDisabled(true);
        }
      });
      // setOpenDeleteModal(true);
    }
  }, [isDeleteRender]);

  return (
    <>
      {openAccountModal && (
        <AddPropertyConnectAccount
          onOpen={onOpenModal}
          onClose={onCloseModalWorkOrder}
          id={id}
          title={propertyData?.title}
        />
      )}

      <FormModalLayout
        title={"Edit Property"}
        isOpen={EditProperty}
        onClose={onCloseEditModalProperty}
      >
        <AddPropertyEditModal
          id={id}
          onClose={() => {
            onCloseEditModalProperty();
            setUpdate(true);
          }}
        />
      </FormModalLayout>

      {openModalTask && (
        <PropertyDetailsViewAddNewTaskModal
          id={id}
          onOpen={onOpenModal}
          onClose={onCloseModalTask}
        />
      )}

      {openEditModalTask && (
        <EditTaskModal id={taskId} onClose={onCloseEditModalTask} />
      )}

      {openModal && <UnitDeleteModal delId={deleteId} onClose={onCloseModal} />}

      <DeleteModal
        isOpen={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
          setDeleteId("");
          setIsDeleteDisabled(false);
          setDeleteMsg("Are you sure you want to delete this property?");
        }}
        message={deleteMsg}
        deleteCall={() => setIsDeleteRender(!isDeleteRender)}
        isLoading={isLoading}
        disableBtn={isDeleteDisabled}
      />

      <div className="container-fluid bg-white p-3">
        <div className="row">
          <div className="col-md-12 text-end">
            <span className="position-relative">
              <img
                onClick={handleIconClickCustom}
                src={settingIconOrange}
                alt="Action Buttons"
                className="cursor"
              />
              <RecordMenuPopup
                isOpen={isDropdownOpen}
                onClose={() => setDropdownOpen(false)}
                handler={{
                  handleEdit: () => {
                    onOpenEditModalProperty();
                  },
                  handleDeleteRecord: () => {
                    onOpenDeleteModal();
                    setDeleteId(id);
                  },
                }}
                fields={{
                  edit: true,
                  delete: true,
                }}
              />
            </span>
          </div>
        </div>
        {activeTab && <CustomTabs
          items={items}
          defaultActiveKey={activeTab}
          onChange={onChange}
        />}
      </div>
    </>
  );
};

export default PropertyDetailsView;
