import { useEffect, useState } from "react";
import { message } from "antd";
import infoIcon from "assets/info.png";
import { useNavigate } from "react-router-dom";
import ProfileUploader from "Components/ProfileUploader/ProfileUploader";
import { usaStates } from "utils/dropdownData";
import {
  InputField,
  PhoneNumberField,
  SelectField,
  SubmitButton,
  SuccessModal,
} from "Components/GeneralComponents";
import { createLandlordUser, getUserRole } from "network/landlord/setting";
import { LandlordUserFormData } from "utils/FormDataStructure";
import { validateLandlordAddUserInfo } from "utils/validations";

const SettingUserInfoNew = () => {
  // States start
  const [loader, setLoader] = useState(false);
  const [ProfileImages, setProfileImages] = useState("");
  const [errors, setErrors] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [form, setForm] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    roles: [],
  });

  useEffect(() => {
    getUserRole().then((res) => {
      if (res.apiCallStatus === "success") {
        setUserRoles(res.message);
      }
    });
  }, []);

  const handleChange = (fieldName, value) => {
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  const navigate = useNavigate();

  const onCloseModal = () => {
    setOpenModal(false);
    navigate("../user-info");
  };

  const CreateRole = () => {
    const formdata = LandlordUserFormData(form, ProfileImages);

    const newErrors = validateLandlordAddUserInfo(form);

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoader(true);
    createLandlordUser(formdata).then((res) => {
      setLoader(false);
      if (res.apiCallStatus === "success") {
        message.success("User Added Successfully");
        setOpenModal(true);
        // navigate("../user-info");
      } else {
        message.error(res.error.message);
      }
    });
  };
  // Add User End

  const handlePrev = () => {
    navigate("../user-info");
  };

  return (
    <div className="container-fluid bg-white p-3 ">
      {openModal && (
        <SuccessModal
          heading={"User Added Successfully"}
          title={
            "User has been added successfully. You can now view the user's details."
          }
          handler={onCloseModal}
          success={"Go to User Info"}
          onClose={onCloseModal}
        />
      )}
      <p className="heading pt-4 pb-5 ps-4">ADD USER DETAILS</p>
      <div className="row mt-3">
        <p
          className="mb-0"
          style={{
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: "Montserrat",
          }}
        >
          User's Photo
          <span className="sign-up-imp-star" style={{ fontSize: "16px" }}>
            {" "}
            *
          </span>
        </p>
        <p className="normal-grey-text">
          This will be displayed on user's profile.
        </p>
        <div className="photo-container d-flex align-items-start  gap-4">
          <div className="dragger">
            <ProfileUploader
              setProfileImages={setProfileImages}
              ProfileImages={ProfileImages}
            />
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <InputField
            handler={(e) => handleChange("firstName", e.target.value)}
            value={form.firstName}
            type="text"
            name={"firstName"}
            placeholder={"First Name"}
            errorMessage={errors.firstName}
            label="First Name"
            required={true}
          />
        </div>
        <div className="col-md-4">
          <InputField
            handler={(e) => handleChange("middleName", e.target.value)}
            value={form.middleName}
            type="text"
            name={"middleName"}
            placeholder={"Middle Name"}
            errorMessage={errors.middleName}
            label="Middle Name"
            required={false}
          />
        </div>
        <div className="col-md-4">
          <InputField
            handler={(e) => handleChange("lastName", e.target.value)}
            value={form.lastName}
            type="text"
            name={"lastName"}
            placeholder={"Last Name"}
            errorMessage={errors.lastName}
            label="Last Name"
            required={true}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6">
          <InputField
            handler={(e) => handleChange("email", e.target.value)}
            value={form.email}
            type="email"
            name={"email"}
            placeholder={"Email"}
            errorMessage={errors.email}
            label="Email"
            required={true}
          />
        </div>
        <div className="col-md-6">
          <PhoneNumberField
            label="Phone"
            placeholder="Phone"
            value={form.phone}
            handler={(e) => {
              handleChange("phone", e);
            }}
            required={true}
            errorMessage={errors.phone}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6">
          <InputField
            handler={(e) => handleChange("address", e.target.value)}
            value={form.address}
            type="text"
            name={"address"}
            placeholder={"Address"}
            errorMessage={errors.address}
            label="Address"
            required={true}
          />
        </div>
        <div className="col-md-6">
          <InputField
            handler={(e) => handleChange("city", e.target.value)}
            value={form.city}
            type="text"
            name={"city"}
            placeholder={"City"}
            errorMessage={errors.city}
            label="City"
            required={true}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6">
          <SelectField
            handler={(e) => handleChange("state", e)}
            value={form.state}
            placeholder={"Select State"}
            options={usaStates}
            label="State"
            required={true}
            errorMessage={errors.state}
          />
        </div>
        <div className="col-md-6">
          <SelectField
            handler={(e) => handleChange("country", e)}
            value={"USA"}
            placeholder={"Select Country"}
            options={[
              {
                value: "USA",
                label: "USA",
              },
            ]}
            label="Country"
            required={true}
            errorMessage={errors.country}
            disabled={true}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6">
          <SelectField
            handler={(e) => handleChange("roles", e)}
            value={form.roles}
            mode={"multiple"}
            placeholder={"Select Roles"}
            options={userRoles.map((e) => {
              return { value: e._id, label: e.role };
            })}
            label="User Roles"
            required={true}
            errorMessage={errors.roles}
          />
        </div>
        <div className="col-md-6 d-flex align-items-center mt-3">
          <span className="property-details-input-title">
            <img src={infoIcon} alt="" className="cursor info-icon me-2" />
            What parts of DigitalTenant they can access.
          </span>
        </div>
      </div>
      <div className="setting-btn add-user-details-btn row mt-3">
        <div className="col-md-12 my-5 d-flex gap-4">
          <button className="cancel-btn col-md-6 w-50" onClick={handlePrev}>
            Cancel
          </button>
          <SubmitButton handler={CreateRole} isLoading={loader}>
            Send Invite
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default SettingUserInfoNew;
