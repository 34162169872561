import React from "react";
import { Link } from "react-router-dom";

const settingCard = ({ to, isActive, onClick, icon, title, description }) => {
  return (
    <Link className="text-dark" to={to}>
      <div
        onClick={onClick}
        className={
          isActive
            ? "active-setting-tab setting-navbar-container border-bottom border-end pt-3"
            : "setting-navbar-container border-bottom border-end pt-3"
        }
        style={{ width: "362px" }}
      >
        <div className="setting-navbar d-flex gap-3 ">
          <div className="setting-navbar-img">
            <img src={icon} alt="" />
          </div>
          <div className="setting-navbar-text">
            <p className="mb-0 setting-nav-heading">{title}</p>
            <p className="setting-nav-description mb-3">{description}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default settingCard;
