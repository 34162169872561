import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import TenantDetailsView from "Components/TenantDetailsView/TenantDetailsView";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";

const TenantDetailsViewPage = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Tenant Details"}
        pop={true}
      />
      <TenantDetailsView />
    </div>
  );
};

export default TenantDetailsViewPage;
