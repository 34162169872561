import React, { useState, useEffect } from "react";
import { Table, ConfigProvider, Select } from "antd";
import { CSVLink } from "react-csv";
import { useNavigate, Link } from "react-router-dom";
import checkMark from "assets/check-mark.png";
import settingBtn from "assets/more-vertical.png";
import viewIcon from "assets/Icon.png";
import editIcon from "assets/edit-05.png";
import deleteIcon from "assets/trash-01.png";
import trashIconWhite from "assets/trash-icon-white.png";
import dueDot from "assets/_red-Dot.png";
import dot from "assets/_Dot.png";
import settingIcon from "assets/three-dots.png";
import completeIcon from "assets/calendar-check-01.png";
import inActiveDot from "assets/_inActive-Dot.png";
import { usePDF } from "react-to-pdf";
import oval from "assets/Oval.png";
import postUpdateIcon from "assets/edit-3.png";
import propertyImage from "assets/office-skyscrapers-business-district 2.png";
import searchIcon from "assets/search.png";
import CompleteTask from "Helpers/CompleteTask";
import EditTaskModal from "Components/EditTaskModal/EditTaskModal";
import DeleteModal from "Modals/DeleteModal/DeleteModal";
import { fetchPropertyReport, getProperty } from "network/landlord/property";
import { useSelector } from "react-redux";
import Loader from "Helpers/Loader";

const ReportsTab = ({ url, id }) => {
  const navigate = useNavigate();
  const [selectedReport, setSelectedReport] = useState("tenant-reports");
  const [exportDropdown, setExportDropdown] = useState(false);
  const [key, setKey] = useState(null);
  const [selectedTableItem, setSelectedTableItem] = useState([]);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [openEditModalTask, setOpenEditModalTask] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openModalPostUpdate, setOpenModalPostUpdate] = useState(false);
  const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });
  const [reportData, setReportData] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const search = useSelector((state) => {
    return state.Search.value;
  });
  //
  useEffect(() => {
    setIsLoading(true);
    getProperty(id).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        setData(
          res.message.units
            .filter((data) =>
              data.name.toLowerCase().includes(search.toLowerCase())
            )
            .map((e, index) => ({
              key: index + 1,
              id: e.id,
              unitName: e.name,
              img: e.images[0],
              bed: e.bedroom,
              bath: e.bathroom,
              sqft: e.area,
              unitDetails: "+1 (555) 098-7654",
              rentAmount: e.rent_amount,
              status: "Month-to-Month",
              futureStatus: "Not Rented",
            }))
        );
      } else {
        console.log("Error in fetching property data");
      }
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchPropertyReport(url, id).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        setReportData(
          res.message.map((e, index) => ({
            key: index + 1,
            id: e.id,
            title: e?.title,
            img: e?.image,
            assigned: e?.assignedTo?.map((e) => e.firstName).join(", "),
            assignedToMe: localStorage.getItem("name"),
            due: [new Date(e.dueDate).toLocaleDateString(), e.priority],
            related: e?.property?.title,
            status: e?.status,
          }))
        );
      } else {
        console.log(res, "error");
      }
    });
  }, []);

  const invoiceReportData = reportData?.map((e) => ({
    key: "1",
    invoice_no: e.data?.invoice_no,
    name: e.data?.tenant?.firstName,
    start_date: new Date(e.data?.start_date).toLocaleDateString(),
    end_date: new Date(e.data?.end_date).toLocaleDateString(),
    related_to: e.data?.property_id?.title,
    invoice_date: e.data?.invoice_date || "Not set",
    status: e.data?.status,
  }));
  const tenantReportData = reportData?.map((e) => ({
    key: "1",
    name: `${e.data?.firstName} ${e.data?.lastName}`,
    property: e.data?.address,
    email: e.data?.email,
    status: "",
  }));
  const taskReportData = reportData
    ?.filter((e) =>
      e.data.property.title?.toLowerCase().includes(search.toLowerCase())
    )
    .map((e) => ({
      key: e.id,
      title: e?.data?.title,
      img: e.data?.image,
      assigned: e.data?.assignedTo.map((e) => e.data?.firstName).join(", "),
      assignedToMe: localStorage?.getItem("name"),
      due:
        !e.data?.due && !e.data?.priority
          ? "Not Set"
          : [new Date(e.data?.dueDate)?.toLocaleDateString(), e.data?.priority],
      related: e.data?.property?.title,
      status: e.data?.status || "N/A",
    }));
  const handleIconClick = (result) => {
    // Toggle the dropdownOpen state
    const filterData = reportData.filter((item) => {
      return item.key === result;
    });
    setKey(filterData.key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const onOpenEditModalTask = () => {
    setOpenEditModalTask(true);
  };
  const onCloseEditModalTask = () => {
    setOpenEditModalTask(false);
  };

  const handleReportChange = (selectedOption) => {
    setSelectedReport(selectedOption);
  };

  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleTaskComplete = (id) => {
    const { completeStatus } = CompleteTask(id);
    completeStatus();
  };

  const onOpenModalPostUpdate = () => {
    setOpenModalPostUpdate(true);
  };

  const reportsColumns = [
    {
      title: "Property Name",
      dataIndex: "propertyName",
      render: (text) => (
        <span>
          <img src={propertyImage} alt="" className="mh_40 mw_40" />
          {text}
        </span>
      ),
    },
    {
      title: "Phone No",
      dataIndex: "phone",
      render: (text) => (
        <>
          {" "}
          <span className="phone">{text}</span>
        </>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text, type) => (
        <>
          {type.key === "1" && (
            <span className="type-text-single-family rounded-4">
              Single family
            </span>
          )}
          {type.key === "2" && (
            <span className="type-text-multi-commercial rounded-4">
              Multi Commercial
            </span>
          )}
          {type.key === "3" && (
            <span className="type-text-multi-family rounded-4">
              Multi Commercial
            </span>
          )}
          {type.key === "4" && (
            <span className="type-text-single-family rounded-4">
              Single family
            </span>
          )}
        </>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Rent Amount",
      dataIndex: "rent",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Tenant",
      dataIndex: "tenant",
      render: (text) => (
        <>
          <span>{text}</span>
          <br />
          <img src={checkMark} alt="" />{" "}
          <span className="current-tenant-text">Current Tenant</span>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div className="task-table-setting-container position-relative cursor">
            <img
              src={settingBtn}
              alt=""
              onClick={() => handleIconClick(setting.key)}
            />
            {setting.key === key && (
              <div className="task-table-setting-dropdown-prospect bg-white box-shadow">
                <ul className="p-0 d-flex flex-column gap-3">
                  <li
                    onClick={() => {
                      navigate("");
                    }}
                    className="list-style-none table-setting-dropdown-menu"
                  >
                    {" "}
                    <img src={viewIcon} alt="" /> View
                  </li>
                  <li className="list-style-none table-setting-dropdown-menu">
                    {" "}
                    <img src={editIcon} alt="" /> Edit
                  </li>
                  <li className="list-style-none table-setting-dropdown-menu">
                    {" "}
                    <img src={deleteIcon} alt="" /> Delete
                  </li>
                </ul>
              </div>
            )}
          </div>
        </>
      ),
    },
  ];

  const invoiceColumn = [
    {
      title: "Invoice No",
      dataIndex: "invoice_no",
      render: (text) => (
        <>
          <Link to="">
            <span className="property-table-name-text">{text}</span>
          </Link>
        </>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text) => (
        <>
          <Link to="">
            <span className="property-table-name-text">{text}</span>
          </Link>
        </>
      ),
    },
    {
      title: "Start date",
      dataIndex: "start_date",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "End date",
      dataIndex: "end_date",
      render: (text) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: "Related to",
      dataIndex: "related_to",
      render: (text) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
      render: (text) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, status) => (
        <>
          <span
            className={
              status.key === "2"
                ? "tenant-report-due-bar"
                : "tenant-report-active-bar"
            }
          >
            <img
              src={status.key === "2" ? dueDot : dot}
              alt=""
              className="me-1"
            />
            {text}
          </span>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div className="task-table-setting-container position-relative cursor">
            <img
              src={settingIcon}
              alt=""
              onClick={() => handleIconClick(setting.key)}
            />
            {setting.key === key && (
              <div className="task-table-setting-dropdown-prospect bg-white box-shadow">
                <ul className="p-0 d-flex flex-column gap-3">
                  <Link>
                    <li className="list-style-none table-setting-dropdown-menu">
                      {" "}
                      <img src={viewIcon} alt="" /> View
                    </li>
                  </Link>
                  <Link>
                    <li className="list-style-none table-setting-dropdown-menu">
                      {" "}
                      <img src={editIcon} alt="" /> Edit
                    </li>
                  </Link>
                  <Link>
                    <li className="list-style-none table-setting-dropdown-menu">
                      {" "}
                      <img src={completeIcon} alt="" /> Complete
                    </li>
                  </Link>
                  <Link>
                    <li className="list-style-none table-setting-dropdown-menu">
                      {" "}
                      <img src={deleteIcon} alt="" /> Delete
                    </li>
                  </Link>
                </ul>
              </div>
            )}
          </div>
        </>
      ),
    },
  ];

  const tenantColumn = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Properties",
      dataIndex: "property",
      render: (text) => (
        <>
          {" "}
          <span>{text}</span>
        </>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      render: (text, type) => (
        <>
          <span>{text} </span>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, status) => (
        <>
          <span
            className={
              status.key === "2"
                ? "tenant-report-in-active-bar"
                : "tenant-report-active-bar"
            }
          >
            <img
              src={status.key === "2" ? inActiveDot : dot}
              alt=""
              className="me-1"
            />
            Active
          </span>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div className="task-table-setting-container position-relative cursor">
            <img
              src={settingIcon}
              alt=""
              onClick={() => handleIconClick(setting.key)}
            />
            {setting.key === key && (
              <div className="task-table-setting-dropdown-prospect bg-white box-shadow">
                <ul className="p-0 d-flex flex-column gap-3">
                  <Link>
                    <li className="list-style-none table-setting-dropdown-menu">
                      {" "}
                      <img src={viewIcon} alt="" /> View
                    </li>
                  </Link>
                  <Link>
                    <li className="list-style-none table-setting-dropdown-menu">
                      {" "}
                      <img src={editIcon} alt="" /> Edit
                    </li>
                  </Link>
                  <Link>
                    <li className="list-style-none table-setting-dropdown-menu">
                      {" "}
                      <img src={completeIcon} alt="" /> Complete
                    </li>
                  </Link>
                  <Link>
                    <li className="list-style-none table-setting-dropdown-menu">
                      {" "}
                      <img src={deleteIcon} alt="" /> Delete
                    </li>
                  </Link>
                </ul>
              </div>
            )}
          </div>
        </>
      ),
    },
  ];

  const taskColumn = [
    {
      title: "Title",
      dataIndex: "title",
      render: (text, task) => (
        <Link
          to={`/task-details?id=${task.key}`}
          className="all-task-table-title-text"
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Assigned To",
      dataIndex: "assigned",
      render: (text, assigned) => (
        <>
          {" "}
          <img className="me-2" src={oval} alt="" />
          {assigned.assignedToMe ? (
            <span>
              {text}
              {text ? ", " : ""}
              {assigned.assignedToMe} (YOU)
            </span>
          ) : (
            <span>{text}</span>
          )}
        </>
      ),
    },
    {
      title: "Due At",
      dataIndex: "due",
      render: (text) => (
        <>
          <div className="assign-date-container">
            <div
              className={
                text[1] === "High"
                  ? "priority-text bg-error"
                  : text[1] === "Low"
                  ? "priority-text bg-grey"
                  : text[1] === "Medium"
                  ? "priority-text bg-yellow"
                  : text[1] === "Very High"
                  ? "priority-text bg-error"
                  : ""
              }
            >
              <span>{`${text[1]} Priority`}</span>
            </div>
            <br />
            <span className="assign-to-date">{text[0]}</span>
          </div>
        </>
      ),
    },
    {
      title: "Related To",
      dataIndex: "related",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <>
          <span
            className={
              status === "Completed"
                ? "completed-status text-white"
                : status === "In Progress"
                ? "in-progress-status text-white"
                : status === "Not Started"
                ? "not-started-status text-dark"
                : ""
            }
          >
            {status}
          </span>{" "}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div className="task-table-setting-container position-relative cursor">
            <img
              src={settingIcon}
              alt=""
              onClick={() => handleIconClick(setting.key)}
            />
            {setting.key === key && (
              <div className="task-table-setting-dropdown-prospect bg-white box-shadow">
                <ul className="p-0 d-flex flex-column gap-3">
                  <Link to={`/task-details?id=${setting.key}`}>
                    <li className="list-style-none table-setting-dropdown-menu">
                      {" "}
                      <img src={viewIcon} alt="" /> View
                    </li>
                  </Link>
                  <li
                    onClick={() => {
                      onOpenEditModalTask();
                      setTaskId(setting.key);
                    }}
                    className="list-style-none table-setting-dropdown-menu"
                  >
                    {" "}
                    <img src={editIcon} alt="" /> Edit
                  </li>
                  <li
                    onClick={() => handleTaskComplete(setting.key)}
                    className="list-style-none table-setting-dropdown-menu"
                  >
                    {" "}
                    <img src={completeIcon} alt="" /> Complete
                  </li>
                  <Link>
                    <li
                      onClick={() => {
                        onOpenModalPostUpdate();
                        setTaskId(setting.key);
                      }}
                      className="list-style-none table-setting-dropdown-menu"
                    >
                      {" "}
                      <img src={postUpdateIcon} alt="" /> Post an Update
                    </li>
                  </Link>
                  <Link
                    onClick={() => {
                      onOpenDeleteModal();
                      setDeleteId(setting.key);
                    }}
                  >
                    <li className="list-style-none table-setting-dropdown-menu">
                      {" "}
                      <img src={deleteIcon} alt="" /> Delete
                    </li>
                  </Link>
                </ul>
              </div>
            )}
          </div>
        </>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedTableItem(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {openEditModalTask && (
            <EditTaskModal id={taskId} onClose={onCloseEditModalTask} />
          )}
          {openDeleteModal === true ? (
            <DeleteModal
              onClose={onCloseDeleteModal}
              //   component={component}
              //   fetchFun={fetchDeleteFun}
              route={`property-details-view?id=${id}`}
              deleteBtnText={`Delete`}
              delId={deleteId}
            />
          ) : (
            ""
          )}
          {
            <div className="container-fluid bg-white p-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="task-search-input position-relative">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control search-form-control-task"
                    />
                    <div className="search-icon-task">
                      <img src={searchIcon} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <div className="report-type-text-container mt-3">
                    <span className="report-type-text ">Report Type</span>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">
                  <div className="report-type-box d-flex gap-3 align-items-center">
                    <Select
                      defaultValue={{ label: "Select Reports" }}
                      style={{
                        width: "100%",
                        height: 50,
                      }}
                      onChange={handleReportChange}
                      options={[
                        {
                          label: "Tenant Reports",
                          value: `tenant-reports`,
                        },
                        {
                          label: "Invoice Reports",
                          value: `invoice-reports`,
                        },
                        {
                          label: "Task Reports",
                          value: `task-reports`,
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="col-md-8 text-end">
                  <div className="report-export-buttons d-flex justify-content-end align-items-center gap-3">
                    <div className="export-btn-container position-relative">
                      <button
                        onClick={() => {
                          setExportDropdown(!exportDropdown);
                        }}
                        className="export-btn"
                      >
                        Export as{" "}
                        <span className="">
                          <svg
                            width={18}
                            height={18}
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="m6 9 6 6 6-6" />
                          </svg>
                        </span>
                      </button>
                      <div
                        className={
                          exportDropdown === true
                            ? "export-dropdown box-shadow export-dropdown-show"
                            : "export-dropdown box-shadow"
                        }
                      >
                        <ul className="d-flex flex-column justify-content-start text-start gap-3 p-0 mb-0">
                          <li className="list-style-none">Excel</li>
                          <li
                            onClick={() => toPDF()}
                            className="list-style-none cursor"
                          >
                            PDF
                          </li>
                        </ul>
                      </div>
                    </div>
                    <CSVLink
                      data={data}
                      className="modal-save-btn property-details-report-download-btn"
                    >
                      Download
                    </CSVLink>
                  </div>
                </div>
              </div>
              {selectedTableItem.length >= 1 && (
                <div className="table-delete-icon mt-3">
                  <button className="table-delete-btn next-btn-main">
                    <img src={trashIconWhite} />
                    Delete
                  </button>
                </div>
              )}
              <div className="report-table-container mt-3" ref={targetRef}>
                <ConfigProvider
                  theme={{
                    components: {
                      Table: {
                        colorTextHeading: "#667085",
                        colorText: "#667085",
                        fontSize: 14,
                        fontWeightStrong: 500,
                        fontFamily: "Montserrat",
                      },
                      Pagination: {
                        itemActiveBg: "#EF6B3E",
                      },
                      Checkbox: {
                        colorPrimary: "#EF6B3E",
                        colorPrimaryHover: "#EF6B3E",
                      },
                    },
                  }}
                >
                  <Table
                    pagination={false}
                    className="scroll-remove scroll-responsive-tablet property-reports-table"
                    rowSelection={{
                      type: selectionType,
                      ...rowSelection,
                    }}
                    columns={
                      selectedReport === "invoice-reports"
                        ? invoiceColumn
                        : selectedReport === "tenant-reports"
                        ? tenantColumn
                        : selectedReport === "task-reports"
                        ? taskColumn
                        : reportsColumns
                    }
                    dataSource={
                      selectedReport === "invoice-reports"
                        ? invoiceReportData
                        : selectedReport === "tenant-reports"
                        ? tenantReportData
                        : selectedReport === "task-reports"
                        ? taskReportData
                        : reportData
                    }
                  />
                </ConfigProvider>
              </div>
            </div>
          }
        </>
      )}
    </>
  );
};

export default ReportsTab;
