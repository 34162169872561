import React, { useState, useEffect } from "react";
import trashIconWhite from "assets/trash-icon-white.png";
import { useNavigate, Link } from "react-router-dom";
import AddVendorsDetailsEditModal from "Modals/EditVendorsDetailsModal/EditVendorsDetailsModal";
import { FilterValue } from "Store/Slices/FilterSlice";
import { useDispatch } from "react-redux";
import { formatPhoneNumber } from "utils";
import * as Component from "Components/GeneralComponents";
import {
  getVendors,
  deleteVendor,
  deleteMultipleVendors,
} from "network/landlord/vendor";
import { message } from "antd";
import * as Icon from "assets/icons";

const AllVendors = () => {
  // States start
  const [selectionType, setSelectionType] = useState("checkbox");
  const [selectedTableItem, setSelectedTableItem] = useState("");
  const [DeleteId, setDeleteId] = useState("");
  const [openEditModalTask, setOpenEditModalTask] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [key, setKey] = useState([]);
  const [update, setUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [filteredVendors, setFilteredVendors] = useState([]);
  const [isRerender, setIsRerender] = useState(false);
  const [isDeleteVendorRender, setIsDeleteVendorRender] = useState(false);
  const [isDeleteVendorsRender, setIsDeleteVendorsRender] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isDeleteVendorsModal, setIsDeleteVendorsModal] = useState(false);
  const [forceDeleteMsg, setForceDeleteMsg] = useState(
    "Are you sure you want to delete this vendor?"
  );
  const [forceVendorsDeleteMsg, setForceVendorsDeleteMsg] = useState(
    "Are you sure you want to delete this vendors?"
  );
  const [force, setForce] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  // States end

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    range: "",
    service: "",
  });

  const handleResetFilter = () => {
    setFilters({
      search: "",
      fromDate: "",
      toDate: "",
      range: "",
      property: "",
    });
  };

  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const onCloseDeleteModal = () => {
    // setOpenDeleteModal(false);
    setOpenDeleteModal(false);
    setForce("");
    setForceDeleteMsg("Are you sure you want to delete this vendor?");
  };
  const onCloseDeleteVendorsModal = () => {
    setIsDeleteVendorsModal(false);
    setForce("");
    setForceVendorsDeleteMsg("Are you sure you want to delete this vendors?");
  };
  const onCloseEditModalTask = () => {
    setOpenEditModalTask(false);
  };
  // Data Table Functions
  const handleIconClick = (result) => {
    // Toggle the dropdownOpen state
    const filterData = vendors.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const columns = [
    {
      title: "Vendor Name",
      dataIndex: "vendorName",
      render: (text, vendorName) => (
        <>
          <Link
            to={`/vendor-detail-view?id=${vendorName.key}`}
            className="d-flex align-items-center gap-2"
          >
            <div
              className="d-flex align-items-center justify-content-center flex-shrink-0"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                overflow: "hidden",
                border: `0.5px solid #c9c9c9`,
                position: "relative",
              }}
            >
              {vendorName.img ? (
                <img
                  src={vendorName.img}
                  alt="task"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <Component.FirstCharacterIcon name={text} />
              )}
            </div>
            <div className="">{text}</div>
          </Link>
        </>
      ),
    },

    {
      title: "Company Name",
      dataIndex: "companyname",
      render: (text, email) => (
        <>
          <span className="tenants_table_email_text">{text}</span>
        </>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      render: (text, email) => (
        <>
          <span className="tenants_table_email_text">{text}</span>
        </>
      ),
    },
    {
      title: "Phone No",
      dataIndex: "phone",
      render: (text) => (
        <>
          {" "}
          <span className="tenants_table_phone_text phone">
            {formatPhoneNumber(text)}
          </span>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <Icon.VerticalElipsisIcon width={23} height={23} />
            <Component.RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleDeleteRecord: () => {
                  onOpenDeleteModal();
                  setDeleteId(setting.key);
                },
                handleView: () =>
                  navigate(`/vendor-detail-view?id=${setting.key}`),
                handleEdit: () =>
                  navigate(`/edit-vendor-details/${setting.key}`),
              }}
              fields={{
                view: true,
                edit: true,
                delete: true,
              }}
            />
          </div>
        </>
      ),
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTableItem([...selectedRowKeys]);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  // Get Vendors
  const resetFilter = () => {
    dispatch(FilterValue({ reset: "reset" }));
  };

  // get vendors with filter
  useEffect(() => {
    setIsLoading(true);
    // console.log("filter >>>>>", filters);
    getVendors(filters).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        setVendors(
          res.message.data.map((e) => ({
            key: e._id,
            vendorName: e.name.trim(),
            img: e?.profileImage,
            email: e.email,
            phone: e.phone,
            companyname: e.company,
            balance: "$0.1200",
          }))
        );
      }
    });
  }, [isRerender]);

  // handle search
  useEffect(() => {
    if (filters.search) {
      setFilteredVendors(
        vendors.filter((item) => {
          if (
            item.vendorName.toLowerCase().includes(filters.search.toLowerCase())
          )
            return item;
          if (
            item.companyname
              .toLowerCase()
              .includes(filters.search.toLowerCase())
          )
            return item;
          if (item.email.toLowerCase().includes(filters.search.toLowerCase()))
            return item;
          if (item.phone.includes(filters.search)) return item;
          // if (item.balance.toLowerCase().includes(search.toLowerCase()))
          //   return item;
        })
      );
    } else {
      setFilteredVendors(vendors);
    }
  }, [filters.search, vendors]);

  // handle delete vendor
  useEffect(() => {
    if (DeleteId) {
      setIsLoader(true);
      deleteVendor(DeleteId, force).then((res) => {
        setIsLoader(false);
        if (res.apiCallStatus === "success") {
          onCloseDeleteModal();
          message.success("Vendor Deleted Successfully");
          setIsRerender(!isRerender);
          setDeleteId("");
        } else if (res.apiCallStatus === "error") {
          setForce("true");
          setForceDeleteMsg(res.message);
        }
      });
    }
  }, [isDeleteVendorRender]);

  // handle multiple delete vendors
  useEffect(() => {
    if (selectedTableItem.length > 0) {
      console.log("selectedTableItem", selectedTableItem);
      const data = { ids: selectedTableItem };
      setIsLoader(true);
      deleteMultipleVendors(data, force).then((res) => {
        setIsLoader(false);
        if (res.apiCallStatus === "success") {
          onCloseDeleteVendorsModal();
          message.success("Vendors Deleted Successfully");
          setIsRerender(!isRerender);
          setSelectedTableItem([]);
        } else if (res.apiCallStatus === "error") {
          setForce("true");
          setForceVendorsDeleteMsg(res.message);
        }
      });
    }
  }, [isDeleteVendorsRender]);

  return (
    <>
      <Component.DeleteModal
        isOpen={openDeleteModal}
        onClose={onCloseDeleteModal}
        message={forceDeleteMsg}
        deleteCall={() => setIsDeleteVendorRender(!isDeleteVendorRender)}
        isLoading={isLoader}
        isForceDelete={force}
      />

      <Component.DeleteModal
        isOpen={isDeleteVendorsModal}
        onClose={onCloseDeleteVendorsModal}
        message={forceVendorsDeleteMsg}
        deleteCall={() => setIsDeleteVendorsRender(!isDeleteVendorsRender)}
        isLoading={isLoader}
        isForceDelete={force}
      />

      {openEditModalTask && (
        <AddVendorsDetailsEditModal
          id={taskId}
          setUpdate={setUpdate}
          onClose={onCloseEditModalTask}
        />
      )}
      <div className="container-fluid bg-white p-3">
        <Component.FilterRow
          filter={filters}
          setFilter={setFilters}
          btnTitle={"Add New Vendor"}
          btnHandler={() => navigate("/add-vendor-details")}
          handleSubmit={() => setIsRerender(!isRerender)}
          // showCalendarFilter={true}
          handleReset={handleResetFilter}
          filterList={{
            range: true,
            date: true,
            service: true,
          }}
        />
        <div className="row mt-3">
          <div className="col-md-12">
            {selectedTableItem.length >= 1 && (
              <div className="table-delete-icon mb-3">
                <button
                  onClick={() => setIsDeleteVendorsModal(true)}
                  className="table-delete-btn next-btn-main"
                >
                  <img src={trashIconWhite} alt="" />
                  Delete
                </button>
              </div>
            )}
            <Component.CustomTable
              pagination={true}
              columns={columns}
              dataSource={filteredVendors}
              loading={isLoading}
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AllVendors;
