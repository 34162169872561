import React, { useState } from "react";
import "../../style/dropdown.css";

const DropdownLayout = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div
        className={`container ${
          isOpen ? "container-shadow" : "collapse-container"
        }`}
        style={{ cursor: "pointer" }}
      >
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={toggleOpen}
        >
          <h6 style={{ fontSize: "16px", color: "#344054" }}>{title}</h6>
          <div onClick={toggleOpen} style={{ width: "16px" }}>
            {isOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m4.5 15.75 7.5-7.5 7.5 7.5"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
              </svg>
            )}
          </div>
        </div>
        {isOpen && children}
      </div>
    </div>
  );
};

export default DropdownLayout;
