import { useState } from "react";
import searchIcon from "assets/New icons (7).png";
import CustomIconOpen from "assets/minus-circle.png";
import CustomIconClose from "assets/plus-circle.png";
import { Collapse } from "antd";

const Help = () => {
  const text = `
        Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.
    `;
  const items = [
    {
      key: "1",
      label: "Is there a free trial available?",
      children: <p>{text}</p>,
    },
    {
      key: "2",
      label: "Can I change my plan later?",
      children: <p>{text}</p>,
    },
    {
      key: "3",
      label: "What is your cancellation policy?",
      children: <p>{text}</p>,
    },
  ];
  const text2 = `
        Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.
    `;
  const items2 = [
    {
      key: "1",
      label: "Is there a free trial available?",
      children: <p>{text2}</p>,
    },
    {
      key: "2",
      label: "Can I change my plan later?",
      children: <p>{text2}</p>,
    },
    {
      key: "3",
      label: "What is your cancellation policy?",
      children: <p>{text2}</p>,
    },
  ];
  const text3 = `
        Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.
    `;
  const items3 = [
    {
      key: "1",
      label: "Is there a free trial available?",
      children: <p>{text3}</p>,
    },
    {
      key: "2",
      label: "Can I change my plan later?",
      children: <p>{text3}</p>,
    },
    {
      key: "3",
      label: "What is your cancellation policy?",
      children: <p>{text3}</p>,
    },
  ];
  const defaultActiveKey = ["1"];
  const defaultActiveKey2 = ["1"];
  const defaultActiveKey3 = ["1"];
  const [activeKey, setActiveKey] = useState(defaultActiveKey);
  const [activeKey2, setActiveKey2] = useState(defaultActiveKey2);
  const [activeKey3, setActiveKey3] = useState(defaultActiveKey3);
  return (
    <>
      <div className="container-fluid bg-white p-3">
        <div className="row m-b-50">
          <div className="col-md-12">
            <div className="search-bar mt-4">
              <form>
                <div className="input-icon">
                  <span className="input-icon-addon">
                    <img src={searchIcon} alt="" className="icon" />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="setting-container d-flex align-items-start gap-3 mt-4">
          <div className="col-md-4 mt-2">
            <div className="setting-navbar-text">
              <p className="mb-0 help-nav-heading">General Questions </p>
              <p className="help-nav-content mt-3">
                General knowledge is information that has been accumulated over
                time through various mediums and sources. It excludes
                specialized learning that can only be obtained with extensive
                training and information confined to a single medium.{" "}
              </p>
            </div>
          </div>
          <div className="col-md-8 flex-grow-1 mt-2 help-accordion-main">
            <Collapse
              className="help-accordion-questions"
              accordion
              activeKey={activeKey}
              onChange={setActiveKey}
              expandIconPosition="right"
              expandIcon={({ isActive }) => (
                <img
                  src={isActive ? CustomIconOpen : CustomIconClose}
                  alt={`Custom Icon ${isActive ? "Open" : "Close"}`}
                  className="custom-icon"
                />
              )}
              items={items}
            />
          </div>
        </div>
        <div className="setting-container d-flex align-items-start gap-3 mt-4">
          <div className="col-md-4">
            <div className="setting-navbar-text">
              <p className="mb-0 help-nav-heading">Manage Account </p>
              <p className="help-nav-content mt-3">
                General knowledge is information that has been accumulated over
                time through various mediums and sources. It excludes
                specialized learning that can only be obtained with extensive
                training and information confined to a single medium.{" "}
              </p>
            </div>
          </div>
          <div className="col-md-8 flex-grow-1 mt-2 help-accordion-main">
            <Collapse
              accordion
              activeKey={activeKey2}
              onChange={setActiveKey2}
              expandIconPosition="right" // Add this line to move the icon to the right
              expandIcon={({ isActive }) => (
                <img
                  src={isActive ? CustomIconOpen : CustomIconClose}
                  alt={`Custom Icon ${isActive ? "Open" : "Close"}`}
                  className="custom-icon"
                />
              )}
              items={items2}
            />
          </div>
        </div>
        <div className="setting-container d-flex align-items-start gap-3 mt-4">
          <div className="col-md-4">
            <div className="setting-navbar-text">
              <p className="mb-0 help-nav-heading">Privacy & Security </p>
              <p className="help-nav-content mt-3">
                General knowledge is information that has been accumulated over
                time through various mediums and sources. It excludes
                specialized learning that can only be obtained with extensive
                training and information confined to a single medium.{" "}
              </p>
            </div>
          </div>
          <div className="col-md-8 flex-grow-1 mt-2 help-accordion-main">
            <Collapse
              accordion
              activeKey={activeKey3}
              onChange={setActiveKey3}
              expandIconPosition="right" // Add this line to move the icon to the right
              expandIcon={({ isActive }) => (
                <img
                  src={isActive ? CustomIconOpen : CustomIconClose}
                  alt={`Custom Icon ${isActive ? "Open" : "Close"}`}
                  className="custom-icon"
                />
              )}
              items={items3}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Help;
