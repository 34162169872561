import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import Mileage from "Components/Mileage/Mileage";
import NoAccessPage from "Pages/NoAccessMainPage/NoAccessPage";
import React from "react";

const MileagePage = () => {
  const storedRoles = localStorage.getItem("myUserRoles");
  const myUserRoles =
    storedRoles !== "undefined" ? JSON.parse(storedRoles) : [];
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar topBarTitle={"Mileage "} />
      {!["Owner/Executive", "Property Manager", "Accounting/Finance"].some(
        (role) => myUserRoles?.includes(role)
      ) && myUserRoles?.length !== 0 ? (
        <NoAccessPage />
      ) : (
        <Mileage />
      )}
    </div>
  );
};

export default MileagePage;
