import React, { useState, useEffect } from "react";
import { message } from "antd";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import settingIconOrange from "assets/dots-vertical.png";
import AddVendorsDetailsEditModal from "Modals/EditVendorsDetailsModal/EditVendorsDetailsModal";
import { useLocation, useNavigate } from "react-router-dom";
import { FilesTab, NotesTab } from "Components/TenantDetailsView";
import { OverviewTab } from "./OverviewTab";
import * as API from "network/landlord/vendor";
import * as Component from "Components/GeneralComponents";

const VendorDetailView = () => {
  const { id, tab } = UseUrlParamsHook();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [openEditModalTask, setOpenEditModalTask] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [force, setForce] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [isDeleteVendorRender, setIsDeleteVendorRender] = useState(false);
  const [forceDeleteMsg, setForceDeleteMsg] = useState("Are you sure you want to delete this vendor?");
  const [activeTab, setActiveTab] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const items = [
    {key: "1", label: "Overview", children: <OverviewTab id={id} />},
    {key: "2", label: "Files", children: <FilesTab category="vendor" id={id} />},
    {key: "3", label: "Notes", children: <NotesTab category="vendor" id={id} />},
  ];

  useEffect(() => {
    setActiveTab(tab || "1");
  }, [tab]);

  const onChange = (key) => {
    setActiveTab(key);
    searchParams.set("tab", key);
    navigate({ search: searchParams.toString() });
  };

  const onCloseEditModalTask = () => {
    setOpenEditModalTask(false);
  };
  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
    setDeleteId(id);
  };
  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleIconClickCustom = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // handle delete vendor
  useEffect(() => {
    if (deleteId) {
      setIsLoader(true);
      API.deleteVendor(deleteId, force).then((res) => {
        setIsLoader(false);
        if (res.apiCallStatus === "success") {
          onCloseDeleteModal();
          message.success("Vendor Deleted Successfully");
          setDeleteId("");
          setForce("");
          navigate(`/all-vendor`);
        } else if (res.apiCallStatus === "error") {
          setForce("true");
          setForceDeleteMsg(res.message);
        }
      });
    }
  }, [isDeleteVendorRender]);

  return (
    <>
      <Component.DeleteModal
        isOpen={openDeleteModal}
        onClose={onCloseDeleteModal}
        message={forceDeleteMsg}
        deleteCall={() => setIsDeleteVendorRender(!isDeleteVendorRender)}
        isLoading={isLoader}
        isForceDelete={force}
      />
      {openEditModalTask && (
        <AddVendorsDetailsEditModal id={id} onClose={onCloseEditModalTask} />
      )}
      <div className="container-fluid bg-white p-3">
        <div className="row">
          <div className="col-md-12 text-end">
            <span className="position-relative">
              <img
                onClick={handleIconClickCustom}
                src={settingIconOrange}
                alt="Action Buttons"
                className="cursor"
              />
              <Component.RecordMenuPopup
                isOpen={dropdownOpen}
                onClose={() => setDropdownOpen(false)}
                fields={{ edit: true, delete: true }}
                handler={{
                  handleDeleteRecord: () => onOpenDeleteModal(),
                  handleEdit: () => navigate(`/edit-vendor-details/${id}`),
                }}
              />
            </span>
          </div>
        </div>
        {activeTab && <Component.CustomTabs
          items={items}
          defaultActiveKey={activeTab}
          onChange={onChange}
        />}
      </div>
    </>
  );
};

export default VendorDetailView;
