import React, { useState, useEffect } from "react";
import leaseStatusIcon from "assets/lease-status.png";
import leaseRentIcon from "assets/lease-rent.png";
import leaseBalanceIcon from "assets/lease-balance.png";
import Leasedstatuscards from "Components/GeneralComponents/Leasedstatuscards";
import Leasehistorydata from "Components/GeneralComponents/Leasehistorydata";
import Loader from "Helpers/Loader";
import * as API from "network/landlord/lease";

const OverviewTab = ({ id }) => {
  const [lease, setLease] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(false);

  useEffect(() => {
    setIsTableLoading(true);
    API.getLeaseById(id).then((res) => {
      setIsTableLoading(false);
      if (res.apiCallStatus === "success") {
        setLease(res.message.data); 
      }
    });
  }, [id]);

  return (
    <>
      {isTableLoading ? (
        <Loader />
      ) : (
        <div>
          <div className="row mt-3">
            <Leasedstatuscards
              icon={leaseBalanceIcon}
              value={lease?.balance}
              title="Balance"
            />
            <Leasedstatuscards
              icon={leaseStatusIcon}
              value={lease?.status?.value}
              days={lease?.status?.days}
              title="Lease Status"
            />
            <Leasedstatuscards
              icon={leaseRentIcon}
              value={lease?.rent_amount}
              title="Rent"
            />
          </div>
          <div className="row mt-5">
            <div className="col-md-6">
              <div className="task-info-heading">
                <h4>LEASE INFO</h4>
              </div>
              <div className="task-info-lists mt-4">
                <p>
                  <span className="task-info-list-span me-3">Tenant Name:</span>{" "}
                  {lease?.tenant_name || "N/A"}
                </p>
                <p>
                  <span className="task-info-list-span me-3">
                    Property Address:
                  </span>{" "}
                  {`${lease?.property_address?.address_line_1} ${ lease?.property_address?.address_line_2 ? lease?.property_address?.address_line_2 : ""} ${lease?.property_address?.city} ${lease?.property_address?.state} ${lease?.property_address?.zipcode} ${lease?.property_address?.country}`}
                </p>
                <p>
                  <span className="task-info-list-span me-3">Unit:</span>{" "}
                  {lease.unit ? lease?.unit?.name : "N/A"}
                </p>
                <p>
                  <span className="task-info-list-span me-3">Lease Term:</span>{" "}
                  {lease.lease_term ? lease.lease_term : "N/A"}
                </p>
                <p>
                  <span className="task-info-list-span me-3">
                    Lease Term Start Date:
                  </span>
                  {lease.lease_term_start_date ? new Date(lease.lease_term_start_date).toLocaleDateString() : "N/A"}
                </p>
                <p>
                  <span className="task-info-list-span me-3">
                    Lease Term End Date:
                  </span>{" "}
                  {lease.lease_term_end_date ? new Date(lease.lease_term_end_date).toLocaleDateString() : "N/A"}
                </p>
                <p>
                  <span className="task-info-list-span me-3">Deposit:</span>{" "}
                  ${lease.deposit_amount}
                </p>
                <p>
                  <span className="task-info-list-span me-3">Documents:</span>
                  {lease.documents?.map((doc) => (
                    <span className="me-3">{doc.name}, </span>
                  ))}
                </p>
              </div>
            </div>
            <div className="fw-bolder border-top  m-3">LEASE HISTORY</div>
            <Leasehistorydata />
            <Leasehistorydata />
            <Leasehistorydata />
          </div>
        </div>
      )}
    </>
  );
};

export default OverviewTab;
