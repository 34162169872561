import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import VendorDetailView from "Components/VendorDetailView/VendorDetailView";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const VendorsDetail = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Vendor Details"}
        back="all-vendor"
      />
      <VendorDetailView />
    </div>
  );
};

export default VendorsDetail;
