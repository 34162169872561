import React, { useState } from "react";
import searchIcon from "assets/search.png";
import FilterIcon from "assets/filter.png";
import chevronIconDown from "assets/chevron-down.png";
import subtractIcon from "assets/subtract-minus-remove.png";
import calendarIcon from "assets/calendar-icon.png";
import { useNavigate } from "react-router-dom";
import { Select, ConfigProvider, Checkbox } from "antd";
import { useDispatch } from "react-redux";
import { getValue } from "Store/Slices/SearchSlice";
import TaskFilter from "./TaskFilter";
import TenantFilter from "./TenantFilter";
import AccountFilter from "./AccountFilter";
import NotesFilter from "./NotesFilter";
import VendorsFilter from "./VendorsFilter";
import ProspectsFilter from "./ProspectsFilter";
import LeaseFilter from "./LeaseFilter";
import UnitsFilter from "./UnitsFilter";
import SearchButton from "./SearchButton";
import CustomerQueriesFilter from "./CustomersQueriesFilter";
import InvoiceFilter from "./InvoiceFilter";
import FilesFilter from "./FilesFilter";
import { ButtonVariant1, InputField, SelectField } from "Components/GeneralComponents";
import { FilterValue } from "Store/Slices/FilterSlice";
import { useSelector } from "react-redux";
import { Button, Card } from 'antd'
import  { CheckboxChangeEvent } from 'antd/es/checkbox'

const SearchBar = ({
  btnTitle,
  onClick,
  route,
  taskFilter,
  tenantFilter,
  vendorsFilter,
  prospectsFilter,
  leaseFilter,
  accountingFilter,
  notesFilter,
  unitsFilter,
  serviceProfessionalFilter,
  customerQueriesFilter,
  invoiceFilter,
  fileFilter,
  disabled,
  innerPage,
  warning,
}) => {
  const [search, setSearch] = useState("");
  const [taskFilterDropdown, settaskFilterDropdown] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filterVal, setFilterVal] = useState([
    {
      value: "",
      property: "",
    },
  ]);
  // Search Start
  const dispatch = useDispatch();
  dispatch(getValue(search));
  // Search End
  let dropdownIcon;
  dropdownIcon = (
    <>
      <img src={chevronIconDown} alt="" />
    </>
  );
  let swapIcon;
  swapIcon = (
    <>
      <img src={subtractIcon} alt="" />
    </>
  );
  // select date icon
  let dateIcon;
  dateIcon = (
    <>
      <img src={calendarIcon} alt="" />
    </>
  );
  const resetFilter = () => {
    dispatch(FilterValue({ reset: "reset" }));
  };

  const range = useSelector((state) => {
    return state.FilterValue.value;
  });
  const property = useSelector((state) => {
    return state.FilterValue.property;
  });
  const fromDate = useSelector((state) => {
    return state.FilterValue.fromDate;
  });
  const toDate = useSelector((state) => {
    return state.FilterValue.toDate;
  });
  const navigate = useNavigate();
  const searchClickHandler = () => {
    if (!route) {
      onClick();
    } else {
      navigate(`/${route}`);
    }
  };

  const { Option } = Select;
  const initialItems = ["Los angeles", "New york", "Dallas TX"];
  const initialItemsServices = ["Plumbing", "Carpenter", "Electrician"];
   const [selectedLocation, setSelectedLocation] = useState(null)
  const [selectedService, setSelectedService] = useState(null)
  const [status, setStatus] = useState({
    accepted: false,
    connected: true,
    pending: true,
    decline: true
  })

  const locationOptions = [
    { value: 'los-angeles', label: 'Los Angeles' },
    { value: 'new-york', label: 'New York' },
    { value: 'dallas', label: 'Dallas TX' }
  ]

  const serviceOptions = [
    { value: 'plumbing', label: 'Plumbing' },
    { value: 'carpenter', label: 'Carpenter' },
    { value: 'electrician', label: 'Electrician' }
  ]
  const handleStatusChange = (name) => (e) => {
    setStatus(prev => ({
      ...prev,
      [name]: e.target.checked
    }));
  };
  

  const getSelectedCount = () => {
    return Object.values(status).filter(Boolean).length +
           (selectedLocation ? 1 : 0) +
           (selectedService ? 1 : 0)
  }

  const selectStyle = {
    width: '100%',
    height: '40px',
    border: '1px solid #e8e8e8',
    borderRadius: '4px',
    backgroundColor: '#f9f9f9',
  }
  const handleItemChange = (value) => {
    // Update the selected items when checkboxes are clicked.
    setSelectedItems(value);
  };
  return (
    <>
      <div className="row d-flex">
        {serviceProfessionalFilter ? (
          <div className="search-bar d-flex align-items-center gap-3">
            <div className="task-search-input position-relative flex-grow-1 ">
              <input
                type="text"
                placeholder="Search"
                className="form-control search-form-control-task "
              />
              <div className="search-icon-task">
                <img src={searchIcon} alt="" />
              </div>
            </div>

            
           
            <button
          onClick={() => {
            settaskFilterDropdown(!taskFilterDropdown);
          }}
          className="filter-btn d-flex align-items-center"
        >
          {" "}
          <img src={FilterIcon} alt="" /> Filter
        </button>

            <div
              className={
                taskFilterDropdown === true
                  ? "service-professional-filter-dropdown position-absolute"
                  : "d-none"
              }
            >
              <Card
                className="shadow-sm rounded-lg"
                style={{ width: "100%", border: "none" }}
                bodyStyle={{ padding: "1rem" }}
              >
                <div className="d-flex flex-column gap-1">
                  <h6 className="text-dark mb-0 fw-bold">FILTER BY</h6>

                 <div className="mb-2">
                 <SelectField
                    placeholder="Select Location"
                    style={selectStyle}
                    options={locationOptions}
                    value={selectedLocation}
                    handler={setSelectedLocation}
                    className="mb-3"
                  />
                 </div>

                 <div className="mb-3">
                 <SelectField
                    placeholder="Select Service"
                    style={selectStyle}
                    options={serviceOptions}
                    value={selectedService}
                    handler={setSelectedService}
                    className="mb-3"
                  />
                 </div>

                  <div className="d-flex flex-column gap-2 mt-2">
                    <h6 className="text-dark ">Status</h6>
                    <div className="d-flex flex-column gap-2">
                      {["accepted", "connected", "pending", "decline"].map(
                        (statusName) => (
                          <Checkbox
                            key={statusName}
                            checked={status[statusName]}
                            onChange={handleStatusChange(statusName)}
                            className="py-1"
                          >
                            {statusName.charAt(0).toUpperCase() +
                              statusName.slice(1)}
                          </Checkbox>
                        )
                      )}
                    </div>
                  </div>

                  {getSelectedCount() > 0 && (
                    <div
                      className="text-center py-2 rounded mt-3 mb-2"
                      style={{
                        border: "1px solid #f97316",
                        color: "#f97316",
                        backgroundColor: "#fff8f6",
                      }}
                    >
                      Selected {getSelectedCount()} filters
                    </div>
                  )}

                  <Button
                    type="primary"
                    block
                    size="large"
                    // className="mt-2"
                    style={{
                      backgroundColor: "#ef6b3e",
                      borderColor: "#f97316",
                      height: "48px",
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </Card>
            </div>
          </div>
        ) : (
          <>
            <div className="col-lg-7 col-md-12 mb-4">
              {/* Desktop */}
              <div className="task-search-input position-relative">
                <InputField
                  handler={(e) => setSearch(e.target.value)}
                  type="text"
                  placeholder="Search"
                  className="form-control search-form-control-task"
                />
                <div className="search-icon-task">
                  <img src={searchIcon} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 text-center">
              <div className="task-buttons d-flex gap-3 justify-content-center align-items-center">
                <div className="position-relative">
                  {taskFilter ? (
                    <TaskFilter innerPage={innerPage} />
                  ) : tenantFilter ? (
                    <TenantFilter innerPage={innerPage} />
                  ) : vendorsFilter ? (
                    <VendorsFilter />
                  ) : prospectsFilter ? (
                    <ProspectsFilter />
                  ) : leaseFilter ? (
                    <LeaseFilter />
                  ) : accountingFilter ? (
                    <AccountFilter />
                  ) : notesFilter ? (
                    <NotesFilter />
                  ) : customerQueriesFilter ? (
                    <CustomerQueriesFilter />
                  ) : unitsFilter ? (
                    <UnitsFilter />
                  ) : invoiceFilter ? (
                    <InvoiceFilter />
                  ) : fileFilter ? (
                    <FilesFilter />
                  ) : (
                    ""
                  )}
                </div>
                {/* FIXME fix this asap */}
                <SearchButton
                  disabled={disabled}
                  warning={warning}
                  btnTitle={btnTitle}
                  searchClickHandler={searchClickHandler}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SearchBar;
