import React, { useRef, useState } from "react";
import cancelIcon from "assets/x-circle.png";
import uploadIcon from "assets/upload-cloud-02.png";
import pdfIcon from "assets/pdf.jpg";
import excelIcon from "assets/excel.jpg";
import wordIcon from "assets/word.jpg";
const FileUploader = ({
  setImages,
  Images,
  DeletedImages,
  setDeletedImages,
  property,
  height = 150,
}) => {
  // States
  const [isDragging, setIsDragging] = useState(false);
  const [isImageDragging, setIsImageDragging] = useState(false);
  const fileInputRef = useRef(null);
  const draggedImage = useRef(null);
  const draggedOverImage = useRef(null);
  // States End

  // Functions
  const selectedFile = () => {
    fileInputRef.current.click();
  };
  const onFileSelect = (images) => {
    const files = images;
    if (files.length == 0) return;
    for (let i = 0; i < files.length; i++) {
      // if ((files[i].type.split("/")[0] !== "image" || files[i].type.split("/")[1] !== "pdf")) {
      //   console.log('this is not pdf nor image')

      //   continue
      // }
      if (!Images?.some((e) => e.name == files[i].name)) {
        setImages((prev) => [...prev, files[i]]);
      }
    }

    console.log(files[0].name, Images, "files");
  };
  const deleteImages = (index) => {
    setImages((prev) => {
      if (prev[index] instanceof File) {
        return prev.filter((_, i) => i !== index);
      } else {
        const deletedImage = prev[index];
        setDeletedImages((prev) => [...prev, deletedImage]);
        return prev.filter((_, i) => i !== index);
      }
    });
  };
  const onDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
    event.dataTransfer.dropEffect = "copy";
  };
  const onDragLeave = () => {
    setIsDragging(false);
  };
  const onDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    for (let i = 0; i < files.length; i++) {
      // if (files[i].type.split("/")[0] !== "image") continue;
      if (!Images.some((e) => e.name == files[i].name)) {
        setImages((prev) => [...prev, files[i]]);
      }
    }
    setIsDragging(false);
  };
  const handleSort = (event) => {
    const imageClone = [...Images];
    const temp = imageClone[draggedImage.current];
    imageClone[draggedImage.current] = imageClone[draggedOverImage.current];
    imageClone[draggedOverImage.current] = temp;
    setImages(imageClone);
  };
  // Functions End

  return (
    <>
      <div className="dragger-card">
        <div
          className="drag-area"
          style={{ height: `${height}px` }}
          onClick={selectedFile}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          {isDragging ? (
            <span>Drop file here...</span>
          ) : (
            <>
              <p>
                <img src={uploadIcon} alt="" />
              </p>
              <p className="mb-0">
                <span className="normal-grey-text fw-bold">
                  Click to upload
                </span>{" "}
                <span className="primary-black-text">or drag and drop</span>
              </p>
              <p className="primary-black-text">
                SVG, PNG, JPG or GIF (max. 800x400px)
              </p>
            </>
          )}
          {property ? (
            <input
              type="file"
              name=""
              ref={fileInputRef}
              onChange={(e) => onFileSelect(e.target.files)}
              accept=".png,.jpg,.jpeg"
              multiple
              id=""
            />
          ) : (
            <input
              type="file"
              name=""
              ref={fileInputRef}
              onChange={(e) => onFileSelect(e.target.files)}
              accept=".png,.jpg,.jpeg,.xlsx,.pdf,.docx,"
              multiple
              id=""
            />
          )}
        </div>
        <div className={`uploaded-img-box ${Images.length ? "mt-5" : "mt-2"}`}>
          {Images?.map((image, index) => {
            return (
              <div
                draggable
                onDragStart={() => {
                  draggedImage.current = index;
                  setIsImageDragging(true);
                }}
                onDragEnter={() => (draggedOverImage.current = index)}
                onDragEnd={handleSort}
                onDragOver={(e) => e.preventDefault()}
                key={index}
                className={"images cursor-grab"}
              >
                <div className="upload-img-cancel-btn">
                  <span
                    onClick={() => deleteImages(index)}
                    className="upload-cancel-btn text-danger"
                  >
                    <img src={cancelIcon} alt="" />
                  </span>
                </div>
                <img
                  className="upload-img"
                  src={
                    image instanceof File &&
                    image?.type.split("/")[0] === "image"
                      ? URL?.createObjectURL(image)
                      : image?.name?.includes("pdf")
                      ? pdfIcon
                      : image?.name?.includes("xlsx")
                      ? excelIcon
                      : image?.name?.includes("docx")
                      ? wordIcon
                      : image
                  }
                  alt=""
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default FileUploader;

