import React from "react";
import { Table, ConfigProvider } from "antd";
import Loader from "Helpers/Loader";

const CustomeTable = ({
  loading = false,
  pagination = false,
  rowSelection,
  columns,
  dataSource,
}) => {
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="mb-5">
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  colorTextHeading: "#667085",
                  colorText: "#667085",
                  fontSize: 14,
                  fontWeightStrong: 500,
                  fontFamily: "Montserrat",
                },
                Pagination: {
                  itemActiveBg: "#EF6B3E",
                },
                Checkbox: {
                  colorPrimary: "#EF6B3E",
                  colorPrimaryHover: "#EF6B3E",
                },
              },
            }}
          >
            <Table
              pagination={pagination}
              className="scroll-responsive-tablet"
              rowSelection={rowSelection}
              columns={columns}
              dataSource={dataSource}
            />
          </ConfigProvider>
        </div>
      )}
    </>
  );
};

export default CustomeTable;
