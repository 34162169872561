import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import TenantProperties from "Components/TenantProperties/TenantProperties";
import React from "react";

const Properties = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar topBarTitle={"Tenant Properties"} />
      <TenantProperties />
    </div>
  );
};

export default Properties;
