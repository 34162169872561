export const addTenantDetails = ["Email", "Personal Details", "Emergency Info"];
export const addProspectDetails = [
  "Email",
  "Personal Details",
  "Emergency Info",
  "Dependent Info",
  "Pet Info",
  "Vechile Info",
];
export const addVendorDetails = [
  "Personal Details",
  "Services",
  "Insurance",
  "Properties",
  "Federal Taxes",
];

export const addLeaseDetails = [
  "Lease Info",
  "Tenant Info",
  "Dependent Info",
  "Pet Info",
  "Vechile Info",
  "Document Info",
];

export const addCommercialLeaseDetails = [
  "Lease Info",
  "Tenant Info",
  "Vechile Info",
  "Document Info",
];

export const draftLeaseDetails = [
  "Lease Info",
  "Tenant Info",
  "Dependent Info",
  "Pet Info",
  "Vechile Info",
  "Document Info",
];

export const draftCommercialLeaseDetails = [
  "Lease Info",
  "Tenant Info",
  "Vechile Info",
  "Document Info",
];

export const renewLeaseDetails = [
  "Lease Info",
  "Document Info",
];

export const addAccountDetails = ["Property Info", "Account Info"];

export const addPropertyDetails = [
  "Property Type",
  "Property Details",
  "Unit Information",
];

export const addTeneantPassportProfileDetails = [
  "Edit Profile",
  "Payment",
  "Identity Verification",
  "Income Verification",
  "Employement Information",
  "Rental History",
  "Background Check",
  "Questions",
];
