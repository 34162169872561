import suspensionIcon from "assets/suspensionIcon.png";
import { SubmitButton } from "..";

const AccountSuspensionModal = ({ isOpen, onClose }) => {
  return (
    <>
  {isOpen && (
    <div className="payment-modal-container">
      <div className="d-flex flex-column justify-content-center align-items-center vendor-success-modal position-relative w-50 w-md-75 w-lg-50 h-auto p-5">
        <div className="row">
          <div className="col-12 text-center">
            <img
              src={suspensionIcon}
              alt="Suspension Icon"
              className="img-fluid"
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 text-center">
            <p className="convert-tenant-modal-title">Account Suspension</p>
            <p className="normal-grey-text convert-tenant-modal-text">
              Your account has been suspended.
            </p>
          </div>
        </div>
        <div className="row justify-content-center mt-3 w-100">
          <div className="col-lg-5 col-md-6 col-sm-12 text-center p-3">
            <SubmitButton children="Repay" type="contained" className="text-wrap" />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 text-center p-3">
            <SubmitButton children="Add Another Account" className="text-wrap"/>
          </div>
        </div>
      </div>
    </div>
  )}
</>



  );
};

export default AccountSuspensionModal;
