import React from 'react';
import { useNavigate } from 'react-router-dom';
function CreateButton({handleClose}) {
    const navigate = useNavigate();
  return (
    <div className="d-flex justify-content-center">
            
    <button
      onClick={() => {
        handleClose()
        navigate("/create-new");
      }}
      className="modal-save-btn w-75 mx-auto p-0"
    >
      {localStorage.getItem("role") === "serviceprovider"
        ? "Create New"
        : "Create New"}
    </button>
  </div>
  );
}

export default CreateButton;
