import React from "react";
import {
  InputField,
  SelectField,
  SubmitButton,
} from "Components/GeneralComponents";
import { selectPets } from "utils/dropdownData";
import DropdownLayout from "../GeneralComponents/DropdownLayout";
import "../../style/dropdown.css";

const PetsForm = ({
  pets,
  handleChange,
  handleBackButton,
  handleNext,
  addAnotherPet,
  removePet,
  isLoading = false,
  isSubmitForm = false,
  disabled = false,
}) => {
  return (
    <div className="container-fluid">
      {pets.map((pet, index) => (
        <DropdownLayout key={index} title={`Pet ${index + 1}`}>
          <div className="row mt-3">
            <div className="col-md-6">
              <SelectField
                name={"petType"}
                label={"Pet Type"}
                placeholder={"Select Pet Type"}
                value={pet.petType}
                handler={(e) => handleChange("petType", index, e)}
                options={selectPets}
                disabled={disabled}
              />
            </div>
            <div className="col-md-6">
              <InputField
                name={"petName"}
                label={"Name"}
                placeholder={"Name"}
                value={pet.petName}
                handler={(e) =>
                  handleChange(e.target.name, index, e.target.value)
                }
                disabled={disabled}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <InputField
                name={"petWeight"}
                type={"number"}
                label={"Weight"}
                placeholder={"Weight"}
                value={pet.petWeight}
                handler={(e) =>
                  handleChange(e.target.name, index, e.target.value)
                }
                disabled={disabled}
              />
            </div>
            <div className="col-md-6">
              <InputField
                name={"petBreed"}
                label={"Breed"}
                placeholder={"Breed"}
                value={pet.petBreed}
                handler={(e) =>
                  handleChange(e.target.name, index, e.target.value)
                }
                disabled={disabled}
              />
            </div>
            {index !== 0 ? (
              <div className="row button" onClick={() => removePet(index)}>
                <div className="col-md-12">
                  <button
                    onClick={() => removePet(index)}
                    className="primary-orange-text add-new-btn"
                  >
                    Remove
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </DropdownLayout>
      ))}

      <div className="row mt-3 button" onClick={addAnotherPet}>
        <div className="col-md-12">
          <button
            onClick={addAnotherPet}
            className="primary-orange-text add-new-btn"
          >
            + Add Another Pet
          </button>
        </div>
      </div>

      <div className="d-flex justify-content-between gap-1 pb-3 mt-5">
        <div style={{ width: "50%" }}>
          <SubmitButton type="contained" handler={handleBackButton}>
            Back
          </SubmitButton>
        </div>
        <div style={{ width: "50%" }}>
          <SubmitButton handler={handleNext} isLoading={isLoading}>
            {isSubmitForm ? "Submit" : "Next"}
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default PetsForm;
