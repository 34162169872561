import React from "react";
import { useState, useEffect } from "react";
import * as Component from "Components/GeneralComponents";
import { validateTenantTaskForm } from "utils/validations";
import { fetchproperties } from "network/tenant/Property";
import { TenantTaskFormData } from "utils/FormDataStructure";
import { createnewtask } from "network/tenant/task";
import { message } from "antd";

const CreateTask = ({ onClose }) => {
  const [Images, setImages] = useState([]);
  const [loader, setLoader] = useState(false);
  const [propertyData, setPropertyData] = useState([]);
  const [propertyDropdown, setpropertyDropdown] = useState(false);
  const [unitData, setUnitData] = useState([]);
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({
    property: {
      propertyId: "",
      unitId: "",
    },
    title: "",
    description: "",
  });

  useEffect(() => {
    fetchproperties(true).then((res) => {
      if (res.apiCallStatus === "success") {
        setPropertyData(
          res.message.data?.map((e) => ({
            id: e.property.id,
            heading1: "",
            heading2: e.property.address,
            heading3: e.property.title,
            units: e.units.map((unit) => ({ id: unit.id, name: unit.name })),
          }))
        );
      }
    });
  }, []);

  const addTask = () => {
    const newErrors = validateTenantTaskForm(form);
    setErrors(newErrors);
    
    if (Object.keys(newErrors).length === 0) {
      const formData = TenantTaskFormData(form, Images);
      setLoader(true);
      
      createnewtask(formData).then((res) => {
        setLoader(false);
        message.success("Task created successfully");
        console.log(res);
        onClose();
      });
    }
  };

  const handleChange = (fieldName, value) => {
    console.log(fieldName, value);
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  // Handle property change
  const handlePropertyChange = (e) => {
    handleChange("property", { propertyId: e.id, unitId: "" });
    setUnitData(e.units);
  };

  return (
    <>
      <div className="modal-content-main-section p-41">
        <Component.SelectPropertyField
          label={"Property"}
          placeholder={"Select Property"}
          isRequired={true}
          PropertyData={propertyData}
          category={"tenant"}
          errorMessage={errors.property}
          onPropertySelect={(selectedProperty) => {
            console.log("Selected Property", selectedProperty);
            handlePropertyChange(selectedProperty);
            setpropertyDropdown(false);
          }}
        />
        <div className="row mt-3">
          <div className="col-md-12 text-start">
            <Component.SelectField
              name={"unit"}
              required={true}
              label={"Unit"}
              TooltipTitle={
                "Unit can't be selected without selecting property first."
              }
              placeholder={"First select property then unit"}
              value={form.property.unitId}
              options={unitData.map((e) => ({ value: e.id, label: e.name }))}
              handler={(e) =>
                handleChange("property", { ...form.property, unitId: e })
              }
              errorMessage={errors.unit}
              disabled={unitData.length === 0}
            />
          </div>
        </div>
      </div>
      <div className="p-41">
        <div className="row mt-3">
          <div className="col-md-12 text-start">
            <Component.InputField
              name={"title"}
              required={true}
              label={"Title"}
              placeholder={"Title"}
              value={form.title}
              handler={(e) => handleChange("title", e.target.value)}
              errorMessage={errors.title}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-12">
            <Component.TextAreaField
              name={"description"}
              required={true}
              label={"Description"}
              placeholder={"Description"}
              value={form.description}
              handler={(e) => handleChange(e.target.name, e.target.value)}
              rows={5}
              cols={20}
              errorMessage={errors.description}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <Component.MediaField
              label={"Upload Media"}
              setImages={setImages}
              Images={Images}
            />
          </div>
        </div>
      </div>
      <div className="modal-content-footer-section-scroll p-custom">
        <div className="row mt-3">
          <div className="col-md-12 d-flex justify-content-end responsive-direction-column">
            <div className="task-modal-footer-btn">
              
              <Component.SaveModalButton loader={loader} addTask={addTask} />
              
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTask;
