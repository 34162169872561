import { useEffect, useState } from "react";
import DigitalTenantLogo from "assets/Copy of DigitalTenant_Logo.png";
import { useNavigate } from "react-router-dom";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import LandLordPackages from "Components/LandLordPackages/LandLordPackages";
import { getSubscriptionPlans, subscribe } from "network/landlord/subscription";
import { PricingToggle, SubmitButton } from "Components/GeneralComponents";

const LandLordSubscription = () => {
  // States start
  const [interval, setInterval] = useState("Monthly");
  const [selectedPlan, setSelectedPlan] = useState({
    planName: "",
    price: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const [plan, setPlan] = useState([]);
  const navigate = useNavigate();
  // States end
  const { email, id } = UseUrlParamsHook();

  useEffect(() => {
    console.log("Parent Selected Plan", selectedPlan);
  }, [selectedPlan]);

  useEffect(() => {
    setIsLoading(true);
    console.log("Fetching Plans");
    getSubscriptionPlans().then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        setPlan(res.message.plans);
      }
    });
  }, []);

  const handleContinue = () => {
    console.log("Selected Plan: ", selectedPlan);
    if (selectedPlan.planName === "Free") {
      // console.log("Free Plan Selected: ", selectedPlan, "User ID: ", id);
      setIsLoader(true);
      subscribe({
        userId: id,
        plan: "Trial",
        billingCycle: "Trial",
        paymentMethodId: "Free",
      }).then((res) => {
        setIsLoader(false);
        if (res.apiCallStatus === "success") {
          // navigate("/land-lord-sign-in");
          window.location.href = "/";
        }
      });
    } else {
      navigate(
        `/landlord/register/pay?email=${email}&id=${id}&interval=${interval}&planName=${selectedPlan.planName}&price=${selectedPlan.price}`
      );
    }
  };
  return (
    <div style={{ backgroundColor: "white" }}>
      <div className="container-fluid">
        <div className="container">
          <div className="land-lord-subscribe-heading text-center mt-3">
            <img src={DigitalTenantLogo} className="my-5" alt="" />
            <h1 className="fs-1">Select a subscription plan</h1>
          </div>
          <div className="land-lord-subscribe-tabs text-center my-4">
            <span className="landlord-package-tab-btn">Landlord Package</span>
          </div>
          <p className="text-center mb-5">
            Whether you're a landlord or property manager, select the plan that
            works best for you. Choose between our monthly or annual options.
          </p>
          <PricingToggle interval={interval} setInterval={setInterval} />
        </div>

          <LandLordPackages
            data={plan}
            interval={interval}
            setSelectedPlan={setSelectedPlan}
            isLoading={isLoading}
          />
     

        <div className="d-flex justify-content-center py-5">
          <div style={{ width: "300px" }}>
            <SubmitButton
              handler={handleContinue}
              disabled={selectedPlan.planName === ""}
              isLoading={isLoader}
            >
              Continue
            </SubmitButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandLordSubscription;
