import AddUserRole from "Components/AddUserRole/AddUserRole";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const UsersRoleNew = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"User Role"}
        back={"./settings/user-role"}
      />
      <AddUserRole />
    </div>
  );
};

export default UsersRoleNew;
