import { useEffect, useState } from "react";
import * as Component from "Components/GeneralComponents";
import { Link, useNavigate } from "react-router-dom";
import * as Icon from "assets/icons";
import { fetchproperties } from "network/tenant/Property";
import { propertyIcon } from "utils/index";

const TenantProperties = () => {
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [key, setKey] = useState(null);
  const [property, setProperty] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProperties = async () => {
      setIsTableLoading(true);
      const response = await fetchproperties();
      setIsTableLoading(false);

      if (response.apiCallStatus === "success") {
        const propertyData = response.message.data.map((item, index) => {
            return {
            key: index + 1,
            id: item.id,
            unit: item.unit,
            property: item.title,
            image: item.img,
            address: item.address,
            leasestartDate: new Date(item.lease_term_start_date).toLocaleDateString(),
            leaseEndDate: new Date(item.lease_term_end_date).toLocaleDateString(),
            leaseTerm: item.lease_term,
            rentAmount: item.rent_amount,
            type: item.property_sub_type,
            };
        });

        setProperty(propertyData);
      }
    };

    fetchProperties();
  }, []);

  // Data Table Functions
  const handleIconClick = (result) => {
    const filterData = property.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const columns = [
    {
      title: "PROPERTY NAME",
      dataIndex: "property",
      render: (text, data) => (
        
        <Link
            to={`/tenant-property-details-view?id=${data.id}&unit=${data.unit}`}
            className="d-flex align-items-center gap-2"
          >
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                width: "50px",
                borderRadius: "10%",
                overflow: "hidden",
                position: "relative",
              }}
            >
              {data.image ? (
                <img
                  src={data.image}
                  alt="task"
                  style={{ width: "100%", height: "100%" }}
                />
              ) : (
                <img
                  src={propertyIcon(data.type)}
                  alt="task"
                  style={{
                    width: "2rem",
                    height: "2rem",
                    objectFit: "contain",
                  }}
                />
              )}
            </div>
            <div className="all-lease-property-text">{text}</div>
          </Link>
      ),
    },
    {
      title: "ADDRESS",
      dataIndex: "address",
      render: (text) => (
        <>
          <div className="all-lease-property-text">{text}</div>
        </>
      ),
    },
    {
      title: "LEASE DATE",
      dataIndex: "leaseDate",
      render: (text, date) => (
        <>
          <div className="all-lease-date-text  ">{date.leasestartDate} <br></br> {date.leaseEndDate}</div>
        </>
      ),
    },
    {
      title: "LEASE TERM",
      dataIndex: "leaseTerm",
      render: (text, term) => (
        <>
          <div className="table-status-bar lease-status text-nowrap">
            {text}{" "}
          </div>
        </>
      ),
    },
    {
      title: "RENT AMOUNT",
      dataIndex: "rentAmount",
      render: (text, rent) => (
        <>
          <div className="">${text}</div>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <Icon.VerticalElipsisIcon width={23} height={23} />
            <Component.RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleView: () => {
                  navigate(`/tenant-property-details-view?id=${setting.id}&unit=${setting.unit}`);
                },
              }}
              fields={{
                view: true,
                delete: false,
              }}
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="flex-fill p-3">
        <Component.FilterRow filter={{}} setFilter={() => {}} />
        <div className="table-container mt-3 p-2">
          <Component.CustomTable
            loading={isTableLoading}
            pagination={true}
            columns={columns}
            dataSource={property}
          />
        </div>
      </div>
    </>
  );
};

export default TenantProperties;
