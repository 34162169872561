import React, { useState } from "react";
import GoogleAuth from "Hooks/GoogleAuth";
import Authlayout from "Components/GeneralComponents/Layout/Authlayout";
import TenantLogin from "./TenantLogin";
import { validationtenantSignIn } from "utils/validations";
import * as API from "network/tenant/auth";
import { message } from "antd";

const TenantSignIn = () => {
  
  const [loader, setLoader] = useState(false);
  const [facebookLogin, setFacebookLogin] = useState(null);
  const [form, setForm] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    const newError = validationtenantSignIn(form);
    setErrors(newError);
    if (Object.keys(newError).length === 0) {
      setLoader(true);
      API.tenantsignin(form).then((res) => {
        if (res.apiCallStatus === "success") {
          message.success("Login Successful");
          if (res.message.userRole) {
            localStorage.setItem("role", "user");
            localStorage.setItem("user_role", res.message.userRole.role);
          } else {
            localStorage.setItem("role", res.message.role);
          }

          localStorage.setItem("name", res.message.name);
          localStorage.setItem("user_id", res.message._id);
          localStorage.setItem("token", res.message.token);
          localStorage.setItem("email", res.message.email);

          setTimeout(() => {
            window.location.replace("/");
          }, 200);
        } else {
          setLoader(false);
          console.log(res);
          message.error(res.message);
        }
      });
    }

  };

  const googleAuth = () => {
    GoogleAuth("tenant");
  };

  const resolveFacebook = (responce) => {
    console.log(responce);
    setFacebookLogin(responce.data);
    console.log(facebookLogin);
  };
  const rejectFacebook = (error) => {
    console.log(error);
  };
  // Login End
  return (
    <>
 
      <Authlayout loader={loader} >
        <TenantLogin
          form={form}
          errors={errors}
          googleAuth={googleAuth}
          resolveFacebook={resolveFacebook}
          rejectFacebook={rejectFacebook}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      </Authlayout>
    </>
  );
};

export default TenantSignIn;
