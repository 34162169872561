import { useEffect, useMemo, useState } from "react";
import { ConfigProvider, Select, Space } from "antd";
import { DatePicker } from "antd";
import chevronIcon from "assets/chevron-down.png";
import { Radio } from "antd";
import calendarIcon from "assets/calendar.png";
import UseGetHook from "Hooks/UseGetHook";
import FileUploader from "../FileUploader/FileUploader";
import UseUpdateHook from "Hooks/UseUpdateHook";
import ModalLoader from "Components/GeneralComponents/ModalLoader";
import { Component } from "react";

const EditTaskModal = ({ onClose, onOpen, id, setUpdate }) => {
  const [Images, setImages] = useState([]);
  const [DeletedImages, setDeletedImages] = useState([]);
  const { PropertyData, fetchProperty } = UseGetHook("property");
  const { VendorData, fetchVendor } = UseGetHook("vendors");
  const { TaskData, fetchTask } = UseGetHook("task", id);
  const tasks = TaskData.filter((e) => e.id === id);
  const [notifyTenantChecked, setNotifyTenantChecked] = useState(false);
  const [assigneeChecked, setAssigneeChecked] = useState(false);
  const [title, setTitle] = useState("");
  const [dueDate, setdueDate] = useState("");
  const [status, setstatus] = useState(tasks[0]?.status);
  const [priority, setpriority] = useState("");
  const [Description, setDescription] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [Frequency, setFrequency] = useState("");
  const [recurring, setrecurring] = useState(1);
  const [isLandlord, setIsLandlord] = useState(false);
  const [VendorSearch, setVendorSearch] = useState("");
  const [VendorDropdown, setVendorDropdown] = useState(false);
  const [loader, setLoader] = useState(false);
  const [vendor, setVendor] = useState([]);
  // Fetch Data

  useEffect(() => {
    fetchProperty();
    fetchVendor();
    fetchTask();
  }, []);
  const AddVendor = () => {
    fetchVendor();
  };
  // Fetch Data End
  const dateFormat = "MM/DD/YYYY";
  // select box icon
  let dropdownIcon;
  dropdownIcon = (
    <>
      <img src={chevronIcon} alt="" />
    </>
  );
  let calendar;
  calendar = (
    <>
      <img src={calendarIcon} alt="" />
    </>
  );
  const onChangeRadio = (e) => {
    setrecurring(e.target.value);
  };
  // Get Properties
  // Get Vendors

  // Remove Vendor
  const removeVendor = (id) => {
    const deleteItem = vendor.filter((ind) => {
      return ind !== id;
    });
    setVendor(deleteItem);
  };
  // Add Tasks
  const formdata = new FormData();
  for (let i = 0; i < vendor.length; i++) {
    formdata.append(`assignedTo[${[i]}]`, vendor[i]);
  }
  formdata.append(`assignedToMe`, isLandlord);
  if (title) formdata.append("title", title);
  if (dueDate) formdata.append("dueDate", dueDate);
  if (status) formdata.append("status", status);
  if (priority) formdata.append("priority", priority);
  formdata.append("updatedBy", "landlord");
  formdata.append("notifyAssignee", assigneeChecked ? assigneeChecked : false);
  formdata.append(
    "notifyTenant",
    notifyTenantChecked ? notifyTenantChecked : false
  );
  if (recurring === 2) {
    if (startDate) formdata.append("startDate", startDate);
    if (endDate) formdata.append("endDate", endDate);
    if (Frequency) formdata.append("frequency", Frequency);
    formdata.append("recurring", true);
  }
  if (Description) formdata.append("description", Description);
  if (DeletedImages.length > 0) {
    DeletedImages?.forEach((img, index) => {
      formdata.append(`__delete_files[${[index]}]`, img);
    });
  }
  Images?.forEach((img, index) => {
    formdata.append(`images[${[index]}]`, img);
  });
  const imageArray = useMemo(() => {
    return tasks[0]?.images || [];
  }, [tasks]);
  useEffect(() => {
    if (imageArray.length > 0) {
      setImages((prev) => [...prev, ...imageArray]);
    }
  }, [imageArray]);

  const UpdateTask = () => {
    UseUpdateHook("task", id, formdata, onClose, "", setUpdate, setLoader);
  };
  return (
    <>
      <div className="payment-modal-container">
        <div className="payment-method-modal property-details-view-task-modal">
          <div className="modal-heading d-flex justify-content-between align-items-center p-41 pt-20 property-details-view-add-task-modal-heading-main">
            <h1>Edit Tasks</h1>
            <button onClick={onClose} className="modal-cancel-btn">
              <svg
                width={18}
                height={18}
                fill="#667085"
                stroke="#667085"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M18 6 6 18" />
                <path d="m6 6 12 12" />
              </svg>
            </button>
          </div>
          <div className="modal-content-main-section p-41">
            <div className="row mt-3">
              <div className="col-md-12 text-start">
                <span>
                  Tenant Property/Unit/Name
                  <span className="sign-up-imp-star">*</span>
                </span>
                <div className="task-search-property-container position-relative">
                  <input
                    value={tasks[0]?.property.title}
                    type="text"
                    disabled
                    className="form-control "
                    placeholder="Selected Tenant Property/Unit/Name"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12 text-start">
                <p className="mb-0">Assigned To</p>
                <div className="task-vendor-search-container position-relative ">
                  {}
                  <span
                    onClick={() => {
                      setVendorDropdown(!VendorDropdown);
                    }}
                    className="multi-chevron cursor"
                  >
                    <img src={chevronIcon} alt="" />
                  </span>
                  {VendorDropdown ? (
                    <div className="dropdown-options p-3 create-task-vendor-container modal-content-main-section-scroll">
                      <div className="multi-select-options ">
                        {VendorData.length > 0 ? (
                          VendorData.filter((e) =>
                            e.firstName
                              .toLowerCase()
                              .includes(VendorSearch?.toLowerCase())
                          ).map((item) => {
                            return (
                              <div key={item.id}>
                                <div className="landlord-task d-flex justify-content-between align-items-center mt-3">
                                  {localStorage.getItem("name")}
                                  {isLandlord ? (
                                    <button
                                      onClick={() => {
                                        setIsLandlord(false);
                                      }}
                                      className="remove-btn fw-medium"
                                    >
                                      Remove
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() => {
                                        setIsLandlord(true);
                                      }}
                                      className="not-found-add-task-btn primary-orange-text fw-medium"
                                    >
                                      Add
                                    </button>
                                  )}
                                </div>
                                <div
                                  key={item?.id}
                                  className="assigned-task-candidate d-flex justify-content-between align-items-center mt-3"
                                >
                                  <div className="candidate-img">
                                    <img
                                      src={`${item.profileImage}`}
                                      className="rounded-5 property-table-image mw_40 mh_40"
                                      alt=""
                                    />{" "}
                                    <span className="candidate-name ms-2">
                                      {item.firstName} {item.lastName}
                                    </span>
                                  </div>
                                  <div className="candidate-add-btn">
                                    {vendor.includes(item?.id) ? (
                                      <button
                                        onClick={() => {
                                          removeVendor(item?.id);
                                        }}
                                        className="remove-btn fw-medium"
                                      >
                                        Remove
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() => {
                                          vendor.push(item?.id);
                                          AddVendor();
                                        }}
                                        className="not-found-add-task-btn primary-orange-text fw-medium"
                                      >
                                        Add
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div>
                            <div className="assigned-task-candidate d-flex justify-content-between align-items-center mt-3">
                              <div className="landlord-task">
                                {localStorage.getItem("name")}
                              </div>
                              <div className="candidate-img">
                                {/* <img
                                  src={`${item.profileImage}`}
                                  className="rounded-5 property-table-image mw_40 mh_40"
                                  alt=""
                                />{" "} */}
                                <span className="candidate-name ms-2">{}</span>
                              </div>
                              <div className="candidate-add-btn">
                                {isLandlord ? (
                                  <button
                                    onClick={() => {
                                      setIsLandlord(false);
                                    }}
                                    className="remove-btn fw-medium"
                                  >
                                    Remove
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => {
                                      setIsLandlord(true);
                                    }}
                                    className="not-found-add-task-btn primary-orange-text fw-medium"
                                  >
                                    Add
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {vendor.length > 0 ? (
                  <div className="selected-vendor-container d-flex gap-3 mt-3 align-items-center ">
                    {VendorData?.filter((e) => vendor.includes(e.id)).map(
                      (item) => {
                        return (
                          <>
                            <div className="selected-vendors d-flex align-items-center position-relative">
                              {isLandlord && (
                                <div className="selected-vendors me-2">
                                  <span className="candidate-name ms-2">
                                    {localStorage.getItem("name")} (YOU)
                                  </span>
                                </div>
                              )}
                              <img
                                src={`${item.profileImage}`}
                                className="rounded-5 property-table-image mw_40 mh_40"
                                alt=""
                              />{" "}
                              <span className="candidate-name ms-2 position-relative">
                                {item.firstName} {item.lastName}
                              </span>
                            </div>
                          </>
                        );
                      }
                    )}
                  </div>
                ) : (
                  <>
                    {isLandlord && (
                      <div className="selected-vendor-container d-flex gap-3 mt-3 align-items-center ">
                        <div className="selected-vendors">
                          <span className="candidate-name ms-2">
                            {localStorage.getItem("name")}
                          </span>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="p-41">
            <div className="row mt-3">
              <div className="col-md-12 text-start">
                <span>
                  Title<span className="sign-up-imp-star">*</span>
                </span>
                <input
                  defaultValue={tasks[0]?.title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Title"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <div className="task-modal-scroll">
              <div className="row mt-3">
                <div className="col-md-4 text-start">
                  <span>
                    Due Date<span className="sign-up-imp-star">*</span>
                  </span>
                  <br />
                  <ConfigProvider
                    theme={{
                      components: {
                        DatePicker: {
                          zIndexPopupBase: 99999,
                          colorPrimaryHover: "#EF6B3E",
                          borderRadius: 4,
                          fontFamily: "montserrat",
                        },
                      },
                    }}
                  >
                    <Space
                      style={{
                        width: "100%",
                      }}
                      direction="vertical"
                    >
                      <DatePicker
                        onChange={(e, dateString) => {
                          const formattedDate = new Date(
                            e
                          ).toLocaleDateString();
                          setdueDate(formattedDate);
                        }}
                        suffixIcon={calendar}
                        defaultValue={tasks[0]?.dueDate}
                        style={{
                          width: "100%",
                          height: 45,
                        }}
                        format={dateFormat}
                      />
                    </Space>
                  </ConfigProvider>
                </div>
                <div className="col-md-4 text-start">
                  <span>
                    Status<span className="sign-up-imp-star">*</span>
                  </span>
                  <br />
                  <ConfigProvider
                    theme={{
                      components: {
                        Select: {
                          zIndexPopupBase: 99999,
                          colorPrimaryHover: "#EF6B3E",
                          optionSelectedBg: "rgba(239, 107, 62, 0.16)",
                          borderRadius: 4,
                          colorTextPlaceholder: "#667085",
                          fontFamily: "montserrat",
                        },
                      },
                    }}
                  >
                    {tasks.length > 0 ? (
                      <>
                        <Space
                          style={{
                            width: "100%",
                          }}
                          direction="vertical"
                        >
                          <Select
                            suffixIcon={dropdownIcon}
                            defaultValue={tasks[0]?.status}
                            placeholder="Select Status"
                            style={{
                              width: "100%",
                              height: 45,
                            }}
                            onChange={(e) => {
                              setstatus(e);
                            }}
                            options={[
                              {
                                value: "In Progress",
                                label: "In Progress",
                              },
                              {
                                value: "Completed",
                                label: "Completed",
                              },
                              {
                                value: "Not Started",
                                label: "Not Started",
                              },
                            ]}
                          />
                        </Space>
                      </>
                    ) : (
                      <Space
                        style={{
                          width: "100%",
                        }}
                        direction="vertical"
                      >
                        <Select
                          suffixIcon={dropdownIcon}
                          defaultValue={tasks[0]?.status}
                          placeholder="Select Status"
                          style={{
                            width: "100%",
                            height: 45,
                          }}
                          onChange={(e) => {
                            setstatus(e);
                          }}
                          options={[
                            {
                              value: "In Progress",
                              label: "In Progress",
                            },
                            {
                              value: "Completed",
                              label: "Completed",
                            },
                            {
                              value: "Not Started",
                              label: "Not Started",
                            },
                          ]}
                        />
                      </Space>
                    )}
                  </ConfigProvider>
                </div>
                <div className="col-md-4 text-start">
                  <span>
                    Priority<span className="sign-up-imp-star">*</span>
                  </span>
                  <br />
                  <ConfigProvider
                    theme={{
                      components: {
                        Select: {
                          zIndexPopupBase: 99999,
                          colorPrimaryHover: "#EF6B3E",
                          optionSelectedBg: "rgba(239, 107, 62, 0.16)",
                          borderRadius: 4,
                          colorTextPlaceholder: "#667085",
                          fontFamily: "montserrat",
                        },
                      },
                    }}
                  >
                    {tasks.length > 0 ? (
                      <>
                        <Space
                          style={{
                            width: "100%",
                          }}
                          direction="vertical"
                        >
                          <Select
                            suffixIcon={dropdownIcon}
                            defaultValue={tasks[0]?.priority}
                            placeholder="Select Priority"
                            style={{
                              width: "100%",
                              height: 45,
                            }}
                            // showSearch
                            onChange={(e) => setpriority(e)}
                            options={[
                              {
                                value: "Low",
                                label: "Low",
                              },
                              {
                                value: "Medium",
                                label: "Medium",
                              },
                              {
                                value: "High",
                                label: "High",
                              },
                            ]}
                          />
                        </Space>
                      </>
                    ) : (
                      <Space
                        style={{
                          width: "100%",
                        }}
                        direction="vertical"
                      >
                        <Select
                          suffixIcon={dropdownIcon}
                          defaultValue={tasks[0]?.priority}
                          placeholder="Select Priority"
                          style={{
                            width: "100%",
                            height: 45,
                          }}
                          // showSearch
                          onChange={(e) => setpriority(e)}
                          options={[
                            {
                              value: "Low",
                              label: "Low",
                            },
                            {
                              value: "Medium",
                              label: "Medium",
                            },
                            {
                              value: "High",
                              label: "High",
                            },
                          ]}
                        />
                      </Space>
                    )}
                  </ConfigProvider>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <span>Description</span>
                  <textarea
                    onChange={(e) => setDescription(e.target.value)}
                    defaultValue={tasks[0]?.description}
                    name=""
                    id=""
                    cols="30"
                    rows="4"
                    className="form-control"
                    placeholder="Add description"
                  ></textarea>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <ConfigProvider
                    theme={{
                      components: {
                        Radio: {
                          colorText: "#344054",
                          colorPrimary: "#EF6B3E",
                          fontFamily: "Montserrat",
                          fontSize: 16,
                        },
                      },
                    }}
                  >
                    <Radio.Group onChange={onChangeRadio} value={recurring}>
                      <Radio value={1}>One Time Task</Radio>
                      <Radio value={2}>Recurring Task</Radio>
                    </Radio.Group>
                  </ConfigProvider>
                </div>
              </div>
              {recurring === 2 ? (
                <>
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <span>
                        Start Date<span className="sign-up-imp-star">*</span>
                      </span>
                      <ConfigProvider
                        theme={{
                          components: {
                            DatePicker: {
                              zIndexPopup: 99999,
                              fontFamily: "Montserrat",
                              fontSize: 16,
                              colorText: "#667085",
                              colorTextPlaceholder: "#667085",
                              colorPrimaryHover: "#EF6B3E",
                              borderRadius: 4,
                              colorPrimary: "#EF6B3E",
                            },
                          },
                        }}
                      >
                        <DatePicker
                          onChange={(e, dateString) => {
                            const formattedDate = new Date(
                              e
                            ).toLocaleDateString();
                            setstartDate(formattedDate);
                          }}
                          suffixIcon={calendar}
                          style={{
                            width: "100%",
                            height: 45,
                          }}
                          format={dateFormat}
                        />
                      </ConfigProvider>
                    </div>
                    <div className="col-md-4">
                      <span>
                        End Date<span className="sign-up-imp-star">*</span>
                      </span>
                      <ConfigProvider
                        theme={{
                          components: {
                            DatePicker: {
                              zIndexPopup: 99999,
                              fontFamily: "Montserrat",
                              fontSize: 16,
                              colorText: "#667085",
                              colorTextPlaceholder: "#667085",
                              colorPrimaryHover: "#EF6B3E",
                              borderRadius: 4,
                              colorPrimary: "#EF6B3E",
                            },
                          },
                        }}
                      >
                        <DatePicker
                          onChange={(e, dateString) => {
                            const formattedDate = new Date(
                              e
                            ).toLocaleDateString();
                            setendDate(dateString);
                            console.log(formattedDate);
                          }}
                          suffixIcon={calendar}
                          style={{
                            width: "100%",
                            height: 45,
                          }}
                          format={dateFormat}
                        />
                      </ConfigProvider>
                    </div>
                    <div className="col-md-4">
                      <span>
                        Frequency<span className="sign-up-imp-star">*</span>
                      </span>
                      <ConfigProvider
                        theme={{
                          components: {
                            Select: {
                              zIndexPopupBase: 99999,
                              colorText: "#667085",
                              colorTextPlaceholder: "#667085",
                              fontFamily: "Montserrat",
                              fontSize: 16,
                              colorPrimaryHover: "#EF6B3E",
                              optionSelectedBg: "rgba(239, 107, 62, 0.16)",
                              borderRadius: 4,
                            },
                          },
                        }}
                      >
                        <Select
                          suffixIcon={dropdownIcon}
                          placeholder="Select Frequency"
                          style={{
                            width: "100%",
                            height: 45,
                          }}
                          onChange={(e) => setFrequency(e)}
                          options={[
                            {
                              value: "Daily",
                              label: "Daily",
                            },
                            {
                              value: "Weekly",
                              label: "Weekly",
                            },
                            {
                              value: "Monthly",
                              label: "Monthly",
                            },
                            {
                              value: "Quarterly",
                              label: "Quarterly",
                            },
                            {
                              value: "Yearly",
                              label: "Yearly",
                            },
                          ]}
                        />
                      </ConfigProvider>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="row mt-3">
                <div className="col-md-12">
                  <span>Upload Media</span>
                  <div className="dragger">
                    <FileUploader
                      setImages={setImages}
                      Images={Images}
                      setDeletedImages={setDeletedImages}
                      DeletedImages={DeletedImages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-content-footer-section-scroll p-custom">
            <div className="row mt-3">
              <div className="col-md-12 d-flex justify-content-between">
                <div className="modal-check-box-container d-flex align-items-center">
                  <input
                    onChange={(e) => {
                      setAssigneeChecked(e.target.checked);
                    }}
                    type="checkbox"
                    name=""
                    id=""
                    checked={assigneeChecked}
                    className={assigneeChecked ? "checked me-2" : "me-2"}
                  />{" "}
                  <span className="me-2">Notify Assignee</span>
                  <input
                    onChange={(e) => {
                      setNotifyTenantChecked(e.target.checked);
                    }}
                    type="checkbox"
                    name=""
                    id=""
                    checked={notifyTenantChecked}
                    className={notifyTenantChecked ? "checked ms-2" : "me-2"}
                  />{" "}
                  <span className="">Notify Tenant</span>
                </div>
                <div className="task-modal-footer-btn">
                  <Component.SaveModalButton
                    addTask={UpdateTask}
                    loader={loader}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTaskModal;
