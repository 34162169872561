import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import ProfessionalDetails from "Components/ProfessionalDetails/ProfessionalDetails";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const ServiceProfessionalsDetail = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Water Zone "}
        // back="all-service-professional"
        pop={true}
      />
      <ProfessionalDetails />
    </div>
  );
};

export default ServiceProfessionalsDetail;
