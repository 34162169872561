import React from "react";
import { Tabs, ConfigProvider } from "antd";

const CustomTabs = ({ items, defaultActiveKey = "1", onChange }) => (
  <ConfigProvider
    theme={{
      components: {
        Tabs: {
          inkBarColor: "#EF6B3E",
          itemColor: "#667085",
          itemSelectedColor: "#EF6B3E",
          itemHoverColor: "#EF6B3E",
          titleFontSize: 15,
          horizontalItemGutter: window.innerWidth <= 768 ? 10 : 60,
          fontFamily: "Montserrat",
        },
      },
    }}
  >
    <Tabs
      centered={window.innerWidth > 450}
      defaultActiveKey={defaultActiveKey}
      style={{ fontWeight: 500 }}
      onChange={onChange}
      items={items}
    />
  </ConfigProvider>
);

export default CustomTabs;
