import React, { useState } from "react";
import { Collapse, Switch, Button, ConfigProvider } from "antd";
import plusIcon from "assets/plus.png";
import plusIconOrange from "assets/plus-orange.png";
import { residenceType } from "utils/dropdownData";
import { usaStates } from "utils/dropdownData";
import {
  DeleteOutlined,
  PlusOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  InputField,
  PhoneNumberField,
  DateField,
  SelectField,
  SubmitButton,
  RadioGroupField,
  AmountField,
} from "Components/GeneralComponents";
import { formatDate } from "utils";
import MediaField from "./MediaField";
const { Panel } = Collapse;

const RentalHistory = ({
  form,
  errors,
  handleChange,
  addRentalHistory,
  removeRentalHistory,
  handleNext,
  handlePrev,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleImagesChange = (images) => {
    console.log("Images received: ",images);
    // handleChange("images", index, [...rentHistory.images, ...newImages])
  }

  return (
    <div>
      <Collapse expandIconPosition={"start"}>
        {form.map((rentHistory, index) => (
          <Panel
            header={<b>Rental History {index + 1}</b>}
            key={index}
            // extra={
            //   <DeleteOutlined
            //     onClick={(event) => {
            //       event.stopPropagation();
            //       removeWorkHistory(index);
            //     }}
            //   />
            // }
          >
            {console.log(rentHistory)}
            <div className="d-flex justify-content-end mb-3">
              <div
                style={{
                  fontSize: "16px",
                  color: "#ff4d4f",
                  cursor: "pointer",
                  border: "1px solid #ff4d4f",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}
                onClick={() => removeRentalHistory(index)}
              >
                <DeleteOutlined />
                Delete
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <SelectField
                  name={"Residence Type"}
                  required={true}
                  label={"Residence Type"}
                  placeholder={"Residence Type"}
                  value={rentHistory.residenceType}
                  options={residenceType}
                  handler={(e) => handleChange("residenceType", index, e)}
                  errorMessage={errors.residenceType}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3">
                <InputField
                  name={"Street Address"}
                  required={true}
                  label={"Street Address"}
                  placeholder={"Street Address"}
                  value={rentHistory.streetAddress}
                  handler={(e) =>
                    handleChange("streetAddress", index, e.target.value)
                  }
                  errorMessage={errors.streetAddress}
                />
              </div>
              <div className="col-md-6 mt-3">
                <InputField
                  name={"Apt or Unit"}
                  label={"Apt or Unit"}
                  placeholder={"Apt or Unit"}
                  value={rentHistory.aptOrUnit}
                  handler={(e) =>
                    handleChange("aptOrUnit", index, e.target.value)
                  }
                  errorMessage={errors.aptOrUnit}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mt-3">
                <SelectField
                  name={"State"}
                  required={true}
                  label={"State"}
                  placeholder={"Select State"}
                  value={rentHistory.state}
                  options={usaStates}
                  handler={(e) => handleChange("state", index, e)}
                  errorMessage={errors.state}
                />
              </div>
              <div className="col-md-4 mt-3">
                <InputField
                  name={"Postal code"}
                  required={true}
                  label={"Zip Code"}
                  placeholder={"Zip Code"}
                  value={rentHistory.zipCode}
                  handler={(e) => {
                    if (/^\d{0,5}$/.test(e.target.value))
                      handleChange("zipCode", index, e.target.value);
                  }}
                  errorMessage={errors.zipCode}
                />
              </div>
              <div className="col-md-4 mt-3">
                <SelectField
                  name={"Country"}
                  required={true}
                  label={"Country"}
                  placeholder={"Select Country"}
                  value={"USA"}
                  options={[{ name: "USA" }, { name: "Canada" }]}
                  handler={(e) => handleChange("country", index, e)}
                  errorMessage={errors.country}
                  disabled={true}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="d-flex align-items-center">
                  <input type="checkbox" className="me-2" />
                  <span className="ms-2 street-address-text">
                    This is my current address
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3">
                <DateField
                  required={true}
                  label={"Move-in Date"}
                  placeholder={"Select Date"}
                  defaultValue={
                    rentHistory.moveInDate && formatDate(rentHistory.moveInDate)
                  }
                  handler={(e) => {
                    const formattedDate = new Date(e).toLocaleDateString();
                    handleChange("moveInDate", index, formattedDate);
                  }}
                  errorMessage={errors.moveInDate}
                />
              </div>
              <div className="col-md-6 mt-3">
                <DateField
                  required={true}
                  label={"Move-out Date"}
                  placeholder={"Select Date"}
                  defaultValue={
                    rentHistory.moveOutDate &&
                    formatDate(rentHistory.moveOutDate)
                  }
                  handler={(e) => {
                    const formattedDate = new Date(e).toLocaleDateString();
                    handleChange("moveOutDate", index, formattedDate);
                  }}
                  errorMessage={errors.moveOutDate}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <span className="tenant-personal-details-title">
                  Is this reference an individual landlord or a property
                  management company?
                  <span className="input-field-imp-star">*</span>
                </span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12 d-flex gap-3">
                <RadioGroupField
                  value={rentHistory.referenceType}
                  options={[
                    {
                      label: "Individual Landlord",
                      value: "Individual Landlord",
                    },
                    {
                      label: "Property Management",
                      value: "Property Management",
                    },
                  ]}
                  handler={(e) => handleChange("referenceType", index, e)}
                  errorMessage={errors.referenceType}
                  direction="row"
                  gap="5"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3">
                <InputField
                  name={"Full Name"}
                  required={true}
                  label={"Full Name(Landlord or Business)"}
                  placeholder={"Full Name"}
                  value={rentHistory.fullName}
                  handler={(e) =>
                    handleChange("fullName", index, e.target.value)
                  }
                  errorMessage={errors.fullName}
                />
              </div>
              <div className="col-md-6 mt-3">
                <AmountField
                  name={"Monthly Rent Amount"}
                  required={true}
                  label={"Monthly Rent Amount"}
                  placeholder={"Monthly Rent Amount"}
                  value={rentHistory.monthlyRentAmount}
                  handler={(e) => handleChange("monthlyRentAmount", index, e)}
                  errorMessage={errors.monthlyRentAmount}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3">
                <InputField
                  name={"Email"}
                  required={true}
                  label={"Email"}
                  placeholder={"Email"}
                  value={rentHistory.email}
                  handler={(e) => handleChange("email", index, e.target.value)}
                  errorMessage={errors.email}
                />
              </div>
              <div className="col-md-6 mt-3">
                <PhoneNumberField
                  name={"Phone No"}
                  required={true}
                  label={"Phone No"}
                  placeholder={"Phone No"}
                  value={rentHistory.phone}
                  handler={(e) => handleChange("phone", index, e)}
                  errorMessage={errors.phone}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-3">
                <MediaField
                  Images={rentHistory.images}
                    setImages={(images)=>{handleChange("images", index, images)}}


                />
              </div>
            </div>
          </Panel>
        ))}
      </Collapse>
      <div className="row mt-3">
        <div className="col-md-12">
          <button
            onClick={() => {
              addRentalHistory(true);
            }}
            className="add-employment-btn-white"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img
              src={isHovered ? plusIcon : plusIconOrange}
              className="add-property-icon-white"
            />{" "}
            Add Rental History
          </button>
        </div>
      </div>

      <div className="stepper-btn d-flex justify-content-between mt-5 gap-1">
        <div style={{ width: "50%" }}>
          <SubmitButton type="contained" handler={handlePrev}>
            Back
          </SubmitButton>
        </div>
        <div style={{ width: "50%" }}>
          <SubmitButton handler={handleNext}>Next</SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default RentalHistory;
