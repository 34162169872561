import React, { useRef, useState } from "react";
import FilterIcon from "assets/filter.png";
import { ConfigProvider, DatePicker } from "antd";
import { useDispatch } from "react-redux";
import { FilterValue } from "Store/Slices/FilterSlice";
import calendar from "assets/calendar.png";
import { FilterPopup } from "Components/GeneralComponents";

const TenantFilter = ({ innerPage }) => {
  const [taskFilterDropdown, settaskFilterDropdown] = useState(false);
  const [filterVal, setFilterVal] = useState({});
  const [date, setDate] = useState({ fromDate: "", toDate: "" });
  const [isFilterClicked, setIsFilterClicked] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const calendarRef = useRef(null);
  const dispatch = useDispatch();

  const handleOptionSelect = (fieldName, value) => {
    setFilterVal((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  //Filters
  const arrowHandler = (fieldName) => (value) =>
    handleOptionSelect(fieldName, value);
  const handleFilter = () => {
    if (filterVal.value) {
      const value = filterVal.value;
      dispatch(FilterValue({ value }));
    }
    if (filterVal.property) {
      const property = filterVal.property;
      dispatch(FilterValue({ property }));
    }
    if (filterVal.value || filterVal.property || date.fromDate || date.toDate) {
      setIsFilterClicked(true);
    }
    settaskFilterDropdown(false);
  };

  const handleCalendarClick = () => {
    setShowCalendar(!showCalendar);
    if (!showCalendar) {
      setShowCalendar(true);
    }
  };

  const handleDateChange = (dates) => {
    const fromDate = new Date(dates[0]).toLocaleDateString();
    const toDate = new Date(dates[1]).toLocaleDateString();
    dispatch(FilterValue({ toDate }));
    dispatch(FilterValue({ fromDate }));
    setDate({ fromDate, toDate });
    if (dates.length === 2) {
      setShowCalendar(false);
      setIsFilterClicked(true);
    }
  };

  const handleCalendarContainerClick = (event) => {
    event.stopPropagation();
  };

  const resetFilter = () => {
    dispatch(FilterValue({ reset: "reset" }));
    setFilterVal({});
    setDate({
      fromDate: "",
      toDate: "",
    });
    if (filterVal.value || filterVal.property || date.fromDate || date.toDate) {
      setIsFilterClicked(true);
    }
    settaskFilterDropdown(false);
  };

  return (
    <>
      <div className="d-flex gap-3 justify-content-center align-items-center">
        <div
          onClick={handleCalendarClick}
          className="calendar cursor"
          ref={calendarRef}
        >
          <img src={calendar} alt="" />
          {showCalendar && (
            <div
              className="calendar-container"
              onClick={handleCalendarContainerClick}
            >
              <ConfigProvider
                theme={{
                  components: {
                    DatePicker: {
                      cellWidth: window.innerWidth <= 500 ? 20 : 36,
                      // padding: window.innerWidth <= 500 ? 6 : 8,
                      fontSize: window.innerWidth <= 500 ? 12 : 14,
                      cellHeight: window.innerWidth <= 500 ? 18 : 24,
                      colorPrimary: "#EF6B3E",
                      cellActiveWithRangeBg: "#FFEAD5",
                    },
                  },
                }}
              >
                <DatePicker.RangePicker
                  value={dateRange}
                  onChange={handleDateChange}
                  open={true}
                  className="searchbar-datepicker"
                />
              </ConfigProvider>
            </div>
          )}
        </div>
        <button
          onClick={() => {
            settaskFilterDropdown(!taskFilterDropdown);
          }}
          className="filter-btn d-flex align-items-center"
        >
          {" "}
          <img src={FilterIcon} alt="" /> Filter
        </button>
        <button
          onClick={resetFilter}
          className="task-filter-dropdown-btn w-100"
          disabled={(filterVal.value || filterVal.property || date.fromDate || date.toDate) && isFilterClicked ? false : true }
        >
          Reset
        </button>

        <FilterPopup
          isOpen={taskFilterDropdown}
          onclose={() => settaskFilterDropdown(false)}
          filterVal={filterVal}
          arrowHandler={arrowHandler}
          handleSubmit={handleFilter}
          filterList={{
            date: true,
            range: true,
            property: !innerPage,
          }}
        />
      </div>
    </>
  );
};

export default TenantFilter;
