import React, { useState, useEffect } from "react";
import validateError from "assets/alert-circle-validate.png";
import { Select, ConfigProvider, Tooltip } from "antd";
import chevronIcon from "assets/chevron-down.png";
import helpIcon from "assets/help-circle.png";

let dropdownIcon = <img src={chevronIcon} alt="" />;

const SelectField = ({
  mode,
  required = false,
  label = "",
  TooltipTitle,
  placeholder,
  value,
  handler,
  isError = false,
  errorMessage = false,
  options,
  disabled,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const maxTagsToShow = isMobile ? 1 : 3;

  return (
    <div className="position-relative w-100">
      {label && (
        <span className="tenant-personal-details-title">
          {label}{" "}
         {required &&  <span className="sign-up-imp-star">{`${required ? "*" : ""}`}</span>}
          {TooltipTitle && (
            <Tooltip title={TooltipTitle} overlayStyle={{ zIndex: 99999 }}>
              <img src={helpIcon} alt="" className="cursor ps-2" />
            </Tooltip>
          )}
        </span>
      )}
      <div style={{ position: "relative" }}>
        <ConfigProvider
          theme={{
            components: {
              Select: {
                zIndexPopupBase: 99999,
                colorPrimaryHover: "#EF6B3E",
                optionSelectedBg: "rgba(239, 107, 62, 0.16)",
                borderRadius: 4,
                colorTextPlaceholder: "#667085",
                fontFamily: "montserrat",
              },
            },
          }}
        >
          <Select
            mode={mode}
            value={value || null}
            showSearch
            placeholder={placeholder}
            onChange={handler}
            suffixIcon={!disabled ? dropdownIcon : null}
            disabled={disabled}
            style={{
              width: "100%",
              height: 45,
              textAlign: "left",
            }}
            className={(isError || errorMessage) && `date-error-input`}
            maxTagCount={maxTagsToShow}
            maxTagPlaceholder={(omittedValues) => (
              <Tooltip
                overlayStyle={{
                  pointerEvents: "none",
                }}
                title={omittedValues.map(({ label }) => label).join(", ")}
              >
                <span>+{omittedValues.length}</span>
              </Tooltip>
            )}
            options={options.map((e) => {
              return { value: e.value, label: e.label };
            })}
          />
        </ConfigProvider>
        {isError && (
          <div className="validate-icon1">
            <img src={validateError} alt="" />
          </div>
        )}
      </div>
      {errorMessage && (
        <div className="text-danger fw-bold mt-2" style={{ fontSize: "12px" }}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default SelectField;
