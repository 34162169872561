import React from "react";
import { LockFilled } from "@ant-design/icons";
import "style/LockWrapper.css";
import { Tooltip } from "antd";

const LockWrapper = ({ className = "", isLocked, tooltip, children }) => {
  return (
    <Tooltip title={tooltip}>
      <div className={`lock-wrapper ${className}`}>
        {isLocked && (
          <div className="lock-overlay">
            <LockFilled className="lock-icon" />
          </div>
        )}
        <div className={`h-100 ${isLocked ? "locked-content" : ""}`}>
          {children}
        </div>
      </div>
    </Tooltip>
  );
};

export default LockWrapper;
