import React, { useEffect, useRef } from "react";
import * as Icons from "assets/icons";

const RecordMenuPopup = ({
  isOpen,
  onClose,
  handler,
  fields = {
    view: false,
    payNow: false,
    edit: false,
    makedefault: false,
    delete: false,
    viewReport: false,
    sendCreditCheck: false,
    convertToTenant: false,
    download: false,
    renewLease: false,
    endLease: false,
    sendInvite: false,
    postAnUpdate: false,
    complete: false,
    uploadDocument: false,
    editdraft: false,
    editinvoice: false,
    sendreminder: false,
    stopautoreminder: false,
    recordpayment: false,
    downloadaspdf: false,
    print: false,
    archive: false,
    cancel: false,
    editseries: false,
    unarchive: false,
    workOrderUpdate: false,
  },
  links = {
    view: "",
    edit: "",
  },
}) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {isOpen && (
        <div
          ref={popupRef}
          className="task-table-setting-dropdown-prospect bg-white box-shadow"
        >
          <ul className="p-0 d-flex flex-column gap-3">
            {fields.view && (
              <li
                onClick={handler.handleView}
                className="list-style-none table-setting-dropdown-menu"
              >
                <Icons.OpenEyeIcon width={18} height={18} />
                <span className="m-2">View</span>
              </li>
            )}

            {fields.sendInvite && (
              <li
                className="list-style-none table-setting-dropdown-menu"
                onClick={handler.handleSendInvite}
              >
                <Icons.PaperAirlineIcon width={18} height={18} />
                <span className="m-2">Send Invite</span>
              </li>
            )}

            {fields.payNow && (
              <li
                className="list-style-none table-setting-dropdown-menu"
                onClick={handler.handlePayNow}
              >
                <Icons.CardIcon />
                <span className="m-2">Pay Now</span>
              </li>
            )}

            {fields.viewReport && (
              <li
                onClick={handler.handleViewReport}
                className="list-style-none table-setting-dropdown-menu"
              >
                <Icons.OpenEyeIcon width={18} height={18} />
                <span className="m-2">View Report</span>
              </li>
            )}

            {fields.edit && (
              <li
                onClick={handler.handleEdit}
                className="list-style-none table-setting-dropdown-menu"
              >
                <Icons.EditIcon width={18} height={18} />
                <span className="m-2">Edit</span>
              </li>
            )}

            {fields.makedefault && (
              <li
                onClick={handler.handleMakeDefault}
                className="list-style-none table-setting-dropdown-menu"
              >
                <Icons.DefaultIcon width={18} height={18} />
                <span className="m-2">Make Default</span>
              </li>
            )}

            {fields.sendCreditCheck && (
              <li
                className="list-style-none table-setting-dropdown-menu"
                onClick={handler.handleSendCreditCheck}
              >
                <Icons.PaperAirlineIcon width={18} height={18} />
                <span className="m-2">Send Credit Check</span>
              </li>
            )}

            {fields.convertToTenant && (
              <li
                className="list-style-none table-setting-dropdown-menu"
                onClick={handler.handleConvertToTenant}
              >
                <Icons.UsersIcon width={18} height={18} />
                <span className="m-2">Convert To Tenant</span>
              </li>
            )}

            {fields.download && (
              <li
                onClick={handler.handleDownload}
                className="list-style-none table-setting-dropdown-menu"
              >
                <Icons.DownloadIcon width={18} height={18} />
                <span className="m-2">Download</span>
              </li>
            )}

            {fields.renewLease && (
              <li
                className="list-style-none table-setting-dropdown-menu"
                onClick={handler.handleRenewLease}
              >
                <Icons.RenewIcon width={18} height={18} />
                <span className="m-2">Renew Lease</span>
              </li>
            )}

            {fields.endLease && (
              <li
                className="list-style-none table-setting-dropdown-menu"
                onClick={handler.handleEndLease}
              >
                <Icons.EndIcon width={18} height={18} />
                <span className="m-2">End Lease</span>
              </li>
            )}

            {fields.postAnUpdate && (
              <li
                className="list-style-none table-setting-dropdown-menu"
                onClick={handler.handlePostAnUpdate}
              >
                <Icons.Edit2icon width={18} height={18} />
                <span className="m-2">Post an Update</span>
              </li>
            )}
            {fields.workOrderUpdate && (
              <li
                className="list-style-none table-setting-dropdown-menu"
                onClick={handler.workOrderUpdate}
              >
                <Icons.Edit2icon width={18} height={18} />
                <span className="m-2">Work Order Update</span>
              </li>
            )}

            {fields.complete && (
              <li
                className="list-style-none table-setting-dropdown-menu"
                onClick={handler.handleComplete}
              >
                <Icons.CalendarIcon width={18} height={18} />
                <span className="m-2">Complete</span>
              </li>
            )}

            {fields.uploadDocument && (
              <li
                onClick={handler.uploadDocument}
                className="list-style-none table-setting-dropdown-menu"
              >
                <Icons.UploadIcon width={18} height={18} />
                <span className="m-2">Upload Document</span>
              </li>
            )}

            {fields.editdraft && (
              <li
                onClick={handler.editdraft}
                className="list-style-none table-setting-dropdown-menu"
              >
                <Icons.EditIcon width={18} height={18} />
                <span className="m-2">Edit Draft</span>
              </li>
            )}

            {fields.editinvoice && (
              <li
                onClick={handler.editinvoice}
                className="list-style-none table-setting-dropdown-menu"
              >
                <Icons.EditIcon width={18} height={18} />
                <span className="m-2">Edit Invoice</span>
              </li>
            )}

            {fields.sendreminder && (
              <li
                onClick={handler.reminder}
                className="list-style-none table-setting-dropdown-menu"
              >
                <Icons.sendReminderIcon width={18} height={18} />
                <span className="m-2">Send Reminder</span>
              </li>
            )}

            {fields.stopautoreminder && (
              <li
                onClick={handler.stopautoreminder}
                className="list-style-none table-setting-dropdown-menu"
              >
                <Icons.stopReminderIcon width={18} height={18} />
                <span className="m-2">Stop Auto Reminder</span>
              </li>
            )}

            {fields.recordpayment && (
              <li
                onClick={handler.recordpayment}
                className="list-style-none table-setting-dropdown-menu"
              >
                <Icons.recordPaymentIcon width={18} height={18} />
                <span className="m-2">Record Payment</span>
              </li>
            )}

            {fields.downloadaspdf && (
              <li
                onClick={handler.downloadaspdf}
                className="list-style-none table-setting-dropdown-menu"
              >
                <Icons.downloadAsPDF width={18} height={18} />
                <span className="m-2">Download as PDF</span>
              </li>
            )}

            {fields.print && (
              <li
                onClick={handler.print}
                className="list-style-none table-setting-dropdown-menu"
              >
                <Icons.printInvoiceIcon width={18} height={18} />
                <span className="m-2">Print</span>
              </li>
            )}

            {fields.archive && (
              <li
                onClick={handler.archive}
                className="list-style-none table-setting-dropdown-menu"
              >
                <Icons.ArchiveIcon width={18} height={18} />
                <span className="m-2">Archive</span>
              </li>
            )}

            {fields.unarchive && (
              <li
                onClick={handler.unarchive}
                className="list-style-none table-setting-dropdown-menu"
              >
                <Icons.UnarchiveIcon width={18} height={18} />
                <span className="m-2">Unarchive</span>
              </li>
            )}

            {fields.cancel && (
              <li
                onClick={handler.cancel}
                className="list-style-none table-setting-dropdown-menu"
              >
                <Icons.CancelIcon width={18} height={18} />
                <span className="m-2">Cancel</span>
              </li>
            )}

            {fields.editseries && (
              <li
                onClick={handler.editseries}
                className="list-style-none table-setting-dropdown-menu"
              >
                <Icons.EditIcon width={18} height={18} />
                <span className="m-2">Edit series</span>
              </li>
            )}

            {fields.delete && (
              <li
                onClick={handler.handleDeleteRecord}
                className="list-style-none table-setting-dropdown-menu"
              >
                <Icons.DeleteIcon width={18} height={18} />
                <span className="m-2">Delete</span>
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export default RecordMenuPopup;
