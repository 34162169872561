import { useEffect, useRef, useState } from "react";
import customizeDashboardIcon from "assets/Group 1000004204.png";
import rightArrow from "assets/chevron-right.png";

const CustomizeLandlordSideDrawer = ({
  close,
  revenue,
  properties,
  recentActivities,
  tenants,
  prospects,
  vacancies,
  task,
  payables,
  mileage,
}) => {
  const [isPlus1, setIsPlus1] = useState(true);
  const [isPlus2, setIsPlus2] = useState(true);
  const [isPlus3, setIsPlus3] = useState(true);
  const [isPlus4, setIsPlus4] = useState(true);
  const [isPlus5, setIsPlus5] = useState(true);
  const [isPlus6, setIsPlus6] = useState(true);
  const [isPlus7, setIsPlus7] = useState(true);
  const [isPlus8, setIsPlus8] = useState(true);
  const [isPlus9, setIsPlus9] = useState(true);

  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        close();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const toggleIcon1 = () => {
    setIsPlus1(!isPlus1);
  };
  const toggleIcon2 = () => {
    setIsPlus2(!isPlus2);
  };
  const toggleIcon3 = () => {
    setIsPlus3(!isPlus3);
  };
  const toggleIcon4 = () => {
    setIsPlus4(!isPlus4);
  };
  const toggleIcon5 = () => {
    setIsPlus5(!isPlus5);
  };
  const toggleIcon6 = () => {
    setIsPlus6(!isPlus6);
  };
  const toggleIcon7 = () => {
    setIsPlus7(!isPlus7);
  };
  const toggleIcon8 = () => {
    setIsPlus8(!isPlus8);
  };
  const toggleIcon9 = () => {
    setIsPlus9(!isPlus9);
  };

  return (
    <>
      <div ref={popupRef} className="customize-landlord-side-drawer-container drawer-bg-primary">
        <div className="side-drawer-logo custom-side-drawer-title text-center d-flex align-items-center justify-content-between">
          <li className="text-white d-flex align-items-center gap-3 p-3">
            <img src={customizeDashboardIcon} alt="" />
            Customize Dashboard
          </li>
          <span onClick={close} className="p-2">
            <img src={rightArrow} alt="" className="cursor" />
          </span>
        </div>
        <div className="mt-3">
          <ul className="side-drawer-app-list mb-3">
            <div className="d-flex align-items-center ">
              <li className="flex-grow-1 me-2 text-white  list-app-right">
                Revenue
              </li>
              <span
                onClick={() => {
                  toggleIcon1();
                  revenue();
                }}
                className="p-2 cursor customize-icon"
              >
                {/* <img src={isHoveredMinus ? minusIconBlack : minusIcon} alt="" /> */}

                {isPlus1 ? (
                  <svg
                    width={27}
                    height={27}
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
                    <path d="M8 12h8" />
                  </svg>
                ) : (
                  <svg
                    width={27}
                    height={27}
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
                    <path d="M12 8v8" />
                    <path d="M8 12h8" />
                  </svg>
                )}
              </span>
            </div>
            <div className="d-flex align-items-center ">
              <li className="flex-grow-1 me-2 text-white  list-app-right">
                Properties
              </li>
              <span
                onClick={() => {
                  toggleIcon2();
                  properties();
                }}
                className="p-2 cursor customize-icon"
              >
                {/* <img src={isHoveredPlus ? plusIconBlack : plusIcon} alt="" /> */}
                {isPlus2 ? (
                  <svg
                    width={27}
                    height={27}
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
                    <path d="M8 12h8" />
                  </svg>
                ) : (
                  <svg
                    width={27}
                    height={27}
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
                    <path d="M12 8v8" />

                    <path d="M8 12h8" />
                  </svg>
                )}
              </span>
            </div>
            <div className="d-flex align-items-center ">
              <li className="flex-grow-1 me-2 text-white  list-app-right">
                Recent Activities
              </li>
              <span
                onClick={() => {
                  toggleIcon3();
                  recentActivities();
                }}
                className="p-2 cursor customize-icon"
              >
                {/* <img src={isHoveredPlus ? plusIconBlack : plusIcon} alt="" /> */}
                {isPlus3 ? (
                  <svg
                    width={27}
                    height={27}
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
                    <path d="M8 12h8" />
                  </svg>
                ) : (
                  <svg
                    width={27}
                    height={27}
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
                    <path d="M12 8v8" />

                    <path d="M8 12h8" />
                  </svg>
                )}
              </span>
            </div>
            <div className="d-flex align-items-center ">
              <li className="flex-grow-1 me-2 text-white  list-app-right">
                Tenants
              </li>
              <span
                onClick={() => {
                  toggleIcon4();
                  tenants();
                }}
                className="p-2 cursor customize-icon"
              >
                {/* <img src={isHoveredMinus ? minusIconBlack : minusIcon} alt="" /> */}
                {isPlus4 ? (
                  <svg
                    width={27}
                    height={27}
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
                    <path d="M8 12h8" />
                  </svg>
                ) : (
                  <svg
                    width={27}
                    height={27}
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
                    <path d="M12 8v8" />
                    <path d="M8 12h8" />
                  </svg>
                )}
              </span>
            </div>
            <div className="d-flex align-items-center ">
              <li className="flex-grow-1 me-2 text-white  list-app-right">
                Prospects
              </li>
              <span
                onClick={() => {
                  toggleIcon5();
                  prospects();
                }}
                className="p-2 cursor customize-icon"
              >
                {/* <img src={isHoveredPlus ? plusIconBlack : plusIcon} alt="" /> */}
                {isPlus5 ? (
                  <svg
                    width={27}
                    height={27}
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
                    <path d="M8 12h8" />
                  </svg>
                ) : (
                  <svg
                    width={27}
                    height={27}
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
                    <path d="M12 8v8" />

                    <path d="M8 12h8" />
                  </svg>
                )}
              </span>
            </div>
            <div className="d-flex align-items-center ">
              <li className="flex-grow-1 me-2 text-white  list-app-right">
                Vacancies
              </li>
              <span
                onClick={() => {
                  toggleIcon6();
                  vacancies();
                }}
                className="p-2 cursor customize-icon"
              >
                {/* <img src={isHoveredPlus ? plusIconBlack : plusIcon} alt="" /> */}
                {isPlus6 ? (
                  <svg
                    width={27}
                    height={27}
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
                    <path d="M8 12h8" />
                  </svg>
                ) : (
                  <svg
                    width={27}
                    height={27}
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
                    <path d="M12 8v8" />

                    <path d="M8 12h8" />
                  </svg>
                )}
              </span>
            </div>
            <div className="d-flex align-items-center ">
              <li className="flex-grow-1 me-2 text-white  list-app-right">
                Task
              </li>
              <span
                onClick={() => {
                  toggleIcon7();
                  task();
                }}
                className="p-2 cursor customize-icon"
              >
                {/* <img src={isHoveredPlus ? plusIconBlack : plusIcon} alt="" /> */}
                {isPlus7 ? (
                  <svg
                    width={27}
                    height={27}
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
                    <path d="M8 12h8" />
                  </svg>
                ) : (
                  <svg
                    width={27}
                    height={27}
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
                    <path d="M12 8v8" />

                    <path d="M8 12h8" />
                  </svg>
                )}
              </span>
            </div>
            <div className="d-flex align-items-center ">
              <li className="flex-grow-1 me-2 text-white  list-app-right">
                Payables
              </li>
              <span
                onClick={() => {
                  toggleIcon8();
                  payables();
                }}
                className="p-2 cursor customize-icon"
              >
                {/* <img src={isHoveredPlus ? plusIconBlack : plusIcon} alt="" /> */}
                {isPlus8 ? (
                  <svg
                    width={27}
                    height={27}
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
                    <path d="M8 12h8" />
                  </svg>
                ) : (
                  <svg
                    width={27}
                    height={27}
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
                    <path d="M12 8v8" />

                    <path d="M8 12h8" />
                  </svg>
                )}
              </span>
            </div>
            <div className="d-flex align-items-center ">
              <li className="flex-grow-1 me-2 text-white  list-app-right">
                Mileage
              </li>
              <span
                onClick={() => {
                  toggleIcon9();
                  mileage();
                }}
                className="p-2 cursor customize-icon"
              >
                {/* <img src={isHoveredPlus ? plusIconBlack : plusIcon} alt="" /> */}
                {isPlus9 ? (
                  <svg
                    width={27}
                    height={27}
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
                    <path d="M8 12h8" />
                  </svg>
                ) : (
                  <svg
                    width={27}
                    height={27}
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
                    <path d="M12 8v8" />

                    <path d="M8 12h8" />
                  </svg>
                )}
              </span>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

export default CustomizeLandlordSideDrawer;
