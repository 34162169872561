import React from "react";
import { selectVehicle } from "utils/dropdownData";
import {
  InputField,
  SelectField,
  SubmitButton,
} from "Components/GeneralComponents";
import DropdownLayout from "../GeneralComponents/DropdownLayout";
import "../../style/dropdown.css";

const VehicleForm = ({
  vehicles,
  handleChange,
  handleBackButton,
  handleNext,
  addAnotherVehicle,
  removeVehicle,
  isLoading = false,
  isSubmitForm = false,
  disabled = false,
}) => {
  console.log("VehicleForm -> vehicles", vehicles);
  return (
    <div className="container-fluid">
      {vehicles.map((vehicle, index) => (
        <DropdownLayout key={index} title={`Vehicle ${index + 1}`}>
          <div key={index} className="vehicle-container">
            <div className="row top-padd-add">
              <div className="col-md-12">
                <SelectField
                  name={"vehicleType"}
                  label={"Vehicle Type"}
                  placeholder={"Select Vehicle Type"}
                  value={vehicle.vehicleType}
                  handler={(e) => handleChange("vehicleType", index, e)}
                  options={selectVehicle}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <InputField
                  name={"vehicleMake"}
                  label={"Make"}
                  placeholder={"Make"}
                  value={vehicle.vehicleMake}
                  handler={(e) =>
                    handleChange(e.target.name, index, e.target.value)
                  }
                  disabled={disabled}
                />
              </div>
              <div className="col-md-6">
                <InputField
                  name={"vehicleModel"}
                  label={"Model"}
                  placeholder={"Model"}
                  value={vehicle.vehicleModel}
                  handler={(e) =>
                    handleChange(e.target.name, index, e.target.value)
                  }
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <InputField
                  name={"vehicleYear"}
                  type={"number"}
                  label={"Year"}
                  placeholder={"Year"}
                  value={vehicle.vehicleYear}
                  handler={(e) =>
                    handleChange(e.target.name, index, e.target.value)
                  }
                  disabled={disabled}
                />
              </div>
              <div className="col-md-4">
                <InputField
                  name={"vehicleColor"}
                  label={"Color"}
                  placeholder={"Color"}
                  value={vehicle.vehicleColor}
                  handler={(e) =>
                    handleChange(e.target.name, index, e.target.value)
                  }
                  disabled={disabled}
                />
              </div>
              <div className="col-md-4">
                <InputField
                  name={"vehicleLicense"}
                  label={"License Plate"}
                  placeholder={"License Plate"}
                  value={vehicle.vehicleLicense}
                  handler={(e) =>
                    handleChange(e.target.name, index, e.target.value)
                  }
                  disabled={disabled}
                />
              </div>
              {index !== 0 && (
                <div
                  className="row button"
                  onClick={() => removeVehicle(index)}
                >
                  <div className="col-md-12">
                    <button
                      onClick={() => removeVehicle(index)}
                      className="primary-orange-text add-new-btn"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </DropdownLayout>
      ))}

      <div className="row mt-3 button" onClick={addAnotherVehicle}>
        <div className="col-md-12">
          <button
            onClick={addAnotherVehicle}
            className="primary-orange-text add-new-btn"
          >
            + Add Another Vehicle
          </button>
        </div>
      </div>

      <div className="d-flex justify-content-between gap-1 pb-3 mt-5">
        <div style={{ width: "50%" }}>
          <SubmitButton type="contained" handler={handleBackButton}>
            Back
          </SubmitButton>
        </div>
        <div style={{ width: "50%" }}>
          <SubmitButton handler={handleNext} isLoading={isLoading}>
            {isSubmitForm ? "Submit" : "Next"}
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default VehicleForm;
