import React, { useState, useEffect } from "react";
import NotFound from "assets/not-found-img.png";
import { FileForm } from "./index";
import { message, Avatar } from "antd";
import SearchBar from "Helpers/SearchBar";
import trashIconWhite from "assets/trash-icon-white.png";
import { Link } from "react-router-dom";
import excelIcon from "assets/excel.jpg";
import wordIcon from "assets/word.jpg";
import pdfIcon from "assets/pdf.jpg";
import settingIcon from "assets/three-dots.png";
import { useSelector } from "react-redux";
import { FileAndNoteTabFormData } from "utils/FormDataStructure";
import { validateTenantFileForm } from "utils/validations";
import { removeEmptyValues } from "utils";
import * as Component from "Components/GeneralComponents";
import * as API from "network/landlord/file";

const FilesTab = ({ id, category, properties }) => {
  const [showAddFile, setShowAddFile] = useState(false);
  const [errors, setErrors] = useState({});
  const [Images, setImages] = useState([]);
  const [selectedTableItem, setSelectedTableItem] = useState("");
  const [key, setKey] = useState();
  const [deleteId, setDeleteId] = useState("");
  const [files, setFiles] = useState([{
    key: 1,
    id: 1,
    fileName: ["https://www.google.com", "file1"],
    property: "property 1",
    owner: "John Doe",
    date: "12/12/2021",
  },
  {
    key: 2,
    id: 2,
    fileName: ["https://www.google.com", "file2"],
    property: "property 2",
    owner: "John Doe",
    date: "12/12/2021",
  },
  {
    key: 3,
    id: 3,
    fileName: ["https://www.google.com", "file3"],
    property: "property 3",
    owner: "John Doe",
    date: "12/12/2021",

  }]);
  const [isFilesRerender, setIsFilesRerender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isOpenBulkDeleteModal, setIsOpenBulkDeleteModal] = useState(false);
  const [filtredFiles, setFiltredFiles] = useState([]);
  const [form, setForm] = useState({ file_name: "", relatedToProperty: "" });

  // filter values
  const range = useSelector((state) => {
    return state.FilterValue.value;
  });
  const fProperty = useSelector((state) => {
    return state.FilterValue.property;
  });
  const toDate = useSelector((state) => {
    return state.FilterValue.toDate;
  });
  const fromDate = useSelector((state) => {
    return state.FilterValue.fromDate;
  });
  const search = useSelector((state) => {
    return state.Search.value;
  });



  // }, [isFilesRerender, range, fProperty, toDate, fromDate]);

  // handle search filter
  useEffect(() => {
    if (search) {
      const filteredData = files.filter((data) => {
        if (data.fileName[1].toLowerCase().includes(search.toLowerCase()))
          return data;
        else if (data.property.toLowerCase().includes(search.toLowerCase()))
          return data;
        else if (data.owner.toLowerCase().includes(search.toLowerCase()))
          return data;
        else if (data.date.toLowerCase().includes(search.toLowerCase()))
          return data;
      });
      setFiltredFiles(filteredData);
    } else {
      setFiltredFiles(files);
    }
  }, [search, files]);

  const handleChange = (fieldName, value) => {
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  // Add File handle
  const addTenantFiles = () => {
    const formData = FileAndNoteTabFormData({
      fileName: form.file_name,
      relatedToProperty: form.relatedToProperty,
      images: Images,
      [category]: id,
    });
    const newErrors = validateTenantFileForm({ ...form, files: Images },category);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0 && Images.length !== 0) {
      setIsLoader(true);


    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTableItem(selectedRows.map((item) => item.id));
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const filesColumns = [
    {
      title: "File Name",
      dataIndex: "fileName",
      render: (text) => (
        <>
          <Link
            to=""
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {" "}
            <div className="table-file-container d-flex align-items-center gap-3">
              <div className="table-file-img">
                <img
                  className="property-table-image mw_40 mh_40 me-2 rounded-5"
                  src={`${
                    text[0].includes("pdf")
                      ? pdfIcon
                      : text[0].includes("xlsx")
                      ? excelIcon
                      : text[0].includes("docx")
                      ? wordIcon
                      : text[0]
                  }`}
                  alt=""
                />
              </div>
              <div className="table-file-text">
                <p
                  onClick={() => window.open(text[0])}
                  className="m-0 all-files-table-name-text"
                >
                  {text[1]}
                </p>
              </div>
            </div>
          </Link>
        </>
      ),
    },
    {
      title: "Properties",
      dataIndex: "property",
      render: (text) => (
        <>
          <span className="tenant_table_properties_main_text">{text}</span>
          <br />
        </>
      ),
    },
    {
      title: "Owner",
      dataIndex: "owner",
      render: (text, type) => (
        <>
          <Avatar
            style={{
              backgroundColor: "#EF6B3E",
              verticalAlign: "middle",
            }}
            size="large"
          >
            {localStorage.getItem("name")[0]}
          </Avatar>
          <span className="tenant_table_name_text ms-3">
            {localStorage.getItem("name")}
          </span>
        </>
      ),
    },
    {
      title: "Date Uploaded",
      dataIndex: "date",
      render: (text) => (
        <>
          <span className="tenant_table_properties_sub_text">{text}</span>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <img src={settingIcon} alt="" />
            <Component.RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              fields={{ download: true, delete: true }}
              handler={{
                handleDownload: () =>{
                
                },
                handleDeleteRecord: () => {
                  setDeleteId(setting.id);
                  setOpenDeleteModal(true);
                },
              }}
            />
          </div>
        </>
      ),
    },
  ];

  // Data Table Functions
  const handleIconClick = (result) => {
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  // Cancel and Reset File Form
  const handleCancelFileForm = () => {
    setShowAddFile(false);
    setErrors({});
    setForm({ file_name: "" });
    setImages([]);
  };

  // handle delete file
  const handleDeleteFile = () => {
    setIsLoader(true);
  };

  // handle bulk delete file
  const handleBulkDeleteFile = () => {
    setIsLoader(true);
  };

  return (
   <>
      <Component.DeleteModal
        isOpen={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        message={"Are you sure you want to delete this File?"}
        deleteCall={handleDeleteFile}
        isLoading={isLoader}
      />

      <Component.DeleteModal
        isOpen={isOpenBulkDeleteModal}
        onClose={() => setIsOpenBulkDeleteModal(false)}
        message={"Are you sure you want to delete these files?"}
        deleteCall={handleBulkDeleteFile}
        isLoading={isLoader}
      />

      {showAddFile === true ? (
        <>
          <FileForm
            form={form}
            errors={errors}
            handleChange={handleChange}
            Images={Images}
            setImages={setImages}
            properties={properties}
            handleCancel={handleCancelFileForm}
            handleSubmit={addTenantFiles}
            isLoading={isLoader}
          />
        </>
      ) : (
        <>
          <SearchBar
            btnTitle="Add New File"
            onClick={() => setShowAddFile(true)}
            tenantFilter={true}
          />
          <div className="row">
            <div className="col-md-12">
              {selectedTableItem.length >= 1 && (
                <div className="table-delete-icon mt-3">
                  <button
                    className="table-delete-btn next-btn-main"
                    onClick={() => setIsOpenBulkDeleteModal(true)}
                  >
                    <img src={trashIconWhite} alt="" />
                    Delete
                  </button>
                </div>
              )}
              <div className="task-table-container  mt-3">
                <Component.CustomTable
                  loading={isLoading}
                  pagination={true}
                  rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                  }}
                  columns={filesColumns}
                  dataSource={filtredFiles}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div className="not-found-container text-center d-none">
        <img src={NotFound} alt="" />
        <p>
          <strong>No Files found</strong>
        </p>
        <p>
          No files were found; the folder is empty. <br />
          Please try again.
        </p>
        <button className="not-found-add-task-btn primary-orange-text">
          Add Files
        </button>
      </div>
   </>
  )
}

export default FilesTab ;
