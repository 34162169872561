import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLandlordArchive } from "network/landlord/chat";
import { getTenantArchive } from "network/tenant/chat";
import ChatUserList from "../ChatUserList/ChatUserList";
const ArchiveChatUser = () => {
  const [messages, setMessages] = useState([]);
  const role = localStorage.getItem("role");
  useEffect(() => {
    if (role === "landlord" || role === "user") {
      getLandlordArchive().then((res) => {
        if (res.apiCallStatus === "success") {
          setMessages(res.message);
        }
      });
    } else {
      getTenantArchive().then((res) => {
        if (res.apiCallStatus === "success") {
          setMessages(res.message);
        }
      });
    }
  }, []);
  const navigate = useNavigate();
  return (
    <div
      className="flex-fill"
      >
      <ChatUserList archive={true} />
    </div>
  );
};

export default ArchiveChatUser;
