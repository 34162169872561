import { ConfigProvider, Switch } from "antd";
import React, { useState, useEffect } from "react";
import {
  getNotificationSettings,
  updateNotificationSettings,
} from "network/landlord/setting";

const SettingsNotification = () => {
  const [emailNotification, setEmailNotification] = useState(true);
  const [pushNotification, setPushNotification] = useState(true);
  const [soundVibration, setSoundVibration] = useState(true);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    getNotificationSettings().then((res) => {
      if (res.apiCallStatus === "success") {
        setEmailNotification(res.message.data.emailNotification);
        setPushNotification(res.message.data.pushNotification);
        setSoundVibration(res.message.data.soundVibration);
      }
    });
    setIsUpdated(true);
  }, []);

  useEffect(() => {
    if (isUpdated) {
      const data = {
        emailNotification,
        pushNotification,
        soundVibration,
      };
      updateNotificationSettings(data).then((res) => {
        if (res.apiCallStatus === "success") {
          console.log("User role success");
        } else {
          console.log(res, "error");
        }
      });
    }
  }, [emailNotification, pushNotification, soundVibration]);

  return (
    <div>
      <p className="heading pt-4 pb-5 ps-4">NOTIFICATION</p>
      <div className="px-4">
        <div className="d-flex justify-content-between border-bottom">
          <div>
            <p
              className="mb-0"
              style={{
                fontSize: "16px",
                fontWeight: "500",
                fontFamily: "Montserrat",
              }}
            >
              Email Notifications
            </p>
            <p className="normal-grey-text">
              Receive updates and alerts via your registered email.
            </p>
          </div>
          <ConfigProvider
            theme={{
              components: {
                Switch: {
                  colorPrimary: "#EF6B3E",
                  colorPrimaryHover: "#EF6B3E",
                  fontSize: 14,
                },
              },
            }}
          >
            <Switch
              size="large"
              checked={emailNotification}
              onChange={(checked) => {
                setEmailNotification(checked);
              }}
            />
          </ConfigProvider>
        </div>
        <div className="mt-4 d-flex justify-content-between border-bottom">
          <div>
            <p
              className="mb-0"
              style={{
                fontSize: "16px",
                fontWeight: "500",
                fontFamily: "Montserrat",
              }}
            >
              Push Notifications
            </p>
            <p className="normal-grey-text">
              Receive real-time alerts directly on your device.
            </p>
          </div>
          <ConfigProvider
            theme={{
              components: {
                Switch: {
                  colorPrimary: "#EF6B3E",
                  colorPrimaryHover: "#EF6B3E",
                  fontSize: 14,
                },
              },
            }}
          >
            <Switch
              size="large"
              checked={pushNotification}
              onChange={(checked) => {
                setPushNotification(checked);
              }}
            />
          </ConfigProvider>
        </div>
        <div className="mt-4 d-flex justify-content-between">
          <div>
            <p
              className="mb-0"
              style={{
                fontSize: "16px",
                fontWeight: "500",
                fontFamily: "Montserrat",
              }}
            >
              Sound & Vibration
            </p>
            <p className="normal-grey-text">
              Enable sound or vibration for new notifications.
            </p>
          </div>
          <ConfigProvider
            theme={{
              components: {
                Switch: {
                  colorPrimary: "#EF6B3E",
                  colorPrimaryHover: "#EF6B3E",
                  fontSize: 14,
                },
              },
            }}
          >
            <Switch
              size="large"
              checked={soundVibration}
              onChange={(checked) => {
                setSoundVibration(checked);
              }}
            />
          </ConfigProvider>
        </div>
      </div>
    </div>
  );
};

export default SettingsNotification;
