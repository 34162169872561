import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Avatar } from "antd";

const Notification = ({
  profileImage,
  title,
  message,
  createdAt,
  redirectUrl,
}) => {
  return (
    <>
      <div className="container-fluid bg-white">
        <div className="row">
          <div className="col-md-12">
            <Link to={redirectUrl} className="notification-ui_dd-content">
              <div className="notification-list">
                <div className="notification-list_content">
                  <div className="notification-list_img me-4">
                    <Avatar
                      src={profileImage}
                      size="large"
                      style={{border: "2px solid #E5E5E5"}}
                    />
                  </div>
                  <div className="notification-list_detail d-flex flex-column justify-content-center">
                    <p className="notification-user-name"> {title} </p>
                    <p className="notification-user-title">{message}</p>
                    <p className="notification-time-text d-sm-none">
                    {moment(createdAt).format("LT")}
                  </p>
                  </div>
                </div>
                <div className="notification-list_time align-self-end">
                  <p className="notification-time-text d-none d-sm-block">
                    {moment(createdAt).format("LT")}
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
