import React from "react";
import cancelIcon from "assets/x-circle.png";
import { DeleteIcon } from "assets/icons";
import { ThreeDotsLoader } from "Components/GeneralComponents";
import alertIcon from "assets/alert-circle.png";

const Delete = ({
  isOpen,
  onClose,
  deleteCall,
  message,
  isLoading,
  isForceDelete,
  disableBtn = false,
}) => {
  const handleDelete = () => {
    deleteCall();
  };

  return (
    <>
      {isOpen && (
        <>
          <div className="payment-modal-container">
            <div className="vendor-success-modal position-relative">
              <div className="row">
                <div className="col-md-12 text-center">
                  {/* <DeleteIcon /> */}
                  <img src={alertIcon} alt="" />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12 text-center">
                  <p className="normal-grey-text convert-tenant-modal-text">
                    {message}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center mt-2 p-0">
                  <button
                    onClick={handleDelete}
                    className={`${
                      disableBtn
                        ? "disable-delete-modal-delete-btn w-100 btn-box-shadow"
                        : "delete-modal-delete-btn w-100 btn-box-shadow"
                    }`}
                    style={{
                      cursor: disableBtn ? "not-allowed" : "pointer",
                    }}
                  >
                    {isLoading ? (
                      <ThreeDotsLoader />
                    ) : isForceDelete ? (
                      "Confirm Delete"
                    ) : (
                      "Delete"
                    )}
                  </button>
                </div>
              </div>
              <div onClick={onClose} className="cancel-modal-icon cursor">
                <img src={cancelIcon} alt="" />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Delete;
