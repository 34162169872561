import { useState } from "react";
import "style/login.css";
import { message } from "antd";
import { validateLandlordSignIn } from "utils/validations";
import LoginForm from "./LoginForm";
import * as Component from "Components/GeneralComponents";
import * as API from "network/landlord/Auth";

const LandLordSignIn = () => {
  const [form, setForm] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const login = () => {
    setLoader(true);
    API.login(form).then((res) => {
      if (res.apiCallStatus === "success") {
        message.success("Login Successful");
        if (res.message.userRole) {
          localStorage.setItem("role", "user");
          localStorage.setItem("user_role", res.message.userRole.role);
        } else {
          localStorage.setItem("role", res.message.role);
        }
        localStorage.setItem("name", res.message.name);
        localStorage.setItem("user_id", res.message._id);
        localStorage.setItem("token", res.message.token);
        localStorage.setItem("email", res.message.email);

        setTimeout(() => {
          window.location.replace("/");
        }, 300);
      } else {
        setLoader(false);
        console.log(res);
        message.error(res.message);
      }
    });
  };

  const loginLandlord = () => {
    const newError = validateLandlordSignIn(form);
    setErrors(newError);

    if (Object.keys(newError).length === 0) {
      login();
    }
  };

  return (
    <>
       
      <Component.AuthLayout loader={loader}>
        <LoginForm
          form={form}
          handleChange={handleChange}
          errors={errors}
          handleSubmit={loginLandlord}
        />
      </Component.AuthLayout>
    </>
  );
};

export default LandLordSignIn;
