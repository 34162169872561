import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Avatar } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FilterValue } from "Store/Slices/FilterSlice";
import SearchBar from "Helpers/SearchBar";
import SearchBarWithOutBtn from "Helpers/SearchBarWithOutBtn";
import {
  ButtonVariant1,
  CustomTable,
  RecordMenuPopup,
} from "Components/GeneralComponents";
import UserPermission from "libs/UserPermission";
import bedIcon from "assets/bed-icon.png";
import bathIcon from "assets/bath icon.png";
import sqftIcon from "assets/sqft-icon.png";
import activeDot from "assets/_Dot.png";
import inActiveDot from "assets/_inActive-Dot.png";
import * as Icon from "assets/icons";

const UnitTab = ({ id, data, isLoading }) => {
  const { ROLE } = UserPermission();
  const navigate = useNavigate();
  const [key, setKey] = useState("");

  console.log("Unit Data", data);

  const handleIconClick = (result) => {
    // Toggle the dropdownOpen state
    const filterData = data?.filter((item) => {
      return item.key === result;
    });
    setKey(filterData.key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const dispatch = useDispatch();

  const resetFilter = () => {
    dispatch(FilterValue({ reset: "reset" }));
  };

  const range = useSelector((state) => {
    return state.FilterValue.value;
  });
  const property = useSelector((state) => {
    return state.FilterValue.property;
  });
  const fromDate = useSelector((state) => {
    return state.FilterValue.fromDate;
  });
  const toDate = useSelector((state) => {
    return state.FilterValue.toDate;
  });

  const columns = [
    {
      title: "Unit Name",
      dataIndex: "name",
      render: (text, name) => (
        <>
          {console.log("Name", name)}
          <Link
            to={`/tenant-property-units-view?id=${name._id}&property=${id}`}
          >
            {name.img ? (
              <img
                className="unit_table_name_img mr-2"
                src={`${name.img}`}
                alt=""
              />
            ) : (
              <>
                <Avatar
                  style={{
                    backgroundColor: "#EF6B3E",
                    verticalAlign: "middle",
                    marginRight: "10px",
                  }}
                  size="large"
                >
                  {text[0]}
                </Avatar>
              </>
            )}{" "}
            <span className="unit_table_name_text">{text}</span>
          </Link>
        </>
      ),
    },
    {
      title: "Unit Details",
      dataIndex: "unitDetails",
      //render: (text) => <><span>{text}</span></>,
      render: (text, details) => (
        <>
          <ul className="p-0 d-flex gap-3">
            <li className="list-style-none">
              <img src={bedIcon} alt="" />
              <span className="unit_table_details_text ms-2">
                {details.bed || 0} Beds
              </span>
            </li>
            <li className="list-style-none">
              <img src={bathIcon} alt="" />
              <span className="unit_table_details_text ms-2">
                {details.bath || 0} Bath
              </span>
            </li>
            <li className="list-style-none">
              <img src={sqftIcon} alt="" />
              <span className="unit_table_details_text ms-2">
                {details.sqft || 0} sqft
              </span>
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Rent Amount",
      dataIndex: "rent_amount",
      render: (text) => (
        <>
          <span className="unit_table_amount_text">{text}</span>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, status) => (
        <>
          <div
            className={
              text === "Month-to-Month"
                ? "prospect-active-bar"
                : "prospect-inactive-bar"
            }
          >
            <img
              src={text === "Month-to-Month" ? activeDot : inActiveDot}
              alt=""
            />{" "}
            <span>{text}</span>
          </div>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting._id)}
          >
            <Icon.VerticalElipsisIcon width={23} height={23} />
            {setting._id === key && (
              <RecordMenuPopup
                isOpen={setting._id === key}
                onClose={() => setKey(null)}
                handler={{
                  handleView: () => {
                    navigate(`/tenant-property-units-view?id=${setting._id}&property=${id}`);
                  },
                }}
                fields={{
                  view: true,
                }}
              />
            )}
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      {localStorage.getItem("role") !== "landlord" &&
      localStorage.getItem("role") === "user" &&
      ROLE[0]?.units?.add ? (
        <SearchBar
          route={`add-unit-details?id=${id}`}
          btnTitle="Add New Unit"
          unitsFilter={false}
        />
      ) : localStorage.getItem("role") === "landlord" ? (
        <SearchBar
          route={`add-unit-details?id=${id}`}
          btnTitle="Add New Unit"
          unitsFilter={false}
        />
      ) : (
        <SearchBarWithOutBtn unitsFilter={false} />
      )}

      <div className="task-table-container mt-3">
        {range || property || fromDate || toDate ? (
          <div className="mt-2" style={{ width: "fit-content" }}>
            <ButtonVariant1 handler={resetFilter} children={"Reset"} />
          </div>
        ) : (
          ""
        )}
        <CustomTable
          loading={isLoading}
          pagination={true}
          columns={columns}
          dataSource={data}
        />
      </div>
    </>
  );
};

export default UnitTab;
