import React from "react";
import { InputField, SubmitButton } from "Components/GeneralComponents";

const EmailForm = ({
  email,
  setEmail,
  errors,
  handleChange,
  handleNext,
  isLoading,
}) => {
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    handleChange("email", e.target.value);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <InputField
            name={"Email"}
            required={true}
            type="email"
            label={"Email"}
            placeholder={"Email"}
            value={email}
            handler={handleEmailChange}
            errorMessage={errors?.email}
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
          />
        </div>
      </div>
      <div className="stepper-first-btn mt-3">
        <SubmitButton handler={handleNext} isLoading={isLoading}>Next</SubmitButton>
      </div>
    </div>
  );
};

export default EmailForm;
