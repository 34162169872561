import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getNotifications = createAsyncThunk(
  'users/notifications',
  async () => {
    try {
        const config = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };
      
          const response = await fetch(
            `${config.baseUrl}/api/notifications`,
            {
              method: 'GET',
              ...config,
            },
          );
      
          const data = await response.json();
          return data;
    } catch (error) {
        throw error;
    }
  
  },
);

const STATUSES = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

const initialState = {
  allNotifications: [],
  loading: false,
  error: null,
};

const Notification = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    updateNotifications(state, action) {
        state.allNotifications = action.payload;
    },
}

});

export default Notification.reducer;
export const { updateNotifications } = Notification.actions;
