import { useState } from "react";
import TempModal from "Modals/TempModal/TempModal";
import { vendorFormData } from "utils/FormDataStructure";
import { message } from "antd";
import { createVendor, verifyEmail } from "network/landlord/vendor";
import * as Validation from "utils/validations";
import * as InnerComponent from "./index";
import { addVendorDetails } from "utils/stepsData";
import { Stepper } from "Components/GeneralComponents";

const AddVendorDetails = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [DeletedImages, setDeletedImages] = useState([]);
  const [ProfileImages, setProfileImages] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    phone_no: "",
    company: "",
    job: "",
    address1: "",
    address2: "",
    country: "USA",
    city: "",
    state: "",
    zipCode: "",
    services: "",
    provider: "",
    policyNum: "",
    coverage: "",
    effectiveDate: "",
    expireDate: "",
    send_1099_forms: false,
    can_access_all_properties: true,
    properties: [],
  });

  const handleChange = (fieldName, value) => {
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  const handlePersonalDetails = (e) => {
    const newErrors = Validation.validateVendorPersonalDetailsForm(form);
    setErrors(newErrors);
    setIsLoading(true);
    verifyEmail(form.email).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "error") {
        setErrors({ email: res.message });
      }
      if (
        Object.keys(newErrors).length === 0 &&
        res.apiCallStatus === "success"
      ) {
        setCurrentStep(currentStep + 1);
      }
    });
  };

  const handleServicesForm = (e) => {
    if (form.services === "") {
      setErrors({ services: "Please select a service" });
    } else {
      setErrors({});
      setCurrentStep(currentStep + 1);
    }
  };

  const handleInsuranceForm = (e) => {
    const newErrors = Validation.validateVendorInsuranceForm(form);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePropertiesForm = (e) => {
    if (
      form.can_access_all_properties === false &&
      form.properties.length === 0
    ) {
      setErrors({ properties: "Please select a property" });
    } else {
      setErrors({});
      setCurrentStep(currentStep + 1);
    }
  };

  const handleAddVendor = () => {
    const formData = vendorFormData(form, ProfileImages);
    setIsLoading(true);
    createVendor(formData).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        setOpenModal(true);
      } else {
        message.error(res.error.message);
      }
    });
  };

  // Stepper Function
  const handleNext = () => {
    switch (currentStep) {
      case 1:
        handlePersonalDetails();
        break;
      case 2:
        handleServicesForm();
        break;
      case 3:
        handleInsuranceForm();
        break;
      case 4:
        handlePropertiesForm();
        break;
      case 5:
        handleAddVendor();
        break;
    }
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <>
      {openModal && (
        <TempModal
          onClose={() => setOpenModal(false)}
          title="Vendors added successfully"
          success="Go To Vendors"
          route="all-vendor"
        />
      )}
      <div className="container-fluid bg-white p-3">
        <div className="container">
          <Stepper
            steps={addVendorDetails}
            activeStep={currentStep - 1}
            lockStep={false}
            maxSteps={4} // Customize if desired
            setIsDraft={(isDraft) => console.log("Draft status:", isDraft)}
          />

          <div className="stepper-content-container mt-5">
            {currentStep === 1 && (
              <InnerComponent.VendorDetailsForm
                form={form}
                handleChange={handleChange}
                errors={errors}
                handleNext={handleNext}
                setProfileImages={setProfileImages}
                ProfileImages={ProfileImages}
                setDeletedImages={setDeletedImages}
                DeletedImages={DeletedImages}
                isLoading={isLoading}
              />
            )}
            {currentStep === 2 && (
              <InnerComponent.ServicesForm
                form={form}
                handleChange={handleChange}
                errors={errors}
                handleBackButton={handlePrev}
                handleNext={handleNext}
              />
            )}
            {currentStep === 3 && (
              <InnerComponent.InsuranceForm
                form={form}
                handleChange={handleChange}
                errors={errors}
                handleBackButton={handlePrev}
                handleNext={handleNext}
              />
            )}
            {currentStep === 4 && (
              <InnerComponent.PropertiesForm
                form={form}
                handleChange={handleChange}
                errors={errors}
                handleBackButton={handlePrev}
                handleNext={handleNext}
              />
            )}
            {currentStep === 5 && (
              <InnerComponent.FederalTaxesForm
                form={form}
                handleChange={handleChange}
                handleBackButton={handlePrev}
                handleNext={handleNext}
                isLoading={isLoading}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddVendorDetails;
