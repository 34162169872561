import { AuthLayout } from "Components/GeneralComponents";
import TempModal from "Modals/TempModal/TempModal";
import React, { useState } from "react";
import SetPasswordForm from "./SetPasswordForm";

const SetPassword = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleRedirect = () => {
    localStorage.removeItem("role");
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    window.location.replace("/landlord/login");
  };

  return (
    <>
      {openModal && (
        <TempModal
          onClose={() => setOpenModal(false)}
          heading={"Account Created"}
          title={"User Created Successfully"}
          success="Login"
          handler={handleRedirect}
        />
      )}

      <AuthLayout>
        <SetPasswordForm
          openSuccessModal={() => setOpenModal(true)}
        />
      </AuthLayout>
    </>
  );
};

export default SetPassword;
