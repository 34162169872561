import React from "react";
import { formatDate } from "utils";
import * as Component from "Components/GeneralComponents";

const Insurance = ({
  form,
  handleChange,
  errors,
  handleBackButton,
  handleNext,
}) => {
  const handleEffectiveDate = (e) => {
    const formattedDate = new Date(e).toLocaleDateString();
    handleChange("effectiveDate", formattedDate);
  };

  const handleExpireDate = (e) => {
    const formattedDate = new Date(e).toLocaleDateString();
    handleChange("expireDate", formattedDate);
  };

  return (
    <div className="container-fluid">
      <div className="row mt-3">
        <div className="col-md-12">
          <Component.InputField
            name={"provider"}
            required={true}
            label={"Provider"}
            placeholder={"Provider"}
            value={form.provider}
            handler={(e) => handleChange("provider", e.target.value)}
            errorMessage={errors.provider}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6">
          <Component.InputField
            name={"policyNum"}
            required={true}
            type="number"
            label={"Policy Number"}
            placeholder={"Policy Number"}
            value={form.policyNum}
            handler={(e) => handleChange("policyNum", e.target.value)}
            errorMessage={errors.policyNum}
          />
        </div>
        <div className="col-md-6">
          <Component.InputField
            name={"coverage"}
            required={true}
            label={"Coverage"}
            placeholder={"Coverage"}
            value={form.coverage}
            handler={(e) => handleChange("coverage", e.target.value)}
            errorMessage={errors.coverage}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6">
          <Component.DateField
            required={true}
            label={"Effective Date"}
            placeholder={"Select Date"}
            defaultValue={form.effectiveDate && formatDate(form.effectiveDate)}
            handler={handleEffectiveDate}
            errorMessage={errors.effectiveDate}
          />
        </div>
        <div className="col-md-6">
          <Component.DateField
            required={true}
            label={"Expiration Date"}
            placeholder={"Select Date"}
            defaultValue={form.expireDate && formatDate(form.expireDate)}
            handler={handleExpireDate}
            errorMessage={errors.expireDate}
          />
        </div>
      </div>
      <div className="stepper-btn d-flex justify-content-between mt-5 gap-1">
        <div style={{ width: "50%" }}>
          <Component.SubmitButton type="contained" handler={handleBackButton}>
            Back
          </Component.SubmitButton>
        </div>
        <div style={{ width: "50%" }}>
          <Component.SubmitButton handler={handleNext}>
            Next
          </Component.SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default Insurance;
