import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import TenantTask from "Components/TenantTask/TenantTask";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const Tasks = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar topBarTitle="All Maintenance Request" />

      <TenantTask />
    </div>
  );
};

export default Tasks;
