import axios from 'axios';
import config from "Helpers/config.js"

const verifyProspect = async (email) => {
    try {
        const response = await axios.get(`${config.baseUrl}/api/prospect/verify`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            },
            params: { email }
        });
        return response.data; 
    } catch (error) {
        return error.response.data;
    }
};

const createProspect = async (data) => {
    try {
        const response = await axios.post(`${config.baseUrl}/api/prospect`, data, {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

const updateProspect = async (prospectId, data) => {
    try {
        const response = await axios.put(`${config.baseUrl}/api/prospect/${prospectId}`, data, {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

const getProspectById = async (prospectId) => {
    try {
        const response = await axios.get(`${config.baseUrl}/api/prospect/${prospectId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            },
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

const getProspects = async (filters) => {
    try {
        const response = await axios.get(`${config.baseUrl}/api/prospects`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            },
            params: filters
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

const deleteProspect = async (prospectId) => {
    try {
        const response = await axios.delete(`${config.baseUrl}/api/prospect/${prospectId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            },
        });
        return response.data; 
    } catch (error) {
        return error.response.data;
    }
};

const DeleteBulkProspect = async (prospectIds) => {
    try {
        const response = await axios.delete(`${config.baseUrl}/api/prospects`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            },
            data: { prospectIds }
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

const availableProperties = async () => {
    try {
        const response = await axios.get(`${config.baseUrl}/api/properties/available-units`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export {
    verifyProspect,
    createProspect,
    updateProspect,
    getProspects,
    getProspectById,
    deleteProspect,
    DeleteBulkProspect,
    availableProperties
};