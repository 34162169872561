import { io } from 'socket.io-client'
import config from '../Helpers/config.js'

const Socket = () => {
    const role = localStorage.getItem("role")
    const token = localStorage.getItem("token")
    const initializeSocket = io.connect(`${config.socketUrl}/message`, {
        auth: {
            role: role,
            token: token
        }
    });
    return {
        initializeSocket
    }
}

export default Socket
