import axios from "axios";
import config from "Helpers/config.js";

const fetchproperties = async (unique) => {
  const query = unique ? `?unique=true` : "";
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/tenant/properties${query}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getProperty = async (id) => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/tenant/properties/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// fetch(`${config["baseUrl"]}/api/tenant/tasks/unit/${id}`, {
//   method: "GET",
//   headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
// })
//   .then((res) => {
//     return res.json();
//   })
//   .then((res) => {
//     if (res.apiCallStatus === "success") {
//       let temp = [];
//       temp = res.message;
//       setTaskData([...temp]);
//       console.log(temp, "task Success");
//     } else {
//       //console.log(res, 'error')
//     }
//   });

const fetchTenantUnitTasks = async (id) => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/tenant/tasks/unit/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  }
  catch (error) {
    return error.response.data;
  }
};

export { fetchproperties, getProperty, fetchTenantUnitTasks };
