import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import GoogleAuth from "Hooks/GoogleAuth";
import { AuthLayout } from "Components/GeneralComponents";
import SignUpForm from "./SignupForm";
import { signup } from "network/serviceProfessional/auth";
import { validateServiceProfessionalSignUp } from "utils/validations";

const ServiceProfessionalSignUp = () => {
  // States start
  const navigate = useNavigate();
  const [agreeTermsChecked, setAgreeTermsChecked] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [facebookLogin, setFacebookLogin] = useState(null);
  const [form, setForm] = useState({
    fname: "",
    lname: "",
    email: "",
    password: "",
    cpassword: "",
    phone: "",
  });

  // States end

  const handleChange = (fieldName, value) => {
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  const googleAuth = () => {
    GoogleAuth("professional");
  };

  const resolveFacebook = (responce) => {
    console.log(responce);
    setFacebookLogin(responce.data);
    console.log(facebookLogin);
  };
  const rejectFacebook = (error) => {
    console.log(error);
  };

  const serviceProfessionalSignUp = () => {
    const newErrors = validateServiceProfessionalSignUp(form);
    setErrors(newErrors);
    console.log(newErrors);

    if (Object.keys(newErrors).length === 0 && agreeTermsChecked) {
      setLoader(true);
      signup(form).then((response) => {
        setLoader(false);
        if (response.apiCallStatus === "success") {
          message.success("SignUp Successful");
          localStorage.setItem("token", response.message.token);
          localStorage.setItem("role", "serviceprovider");
          localStorage.setItem("email", response.message.email);
          localStorage.setItem("user_id", response.message._id);
          localStorage.setItem("name", response.message.name);
          window.location.href = "/";
        } else if (response.status === 409) {
          message.error("User Already Exists");
        }
      }).catch((e)=>{
        console.log(e);
        message.error("SignUp Failed");
        setLoader(false);
        setErrors({...errors, general: "SignUp Failed" });
      })
    }
  };

  return (
    <>
      <AuthLayout loader={loader}>
        <SignUpForm
          form={form}
          errors={errors}
          handleChange={handleChange}
          serviceProfessionalSignUp={serviceProfessionalSignUp}
          googleAuth={googleAuth}
          setAgree={setAgreeTermsChecked}
          resolveFacebook={resolveFacebook}
          rejectFacebook={rejectFacebook}
        />
      </AuthLayout>
    </>
  );
};

export default ServiceProfessionalSignUp;
