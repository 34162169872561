import AddNotes from "Components/AddNotes/AddNotes";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import TitleTopBar from "Helpers/TitleTopBar";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import React from "react";

const NotesNew = () => {
  const { id } = UseUrlParamsHook();
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Add Notes"}
        back={id ? `property-details-view?id=${id}` : `all-notes`}
      />
      <AddNotes />
    </div>
  );
};

export default NotesNew;
