import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import ServiceProfessionalDashboard from "Components/ServiceProfessionalDashboard/ServiceProfessionalDashboard";
import React from "react";

const Dashboard = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar topBarTitle={"Dashboard"} />
      <ServiceProfessionalDashboard />
    </div>
  );
};

export default Dashboard;
