import React, { useState, useEffect } from "react";
import {
  CustomTable,
  FormModalLayout,
  IconList,
  RecordMenuPopup,
} from "Components/GeneralComponents";
import { getTasks } from "network/landlord/task";
import person from "assets/person.jpg";
import { TaskIcon, VerticalElipsisIcon } from "assets/icons";
import { Link, useNavigate } from "react-router-dom";
import searchIcon from "assets/search.png";
import PostUpdateModal from "Modals/PostUpdateModal/PostUpdateModal";

const TasksTab = ({ id }) => {
  const navigate = useNavigate();
  const [key, setKey] = useState(null);
  const [tasksData, setTasksData] = useState([]);
  const [search, setSearch] = useState("");
  const [taskId, setTaskId] = useState("");
  const [openModalPostUpdate, setOpenModalPostUpdate] = useState(false);
  const [selectedTaskData, setSelectedTaskData] = useState({});
  const [isRerender, setIsRerender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getTasks().then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        const filteredData = res.message.data.filter((e) => e.unit === id);
        console.log("Tasks Data12: ", res.message.data);
        console.log("Tasks Data: ", filteredData);
        setTasksData(
          filteredData
            .filter((e) => e.title.toLowerCase().includes(search.toLowerCase()))
            .map((e) => ({
              key: e._id,
              title: e.title,
              image: e?.image,
              assignees: e.assignees,
              due: {
                dueDate: new Date(e?.dueDate).toLocaleDateString(),
                priority: e?.priority,
              },
              related: e?.propertyAddress,
              status: e.status || "N/A",
              propertyId: e?.property,
              unitId: e?.unit,
            }))
        );
      } else {
        console.log("Error in fetching tasks data");
      }
    });
  }, [id, search, isRerender]);

  const handleIconClick = (result) => {
    const filterData = tasksData.filter((item) => {
      return item.key === result;
    });
    setKey(filterData.key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const onOpenModalPostUpdate = () => {
    setOpenModalPostUpdate(true);
  };

  const onCloseModalPostUpdate = () => {
    setOpenModalPostUpdate(false);
  };

  const columns = [
    {
      title: "TITLE",
      dataIndex: "title",
      render: (text, data) => (
        <Link
          to={`/task-details?id=${data.key}`}
          className="d-flex align-items-center gap-2"
        >
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              overflow: "hidden",
              border: `0.5px solid ${data.image ? "#c9c9c9" : "#ef6b3e"}`,
              position: "relative",
            }}
          >
            {data.image ? (
              <img
                src={data.image}
                alt="task"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            ) : (
              <TaskIcon width={20} height={20} />
            )}
          </div>
          <div className="">{text}</div>
        </Link>
      ),
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo",
      render: (text, data) => (
        <>
          {" "}
          <IconList
            properties={data.assignees}
            defaultimage={person}
            name={true}
          />
        </>
      ),
    },
    {
      title: "DUE DATE",
      dataIndex: "dueDate",
      render: (text, data) => (
        <>
          <div className="assign-date-container">
            {!data.priority ? (
              "Not Set"
            ) : (
              <>
                <div
                  className={
                    data.priority === "High"
                      ? "priority-text bg-error"
                      : data.priority === "Low"
                      ? "priority-text bg-grey"
                      : data.priority === "Medium"
                      ? "priority-text bg-yellow"
                      : data.priority === "Very High"
                      ? "priority-text bg-error"
                      : ""
                  }
                >
                  <span>{`${data.priority} Priority`}</span>
                </div>
                <br />
                <span className="assign-to-date">{text}</span>
              </>
            )}
          </div>
        </>
      ),
    },
    {
      title: "RELATED TO",
      dataIndex: "related",
      render: (text) => <div className="all-lease-property-text">{text}</div>,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      render: (text) => (
        <>
          <span
            className={
              text === "Completed"
                ? "completed-status text-white"
                : text === "In Progress"
                ? "in-progress-status text-white"
                : text === "Not Started"
                ? "not-started-status text-dark"
                : ""
            }
          >
            {text}
          </span>{" "}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <VerticalElipsisIcon width={23} height={23} />
            <RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleView: () => {
                  navigate(`/task-details?id=${setting.key}`);
                },
                handlePostAnUpdate: () => {
                  setSelectedTaskData(setting);
                  onOpenModalPostUpdate();
                  setTaskId(setting.key);
                },
              }}
              fields={{
                view: true,
                postAnUpdate: true,
              }}
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <FormModalLayout
        title={"Post Update"}
        isOpen={openModalPostUpdate}
        onClose={onCloseModalPostUpdate}
      >
        <PostUpdateModal
          onClose={() => {
            onCloseModalPostUpdate();
            setIsRerender(!isRerender);
          }}
          id={taskId}
          data={selectedTaskData}
        />
      </FormModalLayout>

      <div className="row mb-3">
        <div className="col-md-12">
          <div className="task-search-input position-relative">
            <input
              type="text"
              placeholder="Search"
              className="form-control search-form-control-task"
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="search-icon-task">
              <img src={searchIcon} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="task-table-container mt-3">
        <CustomTable
          columns={columns}
          dataSource={tasksData}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default TasksTab;
