import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import NonRenewal from "Components/NonRenewal/NonRenewal";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const LeasesNonRenew = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar topBarTitle={"Non-Renewal Notice"} />
      <NonRenewal />
    </div>
  );
};

export default LeasesNonRenew;
