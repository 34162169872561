import React, { useEffect, useState } from "react";
import cancelIcon from "assets/x-circle.png";
import { SelectField } from "Components/GeneralComponents";

const PurchaseUnit = ({
  onClose,
  setUnitValue,
  value,
  callBack,
  errorMessage,
}) => {
  return (
    <>
      <div className="payment-modal-container">
        <div className="payment-method-modal property-details-view-task-modal p-5 position-relative overflow-visible">
          <div className="d-flex flex-column text-center mb-4">
            <span className="fw-bold">You're out of units</span>
            <span>
              Select units you want to purchase
              <span className="sign-up-imp-star">*</span>
            </span>
          </div>
          {/* <div className="mt-3 d-flex gap-2 justify-content-between">
            <button
              className="add-new-task-btn flex-shrink-0"
              style={{ width: "clamp(4rem, 6vw, 5.5rem)" }}
              onClick={() => setUnitValue((state) => state - 1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                width={21}
                height={21}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5 12h14"
                />
              </svg>
            </button>

            <div className="stepper-content flex-fill">
              <input
                type="number"
                className="form-control h-100"
                placeholder="0"
                value={value}
                onChange={(e) => {
                  const value = e.target.value === "" ? 0:e.target.value;
                  setUnitValue(parseInt(value,10));
                }}
              />
            </div>

            <button
              className="add-new-task-btn flex-shrink-0"
              style={{ width: "clamp(4rem, 6vw, 5.5rem)" }}
              onClick={() => setUnitValue((state) => state + 1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                width={21}
                height={21}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
            </button>
          </div> */}

          <SelectField
            name={"unit"}
            required={true}
            label={"Number of Units to Purchase"}
            placeholder={"Select Units"}
            value={value}
            options={[...Array(10).keys()].map((i) => ({
              value: (i + 1) * 10,
              label: (i + 1) * 10,
            }))}
            handler={(e) => {
              setUnitValue(parseInt(e, 10));
            }}
            errorMessage={errorMessage}
          />

          <div className="row">
            <div className="col-md-12">
              <div className="stepper-btn d-flex justify-content-between mt-2 gap-1 pb-3">
                <button
                  onClick={callBack}
                  className="next-btn-same-class w-100 mt-3"
                >
                  Purchase
                </button>
              </div>
            </div>
          </div>
          <div onClick={onClose} className="cancel-modal-icon cursor">
            <img src={cancelIcon} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchaseUnit;
