import TaskReports from "Components/TaskReports/TaskReports";
import React from "react";

const ReportsTask = () => {
  return (
    <div className="container-fluid">
      <TaskReports />
    </div>
  );
};

export default ReportsTask;
