import React from "react";
import cancelIcon from "assets/x-circle.png";
import { DeleteIcon } from "assets/icons";
import { ThreeDotsLoader } from "Components/GeneralComponents";
import alertIcon from "assets/alert-circle.png";

const EditInvoiceWarning = ({
  isOpen,
  onClose,
  EditNextInvoice,
  EditSeries,
  message,
  isLoading,
  isForceDelete,
  disableBtn = false,
}) => {
  const handleNextEdit = () => {
    EditNextInvoice("editNextInvoice");
  };
  const handleSeriesEdit = () => {
    EditSeries("editSeries");
  };

  return (
    <>
      {isOpen && (
        <>
          <div className="payment-modal-container">
            <div className="vendor-success-modal position-relative">
              <div className="row">
                <div className="col-md-12 text-center">
                  {/* <DeleteIcon /> */}
                  <img src={alertIcon} alt="" />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12 text-center">
                  <p className="normal-grey-text convert-tenant-modal-text">
                    {message}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="d-flex flex-column flex-md-row justify-content-center  gap-2 text-center mt-2 p-0">
                <button
            onClick={handleSeriesEdit}
            className= "recurring-edit-btn text-nowrap"
          >
            Edit Series
          </button>
          <button
            onClick={handleNextEdit}
            className={`${
            "next-invoice-btn-class text-nowrap"
            }`}
          >
            Edit Next Invoice
          </button>
                </div>
              </div>
              <div onClick={onClose} className="cancel-modal-icon cursor">
                <img src={cancelIcon} alt="" />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EditInvoiceWarning;
