import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import AddProspectDetails from "Components/AddProspectDetails/AddProspectDetails";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
const AddProspectDetailsPage = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Add Prospects Details"}
        // back="all-prospect"
        pop={true}
      />
      <AddProspectDetails />
    </div>
  );
};

export default AddProspectDetailsPage;
