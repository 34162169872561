import React, { useEffect, useState } from "react";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import * as InnerComponent from "./index";
import { getProperty } from "network/tenant/Property";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomTabs } from "Components/GeneralComponents";

const TenantPropertyDetailsView = () => {
  const { id, unit, tab } = UseUrlParamsHook();
  const [overviewTabData, setOverviewTabData] = useState({});
  const [landlordInfoTabData, setLandlordInfoTabData] = useState({});
  const [unitsTabData, setUnitsTabData] = useState({});
  const [documentsData, setDocumentsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    setActiveTab(tab || "1");
  }, [tab]);

  const onChange = (key) => {
    setActiveTab(key);
    searchParams.set("tab", key);
    navigate({ search: searchParams.toString() });
  };

  useEffect(() => {
    setIsLoading(true);
    getProperty(id).then((res) => {
      if (res.apiCallStatus === "success") {
        setOverviewTabData(res.message.data.propertyOverview);
        setLandlordInfoTabData(res.message.data.LandlordInfo);
        setUnitsTabData(res.message.data.units);
        setDocumentsData(res.message.data.signnow_documents);
      }
      setIsLoading(false);
    });
  }, [id]);

  const items = [
    {key: "1", label: "Overview", children: <InnerComponent.OverviewTab data={overviewTabData} isLoading={isLoading} />},
    {key: "2", label: "Landlord Info", children: <InnerComponent.LandlordInfoTab id={id} data={landlordInfoTabData} isLoading={isLoading} />},
    {key: "3", label: "Units", children: <InnerComponent.UnitsTab id={id} data={unitsTabData} isLoading={isLoading} />},
    {key: "4", label: "Tasks", children: <InnerComponent.TasksTab id={id} unitId={unit} units={unitsTabData} />},
    {key: "5", label: "Documents", children: <InnerComponent.DocumentsTab data={documentsData} owner={landlordInfoTabData?.name} isLoading={isLoading} />},
    {key: "6", label: "Files", children: <InnerComponent.FilesTab id={id} category="" /> },
  ];

  return (
    <>
      {activeTab && (
        <div className="container-fluid bg-white pt-5">
          <CustomTabs
            items={items}
            defaultActiveKey={activeTab}
            onChange={onChange}
          />
        </div>
      )}
    </>
  );
};

export default TenantPropertyDetailsView;
