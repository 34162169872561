import { LeftArrow, RightArrow } from "assets/icons";
import "../../style/stepper.css";
import React, { useEffect, useState } from "react";

function Steps({
  steps,
  activeStep,
  onStepClick,
  lockStep,
  maxSteps = 4,
  setIsDraft = () => {},
}) {
  const [passedSteps, setPassedSteps] = useState([]);
  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  const [responsiveMaxSteps, setResponsiveMaxSteps] = useState(maxSteps);

  // Initialize passed steps based on the active step
  useEffect(() => {
    // Fill all steps up to and including the active step as passed
    const initialPassedSteps = Array.from({ length: activeStep + 1 }, (_, i) => i);
    setPassedSteps(initialPassedSteps);
  }, [activeStep]);

  // handling responsiveness
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setResponsiveMaxSteps(3);
      } else {
        setResponsiveMaxSteps(maxSteps);
      }
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // handling active step
  useEffect(() => {
    if (!passedSteps.includes(activeStep)) {
      setPassedSteps((prev) => [...prev, activeStep]);
    }

    // Update the start index based on the active step
    if (activeStep < currentStartIndex) {
      setCurrentStartIndex(Math.max(0, activeStep - responsiveMaxSteps + 1));
    } else if (activeStep >= currentStartIndex + responsiveMaxSteps) {
      setCurrentStartIndex(
        Math.min(activeStep, steps.length - responsiveMaxSteps)
      );
    }

    if (passedSteps.includes(1)) {
      setIsDraft(true);
    } else {
      setIsDraft(false);
    }
  }, [activeStep, passedSteps, responsiveMaxSteps]);

  const handleNext = () => {
    const nextIndex = currentStartIndex + 1;
    if (nextIndex + responsiveMaxSteps <= steps.length) {
      setCurrentStartIndex(nextIndex);
    }
  };

  const handlePrev = () => {
    const prevIndex = currentStartIndex - 1;
    if (prevIndex >= 0) {
      setCurrentStartIndex(prevIndex);
    }
  };

  const stepCheck = (val) => {
    return activeStep === val || passedSteps.includes(val)
      ? "step-active"
      : "step-inactive";
  };

  const isStepClickable = (index) => {
    return passedSteps.includes(index);
  };

  return (
    <div className="max-w-[1000px] w-full mx-auto mt-32">
      <div className="step-progress">
        <div className="step-wrapper">
          <button
            className={`step-btn step-btn-left ${
              currentStartIndex > 0 ? "" : "hidden"
            }`}
            onClick={handlePrev}
          >
            <LeftArrow />
          </button>

          <div className="steps">
            {steps
              .slice(currentStartIndex, currentStartIndex + responsiveMaxSteps)
              .map((step, index) => {
                const currentStepIndex = index + currentStartIndex;
                return (
                  <React.Fragment key={index}>
                    <div className={`step ${stepCheck(currentStepIndex)}`}>
                      <div
                        className={`step-count cursor-default ${
                          activeStep === currentStepIndex && "bubble"
                        }`}
                        // onClick={() => {
                        //   if (isStepClickable(currentStepIndex)) {
                        //     onStepClick(currentStepIndex); // Handle click for steps
                        //   }
                        // }}
                      >
                        <div>{currentStepIndex + 1}</div>
                      </div>
                      <div className="step-text">
                        <p>{step}</p>
                      </div>
                    </div>
                    {currentStepIndex <
                      Math.min(
                        steps.length - 1,
                        currentStartIndex + responsiveMaxSteps - 1
                      ) && (
                      <div className={`seperator`}>
                        <div className="unfilled" />
                        {passedSteps.includes(currentStepIndex + 1) && (
                          <div className="filled" />
                        )}
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
          </div>

          <button
            className={`step-btn  step-btn-right ${
              currentStartIndex + responsiveMaxSteps < steps.length
                ? ""
                : "hidden"
            }`}
            onClick={handleNext}
          >
            <RightArrow />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Steps;
