import React, { useState } from "react";
import LandLordDashboard from "Components/LandLordDashboard/LandLordDashboard";
import CustomizeSideBar from "Components/CustomizeLandlordSideDrawer/CustomizeLandlordSideDrawer";
import customizeOptionIcon from "assets/Group 1000004204.png";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";

const Dashboard = () => {
  const [revenueToggle, setRevenueToggle] = useState(true);
  const [propertiesToggle, setPropertiesToggle] = useState(true);
  const [recentactivitiesToggle, setRecentActivitiesToggle] = useState(true);
  const [tenantToggle, setTenantToggle] = useState(true);
  const [prospectsToggle, setProspectsToggle] = useState(true);
  const [vacanciesToggle, setVacanciesToggle] = useState(true);
  const [taskToggle, setTaskToggle] = useState(true);
  const [payablesToggle, setPayablesToggle] = useState(true);
  const [mileageToggle, setMileageToggle] = useState(true);

  // state end
  // State to manage the display of the right sidebar
  const [showRightSidebar, setShowRightSidebar] = useState(false);

  // Function to toggle the right sidebar
  const toggleRightSidebar = () => {
    setShowRightSidebar(true);
  };
  const toggleRightSidebarClose = () => {
    setShowRightSidebar(false);
  };
  const handlRrevenueClick = () => {
    setRevenueToggle(!revenueToggle);
  };
  const handlPropertiesClick = () => {
    setPropertiesToggle(!propertiesToggle);
  };
  const handlRecentActivitiesClick = () => {
    setRecentActivitiesToggle(!recentactivitiesToggle);
  };
  const handlTenantsClick = () => {
    setTenantToggle(!tenantToggle);
  };
  const handlProspectsClick = () => {
    setProspectsToggle(!prospectsToggle);
  };
  const handlVacanciesClick = () => {
    setVacanciesToggle(!vacanciesToggle);
  };
  const handlTaskClick = () => {
    setTaskToggle(!taskToggle);
  };
  const handlPayablesClick = () => {
    setPayablesToggle(!payablesToggle);
  };
  const handlMileageClick = () => {
    setMileageToggle(!mileageToggle);
  };

  return (
    <>
      <div className="right-component bg-white d-flex align-items-stretch">
        <Fixedtopbar topBarTitle={"Dashboard"} />
        <LandLordDashboard
          revenue={revenueToggle}
          properties={propertiesToggle}
          recentActivities={recentactivitiesToggle}
          tenants={tenantToggle}
          prospects={prospectsToggle}
          vacancies={vacanciesToggle}
          task={taskToggle}
          payables={payablesToggle}
          mileage={mileageToggle}
        />
      </div>
      <div
        className="row customize-landlord-dashboard-btn cursor"
        title="Customize Dashboard"
        onClick={toggleRightSidebar}>
        <img
          src={customizeOptionIcon}
          alt=""
          className="customize-landlord-dashboard-icon"
        />
      </div>
      <div
        className={
          showRightSidebar
            ? "right-sidebar customize-landord-dashboard-sidebar-open h-100"
            : "right-sidebar customize-landord-dashboard-sidebar-close h-100"
        }>
        {/* Add content and styles for the right sidebar */}
        <CustomizeSideBar
          //   ========================================================================
          revenue={handlRrevenueClick}
          properties={handlPropertiesClick}
          recentActivities={handlRecentActivitiesClick}
          tenants={handlTenantsClick}
          prospects={handlProspectsClick}
          vacancies={handlVacanciesClick}
          task={handlTaskClick}
          payables={handlPayablesClick}
          mileage={handlMileageClick}
          // ==========================================================================

          close={toggleRightSidebarClose}
        />
      </div>
    </>
  );
};

export default Dashboard;
