import React from "react";
import ChatUserList from "./ChatUserList/ChatUserList";

const Chat = () => {
  return (
    <div
      className="flex-fill"
      style={{ overflowY: "hidden" }}>
      <ChatUserList />
    </div>
  );
};

export default Chat;
