import React, { useState } from "react";
import "style/UnitDetailsViewer.css";
import { Carousel } from "react-bootstrap";
import { FormModalLayout } from ".";

const UnitDetailsViewer = ({ images, address, title }) => {
  const [currImg, setCurrImg] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const handleThumbnailClick = (index) => {
    setCurrImg(index);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="container py-3">
      <FormModalLayout
        isOpen={showModal}
        onClose={handleCloseModal}
        title={title}
      >
        <div className="container">
          <div className="row">
            {images?.map((image, index) => (
              <div
                className="col-md-4 col-sm-6 col-xs-12 mb-3"
                key={index}
                onClick={() => handleThumbnailClick(index)}
              >
                <img
                  className="img-fluid rounded"
                  alt={`Image ${index}`}
                  src={image}
                  width="100%"
                />
              </div>
            ))}
          </div>
        </div>
      </FormModalLayout>

      {images?.length === 0 && (
        <div className="row">
          <div className="col-md-12">
            <div
              className="text-bold d-flex align-items-center justify-content-center"
              style={{ fontSize: "24px", fontWeight: "800", height: "300px", color: "#A9A9A9" }}
            >
              No Images to Display
            </div>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-md-6 py-2">
          <Carousel activeIndex={currImg} onSelect={setCurrImg}>
            {images?.map((image, index) => (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100 img-fluid rounded"
                  alt={`Slide ${index}`}
                  src={image}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>

        <div className="col-md-6">
          <div className="row">
            {images?.slice(0, 4).map((image, index) => (
              <div
                className="col-6 py-2 thumbnail-item"
                key={index}
                onClick={() => handleThumbnailClick(index)}
              >
                <img
                  className={`img-fluid rounded ${
                    currImg === index ? "active-thumbnail" : ""
                  }`}
                  alt={`Thumbnail ${index}`}
                  src={image}
                  width="100%"
                />
              </div>
            ))}
            {images?.length > 4 && (
              <div className="col-12 py-2">
                <button
                  className="btn w-100"
                  style={{ backgroundColor: "#ef6b3e", color: "white" }}
                  onClick={() => setShowModal(true)}
                >
                  + {images.length - 4} More Images
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="unit-info">
        <span className="address">{address}</span>
        <span className="title">{title}</span>
      </div>
    </div>
  );
};

export default UnitDetailsViewer;
