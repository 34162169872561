import { useEffect, useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import TempModal from "Modals/TempModal/TempModal";
import { tenantFormData } from "utils/FormDataStructure";
import {
  verifyTenant,
  createTenant,
  updateTenant,
} from "network/landlord/tenant";
import { PersonalDetailsForm, EmailForm, EmergencyInfoForm } from "./index";
import {
  validateEmail,
  validateTenantPersonalDetails,
} from "utils/validations";
import { Stepper } from "Components/GeneralComponents";
import { addTenantDetails } from "utils/stepsData";

const AddTenantDetails = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState("");
  const [DeletedImages, setDeletedImages] = useState([]);
  const [loader, setloader] = useState(false);
  const [ProfileImages, setProfileImages] = useState([]);
  const [tenant, setTenant] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    dob: "",
    addressLine_1: "",
    addressLine_2: "",
    country: "USA",
    city: "",
    state: "",
    zipCode: "",
    phoneType: "",
    company: "",
    jobTitle: "",
    notes: "",
  });
  const [emergencyContacts, setEmergencyContacts] = useState([
    {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phone: "",
      relationship: "",
      description: "",
    },
  ]);

  const handleEmergencyChange = (fieldName, index, value) => {
    const newEmergencyContacts = [...emergencyContacts];
    newEmergencyContacts[index][fieldName] = value;
    setEmergencyContacts(newEmergencyContacts);
  };

  const navigate = useNavigate();

  useEffect(() => {
    setForm({
      firstName: tenant?.firstName || "",
      middleName: tenant?.middleName || "",
      lastName: tenant?.lastName || "",
      email: tenant?.email || "",
      phone: tenant?.phone || "",
      dob: tenant?.dob || "",
      addressLine_1: tenant?.addressLine_1 || "",
      addressLine_2: tenant?.addressLine_2 || "",
      city: tenant?.city?.name || "",
      state: tenant?.state?.name || "",
      zipCode: tenant?.zipcode?.number ? "" + tenant?.zipcode?.number : "",
      phoneType: tenant?.phoneType || "",
      company: tenant?.company || "",
      jobTitle: tenant?.jobTitle || "",
      notes: tenant?.notes?.description,
    });
  }, [tenant]);

  const handleChange = (fieldName, value) => {
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  useEffect(() => {
    if (tenant?.emergencyDetails) {
      setEmergencyContacts(
        tenant.emergencyDetails.map((emergencyData) => ({
          firstName: emergencyData?.firstName || "",
          middleName: emergencyData?.middleName || "",
          lastName: emergencyData?.lastName || "",
          email: emergencyData?.email || "",
          phone: emergencyData?.phone || "",
          relationship: emergencyData?.relationship || "",
          description: emergencyData?.description || "",
        }))
      );
    }
  }, [tenant?.emergencyDetails]);

  const handleAddContact = () => {
    setEmergencyContacts([
      ...emergencyContacts,
      {
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phone: "",
        relationship: "",
        description: "",
      },
    ]);
  };

  const handleRemoveContact = (indexToRemove) => {
    if (indexToRemove !== 0) {
      const updatedEmergencyContacts = emergencyContacts.filter(
        (contact, index) => index !== indexToRemove
      );
      setEmergencyContacts(updatedEmergencyContacts);
    }
  };

  // Add Tenant
  var formdata = new FormData();

  formdata.append("firstName", form.firstName);
  formdata.append("middleName", form.middleName);
  formdata.append("lastName", form.lastName);
  formdata.append("email", form.email);
  formdata.append("phone", form.phone);
  formdata.append("dob", form.dob);
  formdata.append("addressLine_1", form.addressLine_1);
  formdata.append("addressLine_2", form.addressLine_2);
  if (ProfileImages.length !== 0) {
    formdata.append("profileImage", ProfileImages);
  }
  formdata.append("country", form?.country);
  formdata.append("city", form?.city);
  formdata.append("state", form?.state);
  formdata.append("zipcode", form.zipCode);
  formdata.append("phoneType", form.phoneType);
  formdata.append("company", form.company);
  formdata.append("jobTitle", form.jobTitle);
  if (form?.notes) formdata.append("notes", form?.notes);
  if (emergencyContacts) {
    const allFieldsFilled = emergencyContacts.every(
      (items) =>
        items?.firstName &&
        items?.middleName &&
        items?.lastName &&
        items?.email &&
        items?.phone &&
        items?.relationship &&
        items?.description
    );
    if (allFieldsFilled) {
      emergencyContacts.forEach((emergency, index) => {
        formdata.append(
          `emergencyDetails[${index}][firstName]`,
          emergency.firstName
        );
        formdata.append(
          `emergencyDetails[${index}][middleName]`,
          emergency.middleName
        );
        formdata.append(
          `emergencyDetails[${index}][lastName]`,
          emergency.lastName
        );
        formdata.append(`emergencyDetails[${index}][email]`, emergency.email);
        formdata.append(`emergencyDetails[${index}][phone]`, emergency.phone);
        formdata.append(
          `emergencyDetails[${index}][relationship]`,
          emergency.relationship
        );
        formdata.append(
          `emergencyDetails[${index}][description]`,
          emergency.description
        );
      });
    }
  }

  const handleEmailFormSubmit = () => {
    const newErrors = validateEmail(email);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setIsLoading(true);
      verifyTenant(email).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          if (res?.message?.message === "Email Verified Successfully") {
            message.info("Email Verified Successfully");
            setCurrentStep(currentStep + 1);
          } else {
            setTenant(res?.message?.data);
            message.info("Tenant is already exist in this email");
            setCurrentStep(currentStep + 1);
          }
        } else if (res.apiCallStatus === "error") {
          message.error(res?.message);
        }
      });
    }
  };

  const handleAdditionInfoSubmit = () => {
    const newErrors = validateTenantPersonalDetails(form);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleSubmit = () => {
    const formData = tenantFormData(form, emergencyContacts, ProfileImages);
    setIsLoading(true);
    if (tenant?.length === 0) {
      createTenant(formData).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          setOpenModal(true);
        } else {
          message.error(res.error.message);
        }
      });
    } else {
      updateTenant(tenant?._id, formData).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          setOpenModal(true);
        } else {
          message.error(res.error.message);
        }
      });
    }
  };

  const handleNext = () => {
    switch (currentStep) {
      case 1:
        handleEmailFormSubmit();
        break;
      case 2:
        handleAdditionInfoSubmit();
        break;
      case 3:
        handleSubmit();
        break;
    }
  };

  const onOpenModal = () => {
    setOpenModal(true);
  };

  const handleBackButton = () => {
    if (currentStep === 1) {
      navigate("/all-tenants");
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <>
      {openModal === true && (
        <TempModal
          onClose={() => setOpenModal(false)}
          title={
            tenant?.length === 0
              ? `Tenant added successfully`
              : `Tenant updated successfully`
          }
          success="Go To Tenants"
          route="all-tenants"
        />
      )}
      <div className="container-fluid bg-white p-3">
        <div className="container">
          <Stepper
            steps={addTenantDetails}
            activeStep={currentStep - 1}
            lockStep={false}
            maxSteps={4} // Customize if desired
            setIsDraft={(isDraft) => console.log("Draft status:", isDraft)}
          />

          <div className="stepper-content-container mt-4">
            {currentStep === 1 && (
              <EmailForm
                email={email}
                setEmail={setEmail}
                errors={errors}
                handleChange={handleChange}
                handleNext={handleNext}
                isLoading={isLoading}
              />
            )}
            {currentStep === 2 && (
              <PersonalDetailsForm
                form={form}
                handleChange={handleChange}
                errors={errors}
                setProfileImages={setProfileImages}
                ProfileImages={ProfileImages}
                setDeletedImages={setDeletedImages}
                DeletedImages={DeletedImages}
                handleNext={handleNext}
              />
            )}
            {currentStep === 3 && (
              <EmergencyInfoForm
                emergencyContacts={emergencyContacts}
                handleChange={handleEmergencyChange}
                handleAddContact={handleAddContact}
                handleRemoveContact={handleRemoveContact}
                handleNext={handleNext}
                handleBackButton={handleBackButton}
                isLoading={isLoading}
                isSubmitForm={true}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTenantDetails;
