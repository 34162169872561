import { useEffect, useState } from "react";
// import AddProspectModal from "Modals/AddProspectModal/AddProspectModal";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getProspectById, updateProspect } from "network/landlord/prospect";
import { validateProspectPersonalDetails } from "utils/validations";
import { prospectFormData } from "utils/FormDataStructure";
import TempModal from "Modals/TempModal/TempModal";
import Loader from "Helpers/Loader";
import {
  Stepper,
  PersonalDetailsForm,
  EmergencyInfoForm,
  PetsForm,
  VehicleForm,
  DependentInfoForm,
} from "./index";

const AddProspectDetailsEditModal = () => {
  const { id } = useParams();

  // States start
  const [currentStep, setCurrentStep] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [ProfileImages, setProfileImages] = useState([]);
  const [DeletedImages, setDeletedImages] = useState([]);
  const [prospectData, setProspectData] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPropertyType, setSelectedPropertyType] = useState("");
  const [form, setForm] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    dob: "",
    addressLine_1: "",
    addressLine_2: "",
    country: "USA",
    city: "",
    state: "",
    zipCode: "",
    property: "",
    unit: "",
    phoneType: "",
    company: "",
    jobTitle: "",
    notes: "",
  });
  const [emergencyContacts, setEmergencyContacts] = useState([
    {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phone: "",
      relationship: "",
      description: "",
    },
  ]);
  const [pets, setPets] = useState([
    {
      petType: "",
      petName: "",
      petWeight: "",
      petBreed: "",
    },
  ]);
  const [vehicles, setVehicles] = useState([
    {
      vehicleType: "",
      vehicleMake: "",
      vehicleModel: "",
      vehicleYear: "",
      vehicleColor: "",
      vehicleLicense: "",
    },
  ]);
  const [dependentInfo, setDependentInfo] = useState([
    {
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      email: "",
      phone: "",
      relationship: "",
      description: "",
    },
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getProspectById(id).then((res) => {
      setIsLoading(false);
      setProspectData(res?.message);
    });
  }, []);

  useEffect(() => {
    setForm({
      ...form,
      firstName: prospectData?.firstName || "",
      middleName: prospectData?.middleName || "",
      lastName: prospectData?.lastName || "",
      email: prospectData?.email || "",
      phone: prospectData?.phone || "",
      dob: prospectData?.dob || "",
      addressLine_1: prospectData?.addressLine_1 || "",
      addressLine_2: prospectData?.addressLine_2 || "",
      city: prospectData?.city || "",
      state: prospectData?.state || "",
      zipCode: prospectData?.zipcode ? "" + prospectData?.zipcode : "",
      property: prospectData?.interestedIn?.property || "",
      unit: prospectData?.interestedIn?.unit || "",
      company: prospectData?.company || "",
      jobTitle: prospectData?.jobTitle || "",
      phoneType: prospectData?.phoneType || "",
      notes: (prospectData?.notes && prospectData?.notes[0]?.description) || "",
    });
  }, [prospectData]);

  useEffect(() => {
    if (
      prospectData?.emergencyDetails &&
      prospectData?.emergencyDetails.length > 0
    ) {
      setEmergencyContacts(
        prospectData.emergencyDetails.map((emergencyData) => ({
          firstName: emergencyData?.firstName || "",
          middleName: emergencyData?.middleName || "",
          lastName: emergencyData?.lastName || "",
          email: emergencyData?.email || "",
          phone: emergencyData?.phone || "",
          relationship: emergencyData?.relationship || "",
          description: emergencyData?.description || "",
        }))
      );
    }

    if (prospectData?.pets && prospectData?.pets.length > 0) {
      setPets(
        prospectData.pets.map((petData) => ({
          petType: petData?.pet_type || "",
          petName: petData?.name || "",
          petWeight: petData?.weight || "",
          petBreed: petData?.breed || "",
        }))
      );
    }

    if (prospectData?.vehicles && prospectData?.vehicles.length > 0) {
      setVehicles(
        prospectData.vehicles.map((vehicleData) => ({
          vehicleType: vehicleData?.vehicle_type || "",
          vehicleMake: vehicleData?.make || "",
          vehicleModel: vehicleData?.model || "",
          vehicleYear: vehicleData?.year || "",
          vehicleColor: vehicleData?.color || "",
          vehicleLicense: vehicleData?.license || "",
        }))
      );
    }

    if (prospectData?.dependent && prospectData?.dependent.length > 0) {
      setDependentInfo(
        prospectData.dependent.map((dependentData) => ({
          firstName: dependentData?.first_name || "",
          middleName: dependentData?.middle_name || "",
          lastName: dependentData?.last_name || "",
          dob: dependentData?.dob || "",
          email: dependentData?.email || "",
          phone: dependentData?.phone || "",
          relationship: dependentData?.relationship || "",
          description: dependentData?.description || "",
        }))
      );
    }
  }, [prospectData]);

  // Reset pets and vehicles if property type is commercial
  useEffect(() => {
    if (selectedPropertyType === "commercial") {
      setPets([
        {
          petType: "",
          petName: "",
          petWeight: "",
          petBreed: "",
        },
      ]);
      setVehicles([
        {
          vehicleType: "",
          vehicleMake: "",
          vehicleModel: "",
          vehicleYear: "",
          vehicleColor: "",
          vehicleLicense: "",
        },
      ]);
    }
  }, [selectedPropertyType]);

  const handleChange = (fieldName, value) => {
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  const handleEmergencyChange = (field, index, value) => {
    const newEmergencyContacts = [...emergencyContacts];
    newEmergencyContacts[index][field] = value;
    setEmergencyContacts(newEmergencyContacts);
  };

  const handleRemoveContact = (indexToRemove) => {
    if (indexToRemove !== 0) {
      const updatedEmergencyContacts = emergencyContacts.filter(
        (contact, index) => index !== indexToRemove
      );
      setEmergencyContacts(updatedEmergencyContacts);
    }
  };

  const handleAddContact = () => {
    setEmergencyContacts([
      ...emergencyContacts,
      {
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phone: "",
        relationship: "",
        description: "",
      },
    ]);
  };

  const handlePetChange = (field, index, value) => {
    const newPets = [...pets];
    newPets[index][field] = value;
    setPets(newPets);
  };

  const addAnotherPet = () => {
    setPets([
      ...pets,
      { petType: "", petName: "", petWeight: "", petBreed: "" },
    ]);
  };

  const removePet = (indexToRemove) => {
    if (indexToRemove !== 0) {
      const updatedPets = pets.filter((pet, index) => index !== indexToRemove);
      setPets(updatedPets);
    }
  };

  const handleVehicleChange = (field, index, value) => {
    const newVehicles = [...vehicles];
    newVehicles[index][field] = value;
    setVehicles(newVehicles);
  };

  const addAnotherVehicle = () => {
    setVehicles([
      ...vehicles,
      {
        vehicleType: "",
        vehicleMake: "",
        vehicleModel: "",
        vehicleYear: "",
        vehicleColor: "",
        vehicleLicense: "",
      },
    ]);
  };

  const removeVehicle = (indexToRemove) => {
    if (indexToRemove !== 0) {
      const updatedVehicles = vehicles.filter(
        (vehicle, index) => index !== indexToRemove
      );
      setVehicles(updatedVehicles);
    }
  };

  const handleDependentChange = (field, index, value) => {
    const newDependentInfo = [...dependentInfo];
    newDependentInfo[index][field] = value;
    setDependentInfo(newDependentInfo);
  };

  const addAnotherDependent = () => {
    setDependentInfo([
      ...dependentInfo,
      {
        firstName: "",
        middleName: "",
        lastName: "",
        dob: "",
        email: "",
        phone: "",
        relationship: "",
        description: "",
      },
    ]);
  };

  const removeDependent = () => {
    if (dependentInfo.length > 1) {
      const updatedDependentInfo = dependentInfo.filter(
        (dependent, index) => index !== dependentInfo.length - 1
      );
      setDependentInfo(updatedDependentInfo);
    }
  };

  const handleBackButton = () => {
    if (currentStep === 1) {
      navigate("/all-prospect");
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleAdditionInfoSubmit = () => {
    const newErrors = validateProspectPersonalDetails(form);
    if (Object.keys(newErrors).length === 0) {
      setErrors({});
      setCurrentStep(currentStep + 1);
    } else {
      setErrors(newErrors);
    }
  };

  const handleSubmit = () => {
    const formData = prospectFormData(
      form,
      emergencyContacts,
      pets,
      vehicles,
      dependentInfo,
      ProfileImages
    );
    setIsLoading(true);
    updateProspect(prospectData?.id, formData).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        setOpenModal(true);
      }
    });
  };

  const handleNext = () => {
    switch (currentStep) {
      case 1:
        handleAdditionInfoSubmit();
        break;
      case 2:
      case 3:
      case 4:
        setCurrentStep(currentStep + 1);
        break;
      case 5:
        handleSubmit();
        break;
      default:
        break;
    }
  };

  return (
    <>
      {openModal && (
        <TempModal
          onClose={() => setOpenModal(false)}
          title={`Prospect updated successfully`}
          success="Go To Prospects"
          route="all-prospect"
        />
      )}
      <div className="container-fluid bg-white p-3">
        <div className="container">
          <Stepper currentStep={currentStep} />
          <div className="stepper-content-container mt-4">
            {currentStep === 1 && (
              <>
                {isLoading ? (
                  <Loader />
                ) : (
                  <PersonalDetailsForm
                    form={form}
                    handleChange={handleChange}
                    errors={errors}
                    setProfileImages={setProfileImages}
                    ProfileImages={ProfileImages}
                    setDeletedImages={setDeletedImages}
                    DeletedImages={DeletedImages}
                    handleNext={handleNext}
                    handlePropertyType={setSelectedPropertyType}
                  />
                )}
              </>
            )}
            {currentStep === 2 && (
              <EmergencyInfoForm
                emergencyContacts={emergencyContacts}
                handleChange={handleEmergencyChange}
                handleAddContact={handleAddContact}
                handleRemoveContact={handleRemoveContact}
                handleBackButton={handleBackButton}
                handleNext={handleNext}
              />
            )}
            {currentStep === 3 && (
              <DependentInfoForm
                dependentInfo={dependentInfo}
                handleChange={handleDependentChange}
                addAnotherDependent={addAnotherDependent}
                removeDependent={removeDependent}
                handleBackButton={handleBackButton}
                handleNext={handleNext}
              />
            )}
            {currentStep === 4 && (
              <PetsForm
                pets={pets}
                handleChange={handlePetChange}
                addAnotherPet={addAnotherPet}
                removePet={removePet}
                handleBackButton={handleBackButton}
                handleNext={handleNext}
                disabled={selectedPropertyType === "commercial"}
              />
            )}
            {currentStep === 5 && (
              <VehicleForm
                vehicles={vehicles}
                handleChange={handleVehicleChange}
                handleBackButton={handleBackButton}
                handleNext={handleNext}
                addAnotherVehicle={addAnotherVehicle}
                removeVehicle={removeVehicle}
                isSubmitForm={true}
                isLoading={isLoading}
                disabled={selectedPropertyType === "commercial"}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProspectDetailsEditModal;
