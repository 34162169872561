import React, { useState, useEffect } from "react";
import * as Component from "Components/GeneralComponents";
import * as Icon from "assets/icons";
import activeDot from "assets/_Dot.png";
import { formatDate } from "utils";
import { getSignDocumentLink, downloadDocument } from "network/tenant/signnow";

const DocumentsTab = ({ data, owner, isLoading }) => {
  const [key, setKey] = useState(null);
  const [loader, setLoader] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const signDocument = (docId, fieldId) => {
    const form = {
      documentId: docId,
      fieldId: fieldId,
    };
    setLoader(true);
    getSignDocumentLink(form).then((res) => {
      setLoader(false);
      if (res.apiCallStatus === "success") {
        // window.location.href = res.message.link;
        window.open(res.message.link, "_blank");
      }
    });
  };

  useEffect(() => {
    const mydata = data.filter((item) =>
      item.invites.some(
        (invite) => invite.email === localStorage.getItem("email")
      )
    );
    setFilteredData(
      mydata.map((item) => ({
        key: item.id,
        documentId: item.document_id,
        fieldId: item.invites.filter(
          (invite) => invite.email === localStorage.getItem("email")
        )[0].id,
        documentName: item.name,
        status: item.status,
        owner: owner,
        updateat: formatDate(item.updatedAt),
      }))
    );
  }, [data]);

  const columns = [
    {
      title: "Document Name",
      dataIndex: "documentName",
      render: (text) => (
        <>
          <div className="all-lease-property-text ">{text}</div>
        </>
      ),
    },
    {
      title: "Owner",
      dataIndex: "owner",
      render: (text) => (
        <>
          <div className="all-lease-property-text ">{text}</div>
        </>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updateat",
      render: (text) => (
        <>
          <div className="assign-date-container">
            <span className="assign-to-date">{text}</span>
          </div>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, status) => (
        <>
          {text === "pending" ? (
            <div
              onClick={() => signDocument(status.documentId, status.fieldId)}
              className="signnow-btn"
            >
              sign Now {loader && <Component.SpinnerLoader />}
            </div>
          ) : (
            <div className={"prospect-active-bar"}>
              <img src={activeDot} alt="signed" /> <span>{text}</span>
            </div>
          )}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative  cursor text-center "
            onClick={() => handleIconClick(setting.key)}
          >
            <Icon.VerticalElipsisIcon width={23} height={23} />
            <Component.RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleDownload: () => {
                  downloadDocument(setting.documentId);
                },
              }}
              fields={{
                download: true,
              }}
            />
          </div>
        </>
      ),
    },
  ];

  const handleIconClick = (result) => {
    const filterData = data.filter((item) => {
      return item.id === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  return (
    <>
      <div className="table-container mt-5 ">
        <Component.CustomTable
          loading={isLoading}
          pagination={true}
          columns={columns}
          dataSource={filteredData}
          rowSelection={false}
        />
      </div>
    </>
  );
};

export default DocumentsTab;
