import ArchiveChatUser from "Components/Chat/ArchiveChatUser/ArchiveChatUser";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const ChatsArchiveUser = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail="Archived"
        back="user-chat"
      />
      <ArchiveChatUser />
    </div>
  );
};

export default ChatsArchiveUser;
