import React from "react";
import { DatePicker, ConfigProvider, Space } from "antd";
import validateError from "assets/alert-circle-validate.png";
import calendarIcon from "assets/calendar.png";
import moment from "moment";

let calendar = ( <img src={calendarIcon} alt="" />);

const DateField = ({
  required = false,
  label = "",
  placeholder,
  defaultValue,
  handler,
  isError = false,
  errorMessage = false,
  disabled=false,
}) => {
  return (
    <div className="position-relative">
      {label && (
        <span className="tenant-personal-details-title">
          {label}{" "}
          <span className="sign-up-imp-star">{`${required ? "*" : ""}`}</span>
        </span>
      )}

      <div style={{ position: "relative" }}>
        <ConfigProvider
          theme={{
            components: {
              DatePicker: {
                zIndexPopupBase: 99999,
                colorPrimaryHover: "#EF6B3E",
                borderRadius: 4,
                fontFamily: "montserrat",
              },
            },
          }}
        >
          <Space
            style={{ width: "100%" }}
            className={((isError || errorMessage) && disabled == false) && `date-error-input`}
            direction="vertical"
          >
            <DatePicker
              suffixIcon={calendar}
              placeholder={defaultValue ? defaultValue : placeholder}
              style={{
                width: "100%",
                height: 45,
              }}
              onChange={handler}
              className="lease-date-picker"
              format={"MM/DD/YYYY"}
              disabled={disabled}
            />
          </Space>
        </ConfigProvider>
        {(isError && disabled == false) && (
          <div className="validate-icon1">
            <img src={validateError} alt="" />
          </div>
        )}
      </div>
      {(errorMessage && disabled == false) && (
        <div className="text-danger fw-bold mt-2" style={{ fontSize: "12px" }}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default DateField;
