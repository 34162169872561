import { Button } from "antd";
import { InputField, SubmitButton } from "Components/GeneralComponents";
import React from "react";

const UnitInformation = ({
  form,
  handleChange,
  isSubmitForm = false,
  errors,
  handleNext,
  handlePrev,
  remainingUnits,
  setOpenAccountModal,
  isLoader,
}) => {
  return (
    <div className="container-fluid mt-5 mb-4">
      <div className="row mt-4">
        <div className="col-md-12">
          <InputField
            name={"propertyUnit"}
            label={"How many units or add unit amount"}
            placeholder={"Number of Units"}
            required={true}
            type={"number"}
            value={form.propertyUnit}
            handler={(e) => handleChange("propertyUnit", e.target.value)}
            errorMessage={errors?.propertyUnit}
          />
          <div
            className={`${
              remainingUnits - parseInt(form.propertyUnit || 0) > 0
                ? "text-success"
                : "text-danger"
            } fw-bold mt-2`}
            style={{ fontSize: "12px" }}
          >
            {remainingUnits - parseInt(form.propertyUnit || 0) > 0 ? (
              `You have ${
                remainingUnits - parseInt(form.propertyUnit || 0)
              } units remaining now`
            ) : (
              <>
                You have not enough units, please purchase more units
                <Button
                  className="px-2 ms-2"
                  style={{
                    backgroundColor: "#ef6b3e",
                    color: "#fff",
                    border: "none",
                    borderRadius: "8px",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  onClick={() => setOpenAccountModal(true)}
                >
                  Purchase
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="stepper-btn d-flex justify-content-between mt-5 gap-1">
        <div style={{ width: "50%" }}>
          <SubmitButton type="contained" handler={handlePrev}>
            Back
          </SubmitButton>
        </div>
        <div style={{ width: "50%" }}>
          <SubmitButton
            isLoading={isLoader}
            handler={handleNext}
            disabled={remainingUnits - parseInt(form.propertyUnit || 0) <= 0}
          >
            {isSubmitForm ? "Submit" : "Next"}
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default UnitInformation;
