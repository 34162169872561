import React from "react";
import FileUploader from "Components/FileUploader/FileUploader";
import * as Components from "Components/GeneralComponents";
export const NoteForm = ({
  form,
  handleChange,
  errors,
  Images,
  setImages,
  handleCancel,
  handleSubmit,
  handleEdit,
  isEditing,
  isLoading,
}) => {
  return (
   <>
         <div className="container bg-white p-3">
        <div className="row">
          <div className="col-md-12">
            <Components.InputField
              required={true}
              name="note_name"
              placeholder="Note Name"
              label="Note Name"
              value={form.note_name}
              handler={(e) => handleChange("note_name", e.target.value)}
              errorMessage={errors.note_name}
            />
          </div>
        </div>
        <div className="row mt-4">
          <Components.TextAreaField
            required={true}
            name="description"
            placeholder="Add your note"
            label="Write Your Note"
            value={form.description}
            handler={(e) => handleChange("description", e.target.value)}
            errorMessage={errors.description}
            rows={10}
            cols={30}
          />
        </div>
        <div className="row mt-4 text-center">
          <div className="col-md-12">
            <div className="dragger">
              <FileUploader setImages={setImages} Images={Images} />
              {errors.files && (
                <span className="text-danger fw-semibold mt-3">
                  {errors.files}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="notes-btn d-flex align-items-center justify-content-center gap-3">
              <div style={{ width: "50%" }}>
                <Components.SubmitButton
                  type="contained"
                  handler={handleCancel}
                >
                  Cancel
                </Components.SubmitButton>
              </div>
              <div style={{ width: "50%" }}>
                <Components.SubmitButton
                  handler={isEditing ? handleEdit : handleSubmit}
                  isLoading={isLoading}
                >
                  Submit
                </Components.SubmitButton>
              </div>
            </div>
          </div>
        </div>
      </div>

   </>   
  )
}

export default NoteForm
