import React from "react";
import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import * as Icon from "assets/icons";
import * as Component from "Components/GeneralComponents";
import { Table, ConfigProvider, message } from "antd";
import Loader from "Helpers/Loader";
import SearchBar from "Helpers/SearchBar";
import plusIcon from "assets/plus.png";
import searchIcon from "assets/New icons (7).png";
import * as API from "network/landlord/tenant";
import { propertyIcon } from "utils";

const PropertyTab = ({ id }) => {
  const navigate = useNavigate();
  const [key, setKey] = React.useState(null);
  const [property, setProperty] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [hideFilter, setHideFilter] = useState(false);
  const [length, setLength] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    API.getTenantById(id).then((res) => {
      setLength(res?.message?.properties?.length);
      console.log("res====>", res.message);
      setIsLoading(false);
      setProperty(
        res?.message?.properties?.map((item) => {
          return {
            property: {
              title: item.title,
              id: item._id,
              img: item.img,
              type: item.type,
            },
            address: {
              address: item.address,
              id: item._id,
            },
            rent: item.rent_amount,
            status: {
              value: item.lease_status.value,
              days: item.lease_status.days,
            },
          };
        })
      );
    });
  }, [id]);

  const columns = [
    {
      title: "Property Name",
      dataIndex: "property",
      render: (text, propertyName) => (
        <>
          {console.log(text)}
          <Link
            to={`/property-details-view?id=${text.id}`}
            className="property-table-name-text d-flex align-items-center gap-2">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                overflow: "hidden",
                border: `0.5px solid #c9c9c9`,
                position: "relative",
              }}>
              {text.img.length !== 0 ? (
                <img
                  src={text.img}
                  alt="task"
                  style={{ width: "100%", height: "100%" }}
                />
              ) : (
                <img
                  src={propertyIcon(text.type)}
                  alt="task"
                  style={{
                    width: "2rem",
                    height: "2rem",
                    objectFit: "contain",
                  }}
                />
              )}
            </div>
            <span className="properties_dashboard_table_name_text">
              {text.title}
            </span>
          </Link>
        </>
      ),
    },
    {
      title: "Property Address",
      dataIndex: "address",
      render: (text, property) => (
        <Link to={`/property-details-view?id=${text.id}`}>
          <div className="all-lease-property-text">{text.address}</div>
        </Link>
      ),
    },
    {
      title: "Rent",
      dataIndex: "rent",
    },
    {
      title: "Lease Status",
      dataIndex: "status",
      render: (text, status) => (
        <>
          {text.days ? (
            <div>
              {" "}
              <p className="expire-label pb-0 mb-0"></p>
              <span className="about-to-expire-text">{text.value}</span>
              <br />
              <span className="error-text ">{text.days} days remaining</span>
            </div>
          ) : text.value === "Active" ? (
            <div>
              <p className="active-label pb-0 mb-0"></p>
              <span className="primary-orange-text">{text.value}</span>
            </div>
          ) : (
            <div>
              <p className="expired-label pb-0 mb-0"></p>
              <span className="expired-text">{text.value}</span>
            </div>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <div className="container-fluid d-lg-block">
        <div className="row">
          <div className="search-bar">
            <form>
              <div className="input-icon">
                <span className="input-icon-addon">
                  <img
                    src={searchIcon}
                    alt=""
                    className="icon"
                  />
                </span>
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Search"
                />
              </div>
            </form>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-3">
            <div
              className={hideFilter === true ? "sub-headings mt-4" : "d-none"}>
              <h2 className="property-sub-title ">Filters</h2>
            </div>
          </div>
          {/* <div
            className={
              hideFilter === true
                ? "col-md-9 d-flex justify-content-between"
                : "col-md-12 d-flex justify-content-between"
            }
          >
            <div className="sub-headings mt-4 d-flex gap-2 align-items-center">
              <h2 className="property-sub-title mb-0">Property</h2>
              <span className="badge badge-gray">
                <p className="badge-base-text">{length}</p>
              </span>
            </div>
            <div className="properties-dashbboard-btn-main btn-list mt-4">
              <button
                onClick={() => {
                  navigate("/add-property-details");
                }}
                className="add-property-btn"
              >
                <img src={plusIcon} alt="" className="add-property-icon" /> Add
                Property
              </button>
            </div>
          </div> */}
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="properties-dashboard-table-list properties-dashboard-table table-container mt-5">
            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    colorTextHeading: "#667085",
                    colorText: "#667085",
                    fontSize: 14,
                    fontWeightStrong: 500,
                    fontFamily: "Montserrat",
                  },
                  Pagination: {
                    itemActiveBg: "#EF6B3E",
                  },
                  Checkbox: {
                    colorPrimary: "#EF6B3E",
                    colorPrimaryHover: "#EF6B3E",
                  },
                },
              }}>
              <Table
                pagination={true}
                className="all-lease-table-main scroll-remove scroll-responsive-tablet"
                columns={columns}
                dataSource={property}
              />
            </ConfigProvider>
          </div>
        )}
      </div>
    </>
  );
};

export default PropertyTab;
