import { useState, useEffect } from "react";
import TempModal from "Modals/TempModal/TempModal";
import { vendorFormData } from "utils/FormDataStructure";
import { message } from "antd";
import { getVendor, updateVendor } from "network/landlord/vendor";
import * as Validation from "utils/validations";
import * as InnerComponent from "./index";
import Loader from "Helpers/Loader";
import { ConfigProvider } from "antd";
import { Stepper } from "Components/GeneralComponents";
import { addVendorDetails } from "utils/stepsData";

const EditVendorDetailsModal = ({ id }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [DeletedImages, setDeletedImages] = useState([]);
  const [ProfileImages, setProfileImages] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [form, setForm] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    phone_no: "",
    company: "",
    job: "",
    address1: "",
    address2: "",
    country: "USA",
    city: "",
    state: "",
    zipCode: "",
    services: "",
    provider: "",
    policyNum: "",
    coverage: "",
    effectiveDate: "",
    expireDate: "",
    send_1099_forms: false,
    can_access_all_properties: true,
    properties: [],
  });

  useEffect(() => {
    setIsDataLoading(true);
    getVendor(id).then((res) => {
      if (res.apiCallStatus === "success") {
        setIsDataLoading(false);
        setForm({
          first_name: res.message.data.firstName,
          middle_name: res.message.data.middleName,
          last_name: res.message.data.lastName,
          email: res.message.data.email,
          phone_no: res.message.data.phone,
          company: res.message.data.company,
          job: res.message.data.job_title,
          address1: res.message.data.address.address_line_1,
          address2: res.message.data.address.address_line_2,
          country: res.message.data.address.country,
          city: res.message.data.address.city,
          state: res.message.data.address.state,
          zipCode: res.message.data.address.zipcode.toString(),
          services: res.message.data.services,
          provider: res.message.data.insurance.provider,
          policyNum: res.message.data.insurance.policy_number,
          coverage: res.message.data.insurance.coverage,
          effectiveDate: res.message.data.insurance.effective_date,
          expireDate: res.message.data.insurance.expiration_date,
          send_1099_forms: res.message.data.send_1099_forms,
          can_access_all_properties:
            res.message.data.can_access_all_properties || false,
          properties: res.message.data.properties.map((e) => e.id),
        });
        setProfileImages(res.message.data?.profileImage || []);
      } else {
        message.error(res.error.message);
      }
      console.log(form);
    });
  }, []);

  const handleChange = (fieldName, value) => {
    // console.log(fieldName, value, "FIELDNAME");
    setForm({
      ...form,
      [fieldName]: value,
    });
    console.log(form);
  };

  const handlePersonalDetails = (e) => {
    const newErrors = Validation.validateVendorPersonalDetailsForm(form);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleServicesForm = (e) => {
    if (form.services === "") {
      setErrors({ services: "Please select a service" });
    } else {
      setErrors({});
      setCurrentStep(currentStep + 1);
    }
  };

  const handleInsuranceForm = (e) => {
    const newErrors = Validation.validateVendorInsuranceForm(form);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePropertiesForm = (e) => {
    if (
      form.can_access_all_properties === false &&
      form.properties.length === 0
    ) {
      setErrors({ properties: "Please select a property" });
    } else {
      setErrors({});
      setCurrentStep(currentStep + 1);
    }
  };

  const handleUpdateVendor = () => {
    const formData = vendorFormData(form);

    console.log(JSON.stringify(formData, null, 2));
    console.log("Updating Vendor...");
    console.log(form);

    setIsLoading(true);
    updateVendor(id, formData).then((res) => {
      console.log("Updated Successfully");
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        setOpenModal(true);
      } else {
        message.error(res.error.message);
      }
    });
  };

  // Stepper Function
  const handleNext = () => {
    switch (currentStep) {
      case 1:
        handlePersonalDetails();
        break;
      case 2:
        handleServicesForm();
        break;
      case 3:
        handleInsuranceForm();
        break;
      case 4:
        handlePropertiesForm();
        break;
      case 5:
        handleUpdateVendor();
        break;
    }
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <>
      {openModal && (
        <TempModal
          onClose={() => setOpenModal(false)}
          title="Vendors Edited successfully"
          success="Go To Vendors"
          route="all-vendor"
        />
      )}

      <div className="container-fluid bg-white p-3">
        <div className="container">
          <Stepper
            steps={addVendorDetails}
            activeStep={currentStep - 1}
            lockStep={false}
            maxSteps={4} // Customize if desired
            setIsDraft={(isDraft) => console.log("Draft status:", isDraft)}
          />
          {isDataLoading ? (
            <Loader />
          ) : (
            <div className="stepper-content-container mt-5">
              {currentStep === 1 && (
                <InnerComponent.VendorDetailsForm
                  form={form}
                  handleChange={handleChange}
                  errors={errors}
                  handleNext={handleNext}
                  setProfileImages={setProfileImages}
                  ProfileImages={ProfileImages}
                  setDeletedImages={setDeletedImages}
                  DeletedImages={DeletedImages}
                  disableEmail={true}
                />
              )}
              {currentStep === 2 && (
                <InnerComponent.ServicesForm
                  form={form}
                  handleChange={handleChange}
                  errors={errors}
                  handleBackButton={handlePrev}
                  handleNext={handleNext}
                />
              )}
              {currentStep === 3 && (
                <InnerComponent.InsuranceForm
                  form={form}
                  handleChange={handleChange}
                  errors={errors}
                  handleBackButton={handlePrev}
                  handleNext={handleNext}
                />
              )}
              {currentStep === 4 && (
                <InnerComponent.PropertiesForm
                  form={form}
                  handleChange={handleChange}
                  errors={errors}
                  handleBackButton={handlePrev}
                  handleNext={handleNext}
                />
              )}
              {currentStep === 5 && (
                <InnerComponent.FederalTaxesForm
                  form={form}
                  handleChange={handleChange}
                  handleBackButton={handlePrev}
                  handleNext={handleNext}
                  isLoading={isLoading}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditVendorDetailsModal;
