import React, { useEffect, useState } from "react";
import ProfileUploader from "../ProfileUploader/ProfileUploader";
import { usaStates, selectPhoneType } from "utils/dropdownData";
import { formatDate } from "utils";
import { availableProperties } from "network/landlord/prospect";
import {
  InputField,
  PhoneNumberField,
  DateField,
  SelectField,
  TextAreaField,
  SubmitButton,
} from "Components/GeneralComponents";

const PersonalDetails = ({
  form,
  handleChange,
  errors,
  setProfileImages,
  ProfileImages,
  setDeletedImages,
  DeletedImages,
  handleNext,
  handlePropertyType,
}) => {
  const [properties, setProperties] = useState([]);
  const [units, setUnits] = useState([]);
  const [makeEmptyUnit, setMakeEmptyUnit] = useState(false);

  // Fetch available properties
  useEffect(() => {
    availableProperties().then((response) => {
      if (response.apiCallStatus == "success") {
        setProperties(response.message.propertiesWithUnassignedUnits);
      }
    });
  }, []);

  // Set units based on selected property
  useEffect(() => {
    if (form.property && properties.length > 0) {
      const selectedProperty = properties.find(
        (property) => property._id === form.property
      );
      handlePropertyType(selectedProperty.property_type);
      setUnits(selectedProperty.units);
    }
  }, [properties]);

  // Handle property change
  const handlePropertyChange = (e) => {
    handleChange("property", e);
    const selectedProperty = properties.find((property) => property._id === e);
    handlePropertyType(selectedProperty.property_type);
    setUnits(selectedProperty.units);
    setMakeEmptyUnit(true);
  };

  // Make unit field empty when property changes
  useEffect(() => {
    if (makeEmptyUnit) {
      handleChange("unit", "");
      setMakeEmptyUnit(false);
    }
  }, [form.property]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <div className="stepper-content d-flex gap-2 align-items-center">
            <div className="dragger">
              <p>Your Photo</p>
              <ProfileUploader
                setProfileImages={setProfileImages}
                ProfileImages={ProfileImages}
                setDeletedImages={setDeletedImages}
                DeletedImages={DeletedImages}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6"></div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <InputField
            name={"First Name"}
            required={true}
            label={"First Name"}
            placeholder={"First Name"}
            value={form.firstName}
            handler={(e) => handleChange("firstName", e.target.value)}
            errorMessage={errors.firstName}
          />
        </div>
        <div className="col-md-4">
          <InputField
            name={"Middle Name"}
            label={"Middle Name"}
            placeholder={"Middle Name"}
            value={form.middleName}
            handler={(e) => handleChange("middleName", e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <InputField
            name={"Last Name"}
            required={true}
            label={"Last Name"}
            placeholder={"Last Name"}
            value={form.lastName}
            handler={(e) => handleChange("lastName", e.target.value)}
            errorMessage={errors.lastName}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <InputField
            name={"Email"}
            required={true}
            label={"Email"}
            placeholder={"Email"}
            value={form.email}
            handler={(e) => handleChange("email", e.target.value)}
            errorMessage={errors.email}
            disabled={true}
          />
        </div>
        <div className="col-md-4">
          <SelectField
            name={"Phone Type"}
            required={true}
            label={"Phone Type"}
            placeholder={"Select Phone Type"}
            value={form.phoneType}
            options={selectPhoneType}
            handler={(e) => handleChange("phoneType", e)}
            errorMessage={errors.phoneType}
          />
        </div>
        <div className="col-md-4">
          <PhoneNumberField
            name={"Phone No"}
            required={true}
            label={"Phone No"}
            placeholder={"Phone No"}
            value={form.phone}
            handler={(value) => handleChange("phone", value)}
            errorMessage={errors.phone}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <DateField
            required={true}
            label={"Date of Birth"}
            placeholder={"Select Date"}
            defaultValue={form.dob && formatDate(form.dob)}
            handler={(e) => {
              const formattedDate = formatDate(e);
              handleChange("dob", formattedDate);
            }}
            errorMessage={errors.dob}
          />
        </div>
        <div className="col-md-4">
          <InputField
            name={"Company"}
            label={"Company"}
            placeholder={"Company"}
            value={form.company}
            handler={(e) => handleChange("company", e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <InputField
            name={"Job Title"}
            label={"Job Title"}
            placeholder={"Job Title"}
            value={form.jobTitle}
            handler={(e) => handleChange("jobTitle", e.target.value)}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6">
          <InputField
            name={"Address Line 1"}
            required={true}
            label={"Address Line 1"}
            placeholder={"Address Line 1"}
            value={form.addressLine_1}
            handler={(e) => handleChange("addressLine_1", e.target.value)}
            errorMessage={errors.addressLine_1}
          />
        </div>
        <div className="col-md-6">
          <InputField
            name={"Address Line 2"}
            label={"Address Line 2"}
            placeholder={"Address Line 2"}
            value={form.addressLine_2}
            handler={(e) => handleChange("addressLine_2", e.target.value)}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-3">
          <SelectField
            name={"Country"}
            required={true}
            label={"Country"}
            placeholder={"Select Country"}
            value={"USA"}
            options={[{ value: "USA" }, { label: "Canada" }]}
            handler={(e) => handleChange("country", e)}
            errorMessage={errors.country}
            disabled={true}
          />
        </div>
        <div className="col-md-3">
          <InputField
            name={"City"}
            required={true}
            label={"City"}
            placeholder={"City"}
            value={form.city}
            handler={(e) => handleChange("city", e.target.value)}
            errorMessage={errors.city}
          />
        </div>
        <div className="col-md-3">
          <SelectField
            name={"State"}
            required={true}
            label={"State"}
            placeholder={"Select State"}
            value={form.state}
            options={usaStates}
            handler={(e) => handleChange("state", e)}
            errorMessage={errors.state}
          />
        </div>
        <div className="col-md-3">
          <InputField
            name={"Postal code"}
            required={true}
            label={"Zip Code"}
            placeholder={"Zip Code"}
            value={form.zipCode}
            handler={(e) => {
              if (/^\d{0,5}$/.test(e.target.value))
                handleChange("zipCode", e.target.value);
            }}
            errorMessage={errors.zipCode}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6">
          <SelectField
            name={"property"}
            required={true}
            label={"Property"}
            TooltipTitle={
              "User cannot add a prospect until they have added a property and units"
            }
            placeholder={"Select Property"}
            value={form.property}
            options={properties.map((e) => ({ value: e._id, label: e.title }))}
            handler={handlePropertyChange}
            errorMessage={errors.property}
          />
        </div>
        <div className="col-md-6">
          <SelectField
            name={"unit"}
            required={true}
            label={"Unit"}
            TooltipTitle={
              "User cannot add a prospect until they have added a property and units"
            }
            placeholder={"First select property then unit"}
            value={form.unit}
            options={units.map((e) => ({ value: e.id, label: e.name }))}
            handler={(e) => handleChange("unit", e)}
            errorMessage={errors.unit}
            disabled={units.length === 0 ? true : false}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <TextAreaField
            name={"Notes"}
            label={"Add Notes"}
            placeholder={"Add your notes"}
            value={form.notes}
            handler={(e) => handleChange("notes", e.target.value)}
            rows={"10"}
            cols={"30"}
          />
        </div>
      </div>
      <div className="stepper-first-btn property-details-next-btn-main d-flex justify-content-between gap-3 mt-5">
        <SubmitButton handler={handleNext}>Next</SubmitButton>
      </div>
    </div>
  );
};

export default PersonalDetails;
