import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import PropertyAddAccount from "Components/PropertyAddAccount/PropertyAddAccount";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
const PropertyAddAccountPage = () => {
  return (
    <>
      <Fixedtopbar topBarTitle={"Accounting"} />
      <PropertyAddAccount />
    </>
  );
};

export default PropertyAddAccountPage;
