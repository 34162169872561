import React, { useEffect, useState } from "react";
import { message, Tabs } from "antd";
import { ConfigProvider } from "antd";
import UnitDetailsSliderModal from "Modals/UnitDetailsSliderModal/UnitDetailsSliderModal";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import AddTaskUnit from "Modals/AddTaskUnit/AddTaskUnit";
import settingIconOrange from "assets/dots-vertical.png";
import AddUnitDetailsEditModal from "Modals/AddUnitDetailsEditModal/AddUnitDetailsEditModal";
import OverviewTab from "./OverviewTab";
import { getUnit } from "network/landlord/property";
import LeasesTab from "./LeasesTab";
import TenantsTab from "./TenantsTab";
import { FilesTab, NotesTab } from "Components/TenantDetailsView/index.js";
import TasksTab from "./TasksTab";
import { deleteUnit } from "network/landlord/property";
import { CustomTabs, DeleteModal, RecordMenuPopup } from "Components/GeneralComponents";
import { useLocation, useNavigate } from "react-router-dom";

const PropertiesUnitsView = () => {
  const { id, property_id, tab } = UseUrlParamsHook();
  const navigate = useNavigate();
  const [openModalTask, setOpenModalTask] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [EditUnit, setEditUnit] = useState(false);
  const [update, setUpdate] = useState(false);
  const [unitData, setUnitData] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [isDeleteRender, setIsDeleteRender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const items = [
    {key: "1",  label: "Overview",  children: <OverviewTab unitData={unitData} isLoading={isLoading} />},
    {key: "2",  label: "Leases",  children: <LeasesTab />,},
    {key: "3",  label: "Tenants",  children: <TenantsTab id={id} property={property_id} />},
    {key: "4",  label: "Tasks",  children: <TasksTab id={id} />,},
    {key: "5",  label: "Files",  children: <FilesTab category="property" id={property_id} />},
    {key: "6",  label: "Notes",  children: <NotesTab category="property" id={property_id} />},
  ]

  useEffect(() => {
    setActiveTab(tab || "1");
  }, [tab]);

  const onChange = (key) => {
    setActiveTab(key);
    searchParams.set("tab", key);
    navigate({ search: searchParams.toString() });
  };

  const onCloseModalTask = () => {
    setOpenModalTask(false);
  };
  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };
  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const onCloseEditModalUnit = () => {
    setEditUnit(false);
  };

  const handleIconClickCustom = () => {
    setDropdownOpen(!isDropdownOpen);
  };


  useEffect(() => {
    setIsLoading(true);
    getUnit(id).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        setUnitData(res.message);
      }
    });
  }, [update]);

  useEffect(() => {
    if (deleteId) {
      setIsLoader(true);
      deleteUnit(deleteId).then((res) => {
        setIsLoader(false);
        if (res.apiCallStatus === "success") {
          onCloseDeleteModal();
          message.success("Unit Deleted Successfully");
          setDeleteId("");
          setUpdate(true);
        } else {
          message.error("Error in deleting unit");
        }
      });
    }
  }, [isDeleteRender]);

  return (
    <>
      {EditUnit && (
        <AddUnitDetailsEditModal
          setUpdate={setUpdate}
          id={id}
          onClose={onCloseEditModalUnit}
        />
      )}
      {openModal === true && <UnitDetailsSliderModal onClose={onCloseModal} />}
      {openModalTask === true && (
        <AddTaskUnit
          id={id}
          property={property_id}
          onOpen={onOpenModal}
          onClose={onCloseModalTask}
        />
      )}
      <DeleteModal
        isOpen={openDeleteModal}
        onClose={onCloseDeleteModal}
        message={"Are you sure you want to delete this unit"}
        deleteCall={() => setIsDeleteRender(!isDeleteRender)}
        isLoading={isLoader}
      />
      <div className="container-fluid bg-white p-3">
        <div className="row">
          <div className="col-md-12 text-end">
            <span className="position-relative">
              <img
                onClick={handleIconClickCustom}
                src={settingIconOrange}
                alt="Action Buttons"
                className="cursor"
              />
              <RecordMenuPopup
                isOpen={isDropdownOpen}
                onClose={() => setDropdownOpen(false)}
                handler={{
                  handleEdit: () => {
                    navigate(
                      `/properties-units-edit?id=${id}&propertyId=${property_id}`
                    );
                  },
                  handleDeleteRecord: () => {
                    onOpenDeleteModal();
                    setDeleteId(id);
                  },
                }}
                fields={{
                  edit: true,
                  delete: true,
                }}
              />
            </span>
          </div>
        </div>
        {activeTab && <CustomTabs
          items={items}
          defaultActiveKey={activeTab}
          onChange={onChange}
        />}
      </div>
    </>
  );
};

export default PropertiesUnitsView;
