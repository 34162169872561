import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import LocalProfessionalDetail from "Components/LocalProfessionalDetail/LocalProfessionalDetail";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const LocalProfessionalDetailPage = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Water Zone"}
        back="all-service-professional"
      />
      <LocalProfessionalDetail />
    </div>
  );
};

export default LocalProfessionalDetailPage;
