import React from "react";
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { useNavigate } from "react-router-dom";
import config from "Helpers/config.js";
import { Skeleton, Space } from "antd";

const AccountDetails = ({
  connectStripe,
  message,
  account_id,
  isloading = false,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="container">
            <ConnectComponentsProvider connectInstance={connectStripe}>
              {isloading ? (
                <>
                  <Skeleton
                    active
                    paragraph={{ rows: 1, width: "100%" }}
                    title={{ width: "40%", style: { margin: "auto" } }}
                    round
                  />
                  <div className="d-flex justify-content-center mt-3">
                    <Space>
                      <Skeleton.Button
                        active
                        style={{ width: "200px", height: "25px" }}
                      />
                    </Space>
                  </div>
                </>
              ) : (
                <ConnectAccountOnboarding
                  onExit={() => {
                    message.success("Account added");
                    fetch(`${config.baseUrl}/api/stripe/connectConfirm`, {
                      method: "PUT",
                      headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                          "token"
                        )}`,
                      },
                      body: JSON.stringify({
                        account_id: account_id,
                      }),
                    })
                      .then((res) => {
                        return res.json();
                      })
                      .then((res) => {
                        console.log(res);
                      })
                      .catch((e) => console.log(e, "error"));
                    setTimeout(() => {
                      navigate("/all-accounts");
                    }, 1000);
                  }}
                />
              )}
            </ConnectComponentsProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountDetails;
