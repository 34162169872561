import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import ResponsiveSetting from "Components/ResponsiveSetting/ResponsiveSetting";
import Setting from "Components/Setting/Setting";
import React from "react";

const Settings = () => {
  return (
    <div className="row hv-100">
      <div className="col-xl-12 d-lg-12 d-lg-block d-xl-block d-md-none d-none">
        <div className="right-component bg-white d-flex align-items-stretch">
          <Fixedtopbar topBarTitle="Setting" />
          <Setting />
        </div>
      </div>
      <div className="col-md-12 mt-3 d-lg-none d-md-block">
        <div className="right-component bg-white d-flex align-items-stretch">
          <Fixedtopbar topBarTitle="Setting" />
          <ResponsiveSetting />
        </div>
      </div>
    </div>
  );
};

export default Settings;
