import React, { useEffect, useState } from "react";
import { formatPhoneNumber } from "utils";
import { getProspectById } from "network/landlord/prospect";
import Loader from "Helpers/Loader";

const Overview = ({ id }) => {
  const [prospect, setProspect] = useState({});
  const [isLoader, setIsLoader] = useState(false);

  // Get Prospect By Id
  useEffect(() => {
    setIsLoader(true);
    getProspectById(id).then((res) => {
      setIsLoader(false);
      setProspect(res.message);
    });
  }, []);

  return (
    <>
      {isLoader ? (
        <Loader />
      ) : (
        <>
          <div className="row mt-5">
            <div className="col-md-10">
              <div className="task-info-heading">
                <h4>Personal Info</h4>
              </div>
              <div className="task-info-lists mt-5">
                <div className="row">
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">
                        First Name:
                      </span>{" "}
                      {prospect?.firstName || "N/A"}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">
                        Middle Name:
                      </span>{" "}
                      {prospect?.middleName || "N/A"}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">
                        Last Name:
                      </span>{" "}
                      {prospect?.lastName || "N/A"}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">Email:</span>
                      {prospect?.email || "N/A"}
                    </p>
                  </div>
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">
                        Phone No:
                      </span>{" "}
                      {formatPhoneNumber(prospect?.phone) || "N/A"}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">
                        Date of Birth:
                      </span>{" "}
                      {new Date(prospect?.dob).toLocaleDateString() || "N/A"}
                    </p>
                  </div>
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">
                        phoneType:
                      </span>{" "}
                      {prospect?.phoneType || "N/A"}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">Company:</span>{" "}
                      {prospect?.company || "N/A"}
                    </p>
                  </div>
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">
                        Job Title:
                      </span>{" "}
                      {prospect?.jobTitle || "N/A"}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <p>
                      <span className="task-info-list-span me-3">
                        Current Address:
                      </span>{" "}
                      {prospect?.addressLine_1 || prospect?.addressLine_2
                        ? `${prospect?.addressLine_1} ${prospect?.addressLine_2}`
                        : "N/A"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 text-end mt-5">
              <img
                className="img-fluid rounded-5"
                src={`${prospect?.profileImage}`}
                alt=""
              />
            </div>
          </div>
          {prospect?.emergencyDetails &&
            prospect?.emergencyDetails.length > 0 && (
              <>
                <hr />
                <div className="row mt-4">
                  <div className="col-md-12">
                    <div className="task-info-heading">
                      <h4>
                        Emergency Info{" "}
                        <span style={{ color: "#ef6b3e" }}>
                          {" "}
                          ({prospect?.emergencyDetails?.length}){" "}
                        </span>
                      </h4>
                    </div>
                    {prospect?.emergencyDetails.map(
                      (emergencyDetailsData, index) => {
                        return (
                          <div className="task-info-lists mt-5" key={index}>
                            <div className="row">
                              <div className="col-md-4">
                                <p>
                                  <span className="task-info-list-span me-3">
                                    First Name:
                                  </span>{" "}
                                  {emergencyDetailsData?.firstName || "N/A"}
                                </p>
                              </div>
                              <div className="col-md-4">
                                <p>
                                  <span className="task-info-list-span me-3">
                                    Middle Name:
                                  </span>{" "}
                                  {emergencyDetailsData?.middleName || "N/A"}
                                </p>
                              </div>
                              <div className="col-md-4">
                                <p>
                                  <span className="task-info-list-span me-3">
                                    Last Name:
                                  </span>{" "}
                                  {emergencyDetailsData?.lastName || "N/A"}
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <p>
                                  <span className="task-info-list-span me-3">
                                    Email:
                                  </span>{" "}
                                  {emergencyDetailsData?.email || "N/A"}
                                </p>
                              </div>
                              <div className="col-md-4">
                                <p>
                                  <span className="task-info-list-span me-3">
                                    Phone No:
                                  </span>{" "}
                                  {formatPhoneNumber(
                                    emergencyDetailsData?.phone
                                  ) || "N/A"}
                                </p>
                              </div>
                              <div className="col-md-4"></div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <p>
                                  <span className="task-info-list-span me-3">
                                    Relationship:
                                  </span>{" "}
                                  {emergencyDetailsData?.relationship || "N/A"}
                                </p>
                              </div>
                              <div className="col-md-8">
                                <p>
                                  <span className="task-info-list-span me-3">
                                    Description:
                                  </span>{" "}
                                  {emergencyDetailsData?.description || "N/A"}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </>
            )}
          {prospect?.pets && prospect?.pets.length > 0 && (
            <>
              <hr />
              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="task-info-heading">
                    <h4>
                      Pet Info{" "}
                      <span style={{ color: "#ef6b3e" }}>
                        {" "}
                        ({prospect?.pets?.length}){" "}
                      </span>
                    </h4>
                  </div>
                  {prospect?.pets?.map((pet, index) => {
                    return (
                      <div className="task-info-lists mt-5" key={index}>
                        <div className="row">
                          <div className="col-md-4">
                            <p>
                              <span className="task-info-list-span me-3">
                                Pet Type:
                              </span>{" "}
                              {pet.pet_type || "N/A"}
                            </p>
                          </div>
                          <div className="col-md-4">
                            <p>
                              <span className="task-info-list-span me-3">
                                Name:
                              </span>{" "}
                              {pet.name || "N/A"}
                            </p>
                          </div>
                          <div className="col-md-4"></div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <p>
                              <span className="task-info-list-span me-3">
                                Weight:
                              </span>{" "}
                              {pet.weight || "N/A"}
                            </p>
                          </div>
                          <div className="col-md-4">
                            <p>
                              <span className="task-info-list-span me-3">
                                Breed:
                              </span>{" "}
                              {pet.breed || "N/A"}
                            </p>
                          </div>
                          <div className="col-md-4"></div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
          {prospect?.vehicles && prospect?.vehicles.length > 0 && (
            <>
              <hr />
              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="task-info-heading">
                    <h4>
                      Vehicle Info{" "}
                      <span style={{ color: "#ef6b3e" }}>
                        {" "}
                        ({prospect?.vehicles?.length}){" "}
                      </span>
                    </h4>
                  </div>
                  {prospect?.vehicles?.map((vehicle, index) => {
                    return (
                      <div className="task-info-lists mt-5" key={index}>
                        <div className="row">
                          <div className="col-md-12">
                            <p>
                              <span className="task-info-list-span me-3">
                                Vehicle Type:
                              </span>{" "}
                              {vehicle?.vehicle_type || "N/A"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <p>
                              <span className="task-info-list-span me-3">
                                Make:
                              </span>{" "}
                              {vehicle?.make || "N/A"}
                            </p>
                          </div>
                          <div className="col-md-4">
                            <p>
                              <span className="task-info-list-span me-3">
                                Model:
                              </span>{" "}
                              {vehicle?.model || "N/A"}
                            </p>
                          </div>
                          <div className="col-md-4"></div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <p>
                              <span className="task-info-list-span me-3">
                                Year:
                              </span>{" "}
                              {vehicle?.year || "N/A"}
                            </p>
                          </div>
                          <div className="col-md-4">
                            <p>
                              <span className="task-info-list-span me-3">
                                Color:
                              </span>{" "}
                              {vehicle?.color || "N/A"}
                            </p>
                          </div>
                          <div className="col-md-4">
                            <p>
                              <span className="task-info-list-span me-3">
                                License Plate:
                              </span>{" "}
                              {vehicle?.license || "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
          {prospect?.dependent && prospect?.dependent.length > 0 && (
            <>
              <hr />
              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="task-info-heading">
                    <h4>
                      Dependent Info{" "}
                      <span style={{ color: "#ef6b3e" }}>
                        {" "}
                        ({prospect?.dependent?.length}){" "}
                      </span>
                    </h4>
                  </div>
                  {prospect?.dependent.map((dependent, index) => {
                    return (
                      <div className="task-info-lists mt-5">
                        <div className="row">
                          <div className="col-md-4">
                            <p>
                              <span className="task-info-list-span me-3">
                                First Name:
                              </span>{" "}
                              {dependent?.first_name || "N/A"}
                            </p>
                          </div>
                          <div className="col-md-4">
                            <p>
                              <span className="task-info-list-span me-3">
                                Middle Name:
                              </span>{" "}
                              {dependent?.middle_name || "N/A"}
                            </p>
                          </div>
                          <div className="col-md-4">
                            <p>
                              <span className="task-info-list-span me-3">
                                Last Name:
                              </span>{" "}
                              {dependent?.last_name || "N/A"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <p>
                              <span className="task-info-list-span me-3">
                                Date of Birth:
                              </span>{" "}
                              {dependent?.dob
                                ? new Date(dependent?.dob).toLocaleDateString()
                                : "N/A"}
                            </p>
                          </div>
                          <div className="col-md-4"></div>
                          <div className="col-md-4">
                            <p>
                              <span className="task-info-list-span me-3">
                                Relationship:
                              </span>{" "}
                              {dependent?.relationship || "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Overview;
