import React, { useEffect, useState, useRef } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import VerifyEmailModal from "Components/GeneralComponents/Modals/VerifyEmailModal";
import { FetchUser, FetchUserTenant } from "network/landlord/Account";
import { getCurrentSubscription } from "network/landlord/subscription";
import TrialEndedModal from "Components/GeneralComponents/Modals/TrialEndedModal";
import AccountSuspensionModal from "Components/GeneralComponents/Modals/AccountSuspensionModal";
import Sidebar from "Components/SideDrawer/Sidebar";
import { closeDrawer } from "Store/Slices/SideDrawerSlice";
import Settings from "Pages/ServiceProfessionals/Settings/Settings";
import { CompanyProfileSetup } from "Pages/ServiceProfessionals";
const Home = () => {
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const [isVerifyEmail, setIsVerifyEmail] = useState(false);
  const [isUpgraded, setIsUpgraded] = useState(false);
  const [isSuspended, setIsSuspended] = useState(false);
  const drawer = useSelector((state) => state.sideDrawer.drawerState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebarRef = useRef(null);
  const [companyProfile, setCompanyProfile] = useState(false);
  useEffect(() => {
    if (!role || !token) {
      navigate("/onboarding");
    }
  }, [role, token, navigate]);

  useEffect(() => {
    if(role && role=="serviceprovider") {
      setCompanyProfile(true)
    }
  },[])

  useEffect(() => {
    if (role === "landlord") {
      FetchUser().then((res) => {
        if (res.apiCallStatus === "success") {
          setIsVerifyEmail(!res.message.isEmailVerified);
        } else {
          navigate("/onboarding");
        }
      });
    } else if (role === "tenant") {
      FetchUserTenant().then((res) => {
        if (res.apiCallStatus === "success") {
          setIsVerifyEmail(!res.message.isEmailVerified);
        } else {
          navigate("/onboarding");
        }
      });
    }
  }, [role]);

  useEffect(() => {
    if (role === "landlord") {
      getCurrentSubscription().then((res) => {
        if (res.apiCallStatus === "success") {
          const subscription = res.message.subscription;
          if (subscription.plan === "Trial") {
            const trialEndDate = new Date(subscription.trialEndDate);
            if (trialEndDate.getTime() < Date.now()) {
              setIsUpgraded(true);
            }
          } else if (["Basic", "Pro", "Premium"].includes(subscription.plan) && subscription.status === "Expired") {
            setIsSuspended(true);
          }
        }
      });
    }
  }, [role]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isSidebar = event.target.closest('#sidebar') || event.target.closest('.ant-dropdown-menu') || event.target.closest('#sidebar-bottom');
      const isMenuItem = isSidebar && event.target.classList.contains('ant-menu-item');
      const isProfileLink = event.target.closest('.profile-progress') || event.target.closest('.complete-profile-link');
      const isCreateButton = event.target.closest('.create-button') || event.target.classList.contains('modal-save-btn');
      const isLogo = event.target.closest('.logo-class'); // Replace '.logo-class' with your actual logo class or ID
      
  
      // Check if the click is outside the sidebar and not from the allowed elements
      const isMobileScreen = window.innerWidth < 1200;
  
      // Keep drawer open if the click is on the specified elements
      if (isMobileScreen && !(isSidebar || isMenuItem || isProfileLink || isCreateButton || isLogo)) {
        dispatch(closeDrawer());
      }
    };
  
    const onClose = () => {
      dispatch(closeDrawer());
    };
  
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);
  

  // Define the onClose function
  const onClose = () => {
    dispatch(closeDrawer());
  };

  const handleVerifyEmailModalClose = () => {
    setIsVerifyEmail(false);
  };

  const handleUpgradeNow = () => {
    setIsUpgraded(false);
  };

  const handleSuspension = () => {
    setIsSuspended(false);
  };

  return (
    <div>
      <CompanyProfileSetup isOpen={companyProfile}  onClose={()=> setCompanyProfile(false)} />
      <TrialEndedModal isOpen={isUpgraded} onClose={handleUpgradeNow} />
      <VerifyEmailModal
        isOpen={isVerifyEmail}
        onClose={handleVerifyEmailModalClose}
        isSkip={true}
      />
      <AccountSuspensionModal isOpen={isSuspended} onClose={handleSuspension} />
      {!isVerifyEmail && !isUpgraded && (
        <div className="container-fluid">
          <div className="row vh-100" style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
            {/* Sidebar */}
            <div
              className="p-sm-0 d-md-none d-lg-none d-xl-block d-none position-relative drawer-bg-primary"
              style={{
                flex: "0 0 300px",
                maxWidth: "300px",
                backgroundColor: "#F8F9FA",
              }}
            >
              <Sidebar onClose={onClose}/>
            </div>
            {/* Responsive Drawer */}
            <div ref={sidebarRef} className={drawer ? "res-drawer-bg-open" : "res-drawer-bg-close"}>
              <div className={drawer ? "drawer-bg-primary responsive-drawer-container-open" : "drawer-bg-primary responsive-drawer-container"}>
                <Sidebar onClose={onClose} />
              </div>
            </div>
            {/* Main Content */}
            <div
              className="flex-grow-1"
              style={{
                overflowY: "auto",
                boxSizing: "border-box",
                width: "100vw",
                flexShrink: "1",
                overflowX: "scroll",
                padding: "20px",
              }}
            >
              <Outlet />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
