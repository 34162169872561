import { message } from "antd";
import React, { useState } from "react";
import {
  PasswordField,
  PasswordValidate,
  SubmitButton,
} from "Components/GeneralComponents";
import {
  updateLandlordPassword,
  updateTenantPassword,
  updateLandLordUserPassword,
} from "network/landlord/setting";
import { checkPasswordCriteria } from "utils";

const SettingsLoginInfo = () => {
  // States start
  const role = localStorage.getItem("role");
  const [oldPass, setoldPass] = useState("");
  const [newPass, setnewPass] = useState("");
  const [cnewPass, setcnewPass] = useState("");
  const [loader, setLoader] = useState(false);
  const [form, setForm] = useState({
    file_name: "",
  });

  const [errors, setErrors] = useState({});
  const handleChange = (fieldName, value) => {
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!oldPass) newErrors.oldPass = "Current password is required";
    if (!newPass) newErrors.newPass = "New password is required";
    if (!cnewPass) newErrors.cnewPass = "Confirm password is required";
    if (newPass && cnewPass && newPass !== cnewPass)
      newErrors.cnewPass = "New password does not match";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // States end
  const updatepasswordLandlord = () => {
    if (!validateForm()) {
      setLoader(false);
      return;
    }

    updateLandlordPassword(oldPass, newPass, cnewPass).then((res) => {
      setLoader(false);
      if (res.apiCallStatus === "success") {
        setoldPass("");
        setnewPass("");
        setcnewPass("");
        message.success("Password updated successfully");
      } else {
        message.error(res.error.message);
      }
    });
  };

  const updatepasswordLandlordUser = () => {
    if (!validateForm()) {
      setLoader(false);
      return;
    }

    const email = localStorage.getItem("email");

    updateLandLordUserPassword(oldPass, newPass, cnewPass, email).then((res) => {
      setLoader(false);
      if (res.apiCallStatus === "success") {
        setoldPass("");
        setnewPass("");
        setcnewPass("");
        message.success("Password updated successfully");
      } else {
        message.error(res.error.message);
      }
    });
  };

  const updatepasswordTenant = () => {
    if (!validateForm()) {
      setLoader(false);
      return;
    }

    updateTenantPassword(oldPass, newPass, cnewPass).then((res) => {
      setLoader(false);
      if (res.apiCallStatus === "success") {
        setoldPass("");
        setnewPass("");
        setcnewPass("");
        message.success("Password updated successfully");
      } else {
        message.error(res.error.message);
      }
    });
  };

  return (
    <>
      <p className="heading pt-4 pb-5 ps-4">LOGIN & PASSWORD</p>
      <div className="container px-4">
        <p
          className="mb-0"
          style={{
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: "Montserrat",
          }}
        >
          Change Password
        </p>
        <p className="normal-grey-text">
          Lorem ipsum dolor sit amet consectetur.
        </p>
        <div className="row mt-3">
          <div className="col-md-12">
            <PasswordField
              label="Current Password"
              placeholder={"Current Password"}
              value={oldPass}
              required={true}
              handler={(e) => setoldPass(e.target.value)}
              errorMessage={errors.oldPass}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <PasswordField
              label="New Password"
              placeholder={"New Password"}
              value={newPass}
              required={true}
              handler={(e) => setnewPass(e.target.value)}
              errorMessage={errors.newPass}
            />
          </div>
          <div className="col-md-6">
            <PasswordField
              label="Confirm Password"
              placeholder={"Confirm Password"}
              value={cnewPass}
              required={true}
              handler={(e) => setcnewPass(e.target.value)}
              errorMessage={errors.cnewPass}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12 mt-3">
            <PasswordValidate validate={checkPasswordCriteria(newPass)} />
          </div>
        </div>
        <div className="row my-4">
          <div className="col-md-6 w-100">
            <SubmitButton
              handler={() => {
                setLoader(true);
                role === "tenant"
                  ? updatepasswordTenant()
                  : role === "user"
                  ? updatepasswordLandlordUser()
                  : updatepasswordLandlord();
              }}
              isLoading={loader}
            >
              Save
            </SubmitButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsLoginInfo;
