import { useEffect, useState } from "react";
import { message } from "antd";
import ProfileUploader from "Components/ProfileUploader/ProfileUploader";
import { getProfileImageValue, usaStates } from "utils";
import {
  updateProfileTenant,
  updateProfileLandlord,
  updateProfileLandlordUser,
  fetchUser,
  fetchLandlordUser,
  fetchUserTenant,
} from "network/landlord/setting";
import {
  FixedModal,
  InputField,
  PhoneNumberField,
  SelectField,
  SubmitButton,
} from "Components/GeneralComponents";
import { validateLandlordUserInfo } from "utils/validations";
import "style/settings.css";
import Loader from "Helpers/Loader";
import config from "Helpers/config";

const SettingsPersonalInfo = () => {
  // States start
  const role = localStorage.getItem("role");
  const initialEmail = localStorage.getItem("email");
  const [isLogout, setIsLogout] = useState(false);
  const [ProfileImage, setProfileImage] = useState("");
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRerender, setIsRerender] = useState(false);
  const [form, setForm] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    phoneType: "",
  });

  const handleChange = (fieldName, value) => {
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  // States end
  const handleZipCodeChange = (e) => {
    if (/^\d{0,5}$/.test(e.target.value)) {
      handleChange("zipcode", e.target.value);
    }
  };

  const selectPhoneType = [
    { label: "Mobile", value: "Mobile" },
    { label: "Home", value: "Home" },
    { label: "Office", value: "Office" },
  ];

  useEffect(() => {
    setLoader(true);
    if (localStorage.getItem("role") === "tenant") {
      fetchUserTenant().then((res) => {
        if (res.apiCallStatus === "success") {
          setLoader(false);
          console.log("Tenant Data", res.message);
          setForm({
            firstName: res.message?.firstName || "",
            middleName: res.message?.middleName || "",
            lastName: res.message?.lastName || "",
            phone: res.message?.phone || "",
            email: res.message?.email || "",
            address: res.message?.addressLine_1 || "",
            city: res.message?.city || "",
            state: res.message?.state || "",
            zipcode: res.message?.zipcode || "",
            phoneType: res.message?.phoneType || "",
          });
          // setProfileImage(
          //   res.message?.profileImage?.substring(
          //     res.message?.profileImage?.lastIndexOf("/") + 1
          //   )
          // );
          setProfileImage(res.message?.profileImage);
          // handleInputChange({ target: { value: res.message?.phone } });
        }
        // console.log("Tenant Data >>>", form);
      });
    } else if (localStorage.getItem("role") === "user") {
      fetchLandlordUser().then((res) => {
        if (res.apiCallStatus === "success") {
          setLoader(false);
          // console.log("Landlord Data", res.message);
          setForm({
            firstName: res.message?.firstName || "",
            middleName: res.message?.middleName || "",
            lastName: res.message?.lastName || "",
            phone: res.message?.phone || "",
            email: res.message?.email || "",
            address: res.message?.address?.address_line_1 || "",
            city: res.message?.address?.city?.name || "",
            state: res.message?.address?.state?.name || "",
            zipcode: res.message?.zipcode?.number.toString() || "",
            phoneType: res.message?.phoneType || "",
          });
          // setProfileImage(
          //   res.message?.profileImage?.substring(
          //     res.message?.profileImage?.lastIndexOf("/") + 1
          //   )
          // );
          setProfileImage(res.message?.profileImage);
          // handleInputChange({ target: { value: res.message?.phone } });
        }
        // console.log("Landlord Data >>>", form);
      });
    } else {
      fetchUser().then((res) => {
        if (res.apiCallStatus === "success") {
          setLoader(false);
          // console.log("Landlord Data", res.message);
          setForm({
            firstName: res.message?.firstName || "",
            middleName: res.message?.middleName || "",
            lastName: res.message?.lastName || "",
            phone: res.message?.phone || "",
            email: res.message?.email || "",
            address: res.message?.address || "",
            city: res.message?.city?.name || "",
            state: res.message?.state?.name || "",
            zipcode: res.message?.zipcode?.number.toString() || "",
            phoneType: res.message?.phoneType || "",
          });
          // setProfileImage(res.message?.profileImage?.substring(
          //   res.message?.profileImage?.lastIndexOf("/") + 1
          // ));
          setProfileImage(res.message?.profileImage);
          // handleInputChange({ target: { value: res.message?.phone } });
        }
        // console.log("Landlord Data >>>", form);
      });
    }
  }, [isRerender]);

  const Logout = () => {
    fetch(`${config["baseUrl"]}/api/auth/logout`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.apiCallStatus === "success") {
          localStorage.clear();
          // window.location = "/";
        } else {
          console.log(res);
        }
      })
      .catch((err) => console.log(err, "error"));
  };

  const UpdateProfileLandlord = () => {
    // Update Personal info
    var formdata = new FormData();
    formdata.append("firstName", form.firstName);
    formdata.append("middleName", form.middleName);
    formdata.append("lastName", form.lastName);
    formdata.append("email", form.email);
    formdata.append("phone", form.phone);
    formdata.append("phoneType", form.phoneType);
    formdata.append("address", form.address);
    formdata.append("state", form.state);
    formdata.append("country", "USA");
    formdata.append("zipcode", form.zipcode);
    formdata.append("city", form.city);
    formdata.append("profileImage", getProfileImageValue(ProfileImage));

    const newErrors = validateLandlordUserInfo(form);
    setErrors(newErrors);
    console.log("New Errors", newErrors);
    if (Object.keys(newErrors).length === 0) {
      setIsLoading(true);
      updateProfileLandlord(formdata).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          message.success("Profile updated successfully");
          // window.location.reload(true);
          // localStorage.setItem("email", form.email);
          localStorage.setItem("name", form.firstName + " " + form.lastName);
          setIsRerender(true);
 
          if (form.email !== initialEmail) {
            Logout();
            setIsLogout(true);
          }
        } else {
          message.error(res.error.message);
        }
      });
    }
  };

  const UpdateProfileTenant = () => {
    // Update Personal info
    var formdata = new FormData();
    formdata.append("firstName", form.firstName);
    formdata.append("middleName", form.middleName);
    formdata.append("lastName", form.lastName);
    formdata.append("email", form.email);
    formdata.append("phone", form.phone);
    formdata.append("phoneType", form.phoneType);
    formdata.append("address", form.address);
    formdata.append("state", form.state);
    formdata.append("country", "USA");
    formdata.append("zipcode", form.zipcode);
    formdata.append("city", form.city);
    formdata.append("profileImage", getProfileImageValue(ProfileImage));

    const newErrors = validateLandlordUserInfo(form);
    setErrors(newErrors);
    console.log("New Errors", newErrors);
    if (Object.keys(newErrors).length === 0) {
      setIsLoading(true);
      updateProfileTenant(formdata).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          message.success("Profile updated successfully");
          // window.location.reload(true);
          // localStorage.setItem("email", form.email);
          localStorage.setItem("name", form.firstName + " " + form.lastName);
          setIsRerender(true);

          if (form.email !== initialEmail) {
            Logout();
            setIsLogout(true);
          }
        } else {
          message.error(res.error.message);
        }
      });
    }
  };

  const UpdateProfileLandlordUser = () => {
    // Update Personal info
    var formdata = new FormData();
    formdata.append("firstName", form.firstName);
    formdata.append("middleName", form.middleName);
    formdata.append("lastName", form.lastName);
    formdata.append("email", form.email);
    formdata.append("phone", form.phone);
    formdata.append("phoneType", form.phoneType);
    formdata.append("address[address_line_1]", form.address);
    formdata.append("address[city]", form.city);
    formdata.append("address[state]", form.state);
    formdata.append("address[country]", "USA");
    formdata.append("profileImage", getProfileImageValue(ProfileImage));

    const newErrors = validateLandlordUserInfo(form);
    setErrors(newErrors);
    console.log("New Errors", newErrors);
    if (Object.keys(newErrors).length === 0) {
      setIsLoading(true);
      updateProfileLandlordUser(formdata, localStorage.getItem("user_id")).then(
        (res) => {
          setIsLoading(false);
          if (res.apiCallStatus === "success") {
            message.success("Profile updated successfully");
            // window.location.reload(true);
            // localStorage.setItem("email", form.email);
            localStorage.setItem("name", form.firstName + " " + form.lastName);
            setIsRerender(true);

            if (form.email !== initialEmail) {
              Logout();
              setIsLogout(true);
            }
          } else {
            message.error(res.error.message);
          }
        }
      );
    }
  };

  // const values=options
  return loader ? (
    <>
      <p className="heading pt-4 pb-5 ps-4">Personal info</p>
      <Loader />
    </>
  ) : (
    <>
      <FixedModal
        isOpen={isLogout}
        handler={() => {
          window.location = "/";
        }}
        message="Your email address has been updated. For security reasons, please log in again to continue."
        btnName="Login Again"
        isLoading={false}
      />
      <p className="heading pt-4 pb-5 ps-4">Personal info</p>
      {/* <div className="container"> */}
      <div className="setting-info-container">
        <div className="container px-4">
          <p
            className="mb-0"
            style={{
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: "Montserrat",
            }}
          >
            Your Photo
            <span className="sign-up-imp-star" style={{ fontSize: "16px" }}>
              {" "}
              *
            </span>
          </p>
          <p className="normal-grey-text">
            This will be displayed on your profile.
          </p>
          <div className="photo-container d-flex align-items-start  gap-4">
            <div className="dragger">
              <ProfileUploader
                setProfileImages={setProfileImage}
                ProfileImages={ProfileImage}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 mt-3">
              <InputField
                handler={(e) => handleChange("firstName", e.target.value)}
                value={form.firstName}
                type="text"
                name={"firstName"}
                placeholder={"First Name"}
                errorMessage={errors.firstName}
                label="First Name"
                required={true}
              />
            </div>
            <div className="col-md-4 mt-3">
              <InputField
                handler={(e) => handleChange("middleName", e.target.value)}
                value={form.middleName}
                type="text"
                name={"middleName"}
                placeholder={"Middle Name"}
                errorMessage={errors.middleName}
                label="Middle Name"
                required={false}
              />
            </div>
            <div className="col-md-4 mt-3">
              <InputField
                handler={(e) => handleChange("lastName", e.target.value)}
                value={form.lastName}
                type="text"
                name={"lastName"}
                placeholder={"Last Name"}
                errorMessage={errors.lastName}
                label="Last Name"
                required={true}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <InputField
                handler={(e) => handleChange("email", e.target.value)}
                value={form.email}
                type="email"
                name={"email"}
                placeholder={"Email"}
                errorMessage={errors.email}
                label="Email"
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mt-3">
              <PhoneNumberField
                label="Phone"
                placeholder="Phone"
                value={form.phone}
                handler={(e) => {
                  handleChange("phone", e);
                }}
                required={true}
                errorMessage={errors.phone}
              />
            </div>
            <div className="col-md-6 mt-3">
              <SelectField
                handler={(e) => handleChange("phoneType", e)}
                value={form.phoneType}
                placeholder={"Select Phone Type"}
                options={selectPhoneType}
                label="Phone Type"
                required={true}
                errorMessage={errors.phoneType}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mt-3">
              <InputField
                handler={(e) => handleChange("address", e.target.value)}
                value={form.address}
                type="text"
                name={"address"}
                placeholder={"Address"}
                errorMessage={errors.address}
                label="Address"
                required={true}
              />
            </div>
            <div className="col-md-6 mt-3">
              <InputField
                handler={(e) => handleChange("city", e.target.value)}
                value={form.city}
                type="text"
                name={"city"}
                placeholder={"City"}
                errorMessage={errors.city}
                label="City"
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mt-3">
              <SelectField
                handler={(e) => handleChange("state", e)}
                value={form.state}
                placeholder={"Select State"}
                options={usaStates.map((e) => {
                  return { value: e.name, label: e.name };
                })}
                label="State"
                required={true}
                errorMessage={errors.state}
              />
            </div>
            {localStorage.getItem("role") !== "user" && (
              <div className="col-md-6 mt-3">
                <InputField
                  handler={handleZipCodeChange}
                  value={form.zipcode}
                  type="text"
                  name={"zipcode"}
                  placeholder={"Zip Code"}
                  errorMessage={errors.zipcode}
                  label="Zip Code"
                  required={true}
                />
              </div>
            )}
          </div>
          <div className="setting-btn my-4 d-flex align-items-center gap-4">
            <SubmitButton
              handler={() => {
                role === "tenant"
                  ? UpdateProfileTenant()
                  : role === "user"
                  ? UpdateProfileLandlordUser()
                  : UpdateProfileLandlord();
              }}
              isLoading={isLoading}
            >
              Save
            </SubmitButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsPersonalInfo;
