import React from "react";

const SpinnerLoader = () => {
  return (
    <div classNameName="d-flex justify-content-center align-items-center">
      <span
        className="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  );
};

export default SpinnerLoader;
