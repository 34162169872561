import React, { useState } from "react";
import { message } from "antd";
import * as Component from "Components/GeneralComponents";
import { addpaymnetformvalidation } from "utils/validations";
import { formatDate } from "utils";
import * as API from "network/landlord/Account";

const FormAddPayment = ({ onClose, setUpdate }) => {
  const [errors, setErrors] = useState({});
  const [isloading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    name: "",
    cardnumber: "",
    expiry: "",
    cvv: "",
  });

  const handleChange = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const handleSubmit = () => {
    const newErrors = addpaymnetformvalidation(form);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const parsed = form.expiry.split("/");
      const month = parsed[0];
      const year = parsed[2];
      const trimCard = form.cardnumber.split("/").join("");
      const urlencoded = new URLSearchParams();
      urlencoded.append("card[number]", trimCard);
      urlencoded.append("card[exp_month]", parseInt(month));
      urlencoded.append("card[exp_year]", parseInt(year));
      urlencoded.append("card[cvc]", form.cvv);
      // urlencoded.forEach((value, key) => {
      //     console.log(key, value);
      //   });
      API.addPaymentMethod(urlencoded).then((res) => {
        setIsLoading(true);
        if (res) {
          API.addpostpaymnent(res?.id).then((res) => {
            if (res?.apiCallStatus === "success") {
              setIsLoading(false);
              message.success("Payment method added successfully");
              onClose();
              setUpdate(true);
            } else {
              message.error("Error in adding payment method");
              setIsLoading(false);
            }
          });
        } else {
          message.error("Error in adding payment method");
          setIsLoading(false);
        }
      });
    }
  };

  const handleDateChange = (field, e) => {
    handleChange(field, new Date(e).toLocaleDateString());
  };
  const handleCreditCardChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    if (value.length > 0) {
      value = value.match(/.{1,4}/g).join("-"); // Add hyphens every four digits
    }
    handleChange("cardnumber", value);
  };

  return (
    <>
      <div className="p-41">
        <div className="row mt-3">
          <div className="col-md-12 text-start">
            <Component.InputField
              name={"name"}
              required={true}
              label={"Card Holder's Name"}
              placeholder={"Enter the name"}
              value={form.name}
              handler={(e) => handleChange("name", e.target.value)}
              errorMessage={errors.name}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <Component.InputField
              name={"cardnumber"}
              required={true}
              label={"Card Number"}
              placeholder={"Enter the card number"}
              value={form.cardnumber}
              handler={(e) => handleCreditCardChange(e)}
              errorMessage={errors.cardnumber}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <Component.DateField
              required={true}
              label={"Expiration Date"}
              placeholder={"Select the expiration date"}
              defaultValue={form.expiry && formatDate(form.expiry)}
              handler={(e) => handleDateChange("expiry", e)}
              errorMessage={errors.expiry}
            />
          </div>
          <div className="col-md-6">
            <Component.PasswordField
              name={"cvv"}
              required={true}
              label={"CVV"}
              placeholder={"Enter the cvv"}
              value={form.cvv}
              handler={(e) => handleChange("cvv", e.target.value)}
              errorMessage={errors.cvv}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="setting-btn my-4 d-flex align-items-center justify-content-end gap-4">
            <Component.SaveModalButton
              loader={isloading}
              addTask={handleSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FormAddPayment;
