import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import ResponsiveSetting from "Components/ResponsiveSetting/ResponsiveSetting";
import Setting from "Components/Setting/Setting";
import React from "react";

const SettingsPage = () => {
  return (
    <div className="d-flex align-items-stretch">
      {/* For large screens (lg and above) */}
      <div className="d-none d-lg-block flex-fill">
        <div className="right-component bg-white d-flex align-items-stretch">
          <Fixedtopbar topBarTitle="Setting" />
          <Setting />
        </div>
      </div>

      {/* For medium screens (md and below) */}
      <div className=" mt-3 d-lg-none flex-fill">
        <div className="right-component bg-white d-flex align-items-stretch">
          <Fixedtopbar topBarTitle="Setting" />
          <ResponsiveSetting />
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
