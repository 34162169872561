import React from "react";
// import Accounting from "Components/Accounting/Accounting";
import SideDrawer from "Components/SideDrawer/SideDrawer";
import ResponsiveSideBar from "Components/ResponsiveSideBar/ResponsiveSideBar";
import { useSelector } from "react-redux";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import CustomerQueries from "./CustomerQueries";
const ProspectDetailsPage = () => {
  const drawer = useSelector((state) => {
    return state.sideDrawer.drawerState;
  });
  return (
    <>
      <div className="container-fluid">
        <div className="row">
         
          <div className="col-12">
            <div className="right-component bg-white d-flex align-items-stretch">
              <Fixedtopbar
                topBarTitle="Customer Queries"
              />
              <CustomerQueries />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProspectDetailsPage;
