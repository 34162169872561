import React, { useState, useEffect, useRef } from "react";
import chevronIcon from "assets/chevron-down.png";
import "style/selectField.css";
import { SimpleCard } from ".";
import NotFound from "assets/not-found-img.png";

const SelectPropertyField = ({
  PropertyData,
  category,
  errorMessage = false,
  label,
  placeholder,
  selectedPropertyId,
  value,
  isRequired,
  onPropertySelect,
  disabled = false,
}) => {
  // console.log("PropertyData", PropertyData);
  const [propertySearch, setPropertySearch] = useState("");
  const [propertyDropdown, setPropertyDropdown] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState({
    id: "",
    heading1: "",
    heading2: "",
    heading3: "",
  });
  const [fieldValue, setFieldValue] = useState(value);
  const [fieldPlaceholder, setFieldPlaceholder] = useState(
    value ? value : placeholder
  );
  const [seletedItem, setSelectedItem] = useState(value);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (selectedPropertyId && PropertyData.length > 0) {
      const selected = PropertyData.filter(
        (e) => e.id === selectedPropertyId
      )[0];
      setSelectedProperty(selected);

      console.log("selected >><< ", selected);
      onPropertySelect(selected);

      if (!selected)
        return;

      if (category === "lease") {
        setSelectedItem(
          `${
            selected.heading2.charAt(0).toUpperCase() +
            selected.heading2.slice(1)
          } / ${
            selected.heading1.charAt(0).toUpperCase() +
            selected.heading1.slice(1)
          }`
        );
        setFieldValue(
          `${
            selected.heading2.charAt(0).toUpperCase() +
            selected.heading2.slice(1)
          } / ${
            selected.heading1.charAt(0).toUpperCase() +
            selected.heading1.slice(1)
          }`
        );
      } else if (category === "tenant") {
        setSelectedItem(
          `${
            selected.heading3.charAt(0).toUpperCase() +
            selected.heading3.slice(1)
          }`
        );
        setFieldValue(
          `${
            selected.heading3.charAt(0).toUpperCase() +
            selected.heading3.slice(1)
          }`
        );
      } else {
        setSelectedItem(
          `${
            selected.heading2.charAt(0).toUpperCase() +
            selected.heading2.slice(1)
          }`
        );
        setFieldValue(
          `${
            selected.heading2.charAt(0).toUpperCase() +
            selected.heading2.slice(1)
          }`
        );
      }
    }
  }, [selectedPropertyId, PropertyData]);

  const handlePropertySelect = (item) => {
    const selected = PropertyData.filter((e) => e.id === item.id)[0];
    setSelectedProperty(selected);
    if (category === "lease") {
      setSelectedItem(
        `${item.heading2.charAt(0).toUpperCase() + item.heading2.slice(1)} / ${
          item.heading1.charAt(0).toUpperCase() + item.heading1.slice(1)
        }`
      );
      setFieldValue(
        `${item.heading2.charAt(0).toUpperCase() + item.heading2.slice(1)} / ${
          item.heading1.charAt(0).toUpperCase() + item.heading1.slice(1)
        }`
      );
    } else if (category === "tenant") {
      setSelectedItem(
        `${item.heading3.charAt(0).toUpperCase() + item.heading3.slice(1)}`
      );
      setFieldValue(
        `${item.heading3.charAt(0).toUpperCase() + item.heading3.slice(1)}`
      );
    } else {
      setSelectedItem(
        `${item.heading2.charAt(0).toUpperCase() + item.heading2.slice(1)}`
      );
      setFieldValue(
        `${item.heading2.charAt(0).toUpperCase() + item.heading2.slice(1)}`
      );
    }
    setPropertyDropdown(false);
    setPropertySearch("");
    onPropertySelect(selected);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setPropertyDropdown(false);
        propertyDropdown && setFieldValue(fieldPlaceholder);
        setPropertySearch("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="row mt-3">
      <div className="col-md-12">
        <span className="tenant-personal-details-title">
          {label}{" "}
          <span className="sign-up-imp-star">{`${isRequired ? "*" : ""}`}</span>
        </span>
        <div className="position-relative selectField" ref={dropdownRef}>
          <input
            value={fieldValue}
            placeholder={fieldPlaceholder}
            onKeyUp={() => setPropertyDropdown(true)}
            onFocus={() => {
              setPropertyDropdown(true);
              seletedItem
                ? setFieldPlaceholder(seletedItem)
                : value
                ? setFieldPlaceholder(value)
                : setFieldPlaceholder(placeholder);
              setFieldValue("");
            }}
            onChange={(e) => {
              setPropertySearch(e.target.value);
              setFieldValue(e.target.value);
            }}
            className={`${errorMessage ? "error-input" : "form-control"}`}
            type="text"
            disabled={disabled}
          />
          {errorMessage && (
            <span
              className="text-danger fw-bold mt-2"
              style={{ fontSize: "12px" }}
            >
              {errorMessage}
            </span>
          )}
          <span
            onClick={() => {
              if (disabled) {
              } else {
                setPropertyDropdown(!propertyDropdown);
                if (propertyDropdown) {
                  setFieldPlaceholder("");
                  setFieldValue(seletedItem);
                } else {
                  setFieldPlaceholder(seletedItem);
                  setFieldValue("");
                }
              }
            }}
            className="multi-chevron cursor d-flex justify-content-center align-items-center"
          >
            <img src={chevronIcon} alt="chevron icon" />
          </span>
          {propertyDropdown && (
            <div className="my-dropdown p-2">
              <div>
                {PropertyData.length > 0 ? (
                  PropertyData.filter(
                    (e) =>
                      e.heading1
                        .toLowerCase()
                        .includes(propertySearch?.toLowerCase()) ||
                      e.heading2
                        .toLowerCase()
                        .includes(propertySearch?.toLowerCase()) ||
                      e.heading3
                        .toLowerCase()
                        .includes(propertySearch?.toLowerCase())
                  ).map((item, index) => (
                    <div
                      key={item.id}
                      className={`${index === 0 ? "" : "mt-2"}`}
                      style={{ cursor: "pointer" }}
                      onClick={() => handlePropertySelect(item)}
                    >
                      <SimpleCard
                        line1={
                          item.heading1.charAt(0).toUpperCase() +
                          item.heading1.slice(1)
                        }
                        line2={
                          item.heading2.charAt(0).toUpperCase() +
                          item.heading2.slice(1)
                        }
                        line3={
                          item.heading3.charAt(0).toUpperCase() +
                          item.heading3.slice(1)
                        }
                        cardColor={
                          selectedProperty?.id === item?.id ? "#ef6b3e29" : ""
                        }
                      />
                    </div>
                  ))
                ) : (
                  <div className="d-flex align-items-center justify-content-center">
                    <div>
                      <div className="not-found-img">
                        <img src={NotFound} alt="Not Found" width={80} />
                      </div>
                      <div className="not-found-text mt-2 text-center">
                        No Data
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectPropertyField;

