import React from "react";
import AddUnitDetails from "Components/AddUnitDetails/AddUnitDetails";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";

const AddUnitDetailsPage = () => {
  const { id } = UseUrlParamsHook();
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Add Unit Details"}
        back={`property-details-view?id=${id}&tab=2`}
      />
      <AddUnitDetails />
    </div>
  );
};

export default AddUnitDetailsPage;
