import React, { useEffect, useState } from "react";
import { message } from "antd";
import TenantPassportPaymentMethodModal from "Modals/TenantPassportPaymentMethodModal/TenantPassportPaymentMethodModal";
import TenantPassportInvitationSentModal from "Modals/TenantPassportInvitationSentModal/TenantPassportInvitationSentModal";
import UseGetHook from "Hooks/UseGetHook";
import config from "Helpers/config.js";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import { useNavigate } from "react-router-dom";
import * as InnerComponents from "./index";
import { fetchUserTenant } from "network/tenant/passport";
import Checkout from "Components/Payment/Checkout";
import axios from "axios";
import PassportCheckout from "./PassportCheckout";
import IdentityVerificaton from "./IdentityVerification";
import IncomeVerification from "./IncomeVerification";
import { employmentStatus } from "utils/dropdownData";
import Info from "Components/GeneralComponents/Modals/Info";
import TenantQuestions from "Components/TenantQuestions/TenantQuestions";
import Warning from "Components/GeneralComponents/Modals/Warning";
import { refundPayment } from "network/tenant/passport";
import Loader from "Helpers/Loader";
import QuestionHandlerComponent from "./QuestionsStepper";
import ScreeningQuestions from "./StandardQuestions";
import { Stepper } from "Components/GeneralComponents";
import { updateStepInDatabase, getPassportDetails } from "network/tenant/passport";
import { addTeneantPassportProfileDetails } from "utils/stepsData";

const TenantPassportProfile = () => {
  // States start.
  const [openModal, setOpenModal] = useState(false);
  const [openModalInvitation, setOpenModalInvitation] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [tempCurrentStep, setTempCurrentStep] = useState(null);
  const [DeletedImages, setDeletedImages] = useState([]);
  const [Images, setImages] = useState([]);
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [disabledFields, setDisabledFields] = useState([]);
  const [options, setOptions] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [fetchError, setFetchError] = useState(null);
  const [back, setBack] = useState(null);
  const [loading, setLoading] = useState(false);
  const [TenantQuestionsReady, setTenantQuestionsReady] = useState(null);
  const [screeningId, setScreeningId] = useState(null);
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
  const navigate = useNavigate();

  const [form, setForm] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    dob: "",
    ssn: "",
    gender: "",
    maritalStatus: "",
    moveInDate: "",
    itin: "",
    address: "",
    country: "USA",
    state: "",
    city: "",
    zipCode: "",
    income: "",
    employmentStatus: "",
    incomeFrequency: "",
    otherIncome: "",
    otherIncomeFrequency: "",
    assets: 1200,
  });

  const [employerVerificationForm, setEmployerVerificationForm] = useState([
    {
      jobTitle: "",
      companyName: "",
      // annualIncome: "",
      // employmentStatus: "",
      startDate: "",
      endDate: "",
      currentEmployer: false,
      supervisorName: "",
      supervisorEmail: "",
      supervisorPhone: "",
    },
  ]);

  const addWorkHistory = () => {
    setEmployerVerificationForm([
      ...employerVerificationForm,
      {
        jobTitle: "",
        companyName: "",
        annualIncome: "",
        employmentStatus: "",
        startDate: "",
        currentEmployer: false,
        endDate: "",
        supervisorName: "",
        supervisorEmail: "",
        supervisorPhone: "",
      },
    ]);
  };

  const removeWorkHistory = (index) => {
    setEmployerVerificationForm(
      employerVerificationForm.filter((_, i) => i !== index)
    );
  };

  const [rentalHistoryForm, setRentalHistoryForm] = useState([
    {
      residenceType: "",
      streetAddress: "",
      aptOrUnit: "",
      state: "",
      zipCode: "",
      country: "USA",
      moveInDate: "",
      moveOutDate: "",
      referenceType: "",
      fullName: "",
      monthlyRentAmount: "",
      email: "",
      phone: "",
      images: [],
    },
  ]);

  const handleRentalHistoryChange = (field, index, value) => {
    const newRentalHistory = [...rentalHistoryForm];
    newRentalHistory[index][field] = value;
    setRentalHistoryForm(newRentalHistory);
  };

  const addRentalHistory = () => {
    setRentalHistoryForm([
      ...rentalHistoryForm,
      {
        residenceType: "",
        streetAddress: "",
        aptOrUnit: "",
        state: "",
        zipCode: "",
        country: "USA",
        moveInDate: "",
        moveOutDate: "",
        referenceType: "",
        fullName: "",
        monthlyRentAmount: "",
        email: "",
        phone: "",
        images: [],
      },
    ]);
  };
// useEffect(()=>{
//   getPassportDetails().then((res)=>{
//     console.log(res)
//     // setForm({
//     //   firstName: res?.message?.data?.personalInfo?.firstName,
//     //   middleName: res?.message?.data?.personalInfo?.middleName,
//     //   lastName: res?.message?.data?.personalInfo?.lastName,
//     //   email: res?.message?.data?.personalInfo?.email,
//     //   phone: res?.message?.data?.personalInfo?.phone,
//     //   dob: res?.message?.data?.personalInfo?.dob,
//     //   gender: res?.message?.data?.personalInfo?.gender || "",
//     //   maritalStatus: res?.message?.data?.personalInfo?.maritalStatus || "",
//     //   moveInDate: res?.message?.data?.personalInfo?.moveInDate || "",
//     //   itin: res?.message?.data?.personalInfo?.itin || "",
//     //   address: res?.message?.data?.personalInfo?.address?.addressLine_1 || "",
//     //   country: "USA",
//     //   state:  res?.message?.data?.personalInfo?.address?.state,
//     //   city:  res?.message?.data?.personalInfo?.address?.city,
//     //   zipCode:  res?.message?.data?.personalInfo?.address?.zipcode,
//     //   income:  res?.message?.data?.personalInfo?.income,
//     //   employmentStatus: res?.message?.data?.personalInfo?.employmentStatus,
//     //   incomeFrequency: res?.message?.data?.personalInfo?.incomeFrequenncy,
//     //   otherIncome: res?.message?.data?.personalInfo?.otherIncome,
//     //   otherIncomeFrequency:  res?.message?.data?.personalInfo?.otherIncomeFrequency,
//     //   assets: 1200,
//     // })
//     // setEmployerVerificationForm(res?.message?.data?.employmentInformation)
//     // setRentalHistoryForm(res?.message?.data?.rentalHistory)
//     // setCurrentStep(res?.message?.data?.completedSteps || 1)
//     setIsPaymentCompleted(true)
//   }).catch((error)=>{
//     console.log(error)
//   });
// },[])
  const removeRentalHistory = (index) => {
    setRentalHistoryForm(rentalHistoryForm.filter((_, i) => i !== index));
  };

  const handleEmployerChange = (field, index, value) => {
    const newEmployerInfo = [...employerVerificationForm];
    newEmployerInfo[index][field] = value;
    setEmployerVerificationForm(newEmployerInfo);
  };

  const [incomeForm, setIncomeForm] = useState({
    yearlyIncome: "",
    details: "",
  });

  const handleIncomeChange = (fieldName, value) => {
    setIncomeForm({
      ...incomeForm,
      [fieldName]: value,
    });
  };

  const [screeningQuestions, setScreeningQuestions] = useState({
    pets: 0,
    people: 0,
    individuals: 0,
    individualsInformation: [
      {
        fullName: "",
        phone: "",
      }
    ]
  });


  const handleScreeningQuestionsChange = (fieldName, value) => {
    setScreeningQuestions({
      ...screeningQuestions,
      [fieldName]: value,
    });
  };

  const { email, id } = UseUrlParamsHook();

  const handleProfileChange = (fieldName, value) => {
    console.log(fieldName, value);
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  const { user, FetchUserTenant } = UseGetHook("userinfo");
  // States end
  useEffect(() => {
    FetchUserTenant();
  }, []);

  useEffect(() => {
    setLoader(true);
    fetchUserTenant().then((res) => {
      setLoader(false);
      if (res.apiCallStatus === "success") {
        console.log("User >>> ", res);
        setForm({
          ...form,
          firstName: res.message.firstName || "",
          middleName: res.message.middleName || "",
          lastName: res.message.lastName || "",
          email: res.message.email || "",
          phone: res.message.phone || "",
          dob: res.message.dob || "",
          address: res.message.addressLine_1 || "",
          city: res.message.city || "",
          state: res.message.state || "",
          zipCode: res.message.zipcode || "",
        });
        // set those fields to disabled which are already filled
        const fieldsToDisable = [];

        if (res.message.firstName) {
          fieldsToDisable.push("firstName");
        }
        if (res.message.middleName) {
          fieldsToDisable.push("middleName");
        }
        if (res.message.lastName) {
          fieldsToDisable.push("lastName");
        }
        if (res.message.email) {
          fieldsToDisable.push("email");
        }
        if (res.message.phone) {
          fieldsToDisable.push("phone");
        }
        if (res.message.dob) {
          fieldsToDisable.push("dob");
        }
        if (res.message.addressLine_1) {
          fieldsToDisable.push("address");
        }
        if (res.message.city) {
          fieldsToDisable.push("city");
        }
        if (res.message.state) {
          fieldsToDisable.push("state");
        }
        if (res.message.zipcode) {
          fieldsToDisable.push("zipCode");
        }

        setDisabledFields(fieldsToDisable);

        setProfileImage(res.message.profileImage);
      }
    });
  }, []);
  // States end

  const url = "/api/system/transunion/verify";

  // Modal Function
  const onOpenModal = () => {
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  // Modal Function
  const onOpenModalInvitation = () => {
    setOpenModalInvitation(true);
  };
  const onCloseModalInvitation = () => {
    setOpenModalInvitation(false);
  };

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const response = await axios.get(
          `${config.baseUrl}/api/stripe/payForPassportReport`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const data = response.data;
        console.log(data);
        if (data?.message?.data?.client_secret) {
          const clientSecret = data.message.data.client_secret;
          setOptions({ clientSecret });
          setClientSecret(clientSecret);
        } else {
          throw new Error("Client secret not found");
        }
      } catch (error) {
        console.error(
          "Error fetching client secret:",
          error.response?.data || error.message
        );
      }
    };

    if (currentStep === 1) {
      fetchClientSecret();
    }
  }, []);

  const handlePaymentAuthorization = () => {
    handleNext();
  };

  // States
  // Helper function to format error messages
  const formatErrorMessage = (key) => {
    if (key === "itin" || key === "ssn") {
      return `${key.toUpperCase()} is required`;
    } else if (key === "gender") {
      return;
    }
    // Capitalize the first letter and add spaces before capital letters
    return (
      key.charAt(0).toUpperCase() +
      key.slice(1).replace(/([A-Z])/g, " $1") +
      " is required"
    );
  };
  const handleNext = async () => {
    setLoading(true);
    if (currentStep === 1) {
      const newErrors = {};

      console.log(form)
      // Validate all fields in the form
      for (const key in form) {
        console.log(key, form[key]);
        if (key === "gender" || key==="middleName") continue;

        if (form[key] === "") {
          newErrors[key] = formatErrorMessage(key);
          setLoading(false);
        } else if (key === "dob") {
          const dob = new Date(form[key]);
          const now = new Date();
          const ageInYears = now.getFullYear() - dob.getFullYear();

          // Check if the user is less than 18 years old
          if (
            ageInYears < 18 ||
            (ageInYears === 18 && now.getMonth() < dob.getMonth()) ||
            (ageInYears === 18 &&
              now.getMonth() === dob.getMonth() &&
              now.getDate() < dob.getDate())
          ) {
            newErrors[key] = "Age should be at least 18 years";
          }
        }
      }

      setErrors(newErrors); // Set validation errors
      console.log("newErrors:", JSON.stringify(newErrors));

      // Prevent moving to the next step if there are validation errors
      if (Object.keys(newErrors).length > 0) {
        console.log("Returning due to validation errors:", newErrors);
        setLoading(false);
        return;
      }
    }


    // // Call API based on the step
    try {
      if (currentStep === 1) {
        await updateStepInDatabase(currentStep, form);
       
      } else if (currentStep === 5) {
        const newErrors = {};
      console.log("employerVerification form", employerVerificationForm)
      for (const entry of employerVerificationForm) { // use 'for...of' to get each object in the array
        for (const key in entry) { // use 'for...in' to iterate over each key within an object
          console.log("entry", entry);
          if (entry[key] === "") {
            console.log("key", key);
            
            if((key=="endDate" && entry.currentEmployer) || key=="employmentStatus" || key=="annualIncome"){
              continue;
            }
           
           
            newErrors[key] = formatErrorMessage(key);
            setLoading(false);
          }
        }
      }
      
      console.log("newErrors", newErrors)
       setErrors(newErrors); // Set validation errors
        if(Object.keys(newErrors).length > 0){
          setLoading(false);
          return ;
        }
        await updateStepInDatabase(currentStep, employerVerificationForm);
      }else if(currentStep === 6) {
        const newErrors = {};

        for (const entry of rentalHistoryForm) { // use 'for...of' to get each object in the array
          for (const key in entry) { // use 'for...in' to iterate over each key within an object
            console.log("entry", entry);
            if (entry[key] === "") {
             
              newErrors[key] = formatErrorMessage(key);
              setLoading(false);
            }
          }
        }
        console.log("rental Errors: ",newErrors)

         setErrors(newErrors); // Set validation errors
          if(Object.keys(newErrors).length > 0){
            setLoading(false);
            return ;
          }

        let updatedRentalHistoryForm = {
          ...rentalHistoryForm,
          images: Images
        }
        await updateStepInDatabase(currentStep, updatedRentalHistoryForm);
      }else if(currentStep === 7){
        const newErrors = {};
        console.log("employerVerification form", incomeForm)
       
          for (const key in incomeForm) { // use 'for...in' to iterate over each key within an object
            if (incomeForm[key] === "") {
             
              newErrors[key] = formatErrorMessage(key);
              setLoading(false);
            }
          }
        
        console.log("rental Errors: ",newErrors)
         setErrors(newErrors); // Set validation errors
          if(Object.keys(newErrors).length > 0){
            setLoading(false);
            return ;
          }
        let updatedIncomeforms = {
          yearlyIncome: incomeForm.yearlyIncome.amount,
          details: incomeForm.details,
          images: Images
        }
        await updateStepInDatabase(currentStep, updatedIncomeforms);
      }
    } catch (error) {
      console.error("API Error:", error);
      setLoading(false);
      return; // Prevent moving to the next step if there's an API error
    }

    // Move to the next step if there are no errors
    if (tempCurrentStep) {
      setCurrentStep(tempCurrentStep);
      setTempCurrentStep(null);
    } else {
    
        setCurrentStep((prevStep) => (prevStep === 9 ? 1 : prevStep + 1));
      
      
    }
    setLoading(false);
  };


  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const CreateScreeningRequest = () => {
    console.log("Creating screening");
    setLoading(true);
    const newErrors = {};

    for (const key in form) {
      if (form[key] === "" && key != "gender" && key != "middleName") {
        newErrors[key] = `${key} is required`;
        
      }
    }
    setErrors(newErrors);
    console.log("newErrors: " + JSON.stringify(newErrors));
    if (Object.keys(newErrors).length === 0) {
      fetch(`${config.baseUrl}${url}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          firstName: form.firstName,
          middleName: form.middleName,
          lastName: form.lastName,
          email: form.email,
          phone: form.phone,
          dob: form.dob,
          ssn: form.ssn,
          gender: form.gender,
          maritalStatus: form.maritalStatus,
          moveInDate: form.moveInDate,
          itin: form.itin,
          address: form.address,
          country: form.country,
          state: form.state,
          city: form.city,
          zipCode: form.zipCode,
          income: form.income,
          assets: form.assets,
          incomeFrequency: form.incomeFrequency,
          otherIncome: form.otherIncome,
          employmentStatus: form.employmentStatus,
          otherIncomeFrequency: form.otherIncomeFrequency,
          driverLicense: form.driverLicense,
          licenseNumber: form.licenseNumber,
          nationalId: "123456789", // National ID and must be 9 characters long.
        }),
      })
        .then((res) => {
          if (res.ok) {
            setLoading(false);
            return res.json(); // Parse response if status is ok (200-299)
          } else if (res.status === 409) {
            setLoading(false);
            setFetchError(
              "You already have one Screening Request, Cannot create another."
            );
            refundPayment();
          } else if (res.status === 500) {
            refundPayment();
            setLoading(false);
            return res.json().then((errorData) => {
              // message.error(errorData.message || "Internal Server Error");
              setLoading(false);
            });
          } else {
            refundPayment();
            setLoading(false);

            return res.json().then((errorData) => {
              // Handle other errors with specific messages
              throw new Error(
                errorData.message || "An unknown error occurred."
              );
            });
          }
        })
        .then((res) => {
          console.log("res: ", res);
          if (res?.ok === "success" || res?.apiCallStatus === "success") {
            if (
              res.message.message ===
              "You already have one Screening Request, Cannot create another"
            ) {
              console.log("Already have a Screening Request 1");
              message.error(res.message.message);
              setFetchError(
                res.message.message ||
                  "You already have one Screening Request, Cannot create another"
              );
              setLoader(false);
            } else {
              console.log("Created a new Screening Request");

              setLoader(false);
              setScreeningId(id || res.message.screeningRequests_._id);
              console.log(
                "=================================Tenant Passport Profile==================================="
              );
              console.log("ID", res.message.screeningRequests_._id);
              // console.log("SSN",ssn)
              // handleNext()
              // navigate(`/tenant-questions?id=${id || res.message.screeningRequests_._id}&ssn=123456789`);
            }
            setLoading(false)
          } else {
            // message.error(res.error.message || "An error occurred during processing.");
            setFetchError(
              "You already have one Screening Request, Cannot create another"
            );

            setLoading(false)
          }
        })
        .catch((err) => {
          // console.error("Fetch error:", err?.message || "An error occurred during processing.");
          refundPayment();

          setFetchError(
            err?.message || err || "An error occurred during processing."
          );
          setLoading(false)
        });
    }else{
      setLoading(false)
      setCurrentStep(8)
    }
  };
  const handleFetchError = () => {
    setFetchError(null);
    setTempCurrentStep(currentStep);
    setCurrentStep(1);
  };
  // if (loader) {
  //   return <Loader />;
  // }
  return (
    <>
      {fetchError && (
        <Warning
          isOpen={fetchError}
          message={fetchError || "invalid error"}
          onClose={() => handleFetchError()}
        />
      )}
      {openModal === true ? (
        <TenantPassportPaymentMethodModal
          onOpen={onOpenModalInvitation}
          onClose={onCloseModal}
        />
      ) : (
        ""
      )}
      {openModalInvitation === true ? (
        <TenantPassportInvitationSentModal
          success="Share Your Profile"
          route="tenant-passport"
          onClose={onCloseModalInvitation}
        />
      ) : (
        ""
      )}
      <div className="container-fluid bg-white p-3">
        <div className="container">
         

          <Stepper
          steps= {addTeneantPassportProfileDetails}
          activeStep={currentStep -1 }
          // onStepClick={handleStepClick}
          lockStep={false}
          maxSteps={4} // Customize if desired
          setIsDraft={(isDraft) => console.log("Draft status:", isDraft)}
        />
          {console.log(
            "handle screening questions...",
            handleScreeningQuestionsChange
          )}
          <div className="stepper-content-container mt-4">
            {currentStep === 1 &&
              (loading ? (
                <Loader />
              ) : (
                <InnerComponents.EditProfileForm
                  form={form}
                  handleChange={handleProfileChange}
                  errors={errors}
                  setProfileImages={setProfileImage}
                  ProfileImages={profileImage}
                  setDeletedImages={setDeletedImages}
                  DeletedImages={DeletedImages}
                  handleNext={handleNext}
                  disabledFields={disabledFields}
                />
              ))}

            {currentStep === 2 &&
              (loading ? (
                <Loader />
              ) : (
                <PassportCheckout
                  handleNext={handleNext}
                  handlePrev={handlePrev}
                  handlePaymentAuthorization={handlePaymentAuthorization}
                />
              ))}

            {currentStep === 3 &&
              (loading ? (
                <Loader />
              ) : (
                <IdentityVerificaton
                  handleNext={handleNext}
                  handlePrev={handlePrev}
                  data={form}
                  handleError={handleFetchError}
                />
              ))}

            {currentStep === 4 &&
              (loading ? (
                <Loader />
              ) : (
                <IncomeVerification handleNext={handleNext} data={form} />
              ))}

            {currentStep === 5 &&
              (loading ? (
                <Loader />
              ) : (
                <InnerComponents.EmploymentVerificationForm
                  form={employerVerificationForm}
                  errors={errors}
                  handleChange={handleEmployerChange}
                  addWorkHistory={addWorkHistory}
                  removeWorkHistory={removeWorkHistory}
                  handleNext={handleNext}
                  handlePrev={handlePrev}
                />
              ))}

            {currentStep === 6 &&
              (loading ? (
                <Loader />
              ) : (
                <InnerComponents.RentalHistoryForm
                  form={rentalHistoryForm}
                  errors={errors}
                  handleChange={handleRentalHistoryChange}
                  addRentalHistory={addRentalHistory}
                  removeRentalHistory={removeRentalHistory}
                  handleNext={handleNext}
                  handlePrev={handlePrev}
                />
              ))}

            {currentStep === 7 &&
              (loading ? (
                <Loader />
              ) : (
                <InnerComponents.CreditAndBackgroundCheckForm
                  form={incomeForm}
                  errors={errors}
                  Images={Images}
                  setImages={setImages}
                  handleChange={handleIncomeChange}
                  handleNext={handleNext}
                  handlePrev={handlePrev}
                />
              ))}

            {currentStep === 8 &&
              (loading ? (
                <Loader />
              ) : (
                <QuestionHandlerComponent
                  form={screeningQuestions}
                  errors={errors}
                  handleChange={handleScreeningQuestionsChange}
                  handleNext={handleNext}
                  handlePrev={handlePrev}
                  CreateScreeningRequest={CreateScreeningRequest}
                  screeningId={screeningId}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TenantPassportProfile;
