import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import UserPermission from "libs/UserPermission";
import { SettingCard } from "Components/GeneralComponents";
import { landlordTabs, tenantTabs, userTabs } from "utils/settingTabs";
import "style/settings.css";

const Setting = () => {
  const [activeTab, setActiveTab] = useState("personalInfo");
  const { ROLE } = UserPermission();

  const role = localStorage.getItem("role");
  const tabs = role === "landlord" ? landlordTabs : role === "user" ? userTabs : tenantTabs;

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="container-fluid bg-white p-0">
      <div className="setting-container d-md-none d-lg-flex d-none align-items-start gap-3">
        <div className="">
          {tabs.map((tab) => {
            // const showTab =
            //   !tab.roles ||
            //   tab.roles.includes(localStorage.getItem("role")) ||
            //   (ROLE[0]?.[tab.permission]?.view &&
            //     tab.roles.includes(localStorage.getItem("role")));

            return (
              // showTab && (
              <SettingCard
                key={tab.key}
                to={tab.to}
                isActive={activeTab === tab.key}
                onClick={() => handleTabClick(tab.key)}
                icon={tab.icon}
                title={tab.title}
                description={tab.description}
              />
              // )
            );
          })}
        </div>
        <div className="flex-grow-1 mt-2">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Setting;
