import React, { useEffect, useState } from "react";
import inProgressIcon from "assets/task-details-progress.png";
import DueDateIcon from "assets/task-details-due.png";
import dollar from "assets/CurrencyDollar.png";
import userImg from "assets/over-user-img.png";
import InviteProfessionalModal from "Modals/InviteProfessionalModal/InviteProfessionalModal";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import UseGetHook from "Hooks/UseGetHook";
const LocalProfessionalDetail = () => {
  //States start
  const [openModal, setOpenModal] = useState(false);
  //States end

  const { id } = UseUrlParamsHook();
  const { fetchLocalServiceProId, listProId } = UseGetHook('company', id)


  useEffect(() => {

    fetchLocalServiceProId()
    console.log(listProId, "local professionals")
  }, [])


  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };
  return (
    <>
      {openModal === true && <InviteProfessionalModal onClose={onCloseModal} />}
      <div className="container-fluid p-3">
        <div className="row mt-3">

          <div className="col-md-4">
            <div className="task-overview-tab-boxes p-3 position-relative">
              <div className="overview-box-img">
                <img src={DueDateIcon} alt="" />
              </div>
              <div className="over-view-box-text">
                <span>Open work orders</span>
                <br />
                <span>{listProId[0]?.orders ? listProId[0]?.orders : "-"}</span>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="task-overview-tab-boxes p-3 position-relative">
              <div className="overview-box-img">
                <img src={DueDateIcon} alt="" />
              </div>
              <div className="over-view-box-text">
                <span>Charges</span>
                <br />
                <span>
                  <img src={dollar} alt="" />
                </span>{" "}
                <span>{listProId[0]?.charges ? listProId[0]?.charges : "-"} Hourly</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-10">
            <div className="task-info-heading">
              <h4>Service Professional info</h4>
            </div>
            <div className="task-info-lists mt-4">
              <p>
                <span className="task-info-list-span me-3">Company Name:</span>{" "}
                {listProId?.companyName}
              </p>
              <p>
                <span className="task-info-list-span me-3">Location:</span>{" "}
                {listProId?.state?.name}

              </p>
              <p>
                <span className="task-info-list-span me-3">Phone No :</span>{" "}
                {listProId?.phone}
              </p>
              <p>
                <span className="task-info-list-span me-3">Email:</span>{" "}
                {listProId?.email}
              </p>
              <p>
                <span className="task-info-list-span me-3">Services:</span>{" "}
                {listProId[0]?.services ? listProId[0]?.services : '-'}
              </p>
              <p>
                <span className="task-info-list-span me-3">Description:</span>
                {listProId?.description}
              </p>
            </div>
          </div>
          <div className="col-md-2 text-end">
            <img src={userImg} alt="" />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <button
              onClick={onOpenModal}
              className="modal-submit-btn next-btn-main w-25"
            >
              Send Invite
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocalProfessionalDetail;
