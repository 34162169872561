import { SelectField, TextAreaField } from "Components/GeneralComponents";
import { useState } from "react";

function ServiceProfessionalWorkUpdateModel() {
  const [description, setDescription] = useState("");

  return (
    <div
      className=""
      style={{ padding: "0 30px" }}>
      <div className="mt-3">
        <TextAreaField
          name={"description"}
          label={"Description"}
          placeholder={"Description"}
          handler={(e) => setDescription(e.target.value)}
          required={true}
          value={description}
          rows={"5"}
          cols={"30"}
        />
      </div>

      <div className="post-update-btn mt-5 ">
        <button
          onClick={() => {
            console.log("Updating task", description);
          }}
          className="modal-post-update-btn ms-auto">
          Update
        </button>
      </div>
    </div>
  );
}
export default ServiceProfessionalWorkUpdateModel;
