// IncomeVerification.js
import React, { useState, useEffect, useRef } from "react";
import { PlaidLink } from "react-plaid-link";
import { message } from "antd";
import {
  generateIncomeVerificationToken,
  exchangePublicToken,
  handleIncomeVerificationFailure,
  handleIncomeVerificationSuccess,
} from "network/tenant/passport";
import Loader from "Helpers/Loader";
import { refundPayment } from "network/tenant/passport";
const IncomeVerification = ({ handleNext, data }) => {
  const [linkToken, setLinkToken] = useState(null);
  const plaidLinkRef = useRef(null); // Reference for PlaidLink
  const [pageLoading,setPageLoading] = useState(null);
  useEffect(() => {

    const fetchLinkToken = async () => {
      try {
        const response = await generateIncomeVerificationToken(data);
        console.log(response);
        if (response?.apiCallStatus == "error") {
          message.error(response.error);
          return;
        }
        setLinkToken(response.linkTokenResponse.link_token);
      } catch (error) {
        console.error("Error generating link token: ", error);
        // Handle error scenario, e.g., set an error state
      }
    };

    fetchLinkToken();
  }, []);

  const onSuccess = async (public_token, metadata) => {
    setPageLoading(true)
    console.log("Public Token: ", public_token);
    console.log("Metadata: ", metadata);

    try {
      const result = await exchangePublicToken(public_token);
      console.log("Result: ", result);

      console.log("Income verified");
      setPageLoading(false)
      handleNext();
    } catch (error) {
      setPageLoading(false)

      console.error("Error in income verification: ", error);
      // Handle error scenario
    }
  };

  const onFailure = async (err, metadata) => {
    console.log("Failure: ", err, metadata);
    try {
      const response = await handleIncomeVerificationFailure();
      console.log("Income verification failure response: ", response.data);
    } catch (error) {
      console.error("Error in handling failure: ", error);
    }
  };

  const onExit = async (err, metadata) => {
    if (err) {
      console.log("Exit with error: ", err);
    } else {
      console.log("Exit without error");
    }

    try {
      const response = await handleIncomeVerificationFailure();
      console.log("Income verification exit response: ", response.data);
    } catch (error) {
      console.error("Error in handling exit: ", error);
    }
  };

  // If there's no linkToken, return null to avoid rendering PlaidLink
  if (!linkToken || pageLoading) {
    return <Loader />; // Optional loading state while waiting for the token
  }

  return (
    linkToken && (
      <div className="container relative text-center d-flex flex-column align-items-center justify-content-center">
        <div className="p-4">
          <h2 className="text-center mb-4">Income Verification</h2>
          <p>Click on the button below to verify your Income</p>

          {linkToken ? (
            <div className="d-flex justify-content-center align-items-center ">
              <PlaidLink
                ref={plaidLinkRef}
                token={linkToken}
                // onLoad={() => {
                //   if (plaidLinkRef.current) {
                //     plaidLinkRef.current.open();
                //   }
                // }}
                onSuccess={onSuccess}
                onFailure={() => refundPayment()}
                onExit={(err, metadata) => console.log("Exit: ", err, metadata)}
                className="btn btn-primary text-center"
                style={{
                  background: "#ef6b3e",
                  borderRadius: "8px",
                }} // Add classes for button styling
              >
                <span className="d-block text-white  p-2 font-weight-0">
                  Income Verification
                </span>
              </PlaidLink>
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    )
  );
};

export default IncomeVerification;
