import CreatePayable from "Components/CreatePayable/CreatePayable";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import React from "react";

const PayableNew = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail="Create Payable"
        pop={true}
      />
      <CreatePayable />
    </div>
  );
};

export default PayableNew;
