import React from 'react'
import checkPassIconGreen from "assets/_CheckboxGreen.png";
import checkPassIconRed from "assets/_CheckboxRed.png";


const PasswordValidate = ({ validate }) => {
  return (
    <ul className="password-validation-list-menu p-0 d-flex flex-column gap-2">
            <li>
              {" "}
              <img
                src={
                  validate.hasMinimumLength
                    ? checkPassIconGreen
                    : checkPassIconRed
                }
                className="me-2"
                alt=""
              />{" "}
              Must be at least 8 characters.
            </li>
            <li>
              {" "}
              <img
                src={
                  validate.hasNumber
                    ? checkPassIconGreen
                    : checkPassIconRed
                }
                className="me-2"
                alt=""
              />{" "}
              Must contain at least 1 number
            </li>
            <li>
              {" "}
              <img
                src={
                  validate.hasUpperCase
                    ? checkPassIconGreen
                    : checkPassIconRed
                }
                className="me-2"
                alt=""
              />{" "}
              Must contain at least 1 upper letter
            </li>
            <li>
              {" "}
              <img
                src={
                  validate.hasSpecialCharacter
                    ? checkPassIconGreen
                    : checkPassIconRed
                }
                className="me-2"
                alt=""
              />{" "}
              Must contain at least 1 special character
            </li>
          </ul>
  )
}

export default PasswordValidate