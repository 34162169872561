import AllReports from "Components/AllReports/AllReports";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import NoAccessPage from "Pages/NoAccessMainPage/NoAccessPage";
import React from "react";

const Reports = () => {
  const storedRoles = localStorage.getItem("myUserRoles");
  const myUserRoles =
    storedRoles !== "undefined" ? JSON.parse(storedRoles) : [];
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar topBarTitle={"Reports"} />
      {!["Owner/Executive", "Property Manager"].some((role) =>
        myUserRoles?.includes(role)
      ) && myUserRoles?.length !== 0 ? (
        <NoAccessPage />
      ) : (
        <AllReports />
      )}
    </div>
  );
};

export default Reports;
