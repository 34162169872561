import React, { useState, useRef, useEffect } from "react";
import { Tooltip } from "antd";
import chevronIcon from "assets/chevron-down.png";
import { useMediaQuery } from "@mui/material";

const SelectMultipleColumns = ({
  label,
  placeholder,
  isRequired,
  values,
  selectedAmenities,
  options,
  handleChange,
  showMaxItems,
}) => {
  console.log("values", values);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const isMobile = useMediaQuery("(max-width: 767px)");
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1024px)");
  // const maxItemsToShow = isMobile || isTablet ? 1 : 3;
  const maxItemsToShow = showMaxItems ? showMaxItems : isMobile || isTablet ? 1 : 3;
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (value) => {
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.includes(value)
        ? prevSelectedOptions.filter((option) => option !== value)
        : [...prevSelectedOptions, value]
    );
  };

  useEffect(() => {
    if (selectedAmenities?.length > 0) {
      selectedAmenities.map((value) => {
        setSelectedOptions((prevSelectedOptions) => [
          ...prevSelectedOptions,
          value,
        ]);
      });
    }
  }, [selectedAmenities]);

  useEffect(() => {
    handleChange(selectedOptions);
  }, [selectedOptions]);

  const removeMultiValue = (value) => {
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.filter((option) => option !== value)
    );
  };

  return (
    <div className="row mt-3">
      <div className="col-md-12">
        <span className="property-details-input-title">
          {label} {isRequired && <span className="text-danger">*</span>}
        </span>
        <div
          className="custom-multi-select position-relative"
          ref={dropdownRef}
        >
          <input
            readOnly
            placeholder={
              isOpen || selectedOptions.length > 0 ? "" : placeholder
            }
            onFocus={toggleDropdown}
            type="text"
            className="form-control"
            // style={{ height: inputHeight }}
            ref={inputRef}
          />
          <span onClick={toggleDropdown} className="multi-chevron cursor">
            <img src={chevronIcon} alt="" />
          </span>
          <div className="selected-data d-flex align-items-center gap-3">
            {selectedOptions.length > 0
              ? selectedOptions.map((data, index) => {
                  if (index < maxItemsToShow) {
                    return (
                      <div key={data}>
                        <span className="select-data-box position-relative">
                          {data}
                          <div
                            onClick={() => {
                              removeMultiValue(data);
                            }}
                            className="cancel-select cursor"
                          >
                            <svg
                              width={16}
                              height={16}
                              fill="#EF6B3E"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M22 12a10 10 0 1 1-20 0 10 10 0 0 1 20 0ZM8.693 7.808a.626.626 0 1 0-.885.885L11.116 12l-3.308 3.307a.626.626 0 1 0 .885.885L12 12.884l3.307 3.308a.627.627 0 0 0 .885-.885L12.884 12l3.308-3.307a.627.627 0 0 0-.885-.885L12 11.116 8.693 7.808Z" />
                            </svg>
                          </div>
                        </span>
                      </div>
                    );
                  }
                  if (index === maxItemsToShow) {
                    const remainingItems = selectedOptions
                      .map((selected) => selected + ", ")
                      .filter((_, i) => i >= maxItemsToShow);
                    return (
                      <div>
                        <Tooltip title={remainingItems}>
                          <span className="select-data-box position-relative">
                            +{selectedOptions.length - maxItemsToShow}
                          </span>
                        </Tooltip>
                      </div>
                    );
                  }
                })
              : ""}
          </div>
          {isOpen && (
            <div className="dropdown-options">
              <div className="row">
                {options.map((option) => (
                  <div className="col-md-4">
                    <div className="multi-select-options">
                      {option.map((option) => (
                        <div
                          key={option.id}
                          className="d-flex align-items-center"
                        >
                          <input
                            type="checkbox"
                            id={option.value}
                            value={option.value}
                            checked={selectedOptions.includes(option.value)}
                            onChange={() => handleCheckboxChange(option.value)}
                          />
                          <label htmlFor={option.value}>{option.label}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectMultipleColumns;
