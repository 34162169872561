import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import AddTenantDetailsEditModal from "Modals/AddTenantDetailsEditModal/AddTenantDetailsEditModal";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";

const AddTenantDetailsEditModalPage = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Edit Tenant Details"}
        back="all-tenants"
      />
      <AddTenantDetailsEditModal />
    </div>
  );
};

export default AddTenantDetailsEditModalPage;
