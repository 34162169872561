import { useEffect, useState } from "react";
import blackBoxStar from "assets/star.png";
import whiteBoxWallet from "assets/wallet-03.png";
import plusIconOrange from "assets/plus-orange.png";
import { Link } from "react-router-dom";
import { message } from "antd";
import * as Component from "Components/GeneralComponents";
import * as Icon from "assets/icons";
import config from "Helpers/config.js";
import FormAddPayment from "Modals/AddPaymentMethod/FormAddPayment";
import { Skeleton } from "antd";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import masterCard from "assets/master-card.png";
import * as API from "network/landlord/Account";
import Countdown from "Components/GeneralComponents/Countdown";
import {
  getCurrentSubscription,
  getSubscriptionPlans,
  subscribe,
} from "network/landlord/subscription";
import SettingSubscriptionCards from "Components/LandLordPackages/SettingSubscriptionCards";
import Loader from "Helpers/Loader";

const SettingsSubscription = () => {
  // States start
  const [changePlan, SetChangePlan] = useState(false);
  const [managePayment, setManagePayment] = useState(false);
  const [payment, setPayment] = useState([]);
  const [key, setKey] = useState([]);
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [interval, setInterval] = useState("Monthly");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isPayNowModelOpen, setIsPayNowModelOpen] = useState(false);
  const [options, setOptions] = useState({});
  const [currentPlan, setCurrentPlan] = useState(null);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({
    planName: "",
    price: "",
  });
  const [downgradedTo, setDowngradedTo] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getSubscriptionPlans().then((response) => {
      if (response.apiCallStatus === "success") {
        setPlans(response.message.plans);
        getCurrentSubscription().then((res) => {
          setIsLoading(false);
          if (res.apiCallStatus === "success") {
            setCurrentPlan(res.message.subscription);
            res.message.subscription.billingCycle !== "Trial" &&
              setInterval(res.message.subscription.billingCycle);
            setSelectedPlan({
              planName: res.message.subscription.plan,
              price:
                res.message.subscription.plan === "Trial"
                  ? "Free"
                  : res.message.subscription.billingCycle === "Monthly"
                  ? response.message.plans?.[res.message.subscription.plan]
                      .priceMonthly
                  : response.message.plans?.[res.message.subscription.plan]
                      .priceYearly,
            });
            res.message.subscription.downgradeTo
              ? setDowngradedTo({
                  downgradePlan: res.message.subscription.downgradeTo,
                  downgradeDate: res.message.subscription.downgradeDate,
                })
              : setDowngradedTo(null);
          }
        });
      }
    });
  }, [update]);

  const FetchPaymentMethod = async () => {
    const response = await API.FetchPaymentMethod();
    setIsLoading(false);
    const { message } = response;
    setPayment(message.data);
  };

  useEffect(() => {
    FetchPaymentMethod();
  }, [update]);

  const deletePaymentMethod = (id) => {
    if (data.length === 1) {
      message.error("You must have at least one payment method");
    } else {
      fetch(`${config.baseUrl}/api/stripe/payment-method/${id}`, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          if (res.apiCallStatus === "success") {
            message.success(res.message.message);
            setUpdate(!update);
            setIsLoading(!isLoading);
          } else {
          }
        });
    }
  };
  const onOpenAccountModal = () => {
    setOpenAccountModal(true);
  };
  const onCloseAddPaymentModal = () => {
    setOpenAccountModal(false);
  };

  const handleIconClick = (result) => {
    // Toggle the dropdownOpen state
    const filterData = data.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, prospectName) => (
        <>
          <Link>
            <span className="property-table-name-text ">
              <img
                src={`${prospectName.img}`}
                alt=""
                className="me-2 property-table-image mw_40 mh_40 me-2 rounded-5"
              />
              {text}
            </span>
          </Link>
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text, phone) => (
        <>
          <span className="phone">{text}</span>
        </>
      ),
    },
    {
      title: "Card",
      dataIndex: "card_number",
      render: (text) => (
        <>
          <span>●●●● ●●●● ●●●● {text}</span>
        </>
      ),
    },
    {
      title: "Card type",
      dataIndex: "card",
      render: (text) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: "Expiry",
      dataIndex: "exp",
      render: (text, exp) => (
        <>
          <span>{`${exp.exp_month}/${exp.exp_year}`}</span>
        </>
      ),
    },
    {
      title: "Payment method type",
      dataIndex: "type",
      render: (text, exp) => (
        <>
          <span>{text ? "Primary" : "Secondary"}</span>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <Icon.VerticalElipsisIcon width={23} height={23} />
            <Component.RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleDeleteRecord: () => {
                  deletePaymentMethod(setting.key);
                },
              }}
              fields={{
                edit: true,
                delete: true,
              }}
            />
          </div>
        </>
      ),
    },
  ];

  const data =
    payment?.map((e) => ({
      key: e.id,
      name: localStorage.getItem("name"),
      email: localStorage.getItem("email"),
      card_number: e.card.last4,
      card: e.card.brand,
      exp_month: e.card.exp_month,
      exp_year: e.card.exp_year,
      type: e.default_payment_method,
    })) || [];

  // Stripe
  const stripeKey = loadStripe(config.stripe_publish_key);

  const fetchClientSecret = async (planType) => {
    const res = await subscribe({
      userId: localStorage.getItem("user_id"),
      plan: planType,
      billingCycle: interval,
      paymentMethodId: null,
    });
    const Session = res.message.session;
    console.log("Subscribe Response", res);
    return Session.client_secret;
  };

  async function SubscribeIfTrial(planType) {
    console.log("SubscribeIfTrial", planType);
    const clientSecret = await fetchClientSecret(planType);
    console.log("Client Secret", clientSecret);
    // Handle Checkout Completion
    const handleCheckoutCompletion = async (event) => {
      setUpdate(true);
      setIsPayNowModelOpen(false);
    };

    const options = { clientSecret, onComplete: handleCheckoutCompletion };
    setOptions(options);
    setIsPayNowModelOpen(true);
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {openDeleteModal && <></>}
          <Component.FormModalLayout
            title={"ADD PAYMENT METHOD DETAILS"}
            isOpen={openAccountModal}
            onClose={onCloseAddPaymentModal}
          >
            <FormAddPayment
              onClose={onCloseAddPaymentModal}
              setUpdate={setUpdate}
            />
          </Component.FormModalLayout>

          {downgradedTo && (
            <Countdown
              targetDate={downgradedTo?.downgradeDate}
              from={currentPlan?.plan}
              to={downgradedTo?.downgradePlan}
            />
          )}
          {/* <p className="heading">SUBSCRIPTION</p> */}

          <div className=" d-flex justify-content-md-between flex-md-row flex-column gap-4 ps-4 pe-4  ">
            {currentPlan ? (
              <>
                <div
                  className=""
                  style={{
                    height: "210px",
                    backgroundColor: "black",
                    borderRadius: "10px",
                  }}
                >
                  <div className="p-3">
                    <div>
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column pe-4">
                          <span
                            className=""
                            style={{ color: "white", textWrap: "nowrap" }}
                          >
                            Current Subscription Plan
                          </span>
                          <span className="primary-orange-text fs-5">
                            {currentPlan?.plan}
                          </span>{" "}
                        </div>
                        <div>
                          <img src={blackBoxStar} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="sub-black-box-text">
                      <p className="m-0 pb-2" style={{ color: "white" }}>
                        $
                        {currentPlan?.plan === "Trial"
                          ? "Free"
                          : currentPlan?.billingCycle === "Monthly"
                          ? plans?.[currentPlan?.plan]?.priceMonthly
                          : plans?.[currentPlan?.plan]?.priceYearly}
                      </p>
                    </div>
                    <div>
                      <button
                        onClick={() => {
                          SetChangePlan(!changePlan);
                          setManagePayment(false);
                        }}
                        className="sub-black-box-btn mt-2"
                      >
                        Change Plan
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Skeleton.Button
                  active
                  shape={"square"}
                  block={false}
                  style={{ width: "330px", height: "190px" }}
                />
              </>
            )}

            <>
              <div
                className=""
                style={{
                  height: "210px",
                  border: "1px solid #f4f2f9",
                  borderRadius: "10px",
                }}
              >
                <div className="p-3">
                  <div>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-column">
                        <div
                          className="drawer-text-grey pe-4"
                          style={{
                            fontSize: "20px",
                            fontWeight: "600",
                            textWrap: "nowrap",
                          }}
                        >
                          Payment Methods
                        </div>
                      </div>
                      <div>
                        <img src={whiteBoxWallet} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="card-info-box d-flex flex-direction-row  gap-3 mb-3 ">
                    <div className="card-info-img">
                      <img src={masterCard} className="img-fluid" alt="" />
                    </div>
                    <div className="card-text" style={{ width: "200px" }}>
                      <p className="drawer-text-grey mb-0">
                        {" "}
                        <span style={{ fontSize: "12px" }}>
                          {data[0]?.card}
                        </span>{" "}
                        ●●●● ●●●● ●●●●
                        <span
                          className="text-dark ms-3 f-italic"
                          style={{ fontSize: "12px" }}
                        >
                          {data.length > 0 ? data[0]?.card_number : "----"}
                        </span>
                      </p>
                      <p
                        className="drawer-text-grey mb-0"
                        style={{ fontSize: "12px" }}
                      >
                        Exp. Date{" "}
                        <span className="text-dark ms-3 f-italic">
                          {data.length > 0
                            ? `${data[0]?.exp_month}/${data[0]?.exp_year}`
                            : "--/--/--"}
                        </span>
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      setManagePayment(!managePayment);
                      SetChangePlan(false);
                    }}
                    className="sub-white-box-btn"
                  >
                    Manage Payment{" "}
                  </button>
                </div>
              </div>
            </>
          </div>
          <div className="sub-payment-box mt-3">
            <div className="sub-payment-heading row">
              {changePlan && (
                <>
                  <div className="pb-2 d-flex justify-content-center">
                    Subscription Plans
                  </div>
                  <Component.PricingToggle
                    interval={interval}
                    setInterval={setInterval}
                  />
                  <SettingSubscriptionCards
                    data={plans}
                    interval={interval}
                    selectedPlan={selectedPlan}
                    setSelectedPlan={setCurrentPlan}
                    subscribeIfTrial={SubscribeIfTrial}
                    setUpdate={setUpdate}
                    isLoading={isLoading}
                  />
                </>
              )}
            </div>
            {managePayment && (
              <>
                <div className="container px-4">
                  <div className="d-flex justify-content-between mt-2">
                    <p className="heading mb-5">PAYMENT</p>
                    <button
                      className="add-subscription-btn-white"
                      onClick={onOpenAccountModal}
                    >
                      <img
                        src={plusIconOrange}
                        className="add-property-icon-white"
                        alt=""
                      />{" "}
                      Add Payment Method
                    </button>
                  </div>
                </div>
                <div className="mt-3">
                  <Component.CustomTable
                    loading={isLoading}
                    pagination={true}
                    columns={columns}
                    dataSource={data}
                    rowSelection={false}
                  />
                </div>
              </>
            )}
          </div>

          {/* Create Subscriber now Model */}
          <Component.FormModalLayout
            isOpen={isPayNowModelOpen}
            onClose={() => setIsPayNowModelOpen(false)}
          >
            <EmbeddedCheckoutProvider stripe={stripeKey} options={options}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </Component.FormModalLayout>
        </>
      )}
    </>
  );
};

export default SettingsSubscription;
