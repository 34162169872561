import React, { useState, useEffect } from "react";
import settingIconOrange from "assets/dots-vertical.png";
import * as Component from "Components/GeneralComponents";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import * as InnerComponent from "./index";
import * as API from "network/landlord/lease";
import { message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import UploadDocument from "Components/NewLease/UploadDocument";

const LeaseDetails = () => {
  const { id, tab } = UseUrlParamsHook();
  const navigate = useNavigate();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isDeleteLeaseModal, setIsDeleteLeaseModal] = useState(false);
  const [commercial, setCommercial] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isupload, setIsupload] = useState(false);
  const [data, setData] = useState([]);
  const [leaseId, setLeaseId] = useState("");
  const [activeTab, setActiveTab] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  
  useEffect(() => {
    setIsLoading(true);
    API.getLeaseById(id).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        res.message.data.property_type === "commercial"
          ? setCommercial(true)
          : setCommercial(false);
        setData(res.message.data);
      }
    });
  }, [id]);

  const items = [
    { key: "1", label: "Overview", children: <InnerComponent.OverviewTab id={id} /> },
    { key: "2", label: "Tenants", children: <InnerComponent.TenantsTab propertyId={data?.property} unitId={data?.unit?._id} /> },
    !commercial && { key: "3", label: "Pet Info", children: <InnerComponent.PetsInfoTab id={id} /> },
    { key: "4", label: "Vehicle Info", children: <InnerComponent.VehicleInfoTab id={id} /> },
    !commercial && { key: "5", label: "Dependent Info", children: <InnerComponent.DependentInfoTab id={id} /> },
    { key: "6", label: "Documents", children: <InnerComponent.DocumentsTab data={data} /> },
    { key: "7", label: "Files", children: <InnerComponent.FilesTab id={id} category={"lease"} /> },
    { key: "8", label: "Notes", children: <InnerComponent.NotesTab id={id} category={"lease"} /> },
  ].filter(Boolean)

  useEffect(() => {
    setActiveTab(tab || "1");
  }, [tab]);

  const onChange = (key) => {
    setActiveTab(key);
    searchParams.set("tab", key);
    navigate({ search: searchParams.toString() });
  };

  const handleIconClickCustom = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  
  const handleopenmodal = () => {
    setIsupload(!isupload);
  };


  // handle delete Lease
  const handleDeleteLease = () => {
    setIsLoading(true);
    API.deleteLease(deleteId).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        message.success(res?.message?.message);
        setIsDeleteLeaseModal(false);
        navigate("/all-lease");
      } else {
        message.error(res?.message);
      }
    });
  };

  return (
    <>
      {isupload && (
        <Component.FormModalLayout
          title={"Upload Document"}
          isOpen={isupload}
          onClose={handleopenmodal}
        >
          <div className="modal-content-main-section p-41">
            <UploadDocument leaseId={leaseId} onClose={handleopenmodal} />
          </div>
        </Component.FormModalLayout>
      )}

      <Component.DeleteModal
        isOpen={isDeleteLeaseModal}
        onClose={() => setIsDeleteLeaseModal(false)}
        message={"Are you sure you want to delete this lease?"}
        deleteCall={handleDeleteLease}
        isLoading={isLoading}
      />

      <div className="container-fluid bg-white p-3">
        <div className="d-flex justify-content-end">
          <div className="position-relative">
            <img
              onClick={handleIconClickCustom}
              src={settingIconOrange}
              alt="Action Buttons"
              className="cursor"
            />
            {isDropdownOpen && (
              <>
                <Component.RecordMenuPopup
                  isOpen={true}
                  onClose={() => {}}
                  handler={{
                    handleDeleteRecord: () => {
                      setDeleteId(data._id);
                      setIsDeleteLeaseModal(true);
                    },
                    handleRenewLease: () => {},
                    uploadDocument: () => {
                      setLeaseId(data._id);
                      handleopenmodal();
                    },
                  }}
                  fields={{
                    delete: true,
                    renewLease: true,
                    uploadDocument: true,
                  }}
                />
              </>
            )}
          </div>
        </div>
        {activeTab && !isLoading && <Component.CustomTabs
          items={items}
          defaultActiveKey={activeTab}
          onChange={onChange}
        />}
      </div>
    </>
  );
};

export default LeaseDetails;
