import React from "react";
import { useState } from "react";
import * as Component from "Components/GeneralComponents";
import { validatecreateTaskForm } from "utils/validations";
import { TenantCreateTaskFormData } from "utils/FormDataStructure";
import { createnewtask } from "network/tenant/task";
import { message } from "antd";

const TaskCreate = ({ id, data, onclose ,render }) => {
  const [errors, setErrors] = useState({});
  const [Images, setImages] = useState([]);
  const [loader, setLoader] = useState(false);
  const [form, setForm] = useState({
    title: "",
    description: "",
    unit: "",
    property: id,
  });

  const handleChange = (fieldName, value) => {
    console.log(fieldName, value);
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  const addTask = () => {
    const newErrors = validatecreateTaskForm(form);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setLoader(true);
      const formdata = TenantCreateTaskFormData(form, Images);
      createnewtask(formdata).then((res) => {
        if (res.apiCallStatus === "success") {
          message.success("Task created successfully");
          setLoader(false);
          onclose();
          render(true);
        } else {
          setLoader(false);
          message.error("Task creation failed");
        }
      });
    }
  };
  return (
    <>
      <div className="p-41">
        <div className="row mt-3">
          <div className="col-md-12 text-start">
            <Component.SelectField
              name={"unit"}
              required={true}
              label={"Unit"}
              placeholder={"Select Unit"}
              value={form.unit}
              handler={(e) => handleChange("unit", e)}
              options={
                data
                  ? data?.map((unit) => {
                      return { value: unit._id, label: unit.name };
                    })
                  : []
              }
              errorMessage={errors.unit}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12 text-start">
            <Component.InputField
              name={"title"}
              required={true}
              label={"Title"}
              placeholder={"Title"}
              value={form.title}
              handler={(e) => handleChange("title", e.target.value)}
              errorMessage={errors.title}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-12">
            <Component.TextAreaField
              name={"description"}
              required={true}
              label={"Description"}
              placeholder={"Description"}
              value={form.description}
              handler={(e) => handleChange(e.target.name, e.target.value)}
              rows={5}
              cols={20}
              errorMessage={errors.description}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <Component.MediaField
              label={"Upload Media"}
              setImages={setImages}
              Images={Images}
            />
          </div>
        </div>
      </div>
      <div className="modal-content-footer-section-scroll p-custom">
        <div className="row mt-3">
          <div className="col-md-12 d-flex justify-content-end responsive-direction-column">
            <div className="task-modal-footer-btn">
              <Component.SaveModalButton loader={loader} addTask={addTask} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskCreate;
