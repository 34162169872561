import React, { useState, useEffect, useCallback } from "react";
import { selectBoolean } from "utils/dropdownData";
import * as Component from "Components/GeneralComponents";
import {
  uploadDocument,
  getDocument,
  getDocumentEditorLink,
  addRecipientToDoc,
  subscribeDocumentEvent,
} from "network/landlord/signnow";
import { addInvites } from "network/landlord/lease";
import { message } from "antd";
import { validateLeaseDocumentForm } from "utils/validations";
import Loader from "Helpers/Loader";
import { payToSignDocument } from "network/landlord/signnow";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import config from "Helpers/config.js";

const DocumentInfo = ({
  form,
  handleChange,
  errors,
  leaseFiles,
  setLeaseFiles,
  handleNext,
  handleBackButton,
  isSubmitForm,
  showbackbutton = true,
  tenantEmails,
  setSuccess,
  leaseId,
  buttonLoader = false,
}) => {
  const stripeKey = loadStripe(config.stripe_publish_key);
  const urlParams = new URLSearchParams(window.location.search);
  const documentId = urlParams.get("documentId");
  const [isLoader, setLoader] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [roles, setRoles] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [error, setError] = useState({});
  const [update, setUpdate] = useState(false);
  const [isPurchaseModelOpen, setIsPurchaseModelOpen] = useState(false);
  const [checkoutSuccess, setCheckoutSuccess] = useState(false);

  const handleUpload = async (file) => {
    if (!file) {
      // console.log("Please select a document to sign!");
      return;
    }

    setLoader(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("leaseId", leaseId);

    try {
      const res = await uploadDocument(formData);

      setLoader(false);

      if (res.apiCallStatus === "success") {
        handleChange("object", {
          documentId: res.message.documentId,
          documentName: file.name,
          documentStatus: "pending",
          documentUrl: `https://app.signnow.com/webapp/document/${res.message.documentId}`,
        });
        subscribeDocumentEvent({
          documentId: res.message.documentId,
        }).then((res) => {
          // console.log("Subscribe Document Event =>>", res);
        });
        setUpdate(true);
        window.location.href = res.message.editorUrl;
        message.success("Document uploaded successfully!");
      } else {
        message.error("Document upload failed.");
      }
    } catch (error) {
      setLoader(false);
      message.error("An error occurred during upload.");
    }
  };

  useEffect(() => {
    if (update) {
      handleNext();
    }
  }, [update]);

  const sendInvite = async () => {
    const formdata = recipients;
    setLoader(true);
    addRecipientToDoc(documentId, formdata).then((res) => {
      console.log("Invite Response =>>", res);
      setLoader(false);
      if (res.apiCallStatus === "success") {
        handleChange("invites", res.message.response.data);
        // setUpdate(true);
        const invites = {
          invites: res.message.response.data,
        };
        console.log("Invites =>>", invites);
        addInvites(documentId, invites).then((res) => {
          console.log("Add Invites =>>", res);
          message.success("Invite sent successfully!");
        });
        setSuccess(true);
      }
    });
  };

  useEffect(() => {
    if (documentId) {
      setLoading(true);
      getDocument(documentId).then((res) => {
        setLoading(false);
        if (res.apiCallStatus === "success") {
          // console.log("Document Data =>>", res.message);
          setRoles(res.message.data.roles);
        }
      });
    }
  }, []);

  const handleSubmit = () => {
    const newErrors = validateLeaseDocumentForm(form, leaseFiles);
    setError(newErrors);
    if (Object.keys(newErrors).length > 0) {
      return;
    }

    if (form.isSigned === "No" && form.newLeaseSign === "Yes") {
      handleUpload(leaseFiles[0]);
    } else {
      handleNext();
    }
  };

  const handleEditDocument = () => {
    setEditLoader(true);
    const formData = new FormData();
    // console.log("Lease ID =>>", leaseId);
    formData.append("leaseId", leaseId);
    getDocumentEditorLink(documentId, leaseId).then((res) => {
      setEditLoader(false);
      if (res.apiCallStatus === "success") {
        window.location.href = res.message.editorUrl;
      }
    });
  };

  const fetchClientSecret = useCallback(async () => {
    const res = await payToSignDocument();
    console.log("Subscribe Response", res);
    const Session = res.message.clientSecret;

    return Session;
  }, []);

  useEffect(() => {
    if (checkoutSuccess) {
      console.log("Checkout Success");
      handleSubmit();
    }
  }, [checkoutSuccess]);

  const handleCheckoutCompletion = async () => {
    setCheckoutSuccess(true);
  };

  const options = { fetchClientSecret, onComplete: handleCheckoutCompletion };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          <Component.FormModalLayout
            isOpen={isPurchaseModelOpen}
            onClose={() => setIsPurchaseModelOpen(false)}
          >
            <EmbeddedCheckoutProvider stripe={stripeKey} options={options}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </Component.FormModalLayout>
          {roles.length > 0 ? (
            <div className="mt-5">
              <div className="row d-flex align-items-center">
                <span className="col heading">
                  Select Emails to send invite
                </span>
                <div className="col-md-3">
                  <Component.SubmitButton
                    handler={handleEditDocument}
                    isLoading={editLoader}
                  >
                    Edit Document
                  </Component.SubmitButton>
                </div>
              </div>
              {roles.map((role, index) => (
                <div className="mt-3">
                  <Component.SelectField
                    label={role.name}
                    name={role.name}
                    placeholder={"Select Role"}
                    options={tenantEmails.map((email) => ({
                      label: email,
                      value: email,
                    }))}
                    value={recipients[index]?.email}
                    handler={(e) => {
                      console.log("Recipient =>> ", role.name, e);
                      setRecipients((prevState) => {
                        // return [...prevState, { role_id: role.unique_id, email: e, order: 1, auth_method: "none" }];
                        // only add in the index from map if it exists in the array else add it to the end
                        const index = prevState.findIndex(
                          (item) => item.role_id === role.unique_id
                        );
                        if (index === -1) {
                          return [
                            ...prevState,
                            {
                              role_id: role.unique_id,
                              email: e,
                              order: 1,
                              auth_method: "none",
                            },
                          ];
                        }
                        prevState[index] = {
                          role_id: role.unique_id,
                          email: e,
                          order: 1,
                          auth_method: "none",
                        };
                        return [...prevState];
                      });
                    }}
                  />
                </div>
              ))}
              <div className="d-flex justify-content-between gap-1 pb-3 mt-5">
                <div style={{ width: "50%" }}>
                  {showbackbutton && (
                    <Component.SubmitButton
                      type="contained"
                      handler={handleBackButton}
                    >
                      Back
                    </Component.SubmitButton>
                  )}
                </div>
                <div style={{ width: "50%" }}>
                  <Component.SubmitButton
                    handler={sendInvite}
                    isLoading={isLoader}
                  >
                    {isSubmitForm ? "Submit" : "Next"}
                  </Component.SubmitButton>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="row mt-3">
                <div className="col-md-12">
                  <Component.SelectField
                    name={"isSigned"}
                    label={"Is the lease signed?"}
                    placeholder={"Select Signed"}
                    required={true}
                    value={form.isSigned}
                    options={selectBoolean}
                    handler={(e) => handleChange("isSigned", e)}
                    errorMessage={error.isSigned}
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <Component.MediaField
                    label={""}
                    setImages={setLeaseFiles}
                    Images={leaseFiles}
                    errorMessage={errors.files || error.files}
                    height={266}
                  />
                </div>
              </div>
              {form.isSigned === "No" && (
                <>
                  <div className="tenant-personal-details-title mt-4">
                    Would you like to get a new lease sign?
                    <span className="sign-up-imp-star">{`${" *"}`}</span>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <Component.RadioGroupField
                        value={form.newLeaseSign}
                        options={[
                          { label: "Yes", value: "Yes" },
                          { label: "No", value: "No" },
                        ]}
                        handler={(e) => handleChange("newLeaseSign", e)}
                        direction="row"
                        gap="5"
                        errorMessage={errors.newLeaseSign || error.newLeaseSign}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="d-flex justify-content-between gap-1 pb-3 mt-5">
                <div style={{ width: "50%" }}>
                  {showbackbutton && (
                    <Component.SubmitButton
                      type="contained"
                      handler={handleBackButton}
                    >
                      Back
                    </Component.SubmitButton>
                  )}
                </div>
                <div style={{ width: "50%" }}>
                  <Component.SubmitButton
                    handler={
                      form.isSigned === "No" && form.newLeaseSign === "Yes" && leaseFiles.length > 0
                        ? () => setIsPurchaseModelOpen(true)
                        : handleSubmit
                    }
                    // isLoading={isLoader}
                    isLoading={
                      form.isSigned === "No" && form.newLeaseSign === "Yes"
                        ? isLoader
                        : buttonLoader
                    }
                  >
                    {isSubmitForm
                      ? form.isSigned === "No" && form.newLeaseSign === "Yes" && leaseFiles.length > 0
                        ? "Pay to Sign"
                        : "Submit"
                      : "Next"}
                  </Component.SubmitButton>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default DocumentInfo;
