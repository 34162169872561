import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import helpIcon from "assets/help-circle.png";

import {
  CustomTable,
  DeleteModal,
  FilterRow,
  RecordMenuPopup,
} from "Components/GeneralComponents";
import settingIcon from "assets/three-dots.png";
import trashIconWhite from "assets/trash-icon-white.png";
import activeDot from "assets/_Dot.png";
import redDot from "assets/svg/_Dot.svg";

const AllLandlords = () => {
  const navigate = useNavigate();

  // State management
  const [filter, setFilter] = useState({});
  const [filteredLandlords, setFilteredLandlords] = useState([]); // Default to show all data
  const [selectedTableItem, setSelectedTableItem] = useState([]);
  const [isOpenBulkDeleteModal, setIsOpenBulkDeleteModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [key, setKey] = useState(null);

 // Sample data
const data = [
    {
      key: "1",
      name: "Olivia Rhye",
      phone: "+1 (555) 543-2109",
      email: "olivia@untitledui.com",
      property: "354 Gladwell Street\nPITTSBURGH, Pennsylvania(PA),15283",
      status: "Active",
    },
    {
      key: "2",
      name: "Phoenix Baker",
      phone: "+1 (555) 345-6789",
      email: "phoenix@untitledui.com",
      property: "4570 Griffin Street\nPhoenix, Arizona(AZ), 85003",
      status: "Inactive",
    },
    {
      key: "3",
      name: "Lana Steiner",
      phone: "+1 (555) 987-6543",
      email: "lana@untitledui.com",
      property: "1908 Parrish Avenue\nUtopia, Texas(TX), 78884",
      status: "Active",
    },
    {
      key: "4",
      name: "Demi Wilkinson",
      phone: "+1 (555) 789-0123",
      email: "demi@untitledui.com",
      property: "3183 Ashcraft Court\nSan Diego, California(CA), 92121",
      status: "Active",
    },
    {
      key: "5",
      name: "Mason Lee",
      phone: "+1 (555) 654-3210",
      email: "mason@untitledui.com",
      property: "1625 Green Hill Drive\nAustin, Texas(TX), 78758",
      status: "Inactive",
    },
    {
      key: "6",
      name: "Avery Patel",
      phone: "+1 (555) 567-8901",
      email: "avery@untitledui.com",
      property: "782 Forest Road\nDenver, Colorado(CO), 80202",
      status: "Active",
    },
    {
      key: "7",
      name: "Jordan King",
      phone: "+1 (555) 456-7890",
      email: "jordan@untitledui.com",
      property: "994 Oak Street\nPortland, Oregon(OR), 97205",
      status: "Inactive",
    },
    {
      key: "8",
      name: "Taylor Brooks",
      phone: "+1 (555) 234-5678",
      email: "taylor@untitledui.com",
      property: "215 Maple Avenue\nChicago, Illinois(IL), 60616",
      status: "Active",
    },
    {
      key: "9",
      name: "Morgan Scott",
      phone: "+1 (555) 123-4567",
      email: "morgan@untitledui.com",
      property: "502 Cedar Lane\nSeattle, Washington(WA), 98101",
      status: "Inactive",
    },
    {
      key: "10",
      name: "Jamie Torres",
      phone: "+1 (555) 789-6543",
      email: "jamie@untitledui.com",
      property: "300 Rosewood Drive\nLas Vegas, Nevada(NV), 89109",
      status: "Active",
    },
    {
      key: "11",
      name: "Alexis Reed",
      phone: "+1 (555) 876-5432",
      email: "alexis@untitledui.com",
      property: "850 Birch Street\nMiami, Florida(FL), 33101",
      status: "Inactive",
    },
    {
      key: "12",
      name: "Charlie Morgan",
      phone: "+1 (555) 345-6789",
      email: "charlie@untitledui.com",
      property: "421 Pine Crescent\nBoston, Massachusetts(MA), 02118",
      status: "Active",
    },
    {
      key: "13",
      name: "Casey Nguyen",
      phone: "+1 (555) 234-5678",
      email: "casey@untitledui.com",
      property: "1031 Cherry Lane\nSacramento, California(CA), 95814",
      status: "Active",
    },
    {
      key: "14",
      name: "Sydney Turner",
      phone: "+1 (555) 789-1234",
      email: "sydney@untitledui.com",
      property: "1968 Willow Street\nDallas, Texas(TX), 75202",
      status: "Inactive",
    },
    {
      key: "15",
      name: "Elliott Collins",
      phone: "+1 (555) 456-7891",
      email: "elliott@untitledui.com",
      property: "765 Aspen Boulevard\nNew York, New York(NY), 10001",
      status: "Active",
    },
  ];
  
  useEffect(()=>{
    setFilteredLandlords(data)
  },[])

  // Handle row selection
  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedTableItem(selectedRowKeys);
    },
  };

  // Handle icon click to open RecordMenuPopup
  const handleIconClick = (settingKey) => {
    setKey(settingKey === key ? null : settingKey);
  };

  // Handle reset filter action
  const handleResetFilter = () => {
    setFilter({});
    setFilteredLandlords(data);
  };

  // Handle search
  const handleSearch = (searchTerm) => {
    console.log(searchTerm);
    const filtered = data.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.phone.includes(searchTerm) ||
      item.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredLandlords(filtered);
  };

  // Handle single deletion
  const handleDelete = (id) => {
    setFilteredLandlords((prevFiles) => prevFiles.filter((item) => item.key !== id));
  };

  const PhoneColumnTitle = () => (
    <span>
      PHONE NO
      <Tooltip title="Phone must be in e14 format">
        <img
          src={helpIcon}
          alt="Help Icon"
          className="cursor ps-2"
          style={{ marginLeft: 5 }}
        />
      </Tooltip>
    </span>
  );

  // Table columns with custom render functions
  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
    },
    {
      title: <PhoneColumnTitle />,
      dataIndex: "phone",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "EMAIL ADDRESS",
      dataIndex: "email",
    },
    {
      title: "PROPERTIES",
      dataIndex: "property",
      render: (text) => <span style={{ whiteSpace: "pre-line" }}>{text}</span>,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      render: (text, setting) => (
        <div
          className={`${
            text === "Paid" || text === "Active"
              ? "prospect-active-bar"
              : "landlords-inactive-bar"
          }`}
          style={{ padding: "5px 10px" }}
        >
          <img
            src={text === "Active" && activeDot }
            alt=""
          />{" "}
          { text === "Inactive" && <div style={{
            width: "6px",
            height: "6px",
            background: text === "Inactive" && " #667085" ,
            borderRadius: "50%",
            display: "inline-block",
            // marginLeft: 5,
           
          }}></div>}
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (_, record) => (
        <div
          className="position-relative cursor text-center"
          onClick={() => handleIconClick(record.key)}
        >
          <img src={settingIcon} alt="Settings" />
          <RecordMenuPopup
            isOpen={record.key === key}
            onClose={() => setKey(null)}
            fields={{ download: true, delete: true }}
            handler={{
              handleDeleteRecord: () => {
                setDeleteId(record.key);
                setOpenDeleteModal(true);
              },
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="container-fluid bg-white p-3">
      <FilterRow
        filter={filter}
        setFilter={setFilter}
        // btnTitle="Add New File"
        // btnHandler={() => navigate("/new-files")}  //F9FABF
        handleSubmit={() => handleSearch(filter.search || "")}
        handleReset={handleResetFilter}
        filterList={{ date: true, range: true, property: true }}
      />

      <div className="row mt-3">
        <div className="col-md-12">
          {selectedTableItem.length > 0 && (
            <div className="table-delete-icon mt-3">
              <button
                onClick={() => setIsOpenBulkDeleteModal(true)}
                className="table-delete-btn next-btn-main"
              >
                <img src={trashIconWhite} alt="Delete" />
                Delete
              </button>
            </div>
          )}

          <div className="table-container">
            <CustomTable
              pagination={true}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              columns={columns}
              dataSource={filteredLandlords?.length ? filteredLandlords : data}
            />
          </div>
        </div>
      </div>

      {/* Delete Modal for single deletion */}
      {openDeleteModal && (
        <DeleteModal
          visible={openDeleteModal}
          onCancel={() => setOpenDeleteModal(false)}
          onConfirm={() => {
            handleDelete(deleteId);
            setOpenDeleteModal(false);
            setDeleteId(null);
          }}
        />
      )}

      {/* Bulk Delete Modal */}
      {isOpenBulkDeleteModal && (
        <DeleteModal
          visible={isOpenBulkDeleteModal}
          onCancel={() => setIsOpenBulkDeleteModal(false)}
          onConfirm={() => {
            setFilteredLandlords((prevFiles) =>
              prevFiles.filter((item) => !selectedTableItem.includes(item.key))
            );
            setIsOpenBulkDeleteModal(false);
            setSelectedTableItem([]);
          }}
        />
      )}
    </div>
  );
};

export default AllLandlords;
