import React, { useEffect, useState } from "react";
import config from "Helpers/config.js";
import { message } from "antd";
import Loader from "Helpers/Loader";
import { useNavigate } from "react-router-dom";
import Warning from "Components/GeneralComponents/Modals/Warning";
import QuestionsCountdown from "Components/GeneralComponents/QuestionsCountdown";
import SessionWarning from "Components/GeneralComponents/Modals/SessionWarning";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import { SubmitButton, SuccessModal } from "Components/GeneralComponents";
const TenantQuestions = ({ id, ssn }) => {
  console.log(
    "=================================Tenant Questions==================================="
  );
  console.log("ID", id);
  console.log("SSN", ssn);
  const [data, setData] = useState([]);
  const [value, setValues] = useState([]);
  const [options, setOptions] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [questionTimer, setQuestionTimer] = useState(false);
  const [sessionWarning, setSessionWarning] = useState(false);
  const [finalLoading, setFinalLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  // const { id, ssn, paid } = UseUrlParamsHook();
  const navigate = useNavigate();
  const onChange = (question, choiceKeyName) => {
    setValues({
      ...value,
      [question]: choiceKeyName,
    });
  };

  // Stripe
  const stripeKey = loadStripe(config.stripe_publish_key);

  const submitAnswer = () => {
    // setLoading(true);
    const answers = Object.keys(value).map((questionKeyName) => ({
      questionKeyName,
      selectedChoiceKeyName: value[questionKeyName],
    }));
    fetch(`${config.baseUrl}/api/renter/transunion/SubmitAnswers`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        answers,
        screeningId: id,
      }),
    })
      .then((res) => {
        // setLoading(false);

        return res.json();
      })
      .then((res) => {
        setLoading(false);

        if (res.apiCallStatus === "success") {
          message.success("Answer submitted successfully");
          setSuccessMessage(true);
          fetch(`${config.baseUrl}/api/stripe/capturePassportPayment`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }).then((res) => {
            console.log(res);
            return res.json();
          });
          // setLoading(false);

          //   setOptions({
          //     fetchClientSecret:res.message.data ,
          //     onComplete: submitAnswer,
          // });
          // navigate("/tenant-passport");
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        setLoading(false);

        console.log(err, "Error");
      });
  };

  const handleSendClick = () => {
    // setLoading(true);
    setFinalLoading(true);
    // CHECK IF ALL QUESTIONS ARE ANSWERED
    if (Object.keys(value).length !== data.length) {
      message.error("Please answer all questions");
      // setLoading(false);
      setFinalLoading(false);
      return;
    }
    submitAnswer();

    //Stripe Payment
    // if (paid === "true") {
    //     submitAnswer()
    // }
    // else {
    //     const stripCall = () => {
    //         return fetch(`${config.baseUrl}/api/renter/transunion/payNow`, {
    //             method: "POST",
    //             headers: {
    //                 "content-type": "application/json",
    //                 Authorization: `Bearer ${localStorage.getItem("token")}`,
    //             },
    //             body: JSON.stringify({
    //                 email: localStorage.getItem("email"),
    //                 // returnUrl: `${process.env.REACT_APP_ENVIRONMENT === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL}/tenant-passport`
    //             }),
    //         }).then((res) => res.json()).then((data) => data?.message?.clientSecret);
    //     };
    //     setOptions({
    //         fetchClientSecret: stripCall,
    //         onComplete: submitAnswer,
    //     });
    // }
  };
  const fetchQuestions = async () => {
    setLoading(true);
    fetch(`${config.baseUrl}/api/transunion/createExamRequest`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        screeningId: id,
        ssn: ssn,
      }),
    })
      .then((res) => {
        setLoading(false);
        return res.json();
      })
      .then((res) => {
        setLoading(false);
        if (res.apiCallStatus === "success") {
          setQuestionTimer(true);
          const { authenticationQuestions } = res.message.examRequest;
          setData(authenticationQuestions);
        } else {
          setError(res?.examRequest?.message);
          console.log(res);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.message);
        console.log(err, "Error");
      });
  };
  useEffect(() => {
    setLoading(true);
    fetchQuestions();
  }, []);

  const handleSessionTimeout = () => {
    setSessionWarning(true);
  };

  const handleRenewSession = () => {
    setSessionWarning(false);
    fetchQuestions();
  };
  const handleClose = () => {
    navigate("/tenant-passport");
  };
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      {error && (
        <Warning
          isOpen={error}
          message={error || "invalid error"}
          onClose={handleClose}
        />
      )}
      {data.length > 0 || !error ? (
        <>
          {sessionWarning && (
            <SessionWarning
              isOpen={sessionWarning}
              onClose={() => navigate(`/tenant-passport`)}
              confirmationCall={handleRenewSession}
              message={
                "Question Session Expired click below to generate a new session"
              }
              isLoading={false}
              disableBtn={false}
            />
          )}
           {successMessage && (
            <SuccessModal
              title={"Answer submitted successfully"}
              success={true}
              onClose={() => navigate(`/tenant-passport`)}
             
            />
          )}

          <div className="bg-white p-3">
            {questionTimer && questionTimer && (
              <QuestionsCountdown
                targetDate={Date.now() + 5 * 60 * 1000}
                handleSessionTimeout={handleSessionTimeout}
              />
            )}
            {options.fetchClientSecret ? (
              <div className="row mt-4">
                {/* <EmbeddedCheckoutProvider stripe={stripeKey} options={options}>
                                    <EmbeddedCheckout />
                                </EmbeddedCheckoutProvider> */}
                <h1>TransUnion Screening</h1>
              </div>
            ) : (
              <>
                <div className="d-flex flex-column gap-5">
                  {data.map((question, index) => {
                    return (
                      <div className="" key={index}>
                        <p className="fw-semibold">
                          {question.questionDisplayName}
                        </p>
                        <p>{question.choices.choiceDisplayName}</p>
                        {question.choices.map((choice, index) => {
                          return (
                            <React.Fragment key={index}>
                              <input
                                className="mx-2"
                                onChange={(e) =>
                                  onChange(
                                    question.questionKeyName,
                                    choice.choiceKeyName
                                  )
                                }
                                name={`answer-${question.questionKeyName}`}
                                value={choice.choiceKeyName}
                                type="radio"
                              />{" "}
                              <label htmlFor="">
                                {choice.choiceDisplayName}
                              </label>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
                <div className="stepper-first-btn d-flex justify-content-between mt-5">
                 
                  <SubmitButton
                    handler={handleSendClick}
                    isLoading={finalLoading}
                  >
                    Send
                  </SubmitButton>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default TenantQuestions;
