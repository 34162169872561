import React from "react";
import * as Components from "Components/GeneralComponents";

const FileForm = ({
  form,
  handleChange,
  errors,
  Images,
  setImages,
  properties,
  handleCancel,
  handleSubmit,
  isLoading,
}) => {
  return (
    <div className="container bg-white p-3 ">
      <div className="row">
        <div className="col-md-12">
          <Components.InputField
            required={true}
            name={"file_name"}
            placeholder={"File Name"}
            isLabel={true}
            label={"File Name"}
            value={form.file_name}
            handler={(e) => handleChange("file_name", e.target.value)}
            errorMessage={errors.file_name}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <div className="task-search-property-container position-relative">
            {properties ? (
              <Components.SelectPropertyField
                label={"Property"}
                placeholder={"Select Property"}
                PropertyData={properties}
                isRequired={true}
                onPropertySelect={(e) => {
                  handleChange("relatedToProperty", e.id);
                }}
                errorMessage={errors.relatedToProperty}
              />
            ) : (
              <Components.InputField
                required={true}
                name={"property"}
                placeholder={"Property"}
                isLabel={true}
                label={"Property"}
                value={"ABC Property"}
                handler={(e) => handleChange("property", e.target.value)}
                errorMessage={errors.property}
                disabled={true}
              />
            )}
          </div>
        </div>
      </div>
      <div className="row mt-3 text-center">
        <div className="col-md-12">
          <Components.MediaField
            required={true}
            label={"Upload Media"}
            setImages={setImages}
            Images={Images}
            errorMessage={errors.files}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between gap-1 mt-5">
        <div style={{ width: "50%" }}>
          <Components.SubmitButton type="contained" handler={handleCancel}>
            Cancel
          </Components.SubmitButton>
        </div>
        <div style={{ width: "50%" }}>
          <Components.SubmitButton handler={handleSubmit} isLoading={isLoading}>
            Submit
          </Components.SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default FileForm;
