import "../../style/payable-overview.css";
import img from "../../assets/default_payable_logo.png";
import PayableDatafield from "./PayableDatafield/PayableDatafield";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getInvoiceDetailsById } from "network/landlord/Account";
import Loader from "Helpers/Loader";
import { formatPhoneNumber } from "utils";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { SpinnerLoader } from "Components/GeneralComponents";
function PayableOverview() {
  const [invoiceData, setInvoiceData] = useState(null);
  const { invoiceId } = useParams(); // Get the 'id' from route parameters
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchInvoiceDetails = async () => {
      try {
        const response = await getInvoiceDetailsById(invoiceId);
        console.log("invoiceDetails", response);
        if (response.apiCallStatus === "success") {
          setInvoiceData(response.message.invoice);
        } else {
          console.error("Error fetching invoice:", response.error);
        }
      } catch (error) {
        console.error("An error occurred while fetching invoice:", error);
      }
    };

    fetchInvoiceDetails();
  }, [invoiceId]);
  const getFormattedDate = (date) => {
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    return new Date(date).toLocaleDateString("en-GB", options);
  };
  const getOriginalDueDate = (invoiceDate, dueDateText) => {
    // Convert the invoiceDate string to a Date object
    const parsedInvoiceDate = new Date(invoiceDate);
    console.log("Parsed Invoice Date:", invoiceDate);
    console.log(parsedInvoiceDate);

    if (!isNaN(parsedInvoiceDate)) {
      const daysToAdd = parseInt(dueDateText.match(/\d+/)[0]); // Extract number from text
      const dueDate = new Date(parsedInvoiceDate);
      dueDate.setDate(dueDate.getDate() + daysToAdd);
      console.log("Due Date:", dueDate.toISOString().slice(0, 10)); // Outputs: YYYY-MM-DD format
      return dueDate.toISOString().slice(0, 10); // Optional: return the formatted due date
    } else {
      console.error("Invalid invoiceDate format");
      return null;
    }
  };
  const handleDownload = async () => {
    setLoading(true)
    const invoiceCard = document.getElementById("invoice-card");
    if (invoiceCard) {
      const canvas = await html2canvas(invoiceCard);
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "pt", "a4");
      const width = pdf.internal.pageSize.getWidth();
      const height = (canvas.height * width) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, width, height);
      pdf.save(`Invoice_${invoiceData.invoice.invoiceNo}.pdf`);
    }
    setLoading(false)
  };

  const handlePrint = () => {
    const invoiceCard = document.getElementById("invoice-card");
    if (invoiceCard) {
      const originalContents = document.body.innerHTML;
      const invoiceContents = invoiceCard.innerHTML;
      document.body.innerHTML = invoiceContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload(); // Reload to reset page
    }
  };

  if (!invoiceData) {
    return <div className="mx-auto">
      <Loader />
    </div>;
  }

  return (
    <div className="container-fluid payable-overview py-2 mt-4">
      {/* buttons */}
      <div className="container-xl d-flex justify-content-end column-gap-3">
        <button
        onClick={handleDownload}
        disabled={loading}
        className="d-flex flex-row btn payable-overview-btn payable-overview-download-btn">
          {
            loading ? <span style={{display: "inline"}}><SpinnerLoader/></span> : <svg
            width="21"
            height="21"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.45 15V19C21.45 19.5304 21.2392 20.0391 20.8642 20.4142C20.4891 20.7893 19.9804 21 19.45 21H5.44995C4.91952 21 4.41081 20.7893 4.03574 20.4142C3.66066 20.0391 3.44995 19.5304 3.44995 19V15M7.44995 10L12.45 15M12.45 15L17.45 10M12.45 15V3"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          }
          <span className="ms-2">{loading ? 'Downloading...' : 'Download'}</span>
        </button>
        <button 
        onClick={handlePrint}
        className="btn payable-overview-btn payable-overview-print-btn">
          <svg
            width="21"
            height="21"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.94995 9V2H18.95V9M6.94995 18H4.94995C4.41952 18 3.91081 17.7893 3.53574 17.4142C3.16066 17.0391 2.94995 16.5304 2.94995 16V11C2.94995 10.4696 3.16066 9.96086 3.53574 9.58579C3.91081 9.21071 4.41952 9 4.94995 9H20.95C21.4804 9 21.9891 9.21071 22.3642 9.58579C22.7392 9.96086 22.95 10.4696 22.95 11V16C22.95 16.5304 22.7392 17.0391 22.3642 17.4142C21.9891 17.7893 21.4804 18 20.95 18H18.95M6.94995 14H18.95V22H6.94995V14Z"
              stroke="#667085"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="ms-2">Print</span>
        </button>
      </div>

      {/* invoice card */}
      <div id="invoice-card" className="container-xl px-0 my-4 py-3 payable-overview-invoice-card">
        <div
          className="
        payable-overview-ribbon"
        >
          <span>One Time Invoice</span>
        </div>
        {/* invoice from */}
        <div className="p-5 payable-overview-invoice-from">
          <div className="payable-overview-invoice-from-item d-flex mt-4 mt-xl-0 flex-column flex-xl-row row-gap-4 align-items-center">
            <h2 className="payable-overview-invoice-id">
              INVOICE :{" "}
              <span>
                {invoiceData?.invoice.invoiceNo.substring(
                  invoiceData?.invoice.invoiceNo.indexOf("INV")
                )}
              </span>
            </h2>
            <div>
              <img
                className="payable-overview-invoice-from-icon"
                src={img}
                alt=""
              />
            </div>
          </div>

          <div className="payable-overview-invoice-from-item d-flex mt-5 d-flex flex-column flex-md-row row-gap-4">
            <div className="d-flex justify-content-between flex-column flex-sm-row flex-md-column row-gap-4 w-100">
              <div className="payable-overview-invoice-field">
                <span>Invoice Date :</span>
                <span>{getFormattedDate(invoiceData.invoice.invoiceDate)}</span>
              </div>
              <div className="payable-overview-invoice-field">
                <span>Due Date :</span>
                <span>
                  {getFormattedDate(
                    getOriginalDueDate(
                      invoiceData.invoice.invoiceDate,
                      invoiceData.invoice.dueDate
                    )
                  )}
                </span>
              </div>
            </div>
            <div className="payable-overview-from-person">
              <h3>{invoiceData.company.fullName}</h3>
              <address>{invoiceData.company.address}</address>
              <p>{formatPhoneNumber(invoiceData.company.phone)}</p>
            </div>
          </div>
        </div>

        {/* divider */}
        <div className="payable-divider" />
        {/* invoice to */}
        <div className="p-5 row row-gap-4">
          <div className="col-12 col-xl d-xl-flex justify-content-center">
            <div className="w-100 payable-overview-property-name">
              <PayableDatafield
                fieldName={"Property"}
                fieldValue={
                  invoiceData.property.address
                }
              />
            </div>
          </div>

          <div className="col-12 col-md-6 col-xl d-xl-flex flex-xl-column align-items-center">
            <div className="d-flex flex-column row-gap-4">
              <PayableDatafield
                fieldName={"Tenant Name"}
                fieldValue={invoiceData.tenantName}
              />
              <PayableDatafield
                fieldName={"Bank Account"}
                fieldValue={invoiceData.stripeAccountDetails}
              />
            </div>
          </div>

          <div className="col-12 col-md-6 col-xl d-xl-flex justify-content-center">
            <div>
              <div className="payable-overview-invoice-field">
                <span>Total Amount :</span>
                <span className="fs-2">${invoiceData.total}</span>
              </div>
              <div className="payable-overview-due-date">
                Due Date:{" "}
                {getFormattedDate(
                  getOriginalDueDate(
                    invoiceData.invoice.invoiceDate,
                    invoiceData.invoice.dueDate
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        {/* invoice to product table */}
        <div className="p-5 payable-overview-table-container">
          <table className="payable-overview-table">
            <thead>
              <tr>
                <th scope="col">Product Name</th>
                <th scope="col">Description</th>
                <th scope="col">Category</th>
                <th scope="col">Amount</th>
              </tr>
            </thead>
            <tbody className="payable-overview-table-body">
              {invoiceData.invoice.description.map((item) => (
                <tr key={item.id}>
                  <td>{item.productName}</td>
                  <td>{item.description}</td>
                  <td>{item.category}</td>
                  <td>{item.amount}</td>
                </tr>
              ))}
            </tbody>
            <tfoot className="payable-overview-table-footer">
              <tr>
                <td></td>
                <td colSpan={2}>Subtotal</td>
                <td>${invoiceData.subtotal}</td>
              </tr>
              <tr>
                <td></td>
                <td colSpan={2}>Discount</td>
                <td>${invoiceData.discountAmount}</td>
              </tr>
              <tr>
                <td></td>
                <td colSpan={2}>Late Fees</td>
                <td>${invoiceData.lateFees}</td>
              </tr>
              <tr>
                <td></td>
                <td colSpan={2}>Other Amount</td>
                <td>${invoiceData.invoice.otherAmounts[0].amount}</td>
              </tr>
              <tr>
                <td></td>
                <td colSpan={2}>Total</td>
                <td>${invoiceData.total}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        {/* thank you */}
        <div className="px-5 py-2 payable-overview-footer">
          <p>Thank You For your Business!</p>
        </div>
      </div>
    </div>
  );
}

export default PayableOverview;
