import TenantReports from "Components/TenantReports/TenantReports";
import React from "react";

const ReportsTenant = () => {
  return (
    <div className="container-fluid">
      <TenantReports />
    </div>
  );
};

export default ReportsTenant;
