import React, { useState, useEffect } from "react";
import { message } from "antd";
import ModalLoader from "Components/GeneralComponents/ModalLoader";
import { updateTask, getAssignees } from "network/landlord/task";
import {
  Checkbox,
  DateField,
  SelectField,
  SelectVendorField,
  TextAreaField,
} from "Components/GeneralComponents";
import { selectTaskStatus, selectTaskPriority } from "utils/dropdownData";
import { formatDate } from "utils";
import { validatePostUpdateTask } from "utils/validations";

const PostUpdateModal = ({ onClose, id, data }) => {
  console.log("data", data)
  const [loader, setLoader] = useState(false);
  const [form, setForm] = useState({
    priority: "",
    due_date: "",
    status: "",
    inputDetails: "",
  });

  useEffect(() => {
    setForm({
      priority: data.priority || "",
      due_date: ((data.dueDate === "Invalid Date") ? ("") : (new Date(data?.dueDate).toLocaleDateString())) || "",
      status: data.status || "",
      inputDetails: "",
    });
  }, [data]);

  const [errors, setErrors] = useState({});
  const [notifyTenant, setNotifyTenant] = useState(false);
  const [notifyAssignee, setNotifyAssignee] = useState(false);
  const [vendor, setVendor] = useState([]);
  const [vendorData, setVendorData] = useState([]);

  useEffect(() => {
    getAssignees().then((res) => {
      if (res.apiCallStatus === "success") {
        setVendorData(res.message.data);
      }
    });
  }, []);

  // select box icon
  const handleForm = (formField, value) => {
    setForm((prev) => {
      return {
        ...prev,
        [formField]: value,
      };
    });
  };

  const handleDateChange = (field, e) => {
    const formattedDate = new Date(e).toLocaleDateString();
    handleForm(field, formattedDate);
  };

  const UpdateTask = () => {
    const newErrors = validatePostUpdateTask(form);
    setErrors(newErrors);

    const formData = new FormData();
    formData.append("dueDate", form.due_date);
    formData.append("status", form.status);
    formData.append("priority", form.priority);
    formData.append("notifyAssignee", notifyAssignee);
    formData.append("notifyTenant", notifyTenant);
    formData.append("inputDetails", form.inputDetails);
    vendor.forEach((item, index) => {
      formData.append(`assignedTo[${index}][_id]`, item.id);
      formData.append(`assignedTo[${index}][type]`, item.type);
    });

    console.log("formData >>>");
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setLoader(true);
      updateTask(id, formData).then((res) => {
        setLoader(false);
        if (res.apiCallStatus === "success") {
          message.success("Task updated successfully");
          console.log("Task updated successfully");
          onClose();
        }
      });
    }
  };

  return (
    <>
      <div className="modal-content-main-section p-41">
        <SelectVendorField
          label={"Assigned To"}
          placeholder={"Search here"}
          VendorData={vendorData}
          onVendorChange={(e) => {
            // console.log("Vendor >>>", e);
            setVendor(e);
            console.log("Vendor >>>", vendor);
          }}
          selectedVendors={data.assignees}
          errorMessage={errors.assignee}
          localStorageName={localStorage.getItem("name")}
        />
        <div className="row mt-3">
          <div className="col-md-6 text-start">
            <SelectField
              name={"priority"}
              required={true}
              label={"Priority"}
              placeholder={"Select Priority"}
              value={form.priority}
              options={selectTaskPriority}
              handler={(e) => handleForm("priority", e)}
              errorMessage={errors.priority}
            />
          </div>
          <div className="col-md-6 text-start">
            <DateField
              required={true}
              label={"Due Date"}
              placeholder={"Select Date"}
              defaultValue={form.due_date && formatDate(form.due_date)}
              handler={(e) => handleDateChange("due_date", e)}
              errorMessage={errors.due_date}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <SelectField
              name={"status"}
              required={true}
              label={"Status"}
              placeholder={"Select Status"}
              value={form.status}
              options={selectTaskStatus}
              handler={(e) => handleForm("status", e)}
              errorMessage={errors.status}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <TextAreaField
              name={"input details"}
              label={"Input Details"}
              placeholder={"Input Details"}
              handler={(e) => handleForm("inputDetails", e.target.value)}
              required={true}
              value={form.inputDetails}
              errorMessage={errors.inputDetails}
              rows={"5"}
              cols={"30"}
            />
          </div>
        </div>
      </div>
      <div className="modal-content-footer-section-scroll p-custom-post-update">
        <div className="row mt-3">
          <div className="col-md-12 d-flex justify-content-between">
            <Checkbox
              label={"Notify Assignee"}
              checked={notifyAssignee}
              onChange={(e) => setNotifyAssignee(e.target.checked)}
            />
            <Checkbox
              label={"Notify Tenant"}
              checked={notifyTenant}
              onChange={(e) => setNotifyTenant(e.target.checked)}
            />
            <div className="post-update-btn">
              <button
                onClick={() => {
                  UpdateTask();
                }}
                className="modal-post-update-btn"
              >
                Post Update{loader && <ModalLoader />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostUpdateModal;
