import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import Maintenance from "Components/Maintenance/Maintenance";
import NoAccessPage from "Pages/NoAccessMainPage/NoAccessPage";
import React from "react";

const MaintenancePage = () => {
  const storedRoles = localStorage.getItem("myUserRoles");
  const myUserRoles =
    storedRoles !== "undefined" ? JSON.parse(storedRoles) : [];
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar topBarTitle="Maintenance" />
      {!["Owner/Executive", "Property Manager", "Maintenance Technician"].some(
        (role) => myUserRoles?.includes(role)
      ) && myUserRoles?.length !== 0 ? (
        <NoAccessPage />
      ) : (
        <Maintenance />
      )}
    </div>
  );
};

export default MaintenancePage;
