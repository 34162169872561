import React, { useEffect, useRef, useState, useCallback } from "react";
import notificationIcon from "assets/bell-01.png";
import helpIcon from "assets/Help icon.png";
import { useNavigate, Link, NavLink } from "react-router-dom";
import "../hamburgers/dist/hamburgers.css";
import { useDispatch, useSelector } from "react-redux";
import { openDrawer } from "Store/Slices/SideDrawerSlice";
import moment from "moment";
import {
  getNotifications,
  markAllAsRead,
  markNotificationAsRead,
} from "network/tenant/notification";
import { TownHouse } from "assets/svg/index";
import { Avatar } from "antd";
import { useMediaQuery } from "@mui/material";
import Loader from "./Loader";
import { LeftArrow } from "assets/icons";

const TitleTopBar = ({ topBarTitle, topBarTitleDetail, back, vendorText,pop }) => {
  // States
  const [notifyData, setNotifyData] = useState([]);
  const [notifyDropdown, setNotifyDropdown] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const popupRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isHamburgerMenu = useMediaQuery("(max-width:1200px)");

  const fetchNotifications = useCallback(async () => {
    setIsLoading(true);
    const response = await getNotifications();
    setIsLoading(false);
    if (response?.apiCallStatus === "success") {
      const notifications = response?.message?.data || [];
      setNotifyData(notifications);
      setUnreadNotifications(notifications.filter((n) => !n.isRead).length);
    }
  }, []);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setNotifyDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => setNotifyDropdown((prev) => !prev);

  const handleMarkAllAsRead = async () => {
    const response = await markAllAsRead();
    if (response?.apiCallStatus === "success") {
      fetchNotifications();
    }
  };

  const handleMarkAsReadById = async (notificationId, redirectUrl) => {
    const response = await markNotificationAsRead(notificationId);
    if (response?.apiCallStatus === "success") {
      fetchNotifications();
      if (redirectUrl) {
        navigate(redirectUrl);
      }
    }
  };

  const drawer = useSelector((state) => state.sideDrawer.drawerState);
  const openDrawerHandler = () => dispatch(openDrawer());

  const redirecting = () => {
    if (pop) {
      navigate(-1);
    } else if(back){
       navigate(`/${back}`);
    }
  };

  return (
    <div
      className={`responsive-top-bar d-flex justify-content-between gap-3 ${
        vendorText ? "align-items-start" : "align-items-center"
      }`}>
      {isHamburgerMenu && (
        <div className="burger-menu">
          <button
            onClick={openDrawerHandler}
            className={`hamburger hamburger--elastic ${
              drawer ? "is-active" : ""
            }`}
            type="button">
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
      )}
      <div className="page-title-box ">
        <div className="top-heading-h1 d-flex align-items-center gap-2">
          {topBarTitleDetail && (
            <button
              onClick={redirecting}
              className="topbar-navigate-back-btn cursor">
              <LeftArrow  strokeWidth={2}/>
            </button>
          )}
          <h1 className="fw-bold top-bar-heading d-flex align-items-center">
            {topBarTitle}
            {topBarTitleDetail}
          </h1>
          {vendorText && (
            <p className="fw-medium top-bar-subheading m-0 line-clamp-2">
              {vendorText}
            </p>
          )}
        </div>
      </div>
      <div className="notification-box-container">
        <div className="dashboard-right-top-header d-flex align-items-center gap-2">
          <span className="help-icon">
            <NavLink to="/help">
              <img
                src={helpIcon}
                alt="Help"
              />
            </NavLink>
          </span>
          <div className="notify-icon position-relative cursor">
            <img
              onClick={toggleDropdown}
              src={notificationIcon}
              alt="Notifications"
            />
            <div
              ref={popupRef}
              className={
                notifyDropdown
                  ? "notification-dropdown-show"
                  : "notification-dropdown"
              }>
              <div className="notification-dropdown-header d-flex justify-content-between align-items-center">
                <p className="notification-left-heading">Notifications</p>
                <p
                  className="notification-right-heading"
                  onClick={handleMarkAllAsRead}>
                  Mark all as read
                </p>
              </div>
              <div className="notification-dropdown-second-header d-flex gap-3">
                <Link
                  to="/notification"
                  className="notification-left-second-heading">
                  All
                </Link>
                <p className="notification-right-second-heading">
                  Unread{" "}
                  <span className="unread-count">{unreadNotifications}</span>
                </p>
              </div>
              {isLoading ? (
                <div className="mb-5">
                  <Loader />
                </div>
              ) : (
                notifyData.slice(0, 4).map((item) => (
                  <div
                    key={item?._id}
                    onClick={() =>
                      handleMarkAsReadById(item?._id, item?.redirectUrl)
                    }
                    className={`notification-box${
                      item.isRead ? "" : "-unread"
                    } d-flex gap-1 position-relative`}>
                    <div className="notification-box-img d-flex gap-1">
                      <Avatar
                        src={TownHouse}
                        size="large"
                        style={{ border: "2px solid #E5E5E5" }}
                      />
                    </div>
                    <div className="notification-box-text">
                      <p className="mb-0 notification-text-one">
                        {item?.title}
                      </p>
                      <p className="mb-0 notification-text-second">
                        {item?.message}
                      </p>
                    </div>
                    <div
                      className="notification-box-time position-absolute top-0"
                      style={{ right: "10px" }}>
                      <span className="normal-grey-text">
                        {moment(item?.updatedAt).format("LT")}
                      </span>
                    </div>
                  </div>
                ))
              )}
              {notifyData.length > 4 && (
                <div className="text-center border-top p-2">
                  <Link
                    to="/notification"
                    className="text-dark fw-bold td">
                    See All Notifications
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitleTopBar;
