import React from "react";
import SignUpLeftBanner from "Components/SignUpLeftBanner/SignUpLeftBanner";
// import DigitalTenantLogo from "assets/Dt logo.png";
import { DTLogo } from "assets/icons";

const Authlayout = ({ loader, children }) => {
  return (
    <>
        {loader && (
          <div className="loader-container">
            <span className="loader"></span>
          </div>
        )}
        <div
          className="row background-image-login"
          // style={{ background: "black", height: "100vh" }}
        >
          <div className="height-issue col-xl-7 col-lg-12 col-md-12 p-0">
            <SignUpLeftBanner />
          </div>
          <div
            className="col-xl-5 col-lg-12 col-md-12 col-sm-12 d-flex pt-5 justify-content-center "
            style={{ background: "#fff", height: "100vh", overflow: "auto" }}
          >
            <div className="land-lord-sign-in pt-2 ps-4 pe-4 w-100 md:d-flex md:flex-column  md:align-items-center  ">
              <div className="onboarding-right-heading text-start mb-5">
                <div
                  className="logo-container text-center"
                  style={{ marginBottom: "100px" }}
                >
                  {/* <img
                    src={DigitalTenantLogo}
                    className="img-fluid "
                    style={{ width: "300px" }}
                    alt=""
                  /> */}
                  <DTLogo mode={""}/>
                </div>
              </div>
              <div className="w-100 float-center pb-5">{children}</div>
            </div>
          </div>
        </div>
    </>
  );
};

export default Authlayout;
