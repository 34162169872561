import React from "react";
import * as InnerComponent from "Components/GeneralComponents";
import Or from "assets/login-or-image.png";
import { LoginSocialFacebook } from "reactjs-social-login";
import { usaStates, selectPhoneType } from "utils/dropdownData";
import { useState } from "react";
import facebookIcon from "assets/fb login.png";
import googleIcon from "assets/google login.png";
import { Link } from "react-router-dom";
import { PasswordValidate } from "Components/TenantSetPassword";
import { checkPasswordCriteria } from "utils";
import * as Icons from "assets/icons";
import { googleAuthUrl } from "network/landlord/Auth";

const Signup = ({
  form,
  errors,
  handleChange,
  landLordSignUp,
  setAgree,
  resolveFacebook,
  rejectFacebook,
}) => {
  const [agreeTermsChecked, setAgreeTermsChecked] = useState(false);

  setAgree(agreeTermsChecked);

  const googleAuth = () => {
    googleAuthUrl();
  };

  return (
    <>
      <div className="land-lord-sign-up">
        <h1 className="mt-5">Register as a Landlord</h1>
        <div className="col-md-12 mt-3 not-a-member">
          Not a member yet?{" "}
          <Link className="primary-orange-text" to="/landlord/login">
            Login Now!
          </Link>
        </div>
        <Link className="primary-orange-text" to="/onboarding">
          <Icons.BackArrow />
          Back
        </Link>
        <div className="row">
          <div className="col-md-6 mt-3">
            <InnerComponent.InputField
              name={"First Name"}
              required={true}
              label={"First Name"}
              placeholder={"First Name"}
              value={form.Firstname}
              handler={(e) => handleChange("Firstname", e.target.value)}
              errorMessage={errors.Firstname}
            />
          </div>
          <div className="col-md-6 mt-3">
            <InnerComponent.InputField
              name={"Last Name"}
              required={true}
              label={"Last Name"}
              placeholder={"Last Name"}
              value={form.Lastname}
              handler={(e) => handleChange("Lastname", e.target.value)}
              errorMessage={errors.Lastname}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <InnerComponent.InputField
              name={"Email"}
              required={true}
              label={"Email"}
              placeholder={"Email"}
              value={form.Email}
              handler={(e) => handleChange("Email", e.target.value)}
              errorMessage={errors.Email}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mt-3">
            <InnerComponent.PasswordField
              name={"Password"}
              required={true}
              label="Create new Password"
              placeholder={"Password"}
              value={form.Password}
              handler={(e) => handleChange("Password", e.target.value)}
              errorMessage={errors.Password}
            />
          </div>
          <div className="col-md-6 mt-3">
            <InnerComponent.PasswordField
              name={"ConfirmPassword"}
              required={true}
              label="Confirm Password"
              placeholder={"Confirm Password"}
              value={form.ConfirmPassword}
              handler={(e) => handleChange("ConfirmPassword", e.target.value)}
              errorMessage={errors.ConfirmPassword}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mt-3">
            <InnerComponent.SelectField
              name={"Phone Type"}
              required={true}
              label={"Phone Type"}
              placeholder={"Select Phone Type"}
              value={form.PhoneType}
              options={selectPhoneType}
              handler={(e) => handleChange("PhoneType", e)}
              errorMessage={errors.PhoneType}
            />
          </div>
          <div className="col-md-6 mt-3">
            <InnerComponent.PhoneNumberField
              name={"Phone No"}
              required={true}
              label={"Phone No"}
              placeholder={"Phone No"}
              value={form.Phone}
              handler={(value) => handleChange("Phone", value)}
              errorMessage={errors.Phone}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <InnerComponent.InputField
              name={"Address"}
              required={true}
              label={"Address"}
              placeholder={"Address"}
              value={form.Address}
              handler={(e) => handleChange("Address", e.target.value)}
              errorMessage={errors.Address}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mt-3">
            <InnerComponent.SelectField
              name={"Country"}
              required={true}
              label={"Country"}
              placeholder={"Select Country"}
              value={"USA"}
              options={[{ value: "USA" }, { label: "Canada" }]}
              disabled={true}
            />
          </div>
          <div className="col-md-6 mt-3">
            <InnerComponent.InputField
              name={"City"}
              required={true}
              label={"City"}
              placeholder={"City"}
              value={form.City}
              handler={(e) => handleChange("City", e.target.value)}
              errorMessage={errors.City}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mt-3">
            <InnerComponent.SelectField
              name={"State"}
              required={true}
              label={"State"}
              placeholder={"Select State"}
              value={form.State}
              options={usaStates}
              handler={(e) => handleChange("State", e)}
              errorMessage={errors.State}
            />
          </div>
          <div className="col-md-6 mt-3">
            <InnerComponent.InputField
              name={"Postal code"}
              required={true}
              label={"Zip Code"}
              placeholder={"Zip Code"}
              value={form.Zipcode}
              handler={(e) => {
                if (/^\d{0,5}$/.test(e.target.value))
                  handleChange("Zipcode", e.target.value);
              }}
              errorMessage={errors.Zipcode}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <InnerComponent.InputField
              name={"BusinessName"}
              required={true}
              label={"Business Name"}
              placeholder={"Business Name"}
              value={form.BusinessName}
              handler={(e) => handleChange("BusinessName", e.target.value)}
              errorMessage={errors.BusinessName}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12 mt-3">
            <PasswordValidate validate={checkPasswordCriteria(form.Password)} />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12 mt-3">
            <div className="sign-up-terms-checkbox d-flex align-items-center">
              <input
                onChange={(e) => {
                  setAgreeTermsChecked(e.target.checked);
                }}
                type="checkbox"
                name=""
                id=""
                checked={agreeTermsChecked}
                className={agreeTermsChecked ? "checked" : ""}
              />{" "}
              <span>
                I agree to all the{" "}
                <span className="primary-orange-text">Terms</span> and{" "}
                <span className="primary-orange-text">Privacy policy </span>
              </span>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="sign-up-buttons d-flex justify-content-center">
              <InnerComponent.ButtonVariant1
                className="create-accoutn-btn w-100"
                handler={landLordSignUp}
              >
                Create Account
              </InnerComponent.ButtonVariant1>
            </div>
          </div>
        </div>
        <div className="remember-me-checkBox d-flex justify-content-center mt-4">
          <p className="text-end">
            <img src={Or} alt="" style={{ width: "100%" }} />
          </p>
        </div>
        <div className="row mb-5 ">
          <div className="col-md-12">
            <div className="sign-up-buttons d-flex gap-4 justify-content-center">
              <button onClick={googleAuth} className="google-signup-btn">
                <img src={googleIcon} alt="" style={{ width: "140px" }} />
              </button>
              <LoginSocialFacebook
                appId="1129610314899200"
                autoLoad={false}
                onResolve={(response) => resolveFacebook(response)}
                onReject={(error) => rejectFacebook(error)}
              >
                <button className="facebook-signup-btn">
                  <img src={facebookIcon} alt="" style={{ width: "140px" }} />
                </button>
              </LoginSocialFacebook>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
