import React from "react";
import { SpinnerLoader } from "../index";

const SubmitButton = ({ handler, isLoading = false, type="", children, disabled, className = ""}) => {
  return (
    <button
      onClick={isLoading ? () => {} : handler}
      className={`${
        type.toLowerCase() === "contained"
          ? "back-prev-btn"
          : "next-btn-same-class"
      } w-100 ${className}`}
      // apply disable styles
      style={{
        pointerEvents: isLoading || disabled ? "none" : "auto",
        opacity: isLoading || disabled ? 0.5 :
        1,
        textWrap: "nowrap",
      }}
      disabled={isLoading || disabled}
    >
      <span>{children}</span>
      {isLoading && <SpinnerLoader />}
    </button>
  );
};

export default SubmitButton;
