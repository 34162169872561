import React, { useEffect, useState } from "react";
import * as API from "network/landlord/lease";
import * as Component from "Components/GeneralComponents";

const VehicleInfoTab = ({ id }) => {
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [vehicalinfo, setVehicalinfo] = useState([]);

  useEffect(() => {
    setIsTableLoading(true);
    API.getLeaseById(id, { tab: "vehicles" }).then((res) => {
      if (res.apiCallStatus === "success") {
        setVehicalinfo(
          res.message.data?.map((e) => ({
            key: e._id,
            vehicaltype: e.vehicle_type ? e.vehicle_type : "---",
            make: e.make ? e.make : "---",
            model: e.model ? e.model : "---",
            year: e.year ? e.year : "---",
            color: e.color ? e.color : "---",
            licenseplate: e.license ? e.license : "---",
          }))
        );
        setIsTableLoading(false);
      }
    });
  }, []);

  const columns = [
    {
      title: "Vehicle Type",
      dataIndex: "vehicaltype",
      render: (text) => <span className="tenants_table_name_text">{text}</span>,
    },
    {
      title: "Make",
      dataIndex: "make",
      render: (text) => (
        <>
          <span className="tenants_table_properties_text">{text}</span>
        </>
      ),
    },
    {
      title: "Model",
      dataIndex: "model",
      render: (text) => (
        <>
          <span className="tenants_table_email_text">{text}</span>
        </>
      ),
    },
    {
      title: "Year",
      dataIndex: "year",
      render: (text) => (
        <>
          <span className="tenants_table_email_text">{text}</span>
        </>
      ),
    },
    {
      title: "Color",
      dataIndex: "color",
      render: (text) => (
        <>
          <span className="tenants_table_email_text">{text}</span>
        </>
      ),
    },
    {
      title: "License Plate",
      dataIndex: "licenseplate",
      render: (text) => (
        <>
          <span className="tenants_table_email_text">{text}</span>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="container-fluid bg-white">
        <div className="row">
          <div className="col-md-12">
            <Component.CustomTable
              loading={isTableLoading}
              pagination={true}
              columns={columns}
              dataSource={vehicalinfo}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default VehicleInfoTab;
