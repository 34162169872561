import React, { useState, useEffect } from 'react';
import { Button, Dropdown, message } from 'antd';
import { FilterRow, CustomTable, RecordMenuPopup } from 'Components/GeneralComponents';
import { EllipsisOutlined, EyeOutlined } from '@ant-design/icons';
import settingIcon from "assets/three-dots.png";
import trashIconWhite from "assets/trash-icon-white.png";
import { Link, useNavigate } from 'react-router-dom';


function CustomerQueries() {
    const navigate = useNavigate()
  const [filter, setFilter] = useState({
    search: '',
    fromDate: '',
    toDate: '',
    range: '',
    category: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedTableItem, setSelectedTableItem] = useState([]);
  const [key, setKey] = useState([]);

  const [isOpenBulkDeleteModal, setIsOpenBulkDeleteModal] = useState([]); //

  const handleIconClick = (result) => {
    // Toggle the dropdownOpen state
    const filterData = tableData.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  // Fetch mock data
  const fetchCustomerQueries = async () => {
    setIsLoading(true);
    setTimeout(() => {
      const mockData = [
        {
          key: 1,
          id: 1,
          customerName: 'Olivia Rhye',
          queryDetails: 'Youthful, adventurous and fun, treehouses hold a special place in our hearts...',
        },
        {
          key: 2,
          id: 2,
          customerName: 'Phoenix Baker',
          queryDetails: 'Youthful, adventurous and fun, treehouses hold a special place in our hearts...',
        },
        {
          key: 3,
          id: 3,
          customerName: 'Lana Steiner',
          queryDetails: 'Youthful, adventurous and fun, treehouses hold a special place in our hearts...',
        },
        {
          key: 4,
          id: 4,
          customerName: 'Demi Wilkinson',
          queryDetails: 'Youthful, adventurous and fun, treehouses hold a special place in our hearts...',
        },
      ];
      setTableData(mockData);
      setFilteredData(mockData);
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    fetchCustomerQueries();
  }, []);

  const handleFilter = ()=>{
    console.log("handleFilter: ", filter)
    message.info('Filters applied')
  }

  // Menu actions for the RecordMenuPopup
  const menuItems = [
    {
      key: 'view',
      label: (
        <div onClick={() => message.info('View query details')}>
          <EyeOutlined /> View
        </div>
      ),
    },
  ];

  // Table columns
  const columns = [
    {
      title: 'NAME',
      dataIndex: 'customerName',
      render: (text, record) => (
        <Link to={`/customer-queries/${record.id}`}>
          <span>{text}</span>
        </Link>
      ),
      
    },
    {
      title: 'QUERY DETAILS',
      dataIndex: 'queryDetails',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      render: (_, record) => (
        <div className="action-buttons">
          <Button
            type="primary"
           className="mx-2"
           style={{
            background: "#D92D20",
             borderRadius: "3px",
             marginBottom: "1rem",

           }}
           
             onClick={() => message.error(`Declined query from ${record.customerName}`)}
          >
            Decline
          </Button>
          <Button
            type="primary"
            className="mx-2"
            style={{
                background: "#EF6B3E",
                borderRadius: "3px"
            }}
            onClick={() => message.success(`Accepted query from ${record.customerName}`)}
          >
            Accept
          </Button>
          <RecordMenuPopup
            items={menuItems}
            trigger={['click']}
          >
            <Button
              icon={<EllipsisOutlined />}
              shape="circle"
            />
          </RecordMenuPopup>
        </div>
      ),
    },
    {
        title: "",
        dataIndex: "setting",
        render: (text, setting) => (
          <>
            <div
              className="position-relative cursor text-center"
              onClick={() => handleIconClick(setting.key)}
            >
              <img src={settingIcon} alt="" />
              <RecordMenuPopup
                isOpen={setting.key === key}
                onClose={() => setKey(null)}
                fields={{ view: true }}
                handler={{
                  handleView: () => navigate(`/customer-queries/${setting.id}`)
                }}
              />
            </div>
          </>
        ),
      },
  ];

  return (
    <div className="container-fluid bg-white p-3 ">
      <FilterRow
        filter={filter}
        setFilter={setFilter}
        filterList={{
            date: true,
            range: true
        }}
        handleSubmit={handleFilter}
        handleReset={() => setFilter({ search: '', fromDate: '', toDate: '', range: '', category: '' })}
      />
     
        <div className="row mt-3">
          <div className="col-md-12">
            {selectedTableItem.length >= 1 && (
              <div className="table-delete-icon mt-3">
                <button
                  onClick={() => setIsOpenBulkDeleteModal(true)}
                  className="table-delete-btn next-btn-main"
                >
                  <img src={trashIconWhite} alt="" />
                  Delete
                </button>
              </div>
            )}
            <div className="table-container">
              <CustomTable
                loading={isLoading}
                pagination
                columns={columns}
                dataSource={filteredData}
                rowSelection={{
                  type: 'checkbox',
                  onChange: (selectedRowKeys, selectedRows) => {
                    setSelectedTableItem(selectedRows.map((item) => item.id));
                  },
                }}
              />
            </div>
          </div>
        </div>
    </div>
  );
}

export default CustomerQueries;
