import React, { useEffect } from "react";
import {
  CustomTable,
  DeleteModal,
  RecordMenuPopup,
} from "Components/GeneralComponents";
import { getLeaseUnit } from "network/landlord/property";
import searchIcon from "assets/search.png";
import whiteDot from "assets/_white-Dot.png";
import { useNavigate, Link, useParams } from "react-router-dom";
import { VerticalElipsisIcon } from "assets/icons";
import { deleteLease } from "network/landlord/lease";
import trashIconWhite from "assets/trash-icon-white.png";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";

const LeasesTab = () => {
  const { id, property_id } = UseUrlParamsHook();
  const navigate = useNavigate();
  const [leaseData, setLeaseData] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [key, setKey] = React.useState(null);
  const [isDeleteRender, setIsDeleteRender] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState("");
  const [selectionType, setSelectionType] = React.useState("checkbox");
  const [selectedTableItem, setSelectedTableItem] = React.useState([]);

  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleIconClick = (result) => {
    const filterData = leaseData.filter((item) => {
      return item.key === result;
    });
    setKey(filterData.key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  useEffect(() => {
    getLeaseUnit(id, property_id).then((res) => {
      if (res.apiCallStatus === "success") {
        console.log("Lease Data: ", res);
        const data = [res.message.data];
        console.log("Data: ", data);
        setLeaseData(
          data
            .filter((e) =>
              e.property.title.toLowerCase().includes(search.toLowerCase())
            )
            .map((e) => ({
              key: e.id,
              property: e.property.title,
              date: [
                new Date(e.lease_term_start_date).toLocaleDateString(),
                new Date(e.lease_term_end_date).toLocaleDateString(),
              ],
              term: e.lease_term,
              rent: `$${e.rent_amount.toLocaleString()}`,
              deposit: e.deposit_amount,
              balance: "$1000",
              status: `Active`,
            }))
        );
      }
    });
  }, [search]);

  const columns = [
    {
      title: "Lease",
      dataIndex: "property",
      render: (text, property) => (
        <Link to={`/lease-detail?id=${property.key}`}>
          <span className="all-lease-property-text">{text}</span>
        </Link>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (text) => (
        <>
          <span className="all-lease-date-text">{text[0]}</span>
          <br />
          <span className="all-lease-date-text">{text[1]}</span>
        </>
      ),
    },
    {
      title: "Term",
      dataIndex: "term",
      render: (text, term) => (
        <>
          <div className="table-status-bar lease-status">
            <>
              <img src={whiteDot} alt="" /> <span>{text}</span>
            </>
          </div>
        </>
      ),
    },
    {
      title: "Rent",
      dataIndex: "rent",
    },
    {
      title: "Deposit",
      dataIndex: "deposit",
      render: (text) => (
        <>
          <span>{`$${text}`}</span>
        </>
      ),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      render: (text) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, status) => (
        <>
          {status.key === "1" && (
            <>
              <p className="expire-label pb-0 mb-0"></p>
              <span className="about-to-expire-text">About to Expire</span>
              <br />
              <span className="error-text ">23 days remaining</span>
            </>
          )}
          <>
            <p className="active-label pb-0 mb-0"></p>
            <span className="primary-orange-text">{text}</span>
          </>
          {status.key === "3" && (
            <>
              <p className="expired-label pb-0 mb-0"></p>
              <span className="expired-text">Expired</span>
            </>
          )}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="task-table-setting-container position-relative cursor"
            onClick={() => {
              handleIconClick(setting.key);
            }}
          >
            <VerticalElipsisIcon width={23} height={23} />
            <RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleView: () => {
                  navigate(`/lease-detail?id=${setting.key}`);
                },
                handleRenewLease: () => {
                  navigate(`/renew-lease?id=${setting.key}`);
                },
                handleDeleteRecord: () => {
                  setDeleteId(setting.key);
                  onOpenDeleteModal();
                },
              }}
              fields={{
                view: true,
                renewLease: true,
                delete: true,
              }}
            />
          </div>
        </>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTableItem([...selectedRowKeys]);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  useEffect(() => {
    if (deleteId) {
      setIsLoading(true);
      deleteLease(deleteId).then((res) => {
        if (res.apiCallStatus === "success") {
          setIsLoading(false);
          setIsDeleteRender(!isDeleteRender);
        }
      });
      console.log("deleteId", deleteId);
    }
  }, [isDeleteRender]);

  return (
    <div>
      <DeleteModal
        isOpen={openDeleteModal}
        onClose={onCloseDeleteModal}
        message={"Are you sure you want to delete this lease?"}
        deleteCall={() => setIsDeleteRender(!isDeleteRender)}
        isLoading={isLoading}
      />
      <div className="row mb-3">
        <div className="col-md-12">
          <div className="task-search-input position-relative">
            <input
              type="text"
              placeholder="Search"
              className="form-control search-form-control-task"
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="search-icon-task">
              <img src={searchIcon} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="task-table-container mt-3">
        {selectedTableItem.length !== 0 && (
          <div className="table-delete-icon mb-3">
            <button
              // onClick={() => setIsDeleteTenantModal(true)}
              className="table-delete-btn next-btn-main"
            >
              <img src={trashIconWhite} alt="" />
              Delete
            </button>
          </div>
        )}
        <CustomTable
          // rowSelection={{
          //   type: selectionType,
          //   ...rowSelection,
          // }}
          columns={columns}
          dataSource={leaseData}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default LeasesTab;
