import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import GoogleAuth from "Hooks/GoogleAuth";
import SignupForm from "./SignUpForm";
import * as component from "Components/GeneralComponents";
import { LandlorSignupvalidationforom } from "utils/validations";
import * as API from "network/landlord/Auth";

const LandLordSignUp = () => {
  const [agree, setAgree] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [facebookLogin, setFacebookLogin] = useState(null);
  const [form, setForm] = useState({
    Firstname: "",
    Lastname: "",
    Email: "",
    Password: "",
    ConfirmPassword: "",
    Phone: "",
    PhoneType: "",
    City: "",
    State: "",
    Zipcode: "",
    Country: "USA",
    BusinessName: "",
    Address: "",
  });

  const handleChange = (fieldName, value) => {
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  const resolveFacebook = (response) => {
    console.log(response);
    setFacebookLogin(response.data);
    console.log(facebookLogin);
  };

  const rejectFacebook = (error) => {
    console.log(error);
  };

  // Navigation State
  const navigate = useNavigate();

  const formData = {
    fname: form.Firstname,
    lname: form.Lastname,
    email: form.Email,
    password: form.Password,
    cpassword: form.ConfirmPassword,
    phone: form.Phone,
    phoneType: form.PhoneType,
    locality: form.City,
    region: form.State,
    postalCode: form.Zipcode,
    country: "USA",
    businessName: form.BusinessName,
    address: form.Address,
  };

  function register() {
    API.signup(formData)
      .then((res) => {
        setLoader(false);
        if (res.apiCallStatus === "success") {
          message.success("Account created Successfuly");
          const userId = res.message._id;
          localStorage.setItem("token", res.message.token);
          localStorage.setItem("role", "landlord");
          localStorage.setItem("email", form.Email);
          localStorage.setItem("user_id", userId);
          localStorage.setItem("name", `${form.Firstname} ${form.Lastname}`);
          navigate(`/landlord/register/subscription?email=${form.Email}&id=${userId}`);
        } else {
          message.error(res.error.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  }

  const landLordSignUp = () => {
    const newErrors = LandlorSignupvalidationforom(form);
    setErrors(newErrors);
    console.log("New Errors >>", newErrors);
    if (Object.keys(newErrors).length === 0 && agree) {
      setLoader(true);
      register();
    }
  };

  const googleAuth = () => {
    GoogleAuth("landlord");
  };

  return (
    <>
      <component.AuthLayout loader={loader}>
        <SignupForm
          form={form}
          handleChange={handleChange}
          errors={errors}
          landLordSignUp={landLordSignUp}
          googleAuth={googleAuth}
          setAgree={setAgree}
          setFacebookLogin={setFacebookLogin}
          resolveFacebook={resolveFacebook}
          rejectFacebook={rejectFacebook}
        />
      </component.AuthLayout>
    </>
  );
};
export default LandLordSignUp;