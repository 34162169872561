import React, { useEffect, useState, useRef } from "react";
import FileUploader from "Components/FileUploader/FileUploader";
import {
  usaStates,
  propertyStatus,
  selectMultiColumns,
  residentialPropertyType,
  commercialPropertyType,
} from "utils/dropdownData";
import {
  AmountField,
  InputField,
  SelectField,
  SelectMultipleColumns,
  SubmitButton,
  TextAreaField,
} from "Components/GeneralComponents";
import { getProperty, updateProperty } from "network/landlord/property";
import { validateEditPropertyDetails } from "utils/validations";

const AddPropertyEditModal = ({ onClose, id }) => {
  const [Images, setImages] = useState([]);
  const [DeletedImages, setDeletedImages] = useState([]);
  const [loader, setLoader] = useState(false);
  const [propertyType, setPropertyType] = useState("");
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [form, setForm] = useState({
    title: "",
    property_sub_type: "",
    status: "",
    amenities: [],
    bedroom: "",
    bathroom: "",
    area: "",
    rent: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    description: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (fieldName, value) => {
    if (fieldName === "amenities") {
      setForm({
        ...form,
        amenities: value,
      });
    } else {
      setForm({
        ...form,
        [fieldName]: value,
      });
    }
  };

  useEffect(() => {
    getProperty(id).then((res) => {
      if (res.apiCallStatus === "success") {
        const data = res.message;
        setForm({
          title: data.title || "",
          property_sub_type: data.property_sub_type || "",
          status: data.status || "",
          amenities: data.amenities || [],
          bedroom: data.bedroom || "",
          bathroom: data.bathroom || "",
          area: data.area || "",
          rent: data.rent_amount || "",
          address: data.address.address_line_1 || "",
          address2: data.address.address_line_2 || "",
          city: data.address.city || "",
          state: data.address.state || "",
          zipCode: data.address.zipcode.toString() || "",
          description: data.description || "",
        });
        setImages(data.images);
        setPropertyType(data.property_type);
        setSelectedAmenities(data.amenities);
      }
    });
  }, []);

  // Add Tasks
  const EditProperty = () => {
    const formData = new FormData();
    formData.append("title", form.title);
    formData.append("property_sub_type", form.property_sub_type);
    formData.append("status", form.status);
    formData.append("address[address_line_1]", form.address);
    formData.append("address[address_line_2]", form.address2);
    formData.append("address[zipcode]", form.zipCode);
    formData.append("address[state]", form.state);
    formData.append("address[city]", "USA");
    formData.append("description", form.description);
    formData.append("bedroom", form.bedroom);
    formData.append("bathroom", form.bathroom);
    formData.append("area", form.area);
    formData.append("rent_amount", form.rent);
    form.amenities.forEach((amenity, index) => {
      formData.append(`amenities[${index}]`, amenity);
    });
    Images?.forEach((img, index) => {
      formData.append(`images[${index}]`, img);
    });
    if (DeletedImages.length > 0) {
      DeletedImages?.forEach((img, index) => {
        formData.append(`__delete_files[${[index]}]`, img);
      });
    }
    const newErrors = validateEditPropertyDetails(form);
    setErrors(newErrors);

    console.log("newErrors", newErrors);
    console.log("form", form);

    if (Object.keys(newErrors).length > 0) {
      return;
    }

    setLoader(true);
    updateProperty(id, formData).then((res) => {
      setLoader(false);
      if (res.apiCallStatus === "success") {
        onClose();
      }
    });
  };

  return (
    <>
      <div className="modal-content-main-section p-41">
        <div className="row mt-3">
          <div className="col-md-12">
            <InputField
              name={"title"}
              required={true}
              label={"Property Title"}
              placeholder={"Property Title"}
              value={form.title}
              handler={(e) => handleChange("title", e.target.value)}
              errorMessage={errors.title}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            {propertyType === "residential" ? (
              <SelectField
                name={"property_sub_type"}
                required={true}
                label={"Property Type"}
                placeholder={"Property Type"}
                value={form.property_sub_type}
                options={residentialPropertyType}
                handler={(e) => handleChange("property_sub_type", e)}
                errorMessage={errors.property_sub_type}
              />
            ) : (
              <SelectField
                name={"property_sub_type"}
                required={true}
                label={"Property Type"}
                placeholder={"Property Type"}
                value={form.property_sub_type}
                options={commercialPropertyType}
                handler={(e) => handleChange("property_sub_type", e)}
                errorMessage={errors.property_sub_type}
              />
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <SelectField
              name={"status"}
              required={true}
              label={"Property Status"}
              placeholder={"Property Status"}
              value={form.status}
              options={propertyStatus}
              handler={(e) => handleChange("status", e)}
              errorMessage={errors.status}
            />
          </div>
        </div>
        <div className="mt-3">
          <SelectMultipleColumns
            label={"Select Amenities"}
            placeholder={"Select Amenities"}
            values={form.amenities}
            selectedAmenities={selectedAmenities}
            options={selectMultiColumns}
            showMaxItems={1}
            handleChange={(e) => handleChange("amenities", e)}
          />
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <InputField
              name={"bedroom"}
              required={true}
              type="number"
              label={"Bedrooms"}
              placeholder={"Bedrooms"}
              value={form.bedroom}
              handler={(e) => handleChange("bedroom", e.target.value)}
              errorMessage={errors.bedroom}
            />
          </div>
          <div className="col-md-6">
            <InputField
              name={"bathroom"}
              required={true}
              type="number"
              label={"Bathrooms"}
              placeholder={"Bathrooms"}
              value={form.bathroom}
              handler={(e) => handleChange("bathroom", e.target.value)}
              errorMessage={errors.bathroom}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <InputField
              name={"area"}
              required={true}
              type="number"
              label={"SqFt"}
              placeholder={"SqFt"}
              value={form.area}
              handler={(e) => handleChange("area", e.target.value)}
              errorMessage={errors.area}
            />
          </div>
          <div className="col-md-6">
            <AmountField
              name={"rent"}
              required={true}
              label={"Rent Amount"}
              placeholder={"Enter Rent Amount"}
              value={{ amount: form.rent }}
              handler={(value) => handleChange("rent", value.amount)}
              errorMessage={errors.rent}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <InputField
              name={"address"}
              required={true}
              label={"Address Line 1"}
              placeholder={"Address Line 1"}
              value={form.address}
              handler={(e) => handleChange("address", e.target.value)}
              errorMessage={errors.address}
            />
          </div>
          <div className="col-md-6">
            <InputField
              name={"address2"}
              label={"Address Line 2"}
              placeholder={"Address Line 2"}
              value={form.address2}
              handler={(e) => handleChange("address2", e.target.value)}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-3">
            <SelectField
              name={"country"}
              required={true}
              label={"Country"}
              placeholder={"Country"}
              value={"USA"}
              options={[{ value: "USA" }, { label: "Canada" }]}
              handler={(e) => handleChange("country", e)}
              errorMessage={errors.country}
              disabled={true}
            />
          </div>
          <div className="col-md-3">
            <InputField
              name={"city"}
              required={true}
              label={"City"}
              placeholder={"City"}
              value={form.city}
              handler={(e) => handleChange("city", e.target.value)}
              errorMessage={errors.city}
            />
          </div>
          <div className="col-md-3">
            <SelectField
              name={"state"}
              required={true}
              label={"State"}
              placeholder={"Select State"}
              value={form.state}
              options={usaStates}
              handler={(e) => handleChange("state", e)}
              errorMessage={errors.state}
            />
          </div>
          <div className="col-md-3">
            <InputField
              name={"Postal code"}
              required={true}
              label={"Zip Code"}
              placeholder={"Zip Code"}
              value={form.zipCode}
              handler={(e) => {
                if (/^\d{0,5}$/.test(e.target.value))
                  handleChange("zipCode", e.target.value);
              }}
              errorMessage={errors.zipCode}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <TextAreaField
              name={"description"}
              label={"Description"}
              placeholder={"Add your property description here"}
              value={form.description}
              handler={(e) => handleChange("description", e.target.value)}
              rows={"10"}
              cols={"30"}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <span className="property-details-input-title">
              Properties Images
            </span>
            <div className="dragger ">
              <FileUploader
                setImages={setImages}
                Images={Images}
                setDeletedImages={setDeletedImages}
                DeletedImages={DeletedImages}
              />
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-7"></div>
          <div className="col-md-5">
            <SubmitButton handler={EditProperty} isLoading={loader}>
              Save
            </SubmitButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPropertyEditModal;
