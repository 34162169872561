import React, { useEffect, useState } from "react";
import { formatDate } from "utils";
import { TaskFormData } from "utils/FormDataStructure";
import { validateCreateTaskForm } from "utils/validations";
import * as Component from "Components/GeneralComponents";
import * as Options from "utils/dropdownData";
import { getActiveProperties, getProperties } from "network/landlord/property";
import {
  getAssignees,
  updateTask,
} from "network/landlord/task";

const radioOptions = [
  { value: false, label: "One Time Task" },
  { value: true, label: "Recurring Task" },
];

const EditTaskForm = ({ id, onClose, data }) => {
  console.log("Edit Task Form ID", id);
  const [Images, setImages] = useState([]);
  const [propertyDropdown, setpropertyDropdown] = useState(false);
  const [loader, setloader] = useState(false);
  const [vendor, setVendor] = useState([]);
  const [errors, setErrors] = useState({});
  const [propertyData, setPropertyData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [loadUnit, setLoadUnit] = useState(true);
  const [form, setForm] = useState({
    property: {
      propertyId: "",
      unitId: "",
    },
    title: "",
    due_date: "",
    status: "Not Started",
    priority: "",
    description: "",
    start_date: "",
    end_date: "",
    frequency: "",
    recurring: false,
    isNotifyAssignee: false,
    isNotifyTenant: false,
  });

  useEffect(() => {
    console.log("Data", data);
    setForm({
      property: {
        propertyId: data.property,
        unitId: data.unit,
      },
      title: data.title,
      due_date: new Date(data?.dueDate).toLocaleDateString(),
      status: data.status,
      priority: data.priority,
      description: data.description,
      start_date: "",
      end_date: "",
      frequency: "",
      recurring: false,
      isNotifyAssignee: false,
      isNotifyTenant: false,
    });
  }, [data]);

  const handleChange = (fieldName, value) => {
    console.log(fieldName, value);
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  useEffect(() => {
    getProperties().then((res) => {
      if (res.apiCallStatus === "success") {
        setPropertyData(
          res.message.map((e) => ({
            id: e.id,
            heading1: "",
            heading2: `${e.address.address_line_1}, ${e.address.address_line_2}, ${e.address.city}, ${e.address.state}, ${e.address.country}, ${e.address.zipcode}`,
            heading3: e.title,
            units: e.units.map((unit) => ({ id: unit.id, name: unit.name })),
          }))
        );
      }
    });
    getAssignees().then((res) => {
      if (res.apiCallStatus === "success") {
        setVendorData(res.message.data);
      }
    });
  }, []);

  // Handle property change
  const handlePropertyChange = (e) => {
    loadUnit ? handleChange("property", { propertyId: e.id, unitId: data.unitId }) : handleChange("property", { propertyId: e.id, unitId: "" });
    setUnitData(e.units);
    loadUnit && setLoadUnit(false);
  };

  const UpdateTask = () => {
    const formData = TaskFormData(form, Images, vendor);
    const newErrors = validateCreateTaskForm(form, vendor);
    setErrors(newErrors);

    console.log("My Errors", newErrors);

    if (Object.keys(newErrors).length === 0) {
      setloader(true);
      updateTask(id, formData).then((res) => {
        setloader(false);
        console.log(res);
        onClose();
      });
    }
  };

  const handleDateChange = (field, e) => {
    const formattedDate = new Date(e).toLocaleDateString();
    handleChange(field, formattedDate);
  };

  return (
    <>
  <div className="modal-content-main-section p-3">
    <Component.SelectPropertyField
      label={"Property"}
      placeholder={"Select Property"}
      isRequired={true}
      PropertyData={propertyData}
      category={"tenant"}
      errorMessage={errors.property}
      selectedPropertyId={form.property.propertyId}
      onPropertySelect={(selectedProperty) => {
        handlePropertyChange(selectedProperty);
        setpropertyDropdown(false);
      }}
    />

    <div className="row mt-3">
      <div className="col-12">
        <Component.SelectField
          name={"unit"}
          required={true}
          label={"Unit"}
          TooltipTitle={"Unit can't be selected without selecting property first."}
          placeholder={"First select property then unit"}
          value={form.property.unitId}
          options={unitData.map((e) => ({ value: e.id, label: e.name }))}
          handler={(e) =>
            handleChange("property", { ...form.property, unitId: e })
          }
          errorMessage={errors.unit}
          disabled={unitData.length === 0}
        />
      </div>
    </div>

    <Component.SelectVendorField
      label={"Assigned To"}
      placeholder={"Search here"}
      VendorData={vendorData}
      selectedVendors={data.assignees}
      onVendorChange={(e) => {
        setVendor(e);
      }}
      errorMessage={errors.assignee}
      isRequired={true}
      localStorageName={localStorage.getItem("name")}
    />
  </div>

  <div className="p-3">
    <div className="row mt-3">
      <div className="col-12">
        <Component.InputField
          name={"title"}
          required={true}
          label={"Title"}
          placeholder={"Title"}
          value={form.title}
          handler={(e) => handleChange("title", e.target.value)}
          errorMessage={errors.title}
        />
      </div>
    </div>

    <div className="task-modal-scroll">
      <div className="row mt-3">
        <div className="col-12 col-md-4">
          <Component.DateField
            required={true}
            label={"Due Date"}
            placeholder={"Select Date"}
            defaultValue={form.due_date}
            handler={(e) => handleDateChange("due_date", e)}
            errorMessage={errors.due_date}
          />
        </div>
        <div className="col-12 col-md-4">
          <Component.SelectField
            name={"status"}
            required={true}
            label={"Status"}
            placeholder={"Select Status"}
            value={form.status}
            options={Options.selectTaskStatus}
            handler={(e) => handleChange("status", e)}
            errorMessage={errors.status}
          />
        </div>
        <div className="col-12 col-md-4">
          <Component.SelectField
            name={"priority"}
            required={true}
            label={"Priority"}
            placeholder={"Select Priority"}
            value={form.priority}
            options={Options.selectTaskPriority}
            handler={(e) => handleChange("priority", e)}
            errorMessage={errors.priority}
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <Component.TextAreaField
            name={"description"}
            required={true}
            label={"Description"}
            placeholder={"Description"}
            value={form.description}
            handler={(e) => handleChange(e.target.name, e.target.value)}
            rows={5}
            cols={20}
            errorMessage={errors.description}
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <Component.RadioGroupField
            value={form.recurring}
            handler={(value) => handleChange("recurring", value)}
            options={radioOptions}
            direction="row"
          />
        </div>
      </div>

      {form.recurring && (
        <div className="row mt-3">
          <div className="col-12 col-md-4">
            <Component.DateField
              required={true}
              label={"Start Date"}
              placeholder={"Select Date"}
              defaultValue={form.start_date && formatDate(form.start_date)}
              handler={(e) => handleDateChange("start_date", e)}
              errorMessage={errors.start_date}
            />
          </div>
          <div className="col-12 col-md-4">
            <Component.DateField
              required={true}
              label={"End Date"}
              placeholder={"Select Date"}
              defaultValue={form.end_date && formatDate(form.end_date)}
              handler={(e) => handleDateChange("end_date", e)}
              errorMessage={errors.end_date}
            />
          </div>
          <div className="col-12 col-md-4">
            <Component.SelectField
              name={"frequency"}
              required={true}
              label={"Frequency"}
              placeholder={"Select Frequency"}
              value={form.frequency}
              options={Options.selectFrequency}
              handler={(e) => handleChange("frequency", e)}
              errorMessage={errors.frequency}
            />
          </div>
        </div>
      )}

      <div className="row mt-3">
        <div className="col-12">
          <Component.MediaField
            label={"Upload Media"}
            setImages={setImages}
            Images={Images}
          />
        </div>
      </div>
    </div>
  </div>

  <div className="modal-content-footer-section-scroll p-custom">
    <div className="row mt-3">
      <div className="col-12 d-flex justify-content-between responsive-direction-column">
        <div className="modal-check-box-container d-flex align-items-center">
          <input
            onChange={(e) => handleChange("isNotifyAssignee", e.target.checked)}
            type="checkbox"
            checked={form.isNotifyAssignee}
            className="me-2"
          />
          <span className="me-2 task-checkbox-text">Notify Assignee</span>
          <input
            onChange={(e) => handleChange("isNotifyTenant", e.target.checked)}
            type="checkbox"
            checked={form.isNotifyTenant}
            className="ms-2"
          />
          <span className="task-checkbox-text">Notify Tenant</span>
        </div>
        <div className="col-12 col-md-4">
          <Component.SubmitButton handler={UpdateTask} loader={loader}>
            Save
          </Component.SubmitButton>
        </div>
      </div>
    </div>
  </div>
</>

  );
};

export default EditTaskForm;
