import {
  InputField,
  SubmitButton,
  TextAreaField,
} from "Components/GeneralComponents";
import React from "react";
import arrowLeft from "assets/chevron-left.png";

const GeneralInfo = ({
  form,
  handleChange,
  errors,
  handleNext,
  handlePrev,
}) => {
  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row mt-3">
          <div className="col-md-12">
            <InputField
              handler={(e) => handleChange("role", e.target.value)}
              label="Role Name"
              value={form.role}
              type="text"
              name={"role"}
              placeholder={"Role Name"}
              errorMessage={errors.role}
              required={true}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <TextAreaField
              handler={(e) => handleChange("description", e.target.value)}
              label="Role Description"
              value={form.description}
              name={"description"}
              placeholder={"Role Description"}
              errorMessage={errors.description}
              required={true}
              rows={"8"}
              cols={"30"}
            />
          </div>
        </div>
        <div className="row mt-5">
          <button onClick={handlePrev} className="back-prev-btn">
            <img src={arrowLeft} />
            Cancel{" "}
          </button>
          <div className="col-md-6">
            <SubmitButton handler={handleNext}>Next</SubmitButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralInfo;
