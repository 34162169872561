import axios from "axios";
import config from "Helpers/config.js";

const fetchClientSecret = async (data) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/stripe/createNewSubscriber`,
      data,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const fetchLandlordPlans = async () => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/landlord/plans`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const UpdateSubscription = async (data) => {
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/stripe/updateSubscription`,
      data,
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// SUBSCRIPTION ROUTES
// router.post('/subscribe', subscriptionController.subscribe);  // For subscribing to a new plan
// router.post('/upgrade', subscriptionController.upgrade);  // For upgrading a plan
// router.post('/downgrade', subscriptionController.downgrade);  // For downgrading a plan
// router.get('/current', subscriptionController.getCurrentSubscription);  // Get current subscription
// router.post('/trial-end', subscriptionController.endTrial);  // Handle end of trial
// router.get('/plans', subscriptionController.getPlans);  // Get all plans
// router.get('/checkSubscriptionStatus', subscriptionController.checkSubscriptionStatus);  // Check subscription status
// router.post('/cancel', subscriptionController.cancelSubscription);  // Cancel subscription

const makedefaultPaymentMethod = async (data) => {
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/stripe/makeDefaultPaymentMethod`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getSubscriptionPlans = async () => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/stripe/plans`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const subscribe = async (data) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/stripe/subscribe`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const upgrade = async (data) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/stripe/upgrade`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const downgrade = async (data) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/stripe/downgrade`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getCurrentSubscription = async () => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/stripe/current`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const endTrial = async (data) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/stripe/trial-end`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const checkSubscriptionStatus = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/stripe/checkSubscriptionStatus`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const cancelSubscription = async () => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/stripe/cancel`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export {
  fetchClientSecret,
  fetchLandlordPlans,
  UpdateSubscription,
  makedefaultPaymentMethod,
  getSubscriptionPlans,
  subscribe,
  upgrade,
  downgrade,
  getCurrentSubscription,
  endTrial,
  checkSubscriptionStatus,
  cancelSubscription,
};
