import { InputField, PhoneNumberField, SelectField, SubmitButton } from "Components/GeneralComponents";
import React, { useState, useEffect } from "react";
import { updateStepInDatabase } from 'network/tenant/passport';

const StandardQuestions = ({
  form,
  errors,
  handleChange,
  handleNext,
  handlePrev,
}) => {
  const [individualRows, setIndividualRows] = useState([]);

  console.log("handle Chage: ",handleChange)
  // Add new row, capped by the selected `individuals` count
  const addRow = () => {
    if (individualRows.length < form.individuals) {
      setIndividualRows([...individualRows, { fullName: "", phone: "" }]);
    }
  };

  // Update individual row data for each input field
  const handleRowChange = (index, field, value) => {
    const updatedRows = [...individualRows];
   
    updatedRows[index][field] = value;
    setIndividualRows(updatedRows);
    handleChange("individualsInformation", updatedRows)
  };

  // Adjust the number of rows based on the `individuals` count
  useEffect(() => {
    if (individualRows.length > form.individuals) {
      setIndividualRows(individualRows.slice(0, form.individuals));
    }
  }, [form.individuals, individualRows]);

  const handleSubmit  = async()=>{
    await updateStepInDatabase(8,form)

    handleNext()
  }

  return (
    <div>
      {/* Section Title */}
      <div style={{
        fontFamily: "Montserrat",
        fontWeight: "600",
        fontSize: "20px",
        lineHeight: "24px",
        color: "#344054",
      }}>
        Standard Questions
      </div>

      <div style={{
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#344054A1",
      }} className="mt-3">
        Please answer to the best of your ability all of the questions below.
      </div>

      {/* Select Fields */}
      <div className="row mt-3">
        <div className="col-md-12">
          <SelectField
            name={"pets"}
            label={"How many pets will be living in the unit with you?"}
            placeholder={"Select options"}
            options={[...Array(11).keys()].map(num => ({ value: num, label: num }))}
            value={form.pets}
            handler={(e) => handleChange("pets", e)}
            errorMessage={errors.pets}
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-12">
          <SelectField
            name={"people"}
            label={"How many people will be living in the unit with you?"}
            placeholder={"Select options"}
            options={[...Array(11).keys()].map(num => ({ value: num, label: num }))}
            value={form.people}
            handler={(e) => handleChange("people", e)}
            errorMessage={errors.people}
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-12">
          <SelectField
            name={"individuals"}
            label={"How many total individuals will be applying for this unit, including yourself?"}
            placeholder={"Select options"}
            options={[...Array(11).keys()].map(num => ({ value: num, label: num }))}
            value={form.individuals}
            handler={(e) => handleChange("individuals", e)}
            errorMessage={errors.individuals}
          />
        </div>
      </div>

      {/* Full Name and Phone Input for Main Applicant */}
      {/* <div className="row">
        <div className="col-md-6 mt-3">
          <InputField
            name={"Full Name"}
            required={true}
            label={"Full Name"}
            placeholder={"Full Name"}
            value={form.fullName}
            handler={(e) => handleChange("fullName", e.target.value)}
            errorMessage={errors.fullName}
          />
        </div>
        <div className="col-md-6 mt-3">
          <PhoneNumberField
            name={"Phone No"}
            required={true}
            label={"Phone No"}
            placeholder={"Phone No"}
            value={form.phone}
            handler={(e) => handleChange("phone", e)}
            errorMessage={errors.phone}
          />
        </div>
      </div> */}

      {/* Optional Individuals' Information Section */}
      <div className="mt-3">
        <label className="form-label">Individuals' Information</label>
        {individualRows.map((individual, index) => (
          <div className="row" key={index}>
            <div className="col-md-6 mt-2">
              <InputField
                name={`fullName_${index}`}
                label={`Full Name ${index + 1}`}
                placeholder={"Full Name"}
                value={individual.fullName}
                handler={(e) => handleRowChange(index, "fullName", e.target.value)}
                errorMessage={errors[`fullName_${index}`]}
              />
            </div>
            <div className="col-md-6 mt-2">
              <PhoneNumberField
                name={`phone_${index}`}
                label={`Phone No ${index + 1}`}
                placeholder={"Phone No"}
                value={individual.phone}
                handler={(e) => handleRowChange(index, "phone", e)}
                errorMessage={errors[`phone_${index}`]}
              />
            </div>
          </div>
        ))}

        {/* Add Individual Button */}
        {individualRows.length < form.individuals && (
          <button type="button" className="btn btn-link mt-2" onClick={addRow}>
            + Add Individual
          </button>
        )}
      </div>

      {/* Navigation Buttons */}
      <div className="stepper-btn d-flex justify-content-between mt-5 gap-1">
        <div style={{ width: "50%" }}>
          <SubmitButton type="contained" handler={handlePrev}>
            Back
          </SubmitButton>
        </div>
        <div style={{ width: "50%" }}>
          <SubmitButton handler={handleSubmit}>Next</SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default StandardQuestions;
