import React, { useState } from "react";
import GoogleAuth from "Hooks/GoogleAuth";
import { useNavigate } from "react-router-dom";
import Authlayout from "Components/GeneralComponents/Layout/Authlayout";
import { message } from "antd";
import { serviceProfessionalSignIn } from "utils/validations";
import { login } from "network/serviceProfessional/auth";
import LoginForm from "./LoginForm";

const ServiceProfessionalSignIn = () => {
  // States starts
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [facebookLogin, setFacebookLogin] = useState(null);
  const [form, setForm] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});

  // States end

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    const newError = serviceProfessionalSignIn(form);
    setErrors(newError);

    if (Object.keys(newError).length === 0) {
      setLoader(true);
      login(form).then((res) => {
        setLoader(false);
        if (res.apiCallStatus === "success") {
          message.success("Login Successful");
          if (res.message.userRole) {
            localStorage.setItem("role", "user");
            localStorage.setItem("user_role", res.message.userRole.role);
          } else {
            localStorage.setItem("role", res.message.role);
          }

          localStorage.setItem("name", res.message.name);
          localStorage.setItem("user_id", res.message._id);
          localStorage.setItem("token", res.message.token);
          localStorage.setItem("email", res.message.email);

          setTimeout(() => {
            window.location.replace("/");
          }, 200);
        } else {
          console.log(res);
          message.error(res.message);
        }
      });
    }
  };
  const googleAuth = () => {
    GoogleAuth("professional");
  };

  const resolveFacebook = (responce) => {
    console.log(responce);
    setFacebookLogin(responce.data);
  };
  const rejectFacebook = (error) => {
    console.log(error);
  };
  return (
    <>
      <Authlayout loader={loader}>
        <LoginForm
          form={form}
          errors={errors}
          googleAuth={googleAuth}
          resolveFacebook={resolveFacebook}
          rejectFacebook={rejectFacebook}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      </Authlayout>
    </>
  );
};

export default ServiceProfessionalSignIn;
