import Notification from "Components/Notification/Notification";
import TitleTopBar from "Helpers/TitleTopBar";
import React, { useEffect, useState } from "react";
import config from "Helpers/config.js";
import axios from "axios";
import Loader from "Helpers/Loader";
import { TownHouse } from "assets/svg/index";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";

const NotificationPage = () => {
  const [groupedNotifications, setGroupedNotifications] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const notificationData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${config.baseUrl}/api/notifications`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        setLoading(false);
        const notifications = response?.data?.message?.data;
        setGroupedNotifications(groupNotificationsByDate(notifications));
      } catch (error) {
        console.error("Failed to fetch notifications:", error);
      }
    };

    notificationData();
  }, []);

  // Function to group notifications
  const groupNotificationsByDate = (notifications) => {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const getFormattedDate = (date) => {
      const options = {
        weekday: "short",
        day: "numeric",
        month: "short",
        year: "numeric",
      };
      return new Date(date).toLocaleDateString("en-GB", options);
    };

    return notifications.reduce((acc, notification) => {
      const notificationDate = new Date(notification.createdAt);
      const dateString = getFormattedDate(notification.createdAt);

      // Check if the notification is from today
      if (notificationDate.toDateString() === today.toDateString()) {
        acc["Today"] = acc["Today"] || [];
        acc["Today"].push(notification);
      }
      // Check if the notification is from yesterday
      else if (notificationDate.toDateString() === yesterday.toDateString()) {
        acc["Yesterday"] = acc["Yesterday"] || [];
        acc["Yesterday"].push(notification);
      }
      // Otherwise, use the formatted date as the key
      else {
        acc[dateString] = acc[dateString] || [];
        acc[dateString].push(notification);
      }

      return acc;
    }, {});
  };

  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar topBarTitle="Notification" />
      {loading ? (
        <Loader />
      ) : (
        <div className="flex-fill mt-5">
          {Object.keys(groupedNotifications).map((dateGroup) => (
            <div key={dateGroup}>
              <span
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "16px",
                  padding: "20px 0px 10px 20px",
                  color: "#667085",
                }}>
                {dateGroup}
              </span>
              {groupedNotifications[dateGroup].map((notificationsData) => (
                <Notification
                  key={notificationsData?._id}
                  profileImage={TownHouse}
                  title={notificationsData?.title}
                  message={notificationsData?.message}
                  createdAt={notificationsData?.createdAt}
                  redirectUrl={notificationsData?.redirectUrl}
                  _id={notificationsData?._id}
                />
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NotificationPage;
