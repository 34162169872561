import React from "react";

const SearchButton = ({ handler, disabled, children }) => {
  return (
    <button 
        onClick={handler} 
        className={`${disabled ? "add-new-task-btn cursor-not-allowed opacity-50" : "add-new-task-btn"}`}
        disabled={disabled}
    >
      {children}
    </button>
  );
};

export default SearchButton;
