import React, { useEffect, useState } from "react";
import "../../style/notification.css";
import { convertTimestamp } from "../../utils";
import Pusher from "pusher-js";
import { useDispatch } from "react-redux";
import { updateNotifications } from "Store/Slices/Notifications";
import config from "Helpers/config.js";
import notificationTune from "assets/sounds/notification_tune.wav";
import { TownHouse } from "assets/svg/index";

const NotificationComponent = () => {
  const dispatch = useDispatch();
  const [isNotification, setIsNotification] = useState(false);
  const [notification, setNotification] = useState({});

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      encrypted: true,
    });

    const channel = pusher.subscribe(`user-${localStorage.getItem("user_id")}`);
    channel.bind("push-notification", (data) => {
      console.log("Notification Data: ", data);
      if (data.soundVibration) {
        const notificationSound = new Audio(notificationTune);
        notificationSound.play().catch((error) => {
          console.error("Error playing notification sound:", error);
        });
      }
      setNotification(data);
      setIsNotification(true);

      setTimeout(() => {
        setIsNotification(false);
        setNotification({});
      }, 5000);
    });

    return () => {
      pusher.unsubscribe(`user-${localStorage.getItem("user_id")}`);
    };
  }, []);

  useEffect(() => {
    if (isNotification) {
      fetch(`${config.baseUrl}/api/notifications`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }).then((res) => {
        const response = res.json();
        dispatch(updateNotifications(response.message));
      });
    }
  }, [isNotification]);

  return (
    <>
      {isNotification && (
        <>
          <div className="push-notification-container">
            <div className="notification-content-container">
              <div className="push-notification-img-container">
                <img
                  src={TownHouse}
                  alt="Notification Icon"
                  id="notification-img"
                />
              </div>
              <div className="notification-content">
                <div className="push-notification-title-container">
                  <span className="push-notification-title" id="notification-title">
                    {notification.title}
                  </span>
                </div>
                <div
                  className="push-notification-content"
                  id="notification-message"
                >
                  {notification.message}
                </div>
              </div>
            </div>
            <div className="notification-time" id="notification-time">
              {convertTimestamp(notification.createdAt)}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default NotificationComponent;
