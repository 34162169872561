import React from "react";

const Close = ({width=18, height=18}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="#667085"
      stroke="#667085"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18 6 6 18" />
      <path d="m6 6 12 12" />
    </svg>
  );
};

export default Close;
