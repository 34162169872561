import AddVendorDetails from "Components/AddVendorDetails/AddVendorDetails";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import React from "react";

const VendorsNew = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail="Add Vendor Details"
        pop={true}
      />
      <AddVendorDetails />
    </div>
  );
};

export default VendorsNew;
