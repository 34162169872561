import React from "react";
import "style/OptionCard.css";

const PersonItemCard = ({ imgSrc, name }) => {
  return (
    <div className="d-flex align align-items-center">
      <div className="profile">
        <img src={imgSrc} alt="Profile" className="profile-img" />
      </div>
      <div className="name">{name}</div>
    </div>
  );
};

export default PersonItemCard;
