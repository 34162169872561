import AddAccount from "Components/AddAccount/AddAccount";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import React from "react";

const AccountsAdd = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Add Account"}
        pop={true}
      />
      <AddAccount />
    </div>
  );
};

export default AccountsAdd;
