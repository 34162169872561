import EditPayable from "Components/EditPayable/EditPayable";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const PayableEdit = () => {
  return (
    <div className="col-xl-10 col-lg-12 col-md-12 mt-3">
      <Fixedtopbar
        topBarTitleDetail="Edit Payment"
        back="payment"
      />
      <EditPayable />
    </div>
  );
};

export default PayableEdit;
