import axios from "axios";
import config from "Helpers/config.js";

const signup = async (payload) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/auth/serviceprovider/signup`,
      payload,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};


const login = async (payload) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/auth/serviceprovider/login`,
      payload,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export { signup, login};