import { useCallback, useEffect, useState } from "react";
import { message } from "antd";
import PurchaseUnit from "Modals/PurchaseUnit/PurchaseUnit";
import config from "Helpers/config.js";
import * as InnerComponent from "./index";
import { validatePropertyDetails, validateUnitInfo } from "utils/validations";
import { createProperty, purchaseUnits } from "network/landlord/property";
import { FormModalLayout, Stepper, SuccessModal } from "Components/GeneralComponents";
import { useNavigate } from "react-router-dom";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getRemainingUnits, updatePaidUnit } from "network/landlord/property";
import { addPropertyDetails } from "utils/stepsData";
import { set } from "date-fns";

const AddPropertyDetails = () => {
  // Choose Property Type states
  const navigate = useNavigate();
  const [unitValue, setUnitValue] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedPropertyType, setSelectedPropertyType] = useState("");
  const [isPurchaseModelOpen, setIsPurchaseModelOpen] = useState(false);
  const stripeKey = loadStripe(config.stripe_publish_key);
  const [sessionId, setSessionId] = useState("");
  const [checkoutSuccess, setCheckoutSuccess] = useState(false);
  const [purchaseError, setPurchaseError] = useState("");

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSelectedPropertyType("");
  };

  const handlePropertyTypeClick = (propertyType) => {
    setSelectedPropertyType(propertyType);
  };

  // States start
  const [currentStep, setCurrentStep] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [Images, setImages] = useState([]);
  const [loader, setLoader] = useState(false);
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [remainingUnits, setRemainingUnits] = useState(0);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    getRemainingUnits().then((res) => {
      if (res.apiCallStatus === "success") {
        setRemainingUnits(res.message.remainingPaidUnits);
      }
    });
  }, []);

  const [form, setForm] = useState({
    title: "",
    status: "",
    amenities: [],
    bedroom: "",
    bathroom: "",
    area: "",
    rent: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    description: "",
  });
  const [unitInfo, setUnitInfo] = useState({
    propertyUnit: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (fieldName, value) => {
    if (fieldName === "amenities") {
      setForm({
        ...form,
        amenities: value,
      });
    } else {
      setForm({
        ...form,
        [fieldName]: value,
      });
    }
  };

  const handleUnitInfoChange = (fieldName, value) => {
    setUnitInfo({
      ...unitInfo,
      [fieldName]: value,
    });
    // if (fieldName === "propertyUnit") {
    //   remainingUnits - value < 0
    //     ? setUnitValue(value - remainingUnits)
    //     : setUnitValue(0);
    // }
  };
  // States end

  // Modal Function
  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
    navigate("/properties-dashboard");
  };

  const onCloseModalWorkOrder = () => {
    setOpenAccountModal(false);
  };
  // Create Property

  const CreateProperty = () => {
    const formData = new FormData();
    // console.log("form", form);
    formData.append("title", form.title);
    formData.append("status", form.status);
    formData.append("address[address_line_1]", form.address);
    formData.append("address[address_line_2]", form.address2);
    formData.append("address[zipcode]", parseInt(form.zipCode));
    formData.append("address[state]", form.state);
    formData.append("address[city]", form.city);
    formData.append("address[country]", "USA");
    formData.append("description", form.description);
    formData.append("bedroom", form.bedroom);
    formData.append("bathroom", form.bathroom);
    formData.append("area", form.area);
    formData.append("rent_amount", form.rent.amount);
    formData.append("unit", unitInfo.propertyUnit);
    formData.append("property_type", selectedCategory);
    formData.append("property_sub_type", selectedPropertyType);
    Images.forEach((img) => {
      formData.append("images", img);
    });
    // console.log("form Amenities", form);
    form.amenities.forEach((amenity, i) => {
      formData.append(`amenities[${[i]}]`, amenity);
    });

    setLoader(true);
    createProperty(formData).then((res) => {
      setLoader(false);
      if (res.apiCallStatus === "success") {
        onOpenModal();
      } else {
        message.error(res.message);
        setOpenAccountModal(true);
      }
    });
  };
  // Stepper Function
  const handleNext = () => {
    if (currentStep === 1) {
      if (selectedCategory && selectedPropertyType) {
        setCurrentStep(currentStep + 1);
      } else {
        message.error("Please select property type to continue");
      }
    }

    if (currentStep === 2) {
      const newErrors = validatePropertyDetails(
        form,
        selectedCategory === "commercial"
      );
      if (Object.keys(newErrors).length === 0) {
        setErrors({});
        setCurrentStep(currentStep + 1);
      } else {
        setErrors(newErrors);
      }
    }
    if (currentStep === 3) {
      const newErrors = validateUnitInfo(unitInfo);
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        setErrors({});
        CreateProperty();
      }
    }
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const fetchClientSecret = useCallback(async () => {
    const res = await purchaseUnits({
      quantity: unitValue,
    });
    console.log("Subscribe Response", res);
    const Session = res.message.clientSecret;
    setSessionId(res.message.sessionId);

    return Session;
  }, [unitValue]);

  useEffect(() => {
    if (sessionId) {
      if (checkoutSuccess) {
        updatePaidUnit({ sessionId }).then((res) => {
          if (res.apiCallStatus === "success") {
            getRemainingUnits().then((res) => {
              if (res.apiCallStatus === "success") {
                setRemainingUnits(res.message.remainingPaidUnits);
              }
            });
          } else {
            console.error("Update Paid Unit failed:", res.message);
          }
          setOpenAccountModal(false);
        });
        setCheckoutSuccess(false);
      }
    }
  }, [sessionId, checkoutSuccess]);

  const handleCheckoutCompletion = async () => {
    setCheckoutSuccess(true);
  };

  const options = { fetchClientSecret, onComplete: handleCheckoutCompletion };

  const handlePurchaseUnit = () => {
    if (unitValue <= 0) {
      setPurchaseError("Unit value is required");
      return;
    }
    setPurchaseError("");
    setIsPurchaseModelOpen(true);
  }

  return (
    <>
      {openAccountModal && (
        <PurchaseUnit
          onClose={() => setOpenAccountModal(false)}
          setUnitValue={setUnitValue}
          callBack={handlePurchaseUnit}
          value={unitValue}
          errorMessage={purchaseError}
        />
      )}
      {openModal === true && (
        <SuccessModal
          heading={"Property Added Successfully"}
          title={
            "Your property has been successfully added. Check your properties"
          }
          route={"all-accounts"}
          success={"Add Bank Account"}
          onClose={onCloseModal}
        />
      )}
      <div className="container-fluid bg-white p-3">
          <div className="container">
          <Stepper
            steps={addPropertyDetails}
            activeStep={currentStep - 1}
            lockStep={false}
            maxSteps={4}
            setIsDraft={(isDraft) => console.log("Draft status:", isDraft)}
          />

          <div className="stepper-content-container mt-4 add-property-details-stepper-content">
            {currentStep === 1 && (
              <InnerComponent.PropertyTypeForm
                selectedCategory={selectedCategory}
                selectedPropertyType={selectedPropertyType}
                handleCategoryClick={handleCategoryClick}
                handlePropertyTypeClick={handlePropertyTypeClick}
                handleNext={handleNext}
              />
            )}
            {currentStep === 2 && (
              <InnerComponent.PropertyDetailsForm
                form={form}
                handleChange={handleChange}
                errors={errors}
                Images={Images}
                setImages={setImages}
                handlePrev={handlePrev}
                handleNext={handleNext}
                choosePropertyTypeCommercial={selectedCategory === "commercial"}
                // windowWidth={windowWidth}
              />
            )}
            {currentStep === 3 && (
              <InnerComponent.UnitInformationForm
                form={unitInfo}
                handleChange={handleUnitInfoChange}
                errors={errors}
                isSubmitForm={true}
                handlePrev={handlePrev}
                handleNext={handleNext}
                remainingUnits={remainingUnits}
                setOpenAccountModal={setOpenAccountModal}
                isLoader={loader}
              />
            )}
          </div>
        </div>
      </div>
      <FormModalLayout
        isOpen={isPurchaseModelOpen}
        onClose={() => setIsPurchaseModelOpen(false)}
      >
        <EmbeddedCheckoutProvider stripe={stripeKey} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </FormModalLayout>
    </>
  );
};

export default AddPropertyDetails;
