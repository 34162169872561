import React from "react";

const Stepper = ({ currentStep }) => {
  return (
    <div className="stepper-container step-container-responsive">
      <div
        className={
          currentStep === 1 || currentStep === 2
            ? "step-1 stepper-active"
            : "step-1 stepper-inactive"
        }
      >
        1 <div className="form-step-text">General Info</div>
      </div>
      <div
        className={
          currentStep === 2
            ? "step-2 stepper-active"
            : "step-2 stepper-inactive"
        }
      >
        2 <div className="form-step-text">Permission Level</div>
      </div>
      <div className="lines-property">
        <div className={`line ${currentStep === 2 ? "active" : ""}`}></div>
      </div>
    </div>
  );
};

export default Stepper;
