import React, { useEffect, useState } from "react";
import { message } from "antd";
import {
  validateLeaseInfoForm,
  validateLeaseTenantsForm,
  validateLeaseDocumentForm,
} from "utils/validations";
import { leaseFormData } from "utils/FormDataStructure";
import { createLease, updateLease } from "network/landlord/lease";
import * as InnerComponents from "./index";
import TempModal from "Modals/TempModal/TempModal";
import { addCommercialLeaseDetails, addLeaseDetails } from "utils/stepsData";
import { Stepper } from "Components/GeneralComponents";

const NewLease = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [errors, setErrors] = useState({});
  const [selectedPropertyType, setSelectedPropertyType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [leaseFiles, setLeaseFiles] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [prospectReplacedModal, setProspectReplacedModal] = useState(false);
  const [leaseId, setLeaseId] = useState("");
  const [form, setForm] = useState({
    property: {
      id: "",
      heading1: "",
      heading2: "",
      heading3: "",
    },
    unit: "",
    lease_term: "",
    lease_term_start_date: "",
    lease_term_end_date: "",
    first_rent_date: "",
    frequency: "",
    rent_amount: {
      amount: "",
    },
    security_deposit: "",
    security_amount: {
      amount: "",
    },
    late_fee: "",
    late_fee_charge: "",
    late_fee_frequency: "",
    late_fees_amount: {
      amount: "",
      type: "$",
    },
    renewLease: "",
    tenant: {
      _id: "",
      name: "",
      email: "",
      type: "",
    },
    coTenants: [],
  });
  const [tenantEmails, setTenantEmails] = useState([]);
  const [dependentInfo, setDependentInfo] = useState([
    {
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      email: "",
      phone: "",
      relationship: "",
      description: "",
    },
  ]);
  const [pets, setPets] = useState([
    {
      petType: "",
      petName: "",
      petWeight: "",
      petBreed: "",
    },
  ]);
  const [vehicles, setVehicles] = useState([
    {
      vehicleType: "",
      vehicleMake: "",
      vehicleModel: "",
      vehicleYear: "",
      vehicleColor: "",
      vehicleLicense: "",
    },
  ]);
  const [documentInfo, setDocumentInfo] = useState({
    isSigned: "",
    newLeaseSign: "",
    documentId: "",
    documentName: "",
    documentUrl: "",
    documentStatus: "",
    invites: [],
  });

  const handleChange = (fieldName, value, index) => {
    if (fieldName === "coTenant") {
      const newCoTenants = [...form.coTenants];
      newCoTenants[index] = value;
      setForm({
        ...form,
        coTenants: newCoTenants,
      });
      return;
    }
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  useEffect(() => {
    if (form.security_deposit === "No") {
      setForm((prevForm) => ({
        ...prevForm,
        security_amount: {
          ...prevForm.security_amount,
          amount: "",
        },
      }));
    }
  }, [form.security_deposit]);

  useEffect(() => {
    if (form.late_fee === "No") {
      setForm((prevForm) => ({
        ...prevForm,
        late_fee_charge: "",
        late_fee_frequency: "",
        late_fees_amount: {
          ...prevForm.late_fees_amount,
          amount: "",
        },
      }));
    }
  }, [form.late_fee]);

  const handleDocumentInfoChange = (fieldName, value) => {
    // console.log("Document Object ----->>", fieldName, value);

    if (fieldName === "object") {
      setDocumentInfo((prevDocumentInfo) => ({
        ...prevDocumentInfo,
        documentId: value.documentId,
        documentName: value.documentName,
        documentStatus: value.documentStatus,
        documentUrl: value.documentUrl,
      }));
      return;
    } else if (fieldName === "invites") {
      setDocumentInfo((prevDocumentInfo) => ({
        ...prevDocumentInfo,
        invites: value,
      }));
      return;
    }
    setDocumentInfo((prevDocumentInfo) => ({
      ...prevDocumentInfo,
      [fieldName]: value,
    }));
  };

  const handlePetChange = (field, index, value) => {
    const newPets = [...pets];
    newPets[index][field] = value;
    setPets(newPets);
  };

  const handleDependentChange = (field, index, value) => {
    const newDependents = [...dependentInfo];
    newDependents[index][field] = value;
    setDependentInfo(newDependents);
  };

  const addAnotherPet = () => {
    setPets([
      ...pets,
      { petType: "", petName: "", petWeight: "", petBreed: "" },
    ]);
  };

  const removePet = (indexToRemove) => {
    if (indexToRemove !== 0) {
      const updatedPets = pets.filter((pet, index) => index !== indexToRemove);
      setPets(updatedPets);
    }
  };

  const addAnotherDependent = () => {
    setDependentInfo([
      ...dependentInfo,
      {
        firstName: "",
        middleName: "",
        lastName: "",
        dob: "",
        email: "",
        phone: "",
        relationship: "",
        description: "",
      },
    ]);
  };

  const removeDependent = (indexToRemove) => {
    if (indexToRemove !== 0) {
      const updatedDependents = dependentInfo.filter(
        (dependent, index) => index !== indexToRemove
      );
      setDependentInfo(updatedDependents);
    }
  };

  const handleVehicleChange = (field, index, value) => {
    const newVehicles = [...vehicles];
    newVehicles[index][field] = value;
    setVehicles(newVehicles);
  };

  const addAnotherVehicle = () => {
    setVehicles([
      ...vehicles,
      {
        vehicleType: "",
        vehicleMake: "",
        vehicleModel: "",
        vehicleYear: "",
        vehicleColor: "",
        vehicleLicense: "",
      },
    ]);
  };

  const removeVehicle = (indexToRemove) => {
    if (indexToRemove !== 0) {
      const updatedVehicles = vehicles.filter(
        (vehicle, index) => index !== indexToRemove
      );
      setVehicles(updatedVehicles);
    }
  };

  const handleAddCoTenant = () => {
    setForm({
      ...form,
      coTenants: [
        ...form.coTenants,
        { _id: "", name: "", email: "", type: "" },
      ],
    });
  };

  const handleRemoveCoTenant = (index) => {
    const updatedCoTenants = form.coTenants.filter((tenant, i) => i !== index);
    setForm({
      ...form,
      coTenants: updatedCoTenants,
    });
  };

  const handleBackButton = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleLeaseInfo = () => {
    const newErrors = validateLeaseInfoForm(form);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      leaseId ? handleUpdateLease() : handleAddLease();
    }
  };

  const handleTenantInfo = () => {
    const newErrors = validateLeaseTenantsForm(form);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      handleUpdateLease();
    }
  };

  const handleDocumentInfo = () => {
    const newErrors = validateLeaseDocumentForm(documentInfo, leaseFiles);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      handleUpdateLease();
    }
  };

  const handleAddLease = () => {
    const formData = leaseFormData(
      form,
      leaseFiles,
      dependentInfo,
      pets,
      vehicles,
      documentInfo,
      currentStep
    );
    setIsLoading(true);
    createLease(formData).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        // setOpenModal(true);
        setLeaseId(res.message.data.id);
        setCurrentStep(currentStep + 1);
      } else {
        message.error(res.error.message);
      }
    });
  };

  const handleUpdateLease = () => {
    const formData = leaseFormData(
      form,
      leaseFiles,
      dependentInfo,
      pets,
      vehicles,
      documentInfo,
      currentStep
    );
    setIsLoading(true);
    updateLease(leaseId, formData).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        // setOpenModal(true);
        if (
          currentStep === 6 ||
          (selectedPropertyType === "commercial" && currentStep === 4)
        ) {
          setOpenModal(true);
        } else {
          setCurrentStep(currentStep + 1);
        }

        if (currentStep === 2) {
          if (res.message?.popupMessage === "Prospect's data is replaced with existing tenant data.") {
            // message.success(res.message.popupMessage);
            setProspectReplacedModal(true);
            setForm((prevForm) => ({
              ...prevForm,
              tenant: {
                ...prevForm.tenant,
                _id: res.message.data.tenant,
                type: "Tenant",
              },
              coTenants: prevForm.coTenants.map((coTenant) => ({
                ...coTenant,
                type: "Tenant",
              })),
            }));
          } else {
            setForm((prevForm) => ({
              ...prevForm,
              tenant: {
                ...prevForm.tenant,
                type: "Tenant",
              },
              coTenants: prevForm.coTenants.map((coTenant) => ({
                ...coTenant,
                type: "Tenant",
              })),
            }));
          }

          // setForm((prevForm) => ({
          //   ...prevForm,
          //   tenant: {
          //     ...prevForm.tenant,
          //     type: "Tenant",
          //   },
          //   coTenants: prevForm.coTenants.map((coTenant) => ({
          //     ...coTenant,
          //     type: "Tenant",
          //   })),
          // }));
        }
      } else {
        message.error(res.error.message);
      }
    });
  };

  const handleNext = () => {
    switch (currentStep) {
      case 1:
        handleLeaseInfo();
        break;
      case 2:
        handleTenantInfo();
        break;
      case 3:
        handleUpdateLease();
        break;
      case 4:
        handleUpdateLease();
        break;
      case 5:
        handleUpdateLease();
        break;
      case 6:
        handleDocumentInfo();
        break;
    }
  };

  return (
    <>
      {openModal && (
        <TempModal
          onClose={() => setOpenModal(false)}
          title={"Lease Created Successfully!"}
          success="Go To Leases"
          route="all-lease"
        />
      )}
      {prospectReplacedModal && (
        <TempModal
          onClose={() => setProspectReplacedModal(false)}
          title={"Prospect's data has been replaced with existing Tenant information."}
          success="Close"
          handler={() => {
            setProspectReplacedModal(false);
            // setCurrentStep(currentStep + 1);
          }}
        />
      )}
      <div className="container-fluid p-3">
        <div className="container">
          <Stepper
            steps={selectedPropertyType === "commercial" ? addCommercialLeaseDetails : addLeaseDetails}
            activeStep={currentStep - 1}
            lockStep={false}
            maxSteps={4} // Customize if desired
            setIsDraft={(isDraft) => console.log("Draft status:", isDraft)}
          />
          <div className="stepper-content-container mt-4">
            {currentStep === 1 && (
              <InnerComponents.LeaseInfoForm
                form={form}
                handleChange={handleChange}
                errors={errors}
                leaseFiles={leaseFiles}
                setLeaseFiles={setLeaseFiles}
                handlePropertyType={setSelectedPropertyType}
                handleNext={handleNext}
                isLoading={isLoading}
              />
            )}
            {currentStep === 2 && (
              <InnerComponents.TenantInfoForm
                form={form}
                handleChange={handleChange}
                errors={errors}
                handleAddCoTenant={handleAddCoTenant}
                handleRemoveCoTenant={handleRemoveCoTenant}
                handleBackButton={handleBackButton}
                handleNext={handleNext}
                isLoading={isLoading}
                setTenantEmails={setTenantEmails}
              />
            )}
            {selectedPropertyType !== "commercial" && currentStep === 3 && (
              <InnerComponents.DependentInfoForm
                dependentInfo={dependentInfo}
                handleChange={handleDependentChange}
                addAnotherDependent={addAnotherDependent}
                removeDependent={removeDependent}
                handleBackButton={handleBackButton}
                handleNext={handleNext}
                isLoading={isLoading}
              />
            )}
            {selectedPropertyType !== "commercial" && currentStep === 4 && (
              <InnerComponents.PetsForm
                pets={pets}
                handleChange={handlePetChange}
                handleBackButton={handleBackButton}
                handleNext={handleNext}
                addAnotherPet={addAnotherPet}
                removePet={removePet}
                isLoading={isLoading}
              />
            )}
            {((selectedPropertyType === "commercial" && currentStep === 3) ||
              currentStep === 5) && (
              <InnerComponents.VehicleForm
                vehicles={vehicles}
                handleChange={handleVehicleChange}
                handleBackButton={handleBackButton}
                handleNext={handleNext}
                addAnotherVehicle={addAnotherVehicle}
                removeVehicle={removeVehicle}
                isLoading={isLoading}
              />
            )}
            {((selectedPropertyType === "commercial" && currentStep === 4) ||
              currentStep === 6) && (
              <InnerComponents.DocumentInfoForm
                form={documentInfo}
                handleChange={handleDocumentInfoChange}
                errors={errors}
                leaseFiles={leaseFiles}
                setLeaseFiles={setLeaseFiles}
                handleBackButton={handleBackButton}
                handleNext={handleNext}
                isLoading={isLoading}
                isSubmitForm={true}
                tenantEmails={tenantEmails}
                leaseId={leaseId}
                buttonLoader={isLoading}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewLease;
