import React from "react";
import depositsHeldIcon from "assets/lease-balance.png";
import depositHeldSecondIcon from "assets/Deposit Held icon second.png";
import monthlyRentIcon from "assets/lease-rent.png";
import person from "assets/person.jpg";
import { formatPhoneNumber } from "utils";
import { Leasebox } from "Components/GeneralComponents";

const OverviewTab = ({ tenant }) => {
  return (
    <>
      <div className="row mt-3">
        <Leasebox
          icon={monthlyRentIcon}
          title={"Active Status"}
          value={tenant?.status}
        />
        <Leasebox
          icon={depositHeldSecondIcon}
          title={"Open Task"}
          balance={true}
          value={tenant?.openTasks}
        />
        <Leasebox
          icon={depositsHeldIcon}
          title={"Pending Amount"}
          value={tenant?.pendingAmount}
        />
      </div>
      <div className="row mt-5">
        <div className="col-md-10">
          <div className="task-info-heading">
            <h4>Personal Info</h4>
          </div>
          <div className="task-info-lists mt-5">
            <div className="row">
              <div className="col-md-4">
                <p>
                  <span className="task-info-list-span me-3">First Name:</span>{" "}
                  {tenant?.firstName || "N/A"}
                </p>
              </div>
              <div className="col-md-4">
                <p>
                  <span className="task-info-list-span me-3">Middle Name:</span>{" "}
                  {tenant?.middleName || "N/A"}
                </p>
              </div>
              <div className="col-md-4">
                <p>
                  <span className="task-info-list-span me-3">Last Name:</span>{" "}
                  {tenant?.lastName || "N/A"}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <p>
                  <span className="task-info-list-span me-3">Email:</span>
                  {tenant?.email || "N/A"}
                </p>
              </div>
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <p>
                  <span className="task-info-list-span me-3">Phone No:</span>{" "}
                  {formatPhoneNumber(tenant?.phone) || "N/A"}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <p>
                  <span className="task-info-list-span me-3">
                    Date of Birth:
                  </span>{" "}
                  {tenant?.dob
                    ? new Date(tenant?.dob).toLocaleDateString()
                    : "N/A"}
                </p>
              </div>
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <p>
                  <span className="task-info-list-span me-3">phoneType:</span>{" "}
                  {tenant?.phoneType || "N/A"}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <p>
                  <span className="task-info-list-span me-3">Company:</span>{" "}
                  {tenant?.company || "N/A"}
                </p>
              </div>
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <p>
                  <span className="task-info-list-span me-3">Job Title:</span>{" "}
                  {tenant?.jobTitle || "N/A"}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p>
                  <span className="task-info-list-span me-3">
                    Current Address:
                  </span>{" "}
                  {tenant?.address
                    ? `${tenant.address?.address_line_1}, ${tenant.address?.city}, ${tenant.address?.state}, ${tenant.address?.country}, ${tenant.address?.zipcode}`
                    : "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2 text-end">
          {tenant?.profileImage ? (
            <img
              className="rounded-4 w-100 aspect-1 object-fit-cover"
              src={`${tenant?.profileImage}`}
              alt=""
            />
          ) : (
            <img
              className="rounded-4 w-100"
              src={person}
              alt=""
            />
          )}
        </div>
      </div>
      <hr />
      <div className="row mt-4">
        <div className="col-md-12">
          <div className="task-info-heading">
            <h4>
              Emergency Info{" "}
              <span style={{ color: "#ef6b3e" }}>
                {" "}
                ({tenant?.emergencyDetails?.length}){" "}
              </span>{" "}
            </h4>
          </div>
          {tenant?.emergencyDetails.map((emergencyDetailsData, index) => {
            return (
              <div className="task-info-lists mt-5" key={index}>
                <div className="row">
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">
                        First Name:
                      </span>{" "}
                      {emergencyDetailsData?.firstName || "N/A"}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">
                        Middle Name:
                      </span>{" "}
                      {emergencyDetailsData?.middleName || "N/A"}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">
                        Last Name:
                      </span>{" "}
                      {emergencyDetailsData?.lastName || "N/A"}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">Email:</span>{" "}
                      {emergencyDetailsData?.email || "N/A"}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">
                        Phone No:
                      </span>{" "}
                      {formatPhoneNumber(emergencyDetailsData?.phone) || "N/A"}
                    </p>
                  </div>
                  <div className="col-md-4"></div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">
                        Relationship:
                      </span>{" "}
                      {emergencyDetailsData?.relationship || "N/A"}
                    </p>
                  </div>
                  <div className="col-md-8">
                    <p>
                      <span className="task-info-list-span me-3">
                        Description:
                      </span>{" "}
                      {emergencyDetailsData?.description || "N/A"}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default OverviewTab;
