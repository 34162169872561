import axios from "axios";
import config from "Helpers/config.js";

const verifyTenant = async (email) => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/tenant/verify?email=${email}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getTenants = async (filters) => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/landlord/tenants`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      params: filters,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getTenantsByLease = async (leaseId) => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/landlord/tenants/lease/${leaseId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteTenant = async (tenantId) => {
  try {
    const response = await axios.delete(
      `${config.baseUrl}/api/landlord/tenant/${tenantId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const DeleteBulkTenant = async (tenantIds) => {
  try {
    const response = await axios.delete(
      `${config.baseUrl}/api/landlord/tenants`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        data: {
          tenantIds,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getTenantById = async (tenantId) => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/landlord/tenant/${tenantId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateTenant = async (tenantId, data) => {
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/landlord/tenant/${tenantId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const createTenant = async (data) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/landLord/tenant`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const sendInvite = async (id) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/landlord/tenant/invite`,
      { id },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export {
  verifyTenant,
  getTenants,
  getTenantsByLease,
  getTenantById,
  createTenant,
  updateTenant,
  deleteTenant,
  DeleteBulkTenant,
  sendInvite,
};
