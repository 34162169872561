import React from "react";
import { ConfigProvider, Slider } from "antd";

const NumberRangeSelector = ({
  rangeHandleChange,
  sliderValue,
  handleInputChange,
  max=6000,
  min=1,
  defaultmin=500,
  defaultmax=3800
}) => {
  return (
    <>
      <div>
        <ConfigProvider
          theme={{
            components: {
              Slider: {
                colorPrimary: "#000000",
                colorPrimaryBorder: "#000000",
                colorPrimaryBorderHover: "#000000",
                colorBgElevated: "#000000",
              },
            },
          }}
        >
          <Slider
            onChange={rangeHandleChange}
            range={{ draggableTrack: true }}
            max={max}
            min={min}
            value={sliderValue}
            defaultValue={[defaultmin, defaultmax]}
          />
        </ConfigProvider>
      </div>
      <div className="price-range-value d-flex gap-3 mb-4">
        <span className="dollar-sign">
          $&nbsp;
          <input
            className="price-range-input-box"
            type="number"
            value={
              sliderValue[0] === "" || sliderValue[0] === 0
                ? ""
                : sliderValue[0]
            }
            onChange={(e) => handleInputChange(0, e)}
          />
        </span>
        <div className="range-icon">-</div>
        <span className="dollar-sign">
          $&nbsp;
          <input
            className="price-range-input-box"
            type="number"
            value={
              sliderValue[1] === "" || sliderValue[1] === 0
                ? ""
                : sliderValue[1]
            }
            onChange={(e) => handleInputChange(1, e)}
          />
        </span>
      </div>
    </>
  );
};

export default NumberRangeSelector;
