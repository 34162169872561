import React from "react";
import FileUploader from "Components/FileUploader/FileUploader";

const MediaField = ({
  required = false,
  label = "",
  errorMessage = false,
  setImages,
  Images,
  height = 150,
}) => {
  console.log("Images", Images);
  return (
    <div style={{ textAlign: "left" }}>
      {label && (
        <span className="tenant-personal-details-title">
          {label}{" "}
          <span className="sign-up-imp-star">{`${required ? "*" : ""}`}</span>
        </span>
      )}
      <div>
        <FileUploader setImages={setImages} Images={Images} height={height} />
      </div>
      {errorMessage && (
        <div className="text-danger fw-bold mt-2" style={{ fontSize: "12px" }}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default MediaField;
