import React from 'react'

const Error404 = () => {
  return (
    <>
      <h1 className="text-center">ERROR 404 Page Not Found!</h1>
    </>
  )
}

export default Error404
