import React, { useState, useEffect } from "react";
import Leasedstatuscards from "Components/GeneralComponents/Leasedstatuscards";
import trashIconWhite from "assets/trash-icon-white.png";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

import * as Component from "Components/GeneralComponents";
import activeDot from "assets/_Dot.png";
import redDot from "assets/svg/_Dot.svg";
import { useNavigate, Link } from "react-router-dom";
import * as Icon from "assets/icons";
import * as icon from "assets/svg";
import {
  getAllInvoices,
  archiveInvoice,
  unArchiveInvoice,
  getAllArchivedInvoices,
  getAllTenantInvoices,
  filterAllInvoices,
} from "network/landlord/Account";
import { message, Switch } from "antd";
import { formatDate } from "utils";
import { isRejected } from "@reduxjs/toolkit";
import { invoiceFilter } from "utils/dropdownData";
import { loadStripe } from "@stripe/stripe-js";
import Checkout from "./Checkout";
import RecurringCheckout from "./RecurringCheckout";
import axios from "axios";
import config from "Helpers/config";
import { FormModalLayout } from "Components/GeneralComponents";
import {
  generateAndDownloadPDF,
  generateAndPrintPDF,
} from "network/landlord/Account";
import ConfirmationModal from "Components/GeneralComponents/Modals/ConfirmationModal";
const TenantPayment = () => {
  const [selectedTableItem, setSelectedTableItem] = useState("");
  const [button, setButton] = useState(true);
  const [mulDeleteMsg, setMulDeleteMsg] = useState(
    "Are you sure want to delete selected tenants?"
  );
  const [disableMulDltBtn, setDisableMulDltBtn] = useState(false);
  const [isDeleteTenantModal, setIsDeleteTenantModal] = useState(false);
  const [key, setKey] = useState("");
  const [isDeleteTenantsRender, setIsDeleteTenantsRender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [allInvoices, setAllInvoices] = useState([]);
  const [tempInvoiceData, setTempInvoiceData] = useState("");
  const [leaseStatusCard, setLeaseStatusCard] = useState({
    total_invoices: 0,
    paid_invoices: 0,
    pending_invoices: 0,
    overDue_invoices: 0,
  });

  const [isPayNowModelOpen, setIsPayNowModelOpen] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [options, setOptions] = useState({});

  const [invoiceNo, setInvoiceNo] = useState("12234");
  const [isConfirmModelOpen, setIsConfirmModalOpen] = useState(false);
  const [archiveInvoiceId, setArchiveInvoiceId] = useState(null);
  const [type, setType] = useState(null);
  const [amount, setAmount] = useState(null);
  const [archiveMode, setArchiveMode] = useState(null);

  const navigate = useNavigate();

  // useEffect(() => {
  //   setTimeout(() => {
  //     // setIsTableLoading(false);
  //   }, 1000);
  // }, [update]);

  const [data, setData] = useState([
    {
      key: "1",
    },
    {
      key: "2",
    },
    {
      key: "3",
    },
  ]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [filter, setFilter] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    Select: "",
    invoiceFilter: "",
  });

  const filterInvoices = (type) => {
    console.log("type", type);
    console.log("allInvoices: ", allInvoices);
    const filteredInvoices = allInvoices.filter((item) => item.type === type);
    console.log("filteredInvoices", filteredInvoices);
    setInvoiceData(filteredInvoices);
  };

  const fetchInvoices = async () => {
    setIsTableLoading(true);

    try {
      const response = await getAllTenantInvoices();

      if (response.apiCallStatus === "success") {
        const transformedInvoices =
          response.message.invoices.allInvoices.invoices.map(
            (invoice, index) => ({
              display_invoice_no: invoice.invoiceNo.substring(
                invoice.invoiceNo.indexOf("INV")
              ),
              invoice_no: invoice.invoiceNo,
              due_date: invoice.dueDate,
              type: invoice.type,

              key: index.toString(),
              name: `${invoice.landlordId.firstName} ${invoice.landlordId.lastName}`,
              invoice_date: new Date(invoice.invoiceDate).toLocaleDateString(),
              amount: invoice.total,
              Description: "Rent",
              status:
                invoice.paidStatus.charAt(0).toUpperCase() +
                invoice.paidStatus.slice(1),
            })
          );

        setLeaseStatusCard({
          total_invoices: response.message.invoices.allInvoices.totalInvoices,
          paid_invoices: response.message.invoices.allInvoices.paidInvoices,
          pending_invoices:
            response.message.invoices.allInvoices.pendingInvoices,
          overDue_invoices:
            response.message.invoices.allInvoices.overdueInvoices,
        });

        setAllInvoices(transformedInvoices);
        console.log("Invoices set successfully!", transformedInvoices);
        setInvoiceData(transformedInvoices);
        setIsTableLoading(false);
        setTempInvoiceData(transformedInvoices);
      } else {
        setIsTableLoading(false);
      }
    } catch (error) {
      setIsTableLoading(false);

      console.error("Error fetching invoices", error);
      setIsTableLoading(false);

      message.error("Something went wrong while fetching the invoices!");
    }
  };
  useEffect(() => {
    if (archiveMode !== "archive") {
      fetchInvoices();
    }
  }, []);
  // const getAllTenantInvoices = async() => {
  //   setIsTableLoading(true)
  //   await getAllInvoices()
  //   .then((res)=>{
  //     setAllInvoices(res.data.invoices.allInvoices.invoices)
  //   }).catch((err) => {
  //     console.log("Error fetching all invoices:", err.message);
  //   })
  //   setIsTableLoading(false)

  // }

  const handlePayNow = async (invoiceNo, type) => {
    setIsPayNowModelOpen(true);
    try {
      setInvoiceNo(invoiceNo);
      setType(type);

      const fetchClientSecret = async () => {
        try {
          const response = await axios.post(
            `${config.baseUrl}/api/stripe/paynowMobile`,
            {
              email: localStorage.getItem("email"),
              invoiceNo: invoiceNo,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          const data = response.data;
          console.log("DATA: ", data);
          if (data?.message?.clientSecret) {
            return {
              clientSecret: data.message.clientSecret,
              amount: data.message.amount_due,
            };
          } else {
            throw new Error("Client secret not found");
          }
        } catch (error) {
          console.error(
            "Error fetching client secret:",
            error.response?.data || error.message
          );
          throw error;
        }
      };

      const { clientSecret, amount } = await fetchClientSecret();

      if (clientSecret) {
        setOptions({ clientSecret });
        setClientSecret(clientSecret);
        setAmount(amount);
      }
    } catch (error) {
      // console.message(error.message)
      console.error("Error handling pay now:", error);
    }
  };

  const handleRecurrisvePayment = async (invoiceNo, type) => {
    setInvoiceNo(invoiceNo);
    setType(type);
    setIsPayNowModelOpen(true);

    console.log("handle recurrisve payment: ", type, invoiceNo);

    try {
      const response = await axios.post(
        `${config.baseUrl}/api/stripe/payRecurrsiveInvoice`,
        {
          email: localStorage.getItem("email"),
          invoiceNo: invoiceNo,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const data = response;
      console.log("Recurssive Data: ", data);
      if (data?.data?.message?.client_secret) {
        let clientSecret = data?.data?.message?.client_secret;
        setOptions({ clientSecret });
        setClientSecret(clientSecret);
        setAmount(data?.data?.message?.amount_due);
        return data?.data?.message.client_secret;
      } else {
        throw new Error("Client secret not found");
      }
    } catch (error) {
      console.error(
        "Error fetching client secret:",
        error.response?.data || error.message
      );
      throw error;
    }
  };

  const handleDownloadPDF = (invoiceNo) => {
    generateAndDownloadPDF(invoiceNo);
  };
  const handlePrintPDF = (invoiceNo) => {
    generateAndPrintPDF(invoiceNo);
  };
  const handleClose = () => {
    fetchInvoices();
    setIsPayNowModelOpen(false);
  };
  const handleFilter = async () => {
    try {
      setIsTableLoading(true);
      console.log(filter);
      const updatedFilter = { ...filter, type: true };

      if (updatedFilter.invoiceFilter == "archive") {
        setArchiveMode(true);
      } else {
        setArchiveMode(false);
      }
      const response = await filterAllInvoices(updatedFilter);

      if (response?.apiCallStatus === "success") {
        const transformedInvoices = response.message?.invoices?.map(
          (invoice, index) => ({
            display_invoice_no: invoice.invoiceNo.substring(
              invoice.invoiceNo.indexOf("INV")
            ),
            invoice_no: invoice.invoiceNo,
            name: invoice.landlordId
              ? `${invoice.landlordId.firstName} ${invoice.landlordId.lastName}`
              : `${invoice.tenantId.firstName} ${invoice.tenantId.lastName}`,
            due_date: invoice.dueDate,
            type: invoice.type,
            key: index.toString(),
            invoice_date: new Date(invoice.invoiceDate).toLocaleDateString(),
            amount: invoice.total,
            Description: "Rent",
            status:
              invoice.paidStatus.charAt(0).toUpperCase() +
              invoice.paidStatus.slice(1),
          })
        );
        setAllInvoices(transformedInvoices);
        setInvoiceData(transformedInvoices);
        setTempInvoiceData(transformedInvoices);
        setIsTableLoading(false);
      } else {
        setIsTableLoading(false);
      }
    } catch (error) {
      setIsTableLoading(false);

      message.error("Failed to filter invoices", error);
    }
  };

  const archiveInvoiceById = async (invoice_no) => {
    const resposne = await archiveInvoice(invoice_no);
    if (resposne.apiCallStatus === "success") {
      message.success("Invoice Archived Successfully");
    } else {
      message.error("Failed to archive invoice", resposne?.message);
    }
    handleArchiveClose();
    fetchInvoices();
  };
  const unArchiveInvoiceById = async (invoice_no) => {
    const resposne = await unArchiveInvoice(invoice_no);
    if (resposne.apiCallStatus === "success") {
      message.success("Invoice Archived Successfully");
      getTenantArchivedInvoices();
    } else {
      message.error("Failed to archive invoice", resposne?.message);
    }
    handleArchiveClose();
  };
  const getTenantArchivedInvoices = async () => {
    try {
      setIsTableLoading(true);

      const res = await getAllArchivedInvoices();
      const transformedInvoices = res.message?.invoices?.map(
        (invoice, index) => ({
          display_invoice_no: invoice.invoiceNo.substring(
            invoice.invoiceNo.indexOf("INV")
          ),
          invoice_no: invoice.invoiceNo,
          name: invoice.landlordId
            ? `${invoice.landlordId.firstName} ${invoice.landlordId.lastName}`
            : `${invoice.tenantId.firstName} ${invoice.tenantId.lastName}`,
          due_date: invoice.dueDate,
          type: invoice.type,
          key: index.toString(),
          invoice_date: new Date(invoice.invoiceDate).toLocaleDateString(),
          amount: invoice.total,
          Description: "Rent",
          status:
            invoice.paidStatus.charAt(0).toUpperCase() +
            invoice.paidStatus.slice(1),
        })
      );

      console.log("REspones", res);
      setAllInvoices(transformedInvoices);
      setInvoiceData(transformedInvoices);
    } catch (err) {
      console.log("Error fetching archived invoices:", err.message);
    } finally {
      setIsTableLoading(false);
    }
  };

  const hanldeArchive = (invoiceNo) => {
    setArchiveInvoiceId(invoiceNo);
    setIsConfirmModalOpen(true);
  };
  const handleUnarchive = async (invoiceNo) => {
    setArchiveInvoiceId(invoiceNo);
    setIsConfirmModalOpen(true);
  };
  const handleArchiveClose = () => {
    setIsConfirmModalOpen(false);
  };

  const columns = [
    {
      title: "Invoice No",
      dataIndex: "display_invoice_no",
      render: (text, data) => <>{text}</>,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, data) => <>{text}</>,
    },

    // {
    //   title: "Description",
    //   dataIndex: "Description",
    //   render: (text, date) => <>{text}</>,
    // },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text, date) => <>{text}</>,
    },
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
      render: (text, record) => (
        <>
          <div className="d-flex flex-column ">
            <span className="d-flex flex-row">
              <span className="text-nowrap">{text}</span>
            </span>

            <span className="text-nowrap"> Due Date: {record.due_date}</span>
          </div>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, status) => (
        <>
          <div
            className={`${
              text === "Paid" ? "prospect-active-bar" : "prospect-unpaid-bar"
            }`}
            style={{ width: "70px" }}>
            <img
              src={text === "Paid" ? activeDot : redDot}
              alt=""
            />{" "}
            <span>{text}</span>
          </div>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          {console.log("Setting: ", setting)}

          {button ? (
            <div
              className="position-relative cursor text-center"
              onClick={() => handleIconClick(setting.key)}>
              <Icon.VerticalElipsisIcon
                width={23}
                height={23}
              />
              <Component.RecordMenuPopup
                isOpen={setting.key === key}
                onClose={() => setKey(null)}
                handler={{
                  ...{
                    handlePayNow: () => {
                      if (setting.type === "one time") {
                        handlePayNow(setting.invoice_no, setting.type);
                      } else {
                        handleRecurrisvePayment(
                          setting.invoice_no,
                          setting.type
                        );
                      }
                    },
                  },
                  // handlePayNow: () => handlePayNow(setting.invoice_no, setting.type),
                  downloadaspdf: () => handleDownloadPDF(setting.invoice_no),
                  print: () => handlePrintPDF(setting.invoice_no),
                  archive: () => hanldeArchive(setting.invoice_no),
                  unarchive: () => handleUnarchive(setting.invoice_no),
                }}
                fields={{
                  payNow: setting.status === "Unpaid",
                  downloadaspdf: true,
                  print: true,
                  ...(archiveMode ? { unarchive: true } : { archive: true }),
                }}
              />
            </div>
          ) : (
            <div
              className="position-relative cursor text-center"
              onClick={() => handleIconClick(setting.key)}>
              <Icon.VerticalElipsisIcon
                width={23}
                height={23}
              />
              <Component.RecordMenuPopup
                isOpen={setting.key === key}
                onClose={() => setKey(null)}
                handler={{
                  handleView: () => console.log("View"),
                  cancel: () => console.log("Cancel"),
                  downloadaspdf: () => handleDownloadPDF(setting.invoice_no),
                  print: () => handlePrintPDF(setting.invoice_no),
                }}
                fields={{
                  editseries: true,
                  view: true,
                  cancel: true,
                  downloadaspdf: true,
                  print: true,
                }}
              />
            </div>
          )}
        </>
      ),
    },
  ];
  const handleIconClick = (result) => {
    const filterData = data.filter((item) => {
      return item?.key === result;
    });
    setKey(filterData[0]?.key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  useEffect(() => {}, [isDeleteTenantsRender]);

  useEffect(() => {
    // Check if filter.search has a value
    if (filter.search && filter.search.trim()) {
      console.log("filter.search", filter.search);

      // Filter the invoice data based on the search criteria
      const filteredData = tempInvoiceData.filter((item) => {
        // Check if any of the item properties include the search string
        return (
          item.invoice_no.toLowerCase().includes(filter.search.toLowerCase()) ||
          item.Description.toLowerCase().includes(
            filter.search.toLowerCase()
          ) ||
          item.status.toLowerCase().includes(filter.search.toLowerCase()) ||
          item.name.toLowerCase().includes(filter.search)
        );
      });

      setInvoiceData(filteredData);
    } else {
      // Reset invoice data to the original state when filter is cleared
      setInvoiceData(tempInvoiceData);
    }
  }, [filter.search]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTableItem([...selectedRowKeys]);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const onCloseDeleteTenantModal = () => {
    setIsDeleteTenantModal(false);
    setMulDeleteMsg("Are you sure want to delete selected tenants?");
    setDisableMulDltBtn(false);
  };
  const handleFilterReset = () => {
    setFilter({});

    setArchiveMode(false);
    fetchInvoices();
  };

  return (
    <>
      {isConfirmModelOpen && (
        <ConfirmationModal
          isOpen={isConfirmModelOpen}
          onClose={handleArchiveClose}
          confirmationCall={
            archiveMode ? unArchiveInvoiceById : archiveInvoiceById
          }
          message={
            archiveMode
              ? "Do you Confirm to unarchive Your Invoice?"
              : "Do you Confirm to archive Your Invoice?"
          }
          isLoading={false}
          disableBtn={false}
          data={archiveInvoiceId}
        />
      )}
      {console.log(amount)}

      {isPayNowModelOpen && (
        <FormModalLayout
          isOpen={isPayNowModelOpen}
          onClose={handleClose}
          title="Checkout">
          {type === "one time" ? (
            <Checkout
              options={options}
              invoiceNo={invoiceNo}
              amount={amount}
              handleClose={handleClose}
            />
          ) : (
            <RecurringCheckout
              options={options}
              invoiceNo={invoiceNo}
              amount={amount}
              handleClose={handleClose}
            />
          )}
        </FormModalLayout>
      )}

      <Component.DeleteModal
        isOpen={isDeleteTenantModal}
        onClose={onCloseDeleteTenantModal}
        message={mulDeleteMsg}
        deleteCall={() => setIsDeleteTenantsRender(!isDeleteTenantsRender)}
        isLoading={isLoading}
        disableBtn={disableMulDltBtn}
      />
      <div className="flex-fill">
        <div className="container-fluid bg-white p-4">
          <div className="row mt-3">
            <Leasedstatuscards
              icon={icon.file4}
              balance={true}
              value={leaseStatusCard.total_invoices}
              title="Total Invoices"
            />
            <Leasedstatuscards
              icon={icon.file1}
              balance={true}
              value={leaseStatusCard.paid_invoices}
              title="Paid Invoices"
            />
            <Leasedstatuscards
              icon={icon.file3}
              balance={true}
              value={leaseStatusCard.pending_invoices}
              title="Pending Invoice"
            />
            <Leasedstatuscards
              icon={icon.file2}
              balance={true}
              value={leaseStatusCard.overDue_invoices}
              title="Overdue Invoice"
            />
          </div>
        </div>

        <div className="container-fluid bg-white p-3 ">
          <Component.FilterRow
            filter={filter}
            setFilter={setFilter}
            handleSubmit={handleFilter}
            handleReset={handleFilterReset}
            filterList={{
              date: true,

              invoiceFilter: true,
            }}
          />
        </div>
        <div className="row p-4">
          <div className="col-md-12">
            {selectedTableItem.length !== 0 && (
              <div className="table-delete-icon mb-3">
                <button
                  onClick={() => setIsDeleteTenantModal(true)}
                  className="table-delete-btn next-btn-main">
                  <img
                    src={trashIconWhite}
                    alt=""
                  />
                  Delete
                </button>
              </div>
            )}
            <Component.CustomTable
              loading={isTableLoading}
              pagination={true}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              columns={columns}
              dataSource={invoiceData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TenantPayment;
