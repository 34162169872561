import React, { useState } from "react";
import ModalLoader from "Components/GeneralComponents/ModalLoader";
import { SelectField, TextAreaField } from "Components/GeneralComponents";
import { updateTask } from "network/tenant/task";
import { selectTaskStatus } from "utils/dropdownData";
import { message } from "antd";
const PostUpdateModalForm = ({ onClose, id }) => {
  const [loader, setLoader] = useState(false);
  const [form, setForm] = useState({
    status: "",
    inputdetails: "",
  });
  const [errors, setErrors] = useState({});

  const handleForm = (formField, value) => {
    setForm((prev) => {
      return {
        ...prev,
        [formField]: value,
      };
    });
  };

  const UpdateTask = () => {
    const newErrors = {  };

    const formField = {
      status: "Status",
      inputdetails: "Input Details",
    };
  
    for(let key in form){
      if(form[key] === ""){
      newErrors[key] = `${formField[key]} is required`;
      }

    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setLoader(true);
      updateTask(id, {
        status: form.status,
        inputDetails: form.inputdetails,
      }).then((res) => {
        setLoader(false);
        if (res.apiCallStatus === "success") {
          message.success("Task updated successfully");
          onClose();
        }
      });

      setTimeout(() => {
        onClose();
      }, 1000);
    }
  };

  return (
    <>
    {
      console.log("errors======", errors)
    }
      <div className="modal-content-main-section p-41">
        <div className="row mt-3">
          <div className="col-md-12">
            <TextAreaField
              name={"input Details"}
              label={"Input Details"}
              placeholder={"Details"}
              handler={(e) => handleForm("inputdetails", e.target.value)}
              value={form.inputdetails}
              required={true}
              errorMessage={errors.inputdetails}
              rows={"5"}
              cols={"30"}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <SelectField
              name={"status"}
              required={true}
              label={"Status"}
              placeholder={"Select Status"}
              value={form.status}
              options={selectTaskStatus}
              handler={(e) => handleForm("status", e)}
              errorMessage={errors.status}
            />
          </div>
        </div>
      </div>
      <div className="modal-content-footer-section-scroll p-custom-post-update">
        <div className="row mt-3">
          <div className="col-md-12 d-flex justify-content-end">
            <div className="post-update-btn">
              <button
                onClick={() => {
                  UpdateTask();
                }}
                className="modal-post-update-btn"
              >
                Post Update{loader && <ModalLoader />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostUpdateModalForm;
