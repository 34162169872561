import React from "react";

const Line = () => {
  return (
    <svg
      width="2"
      height="20"
      viewBox="0 0 2 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="0.763916"
        y1="0.964844"
        x2="0.763916"
        y2="24.0352"
        stroke="#8D8B8B"
      />
    </svg>
  );
};

export default Line;
