import EndLease from "Components/EndLease/EndLease";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const LeasesEnd = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail="End Lease"
        back="lease-detail"
      />
      <EndLease />
    </div>
  );
};

export default LeasesEnd;
