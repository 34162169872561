import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Avatar, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FilterValue } from "Store/Slices/FilterSlice";
import SearchBar from "Helpers/SearchBar";
import SearchBarWithOutBtn from "Helpers/SearchBarWithOutBtn";
import {
  ButtonVariant1,
  CustomTable,
  DeleteModal,
  FormModalLayout,
  RecordMenuPopup,
} from "Components/GeneralComponents";
import UserPermission from "libs/UserPermission";
import trashIconWhite from "assets/trash-icon-white.png";
import bedIcon from "assets/bed-icon.png";
import bathIcon from "assets/bath icon.png";
import sqftIcon from "assets/sqft-icon.png";
import activeDot from "assets/_Dot.png";
import inactiveDot from "assets/inactivedot.png";
import { deleteUnit, purchaseUnits } from "network/landlord/property";
import { VerticalElipsisIcon } from "assets/icons";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getRemainingUnits, updatePaidUnit } from "network/landlord/property";
import config from "Helpers/config";
import PurchaseUnit from "Modals/PurchaseUnit/PurchaseUnit";

const UnitTab = ({
  id,
  unitsData,
  isLoading,
  setUpdate,
  remainingUnits,
  setRemainingUnits,
}) => {
  const { ROLE } = UserPermission();
  const navigate = useNavigate();
  const [selectedTableItem, setSelectedTableItem] = useState("");
  const [selectionType, setSelectionType] = useState("checkbox");
  const [key, setKey] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [data, setData] = useState([]);
  const [isDeleteRender, setIsDeleteRender] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [unitValue, setUnitValue] = useState(0);
  const [sessionId, setSessionId] = useState("");
  const [checkoutSuccess, setCheckoutSuccess] = useState(false);
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [isPurchaseModelOpen, setIsPurchaseModelOpen] = useState(false);
  const stripeKey = loadStripe(config.stripe_publish_key);
  const [purchaseError, setPurchaseError] = useState("");

  useEffect(() => {
    if (unitsData) {
      setData(
        unitsData.map((e, index) => ({
          key: index + 1,
          id: e.id,
          unitName: e.name,
          img: e.images[0],
          bed: e.bedroom,
          bath: e.bathroom,
          sqft: e.area,
          unitDetails: "+1 (555) 098-7654",
          rentAmount: e.rent_amount,
          status: e?.isAssigned,
          futureStatus: "Not Rented",
        }))
      );
    }
  }, [unitsData]);

  const handleIconClick = (result) => {
    // Toggle the dropdownOpen state
    const filterData = data.filter((item) => {
      return item.key === result;
    });
    setKey(filterData.key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const dispatch = useDispatch();

  const resetFilter = () => {
    dispatch(FilterValue({ reset: "reset" }));
  };

  const range = useSelector((state) => {
    return state.FilterValue.value;
  });
  const property = useSelector((state) => {
    return state.FilterValue.property;
  });
  const fromDate = useSelector((state) => {
    return state.FilterValue.fromDate;
  });
  const toDate = useSelector((state) => {
    return state.FilterValue.toDate;
  });

  const columns = [
    {
      title: "Unit Name",
      dataIndex: "unitName",
      render: (text, unitName) => (
        <>
          <Link to={`/properties-units-view?id=${unitName.id}&property=${id}`}>
            {unitName.img ? (
              <img
                className="unit_table_name_img"
                src={`${unitName.img}`}
                alt=""
              />
            ) : (
              <>
                <Avatar
                  style={{
                    backgroundColor: "#EF6B3E",
                    verticalAlign: "middle",
                  }}
                  size="large"
                >
                  {text[0]}
                </Avatar>
              </>
            )}{" "}
            <span className="unit_table_name_text ps-2">{text}</span>
          </Link>
        </>
      ),
    },
    {
      title: "Unit Details",
      dataIndex: "unitDetails",
      render: (text, details) => (
        <>
          <ul className="p-0 d-flex gap-3">
            <li className="list-style-none">
              <img src={bedIcon} alt="" />
              <span className="unit_table_details_text ms-2">
                {details.bed || 0} Beds
              </span>
            </li>
            <li className="list-style-none">
              <img src={bathIcon} alt="" />
              <span className="unit_table_details_text ms-2">
                {details.bath || 0} Bath
              </span>
            </li>
            <li className="list-style-none">
              <img src={sqftIcon} alt="" />
              <span className="unit_table_details_text ms-2">
                {details.sqft || 0} sqft
              </span>
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Rent Amount",
      dataIndex: "rentAmount",
      render: (text) => (
        <>
          <span className="unit_table_amount_text">{`$ ${text}`}</span>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, status) => (
        <>
          <div
            className={text ? "prospect-active-bar" : "prospect-inactive-bar"}
          >
            <img src={text ? activeDot : inactiveDot} alt="" />{" "}
            <span>{text ? "Active" : "Inactive"}</span>
          </div>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <VerticalElipsisIcon width={23} height={23} />
            <RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleDeleteRecord: () => {
                  onOpenDeleteModal();
                  setDeleteId(setting.id);
                },
                handleView: () => {
                  navigate(
                    `/properties-units-view?id=${setting.id}&property=${id}`
                  );
                },
                handleEdit: () => {
                  navigate(
                    `/properties-units-edit?id=${setting.id}&property=${id}`
                  );
                },
              }}
              fields={{
                view: true,
                edit: true,
                delete: true,
              }}
            />
          </div>
        </>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedTableItem(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  useEffect(() => {
    if (deleteId) {
      setIsLoader(true);
      deleteUnit(deleteId).then((res) => {
        setIsLoader(false);
        if (res.apiCallStatus === "success") {
          onCloseDeleteModal();
          message.success("Unit Deleted Successfully");
          // setIsRerender(!isRerender);
          setDeleteId("");
          setUpdate(true);
        } else {
          message.error("Error in deleting unit");
        }
      });
    }
  }, [isDeleteRender]);

  const onAddUnitClick = () => {
    remainingUnits > 0
      ? navigate(`/add-unit-details?id=${id}`)
      : setOpenAccountModal(true);
  };

  const fetchClientSecret = useCallback(async () => {
    const res = await purchaseUnits({
      quantity: unitValue,
    });
    // console.log("Subscribe Response", res);
    const Session = res.message.clientSecret;
    setSessionId(res.message.sessionId);

    return Session;
  }, [unitValue]);

  useEffect(() => {
    if (sessionId) {
      if (checkoutSuccess) {
        updatePaidUnit({ sessionId }).then((res) => {
          if (res.apiCallStatus === "success") {
            getRemainingUnits().then((res) => {
              if (res.apiCallStatus === "success") {
                setRemainingUnits(res.message.remainingPaidUnits);
              }
            });
          } else {
            console.error("Update Paid Unit failed:", res.message);
          }
          setOpenAccountModal(false);
        });
        setCheckoutSuccess(false);
      }
    }
  }, [sessionId, checkoutSuccess]);

  const handleCheckoutCompletion = async () => {
    setCheckoutSuccess(true);
  };

  const options = { fetchClientSecret, onComplete: handleCheckoutCompletion };

  const handlePurchaseUnit = () => {
    if (unitValue <= 0) {
      setPurchaseError("Unit value is required");
      return;
    }

    setIsPurchaseModelOpen(true);
  };

  return (
    <>
      {openAccountModal && (
        <PurchaseUnit
          onClose={() => setOpenAccountModal(false)}
          setUnitValue={setUnitValue}
          callBack={handlePurchaseUnit}
          value={unitValue}
          errorMessage={purchaseError}
        />
      )}

      <FormModalLayout
        isOpen={isPurchaseModelOpen}
        onClose={() => setIsPurchaseModelOpen(false)}
      >
        <EmbeddedCheckoutProvider stripe={stripeKey} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </FormModalLayout>

      <DeleteModal
        isOpen={openDeleteModal}
        onClose={onCloseDeleteModal}
        message={"Are you sure you want to delete this unit"}
        deleteCall={() => setIsDeleteRender(!isDeleteRender)}
        isLoading={isLoader}
      />
      {localStorage.getItem("role") !== "landlord" &&
      localStorage.getItem("role") === "user" &&
      ROLE[0]?.units?.add ? (
        <SearchBar
          // route={`add-unit-details?id=${id}`}
          onClick={onAddUnitClick}
          btnTitle={
            remainingUnits <= 0 ? "Purchase Units to Add" : "Add New Unit"
          }
          unitsFilter={false}
        />
      ) : localStorage.getItem("role") === "landlord" ? (
        <SearchBar
          // route={`add-unit-details?id=${id}`}
          onClick={onAddUnitClick}
          btnTitle={
            remainingUnits <= 0 ? "Purchase Units to Add" : "Add New Unit"
          }
          unitsFilter={false}
        />
      ) : (
        <SearchBarWithOutBtn unitsFilter={false} />
      )}

      {selectedTableItem.length >= 1 && (
        <div className="table-delete-icon mt-3">
          <button className="table-delete-btn next-btn-main">
            <img src={trashIconWhite} alt="" />
            Delete
          </button>
        </div>
      )}
      <div className="task-table-container mt-3">
        {range || property || fromDate || toDate ? (
          <div className="mt-2" style={{ width: "fit-content" }}>
            <ButtonVariant1 handler={resetFilter} children={"Reset"} />
          </div>
        ) : (
          ""
        )}
        <CustomTable
          columns={columns}
          dataSource={data}
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          loading={isLoading}
        />
      </div>
    </>
  );
};

export default UnitTab;
