import React from "react";

const OpenEye = ({width=21, height=21}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
      <path d="M12 9a3 3 0 1 0 0 6 3 3 0 1 0 0-6z" />
    </svg>
  );
};

export default OpenEye;
