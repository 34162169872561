import React, { useState, useEffect, useCallback } from "react";
import { selectBoolean } from "utils/dropdownData";
import * as Component from "Components/GeneralComponents";
import {
  uploadDocument,
  subscribeDocumentEvent,
  payToSignDocument,
} from "network/landlord/signnow";
import {
  uploadAdditionalDocument,
  uploadAdditionalSignNowDocument,
} from "network/landlord/lease";
import { message } from "antd";
import { validateLeaseDocumentForm } from "utils/validations";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import config from "Helpers/config.js";

const UploadDocument = ({ leaseId, onClose, back, handleBackButton }) => {
  const stripeKey = loadStripe(config.stripe_publish_key);
  const [leaseFiles, setLeaseFiles] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [error, setError] = useState({});
  const [update, setUpdate] = useState(false);
  const [isPurchaseModelOpen, setIsPurchaseModelOpen] = useState(false);
  const [checkoutSuccess, setCheckoutSuccess] = useState(false);
  const [form, setForm] = useState({
    isSigned: "",
    newLeaseSign: "",
    documentId: "",
    documentName: "",
    documentUrl: "",
    documentStatus: "",
    invites: [],
  });

  const handleChange = (fieldName, value) => {
    if (fieldName === "object") {
      setForm((prevForm) => ({
        ...prevForm,
        documentId: value.documentId,
        documentName: value.documentName,
        documentStatus: value.documentStatus,
        documentUrl: value.documentUrl,
      }));
      return;
    } else if (fieldName === "invites") {
      setForm((prevForm) => ({
        ...prevForm,
        invites: value,
      }));
      return;
    }
    setForm((prevForm) => ({
      ...prevForm,
      [fieldName]: value,
    }));
  };

  const handleUpload = async (file) => {
    if (!file) {
      console.log("Please select a document to sign!");
      return;
    }

    setLoader(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("leaseId", leaseId);

    try {
      const res = await uploadDocument(formData);

      setLoader(false);

      if (res.apiCallStatus === "success") {
        handleChange("object", {
          documentId: res.message.documentId,
          documentName: file.name,
          documentStatus: "pending",
          documentUrl: `https://app.signnow.com/webapp/document/${res.message.documentId}`,
        });
        subscribeDocumentEvent({
          documentId: res.message.documentId,
        }).then((res) => {
          // console.log("Subscribe Document Event =>>", res);
        });
        setUpdate(true);
        window.location.href = res.message.editorUrl;
        message.success("Document uploaded successfully!");
      } else {
        message.error("Document upload failed.");
      }
    } catch (error) {
      setLoader(false);
      message.error("An error occurred during upload.");
    }
  };

  useEffect(() => {
    if (update) {
      const data = {
        documentId: form.documentId,
        documentName: form.documentName,
        documentStatus: form.documentStatus,
        documentUrl: form.documentUrl,
      };
      uploadAdditionalSignNowDocument(leaseId, data).then((res) => {
        if (res.apiCallStatus === "success") {
          message.success("Document uploaded successfully!");
        } else {
          message.error("Document upload failed.");
        }
      });
    }
  }, [update]);

  const handleSubmit = () => {
    const newErrors = validateLeaseDocumentForm(form, leaseFiles);
    setError(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }

    if (form.isSigned === "No" && form.newLeaseSign === "Yes") {
      handleUpload(leaseFiles[0]);
    } else {
      const formData = new FormData();
      formData.append("document", leaseFiles[0]);
      setLoader(true);
      uploadAdditionalDocument(leaseId, formData).then((res) => {
        setLoader(false);
        if (res.apiCallStatus === "success") {
          message.success("Document uploaded successfully!");
          onClose();
        } else {
          message.error("Document upload failed.");
        }
      });
    }
  };

  const fetchClientSecret = useCallback(async () => {
    const res = await payToSignDocument();
    // console.log("Subscribe Response", res);
    const Session = res.message.clientSecret;

    return Session;
  }, []);

  useEffect(() => {
    if (checkoutSuccess) {
      // console.log("Checkout Success");
      handleSubmit();
    }
  }, [checkoutSuccess]);

  const handleCheckoutCompletion = async () => {
    setCheckoutSuccess(true);
  };

  const options = { fetchClientSecret, onComplete: handleCheckoutCompletion };

  return (
    <>
      <Component.FormModalLayout
        isOpen={isPurchaseModelOpen}
        onClose={() => setIsPurchaseModelOpen(false)}
      >
        <EmbeddedCheckoutProvider stripe={stripeKey} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </Component.FormModalLayout>
      <div className="container-fluid">
        <>
          <div className="row mt-3">
            <div className="col-md-12">
              <Component.SelectField
                name={"isSigned"}
                label={"Is the lease signed?"}
                placeholder={"Select Signed"}
                required={true}
                value={form.isSigned}
                options={selectBoolean}
                handler={(e) => handleChange("isSigned", e)}
                errorMessage={error.isSigned}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <Component.MediaField
                label={""}
                setImages={setLeaseFiles}
                Images={leaseFiles}
                errorMessage={error.files}
                height={266}
              />
            </div>
          </div>
          {form.isSigned === "No" && (
            <>
              <div className="tenant-personal-details-title mt-4">
                Would you like to get a new lease sign?
                <span className="sign-up-imp-star">{`${" *"}`}</span>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <Component.RadioGroupField
                    value={form.newLeaseSign}
                    options={[
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ]}
                    handler={(e) => handleChange("newLeaseSign", e)}
                    direction="row"
                    gap="5"
                    errorMessage={error.newLeaseSign}
                  />
                </div>
              </div>
            </>
          )}
          <div className="d-flex justify-content-between gap-1 pb-3 mt-5">
            {back && (
              <div style={{ width: "50%" }}>
                <Component.SubmitButton
                  type="contained"
                  handler={handleBackButton}
                >
                  Back
                </Component.SubmitButton>
              </div>
            )}
            <div style={{ width: `${back ? "50%" : "100%"}` }}>
              <Component.SubmitButton
                handler={
                  form.isSigned === "No" &&
                  form.newLeaseSign === "Yes" &&
                  leaseFiles.length > 0
                    ? () => setIsPurchaseModelOpen(true)
                    : handleSubmit
                }
                isLoading={isLoader}
              >
                {form.isSigned === "No" &&
                form.newLeaseSign === "Yes" &&
                leaseFiles.length > 0
                  ? "Pay to Sign"
                  : "Submit"}
              </Component.SubmitButton>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default UploadDocument;
