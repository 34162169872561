import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Rectangle,
} from "recharts";

const FinanceBarChart = () => {
  const data = [
    {
      name: "jan",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Feb",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Mar",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Apr",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "May",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Jun",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Jul",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "Aug",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "Sep",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
  return (
    <>
      <BarChart
        // width={window.innerWidth <= 850 ? 300 : window.innerWidth === 1024 ? 500 : 1000 }
        // className='full-width-graph w-100'
        // width={window.innerWidth <= 850 ? 320 : window.innerWidth <= 1024 ? 450 : window.innerWidth <= 1280 ? 550 : window.innerWidth <= 1440 ? 350:800}
        width={
          window.innerWidth <= 1000 ? 300 : window.innerWidth <= 768 ? 500 : window.innerWidth <= 480?200: 600
        }
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        barSize={20}
      >
        <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar
          radius={[50, 50, 0, 0]}
          dataKey="pv"
          fill="#EF6B3E"
          activeBar={<Rectangle fill="#EF6B3E" stroke="#EF6B3E" />}
        />
      </BarChart>
    </>
  );
};

export default FinanceBarChart;
