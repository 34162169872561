import axios from "axios";
import config from "Helpers/config.js";

const addCompany = async (payload) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/company`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export { addCompany };
