import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import LeaseDetails from "Components/LeaseDetails/LeaseDetails";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const LeasesDetail = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail="Lease Details"
        back="all-lease"
      />
      <LeaseDetails />
    </div>
  );
};

export default LeasesDetail;
