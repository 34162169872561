import React, { useEffect, useState, useRef } from "react";
import * as Component from "Components/GeneralComponents";
import { Link, useNavigate } from "react-router-dom";
import { formatDate } from "utils";
import { EditOutlined } from "@ant-design/icons"; // Import the Ant Design edit icon
import { getInvoiceByInvoiceNo } from "network/landlord/Account";
import { useLocation } from "react-router-dom";
import {
  selectinvoicecatogries,
} from "network/landlord/Account";
import {
  getLease,
} from "network/landlord/Account";
import { message, Switch } from "antd";
import { ConfigProvider } from "antd";
import { invoicedataform } from "utils/FormDataStructure";
import InvoiceModal from "Modals/InvoiceModal/InvoiceModal";
import { invoiceduedate } from "utils/dropdownData";
import { height } from "@mui/system";
import { updateInvoiceCall, getRecurrsiveInvoiceById, updateRecurrsiveInvoice } from "network/landlord/Account";
const InvoiceEdit = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state);

  // States start
  const [errors, setErrors] = useState({});
  const [invoiceNo, setInvoiceNo] = useState("");
  const [tenants, setTenants] = useState([]);
  const [propertyData, setPropertyData] = useState([

  ]);
  const [invoiceCat, setInvoiceCat] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [total, setTotal] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [BankAccount, setBankAccount] = useState({
    id: "",
    bankName: "",
  });
  const [images, setImages] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);

  const [form, setForm] = useState({
    invoiceNo: "",
    InvoiceDate: '',
    dueDate: "",
    SelectTenant: "",
    property: {
      propertyId: "",
      unitId: "",
    },
    Select_BankAccount: {
      id: "",
      bankName: "",
    },
    leaseId: "",

    frequency: "",
    latefee: false,
    late_fees_amount: {
      type: "",
      amount: 0,
    },
    total: 0,
    subtotal: 0,
    discount: {
      type: "$",
      amount: 0,
    },
    other_amount: {
      field: "OTHER AMOUNT",
      value: 0,
    },
    sendStatus: "",
    images: [],
    message: "",
    propertyName: "",
    tenantName: "",
    unitName: "",
    bankName: "",
  });

  const [postInvoiceData, setPostInvoiceData] = useState({
    to_name: "",
    from_name: "",
    invoice_date: "",
    due_date: "",
    invoice_no: "",
  });
  const inputRef = useRef(null); // Create a ref for the input field

  const handleIconClick = () => {
    setIsFocused(true);
    inputRef.current.focus(); // Focus the input field when icon is clicked
  };
  const handleBlur = () => {
    setIsFocused(false);
  };

  const [isFocused, setIsFocused] = useState(false);
  const [otherform, setOtherform] = useState({
    start_date: "",
    end_date: "",
    frequency: "",
    number_of_days_until_due: "",
    repeatForever: false,
  });

  useEffect(() => {
    console.log("STATE: ", state);
    const fetchInvoiceData = async () => {
      console.log("Fetching recurring invoice")

      try {
        const response = await getInvoiceByInvoiceNo(state?.invoiceNo);
        const data = response?.message?.invoice;
        console.log("Invoice data:", data);
        if (data) {
          setInvoiceData(data);
          const fullInvoiceNo = data.invoice.invoiceNo;
          const invoiceNo = fullInvoiceNo.substring(fullInvoiceNo.indexOf("INV")); // Extract everything starting from 'INV'
          setForm({
            invoiceNo: invoiceNo,
            InvoiceDate: data.invoice.invoiceDate,
            dueDate: data.invoice.dueDate || "",
            SelectTenant: data.invoice.tenantId,
            property: data.invoice.propertyId,
            unitId: data.invoice.unitId,
            leaseId: data.invoice.leaseId || "",
            Select_BankAccount: {
              value: data.invoice.bankAccountId,
              label: data.stripeAccountDetails,
            },
            type: data.invoice.type,
            subtotal: data.invoice.subtotal,
            discount: {
              type: data.invoice.discount.type,
              value: data.invoice.discount.value,
            },
            invoiceLineItems: data.invoice.description,
            total: data.invoice.total,
            message: data.invoice.message,
            sendStatus: data.invoice.sendStatus,
            paidStatus: data.invoice.paidStatus,
            archive: data.invoice.archive,

            latefee: data.invoice.lateFees.applyLateFees,
            late_fees_amount: {
              type: data.invoice.lateFees.amount.type,
              amount: data.invoice.lateFees.amount.value,
            },

            frequency: data.invoice.lateFees.frequency,
            latefee: data.invoice.lateFees.applyLateFees,
            late_fees_amount: {
              type: data.invoice.lateFees.amount.type,
              amount: data.invoice.lateFees.amount.value,
            },

            other_amount: {
              field: data.invoice.otherAmounts[0].description,
              value: data.invoice.otherAmounts[0].amount,
            },
            propertyName: data.propertyName,
            unitName: data.unitName,
            tenantName: data.tenantName,
            bankName: data.stripeAccountDetails,
          });
          setPropertyData([ // Wrap the object inside an array
            {
              id: data.invoice.propertyId,
              heading1: "dffdfdf",
              heading2: data?.propertyName,
              heading3: "Luxury dfdf",
              units: [
                { id: "unit_1", name: "Unit A" },
                { id: "unit_2", name: "Unit B" },
              ],
            }
          ]);
          const mappedInvoiceLineItems = data.invoice.description.map(
            (item) => ({
              description: item.productName,
              product_details: "", // If you have any details to include here, you can add them
              category: item.category,
              amount: item.amount,
            })
          );

          setinvoiceLineItems(mappedInvoiceLineItems);

          setImages(data.invoice.images);
          setOtherform({
            ...otherform,
            start_date: formatDate(
              response?.message.data?.lease_term_start_date
            ),
            end_date: formatDate(response?.message?.data?.lease_term_end_date),
          });

          if (data.invoice.type == "recurring") {
            setOtherform({
              ...otherform,
              start_date: formatDate(data.invoice.recurringDetails.startDate),
              end_date: formatDate(
                response?.message?.data?.lease_term_end_date
              ),
              frequency: data.invoice.recurringDetails.frequency,
              number_of_days_until_due:
                data.invoice.recurringDetails.daysUntilDue,
              repeatForever: data.invoice.recurringDetails.repeatForever,
            });
          }
        } else {
          message.error("Failed to fetch invoice data");
        }
      } catch (error) {
        console.error("Error fetching invoice data:", error);
        message.error("Something went wrong while fetching the invoice!");
      }
    };

    const fetchRecurringInvoiceById = async () => {
      console.log("Fetching recurring invoice")
      try {
        const response = await getRecurrsiveInvoiceById(state?.recurringId);
        const data = response?.message?.invoice;
        console.log("Recurring Invoice Data:", data);
        if (data) {
          setInvoiceData(data);
          const fullInvoiceNo = data?.invoice?.invoiceNo;
          const invoiceNo = fullInvoiceNo?.substring(fullInvoiceNo?.indexOf("INV")); // Extract everything starting from 'INV'
          setForm({
            invoiceNo: invoiceNo,
            InvoiceDate: data.invoice.invoiceDate,
            dueDate: data.invoice.dueDate || "",
            SelectTenant: data.invoice.tenantId,
            property: data.invoice.propertyId,
            unitId: data.invoice.unitId,
            leaseId: data.invoice.leaseId || "",
            Select_BankAccount: {
              value: data.invoice.bankAccountId,
              label: data.stripeAccountDetails,
            },
            type: data.invoice.type,
            subtotal: data.invoice.subtotal,
            discount: {
              type: data.invoice.discount.type,
              value: data.invoice.discount.value,
            },
            invoiceLineItems: data.invoice.description,
            total: data.invoice.total,
            message: data.invoice.message,
            sendStatus: data.invoice.sendStatus,
            paidStatus: data.invoice.paidStatus,
            archive: data.invoice.archive,

            latefee: data.invoice.lateFees.applyLateFees,
            late_fees_amount: {
              type: data.invoice.lateFees.amount.type,
              amount: data.invoice.lateFees.amount.value,
            },

            frequency: data.invoice.lateFees.frequency,
            latefee: data.invoice.lateFees.applyLateFees,
            late_fees_amount: {
              type: data.invoice.lateFees.amount.type,
              amount: data.invoice.lateFees.amount.value,
            },

            other_amount: {
              field: data.invoice.otherAmounts[0].description,
              value: data.invoice.otherAmounts[0].amount,
            },
            propertyName: data.propertyName,
            unitName: data.unitName,
            tenantName: data.tenantName,
            bankName: data.stripeAccountDetails,
          });
          setPropertyData([ // Wrap the object inside an array
            {
              id: data.invoice.propertyId,
              heading1: "dffdfdf",
              heading2: data?.propertyName,
              heading3: "Luxury dfdf",
              units: [
                { id: "unit_1", name: "Unit A" },
                { id: "unit_2", name: "Unit B" },
              ],
            }
          ]);
          const mappedInvoiceLineItems = data.invoice.description.map(
            (item) => ({
              description: item.productName,
              product_details: "", // If you have any details to include here, you can add them
              category: item.category,
              amount: item.amount,
            })
          );

          setinvoiceLineItems(mappedInvoiceLineItems);

          setImages(data.invoice.images);
          setOtherform({
            ...otherform,
            start_date: formatDate(
              response?.message.data?.lease_term_start_date
            ),
            end_date: formatDate(response?.message?.data?.lease_term_end_date),
          });

          if (data.invoice.type == "recurring") {
            setOtherform({
              ...otherform,
              start_date: formatDate(data.invoice.recurringDetails.startDate),
              end_date: formatDate(
                response?.message?.data?.lease_term_end_date
              ),
              frequency: data.invoice.recurringDetails.frequency,
              number_of_days_until_due:
                data.invoice.recurringDetails.daysUntilDue,
              repeatForever: data.invoice.recurringDetails.repeatForever,
            });
          }
        } else {
          message.error("Failed to fetch invoice data");
        }
      } catch (error) {
        console.error("Error fetching invoice data:", error);
        message.error("Something went wrong while fetching the invoice!");
      }
    };

    console.log("Here state is: ", state);
    if (state?.invoiceNo) {
      console.log("Ddjfffffffffffffffffffffffffffffffk: : ", state);

      console.log("-----------------------------------------")
      console.log("---------------------------------------");
      console.log("kjkjdfkdjfkdjfkj No: ", state?.invoiceNo);
      fetchInvoiceData();

    } else {
      console.log("-----------------------------------------")
      console.log("---------------------------------------");
      console.log("RecurringId No: ", state?.recurringId);
      fetchRecurringInvoiceById()
    }
  }, []);


  const updateInvoice = (sendStatus) => {
    const updatedForm = {
      ...form,
      sendStatus: sendStatus,
    };
    console.log("Form: ", updatedForm);
    console.log("otherform: ", otherform);
    console.log("invoiceLineItems: ", invoiceLineItems);
    console.log("options: ", options);

    // if (Object.keys(newErrors).length === 0) {
    const formdata = invoicedataform(
      updatedForm,
      images,
      otherform,
      invoiceLineItems,
      options
    );

    console.log("FormYYYYYYYYYYYYYYYYYYYYYDATA: ");
    for (let pair of formdata.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }
    updateInvoicefunction(formdata);
    // }
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update the window width state on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    //getLease API
    console.log("LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL");
    console.log(form.property);
    if (form.property.propertyId && form.property.unitId) {
      getLease(form.property.propertyId, form.property.unitId).then(
        (response) => {
          if (response?.apiCallStatus === "success") {
            console.log("from lease in create payable: ", response?.message);
            const { lease_start_date, lease_end_date } =
              response?.message?.data;
            console.log(
              "late fees amojnt:  ",
              typeof response?.message?.data?.late_fees_amount
            );

            setForm({
              ...form,
              frequency: response?.message?.data?.frequency.toLowerCase(),
              latefee: response?.message?.data?.late_fee === "0" ? false : true,
              late_fees_amount: Number(
                response?.message?.data?.late_fees_amount
              ),
            });

            console.log(
              "KKKKLKLKLKLOKKLKPOIJIJ: ",
              response?.message?.data?.id
            );

            setOtherform({
              ...otherform,
              start_date: formatDate(
                response?.message.data?.lease_term_start_date
              ),
              end_date: formatDate(
                response?.message?.data?.lease_term_end_date
              ),
            });

            // Example where you're handling the update directly:
            // Example where you're handling the update:
            var prevRent = 0;
            setinvoiceLineItems((prevItems) => {
              prevRent = prevItems
                .filter((item) => item.category === "Rent")
                .reduce((total, item) => total + (item.amount || 0), 0); // Sum up the amounts
              // Filter out any items where all attributes are empty or where category is 'Rent'

              console.log(prevRent);
              setForm({
                ...form,
                leaseId: response?.message?.data?.id,
                subtotal:
                  form.subtotal +
                  response?.message?.data?.rent_amount -
                  prevRent,
              });

              const filteredItems = prevItems.filter(
                (item) =>
                  (item.description ||
                    item.product_details ||
                    item.category ||
                    item.amount) &&
                  item.category !== "Rent"
              );

              // Add the new 'Rent' item to the filtered list
              return [
                ...filteredItems, // Keep only non-empty and non-'Rent' items
                {
                  description: "Rent",
                  product_details: "This is the rent of House",
                  category: "Rent",
                  amount: response?.message?.data?.rent_amount,
                },
              ];
            });
          }
        }
      );
    }
  }, [form.property.propertyId, form.property.unitId]);

  useEffect(() => {
    selectinvoicecatogries().then((res) => {
      if (res.apiCallStatus === "success") {
        setInvoiceCat(res.message);
      }
    });
  }, []);

  const handleChange = (field, value) => {
    console.log("from changeIIIIIIIIIIIIIIIIIIIIIIIII: ", value);
    setForm({
      ...form,
      [field]: value,
    });
  };
  const handleDiscount = (field, value) => {
    console.log("from hannle disocunt: ", value);
    setForm({
      ...form,
      discount: {
        ...form.discount, // Spread the existing discount object
        amount: value.amount,
        type: value.type,
      },
    });
  };

  const calculateDiscountAmount = () => {
    // console.log("Calling my fucntion",field,value);
    console.log("Form.discount.amount: ", form.discount.amount);
    console.log("Form.discount.type: ", form.discount.type);
    if (form.discount.amount === undefined) {
      form.discount.amount = 0;
    }

    let discount = 0;

    // Calculate discount based on type
    if (form.discount.type === "$") {
      // Fixed amount discount
      discount = form.discount.amount;
    } else if (form.discount.type === "%") {
      // Percentage discount
      discount =
        (form.discount.amount / 100) *
        (Number(form.subtotal) + Number(form.other_amount.value));
      console.log(discount);
    }

    // Ensure discount does not exceed the total amount
    if (discount > Number(form.subtotal + form.other_amount.value)) {
      discount = Number(form.subtotal + form.other_amount.value);
    }

    // const discountedTotal = discount;
    setDiscountAmount(discount);
  };

  useEffect(() => {
    calculateDiscountAmount();
  }, [form.discount, form.other_amount, form.subtotal]); // This will run every time discount is updated

  const handleotherform = (field, value) => {
    if (field === "number_of_days_until_due" && value < 0) {
      setOtherform({ ...otherform, [field]: 0 });
    } else {
      setOtherform({ ...otherform, [field]: value });
    }
  };

  const handleDateChange = (field, e) => {
    console.log("halde cate change");
    handleChange(field, new Date(e).toLocaleDateString());
  };

  //mere kam ka function hai
  const updateInvoicefunction = async (formdata) => {
    console.log("updateInvoice FORM DATA: ", formdata);
    if (state?.invoiceNo) {
      const response = await updateInvoiceCall(formdata, state?.editMode);

      if (response.apiCallStatus === "success") {
        // message.success("Invoice Created Successfully");
        console.log("Invoice Created Successfully", response.message);
        const fullInvoiceNo = response.message.invoice.invoiceNo;
        const invoiceNo = fullInvoiceNo.substring(fullInvoiceNo.indexOf("INV")); // Extract everything starting from 'INV'
        setInvoiceNo(invoiceNo);
        setPostInvoiceData({
          to_name: response.message.invoice.to_name,
          from_name: response.message.invoice.from_name,
          invoice_date: formatDate(response.message.invoice.invoiceDate),
          due_date: response.message.invoice.dueDate,
          invoice_no: invoiceNo,
        });
        setOpenModal(true);
      } else {
        message.error(response.message);
      }
    } else {
      const response = await updateRecurrsiveInvoice(formdata, state?.editMode,state?.recurringId);

      if (response.apiCallStatus === "success") {
        // message.success("Invoice Created Successfully");
        console.log("Invoice Created Successfully", response.message);
       
        setPostInvoiceData({
          to_name: response.message.invoice.to_name,
          from_name: response.message.invoice.from_name,
          invoice_date: formatDate(response.message.invoice.invoiceDate),
          due_date: response.message.invoice.dueDate,
          invoice_no: null,
        });
        setOpenModal(true);
      } else {
        message.error(response.message);
      }
    }
  };

  const handlePropertyChange = (e) => {
    console.log("Selected Property: ", e);
    handleChange("property", { propertyId: e.id, unitId: "" });
    setUnitData(e.units);

    console.log("Selected Units: ", e.units);
  };

  const [openModal, setOpenModal] = useState(false);
  const [options, setOptions] = useState();
  const [DeletedImages, setDeletedImages] = useState([]);
  const [invoiceLineItems, setinvoiceLineItems] = useState([
    {
      description: "",
      product_details: "",
      category: "",
      amount: "",
    },
  ]);
  const removeLineItems = (id) => {
    if (id !== 0) {
      const updatedLineItems = invoiceLineItems.filter((lineItem, index) => {
        return index !== id;
      });
      const deletedInvoiceLineItems = invoiceLineItems.filter(
        (lineItem, index) => {
          return index == id;
        }
      );
      setForm({
        ...form,
        subtotal: form.subtotal - Number(deletedInvoiceLineItems[0]?.amount),
      });
      setTotal(total - Number(deletedInvoiceLineItems[0]?.amount));
      setinvoiceLineItems(updatedLineItems);
    }
  };
  const handleAddInputChange = (fieldName, e, index) => {
    if (fieldName === "amount" && e < 0) {
      const list = [...invoiceLineItems];
      list[index][fieldName] = 0;
      setinvoiceLineItems(list);
    } else {
      console.log(e);
      const list = [...invoiceLineItems];
      list[index][fieldName] = e;
      setinvoiceLineItems(list);
    }
    form.subtotal = 0;
    const subtotal = invoiceLineItems.reduce(
      (sum, item) => sum + Number(item.amount),
      0
    );
    console.log("SUB TOTALLLLL: ", subtotal);
    setForm({
      ...form,
      subtotal: subtotal,
    });
    setTotal(subtotal + Number(form.other_amount.value));
  };
  const handleAdd = () => {
    setinvoiceLineItems([
      ...invoiceLineItems,
      { description: "", product_details: "", category: "", amount: "" },
    ]);
    console.log(invoiceLineItems.category);
  };
  // // Modal Functions
  const onCloseModal = () => {
    navigate("/payment");
    setOpenModal(false);
  };

  const downlaodfun = () => { };

  const containerStyle = {
    position: "relative",
    // display: 'flex',
    width: "150px", // Fixed width
    height: "40px",
  };

  const textStyle = {
    height: "40px",
    borderRadius: "4px",

    overflow: "hidden",
    whiteSpace: "nowrap", // Prevent text from wrapping
    border: isFocused ? "1px solid #EF6B3E" : "none", // Dynamic border style
    outline: isFocused ? "2px solid #3B82F6" : "none", // Dynamic outline when focused
    padding: isFocused ? "8px" : "0px", // Dynamic outline when focused
    width: isFocused ? "200px" : "150px", // Dynamic border style
    backgroundColor: "transparent",
    color: "#344054",
    outline: "none",
  };

  const iconStyle = {
    // position: 'absolute',
    top: "7px",
    left: "80%", // Positioning the icon inside the padding
    fontSize: "14px", // Adjust icon size
    color: "#344054", // Icon color
    cursor: "pointer",
  };
  return (
    <>
      {console.log("Invoice Line Items", form)}
      {openModal && (
        <>
          <Component.FormModalLayout
            title={"Invoice Preview"}
            isOpen={openModal}
            onClose={onCloseModal}
          >
            <InvoiceModal
              onClose={onCloseModal}
              to_name={postInvoiceData.to_name}
              from_name={postInvoiceData.from_name}
              invoice_date={postInvoiceData.invoice_date}
              due_date={postInvoiceData.due_date}
              invoice_no={postInvoiceData.invoice_no}
            // postInvoiceData
            />
          </Component.FormModalLayout>
        </>
      )}
      <div className=" bg-white p-3">
        <div className="container">
          <div className="row ">
            {
              state?.invoiceNo && <div className="col-md-4 mt-3">
                <span className="property-details-input-title">Invoice No</span>
                <input
                  type="text"
                  className="form-control"
                  // value="#TBS24301901"
                  value={form.invoiceNo}
                  disabled
                />
              </div>
            }
            <div className="col-md-4 mt-3">
              <Component.DateField
                required={true}
                label={"Invoice Date"}
                placeholder={"Select Invoice Date"}
                defaultValue={formatDate(form.InvoiceDate)}
                handler={(e) => handleDateChange("InvoiceDate", e)}
                errorMessage={errors.InvoiceDate}
              />
            </div>
            <div className="col-md-4 mt-3">
              <Component.SelectField
                required={true}
                label={"Due Date"}
                value={form.dueDate}
                placeholder={"Select Due Date"}
                options={invoiceduedate.map((e) => {
                  return { value: e.value, label: e.label };
                })}
                handler={(e) => handleChange("Duedate", e)}
                errorMessage={errors.dueDate}
              />
            </div>
            <div className="col-md-12 mt-3">
              <Component.SelectField
                required={true}
                label={"Select Tenant"}
                placeholder={"Select Tenant"}
                options={tenants.map((e) => {
                  console.log("EEEEEEEE :", e._id);
                  return { value: e._id, label: e.fullName };
                })}
                value={form.tenantName}
                handler={(e) => handleChange("SelectTenant", e)}
                errorMessage={errors.SelectTenant}
                disabled={true}
              />
            </div>
            <div className="col-md-6 ">
              {/* {propertyData[0]} */}
              {console.log("Property Name: ", propertyData[0]?.heading2)}
              <Component.SelectPropertyField
                label={"Property"}
                placeholder={"Select Property"}
                isRequired={true}
                // value={propertyData[0].heading2}
                PropertyData={propertyData}
                errorMessage={errors.property}
                selectedPropertyId={propertyData[0]?.id}
                onPropertySelect={(selectedProperty) => {
                  handlePropertyChange(selectedProperty);
                }}
                disabled={true}
              />
            </div>
            <div className="col-md-6 mt-3 ">
              <Component.SelectField
                name={"unit"}
                required={true}
                label={"Unit"}
                TooltipTitle={
                  "Unit can't be selected without selecting property first."
                }
                placeholder={"First select property then unit"}
                value={form.unitName}
                options={unitData.map((e) => ({
                  value: e.id,
                  label: e.name.unit,
                }))}
                handler={(e) => {
                  setForm({
                    ...form,
                    property: { ...form.property, unitId: e },
                  });
                  console.log("Unit", e);
                }}
                errorMessage={errors.unit}
                disabled={true}
              />
            </div>
            <div className="col-md-12 mt-3">
              <Component.SelectField
                required={true}
                label="Select Bank Account"
                placeholder={"Select Bank Account"}
                options={[
                  {
                    value: form.Select_BankAccount.id,
                    label: form.Select_BankAccount.name,
                  },
                ]}
                value={form.bankName}
                handler={(e) => handleChange("Select_BankAccount", e)}
                errorMessage={errors.Select_BankAccount}
                disabled={true}
              />
            </div>
            <div className="mt-3">
              <Component.RadioGroupField
                label={"Invoice Category"}
                options={[
                  { value: 1, label: "One Time Invoice" },
                  { value: 2, label: "Recurring Invoice" },
                ]}
                handler={(e) => console.log()}
                value={form.type === "one time" ? 1 : 2}
                direction="row"
                disabled={true}
              />
            </div>
            {options === 2 && (
              <div className="mt-3 row">
                <div className="col-md-4">
                  <Component.DateField
                    required={true}
                    label={"Start Date"}
                    placeholder={"Select Start Date"}
                    defaultValue={
                      otherform.start_date && formatDate(otherform.start_date)
                    }
                    handler={(e) => handleotherform("start_date", e)}
                    errorMessage={errors.start_date}
                  />
                </div>
                <div className="col-md-4">
                  <Component.DateField
                    required={true}
                    label={"End Date"}
                    placeholder={"Select End Date"}
                    defaultValue={
                      otherform.end_date && formatDate(otherform.end_date)
                    }
                    handler={(e) => handleotherform("end_date", e)}
                    errorMessage={errors.end_date}
                  />
                </div>
                <div className="col-md-4">
                  <Component.SelectField
                    required={true}
                    label={"Frequency"}
                    placeholder={"Select Frequency"}
                    options={[
                      { value: "weekly", label: "Weekly" },
                      { value: "monthly", label: "Monthly" },
                      { value: "quarterly", label: "Quarterly" },
                      { value: "yearly", label: "Yearly" },
                    ]}
                    value={otherform.frequency}
                    handler={(e) => handleotherform("frequency", e)}
                    errorMessage={errors.frequency}
                  />
                </div>
                <div className="col-md-4 mt-3">
                  <Component.InputField
                    name={"number_of_days_until_due"}
                    label={"Number of Days Until Due"}
                    type="number"
                    placeholder={"Number of Days Until Due"}
                    value={otherform.number_of_days_until_due}
                    handler={(e) => {
                      handleotherform(
                        "number_of_days_until_due",
                        e.target.value
                      );
                    }}
                    min={0}
                    errorMessage={errors.number_of_days_until_due}
                  />
                </div>

                <div className="col-md-4 mt-3 mt-4 pt-2">
                  <ConfigProvider
                    theme={{
                      components: {
                        Switch: {
                          colorPrimary: "#EF6B3E",
                          colorPrimaryHover: "#EF6B3E",
                        },
                      },
                    }}
                  >
                    <span className="d-flex gap-2">
                      <Switch
                        onChange={(e) => {
                          setOtherform({
                            ...otherform,
                            repeatForever: !otherform.repeatForever,
                          });
                        }}
                      />
                      Repeat Forever
                    </span>
                  </ConfigProvider>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row mt-3 ">
          {invoiceLineItems.map((data, index) => {
            return (
              <>
                <div key={index + 1} className="col-md-12">
                  <div className="container">
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-column flex-md-row">
                        <div className="col-md-4 col-12">
                          <div className="ribin pt-3 pb-3 mt-3 ps-3 mb-3">
                            DESCRIPTION
                          </div>
                          <div className=" pe-md-4">
                            <Component.InputField
                              name="description"
                              label="Product Name"
                              type="text"
                              placeholder="Product Name"
                              value={data.description}
                              handler={(e) =>
                                handleAddInputChange(
                                  "description",
                                  e.target.value,
                                  index
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-12">
                          <div className="ribin pt-3 pb-3 mt-3 mb-3">
                            CATEGORY
                          </div>
                          <div className="pe-md-4">
                            <Component.SelectField
                              name="category"
                              label={"Select Category"}
                              placeholder={"Select Category"}
                              options={invoiceCat.map((e) => {
                                return { value: e._id, label: e.category };
                              })}
                              value={data.category}
                              handler={(e) =>
                                handleAddInputChange("category", e, index)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-12">
                          <div className="ribin pt-3 pb-3  mt-3 mb-3 d-flex align-items-center justify-content-between">
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                marginLeft: "10px",
                              }}
                            >
                              AMOUNT
                            </div>
                            {index !== 0 && (
                              <div
                                className="trash cursor align-items-center "
                                style={{
                                  marginRight: "30px",
                                }}
                                onClick={() => removeLineItems(index)}
                              >
                                <svg
                                  width={20}
                                  height={20}
                                  fill="none"
                                  stroke="#D92D20"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M3 6h18" />
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                  <path d="M10 11v6" />
                                  <path d="M14 11v6" />
                                </svg>
                              </div>
                            )}
                          </div>

                          <div className=" ">
                            <Component.InputField
                              name="amount"
                              label="Amount"
                              type="number"
                              placeholder="Amount"
                              value={data.amount}
                              handler={(e) =>
                                handleAddInputChange(
                                  "amount",
                                  e.target.value,
                                  index
                                )
                              }
                              min={0}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mt-3">
                        <Component.TextAreaField
                          name="product_details"
                          label="Product Details"
                          type="text"
                          rows={4}
                          cols={10}
                          placeholder="Product Details"
                          value={data.product_details}
                          handler={(e) =>
                            handleAddInputChange(
                              "product_details",
                              e.target.value,
                              index
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <div className="container mb-4 mt-4">
          <div className="row mt-3 mb-4 ">
            <div className="col-md-12 text-start ">
              <button
                onClick={handleAdd}
                className="save-btn-outline primary-orange-text fw-bold"
              >
                + Add Item
              </button>
            </div>
          </div>
          <div className="border-top">
            <div className="row mt-4">
              <div className=" col-md-4 col-12">
                <Component.SelectField
                  label={"Late Fees"}
                  placeholder={"Select Late Fees"}
                  options={[
                    { value: true, label: "Yes" },
                    { value: false, label: "No" },
                  ]}
                  value={form.latefee ? "Yes" : "No"}
                  handler={(e) => handleChange("latefee", e)}
                />
              </div>
              <div className="col-md-4 mt-3 mt-md-0">
                <Component.SelectField
                  label={"Frequency"}
                  placeholder={"Select Frequency"}
                  options={[
                    { value: "daily", label: "Daily" },
                    { value: "weekly", label: "Weekly" },
                    { value: "monthly", label: "Monthly" },
                    { value: "yearly", label: "Yearly" },
                  ]}
                  value={form.frequency}
                  handler={(e) => handleChange("frequency", e)}
                  errorMessage={errors.frequency}
                />
              </div>
              <div className="col-md-4 col-12 mt-3 mt-md-0">

                <Component.AmountField
                  name={"late_fees_amount"}
                  label={"Amount"}
                  placeholder={
                    form.late_fees_amount.type === "%"
                      ? "Enter Late Fees Percentage"
                      : "Enter Late Fees Amount"
                  }
                  value={{
                    amount: form?.late_fees_amount?.amount,
                    type: form.late_fees_amount.type,
                  }}
                  handler={(value) => handleChange("late_fees_amount", value)}
                  amountTypes={["$", "%"]}
                  disabled={form.latefee === true ? false : true}
                />
              </div>
            </div>
          </div>

          {/* //add fields for total and subtoal */}
          <div className="container mt-5 mr-5">
            {/* Sub total Section */}
            <div
              className="row md:column col-md-11  justify-content-end mr-5"
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontSize: "16px",
                lineHeight: "20px",
                textAlign: "left",
                color: "#344054",
                padding: "10px",
                borderRadius: "4px",
              }}
            >
              <div className="col-md-6 offset-md-6 d-flex justify-content-between">
                <span className="">SUBTOTAL</span>
                <span className="">${form.subtotal}.00</span>
              </div>
            </div>

            {/* Discount Section */}
            <div
              className="row col-md-11  justify-content-end"
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontSize: "16px",
                lineHeight: "20px",
                textAlign: "left",
                color: "#344054",
                padding: "10px",
                borderRadius: "4px",
              }}
            >
              <div className="col-md-6 offset-md-6 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
                <span>DISCOUNT</span>
                <div
                  style={{
                    width: window.innerWidth > 768 ? "40%" : "80%",
                    marginTop: window.innerWidth > 768 ? "0" : "5px",
                  }}
                >
                  <Component.AmountField
                    name={"discount"}
                    // label={"Amount"}
                    placeholder={
                      form.discount.type === "%"
                        ? "Enter Discount in Percentage"
                        : "Enter Dicount Amount"
                    }
                    value={{
                      amount: form.discount.value,
                      type: form?.discount?.type,
                    }}
                    handler={(value) => handleDiscount("discount", value)}
                    amountTypes={["$", "%"]}
                  // disabled={form.latefee === true ? false : true}
                  />
                </div>
              </div>
            </div>

            {/* Other Amount Section */}
            <div
              className="row col-md-11 justify-content-end"
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontSize: "16px",
                lineHeight: "20px",
                textAlign: "left",
                color: "#344054",
                padding: "10px",
                borderRadius: "4px",
              }}
            >
              <div className="col-md-6 offset-md-6 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
                <div
                  style={containerStyle}
                  className="d-flex flex-row justify-content-between align-items-start align-items-md-center"
                >
                  <input
                    // contentEditable={true}
                    ref={inputRef} // Attach the ref to the input
                    style={textStyle}
                    onBlur={handleBlur}
                    onFocus={handleIconClick}
                    value={form.other_amount.field}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        other_amount: {
                          ...form.other_amount,
                          field: e.target.value, // Use innerText to get the updated content
                        },
                      });
                    }}
                  />

                  <EditOutlined
                    onClick={handleIconClick}
                    style={{
                      iconStyle,
                      display: isFocused ? "none" : "",
                    }}
                  />
                </div>

                <div
                  style={{
                    width: window.innerWidth > 768 ? "40%" : "80%",
                    marginTop: window.innerWidth > 768 ? "0" : "5px",
                  }}
                >
                  <Component.AmountField
                    name={"other_amount"}
                    // label={"Amount"}
                    placeholder={"0.00"}
                    value={{
                      amount: form.other_amount.value,
                      //   type: form?.other_amount?.type,
                    }}
                    handler={(value) =>
                      setForm({
                        ...form,
                        other_amount: {
                          ...form.other_amount,
                          value: value.amount,
                        },
                      })
                    }
                    amountTypes={["$"]}
                  // disabled={form.latefee === true ? false : true}
                  />
                </div>
              </div>
            </div>

            {/* Total Section */}
            <div
              className="row col-md-11 justify-content-end "
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "20px",
                textAlign: "left",
                color: "#344054",
                padding: "10px",
                borderRadius: "4px",
              }}
            >
              <div className="col-12 col-md-6  d-flex  justify-content-between">
                <span className="text-uppercase " style={{ fontWeight: "600" }}>
                  Total
                </span>
                <span className="" style={{ fontWeight: "600" }}>
                  $
                  {Number(form.subtotal) +
                    Number(form.other_amount.value) -
                    discountAmount}
                </span>
              </div>
            </div>
          </div>

          <div className="mt-4 mr-4">
            <Component.MediaField
              label={"Upload or Attach files"}
              value={images}
              setImages={setImages}
              Images={images}
              DeletedImages={DeletedImages}
              setDeletedImages={setDeletedImages}
            />
          </div>
          <div className="mt-4">
            <Component.TextAreaField
              name="message"
              label="Message"
              type="text"
              rows={4}
              cols={10}
              placeholder="Enter the message"
              value={form.message}
              handler={(e) => handleChange("message", e.target.value)}
            />
          </div>
        </div>
        <div className="container ">
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center ">
            <div className="mb-3 mb-md-0">
              <Link
                className="primary-orange-text fw-semibold td"
                onClick={() => updateInvoice("sent")}
              >
                Save Invoice
              </Link>
            </div>
            <div className="d-flex  gap-2" style={{ width: "350px" }}>
              <button
                onClick={downlaodfun}
                className={`recuring-btn text-nowrap`}
              >
                Download
              </button>
              <button
                onClick={() => updateInvoice("sent")}
                className={`next-btn-same-class text-nowrap`}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceEdit;
