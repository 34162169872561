import axios from "axios";
import config from "Helpers/config.js";

const fetchUserTenant = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/auth/tenant/userinfo`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const generateIncomeVerificationToken = async (data) => {
  console.log("Generating verification token");
  console.log("--------------------------------");
  console.log(localStorage.getItem("token"));
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/plaid/incomeVerification`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    console.log(response);
    return response.data.message;
  } catch (error) {
    return error.response.data;
  }
};

const generateIdentityVerificatoinLink = async (data) => {
  console.log("Generating verification token");
  console.log("--------------------------------");
  console.log("Generating identity: ", data);
  console.log(localStorage.getItem("token"));
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/plaid/identityVerification`,

      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json", // Ensure JSON format
        },
      }
    );
    console.log(response);
    return response.data.message;
  } catch (error) {
    return error.response.data;
  }
};

const exchangePublicToken = async (publicToken) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/plaid/exchangePublicToken`,
      {
        public_token: publicToken,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    console.log(response.data);
    return response.data.access_token;
  } catch (error) {
    return error.response.data;
  }
};

const handleIncomeVerificationSuccess = async () => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/plaid/incomeVerificationSuccess`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    console.log("income verification success: ", response);
    return response.data.message;
  } catch (error) {
    return error.response.data;
  }
};
const handleIncomeVerificationFailure = async () => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/plaid/incomeVerificationSuccess`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    console.log("Income verfication failed: ", response.data);
    return response.data.message;
  } catch (error) {
    return error.response.data;
  }
};
const createPaymentIntent = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/stripe/authorizePassportPayment`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    console.log("Payment: ", response);

    return response;
  } catch (error) {
    console.error("Error creating payment intent:", error);
    return error;
  }
};
const refundPayment = async () => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/stripe/refundPassportPayment`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    console.log("Refund Payment: ", response);
    return response.data.message;
  } catch (error) {
    return error.response.data;
  }
};

// Helper function to update the database based on the current step
const updateStepInDatabase = async (step, data) => {
  try {
    console.log("Update Step", data);
    
    // Initialize FormData object
    const formData = new FormData();

    // Recursive function to handle nested data structures
    const appendFormData = (formData, key, value) => {
      if (Array.isArray(value)) {
        if (key === 'images' || key.includes('images')) {
          // Handle arrays of images/files
          console.log("key: ", key)
          value.forEach((file, index) => {
            console.log("file: ", file)
            console.log("index: ", index)
            console.log("key: ", key)
            formData.append(`${key}[${index}]`, file); // Append each file individually
          });
        } else {
          // For other arrays, stringify and append
          formData.append(key, JSON.stringify(value));
        }
      } else if (value && typeof value === "object" && !(value instanceof File)) {
        // If value is an object and not a File, recursively add each nested key
        for (const nestedKey in value) {
          appendFormData(formData, `${key}[${nestedKey}]`, value[nestedKey]);
        }
      } else {
        // If value is a simple type or File, append directly
        formData.append(key, value);
      }
    };

    // Append each key-value pair to formData
    for (const key in data) {
      appendFormData(formData, key, data[key]);
    }

    // Log FormData contents
    console.log("FormData contents:");
    for (let pair of formData.entries()) {
      console.log(pair[0] + ":", pair[1]);
    }

    const response = await fetch(
      `${config.baseUrl}/api/passport/submitPassport/${step}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formData, // Send formData as the request body
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update data in database");
    }

    console.log("Data updated successfully for", step);
  } catch (error) {
    console.error("Failed to update data:", error);
    throw error;
  }
};

  const getPassportDetails = async()=>{
    try{
      const response = await axios.get(
        `${config.baseUrl}/api/passport/getPassportDetails`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("Passport Details: ", response.data);
      return response.data;
    }catch(error){
      console.error("Failed to fetch passport details:", error);
      throw error;
    }
  }
  
   // Fetch Tenant Screening requests using Axios with async/await
const fetchTenantScreening = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/renter/transunion/viewScreeningStatus`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    // Checking the response status
    if (response.data.apiCallStatus === "success") {
      console.log(response.data); // Successful response
      return response.data; // Return the data if needed
    } else {
      console.log(response.data, "error");
      // message.error(response.data.message || "Failed to fetch tenant screening status");
    }
  } catch (error) {
    console.error("Error fetching tenant screening status:", error);
    // message.error("Failed to fetch tenant screening status");
  }
};

const handleDownloadPassport = async (screeningId) => {
  console.log("Downloading.. final report")
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/renter/transunion/viewFinalReport`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        // Use `params` for GET requests instead of `body`
        body: {
          screeningId,
          requestedProduct: "Credit",
        },
        responseType: "blob", // Ensure the response is in Blob format for PDF
      }
    );

    console.log("response Data: ", response)

    // Create a new Blob from the response data
    const pdfBlob = new Blob([response.data], { type: "application/pdf" });

    // Create a link element to download the PDF
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(pdfBlob);
    downloadLink.download = `Tenant_Report_${screeningId}.pdf`; // Set download file name
    document.body.appendChild(downloadLink);

    // Programmatically trigger the download
    downloadLink.click();

    // Clean up
    URL.revokeObjectURL(downloadLink.href);
    document.body.removeChild(downloadLink);

  } catch (error) {
    console.error("Error downloading the report:", error);
    alert("Failed to download the report. Please try again.");
  }
};

const getAllLandlords = async()=>{
  try{
    const response = await axios.get(
      `${config.baseUrl}/api/renter/transunion/getAllLandlords`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    console.log("All Landlords: ", response.data);
    return response.data;
  }catch(error){
    console.error("Failed to fetch all landlords:", error);
    throw error;
  }
}

export {
  fetchUserTenant,
  fetchTenantScreening,
  generateIncomeVerificationToken,
  generateIdentityVerificatoinLink,
  exchangePublicToken,
  handleIncomeVerificationSuccess,
  handleIncomeVerificationFailure,
  refundPayment,
  updateStepInDatabase,
  getPassportDetails,
  handleDownloadPassport,
  createPaymentIntent,
  getAllLandlords
};
