import { Link } from "react-router-dom";
import MapLine from "assets/MapPinLineSm.png";
import dollar from "assets/CurrencyDollar.png";
import LocalServiceProfessionalSearch from "Helpers/LocalServiceProfessionalSearch";
import { FirstCharacterIcon } from "Components/GeneralComponents";

const FindLocalProfessionalTab = ({ professional }) => {
  return (
    <div>
      <LocalServiceProfessionalSearch />
      {professional.map((data) => (
        <div className="local-professional-container mt-4" key={data.id}>
          <Link to={`/all-service-professional-details?id=${data.id}`}>
            <div className="local-professional-box d-flex align-items-start gap-3">
              <div
                className="d-flex align-items-center justify-content-center  flex-shrink-0"
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                {data.companyLogo ? (
                  <img
                    src={data.companyLogo}
                    alt="task"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <FirstCharacterIcon name={data.companyName} />
                )}
              </div>
              <div className="local-professional-content">
                <div className="local-top-heading">{data.companyName}</div>
                {data.new && (
                  <div className="local-main-heading d-flex align-items-center gap-2">
                    {data.services.name || "-"}{" "}
                    <span className="new-post">New post</span>
                  </div>
                )}
                <div className="local-info d-flex gap-3 align-items-center">
                  <div className="d-flex align-items-center gap-2">
                    <img src={MapLine} alt="" /> {data.state.name}
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <img src={dollar} alt="" /> {data.charges || "-"}
                  </div>
                </div>
                <div className="local-desc mt-1">{data.description}</div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default FindLocalProfessionalTab;
