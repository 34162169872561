import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import AddPropertyDetails from "Components/AddPropertyDetails/AddPropertyDetails";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
const PropertiesNew = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail="Add Property Details"
        pop={true}
      />
      <AddPropertyDetails />
    </div>
  );
};

export default PropertiesNew;
