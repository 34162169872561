import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import TenantPassportDetailsView from "Components/TenantPassportDetailsView/TenantPassportDetailsView";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const PassportDetail = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Tenant Passport Details"}
        back="tenant-passport"
      />
      <TenantPassportDetailsView />
    </div>
  );
};

export default PassportDetail;
