import React from "react";

const Checkbox = ({ label, checked, onChange }) => {
  return (
    <div className="modal-check-box-container d-flex align-items-center">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className={checked ? "checked" : ""}
      />
      <span className="tenant-personal-details-title ms-1">{label}</span>
    </div>
  );
};

export default Checkbox;
