import { useState } from "react";
import * as InnerComponent from "./index";
import { useNavigate } from "react-router-dom";
import { validateLandlordUserRoleGeneralInfo } from "utils/validations";
import { createUserRole } from "network/landlord/setting";
import { SuccessModal } from "Components/GeneralComponents";

const AddUserRole = () => {
  // States start
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({
    role: "",
    description: "",
  });

  const handleFormChange = (fieldName, value) => {
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  const [check, setCheck] = useState({
    LL_Dashboard: { view: true, add: false, update: false, delete: false },
    properties: { view: true, add: false, update: false, delete: false },
    units: { view: true, add: false, update: false, delete: false },
    tenants: { view: true, add: false, update: false, delete: false },
    task: { view: true, add: false, update: false, delete: false },
    invoicing: { view: true, add: false, update: false, delete: false },
    prospects: { view: true, add: false, update: false, delete: false },
    profileManagement: { view: true, add: false, update: false, delete: false },
    settings_profile: { view: true, add: false, update: false, delete: false },
    settings_changePassword: { view: true, add: false, update: false, delete: false },
    settings_Subscription: { view: true, add: false, update: false, delete: false },
    settings_ManageUsers: { view: true, add: false, update: false, delete: false },
    reports: { view: true, add: false, update: false, delete: false },
    workOrders: { view: true, add: false, update: false, delete: false },
    leases: { view: true, add: false, update: false, delete: false },
    accounting: { view: true, add: false, update: false, delete: false },
    mileage: { view: true, add: false, update: false, delete: false },
  });
  
  const handleChange = (module, fieldName, value) => {
    setCheck((prev) => ({
      ...prev,
      [module]: {
        ...prev[module],
        [fieldName]: value,
      },
    }));
  };

  // Modal Function
  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
    navigate("/settings/user-role");
  };
  // Stepper Function
  const handleNext = () => {
    const newErrors = validateLandlordUserRoleGeneralInfo(form);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const handlePrev = () => {
    if (currentStep <= 1) {
      navigate("/settings/user-role");
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  let addUserRole = () => {
    const data = {
      role: form.role,
      description: form.description,
      ...check,
    };

    setLoader(true);

    createUserRole(data).then((res) => {
      setLoader(false);
      if (res.apiCallStatus === "success") {
        onOpenModal();
      }
    });
  };
  return (
    <>
      {openModal && (
        <SuccessModal
          heading={"User Role Added Successfully"}
          title={"User Role has been added successfully."}
          route={"./settings/user-role"}
          success={"Go to User Role"}
          onClose={onCloseModal}
        />
      )}
      <div className="container-fluid bg-white  p-3">
        <InnerComponent.Stepper currentStep={currentStep} />
        <div className="stepper-content-container mt-4 add-account-details-stepper-content">
          {currentStep === 1 && (
            <InnerComponent.GeneralInfo
              form={form}
              errors={errors}
              handleChange={handleFormChange}
              handleNext={handleNext}
              handlePrev={handlePrev}
            />
          )}
          {currentStep === 2 && (
            <InnerComponent.PermissionLevel
              isLoading={loader}
              handleChange={handleChange}
              handlePrev={handlePrev}
              handleNext={addUserRole}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AddUserRole;
