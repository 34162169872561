import React, { useState, useEffect, useMemo } from "react";
import { availableProperties } from "network/landlord/prospect";
import { formatDate } from "utils";
import {
  selectBoolean,
  selectLeaseTerm,
  selectFrequency,
  selectFrequencyFromThree,
  selectLateFeeFrequency,
} from "utils/dropdownData";
import * as Component from "Components/GeneralComponents";

const LeaseInfo = ({
  form,
  handleChange,
  errors,
  handlePropertyType,
  handleNext,
  isLoading = false,
}) => {
  const [state, setState] = useState({
    properties: [],
    units: [],
    makeEmptyUnit: false,
    data: [],
    isWarning: false,
    isPropertyAvailable: false,
    isProspectFetched: false,
  });

  const [isUnitAvailableModal, setIsUnitAvailableModal] = useState(false);

  const {
    properties,
    units,
    makeEmptyUnit,
    data,
    isWarning,
    isPropertyAvailable,
    isProspectFetched,
  } = state;

  useEffect(() => {
    availableProperties().then((response) => {
      if (response.apiCallStatus === "success") {
        const propertiesWithUnits =
          response.message.propertiesWithUnassignedUnits;
        const data = propertiesWithUnits.map((e) => ({
          id: e._id,
          heading1: e.property_type,
          heading2: e.title,
          heading3: e.address,
        }));

        setState((prev) => ({
          ...prev,
          properties: propertiesWithUnits,
          data,
        }));
      }
    });
  }, []);

  // Memoize options for select fields to avoid recalculations
  const unitOptions = useMemo(
    () => units.map((e) => ({ value: e.id, label: e.name })),
    [units]
  );

  // Fetch available properties
  useEffect(() => {
    if (form.property.id) {
      let selectedProperty = state.data.find((e) => e.id === form.property.id);

      setState((prev) => ({
        ...prev,
        isWarning: !selectedProperty,
        isPropertyAvailable: selectedProperty ? true : false,
      }));
    }
  }, [form.property.id]);

  useEffect(() => {
    if (form.unit && unitOptions.length > 0) {
      const selectedUnit = unitOptions.find((unit) => unit.value === form.unit);

      // console.log("<===============================================>");
      // console.log("Form Unit =====> ", form.unit);
      // console.log("unitOptions =====> ", unitOptions);
      // console.log("selectedUnit =====> ", selectedUnit);
      // console.log("<===============================================>");

      if (!selectedUnit) {
        handleChange("unit", "");
        setIsUnitAvailableModal(true);
      }
    }
  }, [state.units, form.unit]);

  const resetForm = () => {
    setState((prev) => ({
      ...prev,
      isWarning: false,
      units: [],
    }));
    handleChange("property", {
      id: "",
      heading1: "",
      heading2: "",
      heading3: "",
    });
    handleChange("unit", "");
  };

  // Update units when properties or selected property changes
  useEffect(() => {
    if (form.property.id && properties.length > 0) {
      const selectedProperty = properties.find(
        (property) =>
          property._id === form.property.id || property.id === form.property.id
      );

      if (selectedProperty) {
        handlePropertyType(selectedProperty.property_type);
        setState((prev) => ({ ...prev, units: selectedProperty.units }));
      }
    }
  }, [form.property.id]);

  // Handle property change
  const handlePropertyChange = (e) => {
    handleChange("property", e);
    const selectedProperty = properties.find(
      (property) => property._id === e.id
    );

    if (selectedProperty) {
      handlePropertyType(selectedProperty.property_type);
      setState((prev) => ({
        ...prev,
        units: selectedProperty.units,
        makeEmptyUnit: true,
      }));
    }
  };

  // Clear unit when property changes
  useEffect(() => {
    if (makeEmptyUnit) {
      isProspectFetched && handleChange("unit", "");
      setState((prev) => ({
        ...prev,
        makeEmptyUnit: false,
        isProspectFetched: true,
      }));
    }
  }, [makeEmptyUnit]);

  return (
    <div className="container-fluid">
      <Component.WarningModal
        isOpen={isWarning}
        onClose={resetForm}
        message="Interested property is not available, please choose a different one"
      />

      <Component.WarningModal
        isOpen={isUnitAvailableModal}
        onClose={() => setIsUnitAvailableModal(false)}
        message="Interested unit is not available, please choose a different one"
      />

      <div className="row">
        <div className="col-md-12">
          <Component.SelectPropertyField
            label="Property"
            placeholder="Select Property"
            category="lease"
            isRequired
            PropertyData={data}
            selectedPropertyId={isPropertyAvailable ? form.property.id : ""}
            errorMessage={errors.property}
            onPropertySelect={handlePropertyChange}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <Component.SelectField
            name="unit"
            required
            label="Unit"
            TooltipTitle="Unit can't be selected without selecting property first."
            placeholder="First select property then unit"
            value={form.unit}
            options={unitOptions}
            handler={(e) => handleChange("unit", e)}
            errorMessage={errors.unit}
            disabled={units.length === 0}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <Component.SelectField
            name={"lease_term"}
            required={true}
            label={"Lease Term"}
            placeholder={"Select Lease Term"}
            value={form.lease_term}
            options={selectLeaseTerm}
            handler={(e) => handleChange("lease_term", e)}
            errorMessage={errors.lease_term}
          />
        </div>
        <div className="col-md-4">
          <Component.DateField
            required={true}
            label={"Lease Term Start Date"}
            placeholder={"Select Start Date"}
            defaultValue={
              form.lease_term_start_date &&
              formatDate(form.lease_term_start_date)
            }
            handler={(e) => {
              const formattedDate = formatDate(new Date(e));
              handleChange("lease_term_start_date", formattedDate);
            }}
            errorMessage={errors.lease_term_start_date}
          />
        </div>
        <div className="col-md-4">
          <Component.DateField
            required={!(form.lease_term === "Month-to-Month")}
            label={"Lease Term End Date"}
            placeholder={"Select End Date"}
            defaultValue={
              form.lease_term_end_date && formatDate(form.lease_term_end_date)
            }
            handler={(e) => {
              const formattedDate = formatDate(new Date(e));
              handleChange("lease_term_end_date", formattedDate);
            }}
            errorMessage={errors.lease_term_end_date}
            disabled={form.lease_term === "Month-to-Month"}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <Component.DateField
            required={true}
            label={"First Rent Date"}
            placeholder={"Select Date"}
            defaultValue={
              form.first_rent_date && formatDate(form.first_rent_date)
            }
            handler={(e) => {
              const formattedDate = formatDate(new Date(e));
              handleChange("first_rent_date", formattedDate);
            }}
            errorMessage={errors.first_rent_date}
          />
        </div>
        <div className="col-md-4">
          <Component.SelectField
            name={"frequency"}
            required={true}
            label={"Frequency"}
            placeholder={"Select Frequency"}
            value={form.frequency}
            options={
              form.lease_term === "Month-to-Month"
                ? selectFrequencyFromThree
                : selectFrequency
            }
            handler={(e) => handleChange("frequency", e)}
            errorMessage={errors.frequency}
          />
        </div>
        <div className="col-md-4">
          <Component.AmountField
            name={"rent_amount"}
            required={true}
            label={"Rent Amount"}
            placeholder={"Enter Rent Amount"}
            value={form.rent_amount}
            handler={(value) => handleChange("rent_amount", value)}
            errorMessage={errors.rent_amount}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6">
          <Component.SelectField
            name={"security_deposit"}
            label={"Security Deposit"}
            placeholder={"Select Security Deposit"}
            value={form.security_deposit}
            options={selectBoolean}
            handler={(e) => handleChange("security_deposit", e)}
          />
        </div>
        <div className="col-md-6">
          <Component.AmountField
            name={"security_deposit_amount"}
            label={"Security Deposit Amount"}
            placeholder={"Enter Security Deposit Amount"}
            value={form.security_amount}
            handler={(value) => handleChange("security_amount", value)}
            disabled={form.security_deposit === "Yes" ? false : true}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6">
          <Component.SelectField
            name={"late_fee"}
            label={"Late Fees"}
            placeholder={"Select Late  Fees"}
            value={form.late_fee}
            options={selectBoolean}
            handler={(e) => handleChange("late_fee", e)}
          />
        </div>

        <div className="col-md-6">
          <Component.InputField
            name={"late_fee_charge"}
            label={"When to Charge Late Fees?"}
            type={"number"}
            placeholder={"Days after rent due"}
            value={form.late_fee_charge}
            handler={(e) => handleChange("late_fee_charge", e.target.value)}
            disabled={form.late_fee === "Yes" ? false : true}
            TooltipTitle={"Set the number of days following the due date before a late fee is charged."}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6">
          <Component.SelectField
            name={"late_fee_frequency"}
            label={"Frequency"}
            placeholder={"Select Frequency"}
            value={form.late_fee_frequency}
            options={selectLateFeeFrequency}
            handler={(e) => handleChange("late_fee_frequency", e)}
            disabled={form.late_fee === "Yes" ? false : true}
          />
        </div>

        <div className="col-md-6">
          <Component.AmountField
            name={"late_fees_amount"}
            label={"Late Fee Amount"}
            placeholder={
              form.late_fees_amount.type === "%"
                ? "Enter the late fee percentage amount."
                : "Enter late fee amount."
            }
            value={form.late_fees_amount}
            handler={(value) => handleChange("late_fees_amount", value)}
            amountTypes={["$", "%"]}
            disabled={form.late_fee === "Yes" ? false : true}
          />
        </div>
      </div>
      <div className="stepper-first-btn property-details-next-btn-main d-flex justify-content-between gap-3 mt-5">
        <Component.SubmitButton isLoading={isLoading} handler={handleNext}>
          Next
        </Component.SubmitButton>
      </div>
    </div>
  );
};

export default LeaseInfo;
