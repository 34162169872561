import axios from "axios";
import config from "Helpers/config.js";

const createVendor = async (data) => {
  try {
    const response = await axios.post(`${config.baseUrl}/api/vendors`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getVendors = async (filters) => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/vendors`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: filters,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getVendor = async (id) => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/vendors/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateVendor = async (id, data) => {
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/vendors/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteVendor = async (id, force) => {
  try {
    const response = await axios.delete(`${config.baseUrl}/api/vendors/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        force: force,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteMultipleVendors = async (data, force) => {
  try {
    const response = await axios.delete(`${config.baseUrl}/api/vendors`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        force: force,
      },
      data: data,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const verifyEmail = async (email) => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/verify/vendors`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        email: email,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export {
  createVendor,
  getVendors,
  getVendor,
  updateVendor,
  deleteVendor,
  deleteMultipleVendors,
  verifyEmail,
};
