import React from "react";
import { Tabs } from "antd";
// import ImageSwiper from "Components/GeneralComponents/ImageSwiper";
import { AmenitiesTab } from "Components/TenantPropertyUnitsView";
import UnitDescription from "./UnitDescription";
import UnitDetailsViewer from "Components/GeneralComponents/UnitDetailsViewer";
import Loader from "Helpers/Loader";

const OverviewTab = ({ unitData, isLoading }) => {
  console.log("Unit Data >>>", unitData);
  const { TabPane } = Tabs;

  // const formateImageViewerData = (data) => {
  //   return {
  //     title: data?.name || "",
  //     images: data?.images || [],
  //     address: `${data?.address?.address_line_1} , ${data?.address?.city}`,
  //     date: data.created_at,
  //   };
  // };
  const images = unitData?.images || [];

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {/* <ImageSwiper data={formateImageViewerData(unitData)} /> */}
          <UnitDetailsViewer
            title={unitData?.name || ""}
            address={`${unitData?.address}`}
            images={images || []}
          />
          <Tabs defaultActiveKey="7" className="property_details_view_sub_tabs mx-5">
            <TabPane tab="Description" key="7">
              <UnitDescription unitData={unitData} />
            </TabPane>
            <TabPane tab="Amenities" key="8">
              <AmenitiesTab amenities={unitData.amenities} />
            </TabPane>
          </Tabs>
        </>
      )}
    </div>
  );
};

export default OverviewTab;
