import React from "react";
import { selectVendorServices } from "utils/dropdownData";
import * as Component from "Components/GeneralComponents";

const Services = ({
  form,
  handleChange,
  errors,
  handleBackButton,
  handleNext,
}) => {
  return (
    <div className="container-fluid">
      <div className="row mt-3">
        <div className="col-md-12">
          <Component.SelectField
            name={"services"}
            required={true}
            label={"What does this vendor do?"}
            placeholder={"Select Service"}
            value={form.services}
            options={selectVendorServices}
            handler={(e) => handleChange("services", e)}
            errorMessage={errors.services}
          />
        </div>
      </div>
      <div className="stepper-btn d-flex justify-content-between mt-5 gap-1">
        <div style={{ width: "50%" }}>
          <Component.SubmitButton type="contained" handler={handleBackButton}>
            Back
          </Component.SubmitButton>
        </div>
        <div style={{ width: "50%" }}>
          <Component.SubmitButton handler={handleNext}>
            Next
          </Component.SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default Services;
