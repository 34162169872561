import React from "react";

function EditInvoice({ width = 21, height = 21 }) {
  return (
    <>
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        data-name="Layer 1"
        viewBox="0 0 512 512"
        fill="currentColor"

      >
        <path d="M411,66.8a6,6,0,0,0,6-6V51a6,6,0,0,0-12,0v9.8A6,6,0,0,0,411,66.8Z" />
        <path d="M64.541,229.835h2.9a6,6,0,1,0,0-12h-2.9a6,6,0,1,0,0,12Z" />
        <path d="M96.411,229.835h21.933a6,6,0,0,0,0-12H96.411a6,6,0,1,0,0,12Z" />
        <path d="M147.781,229.835H305a6,6,0,0,0,0-12H147.781a6,6,0,0,0,0,12Z" />
        <path d="M64.541,167.979h2.9a6,6,0,1,0,0-12h-2.9a6,6,0,0,0,0,12Z" />
        <path d="M96.411,167.979h21.933a6,6,0,0,0,0-12H96.411a6,6,0,1,0,0,12Z" />
        <path d="M147.781,167.979H305a6,6,0,0,0,0-12H147.781a6,6,0,0,0,0,12Z" />
        <path d="M64.541,106.124h2.9a6,6,0,1,0,0-12h-2.9a6,6,0,0,0,0,12Z" />
        <path d="M96.411,106.124h21.933a6,6,0,0,0,0-12H96.411a6,6,0,0,0,0,12Z" />
        <path d="M147.781,106.124H305a6,6,0,0,0,0-12H147.781a6,6,0,0,0,0,12Z" />
        <path d="M64.541,291.691h2.9a6,6,0,0,0,0-12h-2.9a6,6,0,0,0,0,12Z" />
        <path d="M96.411,291.691h21.933a6,6,0,0,0,0-12H96.411a6,6,0,0,0,0,12Z" />
        <path d="M147.781,291.691H305a6,6,0,0,0,0-12H147.781a6,6,0,0,0,0,12Z" />
        <path d="M83.115,420.869c-.044,0-.087-.006-.131-.006s-.086.006-.13.006a12.453,12.453,0,0,1-12.313-12.436,6,6,0,0,0-12,0,24.484,24.484,0,0,0,18.443,23.695v5.721a6,6,0,0,0,12,0v-5.721a24.443,24.443,0,0,0-6-48.139,12.444,12.444,0,1,1,12.444-12.444,6,6,0,0,0,12,0,24.486,24.486,0,0,0-18.444-23.7v-4.881a6,6,0,0,0-12,0v4.881a24.444,24.444,0,0,0,6,48.14,12.44,12.44,0,0,1,.131,24.88Z" />
        <path d="M239.521,404.283H223.658a6,6,0,0,0,0,12h15.863a6,6,0,0,0,0-12Z" />
        <path d="M142.316,416.283H197.37a6,6,0,0,0,0-12H142.316a6,6,0,0,0,0,12Z" />
        <path d="M495.289,229.6l-9.148-27.257a6,6,0,0,0-3.78-3.779L455.1,189.411a6,6,0,0,0-6.151,1.445l-95.811,95.811V189.122H439.1a6,6,0,0,0,6-6V61.978a52.041,52.041,0,0,0-49.46-51.914V10H75.183A58.851,58.851,0,0,0,16.4,68.784V496a6,6,0,0,0,9.748,4.686L63,471.209l36.852,29.477a6,6,0,0,0,7.5,0l36.845-29.476,36.844,29.476a6,6,0,0,0,7.5,0L225.3,471.271l35.221,29.338a6,6,0,0,0,7.588.076l36.915-29.533,38.457,29.6A6,6,0,0,0,353.142,496V376.448l140.7-140.7A6,6,0,0,0,495.289,229.6Zm-73.784,5.678,9.718,9.718L299.562,376.652l-9.717-9.717ZM284.262,378.322l22.115,22.116h0l-15.24,4.483-11.357-11.358,4.484-15.239ZM393.1,22h.038A40.024,40.024,0,0,1,433.1,61.978V177.122H353.142V61.978A40.023,40.023,0,0,1,393.1,22ZM341.142,483.81l-32.529-25.039a6,6,0,0,0-7.408.069l-36.767,29.414-35.221-29.338a6,6,0,0,0-7.588-.075l-36.845,29.475L147.94,458.841a6,6,0,0,0-7.5,0L103.6,488.316,66.747,458.84a6,6,0,0,0-7.5,0L28.4,483.518V68.784A46.837,46.837,0,0,1,75.183,22H359.941a51.892,51.892,0,0,0-18.8,39.978V298.667l-64.025,64.025a5.935,5.935,0,0,0-1.494,2.579l-.019-.03-15.177,51.582a6,6,0,0,0,7.449,7.449L319.458,409.1l-.029-.02a5.929,5.929,0,0,0,2.578-1.493l19.135-19.135Zm-63.119-75.032-2.979.877.876-2.979Zm39.742-13.924-9.718-9.717L439.708,253.476l9.718,9.717ZM457.911,254.708,429.99,226.789l24.82-24.82,20.9,7.017,7.015,20.9Z" />
        <path d="M411,169a6,6,0,0,0,6-6V90.307a6,6,0,0,0-12,0V163A6,6,0,0,0,411,169Z" />
      </svg>
    </>
  );
}

export default EditInvoice;
