import React, { useEffect, useState } from 'react';
import SidebarMenu from './SidebarMenu';
import { getRoutesForRole } from './Routes';
import SidebarBottom from './SidebarBottom';
import { DTLogo } from "assets/icons";
import UseGetHook from 'Hooks/UseGetHook';
import UserPermission from 'libs/UserPermission';
import CreateButton from './CreateButton';
import PofileProgress from './PofileProgress';
import { NavLink } from 'react-router-dom';

import "./sidebar.css";

function Sidebar({ onClose }) {
  const { FetchUser, user, FetchUserTenant, FetchUserLandlordUser } = UseGetHook("userinfo");
  const { FetchUserRole, role } = UserPermission();

  console.log("user: ", user);

  const [myUserRoles, setMyUserRoles] = useState(user[0]?.roles?.map((e) => e.role) || []);
  let userRoles = user[0]?.roles?.map((e) => e.role) || [];
  localStorage.setItem("myUserRoles", JSON.stringify(userRoles) || "[]");

  useEffect(() => {
    if (localStorage.getItem("role") === "tenant") {
      FetchUserTenant();
    } else if (localStorage.getItem("role") === "landlord") {
      FetchUser();
      FetchUserRole();
    } else {
      FetchUserLandlordUser();
      FetchUserRole();
    }
    setMyUserRoles(user[0]?.roles?.map((e) => e.role));
    userRoles = user[0]?.roles?.map((e) => e.role) || [];
    localStorage.setItem("myUserRoles", JSON.stringify(userRoles) || "[]");
  }, []);

  // let routes = localStorage.getItem("role") === "landlord" || localStorage.getItem("role") === "user" 
  //   ? getRoutesForRole(userRoles, "landlord") 
  //   : getRoutesForRole(userRoles, "tenant");

    let routes = []
    if(localStorage.getItem("role") === "landlord" || localStorage.getItem("role") === "user"){
      routes = getRoutesForRole(userRoles, "landlord");
    }else if(localStorage.getItem("role") === "tenant"){
      routes = getRoutesForRole(userRoles, "tenant");
    }else if (localStorage.getItem("role") === "serviceprovider"){
      routes = getRoutesForRole(userRoles, "serviceprovider");
    }

  const handleMenuItemClick = () => {
    onClose();
  };

  return (
    <div
    id="sidebar"
      style={{
        width: "300px",
        backgroundColor:  "#F4F3EF",
        height: "100vh", // Sidebar container takes full viewport height
        display: "flex",
        flexDirection: "column",
        color: "white",
        padding: "0"
      }}
    >
      {/* Logo Section */}
      <div style={{ 
          textAlign: "center", 
          padding: "10px 0", 
          backgroundColor: "#F4F3EF", 
          borderRadius: "20px", 
          // marginBottom: "20px",
          flexShrink: 0 
        }}>
        <NavLink to='/dashboard'>
          <DTLogo mode="dark" width="220px" />
        </NavLink>
      </div>

      {/* Middle Section with Menu and Profile */}
      <div 
      // className='border'
        style={{
          flex: 1, // Allows this section to grow and fill available space
          display: "flex",
          width: "100%",
          flexDirection: "column",
          backgroundColor: "#231F20",
          borderColor: "#231F20 !important",
          borderTopRightRadius: "30px",
          // borderBottomRadius:"10px",

          padding: "10px",
          paddingTop: "50px",
          paddingBottom: "30px",
          overflowY: "auto", // Enables scrolling if content exceeds space
        }}
      >
        {(localStorage.getItem("role") === "landlord" || localStorage.getItem("role") === "user") && (
          <CreateButton handleClose={handleMenuItemClick} />
        )}
        <PofileProgress handleClose={handleMenuItemClick} />

        {/* Sidebar Menu Section */}
        <div style={{ marginTop: "20px", width: "100%" }}>
          <SidebarMenu routes={routes} onMenuItemClick={handleMenuItemClick} />
        </div>
      </div>

      {/* Sidebar Bottom Section */}
      <div 
        style={{ 
          padding: "15px 20px", 
          backgroundColor: "#231F20", 
          flexShrink: 0, // Prevents shrinking of the bottom section
          marginTop: "20px"
        }}
      >
        <SidebarBottom user={user} handleClose={handleMenuItemClick} />
      </div>
    </div>
  );
}

export default Sidebar;
