import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import TaskReportDetail from "Components/TaskReportDetail/TaskReportDetail";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const TaskReportDetailPage = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail="Task Report Detail"
        back="task-reports"
      />
      <TaskReportDetail />
    </div>
  );
};

export default TaskReportDetailPage;
