import PropertyReport from "Components/PropertyReport/PropertyReport";
import React from "react";

const ReportsProperty = () => {

  return (
    <div className="container-fluid">
      <PropertyReport />
    </div>
  );
};

export default ReportsProperty;
