import { useState } from "react";
import exclamationMarkIcon from "assets/exclamation-mark 1.png";
import helpIcon from "assets/help-circle.png";
import calendarIcon from "assets/calendar-icon.png";
import chevronIconDown from "assets/chevron-down.png";
import FileUploader from "Components/FileUploader/FileUploader";
import {
  Upload,
  message,
  Select,
  Space,
  DatePicker,
  Tooltip,
  ConfigProvider,
} from "antd";
import { useNavigate } from "react-router-dom";
const { Dragger } = Upload;

const EndLease = () => {
  // States start
  const [showleaseBalance, setShowLeaseBalance] = useState(false);
  const [Images, setImages] = useState([]);
  // States end

  const dateFormat = "MM/DD/YYYY";
  // select box icon
  let dropdownIcon;
  dropdownIcon = (
    <>
      <img src={chevronIconDown} alt="" />
    </>
  );
  // select date icon
  let dateIcon;
  dateIcon = (
    <>
      <img src={calendarIcon} alt="" />
    </>
  );

  const navigate = useNavigate();
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  return (
    <>
      <div className="container-fluid bg-white p-3">
        {showleaseBalance === true ? (
          <>
            <div className="row mt-5">
              <div className="lease-balance-main-section">
                <p className="lease-balance-main-title">Lease Balance</p>
                <div className="d-flex justify-content-between mt-4">
                  <span className="">
                    <p className="lease-balance-summary-text">Summary</p>
                    <p className="lease-balance-summary-sub-text">
                      Lease outstanding Balance
                    </p>
                  </span>
                  <span className="d-flex align-items-end">
                    <p className="lease-balance-summary-sub-text">$0.00</p>
                  </span>
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <p className="lease-balance-summary-sub-text">
                    Deposits Held
                  </p>
                  <p className="lease-balance-summary-sub-text">$1,850.00</p>
                </div>
                <div className="lease-balance-refund-section mb-4 mt-4"></div>
                <div className="d-flex justify-content-between">
                  <p className="lease-balance-refund-text">
                    Refund Due to Tenant
                  </p>
                  <p className="lease-balance-summary-sub-text">$1,850.00</p>
                </div>
                <div className="lease-balance-refund-section mb-3"></div>
                <div className="d-flex gap-2">
                  <div className="exclamation-icon-custom">
                    <img
                      src={exclamationMarkIcon}
                      alt=""
                      className="exclamation-icon"
                    />
                  </div>
                  <div className="exclamation-icon-content">
                    <p className="lease-balance-footer-title-text mb-0">
                      This lease has a security deposit and no outstanding
                      balance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row">
              <div className="col-md-12 d-flex gap-2">
                <div className="exclamation-icon-custom">
                  <img
                    src={exclamationMarkIcon}
                    alt=""
                    className="exclamation-icon"
                  />
                </div>
                <div className="exclamation-icon-content">
                  <p className="exclamation-icon-content-text">
                    Please note once the lease is ended, there is no way to
                    reactivate it. The move-out date you list here will become
                    the new end date of this lease and portal access will end
                    for all tenants on this lease.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <span className="property-details-input-title">
                  Select Property<span className="sign-up-imp-star">*</span>
                </span>
                <ConfigProvider
                  theme={{
                    components: {
                      Select: {
                        colorText: "#667085",
                        colorTextDisabled: "#667085",
                        fontFamily: "Montserrat",
                        fontSize: 16,
                        borderRadius: 4,
                      },
                    },
                  }}
                >
                  <Select
                    suffixIcon={dropdownIcon}
                    defaultValue="1100 Welker LnJersey Shore, Pennsylvania(PA), 17740"
                    disabled
                    style={{
                      width: "100%",
                      height: 45,
                      backgroundColor: "#e9ecef",
                    }}
                    options={[
                      {
                        value:
                          "1100 Welker LnJersey Shore, Pennsylvania(PA), 17740",
                        label:
                          "1100 Welker LnJersey Shore, Pennsylvania(PA), 17740",
                      },
                      {
                        value:
                          "1200 Welker LnJersey Shore, Pennsylvania(PA), 17740",
                        label:
                          "1200 Welker LnJersey Shore, Pennsylvania(PA), 17740",
                      },
                    ]}
                  />
                </ConfigProvider>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <span className="property-details-input-title">
                  Select Unit<span className="sign-up-imp-star">*</span>
                </span>
                <ConfigProvider
                  theme={{
                    components: {
                      Select: {
                        colorText: "#667085",
                        colorTextDisabled: "#667085",
                        fontFamily: "Montserrat",
                        fontSize: 16,
                        borderRadius: 4,
                      },
                    },
                  }}
                >
                  <Select
                    suffixIcon={dropdownIcon}
                    defaultValue="295 Retton CirWilliamson"
                    disabled
                    style={{
                      width: "100%",
                      height: 45,
                      backgroundColor: "#e9ecef",
                    }}
                    options={[
                      {
                        value: "295 Retton CirWilliamson",
                        label: "295 Retton CirWilliamson",
                      },
                      {
                        value: "296 Retton CirWilliamson",
                        label: "296 Retton CirWilliamson",
                      },
                    ]}
                  />
                </ConfigProvider>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <span className="property-details-input-title">
                  Select Tenant<span className="sign-up-imp-star">*</span>
                </span>
                <ConfigProvider
                  theme={{
                    components: {
                      Select: {
                        colorText: "#667085",
                        colorTextDisabled: "#667085",
                        fontFamily: "Montserrat",
                        fontSize: 16,
                        borderRadius: 4,
                      },
                    },
                  }}
                >
                  <Select
                    suffixIcon={dropdownIcon}
                    defaultValue="Olivia Joe"
                    disabled
                    style={{
                      width: "100%",
                      height: 45,
                      backgroundColor: "#e9ecef",
                    }}
                    options={[
                      {
                        value: "Olivia Joe",
                        label: "Olivia Joe",
                      },
                      {
                        value: "Phoenix Baker",
                        label: "Phoenix Baker",
                      },
                    ]}
                  />
                </ConfigProvider>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <span className="property-details-input-title">
                  Security Deposit
                </span>
                <ConfigProvider
                  theme={{
                    components: {
                      Select: {
                        colorText: "#667085",
                        colorTextPlaceholder: "#667085",
                        fontFamily: "Montserrat",
                        fontSize: 16,
                        borderRadius: 4,
                      },
                    },
                  }}
                >
                  <Select
                    suffixIcon={dropdownIcon}
                    placeholder="Select Security Deposit"
                    disabled
                    style={{
                      width: "100%",
                      height: 45,
                      backgroundColor: "#e9ecef",
                    }}
                    options={[
                      {
                        value: "Surety Bonds",
                        label: "Surety Bonds",
                      },
                      {
                        value: "Installment Agreements",
                        label: "Installment Agreements",
                      },
                    ]}
                  />
                </ConfigProvider>
              </div>
              <div className="col-md-6">
                <span className="property-details-input-title">Amount</span>
                <div className="rent-amount-input-container position-relative">
                  <input type="number" className="form-control" disabled />
                  <div className="dollar-sign-box">$</div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-4">
                <span className="property-details-input-title">Lease Term</span>
                <ConfigProvider
                  theme={{
                    components: {
                      Select: {
                        colorText: "#667085",
                        colorTextDisabled: "#667085",
                        fontFamily: "Montserrat",
                        fontSize: 16,
                        borderRadius: 4,
                      },
                    },
                  }}
                >
                  <Select
                    suffixIcon={dropdownIcon}
                    defaultValue="Fixed Term"
                    disabled
                    style={{
                      width: "100%",
                      height: 45,
                      backgroundColor: "#e9ecef",
                    }}
                    options={[
                      {
                        value: "Fixed Term",
                        label: "Fixed Term",
                      },
                      {
                        value: "Month-to-Month",
                        label: "Month-to-Month",
                      },
                    ]}
                  />
                </ConfigProvider>
              </div>
              <div className="col-md-4">
                <span className="property-details-input-title">
                  Lease Term Start Date
                </span>
                <ConfigProvider
                  theme={{
                    components: {
                      DatePicker: {
                        colorText: "#667085",
                        colorTextPlaceholder: "#667085",
                        fontFamily: "Montserrat",
                        fontSize: 16,
                        borderRadius: 4,
                      },
                    },
                  }}
                >
                  <Space
                    style={{
                      width: "100%",
                    }}
                    direction="vertical"
                  >
                    <DatePicker
                      suffixIcon={dateIcon}
                      placeholder="11/1/2021"
                      disabled
                      style={{
                        width: "100%",
                        height: 45,
                        backgroundColor: "#e9ecef",
                      }}
                      size="large"
                      onChange={onChange}
                      className="lease-date-picker"
                    />
                  </Space>
                </ConfigProvider>
              </div>
              <div className="col-md-4">
                <span className="property-details-input-title">
                  Lease Term End Date
                </span>
                <ConfigProvider
                  theme={{
                    components: {
                      DatePicker: {
                        colorText: "#667085",
                        colorTextPlaceholder: "#667085",
                        fontFamily: "Montserrat",
                        fontSize: 16,
                        borderRadius: 4,
                      },
                    },
                  }}
                >
                  <Space
                    style={{
                      width: "100%",
                    }}
                    direction="vertical"
                  >
                    <DatePicker
                      suffixIcon={dateIcon}
                      placeholder="10/31/2023"
                      disabled
                      style={{
                        width: "100%",
                        height: 45,
                        backgroundColor: "#e9ecef",
                      }}
                      size="large"
                      onChange={onChange}
                      className="lease-date-picker"
                    />
                  </Space>
                </ConfigProvider>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-4">
                <span className="property-details-input-title">
                  First Rent Date
                </span>
                <ConfigProvider
                  theme={{
                    components: {
                      DatePicker: {
                        colorText: "#667085",
                        colorTextPlaceholder: "#667085",
                        fontFamily: "Montserrat",
                        fontSize: 16,
                        borderRadius: 4,
                      },
                    },
                  }}
                >
                  <Space
                    style={{
                      width: "100%",
                    }}
                    direction="vertical"
                  >
                    <DatePicker
                      suffixIcon={dateIcon}
                      placeholder="Select Start Date"
                      disabled
                      style={{
                        width: "100%",
                        height: 45,
                        backgroundColor: "#e9ecef",
                      }}
                      size="large"
                      onChange={onChange}
                      className="lease-date-picker"
                    />
                  </Space>
                </ConfigProvider>
              </div>
              <div className="col-md-4">
                <span className="property-details-input-title">Frequency</span>
                <ConfigProvider
                  theme={{
                    components: {
                      Select: {
                        colorText: "#667085",
                        colorTextPlaceholder: "#667085",
                        fontFamily: "Montserrat",
                        fontSize: 16,
                        borderRadius: 4,
                      },
                    },
                  }}
                >
                  <Select
                    suffixIcon={dropdownIcon}
                    placeholder="Select Frequency"
                    disabled
                    style={{
                      width: "100%",
                      height: 45,
                      backgroundColor: "#e9ecef",
                    }}
                    options={[
                      {
                        value: "",
                        label: "",
                      },
                    ]}
                  />
                </ConfigProvider>
              </div>
              <div className="col-md-4">
                <span className="property-details-input-title">
                  Rent Amount<span className="sign-up-imp-star">*</span>
                </span>
                <div className="rent-amount-input-container position-relative">
                  <input
                    type="number"
                    className="form-control"
                    value="1,922.00"
                    disabled
                    style={{ color: "#667085", fontFamily: "Montserrat" }}
                  />
                  <div className="dollar-sign-box">$</div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <span className="property-details-input-title">
                  Notice Date
                  <Tooltip title="When was the notice given?">
                    <img src={helpIcon} alt="" className="cursor" />
                  </Tooltip>
                </span>
                <ConfigProvider
                  theme={{
                    components: {
                      DatePicker: {
                        colorText: "#667085",
                        colorTextPlaceholder: "#667085",
                        fontFamily: "Montserrat",
                        fontSize: 16,
                        colorPrimaryHover: "#EF6B3E",
                        borderRadius: 4,
                      },
                    },
                  }}
                >
                  <Space
                    style={{
                      width: "100%",
                    }}
                    direction="vertical"
                  >
                    <DatePicker
                      suffixIcon={dateIcon}
                      placeholder="Select Notice Date"
                      style={{
                        width: "100%",
                        height: 45,
                      }}
                      size="large"
                      format={dateFormat}
                      onChange={onChange}
                      className="lease-date-picker"
                    />
                  </Space>
                </ConfigProvider>
              </div>
              <div className="col-md-6">
                <span className="property-details-input-title">
                  Move-out-Date
                  <img src={helpIcon} alt="" className="cursor" />
                </span>
                <ConfigProvider
                  theme={{
                    components: {
                      DatePicker: {
                        colorText: "#667085",
                        colorTextPlaceholder: "#667085",
                        fontFamily: "Montserrat",
                        fontSize: 16,
                        colorPrimaryHover: "#EF6B3E",
                        borderRadius: 4,
                      },
                    },
                  }}
                >
                  <Space
                    style={{
                      width: "100%",
                    }}
                    direction="vertical"
                  >
                    <DatePicker
                      suffixIcon={dateIcon}
                      placeholder="Select Move-out Date"
                      style={{
                        width: "100%",
                        height: 45,
                      }}
                      size="large"
                      format={dateFormat}
                      onChange={onChange}
                      className="lease-date-picker"
                    />
                  </Space>
                </ConfigProvider>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="dragger">
                  <FileUploader setImages={setImages} Images={Images} />
                  {/* <ConfigProvider
                    theme={{
                      components: {
                        Upload: {
                          // actionsColor: "red"
                          colorPrimaryHover: "#EF6B3E",
                          colorFillAlter: "#F9FAFB",
                          colorBorder: "rgba(147, 145, 141, 0.52)",
                        },
                      },
                    }}
                  >
                    <Dragger
                      multiple
                      action="http://localhost:3000/"
                      listType="picture"
                      accept=".png,.jpg,svg"
                      beforeUpload={(file) => {
                        console.log(file);
                        return false;
                      }}
                      onChange={(dragger) => {
                        console.log(dragger);
                      }}
                    >
                      <p className="ant-upload-drag-icon">
                        <img src={fileUploadIcon} alt="" />
                      </p>
                      <p className="ant-upload-text property-images-file-uploader-text">
                        <span className="property-images-file-uploader-text-unique">
                          Click to upload
                        </span>{" "}
                        or drag and drop
                      </p>
                      <p className="ant-upload-hint property-images-file-uploader-text">
                        {" "}
                        SVG, PNG, JPG
                      </p>
                    </Dragger>
                  </ConfigProvider> */}
                </div>
              </div>
            </div>
          </>
        )}
        <div className="row mt-4">
          <div className="col-md-12">
            {showleaseBalance === true ? (
              <button
                onClick={() => {
                  navigate("/all-lease");
                }}
                className="modal-save-btn w-100"
              >
                End Lease
              </button>
            ) : (
              <button
                onClick={() => {
                  setShowLeaseBalance(true);
                }}
                className="modal-save-btn w-100"
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EndLease;
