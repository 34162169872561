import React, { useState, useEffect } from "react";
import { Table, ConfigProvider } from "antd";
import SearchBar from "Helpers/SearchBar";
import { ButtonVariant1 } from "Components/GeneralComponents";
import tenantUserImage from "assets/Ellipse 7.png";
import assignedTasksIcon from "assets/assigned-tasks.png";
import tasksCompletedIcon from "assets/tasks-completed.png";
import pendingTasksIcon from "assets/pending-tasks.png";
import settingBtnIcon from "assets/more-vertical.png";
import settingIcon from "assets/three-dots.png";
import viewIcon from "assets/Icon.png";
import editIcon from "assets/edit-05.png";
import deleteIcon from "assets/trash-01.png";
import trashIconWhite from "assets/trash-icon-white.png";
import completeIcon from "assets/calendar-check-01.png";
import { useDispatch, useSelector } from "react-redux";
import { FilterValue } from "Store/Slices/FilterSlice";
import { Link } from "react-router-dom";
import { fetchTasks } from "network/landlord/property";
import PropertyDetailsViewAddNewTaskModal from "Modals/PropertyDetailsViewAddNewTaskModal/PropertyDetailsViewAddNewTaskModal";
import EditTaskModal from "Components/EditTaskModal/EditTaskModal";
import DeleteModal from "Modals/DeleteModal/DeleteModal";

const TasksTab = ({ id }) => {
  const [showTaskDetails, setShowTaskDetails] = useState(false);
  const [openModalTask, setOpenModalTask] = useState(false);
  const [selectedTableItem, setSelectedTableItem] = useState("");
  const [key, setKey] = useState("");
  const [taskId, setTaskId] = useState("");
  const [openEditModalTask, setOpenEditModalTask] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [selectionType, setSelectionType] = useState("checkbox");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [data, setData] = useState([]);
  const [reRender, setReRender] = useState(false);

  const dispatch = useDispatch();

  const range = useSelector((state) => {
    return state.FilterValue.value;
  });
  const property = useSelector((state) => {
    return state.FilterValue.property;
  });
  const fromDate = useSelector((state) => {
    return state.FilterValue.fromDate;
  });
  const toDate = useSelector((state) => {
    return state.FilterValue.toDate;
  });

  const Search = useSelector((state) => {
    return state.Search.value;
  });

  const resetFilter = () => {
    dispatch(FilterValue({ reset: "reset" }));
  };

  useEffect(() => {
    fetchTasks(id).then((res) => {
      if (res.apiCallStatus === "success") {
        setData(
          res.message
            .filter((e) => e.title.toLowerCase().includes(Search.toLowerCase()))
            .map((e, index) => ({
              key: index + 1,
              id: e.id,
              title: e?.title,
              img: e?.image,
              assigned: e?.assignedTo?.map((e) => e.firstName).join(", "),
              assignedToMe: localStorage.getItem("name"),
              due: [new Date(e.dueDate).toLocaleDateString(), e.priority],
              related: e?.property?.title,
              status: e?.status,
            }))
        );
      }
    });
  }, [range, property, fromDate, toDate, reRender]);

  const onOpenModalTask = () => {
    setOpenModalTask(true);
  };
  const onCloseModalTask = () => {
    setOpenModalTask(false);
  };

  const onOpenEditModalTask = () => {
    setOpenEditModalTask(true);
  };
  const onCloseEditModalTask = () => {
    setOpenEditModalTask(false);
  };

  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleIconClick = (result) => {
    // Toggle the dropdownOpen state
    const filterData = data.filter((item) => {
      return item.key === result;
    });
    setKey(filterData.key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const tasksColumns = [
    {
      title: "Title",
      dataIndex: "title",
      render: (text, name) => (
        <Link
          to={`/task-details?id=${name.id}`}
          className="all-task-table-title-text"
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Assigned To",
      dataIndex: "assigned",
      render: (text, assigned) => (
        <>
          {" "}
          {/* <img className="me-2" src={oval} alt="" /> */}
          {assigned.assignedToMe ? (
            <span>
              {text}
              {text ? ", " : ""}
              {assigned.assignedToMe} (YOU)
            </span>
          ) : (
            <span>{text}</span>
          )}
        </>
      ),
    },
    {
      title: "Due At",
      dataIndex: "due",
      render: (text) => (
        <>
          <div className="assign-date-container">
            <div
              className={
                text[1] === "High"
                  ? "priority-text bg-error"
                  : text[1] === "Low"
                  ? "priority-text bg-grey"
                  : text[1] === "Medium"
                  ? "priority-text bg-yellow"
                  : text[1] === "Very High"
                  ? "priority-text bg-error"
                  : ""
              }
            >
              <span>{`${text[1]} Priority`}</span>
            </div>
            <br />
            <span className="assign-to-date">{text[0]}</span>
          </div>
        </>
      ),
    },
    {
      title: "Related To",
      dataIndex: "related",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <>
          <span
            className={
              status === "Completed"
                ? "completed-status text-white"
                : status === "In Progress"
                ? "in-progress-status text-white"
                : status === "Not Started"
                ? "not-started-status text-dark"
                : ""
            }
          >
            {status}
          </span>{" "}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div className="task-table-setting-container position-relative cursor">
            <img
              src={settingIcon}
              alt=""
              onClick={() => handleIconClick(setting.id)}
            />
            {setting.key === key && (
              <div className="task-table-setting-dropdown-prospect bg-white box-shadow">
                <ul className="p-0 d-flex flex-column gap-3">
                  <Link to={`/task-details?id=${setting.key}`}>
                    <li className="list-style-none table-setting-dropdown-menu">
                      {" "}
                      <img src={viewIcon} alt="" /> View
                    </li>
                  </Link>
                  <li
                    onClick={() => {
                      onOpenEditModalTask();
                      setTaskId(setting.id);
                    }}
                    className="list-style-none table-setting-dropdown-menu"
                  >
                    {" "}
                    <img src={editIcon} alt="" /> Edit
                  </li>
                  <Link>
                    <li className="list-style-none table-setting-dropdown-menu">
                      {" "}
                      <img src={completeIcon} alt="" /> Complete
                    </li>
                  </Link>
                  <li
                    onClick={() => {
                      onOpenDeleteModal();
                      setDeleteId(setting.id);
                    }}
                    className="list-style-none table-setting-dropdown-menu"
                  >
                    {" "}
                    <img src={deleteIcon} alt="" /> Delete
                  </li>
                </ul>
              </div>
            )}
          </div>
        </>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedTableItem(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  return (
    <>
      {openModalTask === true ? (
        <PropertyDetailsViewAddNewTaskModal
          id={id}
          onOpen={onOpenModalTask}
          onClose={onCloseModalTask}
        />
      ) : (
        ""
      )}
      {openEditModalTask && (
        <EditTaskModal
          id={taskId}
          onOpen={onOpenEditModalTask}
          onClose={onCloseEditModalTask}
        />
      )}
      {openDeleteModal === true ? (
        <DeleteModal
          onClose={onCloseDeleteModal}
          component={"task"}
          fetchFun={setReRender(!reRender)}
          route={`property-details-view?id=${id}`}
          deleteBtnText={`Delete Task`}
          delId={deleteId}
        />
      ) : (
        ""
      )}
      {showTaskDetails === true ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <div className="task-overview-tab-boxes p-3 position-relative">
                <div className="overview-box-img">
                  <img src={assignedTasksIcon} alt="" />
                </div>
                <div className="over-view-box-text">
                  <span>Assigned Tasks</span>
                  <h3>13</h3>
                </div>
                <div className="setting-icon">
                  <img src={settingBtnIcon} alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="task-overview-tab-boxes p-3 position-relative">
                <div className="overview-box-img">
                  <img src={tasksCompletedIcon} alt="" />
                </div>
                <div className="over-view-box-text">
                  <span>Tasks Completed</span>
                  <h3>22</h3>
                </div>
                <div className="setting-icon">
                  <img src={settingBtnIcon} alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="task-overview-tab-boxes p-3 position-relative">
                <div className="overview-box-img">
                  <img src={pendingTasksIcon} alt="" />
                </div>
                <div className="over-view-box-text">
                  <span>Pending Tasks</span>
                  <h3>2</h3>
                </div>
                <div className="setting-icon">
                  <img src={settingBtnIcon} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-10">
              <div className="task-info-heading">
                <h4>Task Info</h4>
              </div>
              <div className="task-info-lists">
                <div className="row">
                  <div className="col-md-12">
                    <p>
                      <span className="task-info-list-span me-3">
                        Ticket Title:
                      </span>{" "}
                      Plumbing issues
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <p>
                      <span className="task-info-list-span me-3">
                        Priority:
                      </span>{" "}
                      High
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <p>
                      <span className="task-info-list-span me-3">
                        Due Date:
                      </span>{" "}
                      12/12/23
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <p>
                      <span className="task-info-list-span me-3">Status:</span>{" "}
                      In Progress
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <p>
                      <span className="task-info-list-span me-3">
                        Property:
                      </span>{" "}
                      4570 Griffin Street Phoenix, Arizona(AZ), 85003
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <p>
                      <span className="task-info-list-span me-3">
                        Documents:
                      </span>{" "}
                      docs paper-2
                    </p>
                  </div>
                </div>
                <div className="row mt-3">
                  <span>
                    <div className="modal-check-box-container d-flex align-items-center">
                      <input type="checkbox" className="me-2" name="" id="" />{" "}
                      <span className="ms-2">Notify Assignee(s)</span>
                      <input
                        type="checkbox"
                        className="ms-2"
                        name=""
                        id=""
                      />{" "}
                      <span className="ms-2">Notify Tenant</span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-2 text-end">
              <img src={tenantUserImage} alt="" />
            </div>
          </div>
        </>
      ) : (
        <>
          <SearchBar
            onClick={onOpenModalTask}
            btnTitle="Add New Task"
            innerPage={true}
            taskFilter={true}
          />
          {range || property || fromDate || toDate ? (
            <div className="mt-2" style={{ width: "fit-content" }}>
              <ButtonVariant1 handler={resetFilter} children={"Reset"} />
            </div>
          ) : (
            ""
          )}
          {selectedTableItem.length >= 1 && (
            <div className="table-delete-icon mt-3">
              <button className="table-delete-btn next-btn-main">
                <img src={trashIconWhite} />
                Delete
              </button>
            </div>
          )}
          <div className="task-table-container mt-3">
            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    colorTextHeading: "#667085",
                    //colorTextDescription: '#667085',
                    colorText: "#667085",
                    fontSize: 14,
                    fontWeightStrong: 500,
                    fontFamily: "Montserrat",
                    //cellFontSize: 14,
                  },
                  Pagination: {
                    itemActiveBg: "#EF6B3E",
                  },
                  Checkbox: {
                    colorPrimary: "#EF6B3E",
                    colorPrimaryHover: "#EF6B3E",
                  },
                },
              }}
            >
              <Table
                className="property-details-view-tasks-table scroll-remove scroll-responsive-tablet"
                rowSelection={{
                  type: selectionType,
                  ...rowSelection,
                }}
                columns={tasksColumns}
                dataSource={data}
                pagination={true}
              />
            </ConfigProvider>
          </div>
        </>
      )}
    </>
  );
};

export default TasksTab;
