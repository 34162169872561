import React, { useState, useEffect } from "react";
import { ConfigProvider, Switch, message } from "antd";
import { FilterValue } from "Store/Slices/FilterSlice";
import trashIconWhite from "assets/trash-icon-white.png";
import { Link } from "react-router-dom";
import * as Component from "Components/GeneralComponents";
import { getProperty } from "network/landlord/property";
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import * as Icon from "assets/icons";
import config from "Helpers/config.js";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import { fetchClientSecret } from "network/landlord/Account";
import { deleteaccount, fetchClientSecrets } from "network/landlord/Account";
const ConnectAccountTab = ({ id }) => {
  const [account_id, setAccount_id] = useState("");
  const [selectedTableItem, setSelectedTableItem] = useState([]);
  const [connectStripe, setConnectStripe] = useState(null);
  const [showaccountDetails, setShowAccountDetails] = useState(false);
  const [key, setKey] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isPayNowModelOpen, setIsPayNowModelOpen] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [update, setUpdate] = useState(false);
  const deleteMsg = "Are you sure you want to delete this account?";

  useEffect(() => {
    setIsTableLoading(true);
    getProperty(id).then((res) => {
      if (res.apiCallStatus === "success") {
        setIsTableLoading(false);
        setAccount_id(res.message.account?.landlordAccount?.account_id);
        Object.keys(res.message.account).length !== 0
          ? setData([
              {
                key: res.message.account?.landlordAccount?._id,
                account: "Operating Account",
                bank: res.message.account?.landlordAccount?.stripeConnected
                  ? res.message.account?.landlordAccount?.stripeAccountDetails.map(
                      (e) => e.bank_name
                    )
                  : "---",
                routing_number: res.message.account?.landlordAccount
                  ?.stripeConnected
                  ? res.message.account?.landlordAccount?.stripeAccountDetails.map(
                      (e) => e.routing_number
                    )
                  : "---",
                button: res.message.account?.landlordAccount?.stripeConnected
                  ? "Connected"
                  : "Connect Again",
              },
            ])
          : setData([]);
      }
    });
  }, [id, update, isPayNowModelOpen]);

  const handleIconClick = (result) => {
    const filterData = data.filter((item) => {
      return item.key === result;
    });
    setKey(filterData.key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const accountColumns = [
    {
      title: "Account",
      dataIndex: "account",
      render: (text) => (
        <Link to="/account-details">
          <span className="accounting-table-account-text">{text}</span>
        </Link>
      ),
    },
    {
      title: "Routing No",
      dataIndex: "routing_number",
      render: (text, data) => (
        <Link to="/account-details">
          <span className="accounting-table-account-text">{text}</span>
        </Link>
      ),
    },

    {
      title: "Bank",
      dataIndex: "bank",
      render: (text, data) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "button",
      render: (text, button) => (
        <>
          {data.button === "Connected" ? (
            <button disabled className="connect-bank-btn">
              {text}
            </button>
          ) : (
            <button className="connect-bank-btn" onClick={connectAgain}>
              {text}
            </button>
          )}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <Icon.VerticalElipsisIcon width={23} height={23} />
            <Component.RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleDeleteRecord: () => {
                  onOpenDeleteModal();
                  setDeleteId(account_id);
                },
                handleView: () => {
                  setShowAccountDetails(true);
                },
              }}
              fields={{
                view: true,
                delete: true,
              }}
            />
          </div>
        </>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedTableItem(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const isPayNowModelclosefunction = () => {
    setIsPayNowModelOpen(false);
    setUpdate(!update);
  };
  const isPayNowModelOpenfunction = () => {
    setIsPayNowModelOpen(true);
  };

  const handleAddClick = () => {
    isPayNowModelOpenfunction();
    const addaccount = async () => {
      const res = await fetchClientSecret({ properties: [id] });
      console.log(res);
      if (res?.apiCallStatus === "success") {
        setAccount_id(res?.message.account_id);
        return res?.message?.client_secret;
      } else {
        message.error(res?.message);
      }
    };

    const instance = loadConnectAndInitialize({
      publishableKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
      fetchClientSecret: addaccount,
    });
    setConnectStripe(instance);
  };

  const connectAgain = () => {
    setIsPayNowModelOpen(true);
    const fetchClientSecret = async () => {
      const response = await fetchClientSecrets(account_id);
      if (response.apiCallStatus === "success") {
        return response.message.client_secret;
      } else {
        return undefined;
      }
    };
    const instance = loadConnectAndInitialize({
      publishableKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
      fetchClientSecret: fetchClientSecret,
    });
    setConnectStripe(instance);
  };
  const deleteaccountfun = (id) => {
    setIsLoading(true);
    deleteaccount(id).then((response) => {
      if (response.apiCallStatus === "success") {
        setUpdate(!update);
        message.success("Account Deleted Successfully");
        setIsLoading(false);
        setOpenDeleteModal(false);
      } else {
        message.error(response.message);
        setIsLoading(false);
        setOpenDeleteModal(false);
      }
    });
  };

  return (
    <>
      {openDeleteModal && (
        <Component.DeleteModal
          isOpen={openDeleteModal}
          onClose={onCloseDeleteModal}
          message={deleteMsg}
          deleteCall={() => deleteaccountfun(deleteId)}
          isLoading={isLoading}
        />
      )}
      {showaccountDetails === true ? (
        <>
          <div className="container mt-5 account-details-view-main">
            <div className="row account-details-view-title">
              <div className="col-md-6">
                <span className="fw-bold account-details-view-custom-title">
                  ACCOUNT INFO
                </span>
              </div>
              <div className="col-md-6">
                <div className="account-info-btn d-flex align-items-center justify-content-center gap-3">
                  <button className="connect-bank-btn w-50">
                    Connect to Bank
                  </button>
                  <button
                    // onClick={onOpenAccountModal}
                    className="add-new-task-btn w-50"
                  >
                    <span>
                      <svg
                        width={21}
                        height={21}
                        fill="#fff"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 5v14" />
                        <path d="M5 12h14" />
                      </svg>
                    </span>
                    Add New Account
                  </button>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="task-info-lists">
                  <p>
                    <span className="task-info-list-span me-3">Lease:</span> Use
                    this account to receive payments
                  </p>
                  <p>
                    <span className="task-info-list-span me-3">Account:</span>{" "}
                    Operating Account
                  </p>
                  <p>
                    <span className="task-info-list-span me-3">Status:</span>{" "}
                    Active
                  </p>
                  <p>
                    <span className="task-info-list-span me-3">
                      Description:
                    </span>{" "}
                    <span className="placeholder-custom-text">
                      Generate Lorem Ipsum placeholder text for use in your
                      graphic, print and web layouts, and discover plugins for
                      your favorite writing, design and blogging tools
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <p className="primary-orange-custom-text">
                  Make this account secondary
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p className="primary-orange-custom-sub-text">
                  This will allow you to select this account as secondary.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ConfigProvider
                  theme={{
                    components: {
                      Switch: {
                        colorPrimary: "#EF6B3E",
                        colorPrimaryHover: "#EF6B3E",
                      },
                    },
                  }}
                >
                  <Switch onChange={onChange} />
                </ConfigProvider>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Component.FilterRow
            filter={data}
            setFilter={FilterValue}
            btnTitle={data.length >= 1 ? "" : "Add New Account"}
            btnHandler={isTableLoading ? null : handleAddClick}
            handleSubmit={() => setUpdate(!update)}
          />

          {selectedTableItem.length >= 1 && (
            <div className="table-delete-icon mt-3">
              <button className="table-delete-btn next-btn-main">
                <img src={trashIconWhite} alt="icon" />
                Delete
              </button>
            </div>
          )}
          <div className="task-table-container mt-3">
            <Component.CustomTable
              loading={isTableLoading}
              pagination={false}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              columns={accountColumns}
              dataSource={data}
            />
            {isPayNowModelOpen && (
              <Component.FormModalLayout
                title={"Connect to Bank"}
                isOpen={isPayNowModelOpen}
                onClose={isPayNowModelclosefunction}
              >
                <div className="stripemodal ">
                  <ConnectComponentsProvider connectInstance={connectStripe}>
                    <ConnectAccountOnboarding
                      onExit={() => {
                        message.success("Account added");
                        fetch(`${config.baseUrl}/api/stripe/connectConfirm`, {
                          method: "PUT",
                          headers: {
                            "content-type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem(
                              "token"
                            )}`,
                          },
                          body: JSON.stringify({
                            account_id: account_id,
                          }),
                        })
                          .then((res) => {
                            return res.json();
                          })
                          .then((res) => {
                            console.log(res);
                          })
                          .catch((e) => console.log(e, "error"));
                        setTimeout(() => {
                          setIsPayNowModelOpen(false);
                          setUpdate(!update);
                          // navigate("/all-accounts");
                        }, 1000);
                      }}
                    />
                  </ConnectComponentsProvider>
                </div>
              </Component.FormModalLayout>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ConnectAccountTab;
