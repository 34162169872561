import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import Payment from "Components/Payment/Payment";
import TenantPayment from "Components/Payment/TenantPayment";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const PaymentPage = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar topBarTitle="Payments" />
      {/* {!["Owner/Executive", "Property Manager", "Accounting/Finance"].some(
        (role) => myUserRoles?.includes(role)
      ) && myUserRoles?.length !== 0 ? (
        <NoAccessPage />
      ) : role === "tenant" ? (
        <TenantPayment />
      ) : (
        <Payment />
      )} */}
      <TenantPayment />
    </div>
  );
};

export default PaymentPage;
