import React from 'react'

const ButtonVariation1 = ({handler, disable=false, children}) => {
  return (
    <button
      onClick={handler}
      className="create-accoutn-btn w-100"
      disabled={disable}
    >
      {children}
    </button>
  )
}

export default ButtonVariation1
