import React from "react";
import validateError from "assets/alert-circle-validate.png";

const TextAreaField = ({
  name,
  required = false,
  label = "",
  placeholder,
  value,
  handler,
  isError = false,
  errorMessage = false,
  disabled,
  rows,
  cols,
}) => {
  return (
    <div className="position-relative">
      {label && (
        <span className="tenant-personal-details-title text-nowrap">
          {label}{" "}
          <span className="sign-up-imp-star">{`${required ? "*" : ""}`}</span>
        </span>
      )}
      <div style={{ position: "relative" }}>
        <textarea
          className= {`${isError || errorMessage ? "error-input flase " : "form-control"}`}
          value={value}
          onChange={handler}
          name={name}
          cols={cols}
          rows={rows}
          placeholder={placeholder}
          disabled={disabled}
        ></textarea>
        {isError && (
          <div className="validate-icon1">
            <img src={validateError} alt="" />
          </div>
        )}
      </div>
      {errorMessage && (
        <div className="text-danger fw-bold mt-2" style={{ fontSize: "12px" }}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default TextAreaField;
