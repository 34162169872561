import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UnitDetailsSliderModal from "Modals/UnitDetailsSliderModal/UnitDetailsSliderModal";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import * as InnerComponent from "./index";
import { CustomTabs } from "Components/GeneralComponents";
const TenantPropertyUnitsView = () => {
  const navigate = useNavigate();
  const { id, property_id, tab } = UseUrlParamsHook();
  const [openModal, setOpenModal] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const items = [
    { key: "1", label: "Overview", children: <InnerComponent.OverviewTab /> },
    { key: "2", label: "Tasks", children: <InnerComponent.TasksTab id={id} /> },
    { key: "3", label: "Files", children: <InnerComponent.FilesTab id={id} /> },
    { key: "4", label: "Notes", children: <InnerComponent.NotesTab id={id} /> },
  ];

  useEffect(() => {
    setActiveTab(tab || "1");
  }, [tab]);

  const onChange = (key) => {
    setActiveTab(key);
    searchParams.set("tab", key);
    navigate({ search: searchParams.toString() });
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      {openModal === true && <UnitDetailsSliderModal onClose={onCloseModal} />}

      <div className="container-fluid bg-white p-3">
      {activeTab && <CustomTabs
        items={items}
        defaultActiveKey={activeTab}
        onChange={onChange}
      />}
      </div>
    </>
  );
};

export default TenantPropertyUnitsView;
