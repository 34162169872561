// export default FileUploader;

import React, { useRef, useState } from "react";
import cancelIcon from "assets/x-circle.png";
import uploadIcon from "assets/upload-cloud-02.png";
import pdfIcon from "assets/pdf.jpg";
import excelIcon from "assets/excel.jpg";
import wordIcon from "assets/word.jpg";

const FileUploader = ({
  setImages,
  Images = [],
  DeletedImages = [],
  setDeletedImages,
  property,
  height = 150,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);
  const draggedImage = useRef(null);
  const draggedOverImage = useRef(null);

  // Functions
  const selectedFile = () => {
    fileInputRef.current.click();
  };

  const onFileSelect = (files) => {
    if (files.length === 0) return;
    const newFiles = Array.from(files).filter(
      (file) => !Images.some((img) => img.name === file.name)
    );
    const updatedImages = [...Images, ...newFiles];
    setImages(updatedImages); // Sending the updated array directly
  };

  const deleteImages = (index) => {
    const updatedImages = Images.filter((_, i) => i !== index);
    const imageToDelete = Images[index];

    if (!(imageToDelete instanceof File)) {
      setDeletedImages([...DeletedImages, imageToDelete]);
    }

    setImages(updatedImages); // Sending the updated array directly
  };

  const onDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
    event.dataTransfer.dropEffect = "copy";
  };

  const onDragLeave = () => setIsDragging(false);

  const onDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    onFileSelect(files);
    setIsDragging(false);
  };

  const handleSort = () => {
    const updatedImages = [...Images];
    const dragged = updatedImages[draggedImage.current];
    updatedImages[draggedImage.current] = updatedImages[draggedOverImage.current];
    updatedImages[draggedOverImage.current] = dragged;
    setImages(updatedImages); // Sending the updated array directly
  };

  return (
    <div className="dragger-card">
      <div
        className="drag-area"
        style={{ height: `${height}px` }}
        onClick={selectedFile}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        {isDragging ? (
          <span>Drop file here...</span>
        ) : (
          <>
            <p><img src={uploadIcon} alt="" /></p>
            <p className="mb-0">
              <span className="normal-grey-text fw-bold">Click to upload</span>{" "}
              <span className="primary-black-text">or drag and drop</span>
            </p>
            <p className="primary-black-text">SVG, PNG, JPG or GIF (max. 800x400px)</p>
          </>
        )}
        <input
          type="file"
          ref={fileInputRef}
          onChange={(e) => onFileSelect(e.target.files)}
          accept={property ? ".png,.jpg,.jpeg" : ".png,.jpg,.jpeg,.xlsx,.pdf,.docx"}
          multiple
          style={{ display: "none" }}
        />
      </div>
      <div className={`uploaded-img-box ${Images.length ? "mt-5" : "mt-2"}`}>
        {Images.map((image, index) => (
          <div
            key={index}
            draggable
            onDragStart={() => (draggedImage.current = index)}
            onDragEnter={() => (draggedOverImage.current = index)}
            onDragEnd={handleSort}
            onDragOver={(e) => e.preventDefault()}
            className="images cursor-grab"
          >
            <div className="upload-img-cancel-btn">
              <span
                onClick={() => deleteImages(index)}
                className="upload-cancel-btn text-danger"
              >
                <img src={cancelIcon} alt="Cancel" />
              </span>
            </div>
            <img
              className="upload-img"
              src={
                image instanceof File && image.type.startsWith("image")
                  ? URL.createObjectURL(image)
                  : image.name.includes("pdf")
                  ? pdfIcon
                  : image.name.includes("xlsx")
                  ? excelIcon
                  : image.name.includes("docx")
                  ? wordIcon
                  : image
              }
              alt={image.name}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FileUploader;
