import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import TaskDetails from "Components/TaskDetails/TaskDetails";
import React from "react";

const TaskDetailsPage = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Tasks Details"}
        back="all-task"
      />
      <TaskDetails />
    </div>
  );
};

export default TaskDetailsPage;
