import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import PropertiesDashboard from "Components/PropertiesDashboard/PropertiesDashboard";
import NoAccessPage from "Pages/NoAccessMainPage/NoAccessPage";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";

const Properties = () => {
  const storedRoles = localStorage.getItem("myUserRoles");
  const myUserRoles =
    storedRoles !== "undefined" ? JSON.parse(storedRoles) : [];
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar topBarTitle={"Properties"} />
      {![
        "Owner/Executive",
        "Leasing Agent",
        "Maintenance Technician",
        "Property Manager",
      ].some((role) => myUserRoles?.includes(role)) &&
      myUserRoles?.length !== 0 ? (
        <NoAccessPage />
      ) : (
        <PropertiesDashboard />
      )}
    </div>
  );
};

export default Properties;
