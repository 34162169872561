// QuestionHandlerComponent.js
import React, {useState, useEffect} from 'react';
import TenantQuestions from 'Components/TenantQuestions/TenantQuestions';
import StandardQuestions from './StandardQuestions';
import ScreeningQuestions from './ScreeningQuestions';
import { updateStepInDatabase } from 'network/tenant/passport';
const QuestionHandlerComponent = ({
    form,
    errors,
    handleChange,
    handleNext,
    handlePrev,
    CreateScreeningRequest,
    screeningId  = null,
    ssn,

}) => {
    console.log("screeningId",typeof screeningId)
    const [questionType,setQuestionType] = useState(screeningId ? 3 : 1)
    // Update questionType whenever screeningId changes
  useEffect(() => {
    setQuestionType(screeningId ? 3 : 1);
  }, [screeningId]);

    console.log("Question hadnle change: ",handleChange)
    const handleNextQuestionType = async()=>{
      console.log("nextQuestionType", form);
      if(questionType === 1){
        await updateStepInDatabase(8, form)
      }else if(questionType === 2){
        await updateStepInDatabase(9, form)
      }
     setQuestionType(questionType + 1)
  
    }
    const handlePrevQuestionType = ()=>{
        console.log("Question type Previous: ",questionType)
        setQuestionType(questionType - 1)
    }
  switch (questionType) {
    case 1:
      return <ScreeningQuestions 
      form={form}
      errors={errors}
      handleChange={handleChange}
      handleNext={handleNextQuestionType}
      handlePrev={handlePrev}
      />;
      
    case 2:
      return <StandardQuestions
      form={form}
      errors={errors}
      handleChange={handleChange}
      handleNext={CreateScreeningRequest}
      handlePrev={handlePrevQuestionType}
      />;
      
    case 3:
        return screeningId &&(
            <TenantQuestions
            id={screeningId}
            ssn="123456789"
            // form={screeningQuestions}
            // errors={errors}
            // handleChange={handleScreeningQuestionsChange}
            // handleNext={CreateScreeningRequest}
            // handlePrev={handlePrev}
          />
           )
  
  }
};

export default QuestionHandlerComponent;
