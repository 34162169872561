import React, { useState } from "react";
import { CloseEyeIcon, OpenEyeIcon } from "assets/icons";
import { Tooltip } from "antd";
import helpIcon from "assets/help-circle.png";

const PasswordInput = ({
  name,
  required = false,
  label = "",
  placeholder,
  value,
  handler,
  errorMessage = false,
  disabled,
  pattern,
  TooltipTitle,
}) => {
  const [showPass, setShowPass] = useState(false);

  return (
      <div className="position-relative">
        {label && (
          <span className="tenant-personal-details-title">
            {label}{" "}
            <span className="sign-up-imp-star">{`${required ? "*" : ""}`}</span>
            {TooltipTitle && (
              <Tooltip title={TooltipTitle}>
                <img src={helpIcon} alt="" className="cursor ps-2" />
              </Tooltip>
            )}
          </span>
        )}
        <div className="position-relative">
          <input
            value={value}
            name={name}
            onChange={handler}
            type={showPass ? "text" : "password"}
            className={`${ errorMessage ? "error-input" : "form-control"}`}
            placeholder={placeholder}
            disabled={disabled}
            pattern={pattern}
          />
          <span
            onClick={() => setShowPass(!showPass)}
            className="eye-on cursor"
          >
            {showPass ? <OpenEyeIcon /> : <CloseEyeIcon />}
          </span>
        </div>
        {errorMessage && (
          <div
            className="text-danger fw-bold mt-2"
            style={{ fontSize: "12px" }}
          >
            {errorMessage}
          </div>
        )}
      </div>
  );
};

export default PasswordInput;
