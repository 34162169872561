import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import PayableOverview from "Components/PayableOverview/PayableOverview";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const PayableOverviewPage = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Payments"}
        back={"Payment"}
      />
      <PayableOverview />
    </div>
  );
};

export default PayableOverviewPage;
