import CreateNew from "Components/CreateNew/CreateNew";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const CreateNewPage = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar topBarTitle="Create New" />
      <CreateNew />
    </div>
  );
};

export default CreateNewPage;
