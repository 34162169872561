import React from "react";
import residentialIcon from "assets/country-house 1.png";
import commercialIcon from "assets/commercial-house.png";
import singleFamilyIcon from "assets/country-house 2.png";
import multiFamilyIcon from "assets/country-house 3.png";
import condoIcon from "assets/country-house 3 (1).png";
import townHouseIcon from "assets/country-house 4.png";
import otherIcon from "assets/country-house 1 (1).png";
import officeIcon from "assets/country-house 2 (1).png";
import shoppingCenterIcon from "assets/country-house 3 (2).png";
import storageIcon from "assets/country-house 3 (3).png";
import parkingIcon from "assets/country-house 4 (1).png";
import arrowRight from "assets/chevron-right.png";
import { SubmitButton } from "Components/GeneralComponents";

const propertyTypes = {
  residential: [
    { icon: singleFamilyIcon, label: "Single Family", value: "single_family" },
    { icon: multiFamilyIcon, label: "Multi Family", value: "multi_family" },
    { icon: condoIcon, label: "Condo", value: "condo" },
    { icon: townHouseIcon, label: "Townhouse", value: "townhouse" },
    { icon: otherIcon, label: "Other", value: "residential_others" },
  ],
  commercial: [
    { icon: officeIcon, label: "Office", value: "office" },
    {
      icon: shoppingCenterIcon,
      label: "Shopping Center",
      value: "shopping_center",
    },
    { icon: storageIcon, label: "Storage", value: "commercial_Storage" },
    { icon: parkingIcon, label: "Parking", value: "parking" },
    { icon: otherIcon, label: "Other", value: "commercial_others" },
  ],
};

const PropertyType = ({
  selectedCategory,
  selectedPropertyType,
  handleCategoryClick,
  handlePropertyTypeClick,
  handleNext,
}) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="stepper-content d-flex justify-content-between align-items-center">
            <div className="stepper-content-text-content">
              <div className="add-property-details-form">
                <div className="add-property-details-form-title">
                  <p className="add-property-details-form-title-heading">
                    Choose Property Type
                  </p>
                </div>
                <div className="add-property-details-form-content">
                  <div
                    onClick={() => handleCategoryClick("residential")}
                    className={`cursor add-property-details-form-content-residential mb-4 mt-4 ${
                      selectedCategory === "residential"
                        ? "property-active-form"
                        : ""
                    }`}
                  >
                    <div
                      className={`add-property-details-form-content-residential-icon ${
                        selectedCategory === "residential"
                          ? "property-form-icon-active-border"
                          : ""
                      }`}
                    >
                      <img
                        src={residentialIcon}
                        className="add-property-details-icon"
                        alt="not found icon"
                      />
                      <p className="add-property-details-text">Residential</p>
                    </div>
                    <div className="add-property-details-form-content-residential-text ms-3">
                      <p className="add-property-details-form-content-residential-sub-text">
                        Residential properties include single-family homes,
                        multi-family buildings, condos, townhouses, mobile homes
                        or similar living spaces.
                      </p>
                    </div>
                  </div>
                  <div
                    onClick={() => handleCategoryClick("commercial")}
                    className={`cursor add-property-details-form-content-commercial mb-4 mt-4 ${
                      selectedCategory === "commercial"
                        ? "property-active-form"
                        : ""
                    }`}
                  >
                    <div
                      className={`add-property-details-form-content-commercial-icon ${
                        selectedCategory === "commercial"
                          ? "property-form-icon-active-border"
                          : ""
                      }`}
                    >
                      <img
                        src={commercialIcon}
                        className="add-property-details-icon"
                        alt=""
                      />
                      <p className="add-property-details-text">Commercial</p>
                    </div>
                    <div className="add-property-details-form-content-commercial-text ms-3">
                      <p className="add-property-details-form-content-residential-sub-text">
                        Commercial properties are designed for business
                        purposes—such as office spaces, shopping centers,
                        storage facilities, parking areas, and more.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {selectedCategory && (
                <div className="choose-property-type-residential-options text-center">
                  <div className="choose-property-type-residential-options-title">
                    <p>Which Type Describes Your Property?</p>
                  </div>
                  <div className="choose-property-type-residential-options-icon">
                    <div className="choose-property-type-residential-options-icon-list">
                      <ul className="d-flex justify-content-between gap-3 md-column">
                        {propertyTypes[selectedCategory].map(
                          (propertyType, index) => (
                            <li
                              key={index}
                              onClick={() =>
                                handlePropertyTypeClick(propertyType.value)
                              }
                              className="list-style-none cursor"
                            >
                              <img src={propertyType.icon} alt="" />
                              <p
                                className={`property-description-type-text ${
                                  selectedPropertyType === propertyType.value
                                    ? "property-description-type-text-active"
                                    : ""
                                }`}
                              >
                                {propertyType.label}
                              </p>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              )}

              <SubmitButton handler={handleNext}>
                Next
                <img src={arrowRight} />
              </SubmitButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyType;
