import "react-photo-view/dist/react-photo-view.css";
import { PhotoProvider, PhotoView } from "react-photo-view";
import Leasedstatuscards from "Components/GeneralComponents/Leasedstatuscards";
import inProgressIcon from "assets/task-details-progress.png";
import DueDateIcon from "assets/task-details-due.png";
import Loader from "Helpers/Loader";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import { FirstCharacterIcon } from "Components/GeneralComponents";

const OverviewTab = ({ task, isLoading }) => {
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="row mt-3">
            <Leasedstatuscards
              title="Due Date"
              icon={DueDateIcon}
              date={
                task?.dueDate
                  ? new Date(task?.dueDate).toLocaleDateString()
                  : "Not Set"
              }
            />
            <Leasedstatuscards
              title="Status"
              icon={inProgressIcon}
              value={task?.status}
            />
            <Leasedstatuscards
              title="Priority"
              icon={DueDateIcon}
              pfrequency={task?.priority ? task?.priority : "Not Set"}
            />
          </div>
          <div className="m-md-3">
            <div className="row mt-5 mb-3 ">
              <div className="d-flex flex-column flex-md-row justify-content-md-between">
                <div className="col-md-9 col-12 order-md-0 order-1">
                  <div className="task-info-heading">
                    <h4>TASK INFO</h4>
                  </div>
                  <div className=" d-flex flex-column">
                    <div className="mt-4 task-info-lists ">
                      <p>
                        <span className="task-info-list-span me-3">Title:</span>{" "}
                        {task?.title}
                      </p>

                      <p>
                        <span className="task-info-list-span me-3">
                          Assigned To:
                        </span>{" "}
                        {task?.assignees?.length !== 0
                          ? task?.assignees?.map((item) => item.name).join(", ")
                          : "_"}
                      </p>

                      <p>
                        <span className="task-info-list-span me-3">
                          Related To:
                        </span>{" "}
                        <Link
                          to={`/tenant-property-details-view?id=${task?.property?._id}`}
                        >
                          {task?.property?.address}
                        </Link>
                      </p>
                      <p>
                        <span className="task-info-list-span me-3 text-wrap">
                          Description:
                        </span>{" "}
                        {task?.description ? task?.description : "_"}
                      </p>
                    </div>
                  </div>
                </div>
                {task?.images?.length > 0 ? (
                  <div
                    className="order-md-1 order-0 position-relative "
                    style={{
                      marginBottom: "15px",
                      width: "180px",
                      height: "180px",
                    }}
                  >
                    <img
                      className="img-fluid rounded-circle w-100 h-100 object-cover position-absolute top-0 start-0"
                      src={task?.images ? task?.images[0] : ""}
                      alt=""
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            {task?.images?.length > 0 ? (
              <>
                <div className="row border-top">
                  <div className="col-md-12 mt-4">
                    <div className="task-info-heading">
                      <h4 className="fw-bold">Media</h4>
                    </div>
                    <div className=" mt-4 d-flex gap-3 flex-wrap col-12">
                      <PhotoProvider>
                        <div className=" d-flex gap-2 flex-wrap ">
                          {task?.images?.map((item, index) => (
                            <PhotoView key={index} src={item}>
                              <img
                                src={item}
                                alt=""
                                width={"150px"}
                                className=""
                                style={{ cursor: "pointer" }}
                              />
                            </PhotoView>
                          ))}
                        </div>
                      </PhotoProvider>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <div className="row mt-5 border-top" style={{ maxHeight: "770px" }}>
              <div className="col-md-12 mt-4">
                <div className="task-info-heading mb-5">
                  <h4 className="fw-bold">TASK UPDATES</h4>
                </div>
                <div className="task-updates-container">
                  {task?.updates?.map((item, index) => (
                    <div
                      className={`d-flex align-items-center gap-4  ${
                        index > 0 ? "border-top " : " "
                      }`}
                    >
                      <div
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          overflow: "hidden",
                          position: "relative",
                          backgroundColor: "white",
                          marginTop: "13px",
                          marginBottom: "13px",
                        }}
                      >
                        {item.createdBy.profileImage ? (
                          <Tooltip title={item.createdBy.name}>
                            <img
                              src={item.createdBy.profileImage}
                              alt="profile pic"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <>
                            {item.createdBy.name && <FirstCharacterIcon
                              name={item.createdBy.name}
                              tooltip={true}
                            />}
                          </>
                        )}
                      </div>
                      <div className="d-flex flex-column col-7">
                        <span className="fw-normal">
                          {new Date(item.createdAt).toLocaleDateString()}
                        </span>
                        <span className="fw-bold">{item.inputDetails}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default OverviewTab;
