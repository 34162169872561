import MobileChatBody from "Components/Chat/MobileChatBody/MobileChatBody";
import React from "react";

const MobileChat = () => {
  return (
    <div className="container-fluid h-100 bg-white">
      <div className="row h-100">
        <div className="col-md-12 mt-3">
          <MobileChatBody />
        </div>
      </div>
    </div>
  );
};

export default MobileChat;
