import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import PropertiesDeleteView from "Components/PropertiesDeleteView/PropertiesDeleteView";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";

const PropertiesDeleteViewPage = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar topBarTitle={"Archive Properties"} />
      <PropertiesDeleteView />
    </div>
  );
};

export default PropertiesDeleteViewPage;
