import { useState } from "react";
import { Table, ConfigProvider } from "antd";
import searchIcon from "assets/search.png";
const LandLordDashboardChart = () => {
  // States start
  const [selectionType, setSelectionType] = useState("checkbox");
  // States end

  const columns = [
    {
      title: "Lease Id",
      dataIndex: "leaseId",
      render: (text) => <p className="lease_id_text">{text}</p>,
    },
    {
      title: "Tenant Name",
      dataIndex: "tenantName",
    },
    {
      title: "Property Address",
      dataIndex: "address",
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (text) => <span className="phone">{text}</span>,
    },
  ];
  const data = [
    {
      key: "1",
      leaseId: 24301,
      tenantName: "Olivia Rhye",
      address: "295 Retton CirWilliamson",
      date: "12-11-22 -12-12 - 23",
    },
    {
      key: "2",
      leaseId: 23489,
      tenantName: "Phoenix Baker",
      address: "1500 Marilla St, Dallas",
      date: "10-11-22 - 12-12 - 23",
    },
    {
      key: "3",
      leaseId: 88076,
      tenantName: "Lana Steiner",
      address: "1908 Parrish Avenue",
      date: "12-11-22 -12-12 - 23",
    },
    {
      key: "4",
      leaseId: 23556,
      tenantName: "Drew Cano",
      address: "Andi Lane",
      date: "12-11-22 -12-12 - 23",
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  return (
    <>
      <div className="row mb-3">
        <div className="col-md-12">
          <div className="dashboard-table-search-bar d-flex gap-2 align-items-center">
            <div className="table-search-input position-relative flex-grow-1">
              <input
                type="text"
                placeholder="Search"
                className="form-control search-form-control"
              />
              <div className="search-icon">
                <img src={searchIcon} alt="" />
              </div>
            </div>
            <button className="table-filter-btn more-filters-btn">
              {" "}
              <svg
                width={20}
                height={20}
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4 6h16" />
                <path d="M6 12h12" />
                <path d="M8 18h8" />
              </svg>{" "}
              More filters
            </button>
            {/* <button className='table-filter-btn'> <img src={searchFilterIcon} alt="" /> More filters</button> */}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="dashboard-table">
            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    colorTextHeading: "#667085",
                    //colorTextDescription: '#667085',
                    colorText: "#667085",
                    fontSize: 14,
                    fontWeightStrong: 500,
                    fontFamily: "Montserrat",
                    //cellFontSize: 14,
                  },
                },
              }}
            >
              <Table
                className="table-responsive"
                rowSelection={{
                  type: selectionType,
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={data}
                pagination={false}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandLordDashboardChart;
