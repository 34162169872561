import React from "react";
import { ThreeDots } from "react-loader-spinner";

const ThreeDotsLoader = () => {
  return (
    <ThreeDots
      height="30"
      width="30"
      color="#fff"
      ariaLabel="bars-loading"
      wrapperStyle={{ justifyContent: "center" }}
      wrapperClass=""
      visible={true}
    />
  );
};

export default ThreeDotsLoader;
