import React from "react";

const BackArrow = ({width=15, height=15}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.438 18.75 4.688 12l6.75-6.75" />
      <path d="M5.625 12h13.688" />
    </svg>
  );
};

export default BackArrow;
