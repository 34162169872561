import React, { useState, useEffect } from "react";
import {
  CustomTable,
  DeleteModal,
  FirstCharacterIcon,
  IconList,
  RecordMenuPopup,
} from "Components/GeneralComponents";
import searchIcon from "assets/search.png";
import { Link, useNavigate } from "react-router-dom";
import activeDot from "assets/_Dot.png";
import inactiveDot from "assets/inactivedot.png";
import { VerticalElipsisIcon } from "assets/icons";
import * as SVG from "assets/svg";
import helpIcon from "assets/help-circle.png";
import { sendInvite } from "network/landlord/tenant";
import { Tooltip, message } from "antd";
import { formatPhoneNumber } from "utils";
import { deleteTenant, DeleteBulkTenant } from "network/landlord/tenant";
import trashIconWhite from "assets/trash-icon-white.png";
import { getTenants } from "network/landlord/tenant";

const TenantsTab = ({ id, property }) => {
  const navigate = useNavigate();
  const filter = {
    property: property,
    unit: id,
  };
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [key, setKey] = useState(null);
  const [tenantData, setTenantData] = useState([]);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isDeleteTenantModal, setIsDeleteTenantModal] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState(
    "Are you sure you want to delete this tenant?"
  );
  const [mulDeleteMsg, setMulDeleteMsg] = useState(
    "Are you sure want to delete selected tenants?"
  );
  const [delId, setDelId] = useState("");
  const [selectedTableItem, setSelectedTableItem] = useState([]);
  const [isDeleteTenantRender, setIsDeleteTenantRender] = useState(false);
  const [isDeleteTenantsRender, setIsDeleteTenantsRender] = useState(false);
  const [disableDltBtn, setDisableDltBtn] = useState(false);
  const [disableMulDltBtn, setDisableMulDltBtn] = useState(false);
  const [isRerender, setIsRerender] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getTenants(filter).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        setTenantData(
          res.message.data
            .filter((e) => e.name.toLowerCase().includes(search.toLowerCase()))
            .map((e) => ({
              key: e.id,
              name: e.name,
              img: e?.profileImage,
              properties: e.properties,
              email: e.email,
              phone: e.phone,
              status: e.status,
            }))
        );
      } else {
        console.log("Error in fetching tenant data");
      }
    });
  }, [search, isRerender]);

  const handleIconClick = (result) => {
    // Toggle the dropdownOpen state
    const filterData = tenantData.filter((item) => {
      return item.key === result;
    });
    setKey(filterData.key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const handleSendInvite = () => {
    sendInvite(key).then((res) => {
      if (res.apiCallStatus === "success") {
        message.success(res.message.message);
      }
    });
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const onCloseDeleteTenantModal = () => {
    setIsDeleteTenantModal(false);
  };

  const onOpenDeleteTenantModal = () => {
    setIsDeleteTenantModal(true);
  };

  const columns = [
    {
      title: "Tenant Name",
      dataIndex: "name",
      render: (text, name) => (
        <>
          <Link
            to={`/tenant-details-view?id=${name.key}`}
            className="d-flex align-items-center gap-2"
          >
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                overflow: "hidden",
                border: `0.5px solid #c9c9c9`,
                position: "relative",
              }}
            >
              {name.img ? (
                <img
                  src={name.img}
                  alt="task"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <FirstCharacterIcon name={name.name} />
              )}
            </div>
            <div className="">{text}</div>
          </Link>
        </>
      ),
    },
    // {
    //   title: (
    //     <>
    //       Properties
    //       <Tooltip title={"Non-Expired Leases Properties"}>
    //         <img src={helpIcon} alt="" className="cursor ps-2" />
    //       </Tooltip>
    //     </>
    //   ),
    //   dataIndex: "properties",
    //   render: (text) => (
    //     <>
    //       <span className="tenants_table_properties_text">
    //         <IconList
    //           properties={text}
    //           defaultimage={SVG.defaultPropertyIcon}
    //         />
    //       </span>
    //     </>
    //   ),
    // },
    {
      title: "Email Address",
      dataIndex: "email",
      render: (text) => (
        <>
          <span className="tenants_table_email_text">{text}</span>
        </>
      ),
    },
    {
      title: (
        <>
          Phone No
          <Tooltip title={"Tenant Phone Number"}>
            <img src={helpIcon} alt="" className="cursor ps-2" />
          </Tooltip>
        </>
      ),
      dataIndex: "phone",
      render: (text) => (
        <>
          {" "}
          <span className="tenants_table_phone_text phone">
            {formatPhoneNumber(text)}
          </span>
        </>
      ),
    },
    {
      title: (
        <>
          Status
          <Tooltip title={"Shows if tenant account is created"}>
            <img src={helpIcon} alt="" className="cursor ps-2" />
          </Tooltip>
        </>
      ),
      dataIndex: "status",
      render: (text, status) => (
        <>
          <div
            className={
              text === "Active"
                ? "prospect-active-bar"
                : "prospect-inactive-bar"
            }
          >
            <img src={text === "Active" ? activeDot : inactiveDot} alt="" />{" "}
            <span>{text}</span>
          </div>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <VerticalElipsisIcon width={23} height={23} />
            <RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleDeleteRecord: () => {
                  setDelId(setting.key);
                  onOpenDeleteModal();
                },
                handleView: () => {
                  navigate(`/tenant-details-view?id=${setting.key}`);
                },
                handleEdit: () => {
                  navigate(`/edit-tenant-details/${setting.key}`);
                },
                handleSendInvite: handleSendInvite,
              }}
              fields={{
                view: true,
                sendInvite: setting.status !== "Active",
                edit: true,
                delete: true,
              }}
            />
          </div>
        </>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTableItem([...selectedRowKeys]);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  // handle delete tenant
  useEffect(() => {
    if (delId) {
      setIsLoading(true);
      deleteTenant(delId).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          onCloseDeleteModal();
          message.success("Tenant Deleted Successfully");
          setIsRerender(!isRerender);
          setDelId("");
        } else {
          setDeleteMsg(res.message);
          setDisableDltBtn(true);
        }
      });
    }
  }, [isDeleteTenantRender]);

  // handle multiple delete tenants
  useEffect(() => {
    if (selectedTableItem.length > 0) {
      setIsLoading(true);
      DeleteBulkTenant(selectedTableItem).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          onCloseDeleteTenantModal();
          message.success("Tenants Deleted Successfully");
          setIsRerender(!isRerender);
          setSelectedTableItem([]);
        } else {
          setMulDeleteMsg(res.message);
          setDisableMulDltBtn(true);
        }
      });
    }
  }, [isDeleteTenantsRender]);

  return (
    <div>
      <DeleteModal
        isOpen={openDeleteModal}
        onClose={onCloseDeleteModal}
        message={deleteMsg}
        deleteCall={() => setIsDeleteTenantRender(!isDeleteTenantRender)}
        isLoading={isLoading}
        disableBtn={disableDltBtn}
      />

      <DeleteModal
        isOpen={isDeleteTenantModal}
        onClose={onCloseDeleteTenantModal}
        message={mulDeleteMsg}
        deleteCall={() => setIsDeleteTenantsRender(!isDeleteTenantsRender)}
        isLoading={isLoading}
        disableBtn={disableMulDltBtn}
      />

      <div className="row mb-3">
        <div className="col-md-12">
          <div className="task-search-input position-relative">
            <input
              type="text"
              placeholder="Search"
              className="form-control search-form-control-task"
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="search-icon-task">
              <img src={searchIcon} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="task-table-container mt-3">
        {selectedTableItem.length !== 0 && (
          <div className="table-delete-icon mb-3">
            <button
              onClick={() => setIsDeleteTenantModal(true)}
              className="table-delete-btn next-btn-main"
            >
              <img src={trashIconWhite} alt="" />
              Delete
            </button>
          </div>
        )}
        <CustomTable
          // rowSelection={{
          //   type: selectionType,
          //   ...rowSelection,
          // }}
          columns={columns}
          dataSource={tenantData}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default TenantsTab;
