import React, { useState, useEffect } from "react";
import { Tooltip } from "antd";
import { propertyIcon } from "utils/index";

const IconList = ({
  properties,
  defaultimage,
  name = false,
  properticon = false,
}) => {
  const [titles, setTitles] = useState([]);

  useEffect(() => {
    if (properties.length > 3) {
      const propertiesToConsider = properties.slice(3);
      const extractedTitles = propertiesToConsider.map((property) =>
        propertiesToConsider.length === property
          ? name
            ? property.name + ", "
            : property.title + ", "
          : name
          ? property.name + " "
          : property.title + " "
      );
      setTitles(extractedTitles);
    }
  }, [name, properties]);

  return (
    <>
      {properties.length > 0 ? (
        <div className="row">
          <div className="col-md-12 custom-padding-left d-flex  flex-nowrap ">
            {properties.map((property, index) => {
              return index < 3 ? (
                property.img || property.profileImage ? (
                  <Tooltip
                    key={index}
                    title={name ? property.name : property.address}
                  >
                    <div className="customclassimagdiv">
                      <img
                        src={name ? property.profileImage : property.img}
                        alt="defaultImage"
                        loading="lazy"
                        className=" custom-css-image"
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip
                    key={index}
                    title={name ? property.name : property.address}
                  >
                    <div className="customclassimagdiv">
                      <img
                        src={
                          properticon
                            ? propertyIcon(property.type)
                            : defaultimage
                        }
                        alt="defaultImage"
                        loading="lazy"
                        className=" rounded-circle custom-css-image p-1"
                      />
                    </div>
                  </Tooltip>
                )
              ) : index === properties.length - 1 ? (
                <Tooltip key={index} title={titles}>
                  <div className="customclassimagdiv">
                    <div className="lastcricle ">{properties.length - 3}+</div>
                  </div>
                </Tooltip>
              ) : (
                " "
              );
            })}
          </div>
        </div>
      ) : (
        "Not Assigned"
      )}
    </>
  );
};

export default IconList;
