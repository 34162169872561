import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import TenantPropertyDetailsView from "Components/TenantPropertyDetailsView/TenantPropertyDetailsView";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const PropertiesDetail = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Property Details"}
        back="tenant-properties"
      />
      <TenantPropertyDetailsView />
    </div>
  );
};

export default PropertiesDetail;
