import { useEffect, useState } from "react";
import SearchBar from "Helpers/SearchBar";
import { Avatar } from "antd";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import SettingAddUserDetailsModal from "Modals/SettingEditUserDetailsModal/SettingEditUserDetailsModal";
import {
  ButtonVariant1,
  CustomTable,
  DeleteModal,
  RecordMenuPopup,
  FormModalLayout,
} from "Components/GeneralComponents";
import { FilterValue } from "Store/Slices/FilterSlice";
import { useDispatch } from "react-redux";
import { VerticalElipsisIcon } from "assets/icons";
import { getLandlordUsers, deleteLandlordUser } from "network/landlord/setting";
import trashIconWhite from "assets/trash-icon-white.png";

const SettingsUserInfo = () => {
  // States start
  const [selectionType, setSelectionType] = useState("checkbox");
  const [selectedTableItem, setSelectedTableItem] = useState("");
  const [key, setKey] = useState([]);
  const [OpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [update, setUpdate] = useState(false);
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [isRerender, setIsRerender] = useState(false);
  const [isDeleteUserRender, setIsDeleteUserRender] = useState(false);
  const [isDeleteUsersRender, setIsDeleteUsersRender] = useState(false);
  const [OpenBulkDeleteModal, setOpenBulkDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const search = useSelector((state) => {
    return state.Search.value;
  });

  const dispatch = useDispatch();

  // States end
  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const onCloseBulkDeleteModal = () => {
    setOpenBulkDeleteModal(false);
  };
  const onOpenAccountModal = () => {
    setOpenAccountModal(true);
  };
  const onCloseModalWorkOrder = () => {
    setOpenAccountModal(false);
  };
  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };
  // Data Table functions
  useEffect(() => {
    setLoader(true);
    getLandlordUsers().then((res) => {
      console.log("My Response >>>", res);
      setLoader(false);
      // roles structure
      /**
       * {
       *  roles: [{
       *  role: "Admin"
       * }]
       */
      if (res.apiCallStatus === "success") {
        const data = res.message
          .filter((e) =>
            e.firstName.toLowerCase().includes(search.toLowerCase())
          )
          .map((e) => ({
            key: e.id,
            user: e.firstName + " " + e.lastName,
            phone: e.phone,
            email: e.email,
            role: e?.roles.map((e) => e.role).join(", "),
            img: e?.profileImage,
          }));
        setUsersData(data);
      }
    });
  }, [isRerender, search, update]);

  const resetFilter = () => {
    dispatch(FilterValue({ reset: "reset" }));
  };

  const range = useSelector((state) => {
    return state.FilterValue.value;
  });
  const property = useSelector((state) => {
    return state.FilterValue.property;
  });
  const fromDate = useSelector((state) => {
    return state.FilterValue.fromDate;
  });
  const toDate = useSelector((state) => {
    return state.FilterValue.toDate;
  });

  const handleIconClick = (result) => {
    // Toggle the dropdownOpen state
    const filterData = usersData.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const columns = [
    {
      title: "User",
      dataIndex: "user",
      //   ellipsis: true,
      render: (text, user) => (
        <>
          <Link to={`/user-details?id=${user.key}`}>
            {user.img ? (
              <>
                <img
                  className="rounded-5 property-table-image mw_40 mh_40 me-2"
                  src={user.img}
                  alt=""
                />
                <span className="text-dark"> {text}</span>
              </>
            ) : (
              <>
                <Avatar
                  className="me-2"
                  style={{
                    backgroundColor: "#EF6B3E",
                    verticalAlign: "middle",
                  }}
                  size="large"
                >
                  {text[0]}
                </Avatar>
                <span className="text-dark"> {text}</span>
              </>
            )}
          </Link>
        </>
      ),
    },
    {
      title: "Phone No",
      dataIndex: "phone",
      render: (text) => (
        <>
          {" "}
          <span className="phone">{text}</span>
        </>
      ),
    },
    {
      title: "Email address",
      dataIndex: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (text) => <span className="fw-bold">{text}</span>,
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <VerticalElipsisIcon width={23} height={23} />
            <RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleDeleteRecord: () => {
                  setDeleteId(setting.key);
                  onOpenDeleteModal();
                },
                handleView: () => {
                  navigate(`/user-details?id=${setting.key}`);
                },
                handleEdit: () => {
                  onOpenAccountModal();
                  setDeleteId(setting.key);
                },
              }}
              fields={{
                view: true,
                edit: true,
                delete: true,
              }}
            />
          </div>
        </>
      ),
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      console.log(selectedTableItem.length);
      setSelectedTableItem(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  useEffect(() => {
    if (isDeleteUserRender) {
      setIsLoading(true);
      deleteLandlordUser(deleteId).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          setIsRerender(!isRerender);
          setIsDeleteUserRender(false);
          onCloseDeleteModal();
        }
      });
    }
  }, [isDeleteUserRender]);

  return (
    <>
      {openAccountModal === true ? (
        <FormModalLayout
          title={"Add User Details"}
          isOpen={onOpenModal}
          onClose={onCloseModalWorkOrder}
        >
          <SettingAddUserDetailsModal
            onOpen={onOpenModal}
            onClose={onCloseModalWorkOrder}
            id={deleteId}
            setIsRerender={setIsRerender}
          />
        </FormModalLayout>
      ) : (
        ""
      )}
      <DeleteModal
        isOpen={OpenDeleteModal}
        onClose={onCloseDeleteModal}
        message={"Are you sure you want to delete this user?"}
        deleteCall={() => setIsDeleteUserRender(!isDeleteUserRender)}
        isLoading={isLoading}
      />
      <DeleteModal
        isOpen={OpenBulkDeleteModal}
        onClose={onCloseBulkDeleteModal}
        message={"Are you sure you want to delete these users?"}
        deleteCall={() => setIsDeleteUsersRender(!isDeleteUsersRender)}
        isLoading={isLoading}
      />
      <div className="pe-3">
        <p className="heading pt-4 pb-5 ps-4">USERS</p>
        <SearchBar btnTitle="Add User" route={`./settings/add-user-details`} />
        {range || property || fromDate || toDate ? (
          <div className="mt-2" style={{ width: "fit-content" }}>
            <ButtonVariant1 handler={resetFilter} children={"Reset"} />
          </div>
        ) : (
          ""
        )}
      </div>
      {selectedTableItem.length >= 1 && (
        <div
          className="table-delete-icon mt-3"
          onClick={() => setOpenBulkDeleteModal(true)}
        >
          <button className="table-delete-btn next-btn-main">
            <img src={trashIconWhite} alt="" />
            Delete
          </button>
        </div>
      )}
      <div className="setting-useinfo-container ">
        <CustomTable
          columns={columns}
          dataSource={usersData}
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          pagination={true}
          loading={loader}
        />
      </div>
    </>
  );
};

export default SettingsUserInfo;
