import React from "react";


function Leasedstatuscards({ icon, value, title, days, pfrequency, date,balance}) {

  const formatNumber = (value) => {
    return new Intl.NumberFormat('en-US').format(value);
  };
  return (
    <div className="col-md-3 d-flex justify-content-between flex-grow-1  ">
      <div className=" p-3 task-overview-tab-boxes flex-grow-1">
        {icon && (<div className="overview-box-img d-flex  justify-content-between  ">
          <img src={icon} alt="icon" />
        </div>)}
        <div className="over-view-box-text   ">
          <span>{title}</span>
          {date ? (
            <h2 className="over-view-box-text text-black p-1 ">{date}</h2>
          ) : pfrequency ? (
            <div className=" p-1 ">
              {" "}
              <h4 className={`${(pfrequency==="Medium") ? "bg-warning important" : (pfrequency==="High")? "custom-red-color" : (pfrequency==="Low") ?  "bg-secondary important" : "bg-black" }`}  >{pfrequency}</h4>
            </div>
          ) : typeof value == "string" ? (
            <div className="over-view-box-text">
              <p className="active-label pb-0 mb-0"></p>
              <h2 className="custom-red-color ">
                {value}{" "}
                {days !== undefined ? <span>(Expire in {days} days)</span> : ""}
              </h2>
            </div>
          ) : ( balance ? (<h2 className="over-view-box-text p-1 font-weight   ">{formatNumber(value)}</h2>) : (<h2 className="over-view-box-text p-1 font-weight   ">${value}</h2>)
            
          )}
        </div>
      </div>
    </div>
  );
}
export default Leasedstatuscards;
