import React, { useEffect } from "react";
import UseGetHook from "Hooks/UseGetHook";
import * as Component from "Components/GeneralComponents";

const radioOptions = [
  { value: true, label: "This vendor can work on all my properties" },
  { value: false, label: "This vendor can work only on specific properties" },
];

const Properties = ({
  form,
  handleChange,
  errors,
  handleBackButton,
  handleNext,
}) => {
  const { fetchProperty, PropertyData } = UseGetHook("property");
  useEffect(() => {
    fetchProperty();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row mt-3">
        <div className="col-md-12">
          {console.log(form)}
          <Component.RadioGroupField
            value={form.can_access_all_properties}
            handler={(e) => handleChange("can_access_all_properties", e)}
            options={radioOptions}
          />
          {!form.can_access_all_properties && (
            <div className="mt-4">
              <Component.SelectField
                mode="multiple"
                name={"properties"}
                required={true}
                label={"Properties"}
                placeholder={"Select Properties"}
                value={form.properties.map((e) => e)}
                options={PropertyData.map((e) => {
                  return { value: e.id, label: e.title };
                })}
                errorMessage={errors?.properties}
                handler={(e) => handleChange("properties", e)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="stepper-btn d-flex justify-content-between mt-5 gap-1">
        <div style={{ width: "50%" }}>
          <Component.SubmitButton type="contained" handler={handleBackButton}>
            Back
          </Component.SubmitButton>
        </div>
        <div style={{ width: "50%" }}>
          <Component.SubmitButton handler={handleNext}>
            Next
          </Component.SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default Properties;
