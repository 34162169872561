import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import AllProspect from "Components/AllProspect/AllProspect";
import NoAccessPage from "Pages/NoAccessMainPage/NoAccessPage";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";

const AllProspectPage = () => {
  const storedRoles = localStorage.getItem("myUserRoles");
  const myUserRoles =
    storedRoles !== "undefined" ? JSON.parse(storedRoles) : [];
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar topBarTitle={"All Prospect"} />
      {!["Owner/Executive", "Property Manager", "Leasing Agent"].some((role) =>
        myUserRoles?.includes(role)
      ) && myUserRoles?.length !== 0 ? (
        <NoAccessPage />
      ) : (
        <AllProspect />
      )}
    </div>
  );
};

export default AllProspectPage;
