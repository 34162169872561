import React from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import {Progress} from 'antd';
function PofileProgress({handleClose}) {
    const navigate = useNavigate()
    const handleLinkClick = (path) => {
      if (handleClose) {
        handleClose(); // Call onClose if it's provided (for mobile)
      }
      navigate(path)
    };
  return (
    <>
      
         <div
         style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
           width: '100%',
 
         }}
         >
         <div 
       
          style={{
            width: '75%',
            marginTop: '10px'
          }}
          >
            <ConfigProvider
              theme={{
                components: {
                  Progress: {
                    colorText: "#FFF",
                  },
                },
              }}
            >
              <Progress
                strokeColor="#EF6B3E"
                trailColor="#EAECF0"
                className="text-white"
                percent={60}
              />
            </ConfigProvider>
            <p
            style={{
              cursor: "pointer"
            }}
            onClick={() => handleLinkClick("/settings/personal-info")}   id="progress-bar"  className="text-white">Complete your Profile</p>
          </div>
        
         </div>
    </>
  );
}

export default PofileProgress;
