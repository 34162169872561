import axios from "axios";
import config from "Helpers/config.js";

const addNote = async (data) => {
  try {
    const response = await axios.post(`${config.baseUrl}/api/notes`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getNotes = async (params) => {
  try {
    console.log(`Params: ${params}`);
    const response = await axios.get(`${config.baseUrl}/api/notes`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: params,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const editNote = async (id, data) => {
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/notes/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteNote = async (id) => {
  try {
    const response = await axios.delete(`${config.baseUrl}/api/notes/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteMultipleNotes = async (ids) => {
  try {
    const response = await axios.delete(`${config.baseUrl}/api/notes`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: { ids: ids },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export { addNote, getNotes, editNote, deleteNote, deleteMultipleNotes };
