import React, { useState } from "react";
import { FormModalLayout } from "Components/GeneralComponents";
import Profile from "../CompanyProfileSetup/Profile";
import { SuccessModal } from "Components/GeneralComponents";
function Settings({isOpen, onClose}) {
  
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [images, setImages] = useState([]);
  const [insuranceDocuments, setInsuranceDocuments] = useState([]);
  const [form, setForm] = useState({
    companyName: "",
    companyType: "",
    ssn: "",
    doingBusinessAs: "",
    description: "",
    services: [],
    addressLine_1: "",
    state: "",
    zipcode: "",
    country: "US",
    insuranceDetails: "",
    insuranceExpirationDate: "",
  });

  const handleChange = (fieldName, value) => {
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  const handleSubmit = () => {
    console.log("Submitting form with values : ", form);
    console.log(" images : ", images);
    setIsSuccessModalOpen(true);
    setIsModalOpen(false);
  };
  return (
    <>
      {isSuccessModalOpen && (
        <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={() => setIsSuccessModalOpen(false)}
          title="Your Information Saved Successfully"
        />
      )}
      {isModalOpen && (
        <FormModalLayout
          title="Company Profile Setup"
          isOpen={isOpen}
          onClose={onClose}
          submitLabel="Submit"
          submitDisabled={false}
        >
          <Profile
            form={form}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            images={images}
            setImages={setImages}
            insuranceDocuments={insuranceDocuments}
            setInsuranceDocuments={setInsuranceDocuments}
          />
        </FormModalLayout>
      )}
    </>
  );
}

export default Settings;
