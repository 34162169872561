import React from "react";
import AccountDetails from "Components/AccountDetails/AccountDetails";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";

const AccountsDetail = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Accounting"}
        back="all-accounts"
      />
      <AccountDetails />
    </div>
  );
};

export default AccountsDetail;
