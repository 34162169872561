import AllServiceProfessional from "Components/AllServiceProfessional/AllServiceProfessional";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import TitleTopBar from "Helpers/TitleTopBar";
import NoAccessPage from "Pages/NoAccessMainPage/NoAccessPage";
import React from "react";

const ServiceProfessionals = () => {
  const storedRoles = localStorage.getItem("myUserRoles");
  const myUserRoles =
    storedRoles !== "undefined" ? JSON.parse(storedRoles) : [];
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar topBarTitle={"All Service Professional"} />
      {!["Owner/Executive", "Property Manager"].some((role) =>
        myUserRoles?.includes(role)
      ) && myUserRoles?.length !== 0 ? (
        <NoAccessPage />
      ) : (
        <AllServiceProfessional />
      )}
    </div>
  );
};

export default ServiceProfessionals;
