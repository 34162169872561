import AddMileage from "Components/AddMileage/AddMileage";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const MileageNew = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Add Mileage"}
        pop={true}
      />
      <AddMileage />
    </div>
  );
};

export default MileageNew;
