import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import Help from "Components/Help/Help";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const HelpPage = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar topBarTitle="Help" />
      <Help />
    </div>
  );
};

export default HelpPage;
