import React from "react";
import task from "assets/task.png";
import workorder from "assets/workorder.png";
import { Link } from "react-router-dom";
import UserPermission from "libs/UserPermission";
const Maintenance = () => {
  const { ROLE } = UserPermission();

  return (
    <>
      <div className="container-fluid p-4">
        <div className="row">
          <div className="col-md-12 text-center">
            <p className="contact-page-title my-4">
              Select a Maintenance Category: Task or Work Order
            </p>
          </div>
        </div>
        <div className="container-xl mx-auto row justify-content-center row-gap-4 column-gap-4 px-0 px-md-2 px-lg-4">
          <div className="col-12 col-md p-0">
            <div className="card contact-page-card-main h-100 mx-auto">
              <Link to="/all-task">
                <div className="card-body px-3 py-4 p-lg-4">
                  <img
                    src={task}
                    alt=""
                    className="d-block mx-auto mb-3 contact-page-card-img"
                    style={{ height: "160px" }}
                  />
                  <p className="text-center contact-page-sub-title">Tasks</p>
                  <p className="contact-page-sub-text text-center mb-0">
                    A general responsibilities or actions that need to be
                    completed, such as updating a tenant record or notification
                    about a scheduled inspection.
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-12 col-md p-0">
            <div className="card contact-page-card-main h-100 mx-auto">
              {/* <Link to="/all-work-order"> */}
              <div className="card-body px-3 py-4 p-lg-4">
                <img
                  src={workorder}
                  alt=""
                  className="d-block mx-auto mb-3 contact-page-card-img"
                />
                <p className="text-center contact-page-sub-title">
                  Work Orders
                </p>
                <p className="contact-page-sub-text text-center mb-0">
                  A specific requests for maintenance or repairs, such as fixing
                  a broken door or addressing a plumbing issue.
                </p>
              </div>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Maintenance;
