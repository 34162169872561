import React, { useState, useEffect } from "react";
import { DatePicker, ConfigProvider, Space } from "antd";
import calendarIcon from "assets/calendar-icon.png";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import UseUpdateHook from "Hooks/UseUpdateHook";
import ModalLoader from "Components/GeneralComponents/ModalLoader";
import config from "Helpers/config";
import {
  InputField,
  DateField,
  TextAreaField,
  AmountField,
  SaveModalButton,
} from "Components/GeneralComponents";
import { formatDate } from "utils";

const MileageEditModal = ({ onClose, id }) => {
  const [Data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({})
  const [form, setForm] = useState({
    fromLocation: "",
    toLocation: "",
    businessName: "",
    purpose: "",
    miles: "",
    amount: 0,
    tripDate: "",
  })

  // Get Mileages
  //   const { id } = UseUrlParamsHook();
  useEffect(() => {
    fetch(`${config["baseUrl"]}/api/mileage/${id}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        console.log(res);
        if (res.apiCallStatus === "success") {
          const {
            fromLocation,
            toLocation,
            businessName,
            purpose,
            miles,
            amount,
            tripDate,
          } = res.message;
          console.log("TripDate: ============================")
          const formattedDate = formatDate(new Date(tripDate));
                    // handleChange("tripDate", formattedDate)
         setForm({
          fromLocation,
          toLocation,
          businessName,
          purpose,
          tripDate: tripDate? formattedDate : null,
          miles,
          amount
         })

          let temp = [];
          temp = res.message;
          setData([temp]);
          console.log(Data, "success");
        } else {
          console.log(res, "error");
        }
      });
  }, []);

  const handleChange = (fieldName, value) => {
    console.log(fieldName, value);
    setForm({
      ...form,
      [fieldName]: value,
    });
  };
   // Helper function to format error messages
   const formatErrorMessage = (key) => {
    if (key === "itin" || key === "ssn") {
      return `${key.toUpperCase()} is required`;
    } else if (key === "gender") {
      return;
    }
    // Capitalize the first letter and add spaces before capital letters
    return (
      key.charAt(0).toUpperCase() +
      key.slice(1).replace(/([A-Z])/g, " $1") +
      " is required"
    );
  };
  const editMileage = () => {
    setLoader(true);
    const newErrors = {};
    for (const key in form) {
      console.log(key, form[key]);
      if (key === "tripDate" ) continue;

      if (form[key] === "") {
        newErrors[key] = formatErrorMessage(key);
        setLoader(false);
      } 
    }

    setErrors(newErrors); // Set validation errors
    console.log("newErrors:", JSON.stringify(newErrors));

    // Prevent moving to the next step if there are validation errors
    if (Object.keys(newErrors).length > 0) {
      console.log("Returning due to validation errors:", newErrors);
      setLoader(false);
      return;
    }
    fetch(`${config["baseUrl"]}/api/mileage/${id}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
       form
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.apiCallStatus === "success") {
          onClose();
          setLoader(false);

          console.log(res, "Mileage Update");
        } else {
          console.log(res);
        }
      });
  };
  return (
    <>
    {console.log(form)}
      <div>
        <div>
          <div className="modal-content-main-section p-41">
            <div className="row mt-3">
              <div className="col-md-6">
                <InputField
                  label="From"
                  name="fromLocation"
                  placeholder="From"
                  value={form.fromLocation}
                  handler={(e) => handleChange("fromLocation", e.target.value)}
                  errorMessage={errors.fromLocation}
                  required={true}
                />
              </div>
              <div className="col-md-6">
                <InputField
                  label="To"
                  name="toLocation"
                  placeholder="to"
                  value={form.toLocation}
                  handler={(e) => handleChange("toLocation", e.target.value)}
                  errorMessage={errors.toLocation}
                  required={true}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <InputField
                  label="Business Name"
                  name="businessName"
                  placeholder="Enter Business Name"
                  value={form.businessName}
                  handler={(e) => handleChange("businessName", e.target.value)}
                  errorMessage={errors.businessName}
                  required={true}
                />
              </div>
              <div className="col-md-6">
                {console.log(form)}
                <DateField
                  label="Trip Date"
                  name="tripDate"
                  value={form.tripDate}
                  defaultValue={form.tripDate}
                  placeholder={"Select Date"}
                  handler={(e) => {
                    const formattedDate = formatDate(new Date(e));
                    handleChange("tripDate", formattedDate)
                  }}
                  errorMessage={errors.tripDate}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <TextAreaField
                  rows={3}
                  required={true}
                  label="Purpose"
                  name="purpose"
                  placeholder="Add purpose"
                  value={form.purpose}
                  handler={(e) => handleChange("purpose", e.target.value)}
                  errorMessage={errors.purpose}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <InputField
                  type="number"
                  required={true}
                  name="miles"
                  label="Miles"
                  value={form.miles}
                  handler={(e) => handleChange("miles", e.target.value)}
                  errorMessage={errors.miles}
                />
              </div>
              <div className="col-md-6">
                <AmountField
                  name="amount"
                  required={true}
                  label="Amount"
                  value={{amount : form.amount}}
                  handler={(value) => handleChange("amount", value.amount)}
                  errorMessage={errors.amount}
                />
              </div>
            </div>

            <div className="d-flex justify-content-end mt-3">
              <SaveModalButton
                addTask={editMileage}
                loader={loader}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MileageEditModal;
