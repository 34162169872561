import React, { useState } from "react";
import { DatePicker, ConfigProvider, Space, message } from "antd";
import TempModal from "Modals/TempModal/TempModal";
import calendarIcon from "assets/calendar-icon.png";
import { useNavigate } from "react-router-dom";
import {
  InputField,
  DateField,
  AmountField,
  SubmitButton,
} from "Components/GeneralComponents";
import format from "date-fns/format";
import UsePostHook from "Hooks/UsePostHook";
import { formatDate } from "utils";
const AddMileage = () => {
  const dateFormat = "MM/DD/YYYY";
  //States start
  //const [openModal, setOpenModal] = useState(false);
  const [openModal, setOpenModalMileage] = useState(false);
  // const [loader, setLoader] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [tripDate, setTripDate] = useState("");
  const [loader, setLoader] = useState(false);
  //States end
  const [form, setForm] = useState({
    fromLocation: "",
    toLocation: "",
    purpose: "",
    miles: "",
    amount: "",
    tripDate: "",
    businessName: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (fieldName, value) => {
    console.log(fieldName, value);
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  let calendar;
  calendar = (
    <>
      <img src={calendarIcon} alt="" />
    </>
  );
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  // Modal Function
  const onOpenModal = () => {
    setOpenModalMileage(true);
  };
  const onCloseModal = () => {
    setOpenModalMileage(false);
  };
  const navigate = useNavigate();

  // Create Mileage

  const CreateMileage = () => {
    setLoader(true)
    console.log("Creating mileage...", form);
    UsePostHook(
      "api/mileage",
      {
        fromLocation: form.fromLocation,
        toLocation: form.toLocation,
        businessName: form.businessName,
        purpose: form.purpose,
        miles: form.miles,
        amount: form.amount,
        tripDate: form.tripDate,  
      },
      setLoader,
      onOpenModal
    );
    // setLoader(false)
  };
  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  const formatKey = (key) => {
    return key
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };
  const handleNext = () => {
    setLoader(true)
    const newErrors = {};
    for (const key in form) {
      if ( key === "tripDate") {
        continue;
      }
      if (form[key] === "") {
        newErrors[key] = `${formatKey(key)} is required`;
      }
    }
    setErrors(newErrors);
    console.log("new Errors", newErrors);
    if (Object.keys(newErrors).length === 0) {
      CreateMileage();
    }else{
      setLoader(false)
    }
    
  };
  return (
    <>
      {openModal === true && (
        <TempModal
          onClose={onCloseModal}
          title="Mileage added successfully"
          success="Go To Mileage"
          route="mileage"
        />
      )}
      {/*
                openModal && <AccountAddSuccessModal onClose={onCloseModal} success="Success" message="Mileage added successfully" btnTitle={"Go to Mileage"} />
            */}
      <div className="container p-3">
        <div className="container-fluid bg-white p-3">
          <div className="row mt-3">
            <div className="col-md-6">
              <InputField
                name={"From Location"}
                required={true}
                label={"From Location"}
                placeholder={"From location"}
                value={form.fromLocation}
                handler={(e) => handleChange("fromLocation", e.target.value)}
                errorMessage={errors.fromLocation}
              />
            </div>
            <div className="col-md-6">
              <InputField
                name={"To Location"}
                required={true}
                label={"To Location"}
                placeholder={"To location"}
                value={form.toLocation}
                handler={(e) => handleChange("toLocation", e.target.value)}
                errorMessage={errors.toLocation}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <InputField
                name={"businessName"}
                required={true}
                label={"Business Name"}
                placeholder={"Business Name"}
                value={form.businessName}
                handler={(e) => handleChange("businessName", e.target.value)}
                errorMessage={errors.businessName}
              />
            </div>
            <div className="col-md-6">
              <DateField
                label={"tripDate"}
                name={"tripDate"}
                placeholder={"Select Date of Trip"}
                defaultValue={form.tripDate && formatDate(form.tripDate)}
                handler={(e) => {
                  const formattedDate = formatDate(new Date(e));
                  handleChange("tripDate", formattedDate);
                }}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <InputField
                name={"purpose"}
                required={true}
                label={"Purpose"}
                placeholder={"Purpose of Trip"}
                value={form.purpose}
                handler={(e) => handleChange("purpose", e.target.value)}
                errorMessage={errors.purpose}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <InputField
                type={"number"}
                name={"miles"}
                required={true}
                label={"Miles"}
                placeholder={"Miles of Trip"}
                value={form.miles}
                handler={(e) => handleChange("miles", e.target.value)}
                errorMessage={errors.miles}
              />
            </div>
           
            <div className="col-md-6">
              <AmountField
                name="amount"
                required={true}
                label="Amount"
                placeholder={"Enter Trip Amount"}
                value={{amount : form.amount}}
                handler={(value) => handleChange("amount", value.amount)}
                errorMessage={errors.amount}
              />
              
            </div>
          </div>
          <div className="stepper-btn d-flex justify-content-between gap-1 pb-3 mt-5">
            <button
              onClick={() => {
                navigate("/mileage");
              }}
              className="back-prev-btn mt-3"
            >
              Cancel
            </button>
            <SubmitButton 
            handler={handleNext}
            isLoading={loader}
            disabled={loader}
            className="next-btn-same-class mt-3">
              Save
            </SubmitButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMileage;
