import CreatePayable from "Components/CreatePayable/CreatePayable";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const PayableNew = () => {
  return (
    <div className="col-xl-10 col-lg-12 col-md-12 mt-3">
      <Fixedtopbar
        topBarTitleDetail="Create Payable"
        pop={true}
      />
      <CreatePayable />
    </div>
  );
};

export default PayableNew;
