import { SubmitButton } from 'Components/GeneralComponents';
import { TextAreaField } from 'Components/GeneralComponents';
import UsePostHook from 'Hooks/UsePostHook';
import UseUrlParamsHook from 'Hooks/UseUrlParamsHook';
import { Radio, ConfigProvider, message } from 'antd';
import React, { useState } from 'react';
import * as Component from "Components/GeneralComponents";

const InviteProfessionalModal = ({ onClose }) => {
  const { id } = UseUrlParamsHook();
  const [description, setDescription] = useState('');
  const [reason, setReason] = useState('');
  const radioOptions = [
    { value: "need_services", label: "Need Services" },
    { value: "queryForIssue", label: "Query for Issue" },
    { value: "other", label: "Other" },
  ];

  const [form, setForm] = useState({
    reason: '',
    description: '',
  });

  const handleChange = (fieldName, value) => {
    console.log(fieldName, value);
    setForm({
      ...form,
      [fieldName]: value,
    });
  };
  const inviteProfessional = () => {
    if (UsePostHook('api/serviceProviders/sendInvite', { company: id, description, reason: [reason] })) {
      message.success('Successfully invite sent');
      onClose();
    } else {
      message.error('Send invite failed');
    }
  };

  return (
    <div style={{ width: '100%', padding: '20px', boxSizing: 'border-box' }}>
      <div className="row mb-4">
        <div className="col-md-12">
          <p className="fw-semibold" style={{ fontSize: '18px', marginBottom: '10px' }}>
            Why would you like to send an invitation?
          </p>
          <div className="mb-4">
          <Component.RadioGroupField
            value={form.reason}
            handler={(e) => handleChange("reason", e)}
            options={radioOptions}
          />
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-12">
          <TextAreaField
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Type the reason.."
            rows={5}
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #d9d9d9',
            }}
          />
        </div>
      </div>

      <div className="d-flex justify-content-end mt-4">
        <SubmitButton
          className="modal-submit-btn next-btn-main"
          style={{
            padding: '10px 20px',
            borderRadius: '5px',
            fontSize: '16px',
            fontWeight: '600',
            minWidth: '120px',
          }}
          onClick={inviteProfessional}
        >
          Submit
        </SubmitButton>
      </div>
    </div>
  );
};

export default InviteProfessionalModal;
