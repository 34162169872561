import { useEffect, useState } from "react";
import { message } from "antd";
import { IconList } from "Components/GeneralComponents";
import { useNavigate, Link } from "react-router-dom";
import AddPropertyAccountingModal from "Modals/AddPropertyAccountingModal/AddPropertyAccountingModal";
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import * as Component from "Components/GeneralComponents";
import * as Icon from "assets/icons";
import {
  fetchAccount,
  deleteaccount,
  connectstripeaccount,
  fetchClientSecrets,
} from "network/landlord/Account";

const Accounting = () => {
  // States start
  const [data, setData] = useState([]);
  const [key, setKey] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [connectStripe, setConnectStripe] = useState(null);
  const [isRerender, setIsRerender] = useState(false);
  const [isPayNowModelOpen, setIsPayNowModelOpen] = useState(false);
  const [account_id, setAccount_id] = useState("");
  const [property, setProperty] = useState([]);
  const deleteMsg = "Are you sure you want to delete this account?";
  const [filter, setFilter] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    range: "",
    property: "",
  });
  const [isTableLoading, setIsTableLoading] = useState(false);
  // const dispatch = useDispatch();
  useEffect(() => {
    setIsTableLoading(true);
    fetchAccount().then((response) => {
      if (response.apiCallStatus === "success") {
        const taskdata = response.message.map((e) => {
          return {
            key: e?._id,
            account_id: e?.landlordAccount?.account_id,
            account: "Operating Account",
            property: e?.properties.map((item) => {
              return {
                title: item.title,
                img: item?.images ? item.images[0] : null,
                address: item.address
                  ? `${item.address.address_line_1}, ${item.address.city.name}, ${item.address.state.name}, ${item.address.zipcode.number}`
                  : "",
                type: item.property_sub_type,
              };
            }),
            propertyID: e?.properties.map((f) => f?.id),
            status: e?.landlordAccount?.status,
            stripeConnected: e?.landlordAccount?.stripeConnected,
            button: e?.landlordAccount.stripeConnected
              ? "Connected"
              : "Connect again",
          };
        });
        setData(taskdata);

        setIsTableLoading(false);
      } else {
        setData([]);
        setIsTableLoading(false);
      }
    });
  }, [update]);

  const onCloseModalWorkOrder = () => {
    setOpenAccountModal(false);
  };
  const navigate = useNavigate();

  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  // Data Table Functions
  const handleIconClick = (result) => {
    // Toggle the dropdownOpen state
    const filterData = data.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };
  const connectAgain = (account_id) => {
    setIsPayNowModelOpen(true);
    const fetchClientSecret = async () => {
      const response = await fetchClientSecrets(account_id);
      if (response.apiCallStatus === "success") {
        return response.message.client_secret;
      } else {
        console.log("An error occurred: ", response.error.message);
        return undefined;
      }
    };
    const instance = loadConnectAndInitialize({
      publishableKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
      fetchClientSecret: fetchClientSecret,
    });
    setConnectStripe(instance);
  };

  const columns = [
    {
      title: "Account",
      dataIndex: "account",
      render: (text, id) => (
        <Link to={`/account-details/${id.key}/${id.account_id}`}>
          <span className="accounting-table-account-text">{text}</span>
        </Link>
      ),
    },
    {
      title: "Property",
      dataIndex: "property",
      render: (text, data) => (
        <>
          <div className="propertiescolumn">
            <IconList properties={text} properticon={true} />
          </div>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "button",
      render: (text, button) => (
        <>
          {button.stripeConnected ? (
            <button disabled className="connect-bank-btn">
              {text}
            </button>
          ) : (
            <button
              onClick={() => {
                setProperty(button.propertyID);
                setAccount_id(button.account_id);
                connectAgain(button.account_id);
              }}
              className="connect-bank-btn"
            >
              {text}
            </button>
          )}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <Icon.VerticalElipsisIcon width={23} height={23} />
            <Component.RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleView: () => {
                  navigate(
                    `/account-details/${setting.key}/${setting.account_id}`
                  );
                },
                handleDeleteRecord: () => {
                  onOpenDeleteModal();
                  setDeleteId(setting.account_id);
                },
              }}
              fields={{
                view: true,
                delete: true,
                postAnUpdate: false,
                renewLease: setting.renew,
              }}
            />
          </div>
        </>
      ),
    },
  ];

  const deleteaccountfun = (id) => {
    console.log("id ===", id);
    setIsLoading(true);
    deleteaccount(id).then((response) => {
      console.log("response ===", response);
      if (response.apiCallStatus === "success") {
        message.success("Account Deleted Successfully");
        setIsLoading(false);
        setOpenDeleteModal(false);
        setUpdate(!update);
      } else {
        message.error(response.message);
        setIsLoading(false);
        setOpenDeleteModal(false);
      }
    });
  };

  // handle search
  useEffect(() => {}, [filter.search]);

  return (
    <>
      {openAccountModal === true ? (
        <AddPropertyAccountingModal onClose={onCloseModalWorkOrder} />
      ) : (
        ""
      )}
      {openDeleteModal && (
        <Component.DeleteModal
          isOpen={openDeleteModal}
          onClose={onCloseDeleteModal}
          message={deleteMsg}
          deleteCall={() => deleteaccountfun(deleteId)}
          isLoading={isLoading}
        />
      )}
      <div className="container-fluid mt-3 p-3">
        <Component.FilterRow
          filter={filter}
          setFilter={setFilter}
          btnTitle={"Add New Account"}
          btnHandler={() => navigate("/add-account")}
          handleSubmit={() => setIsRerender(!isRerender)}
          filterList={{
            date: true,
            range: true,
            property: true,
          }}
        />
        <div className="table-container mt-5">
          <Component.CustomTable
            loading={isTableLoading}
            pagination={true}
            columns={columns}
            dataSource={data}
            rowSelection={true}
          />
        </div>
      </div>

      {/* Connect again */}
      <Component.FormModalLayout
        title={"Connect to Bank"}
        isOpen={isPayNowModelOpen}
        onClose={() => setIsPayNowModelOpen(false)}
      >
        <div className="stripemodal ">
          <ConnectComponentsProvider connectInstance={connectStripe}>
            <ConnectAccountOnboarding
              onExit={() => {
                message.success("Account added");
                connectstripeaccount(account_id)
                  .then((res) => {
                    if (res.apiCallStatus === "success") {
                      message.success("Account Connected Successfully"); 
                    } else {
                      message.error(res.message);
                      setUpdate(!update);
                    }
                  })
                  .catch((e) => console.log(e, "error"));
                setTimeout(() => {
                  navigate("/all-accounts");
                }, 1000);
              }}
            />
          </ConnectComponentsProvider>
        </div>
      </Component.FormModalLayout>
    </>
  );
};

export default Accounting;
