import React from "react";
import { useEffect } from "react";
import UserPermission from "libs/UserPermission";
import Link from "antd/es/typography/Link";
import Avatar from "antd/es/avatar/avatar";
import settingIcon from "assets/settings.png";
import logOutIcon from "assets/log-out-04.png";
import UseGetHook from "Hooks/UseGetHook";
import config from "Helpers/config";
import { useNavigate } from "react-router-dom";

function SidebarBottom({user, handleClose}) {

  const navigate = useNavigate()
  const Logout = () => {
    fetch(`${config["baseUrl"]}/api/auth/logout`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.apiCallStatus === "success") {
          localStorage.clear();
          window.location = "/";
        } else {
          console.log(res);
        }
      })
      .catch((err) => console.log(err, "error"));
  };

  const handleLinkClick = (path) => {
    if (handleClose) {
      handleClose(); // Call onClose if it's provided (for mobile)
    }
    navigate(path)
  };

  return (
    <div
    id="sidebar-bottom"
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        zIndex: 9998,
        backgroundColor: "#F78b4b",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        padding: "10px",
        flexWrap: "nowrap",
        marginTop: "70px !important"
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flex: "1",
        }}
      >
        {user[0]?.profileImage ? (
          <img
            src={`${user[0]?.profileImage}`}
            alt="Profile"
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        ) : (
          <Avatar
            style={{
              backgroundColor: "#ef6b3e",
              verticalAlign: "middle",
              border: "2px solid #fff",
              fontSize: "20px",
              width: "50px",
              height: "50px",
            }}
            size="large"
          >
            {user[0]?.firstName[0].toUpperCase() }
          </Avatar>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            textAlign: "left",
            paddingLeft: "4px",
            flexGrow: 0.5,
          }}
        >
          <span style={{ color: "#fff", fontSize: "16px" }}>
            {user[0]?.firstName || localStorage.getItem("name") }
          </span>
          <Link onClick={() => handleLinkClick("/settings/personal-info")} style={{ textDecoration: "none" }}
          
          >
            <p
              style={{
                color: "#fff",
                fontSize: "14px",
                marginBottom: "5px",
              }}
              
            >
              <img
                src={settingIcon}
                alt="Settings"
                style={{ marginRight: "5px" }}
              />
              Setting
            </p>
          </Link>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderLeft: "1px solid white",
          paddingLeft: "10px",
          paddingRight: "10px",
          height: "100%",
        }}
      >
        <span
          onClick={() => Logout()}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >

          <img src={logOutIcon} alt="Logout" style={{ width: "20px" }} />
        </span>
      </div>
    </div>
  );
}

export default SidebarBottom;
