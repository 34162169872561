import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import UserDetails from "Components/UserDetails/UserDetails";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const UserDetailsPage = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"User Details"}
        back="./settings/user-info"
      />
      <UserDetails />
    </div>
  );
};

export default UserDetailsPage;
