import React from 'react';

function index() {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center  text-center"
    style={{
        maxHeight: '100vh',
        minHeight: '100vh',
        overflow: 'hidden !important',
    }}
    >
      <div className="p-5 rounded s">
        <h1 className="display-4 mb-4">Contacts Section for Service Professionals</h1>
        <h4 className="text-muted">Coming Soon...</h4>
      </div>
    </div>
  );
}

export default index;
