function PayableDatafield({ fieldName, fieldValue }) {
  return (
    <div className="payable-overview-invoice-field">
      <span>{fieldName} :</span>
      <span>{fieldValue}</span>
    </div>
  );
}

export default PayableDatafield;
