import React from "react";
import { Link } from "react-router-dom";
import * as component from "Components/GeneralComponents";
import Or from "assets/login-or-image.png";
import facebookIcon from "assets/fb login.png";
import googleIcon from "assets/google login.png";
import { LoginSocialFacebook } from "reactjs-social-login";
import * as Icons from "assets/icons";

const TenantLogin = ({
  form,
  errors,
  googleAuth,
  resolveFacebook,
  rejectFacebook,
  handleSubmit,
  handleChange,
}) => {
  return (
    <div className="container-fluid ">
      <div className="land-lord-sign-in pt-2 ps-4 pe-4">
        <div className="onboarding-right-heading text-start mb-5">
          <h1 className="mt-5">Login to Tenant Portal</h1>
          <div className="col-md-12 mt-3 not-a-member">
            Not a member yet?{" "}
            <Link className="primary-orange-text" to="/tenant/register">
              Register Now!
            </Link>
          </div>
          <Link className="primary-orange-text" to="/onboarding">
            <Icons.BackArrow />
            Back
          </Link>
        </div>
        <div className="sign-in-fields">
          <div className="row mt-4">
            <div className="col-md-12">
              <component.InputField
                name={"email"}
                type={"email"}
                placeholder={"Email"}
                value={form.email}
                handler={handleChange}
                errorMessage={errors.email}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <component.PasswordField
                name={"password"}
                placeholder={"Password"}
                value={form.password}
                handler={handleChange}
                errorMessage={errors.password}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <div className="sign-up-buttons d-flex justify-content-center">
                <component.ButtonVariant1 handler={handleSubmit}>
                  Sign In
                </component.ButtonVariant1>
              </div>
            </div>
          </div>
          <div className="remember-me-checkBox d-flex justify-content-center align-items-center flex-column mt-4">
            <p className="text-end">
              <Link to="/forgot-password" className="sign-in-style-text">
                Forgot Password?
              </Link>
            </p>
          </div>
          {/* <div className="remember-me-checkBox d-flex justify-content-center">
            <p className="text-end">
              <img src={Or} alt="" style={{ width: "100%" }} />
            </p>
          </div>
          <div className="row mb-5 ">
            <div className="col-md-12">
              <div className="sign-up-buttons d-flex gap-4 justify-content-center">
                <button onClick={googleAuth} className="google-signup-btn">
                  <img src={googleIcon} alt="" style={{ width: "140px" }} />
                </button>
                <LoginSocialFacebook
                  appId="1129610314899200"
                  autoLoad={false}
                  onResolve={(response) => resolveFacebook(response)}
                  onReject={(error) => rejectFacebook(error)}
                >
                  <button className="facebook-signup-btn">
                    <img src={facebookIcon} alt="" style={{ width: "140px" }} />
                  </button>
                </LoginSocialFacebook>
              </div>
            </div>
          </div> */}
          <div className="row mb-3" style={{ marginTop: "50px" }}>
            <div className="col-md-12 text-center not-a-member">
              Login as{" "}
              <Link className="primary-orange-text" to="/landlord/login">
                Landlord
              </Link>
              <nbsp /> or{" "}
              <Link
                className="primary-orange-text"
                to="/service-professionals/login"
              >
                Service Professional
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenantLogin;
