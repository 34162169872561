import React from "react";
import LoginLine from "assets/Line-login.png";
import CheckBox from "assets/_Checkbox base.png";
import CheckBoxActive from "assets/_Checkbox active.png";

const OnboardingCard = ({ title, description, icon, isActive, handler }) => {
  return (
    <div className="onboarding-right mt-3">
      <div onClick={handler} className="onboarding-select-boxes">
        <div
          className={
            isActive
              ? "onboarding-select-box active-box"
              : "onboarding-select-box"
          }
        >
          <div className="onboarding-select-box-left d-flex align-items-center gap-2">
            <img
              src={icon}
              className="img-fluid object-fit-contain"
              alt=""
              style={{ width: "70px" }}
            />

            <img
              src={LoginLine}
              className="img-fluid object-fit-contain ps-2 pe-2"
              alt=""
              style={{ height: "100px" }}
            />

            <div className="onboarding-box-text">
              {title}
              <br />{description}
            </div>
          </div>
          <div className="onboarding-select-box-check-box">
            <img src={isActive ? CheckBoxActive : CheckBox} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingCard;
