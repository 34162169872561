import React, { useState, useEffect } from "react";
import NotFound from "assets/not-found-img.png";
import {
  addNote,
  getNotes,
  editNote,
  deleteNote,
  deleteMultipleNotes,
} from "network/landlord/notes";
import { message } from "antd";
import SearchBar from "Helpers/SearchBar";
import * as Component from "Components/GeneralComponents";
import trashIconWhite from "assets/trash-icon-white.png";
import settingIcon from "assets/three-dots.png";
import { useSelector } from "react-redux";
import { DeleteModal } from "Components/GeneralComponents";
import { NoteForm } from "./NoteForm";
import { FileAndNoteTabFormData } from "utils/FormDataStructure";
import { validateNoteForm } from "utils/validations";

const NotesTab = ({ id, category }) => {
  const [showAddNotes, setShowAddNote] = useState(false);
  const [selectedTableItem, setSelectedTableItem] = useState([]);
  const [Images, setImages] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [selectedNote, setSelectedNote] = useState(null);
  const [isNotesRerender, setIsNotesRerender] = useState(false);
  const [notes, setNotes] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [key, setKey] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isOpenBulkDeleteModal, setIsOpenBulkDeleteModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [isRerender, setIsRerender] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    property: "",
  });
  const [noteForm, setNoteForm] = useState({
    note_name: "",
    description: "",
  });

  // filter values
  const range = useSelector((state) => {
    return state.FilterValue.value;
  });
  const property = useSelector((state) => {
    return state.FilterValue.property;
  });
  const toDate = useSelector((state) => {
    return state.FilterValue.toDate;
  });
  const fromDate = useSelector((state) => {
    return state.FilterValue.fromDate;
  });

  const handleNoteChange = (fieldName, value) => {
    console.log(fieldName, value);
    setNoteForm({
      ...noteForm,
      [fieldName]: value,
    });
  };

  // get notes data
  useEffect(() => {
    FetchNotes();
  }, [isNotesRerender, range, property, toDate, fromDate]);

  const FetchNotes = () => {
    const removeEmptyValues = (obj) => {
      return Object.fromEntries(
        Object.entries(obj).filter(
          ([_, value]) => value !== "" && value !== undefined && value !== null
        )
      );
    };

    const filters = removeEmptyValues({ range, property, fromDate, toDate });
    setIsLoading(true);
    getNotes({ [category]: id, ...filters }).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        setNotes(
          res.message?.data.map((e, index) => ({
            key: index + 1,
            id: e.id,
            name: [e.file, e.name],
            description: e.description,
            files: e.files,
            uploadedDate: new Date(e.createdAt).toLocaleDateString(),
          }))
        );
      } else {
        message.error(res?.message);
      }
    });
  };

  const addNotes = () => {
    const formData = FileAndNoteTabFormData({
      name: noteForm.note_name,
      description: noteForm.description,
      images: Images,
      [category]: id,
    });

    const newErrors = validateNoteForm(noteForm);

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setIsLoader(true);
      addNote(formData).then((res) => {
        setIsLoader(false);
        if (res.apiCallStatus === "success") {
          message.success("Note Added Successfully");
          setShowAddNote(false);
          setIsNotesRerender(!isNotesRerender);
          setNoteForm({
            note_name: "",
            description: "",
          });
          setImages([]);
        } else {
          message.error(res.error.message);
        }
      });
    }
  };

  console.log(notes);
  // handle search filter
  // handle search
  useEffect(() => {
    if (filters.search) {
      const search = filters.search.toLowerCase();

      setFilteredNotes(
        notes.filter((item) => {
          if (item.name[1].toLowerCase().includes(search)) return true;
          if (item.description.toLowerCase().includes(search)) return true;

          // Convert the date strings to Date objects
          const date1 = new Date(search);
          const date2 = new Date(item.uploadedDate);

          // Check if the dates are valid
          if (!(isNaN(date1) || isNaN(date2)) && date1 >= date2) {
            return true;
          }

          return false;
        })
      );
    } else {
      setFilteredNotes(notes);
    }
  }, [filters.search, notes]);

  const handleResetFilter = () => {
    setFilters({
      fromDate: "",
      toDate: "",
      property: "",
    });
  };

  const handleIconClick = (result) => {
    const filterData = notes.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0]?.key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const notesColumns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, name) => (
        <>
          <span
            onClick={() => setSelectedNote(name)}
            className="property-table-name-text cursor">
            <img
              className="mh_40 mw_40 rounded-5"
              src={`${text[0]}`}
              alt=""
            />{" "}
            {text[1]}
          </span>
        </>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text) => (
        <>
          <span className="tenant_table_properties_sub_text">{text}</span>
        </>
      ),
    },
    {
      title: "Date uploaded",
      dataIndex: "uploadedDate",
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}>
            <img
              src={settingIcon}
              alt=""
            />
            <Component.RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleDeleteRecord: () => {
                  setDeleteId(setting.id);
                  setOpenDeleteModal(true);
                },
                handleEdit: () => {
                  handleEditClick(setting);
                },
                handleView: () => {
                  console.log("View", setting);
                  setSelectedNote(setting);
                },
              }}
              fields={{ view: true, delete: true, edit: true }}
            />
          </div>
        </>
      ),
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTableItem(selectedRows.map((item) => item.id));
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  // handle delete file
  const handleDeleteNote = () => {
    setIsLoader(true);
    deleteNote(deleteId).then((res) => {
      setIsLoader(false);
      if (res.apiCallStatus === "success") {
        message.success(res.message?.message);
        setOpenDeleteModal(false);
        setIsNotesRerender(!isNotesRerender);
        FetchNotes();
      } else {
        message.error(res.message);
      }
    });
  };

  // handle bulk delete file
  const handleBulkDeleteNote = () => {
    console.log("Selected Items: " + selectedTableItem);
    setIsLoader(true);
    deleteMultipleNotes(selectedTableItem).then((res) => {
      setIsLoader(false);
      if (res.apiCallStatus === "success") {
        message.success(res.message?.message);
        setIsOpenBulkDeleteModal(false);
        setIsNotesRerender(!isNotesRerender);
        setSelectedTableItem([]);
      } else {
        message.error(res.message);
      }
    });
  };

  const handleEditClick = (record) => {
    setSelectedNote(record);
    setNoteForm({
      note_name: record.name[1],
      description: record.description,
    });
    setIsEditing(true);
  };

  const handleCancelNoteForm = () => {
    setShowAddNote(false);
    setErrors({});
    setNoteForm({ note_name: "", description: "" });
    setImages([]);
  };

  const handleEditNote = () => {
    const formData = FileAndNoteTabFormData({
      name: noteForm.note_name,
      description: noteForm.description,
      images: Images,
      [category]: id,
    });

    const newErrors = validateNoteForm(noteForm);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setIsLoader(true);
      editNote(selectedNote.id, formData)
        .then((res) => {
          if (res.apiCallStatus === "success") {
            message.success("Note Edited Successfully");
            setIsLoader(false);
            setIsEditing(false);
            setSelectedNote(null);
            setNoteForm({});
            setImages([]);
            FetchNotes();
          } else {
            message.error(res.error.message);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  return (
    <>
      <DeleteModal
        isOpen={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        message={"Are you sure you want to delete this Note?"}
        deleteCall={handleDeleteNote}
        isLoading={isLoader}
      />

      <DeleteModal
        isOpen={isOpenBulkDeleteModal}
        onClose={() => setIsOpenBulkDeleteModal(false)}
        message={"Are you sure you want to delete these Notes?"}
        deleteCall={handleBulkDeleteNote}
        isLoading={isLoader}
      />

      {showAddNotes || isEditing ? (
        <>
          <NoteForm
            form={noteForm}
            errors={errors}
            handleChange={handleNoteChange}
            Images={Images}
            setImages={setImages}
            handleCancel={handleCancelNoteForm}
            handleSubmit={addNotes}
            handleEdit={handleEditNote}
            isEditing={isEditing}
            isLoading={isLoader}
          />
        </>
      ) : selectedNote ? (
        <>
          <div className="notes-info-title">
            <span
              onClick={() => setSelectedNote(null)}
              style={{ cursor: "pointer" }}>
              <svg
                width={25}
                height={25}
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path d="m15 18-6-6 6-6" />
              </svg>
            </span>
            Notes Info
          </div>
          <div className="task-info-lists mt-5">
            <div className="row">
              <div className="col-md-3">
                <p>
                  <span className="task-info-list-span me-3">Title:</span>
                </p>
              </div>
              <div className="col-md-8">
                <p>{selectedNote.name[1]}</p>
              </div>
              <div className="col-md-2"></div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <p>
                  <span className="task-info-list-span me-3">
                    Date Uploaded:
                  </span>
                </p>
              </div>
              <div className="col-md-8">
                <p>{selectedNote.uploadedDate}</p>
              </div>
              <div className="col-md-2"></div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <p>
                  <span className="task-info-list-span me-3">Notes:</span>
                </p>
              </div>
              <div className="col-md-8">
                <p>{selectedNote.description}</p>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <p>
                <span className="task-info-list-span me-3">Files:</span>
              </p>
            </div>
            <div className="col-md-8">
              {selectedNote.files && selectedNote.files.length > 0 ? (
                selectedNote.files.map((file) => (
                  <div
                    key={file.id}
                    className="file-item">
                    <a
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="file-link">
                      {file.name}
                    </a>
                  </div>
                ))
              ) : (
                <p>No files available</p>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <Component.FilterRow
            filter={filters}
            setFilter={setFilters}
            btnTitle={"Add New Note"}
            btnHandler={() => setShowAddNote(true)}
            handleSubmit={() => setIsRerender(!isRerender)}
            handleReset={handleResetFilter}
            filterList={{
              date: true,
              property: true,
            }}
          />

          <div className="row ">
            {selectedTableItem.length >= 1 && (
              <div className="table-delete-icon mt-3">
                <button
                  onClick={() => setIsOpenBulkDeleteModal(true)}
                  className="table-delete-btn next-btn-main">
                  <img src={trashIconWhite} />
                  Delete
                </button>
              </div>
            )}
            <div className="task-table-container  mt-3">
              <Component.CustomTable
                loading={isLoading}
                pagination={false}
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                columns={notesColumns}
                dataSource={filteredNotes}
              />
            </div>
          </div>

          <div className="not-found-container text-center d-none">
            <SearchBar btnTitle="Add New Note" />
            <img
              src={NotFound}
              alt=""
            />
            <p>
              <strong>No Notes found</strong>
            </p>
            <p>
              No notes were found; the folder is empty. <br /> Please try again.
            </p>
            <button className="not-found-add-task-btn primary-orange-text">
              <span className="plus">
                <svg
                  width={21}
                  height={21}
                  fill="#EF6B3E"
                  stroke="#EF6B3E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5v14" />
                  <path d="M5 12h14" />
                </svg>
              </span>
              Add Notes
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default NotesTab;
