import axios from "axios";
import config from "Helpers/config.js";

const updateProfileTenant = async (formdata) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/auth/tenant/updateprofile`,
      formdata,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateProfileLandlord = async (formdata) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/auth/landlord/updateprofile`,
      formdata,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateProfileLandlordUser = async (formdata, id) => {
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/user/${id}`,
      formdata,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const fetchUser = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/auth/landlord/userinfo`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const fetchLandlordUser = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/auth/landlord/user/userinfo`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const fetchUserTenant = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/auth/tenant/userinfo`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateLandlordPassword = async (oldPass, newPass, cnewPass) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/auth/landlord/updatepassword`,
      {
        oldPassword: oldPass,
        newPassword: newPass,
        confirmPassword: cnewPass,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateTenantPassword = async (oldPass, newPass, cnewPass) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/auth/tenant/updatepassword`,
      {
        oldPassword: oldPass,
        newPassword: newPass,
        confirmPassword: cnewPass,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateLandLordUserPassword = async (
  oldPass,
  newPass,
  cnewPass,
  email
) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/auth/landlord/user/updatepassword`,
      {
        oldPassword: oldPass,
        newPassword: newPass,
        confirmPassword: cnewPass,
        email: email,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getCompany = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/landlord/companies`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const createCompany = async (formdata) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/landlord/companies`,
      formdata,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateCompany = async (formdata) => {
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/landlord/companies`,
      formdata,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getLandlordUsers = async () => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/users`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
const getLandlordUserbyid = async (id) => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/user/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const createLandlordUser = async (formdata) => {
  try {
    const response = await axios.post(`${config.baseUrl}/api/user`, formdata, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteLandlordUser = async (id) => {
  try {
    const response = await axios.delete(`${config.baseUrl}/api/user/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getUserRole = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/userRole/getAllUserRole`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateRole = async (data) => {
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/userupdate/updaterole`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const createUserRole = async (data) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/userRole/addUserRole`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getNotificationSettings = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/notifications/setting`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateNotificationSettings = async (data) => {
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/notifications/setting`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export {
  updateProfileTenant,
  updateProfileLandlord,
  updateProfileLandlordUser,
  fetchUser,
  fetchLandlordUser,
  fetchUserTenant,
  updateLandlordPassword,
  updateTenantPassword,
  updateLandLordUserPassword,
  getCompany,
  createCompany,
  updateCompany,
  getLandlordUsers,
  createLandlordUser,
  deleteLandlordUser,
  getUserRole,
  updateRole,
  createUserRole,
  getLandlordUserbyid,
  getNotificationSettings,
  updateNotificationSettings,
};
