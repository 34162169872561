import { useEffect, useState } from "react";
import { message } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UseGetHook from "Hooks/UseGetHook";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import InviteProfessionalModal from "Modals/InviteProfessionalModal/InviteProfessionalModal";
import config from "Helpers/config.js";
import { FormModalLayout } from "Components/GeneralComponents";
import { FilterValue } from "Store/Slices/FilterSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as Component from "Components/GeneralComponents";
import * as Icon from "assets/icons";
import helpIcon from "assets/help-circle.png";
import { Tooltip } from "antd";
import ListServiceProfessionalTab from "./ListServiceProfessionalTab";
import FindLocalProfessionalTab from "./FindLocalProfessionalTab";

const AllServiceProfessional = () => {
  //States start
  const [selectedTableItem, setSelectedTableItem] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [key, setKey] = useState([]);
  const { id, tab } = UseUrlParamsHook();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const { fetchServiceProfessionals } = UseGetHook("companies", id); //include in array: professional
  const { fetchListProfessional, listProfessional } = UseGetHook(
    "invited-companies",
    id
  );

  useEffect(() => {
    setActiveTab(tab || "1");
  }, [tab]);

  const onChange = (key) => {
    setActiveTab(key);
    searchParams.set("tab", key);
    navigate({ search: searchParams.toString() });
  };

  const professional = [
    {
      id: 1,
      companyLogo:
        "https://www.shutterstock.com/image-vector/circle-line-simple-design-logo-600nw-2174926871.jpg", // Placeholder image URL
      companyName: "Elite Plumbing Co.",
      services: { name: "Plumbing Services" },
      state: { name: "California" },
      charges: "$100 - $500",
      description:
        "Offering high-quality plumbing services for residential and commercial needs.",
      new: true,
    },
    {
      id: 2,
      companyLogo:
        "https://www.shutterstock.com/image-vector/circle-line-simple-design-logo-600nw-2174926871.jpg",
      companyName: "Spark Electricians",
      services: { name: "Electrical Services" },
      state: { name: "New York" },
      charges: "$150 - $700",
      description:
        "Licensed and insured electricians providing safe and reliable services.",
      new: false,
    },
    {
      id: 3,
      companyLogo:
        "https://www.shutterstock.com/image-vector/circle-line-simple-design-logo-600nw-2174926871.jpg",
      companyName: "Green Thumb Landscaping",
      services: { name: "Landscaping" },
      state: { name: "Florida" },
      charges: "$50 - $300",
      description:
        "Professional landscaping services to beautify your outdoor space.",
      new: true,
    },
    {
      id: 4,
      companyLogo:
        "https://www.shutterstock.com/image-vector/circle-line-simple-design-logo-600nw-2174926871.jpg",
      companyName: "Top Cleaners",
      services: { name: "Cleaning Services" },
      state: { name: "Texas" },
      charges: "$80 - $400",
      description:
        "Reliable and thorough cleaning services for homes and offices.",
      new: false,
    },
  ];
  useEffect(() => {
    fetchServiceProfessionals();
    fetchListProfessional();
    console.log(listProfessional, "LISTTT");
  }, []);
  const navigate = useNavigate();
  // Data Table Functions
  const handleIconClick = (result) => {
    // Toggle the dropdownOpen state
    const filterData = data.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };

  const resetFilter = () => {
    dispatch(FilterValue({ reset: "reset" }));
  };

  const range = useSelector((state) => {
    return state.FilterValue.value;
  });
  const property = useSelector((state) => {
    return state.FilterValue.property;
  });
  const fromDate = useSelector((state) => {
    return state.FilterValue.fromDate;
  });
  const toDate = useSelector((state) => {
    return state.FilterValue.toDate;
  });

  const deleteData = (id) => {
    fetch(`${config["baseUrl"]}/api/serviceProviders/deleteInvite/${id}`, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.apiCallStatus === "success") {
          message.success("Invitation deleted successfully");
          navigate(`/all-service-professional`);
          if (setUpdate) setUpdate(true);
        }
        if (res.apiCallStatus === "error") {
          message.error("error in deleting");
        }
      });
  };

  const columns = [
    {
      title: "Company Name",
      dataIndex: "name",
      render: (text, setting) => (
        <>
          <Link
            to={`/all-service-professional-details?id=${setting.id}`}
            className="d-flex align-items-center gap-2"
          >
            <div
              className="d-flex align-items-center justify-content-center flex-shrink-0"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                overflow: "hidden",
                border: `0.5px solid #c9c9c9`,
                position: "relative",
              }}
            >
              {setting.file ? (
                <img
                  src={setting.file}
                  alt="task"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <Component.FirstCharacterIcon name={setting.name} />
              )}
            </div>
            <div className="">{text}</div>
          </Link>
        </>
      ),
    },
    {
      title: (
        <>
          Services
          <Tooltip title={"Services will be provided."}>
            <img src={helpIcon} alt="" className="cursor ps-2" />
          </Tooltip>
        </>
      ),
      dataIndex: "services",
      render: (text) => (
        <>
          <span className="tenant_table_properties_sub_text">{text}</span>
        </>
      ),
    },
    {
      title: (
        <>
          Location
          <Tooltip title={"Service provided location."}>
            <img src={helpIcon} alt="" className="cursor ps-2" />
          </Tooltip>
        </>
      ),
      dataIndex: "location",
      render: (text) => (
        <>
          <span className="tenant_table_phone_text phone">{text}</span>
        </>
      ),
    },
    {
      title: "Charges",
      dataIndex: "charges",
      render: (text) => (
        <>
          <span className="tenant_table_email_text">{text}</span>
        </>
      ),
    },
    {
      title: <>Status</>,
      dataIndex: "status",
      render: (text, status) => (
        <>
          <div className="status-active">
            <span>{text}</span>
          </div>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <div
          className="position-relative cursor text-center"
          onClick={() => handleIconClick(setting.key)}
        >
          <Icon.VerticalElipsisIcon width={23} height={23} />
          <Component.RecordMenuPopup
            isOpen={setting.key === key}
            onClose={() => setKey(null)}
            handler={{
              handleView: () =>
                navigate(`/all-service-professional-details?id=${setting.id}`),
              handleSendInvite: { onOpenModal },
              handleDelete: () => deleteData(setting.id),
            }}
            fields={{
              view: true,
              sendInvite: setting.status === "Declined" ? true : false,
              delete: setting.status === "Declined" ? true : false,
            }}
          />
        </div>
      ),
    },
  ];

  // const data = listProfessional?.map((e, index) => ({
  //   key: index + 1,
  //   id: e._id,
  //   name: e?.company?.companyName,
  //   file: e?.company?.companyLogo,
  //   description: e?.queryDescription,
  //   status: e?.status,
  //   location: e?.company?.state?.name,
  //   services: e?.company?.services?.name ? e?.company?.services?.name : '-',
  //   charges: e?.charges ? e?.charges : "-",
  //   uploadedDate: new Date(e?.modified_at).toLocaleDateString(),
  // }));
  const data = [
    {
      key: 1,
      id: 1,
      name: "ABC Plumbing Services",
      file: "https://www.shutterstock.com/image-vector/circle-line-simple-design-logo-600nw-2174926871.jpg", // Placeholder image URL
      services: "Plumbing, Repair",
      location: "New York, NY",
      charges: "$100 - $500",
      status: "Active",
      setting: { key: 1, status: "Active" },
    },
    {
      key: 2,
      id: 2,
      name: "XYZ Electricians",
      file: "https://www.shutterstock.com/image-vector/circle-line-simple-design-logo-600nw-2174926871.jpg",
      services: "Electrical, Installation",
      location: "Los Angeles, CA",
      charges: "$150 - $700",
      status: "Pending",
      setting: { key: 2, status: "Pending" },
    },
    {
      key: 3,
      id: 3,
      name: "Home Solutions",
      file: "https://www.shutterstock.com/image-vector/circle-line-simple-design-logo-600nw-2174926871.jpg",
      services: "HVAC, Repair",
      location: "Chicago, IL",
      charges: "$200 - $1000",
      status: "Declined",
      setting: { key: 3, status: "Declined" },
    },
    {
      key: 4,
      id: 4,
      name: "Green Landscaping",
      file: "https://www.shutterstock.com/image-vector/circle-line-simple-design-logo-600nw-2174926871.jpg",
      services: "Landscaping, Maintenance",
      location: "Miami, FL",
      charges: "$50 - $300",
      status: "Active",
      setting: { key: 4, status: "Active" },
    },
    {
      key: 5,
      id: 5,
      name: "Top Notch Cleaning",
      file: "https://www.shutterstock.com/image-vector/circle-line-simple-design-logo-600nw-2174926871.jpg",
      services: "Cleaning, Sanitization",
      location: "Austin, TX",
      charges: "$80 - $400",
      status: "Pending",
      setting: { key: 5, status: "Pending" },
    },
  ];
  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedTableItem(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const tabs = [
    {
      key: "1",
      label: "List of Service Professional",
      children: (
        <ListServiceProfessionalTab
          data={data}
          columns={columns}
          rowSelection={rowSelection}
          resetFilter={resetFilter}
        />
      ),
    },
    {
      key: "2",
      label: "Find Local Professional",
      children: <FindLocalProfessionalTab professional={professional} />,
    },
  ];

  return (
    <>
      {openModal === true && (
        <FormModalLayout
          title={"Select Reason for Invite"}
          isOpen={openModal}
          onClose={onCloseModal}
        >
          <InviteProfessionalModal />
        </FormModalLayout>
      )}

      <div className="container-fluid bg-white p-3">
        {activeTab && (
          <Component.CustomTabs
            items={tabs}
            defaultActiveKey={activeTab}
            onChange={onChange}
          />
        )}
      </div>

      {/* <div className="container-fluid bg-white p-3">
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                inkBarColor: "#EF6B3E",
                itemColor: "#667085",
                itemSelectedColor: "#EF6B3E",
                itemHoverColor: "#EF6B3E",
                titleFontSize: 15,
                horizontalItemGutter: window.innerWidth <= 768 ? 10 : 60,
                fontFamily: "Montserrat",
              },
            },
          }}
        >
          <Tabs
            centered={window.innerWidth <= 450 ? false : true}
            defaultActiveKey="1"
            style={{ fontWeight: 500 }}
          >
            <TabPane tab="List of Service Professional" key="1">
              <div key={data} className="container-fluid">
                <SearchBar serviceProfessionalFilter={true} />
                <div className="mt-2" style={{ width: "fit-content" }}>
                  {" "}
                  {range || property || fromDate || toDate ? (
                    <ButtonVariant1 handler={resetFilter} children={"Reset"} />
                  ) : (
                    ""
                  )}
                </div>
                <div className="row ">
                  {selectedTableItem.length >= 1 && (
                    <div className="table-delete-icon mt-3">
                      <button className="table-delete-btn next-btn-main">
                        <img src={trashIconWhite} />
                        Delete
                      </button>
                    </div>
                  )}
                  <Component.CustomTable
                    pagination={true}
                    columns={columns}
                    dataSource={data}
                    rowSelection={{
                      type: selectionType,
                      ...rowSelection,
                    }}
                  />
                </div>
              </div>
            </TabPane>
            <TabPane tab="Find Local Professional" key="2">
              <LocalServiceProfessionalSearch />
              {professional.length > 0 &&
                professional.map((data) => {
                  return (
                    <>
                      <div className="local-professional-container mt-4">
                        <div className="local-professional-box d-flex align-items-start gap-3 ">
                          <>
                            <Link
                              to={`/all-service-professional-details?id=${data.id}`}
                              className="d-flex align-items-center gap-2"
                            >
                              <div
                                className="d-flex align-items-center justify-content-center flex-shrink-0"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "50%",
                                  overflow: "hidden",
                                  border: `0.5px solid #c9c9c9`,
                                  position: "relative",
                                }}
                              >
                                {data.companyLogo ? (
                                  <img
                                    src={data.companyLogo}
                                    alt="task"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <Component.FirstCharacterIcon
                                    name={data.companyName}
                                  />
                                )}
                              </div>
                            </Link>
                          </>
                          <div className="local-professional-content">
                            <div className="local-top-heading">
                              {data?.companyName}
                            </div>

                            <Link
                              to={`/local-professional-details?id=${data.id}`}
                            >
                              {data.new && (
                                <div className="local-main-heading d-flex align-items-center gap-2">
                                  {data?.services?.name
                                    ? data?.services?.name
                                    : "-"}{" "}
                                  <span className="new-post">New post</span>
                                </div>
                              )}
                            </Link>
                            <div className="local-info d-flex gap-3 align-items-center">
                              <div className="d-flex align-items-center gap-2">
                                <img src={MapLine} alt="" /> {data?.state?.name}
                              </div>
                              <div className="d-flex align-items-center gap-2">
                                <img src={dollar} alt="" />{" "}
                                {data?.charges ? data?.charges : "-"}
                              </div>
                            </div>
                            <div className="local-desc mt-1">
                              {data?.description}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </TabPane>
          </Tabs>
        </ConfigProvider>
      </div> */}
    </>
  );
};

export default AllServiceProfessional;
