import React from "react";
import DTLogoWhite from "assets/svg/DTLogoWhite.svg";
import DTLogoBlack from "assets/svg/DTLogoBlack.svg";

const DTLogo = ({ mode, width = "300px" }) => {
  return (
    <div>
      {mode === "light" ? (
        <img src={DTLogoWhite} width={width} alt="DT Logo" />
      ) : (
        <img src={DTLogoBlack} width={width} alt="DT Logo" />
      )}
    </div>
  );
};

export default DTLogo;
