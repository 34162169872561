import { Tooltip } from "@mui/material";
import React from "react";
import "style/OptionCard.css";

const ProfileAndButtonCard = ({ imgSrc, name, buttonText, buttonHandler }) => {
  return (
    <div className="row-card position-relative">
      <div className="d-flex align align-items-center">
        <div className="profile">
          <img src={imgSrc} alt="Profile" className="profile-img" />
        </div>
        <span className="name">{name}</span>
      </div>
      <button
        className={`${
          buttonText === "Remove"
            ? "remove-btn fw-medium"
            : "not-found-add-task-btn primary-orange-text fw-medium"
        }`}
        onClick={buttonHandler}
      >
        {buttonText}
      </button>
    </div>
  );
};

export default ProfileAndButtonCard;
