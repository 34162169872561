import ArchiveChats from "Components/Chat/ArchiveChats/ArchiveChats";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const ChatsArchive = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail="Archived"
        back="archive-user"
      />
      <ArchiveChats />
    </div>
  );
};

export default ChatsArchive;
