import React, { useState, useEffect } from "react";
import { ConfigProvider, Tabs } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import * as InnerComponent from "Components/TenantDetailsView";
import {
  DeleteModal,
  RecordMenuPopup,
  FormModalLayout,
  CustomTabs,
} from "Components/GeneralComponents";
import * as Icon from "assets/icons";
import { message } from "antd";
import { EditTaskForm } from "Components/AllTasks";
import { deleteTask } from "network/landlord/task";
import { OverviewTab } from "Components/TenantTaskDetails";
import { getTaskById, getRecurringTaskById } from "network/landlord/task";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";

const TaskDetails = () => {
  const { id, tab, type } = UseUrlParamsHook();
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [openEditModalTask, setOpenEditModalTask] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [task, setTask] = useState("");
  const [activeTab, setActiveTab] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  
  useEffect(() => {
    setIsLoading(true);
    console.log("task details", id, type);
   if(type === "recurring") {
    console.log("recurring task");
    getRecurringTaskById(id).then((res) => {
      if (res.apiCallStatus === "success") {
        setTask(res.message.data);
        setIsLoading(false);
      }
    });
   }else{
    getTaskById(id).then((res) => {
      if (res.apiCallStatus === "success") {
        setTask(res.message.data);
        setIsLoading(false);
      }
    });
   }
  }, [id]);

  const items = [
    {key: "1", label: "Overview", children: <OverviewTab task={task} isLoading={isLoading} />},
    {key: "2", label: "Notes", children: <InnerComponent.NotesTab id={id} category="task" />},
    {key: "3", label: "Files", children: <InnerComponent.FilesTab id={id} category="task" />},
  ];

  useEffect(() => {
    setActiveTab(tab || "1");
  }, [tab]);

  const onChange = (key) => {
    setActiveTab(key);
    searchParams.set("tab", key);
    navigate({ search: searchParams.toString() });
  };

  const handleIconClickCustom = () => {
    setDropdownOpen(!isDropdownOpen);
  };



  const deleteTaskCall = (id) => {
    setIsLoading(true);
    deleteTask(id).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        setOpenDeleteModal(false);
        message.success("Task Deleted Successfully");
        navigate("/all-task");
      }
    });
  };

  return (
    <>
      <DeleteModal
        isOpen={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        message={"Are you sure you want to delete this task?"}
        deleteCall={() => deleteTaskCall(id)}
        isLoading={isLoading}
      />

      <FormModalLayout
        title={"Edit Task"}
        isOpen={openEditModalTask}
        onClose={() => setOpenEditModalTask(false)}
      >
        <EditTaskForm id={id} onClose={() => setOpenEditModalTask(false)} />
      </FormModalLayout>
      <div className="container-fluid bg-white p-3 ">
        <div className="row">
          <div className="col-md-12 text-end">
            <span className="position-relative">
              <div className="cursor" onClick={handleIconClickCustom}>
                <Icon.VerticalElipsisIcon width={23} height={23} />
                {
                  <RecordMenuPopup
                    isOpen={isDropdownOpen}
                    onClose={() => {}}
                    handler={{
                      handleEdit: () => {
                        setOpenEditModalTask(true);
                      },
                      handleDeleteRecord: () => {
                        setOpenDeleteModal(true);
                      },
                    }}
                    fields={{
                      edit: task.updates?.length > 1 ? false : true,
                      delete: true,
                    }}
                  />
                  // )
                }
              </div>
            </span>
          </div>
        </div>
        {activeTab && <CustomTabs
          items={items}
          defaultActiveKey={activeTab}
          onChange={onChange}
        />}
      </div>
    </>
  );
};

export default TaskDetails;
