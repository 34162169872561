import React from "react";
import * as Component from "Components/GeneralComponents";

const SetProperty = ({property,propertydata,errors, addTask, loader , setProperty}) => {
    const handlePropertyChange = (e) => {
        setProperty(e);
      };
  return (
    <>
      <div className="modal-content-main-section p-41">
      <div className="row mt-3">
        <Component.SelectField
          mode="multiple"
          name={"properties"}
          required={true}
          label={"Properties"}
          placeholder={"Select Properties"}
          value={property?.map((e) => e)}
          options={propertydata?.map((e) => {
            return { value: e.id, label: e.heading2 };
          })}
          errorMessage={errors?.property}
          handler={(e) => handlePropertyChange(e)}
        />
        </div>
        <div className="modal-content-footer-section-scroll p-custom">
        <div className="row mt-3">
          <div className="col-md-12 d-flex justify-content-end responsive-direction-column">
            <div className="task-modal-footer-btn">
              <Component.SaveModalButton loader={loader} addTask={addTask} />
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default SetProperty;
