import { useEffect, useMemo, useState } from "react";
import { Radio, Select, Switch } from "antd";
import { DatePicker, Space } from "antd";
import { usePDF } from "react-to-pdf";
import { Upload, ConfigProvider } from "antd";
import InvoiceModal from "Modals/InvoiceModal/InvoiceModal";
import chevronIcon from "assets/chevron-down.png";
import calendarIcon from "assets/calendar.png";
import FileUploader from "Components/FileUploader/FileUploader";
import UseGetHook from "Hooks/UseGetHook";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import UseUpdateHook from "Hooks/UseUpdateHook";
import { useNavigate } from "react-router-dom";
const { Dragger } = Upload;
const EditPayable = () => {
  const dateFormat = "MM/DD/YYYY";
  // States start
  const { toPDF, targetRef } = usePDF({ filename: "invoice.pdf" });
  const [openModal, setOpenModal] = useState(false);
  const [value, setValue] = useState();
  const [phone, setphone] = useState("");
  const [Images, setImages] = useState([]);
  const [DeletedImages, setDeletedImages] = useState([]);
  const [formattedNumber, setFormattedNumber] = useState("");
  const [formattedNumberShipping, setFormattedNumberShipping] = useState("");
  const [property, setProperty] = useState("");
  const { id } = UseUrlParamsHook();
  const { FetchInvoiceById, Invoice } = UseGetHook("getInvoiceById", id);
  const { invoiceCat, InvoiceCategory } = UseGetHook("getInvoiceCategories");
  const { FetchTenant, TenantData } = UseGetHook("tenants");
  const { fetchProperty, PropertyData } = UseGetHook("property");
  const { UnitData, fetchUnit } = UseGetHook("unit", property);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [frequency, setFrequency] = useState("");
  const [nod, setNod] = useState("");
  const [Repeat, setRepeat] = useState("");
  const navigate = useNavigate()
  const [invoiceLineItems, setinvoiceLineItems] = useState([
    {
      description: "",
      product_details: "",
      category: "",
      amount: "",
    },
  ]);
  const [invoice_date, setInvoiceDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [tenant, setTenant] = useState("");

  const [unit, setUnit] = useState("");
  const [Billing_details, setbilling_details] = useState([
    {
      full_name: "",
      address: "",
      phone_no: phone,
    },
  ]);
  const [Shipping_details, setshipping_details] = useState([
    {
      full_name: "",
      address: "",
      phone_no: phone,
    },
  ]);
  const handleBillingAddress = (fieldName, value) => {
    setbilling_details((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };
  const handleShippingAddress = (fieldName, value) => {
    setshipping_details((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };
  const handleAddInputChange = (fieldName, e, index) => {
    console.log(e);
    const list = [...invoiceLineItems];
    list[index][fieldName] = e;
    setinvoiceLineItems(list);
  };
  const imageArray = useMemo(() => {
    return Invoice?.image || [];
  }, [Invoice]);
  // States end
  useEffect(() => {
    if (imageArray.length > 0 && typeof imageArray === "string") {
      setImages((prev) => [...prev, imageArray]);
    } else {
      setImages((prev) => [...prev, ...imageArray]);
    }
    console.log(imageArray, "imageArray");
    console.log(Images, "Images");
  }, [imageArray]);

  useEffect(() => {
    FetchInvoiceById();
    InvoiceCategory();
    FetchTenant();
    fetchProperty();
    if (property !== "") fetchUnit();
    if (Invoice?.is_recurring) {
      setValue(2);
    } else {
      setValue(1);
    }
  }, [property]);
  let dropdownIcon;
  dropdownIcon = (
    <>
      <img src={chevronIcon} alt="" />
    </>
  );
  let calendar;
  calendar = (
    <>
      <img src={calendarIcon} alt="" />
    </>
  );
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  const onChangeRadio = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  // Modal Functions
  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    navigate("/payment")
    setOpenModal(false);
  };
  const shipping_details = {
    full_name: Shipping_details.full_name,
    address: Shipping_details.address,
    phone_no: phone,
  };
  const billing_details = {
    full_name: Billing_details.full_name,
    address: Billing_details.address,
    phone_no: phone,
  };
  const formdata = new FormData();
  formdata.append("invoice_no", "#TBS24301901");
  if (invoice_date) formdata.append("invoice_date", invoice_date);
  if (dueDate) formdata.append("dueDate", dueDate);
  if (tenant) formdata.append("tenant_id", tenant);
  if (billing_details.full_name)
    formdata.append("billing_details[full_name]", billing_details.full_name);
  if (billing_details.address)
    formdata.append("billing_details[address]", billing_details.address);
  if (billing_details.phone_no)
    formdata.append("billing_details[phone_no]", billing_details.phone_no);
  if (shipping_details.full_name)
    formdata.append("shipping_details[full_name]", shipping_details.full_name);
  if (shipping_details.address)
    formdata.append("shipping_details[address]", shipping_details.address);
  if (shipping_details.phone_no)
    formdata.append("shipping_details[phone_no]", shipping_details.phone_no);
  if (value) formdata.append("is_recurring", value === 2 ? true : false);
  if (value === 2) formdata.append("start_date", startDate);
  if (value === 2) formdata.append("end_date", endDate);
  if (value === 2) formdata.append("frequency", frequency);
  if (value === 2) formdata.append("number_of_days_until_due", nod);
  if (property) formdata.append("property_id", property);
  if (unit) formdata.append("unit_id", unit);
  if (Images.length > 0) {
    Images.forEach((img) => {
      formdata.append("image", img);
    });
  }
  if (invoiceLineItems) {
    const allFieldsFilled = invoiceLineItems.every(
      (items) =>
        items.description &&
        items.product_details &&
        items.category &&
        items.amount
    );

    if (allFieldsFilled) {
      invoiceLineItems.forEach((items, index) => {
        formdata.append(
          `invoiceLineItems[${index}][description]`,
          items.description
        );
        formdata.append(
          `invoiceLineItems[${index}][product_details]`,
          items.product_details
        );
        formdata.append(`invoiceLineItems[${index}][category]`, items.category);
        formdata.append(`invoiceLineItems[${index}][amount]`, items.amount);
      });
    }
  }
  if (DeletedImages.length > 0) {
    DeletedImages?.forEach((img, index) => {
      formdata.append(`__delete_files[${[index]}]`, img);
    });
  }
  const EditInvoice = () => {
    UseUpdateHook("invoice/updateInvoice", id, formdata, onOpenModal);
  };
  // Formated Phone number
  const handleInputChange = (event) => {
    const input = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const formatted = formatPhoneNumber(input);
    setFormattedNumber(formatted); // Update state with formatted number
    const unformatted = input.slice(0, 10);
    setphone(unformatted); // Update state with unformatted number
  };

  const handleInputChangeShipping = (event) => {
    const input = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const formatted = formatPhoneNumber(input);
    setFormattedNumberShipping(formatted); // Update state with formatted number
    const unformatted = input.slice(0, 10);
    setphone(unformatted); // Update state with unformatted number
  };

  const formatPhoneNumber = (input) => {
    let formattedNumber = "";

    if (input.length > 0) {
      formattedNumber = `(${input.slice(0, 3)}`;

      if (input.length > 3) {
        formattedNumber += `) ${input.slice(3, 6)}`;
      }

      if (input.length > 6) {
        formattedNumber += `-${input.slice(6, 10)}`;
      }
    }

    return formattedNumber;
  };
  return (
    <>
      {openModal === true && <InvoiceModal onClose={onCloseModal} />}

      <div className="container-fluid bg-white p-3">
        <div className="row">
          <div className="col-md-4 mt-3">
            <span className="property-details-input-title">Invoice No</span>
            <input
              type="text"
              className="form-control"
              value="#TBS24301901"
              disabled
            />
          </div>
          <div className="col-md-4 mt-3">
            <span className="property-details-input-title">Invoice Date</span>
            <ConfigProvider
              theme={{
                components: {
                  DatePicker: {
                    zIndexPopupBase: 99999,
                    colorPrimaryHover: "#EF6B3E",
                    borderRadius: 4,
                    fontFamily: "montserrat",
                  },
                },
              }}
            >
              <Space
                style={{
                  width: "100%",
                }}
                direction="vertical"
              >
                <DatePicker
                  placeholder="Select Invoice Date"
                  style={{
                    width: "100%",
                    height: 45,
                  }}
                  suffixIcon={calendar}
                  format={dateFormat}
                  onChange={(e) => {
                    const formattedDate = new Date(e).toLocaleDateString();
                    setInvoiceDate(formattedDate);
                  }}
                  className="lease-date-picker"
                />
              </Space>
            </ConfigProvider>
          </div>
          <div className="col-md-4 mt-3">
            <span className="property-details-input-title">Due Date</span>
            <ConfigProvider
              theme={{
                components: {
                  DatePicker: {
                    zIndexPopupBase: 99999,
                    colorPrimaryHover: "#EF6B3E",
                    borderRadius: 4,
                    fontFamily: "montserrat",
                  },
                },
              }}
            >
              <Space
                style={{
                  width: "100%",
                }}
                direction="vertical"
              >
                <DatePicker
                  placeholder="Select Due Date"
                  style={{
                    width: "100%",
                    height: 45,
                  }}
                  suffixIcon={calendar}
                  format={dateFormat}
                  onChange={(e) => {
                    const formattedDate = new Date(e).toLocaleDateString();
                    setDueDate(formattedDate);
                  }}
                  className="lease-date-picker"
                />
              </Space>
            </ConfigProvider>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <span className="property-details-input-title">Select Tenant</span>
            <ConfigProvider
              theme={{
                components: {
                  Select: {
                    zIndexPopupBase: 99999,
                    colorPrimaryHover: "#EF6B3E",
                    optionSelectedBg: "rgba(239, 107, 62, 0.16)",
                    borderRadius: 4,
                    colorTextPlaceholder: "#667085",
                    fontFamily: "montserrat",
                  },
                },
              }}
            >
              {
                Invoice !== "" ?
                  <>
                    <Select
                      defaultValue={Invoice?.tenant?.firstName}
                      onChange={(e) => setTenant(e)}
                      suffixIcon={dropdownIcon}
                      placeholder="Select Tenants"
                      style={{
                        width: "100%",
                        height: 45,
                      }}
                      options={TenantData.map((e) => {
                        return { value: e.id, label: e.firstName };
                      })}
                    />
                  </>
                  :

                  <Select

                    onChange={(e) => setTenant(e)}
                    suffixIcon={dropdownIcon}
                    placeholder="Select Tenant"
                    style={{
                      width: "100%",
                      height: 45,
                    }}
                    options={TenantData.map((e) => {
                      return { value: e.id, label: e.firstName };
                    })}
                  />
              }
              {console.log(Invoice.length, "InvoiceInvoice")}
            </ConfigProvider>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <span className="property-details-input-title">
              Select Property <span className="sign-up-imp-star">*</span>
            </span>
            <ConfigProvider
              theme={{
                components: {
                  Select: {
                    zIndexPopupBase: 99999,
                    colorPrimaryHover: "#EF6B3E",
                    optionSelectedBg: "rgba(239, 107, 62, 0.16)",
                    borderRadius: 4,
                    colorTextPlaceholder: "#667085",
                    fontFamily: "montserrat",
                  },
                },
              }}
            >
              {
                Invoice.length > 0 ?
                  <>
                    <Select
                      defaultValue={{
                        label: Invoice[0].tenant?.firstName,
                        value: Invoice[0].tenant?.firstName,
                      }}
                      suffixIcon={dropdownIcon}
                      placeholder="Select Property"
                      style={{
                        width: "100%",
                        height: 45,
                      }}
                      onChange={(e) => setProperty(e)}
                      options={PropertyData.map((e) => {
                        return { value: e.id, label: e.title };
                      })}
                    />
                  </> : <Select
                    suffixIcon={dropdownIcon}
                    placeholder="Select Property"
                    style={{
                      width: "100%",
                      height: 45,
                    }}
                    onChange={(e) => setProperty(e)}
                    options={PropertyData.map((e) => {
                      return { value: e.id, label: e.title };
                    })}
                  />
              }
              {
                console.log(Invoice?.tenant?.firstName, 'hysfghs')
              }
            </ConfigProvider>
          </div>
          <div className="col-md-6">
            <span className="property-details-input-title">
              Select Unit <span className="sign-up-imp-star">*</span>
            </span>
            <ConfigProvider
              theme={{
                components: {
                  Select: {
                    zIndexPopupBase: 99999,
                    colorPrimaryHover: "#EF6B3E",
                    optionSelectedBg: "rgba(239, 107, 62, 0.16)",
                    borderRadius: 4,
                    colorTextPlaceholder: "#667085",
                    fontFamily: "montserrat",
                  },
                },
              }}
            >
              <Select
                suffixIcon={dropdownIcon}
                placeholder="Select Unit"
                style={{
                  width: "100%",
                  height: 45,
                }}
                onChange={(e) => setUnit(e)}
                options={UnitData.map((e) => {
                  return { value: e.id, label: e.name };
                })}
              />
            </ConfigProvider>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <span className="property-details-input-title">
              Select Bank Account
            </span>
            <ConfigProvider
              theme={{
                components: {
                  Select: {
                    zIndexPopupBase: 99999,
                    colorPrimaryHover: "#EF6B3E",
                    optionSelectedBg: "rgba(239, 107, 62, 0.16)",
                    borderRadius: 4,
                    colorTextPlaceholder: "#667085",
                    fontFamily: "montserrat",
                  },
                },
              }}
            >
              {
                Invoice !== "" ?
                  <>
                    <Select
                      // defaultValue={Invoice?.invoiceLineItems[0]?.category?.category}
                      suffixIcon={dropdownIcon}
                      placeholder="Operating Account"
                      style={{
                        width: "100%",
                        height: 45,
                      }}
                      options={[
                        {
                          value: "Active",
                          label: "Active",
                        },
                        {
                          value: "Not Active",
                          label: "Not Active",
                        },
                      ]}
                    />
                  </> :
                  <Select
                    suffixIcon={dropdownIcon}
                    placeholder="Operating Account"
                    style={{
                      width: "100%",
                      height: 45,
                    }}
                    options={[
                      {
                        value: "Active",
                        label: "Active",
                      },
                      {
                        value: "Not Active",
                        label: "Not Active",
                      },
                    ]}
                  />
              }
            </ConfigProvider>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mt-3">
            <p className="normal-grey-text my-3">BILLING DETAILS</p>
          </div>
          <div className="col-md-6 mt-3">
            <p className="normal-grey-text my-3">SHIPPING DETAILS</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mt-3">
            <span className="property-details-input-title">Full Name</span>
            <input
              defaultValue={Invoice?.billing_details?.full_name}
              onChange={(e) =>
                handleBillingAddress("full_name", e.target.value)
              }
              type="text"
              className="form-control"
              placeholder="Full Name"
            />
          </div>
          <div className="col-md-6 mt-3">
            <span className="property-details-input-title">Full Name</span>
            <input
              onChange={(e) =>
                handleShippingAddress("full_name", e.target.value)
              }
              defaultValue={Invoice?.shipping_details?.full_name}
              type="text"
              className="form-control"
              placeholder="Full Name"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mt-3">
            <span className="property-details-input-title">Address</span>
            <input
              onChange={(e) => handleBillingAddress("address", e.target.value)}
              defaultValue={Invoice?.billing_details?.address}
              type="text"
              className="form-control"
              placeholder="Address"
            />
          </div>
          <div className="col-md-6 mt-3">
            <span className="property-details-input-title">Address</span>
            <input
              onChange={(e) => handleShippingAddress("address", e.target.value)}
              defaultValue={Invoice?.shipping_details?.address}
              type="text"
              className="form-control"
              placeholder="Address"
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6 mt-3">
            <span className="property-details-input-title">Phone No</span>
            <input
              defaultValue={Invoice?.billing_details?.phone_no}
              onChange={handleInputChange}
              type="text"
              className="form-control"
              placeholder="Phone number"
            />
          </div>
          <div className="col-md-6 mt-3">
            <span className="property-details-input-title">Phone No</span>
            <input
              defaultValue={Invoice?.shipping_details?.phone_no}
              onChange={handleInputChangeShipping}
              type="text"
              className="form-control"
              placeholder="Phone number"
            />
          </div>
        </div>
        <div className="row mt-3">
          {Invoice?.invoiceLineItems?.map((data, index) => {
            return (
              <>
                <div className="col-md-12">
                  <div className="table-data-container">
                    <div className="row mt-3">
                      <div className="col-md-3 mt-3">
                        <p className="mb-3 text-center p-3  payable-input-headings">
                          #
                        </p>

                        <p className="text-center">{index + 1}</p>
                      </div>
                      <div className="col-md-3 mt-3">
                        <div className="product-field">
                          <p className="mb-3 p-3 payable-input-headings">
                            DESCRIPTION
                          </p>

                          <span className="property-details-input-title">
                            Product Name
                          </span>
                          <input
                            onChange={(e) =>
                              handleAddInputChange(
                                "description",
                                e.target.value,
                                index
                              )
                            }
                            defaultValue={data.description}
                            type="text"
                            className="form-control mb-3"
                            placeholder="Product Name"
                          />

                          <span className="property-details-input-title">
                            Product Details
                          </span>
                          <textarea
                            onChange={(e) =>
                              handleAddInputChange(
                                "product_details",
                                e.target.value,
                                index
                              )
                            }
                            defaultValue={data.product_details}
                            name=""
                            id=""
                            cols="10"
                            rows="5"
                            className="form-control"
                            placeholder="Product Details"
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-3 mt-3">
                        <div className="category-field">
                          <p className="mb-3 p-3 payable-input-headings">
                            CATEGORY
                          </p>

                          <span className="property-details-input-title">
                            Select Category
                          </span>
                          <ConfigProvider
                            theme={{
                              components: {
                                Select: {
                                  zIndexPopupBase: 99999,
                                  colorPrimaryHover: "#EF6B3E",
                                  optionSelectedBg: "rgba(239, 107, 62, 0.16)",
                                  borderRadius: 4,
                                  colorTextPlaceholder: "#667085",
                                  fontFamily: "montserrat",
                                },
                              },
                            }}
                          >
                            <Select
                              defaultValue={data.category.category}
                              onChange={(e) =>
                                handleAddInputChange("category", e, index)
                              }
                              suffixIcon={dropdownIcon}
                              placeholder="Select Category"
                              style={{
                                width: "100%",
                                height: 45,
                              }}
                              options={invoiceCat.map((e) => {
                                return { value: e._id, label: e.category };
                              })}
                            />
                          </ConfigProvider>
                        </div>
                      </div>
                      <div className="col-md-3 mt-3">
                        <div className="amount-field">
                          <p className="mb-3 p-3 payable-input-headings">
                            AMOUNT
                          </p>

                          <span className="property-details-input-title">
                            Amount
                          </span>
                          <div className="amount-trash-box gap-3">
                            <input
                              onChange={(e) =>
                                handleAddInputChange(
                                  "amount",
                                  e.target.value,
                                  index
                                )
                              }
                              defaultValue={data.amount}
                              type="number"
                              className="form-control"
                              placeholder="Amount"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
        {/* <div className="row mt-3">
          <div className="col-md-12 text-start">
            <button className="save-btn-outline primary-orange-text fw-bold">
              + Add Item
            </button>
          </div>
        </div> */}
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="dragger">
              <FileUploader
                setImages={setImages}
                Images={Images}
                setDeletedImages={setDeletedImages}
                DeletedImages={DeletedImages}
              />
            </div>
            <div className="mt-2">
              <ConfigProvider
                theme={{
                  components: {
                    Radio: {
                      colorText: "#344054",
                      colorPrimary: "#EF6B3E",
                      fontFamily: "Montserrat",
                    },
                  },
                }}
              >
                <Radio.Group onChange={onChangeRadio} value={value}>
                  <Radio value={1} style={{ fontWeight: "500" }}>
                    One Time Invoice
                  </Radio>
                  <Radio value={2} style={{ fontWeight: "500" }}>
                    Recurring Invoice
                  </Radio>
                </Radio.Group>
              </ConfigProvider>
            </div>
          </div>
        </div>
        {value === 2 ? (
          <>
            <div className="row mt-5">
              <div className="col-md-4 mt-3 ">
                <span className="property-details-input-title">
                  Start Date<span className="sign-up-imp-star">*</span>
                </span>
                <div className="lease-date-picker-container d-flex justify-content-center align-items-end">
                  <ConfigProvider
                    theme={{
                      components: {
                        DatePicker: {
                          zIndexPopupBase: 99999,
                          colorPrimaryHover: "#EF6B3E",
                          borderRadius: 4,
                          fontFamily: "montserrat",
                        },
                      },
                    }}
                  >
                    <Space
                      style={{
                        width: "100%",
                      }}
                      direction="vertical"
                    >
                      <DatePicker
                        placeholder="03/11/2019"
                        style={{
                          width: "100%",
                          height: 45,
                        }}
                        suffixIcon={calendar}
                        format={dateFormat}
                        onChange={onChange}
                        className="lease-date-picker"
                      />
                    </Space>
                  </ConfigProvider>
                </div>
              </div>
              <div className="col-md-4 mt-3 ">
                <span className="property-details-input-title">
                  End Date<span className="sign-up-imp-star">*</span>
                </span>
                <div className="lease-date-picker-container d-flex justify-content-center align-items-end">
                  <ConfigProvider
                    theme={{
                      components: {
                        DatePicker: {
                          zIndexPopupBase: 99999,
                          colorPrimaryHover: "#EF6B3E",
                          borderRadius: 4,
                          fontFamily: "montserrat",
                        },
                      },
                    }}
                  >
                    <Space
                      style={{
                        width: "100%",
                      }}
                      direction="vertical"
                    >
                      <DatePicker
                        placeholder="03/12/2023"
                        style={{
                          width: "100%",
                          height: 45,
                        }}
                        suffixIcon={calendar}
                        format={dateFormat}
                        onChange={onChange}
                        className="lease-date-picker"
                      />
                    </Space>
                  </ConfigProvider>
                </div>
              </div>
              <div className="col-md-4 mt-3">
                <span className="property-details-input-title">
                  Frequency<span className="sign-up-imp-star">*</span>
                </span>
                <ConfigProvider
                  theme={{
                    components: {
                      Select: {
                        zIndexPopupBase: 99999,
                        colorPrimaryHover: "#EF6B3E",
                        optionSelectedBg: "rgba(239, 107, 62, 0.16)",
                        borderRadius: 4,
                        colorTextPlaceholder: "#667085",
                        fontFamily: "montserrat",
                      },
                    },
                  }}
                >
                  <Select
                    suffixIcon={dropdownIcon}
                    placeholder="Select Frequency"
                    style={{
                      width: "100%",
                      height: 45,
                    }}
                    options={[
                      {
                        value: "Once a week",
                        label: "Once a week",
                      },
                      {
                        value: "Weekly",
                        label: "Weekly",
                      },
                      {
                        value: "Monthly",
                        label: "Monthly",
                      },
                      {
                        value: "Quarterly",
                        label: "Quarterly",
                      },
                      {
                        value: "Yearly",
                        label: "Yearly",
                      },
                    ]}
                  />
                </ConfigProvider>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-4 mt-3 ">
                <span className="property-details-input-title">
                  Number of Days until due
                </span>
                <input
                  type="text"
                  placeholder="Number of Days"
                  className="form-control"
                />
              </div>
              <div className="col-md-4 mt-3 mt-4 pt-2">
                <ConfigProvider
                  theme={{
                    components: {
                      Switch: {
                        colorPrimary: "#EF6B3E",
                        colorPrimaryHover: "#EF6B3E",
                      },
                    },
                  }}
                >
                  <span className="d-flex gap-2">
                    <Switch />
                    Repeat Forever
                  </span>
                </ConfigProvider>
              </div>
              <div className="col-md-4 mt-3"></div>
            </div>
          </>
        ) : (
          ""
        )}
        <div className="d-flex align-items-center justify-content-between mt-4">
          <div className="mt-4">
            {/* <Link className="primary-orange-text fw-semibold td">
              Save Invoice
            </Link> */}
          </div>
          <div className="payables-btn ">
            <button onClick={EditInvoice} className="save-btn ">
              <span className="me-2">
                <svg
                  width={21}
                  height={21}
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M22 2 11 13" />
                  <path d="m22 2-7 20-4-9-9-4 20-7z" />
                </svg>
              </span>
              Send
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPayable;
