import React from "react";

const Stepper = ({ currentStep }) => {
  return (
    <div className="stepper-container step-container-responsive tenant-stepper">
      <div
        className={
          currentStep === 1 || currentStep === 2
            ? "step-1 personal-details stepper-active"
            : "step-1 personal-details stepper-inactive"
        }
      >
        1<div className="form-step-text">Personal Details</div>
      </div>
      <div
        className={
          currentStep === 2
            ? "step-2 emergency-info stepper-active"
            : "step-2 emergency-info stepper-inactive"
        }
      >
        2<div className="form-step-text">Emergency Info</div>
      </div>
      <div className="lines-tenant vender-tab-line">
        <div className={`line ${currentStep === 2 ? "active" : ""}`}></div>
      </div>
    </div>
  );
};

export default Stepper;
