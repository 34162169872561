import React, { useState } from "react";
import plusIcon from "assets/plus.png";
import plusIconOrange from "assets/plus-orange.png";
import { DropdownLayout, SelectField } from "Components/GeneralComponents";
import {
  InputField,
  PhoneNumberField,
  SubmitButton,
} from "Components/GeneralComponents";

function AddEmployees({
  form,
  handleChange,
  handleAddEmployee,
  handleRemoveEmployee,
  handleSubmit,
  onClose,
  errors,
  isLoading = false,
}) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div className="modal-content-main-section p-41">
      {form.map((employee, index) => (
        <DropdownLayout key={index} title={`Employee ${index + 1}`}>
          <div key={index} className="employee-form mb-5">
            {/* Row for First Name, Middle Name, Last Name */}
            <div className="row mb-3">
              <div className="col-12 col-md-4">
                <InputField
                  name={`firstName-${index}`}
                  placeholder={"First Name"}
                  required={true}
                  label="First Name"
                  value={employee.firstName}
                  handler={(e) =>
                    handleChange(index, "firstName", e.target.value)
                  }
                  errorMessage={errors?.[index]?.firstName}
                />
              </div>
              <div className="col-12 col-md-4">
                <InputField
                  name={`middleName-${index}`}
                  placeholder={"Middle Name"}
                  required={true}
                  label="Middle Name"
                  value={employee.middleName}
                  handler={(e) =>
                    handleChange(index, "middleName", e.target.value)
                  }
                  errorMessage={errors?.[index]?.middleName}
                />
              </div>
              <div className="col-12 col-md-4">
                <InputField
                  name={`lastName-${index}`}
                  placeholder={"Last Name"}
                  required={true}
                  label="Last Name"
                  value={employee.lastName}
                  handler={(e) =>
                    handleChange(index, "lastName", e.target.value)
                  }
                  errorMessage={errors?.[index]?.lastName}
                />
              </div>
            </div>

            {/* Row for Email and Phone Number */}
            <div className="row mb-3">
              <div className="col-12 col-md-6">
                <InputField
                  name={`email-${index}`}
                  placeholder={"Email Address"}
                  required={true}
                  label="Email"
                  value={employee.email}
                  handler={(e) => handleChange(index, "email", e.target.value)}
                  errorMessage={errors?.[index]?.email}
                />
              </div>
              <div className="col-12 col-md-6">
                <PhoneNumberField
                  name={`phone-${index}`}
                  label={"Phone No"}
                  placeholder={"Phone No"}
                  value={employee.phone}
                  handler={(value) => handleChange(index, "phone", value)}
                  errorMessage={errors?.[index]?.phone}
                />
              </div>
              <div>
                <SelectField
                name={`access-${index}`}
                label={"Select Access"}
                placeholder={"Select Access Level"}
                value={employee.accessLevel}
                options={[
                  { value: "Admin", label: "Admin" },
                  { value: "Manager", label: "Manager" },
                  { value: "Employee", label: "Employee" },
                ]}
                handler={(value) => handleChange(index, "accessLevel", value)}
                errorMessage={errors?.[index]?.accessLevel}

                
                />
              </div>
              {index !== 0 && (
                <div
                  className="row button"
                  onClick={() => handleRemoveEmployee(index)}
                >
                  <div className="col-md-12">
                    <button
                      onClick={() => handleRemoveEmployee(index)}
                      className="primary-orange-text add-new-btn"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </DropdownLayout>
      ))}

      <div className="row mt-3">
        <div className="col-md-12">
          <button
            onClick={() => handleAddEmployee()}
            className="add-employment-btn-white"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img
              src={isHovered ? plusIcon : plusIconOrange}
              className="add-property-icon-white"
              alt="Add Employee"
            />{" "}
            Add Another Employee
          </button>
        </div>
      </div>

      <div className="d-flex justify-content-end align-items-center">
        <button
          type="button"
          className="btn btn-link text-muted mr-3"
          onClick={onClose}
        >
          Skip
        </button>
        <div
          className="col-3 align-items-end "
          style={{
            width: "100px",
          }}
        >
          <SubmitButton handler={handleSubmit} isLoading={isLoading}>
            Save
          </SubmitButton>
        </div>
      </div>
    </div>
  );
}

export default AddEmployees;
