import React from "react";
import { Button, Dropdown, message } from "antd";

function CustomerQueriesDetails() {
  return (
    <div className="container-fluid my-4">
      <div className=" p-4">
        <h5
          className="mb-4 fw-bold"
          style={{
            fontFamily: "Montserrat, sans-serif",
          }}
        >
          QUERY INFO
        </h5>

        <div className="mb-3 d-flex">
          <p className="fw-semibold me-3 mb-0">Landlord Name:</p>
          <p className="mb-0">Olivia Rhye</p>
        </div>

        <div className="mb-3 d-flex">
          <p className="fw-semibold me-3 mb-0">Phone No:</p>
          <p className="mb-0">+1 (555) 543-2109</p>
        </div>

        <div className="mb-3 d-flex">
          <p className="fw-semibold me-3 mb-0">Email:</p>
          <p className="mb-0">olivia@untitledui.com</p>
        </div>

        <div className="mb-4">
          <p className="fw-semibold mb-1">Query Description:</p>
          <p className="mb-0">
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here, content
            here', making it look like readable English.
          </p>
        </div>

        <div className="d-flex gap-2 ">
          <Button
            type="primary"
            className="mx-2 mb-3"
            style={{
              backgroundColor: "#EF6B3E",
              borderRadius: "3px",
              border: "none",
              display: "flex",
              fontSize: "14px",
              justifyContent: "center",
              alignItems: "center",
              padding: "1.2rem 2.2rem",
            }}
            // onClick={handleAccept}
          >
            Accept
          </Button>
          <Button
            type="primary"
            className="mx-2 "
            style={{
              backgroundColor: "#D92D20",
              borderRadius: "3px",
              border: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "14px",
              padding: "1.2rem 2.2rem",
            }}
            // onClick={handleDecline}
          >
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CustomerQueriesDetails;
