import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import ProspectDetails from "Components/ProspectDetails/ProspectDetails";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";

const ProspectDetailsPage = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail="Prospect Details"
        back="all-prospect"
      />
      <ProspectDetails />
    </div>
  );
};

export default ProspectDetailsPage;
