// import React from "react";
// import { PieChart, Pie, Cell, Text } from "recharts";

// const MileageChartDashboard = () => {
//   const RADIAN = Math.PI / 180;
//   const data = [
//     { name: "A", value: 80, color: "#ef6b3e" },
//     { name: "B", value: 45, color: "#00000082" },
//     // { name: "C", value: 25, color: "#00000082" },
//   ];
//   const cx = 150;
//   const cy = 200;
//   const iR = 50;
//   const oR = 100;
//   const value = 50;

//   const needle = (value, data, cx, cy, iR, oR, color) => {
//     let total = 0;
//     data.forEach((v) => {
//       total += v.value;
//     });
//     const ang = 180.0 * (1 - value / total);
//     const length = (iR + 2 * oR) / 3;
//     const sin = Math.sin(-RADIAN * ang);
//     const cos = Math.cos(-RADIAN * ang);
//     const r = 5;
//     const x0 = cx + 5;
//     const y0 = cy + 5;
//     const xba = x0 + r * sin;
//     const yba = y0 - r * cos;
//     const xbb = x0 - r * sin;
//     const ybb = y0 + r * cos;
//     const xp = x0 + length * cos;
//     const yp = y0 + length * sin;

//     return [
//       <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
//       <path
//         d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
//         stroke="#none"
//         fill={color}
//       />,
//     ];
//   };

//   return (
//     <>
//       <PieChart width={400} height={300}>
//         <Pie
//           dataKey="value"
//           startAngle={180}
//           endAngle={0}
//           data={data}
//           cx={cx}
//           cy={cy}
//           innerRadius={iR}
//           outerRadius={oR}
//           fill="#8884d8"
//           stroke="none"
//         >
//           {data.map((entry, index) => (
//             <Cell key={`cell-${index}`} fill={entry.color} />
//           ))}
//         </Pie>
//         {/* {needle(value, data, cx, cy, iR, oR, "#d0d000")} */}
//         {<p>Miles Driven</p>}
//       </PieChart>
//     </>
//   );
// };

// export default MileageChartDashboard;

import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const MileageChartDashboard = () => {
  const [state, setState] = useState({
    series: [75],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
        offsetY: -10,
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          dataLabels: {
            name: {
              fontSize: "16px",
              color: "#000000",
              offsetY: 120,
            },
            value: {
              offsetY: 76,
              fontSize: "22px",
              color: undefined,
              formatter: function (val) {
                return val + "%";
              },
            },
          },
        },
      },
      fill: {
        colors: ["#EF6B3E"],
        type: "gradient",
        gradient: {
          shade: "dark",
          shadeIntensity: 0.15,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 65, 91],
        },
      },
      stroke: {
        dashArray: 4,
      },
      labels: ["Miles Driven"],
    },
  });
  return (
    <>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="radialBar"
        height={350}
      />
    </>
  );
};

export default MileageChartDashboard;
