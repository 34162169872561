import TitleTopBar from "Helpers/TitleTopBar";

function Fixedtopbar(props) {
  return (
    <div className="container-fluid bg-default fixed-topbar">
      <TitleTopBar {...props} />
    </div>
  );
}
export default Fixedtopbar;
