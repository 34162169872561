import React from "react";

function SidebarCustomerQueries({ width = 21, height = 21 }) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        id="Customer-Support-1--Streamline-Sharp"
        width={width}
        height={height}
      >
        <desc>
          Customer Support 1 Streamline Icon: https://streamlinehq.com
        </desc>
        <g id="customer-support-1--customer-headset-help-microphone-phone-support">
          <path
            id="Union"
            fill="currentColor"
            fill-rule="evenodd"
            d="M12 3C8.68629 3 6 5.68629 6 9v8H1V9h3c0 -4.41828 3.58172 -8 8 -8 4.4183 0 8 3.58172 8 8h3v8h-3c0 2.7614 -2.2386 5 -5 5v1H9v-4h6v1c1.6569 0 3 -1.3431 3 -3V9c0 -3.31371 -2.6863 -6 -6 -6Z"
            clip-rule="evenodd"
            stroke-width="1"
          ></path>
        </g>
      </svg>
    </div>
  );
}

export default SidebarCustomerQueries;
