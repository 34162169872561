import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUserRole, getLandlordUsers } from "network/landlord/setting";
import {
  CustomTable,
  SelectField,
  SubmitButton,
} from "Components/GeneralComponents";
import { updateRole } from "network/landlord/setting";
import { validateLandlordUserRoles } from "utils/validations";
import { message, Tooltip } from "antd";
import { LockFilled } from "@ant-design/icons";

const SettingAddUserRole = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [role, setRole] = useState([]);
  const [users, setUsers] = useState([]);
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({
    roles: [],
    user: "",
  });

  const handleChange = (fieldName, value) => {
    setForm((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const columns = [
    {
      title: "USER ROLES",
      dataIndex: "userRoles",
      key: "userRoles",
    },
    {
      title: "DESCRIPTION",
      dataIndex: "description",
      key: "description",
    },
  ];

  useEffect(() => {
    getLandlordUsers().then((res) => {
      if (res.apiCallStatus === "success") {
        setUsers(res.message);
      }
    });

    getUserRole().then((res) => {
      if (res.apiCallStatus === "success") {
        setRole(res.message);
      }
    });
  }, []);

  const data = [
    {
      key: "1",
      userRoles: (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <span className="text-bold">Owner/Executive (Full Access)</span>
            <svg
              width={17}
              height={17}
              fill="none"
              stroke="#667085"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
              <path d="M12 16v-4" />
              <path d="M12 8h.01" />
            </svg>
          </div>
        </>
      ),
      description:
        "Access to all features, including properties, maintenance requests, contacts, documentation, communication and accounting. Can create and manage other user accounts and permissions",
    },
    {
      key: "2",
      userRoles: (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <span>
              Property Manager <br /> (Full or Restricted Access)
            </span>
            <svg
              width={17}
              height={17}
              fill="none"
              stroke="#667085"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
              <path d="M12 16v-4" />
              <path d="M12 8h.01" />
            </svg>
          </div>
        </>
      ),
      description:
        "Access to all features related to their assigned properties, maintenance requests, contacts, documentation, communication and accounting",
    },
    {
      key: "2",
      userRoles: (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <span>Leasing Agent (Restricted Access)</span>
            <svg
              width={17}
              height={17}
              fill="none"
              stroke="#667085"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
              <path d="M12 16v-4" />
              <path d="M12 8h.01" />
            </svg>
          </div>
        </>
      ),
      description:
        "Access to property listings, prospect and tenant information, leases, and communication tools.",
    },
    {
      key: "4",
      userRoles: (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <span>Maintenance Technician (Restricted Access)</span>
            <svg
              width={17}
              height={17}
              fill="none"
              stroke="#667085"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
              <path d="M12 16v-4" />
              <path d="M12 8h.01" />
            </svg>
          </div>
        </>
      ),
      description:
        "Access to property information, maintenance request, including task and work orders and communication tools.",
    },
    {
      key: "5",
      userRoles: (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <span>
              Accounting/Finance <br /> (Restricted Access)
            </span>
            <svg
              width={17}
              height={17}
              fill="none"
              stroke="#667085"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
              <path d="M12 16v-4" />
              <path d="M12 8h.01" />
            </svg>
          </div>
        </>
      ),
      description:
        "Access financial modules, including rent rolls, income/expense tracking, and reporting tools.",
    },
  ];

  const assignRole = () => {
    const newErrors = validateLandlordUserRoles(form);
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      return;
    }
    const data = {
      role: form.roles,
      users: form.user,
    };

    setLoader(true);
    updateRole(data).then((res) => {
      setLoader(false);
      if (res.apiCallStatus === "success") {
        setForm({
          roles: [],
          user: "",
        });
        message.success("User Role Assigned Successfully");
      } else {
        message.error(res.error.message);
      }
    });
  };

  return (
    <>
      {/* User Roles Title Start */}
      <div className="d-flex justify-content-between align-items-center mt-3">
        <div className="heading pt-4 pb-5 ps-4">USER ROLES</div>
        <Tooltip title={"Comming Soon"}>
          <div>
            <button
              className="new-user-role-btn me-4"
              onClick={() => {
                navigate("/add-user-role");
              }}
              style={{ cursor: "not-allowed", opacity: 0.6 }}
              disabled={true}
            >
              <svg
                width={20}
                height={20}
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="me-2"
              >
                <path d="M12 5v14" />
                <path d="M5 12h14" />
              </svg>
              New User Role <LockFilled />
            </button>
          </div>
        </Tooltip>
      </div>
      {/* User Roles Title End */}
      <div className="setting-info-container">
        <div className="container">
          {/* User Roles Text Description Start */}
          <div className="mt-3 ps-2">
            <p className="user-roles-text-description">
              These are predefined user roles.
            </p>
          </div>

          {/* User Roles Text Description End */}
          <CustomTable columns={columns} dataSource={data} />
          {/* Pre-defined User Roles End */}

          <p className="mb-0 mt-5">Select User Name & User Roles</p>
          <p className="normal-grey-text">
            Lorem ipsum dolor sit amet consectet.
          </p>
          <div className="row mt-3">
            <div className="col-md-12">
              <SelectField
                required={true}
                label={"User Name"}
                placeholder={"Select User Name"}
                value={form.user}
                options={users.map((e) => {
                  return { value: e.id, label: e.firstName + " " + e.lastName };
                })}
                handler={(e) => handleChange("user", e)}
                errorMessage={errors.users}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <SelectField
                required={true}
                label={"User Role"}
                placeholder={"Select User Role"}
                value={form.roles}
                mode={"multiple"}
                options={role.map((e) => {
                  return { value: e._id, label: e.role };
                })}
                handler={(e) => handleChange("roles", e)}
                errorMessage={errors.role}
              />
            </div>
          </div>
          <div className="row my-4">
            <div className="setting-btn my-4 d-flex align-items-center gap-4">
              <SubmitButton handler={assignRole} isLoading={loader}>
                Save
              </SubmitButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingAddUserRole;
