import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import Contacts from "Components/Contacts/Contacts";
import NoAccessPage from "Pages/NoAccessMainPage/NoAccessPage";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";

const ContactsPage = () => {
  const storedRoles = localStorage.getItem("myUserRoles");
  const myUserRoles =
    storedRoles !== "undefined" ? JSON.parse(storedRoles) : [];
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar topBarTitle="Contacts" />
      {!["Owner/Executive", "Property Manager", "Leasing Agent"].some((role) =>
        myUserRoles?.includes(role)
      ) && myUserRoles?.length !== 0 ? (
        <NoAccessPage />
      ) : (
        <Contacts />
      )}
    </div>
  );
};

export default ContactsPage;
