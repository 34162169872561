import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import TenantDashboard from "Components/TenantDashboard/TenantDashboard";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const Dashboard = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar topBarTitle="Dashboard" />
      <TenantDashboard />
    </div>
  );
};

export default Dashboard;
