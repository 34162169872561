import React, { useEffect, useState } from "react";
import * as InnerComponents from "./index";
import TempModal from "Modals/TempModal/TempModal";
import { renewLeaseDetails } from "utils/stepsData";
import { Stepper } from "Components/GeneralComponents";
import { getLeaseById, renewLease } from "network/landlord/lease";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import {
  validateLeaseDocumentForm,
  validateLeaseInfoForm,
} from "utils/validations";
import Loader from "Helpers/Loader";
import UploadDocument from "Components/NewLease/UploadDocument";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

const RenewLease = () => {
  const { id } = UseUrlParamsHook();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [leaseFiles, setLeaseFiles] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [form, setForm] = useState({
    property: {
      id: "",
      heading1: "",
      heading2: "",
      heading3: "",
    },
    unit: "",
    lease_term: "",
    lease_term_start_date: "",
    lease_term_end_date: "",
    first_rent_date: "",
    frequency: "",
    rent_amount: {
      amount: "",
    },
    security_deposit: "",
    security_amount: {
      amount: "",
    },
    late_fee: "",
    late_fee_charge: "",
    late_fee_frequency: "",
    late_fees_amount: {
      amount: "",
      type: "$",
    },
    renewLease: "",
  });

  const [documentInfo, setDocumentInfo] = useState({
    isSigned: "",
    newLeaseSign: "",
    documentId: "",
    documentName: "",
    documentUrl: "",
    documentStatus: "",
    invites: [],
  });

  const handleChange = (fieldName, value, index) => {
    if (fieldName === "coTenant") {
      const newCoTenants = [...form.coTenants];
      newCoTenants[index] = value;
      setForm({
        ...form,
        coTenants: newCoTenants,
      });
      return;
    }
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  useEffect(() => {
    setLoader(true);
    getLeaseById(id).then((res) => {
      setLoader(false);
      if (res.apiCallStatus === "success") {
        setForm({
          property: {
            id: res.message.data.property || "",
            heading1: "",
            heading2: "",
            heading3: "",
          },
          unit: res.message.data.unit._id || "",
          lease_term: res.message.data.lease_term || "",
          lease_term_start_date: res.message.data.lease_term_start_date || "",
          lease_term_end_date: res.message.data.lease_term_end_date || "",
          first_rent_date: res.message.data.first_rent_date || "",
          frequency: res.message.data.frequency || "",
          rent_amount: {
            amount: res.message.data.rent_amount || "",
          },
          security_deposit: res.message.data.deposit_amount ? "Yes" : "No",
          security_amount: {
            amount: res.message.data.deposit_amount || "",
          },
          // set late fee based on late fee amount
          late_fee: res.message.data.late_fees_amount ? "Yes" : "No",
          late_fee_charge: res.message.data.late_fee_charge_after_days || "",
          late_fee_frequency: res.message.data.late_fee_frequency || "",
          late_fees_amount: {
            amount: res.message.data.late_fees_amount || "",
            type: res.message.data.late_fees_type || "$",
          },
          renewLease: "",
        });
      }
    });
  }, [id]);

  useEffect(() => {
    if (form.security_deposit === "No") {
      setForm((prevForm) => ({
        ...prevForm,
        security_amount: {
          ...prevForm.security_amount,
          amount: "",
        },
      }));
    }
  }, [form.security_deposit]);

  useEffect(() => {
    if (form.late_fee === "No") {
      setForm((prevForm) => ({
        ...prevForm,
        late_fee_charge: "",
        late_fee_frequency: "",
        late_fees_amount: {
          ...prevForm.late_fees_amount,
          amount: "",
        },
      }));
    }
  }, [form.late_fee]);

  const handleDocumentInfoChange = (fieldName, value) => {
    if (fieldName === "object") {
      setDocumentInfo((prevDocumentInfo) => ({
        ...prevDocumentInfo,
        documentId: value.documentId,
        documentName: value.documentName,
        documentStatus: value.documentStatus,
        documentUrl: value.documentUrl,
      }));
      return;
    } else if (fieldName === "invites") {
      setDocumentInfo((prevDocumentInfo) => ({
        ...prevDocumentInfo,
        invites: value,
      }));
      return;
    }
    setDocumentInfo((prevDocumentInfo) => ({
      ...prevDocumentInfo,
      [fieldName]: value,
    }));
  };

  const handleLeaseInfo = () => {
    const newErrors = validateLeaseInfoForm(form);
    setErrors(newErrors);

    const data = {
      lease_term: form.lease_term,
      lease_term_end_date: form.lease_term_end_date,
      rent_amount: form.rent_amount.amount,
      first_rent_date: form.first_rent_date,
      frequency: form.frequency,
      deposit_amount: form.security_amount.amount,
      late_fees_amount: form.late_fees_amount.amount,
      late_fees_type: form.late_fees_amount.type,
      late_fee_frequency: form.late_fee_frequency,
      late_fee_charge: form.late_fee_charge,
    };

    if (Object.keys(newErrors).length === 0) {
      setIsLoading(true);
      renewLease(id, data).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          message.success("Lease renewed successfully!");
          setCurrentStep(currentStep + 1);
        }
      });
    }
  };

  const handleDocumentInfo = () => {
    const newErrors = validateLeaseDocumentForm(documentInfo, leaseFiles);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBackButton = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleNext = () => {
    switch (currentStep) {
      case 1:
        handleLeaseInfo();
        break;
      case 2:
        handleDocumentInfo();
        break;
    }
  };

  return (
    <>
      {loader ? (
        <div className="mx-auto">
          <Loader />
        </div>
      ) : (
        <>
          {openModal && (
            <TempModal
              onClose={() => setOpenModal(false)}
              title={"Lease Created Successfully!"}
              success="Go To Leases"
              route="all-lease"
            />
          )}
          <div className="container-fluid p-3">
            <div className="container">
              <Stepper
                steps={renewLeaseDetails}
                activeStep={currentStep - 1}
                lockStep={false}
                maxSteps={4} // Customize if desired
                setIsDraft={(isDraft) => {}}
              />
              <div className="stepper-content-container mt-4">
                {currentStep === 1 && (
                  <InnerComponents.LeaseInfoForm
                    form={form}
                    handleChange={handleChange}
                    errors={errors}
                    leaseFiles={leaseFiles}
                    setLeaseFiles={setLeaseFiles}
                    handleNext={handleNext}
                    isLoading={isLoading}
                  />
                )}
                {currentStep === 2 && (
                  // <InnerComponents.DocumentInfoForm
                  //   form={documentInfo}
                  //   handleChange={handleDocumentInfoChange}
                  //   errors={errors}
                  //   leaseFiles={leaseFiles}
                  //   setLeaseFiles={setLeaseFiles}
                  //   handleBackButton={handleBackButton}
                  //   handleNext={handleNext}
                  //   isLoading={isLoading}
                  //   isSubmitForm={true}
                  //   tenantEmails={tenantEmails}
                  //   leaseId={id}
                  //   buttonLoader={isLoading}
                  // />
                  <UploadDocument
                    leaseId={id}
                    onClose={() => {
                      navigate("/all-lease");
                    }}
                    back={true}
                    handleBackButton={() => {
                      handleBackButton();
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RenewLease;
