import React from "react";
import {
  InputField,
  PhoneNumberField,
  SelectField,
  TextAreaField,
  SubmitButton,
} from "Components/GeneralComponents";
import { selectRelationship } from "utils/dropdownData";

const EmergencyInfoForm = ({
  emergencyContacts,
  handleChange,
  handleAddContact,
  handleRemoveContact,
  handleBackButton,
  handleNext,
  isLoading = false,
  isSubmitForm = false,
}) => {
  return (
    <div className="container-fluid">
      {emergencyContacts.map((contact, index) => (
        <div key={index}>
          <div className="row">
            <div className="col-md-4">
              <InputField
                name={"firstName"}
                label={"First Name"}
                placeholder={"First Name"}
                value={contact?.firstName}
                handler={(e) => handleChange(e.target.name, index, e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <InputField
                name={"middleName"}
                label={"Middle Name"}
                placeholder={"Middle Name"}
                value={contact?.middleName}
                handler={(e) => handleChange(e.target.name, index, e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <InputField
                name={"lastName"}
                label={"Last Name"}
                placeholder={"Last Name"}
                value={contact?.lastName}
                handler={(e) => handleChange(e.target.name, index, e.target.value)}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <InputField
                name={"email"}
                type="email"
                label={"Email"}
                placeholder={"Email"}
                value={contact?.email}
                handler={(e) => handleChange(e.target.name, index, e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <PhoneNumberField
                name={"phone"}
                label={"Phone No"}
                placeholder={"Phone No"}
                value={contact?.phone}
                handler={(value) => handleChange("phone", index, value)}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <SelectField
                name={"relationship"}
                label={"Relationship"}
                placeholder={"Select Relationship"}
                value={contact?.relationship}
                options={selectRelationship}
                handler={(value) => handleChange('relationship', index, value)}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <TextAreaField
                name={"description"}
                label={"Description"}
                placeholder={"Description"}
                value={contact?.description}
                handler={(e) => handleChange(e.target.name, index, e.target.value)}
                rows={10}
                cols={30}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              {index !== 0 ? (
                <div className="row mt-3">
                  <div className="col-md-12">
                    <button
                      onClick={() => handleRemoveContact(index)}
                      className="primary-orange-text add-new-btn"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ))}
      <div className="row mt-3">
        <div className="col-md-12">
          <button
            onClick={handleAddContact}
            className="primary-orange-text add-new-btn"
          >
            + Add Another Emergency Contact
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-between gap-1 mt-5">
        <div style={{ width: "50%" }}>
          <SubmitButton type="contained" handler={handleBackButton}>Back</SubmitButton>
        </div>
        <div style={{ width: "50%" }}>
          <SubmitButton handler={handleNext} isLoading={isLoading}>
            {isSubmitForm ? "Submit" : "Next"}
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default EmergencyInfoForm;
