import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import sizeIcon from "assets/list-icon-3.png";
import homeIcon from "assets/icon_house.png";
import bedroomIcon from "assets/list-icon-1.png";
import bathroomIcon from "assets/list-icon-2.png";
import buildingIcon from "assets/icon_building.png";
import tickIcon from "assets/icon_tick_circle.png";
import Loader from "Helpers/Loader";
import ImageSwiper from "Components/GeneralComponents/ImageSwiper";
import { AmenitiesTab } from "Components/TenantPropertyUnitsView";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

export const OverviewTab = ({ propertyData, isLoading }) => {
  const { TabPane } = Tabs;
  const containerStyle = { width: "100%", height: "400px" };
  const [center, setCenter] = useState({ lat: null, lng: null });
  const [markers, setMarkers] = useState([]);
  const [addressNotFound, setAddressNotFound] = useState(false);

  // Load Google Maps API once with consistent API key
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  // Fetch coordinates using the Google Geocoding API
  useEffect(() => {
    const getCoords = async () => {
      const formattedAddress = [
        propertyData.address?.address_line_1,
        propertyData.address?.address_line_2,
        propertyData.address?.city,
        propertyData.address?.state,
        propertyData.address?.country,
        propertyData.address?.zipcode,
      ]
        .filter(Boolean)
        .join(", ");

      if (formattedAddress) {
        try {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
              formattedAddress
            )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
          );
          const data = await response.json();
          if (data.results.length > 0) {
            const location = data.results[0].geometry.location;
            setCenter(location);
            setMarkers([{ position: location, popUp: formattedAddress }]);
            setAddressNotFound(false); // Address found
          } else {
            setAddressNotFound(true); // Address not found
          }
        } catch (error) {
          console.error("Error fetching geocode data", error);
          setAddressNotFound(true); // Error implies address not found
        }
      }
    };

    getCoords();
  }, [propertyData?.address]);

  const formateImageViewerData = (data) => ({
    title: data?.title || "",
    images: data?.images || [],
    address: `${data?.address?.address_line_1}, ${data?.address?.city}`,
    date: data.createdAt,
  });

  // Return loader until data and Google Maps API are fully loaded
  if (isLoading || !isLoaded) return <Loader />;

  return (
    <div>
      <ImageSwiper data={formateImageViewerData(propertyData)} />
      <Tabs defaultActiveKey="2" className="property_details_view_sub_tabs mt-5">
        <TabPane tab="Description" key="2">
          <div className="row">
            <div className="col-md-12">
              <pre className="properties_details_view_description_content">
                {propertyData?.description || "N/A"}
              </pre>
            </div>
          </div>
          <div className="row mt-5">
            <div className={window.innerWidth <= 980 ? "" : "col-md-8"}>
              <div className="d-flex icons-list-main">
                <ul className="list-group landlord-property-details-overview">
                  <li className="list-group-item icons-list mb-4">
                    <img src={bedroomIcon} className="icons-list-image" alt="Bedrooms" />
                    {propertyData?.bedroom || "0"}
                    <sup className="icons-list-sub-title">Bedrooms</sup>
                  </li>
                  <li className="list-group-item icons-list description-icon-center mb-4">
                    <img src={bathroomIcon} className="icons-list-image" alt="Bathrooms" />
                    {propertyData?.bathroom || "0"}
                    <sup className="icons-list-sub-title">Bathrooms</sup>
                  </li>
                  <li className="list-group-item icons-list mb-4">
                    <img src={sizeIcon} className="icons-list-image" alt="Size" />
                    {propertyData?.area || "0"}
                    <sup className="icons-list-sub-title-size">Size</sup>
                  </li>
                </ul>
              </div>
              <div className="row text-center">
                <div className="col">
                  <div className="counter">
                    <img src={homeIcon} className="fa-2x" alt="Property Type" />
                    <h2 className="timer count-title count-number">Property Type</h2>
                    <p className="count-text">
                      {propertyData?.property_sub_type?.replace(/_/g, " ") || "N/A"}
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="counter">
                    <img src={buildingIcon} className="fa-2x" alt="Active Units" />
                    <h2 className="timer count-title count-number">Active Units</h2>
                    <p className="count-text">{propertyData?.active_units || "N/A"}</p>
                  </div>
                </div>
                <div className="col">
                  <div className="counter">
                    <img src={tickIcon} className="fa-2x" alt="Status" />
                    <h2 className="timer count-title count-number">Status</h2>
                    <p className="count-text-unique">Active</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={window.innerWidth <= 980 ? "" : "col-md-4"}>
              {addressNotFound ? (
                <div className="text-center text-white d-flex align-items-center justify-content-center bg-dark mt-5"  mapContainerStyle={containerStyle} style={{ height: "100%" }}>
                  <h3>Address Not Found</h3>
                </div>
              ) : (
                <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={13}>
                  {markers.map((marker, index) => (
                    <Marker key={index} position={marker.position} title={marker.popUp} />
                  ))}
                </GoogleMap>
              )}
            </div>
          </div>
        </TabPane>
        <TabPane tab="Amenities" key="3">
          <AmenitiesTab amenities={propertyData?.amenities} />
        </TabPane>
      </Tabs>
    </div>
  );
};
