import axios from "axios";
import config from "Helpers/config.js";

const DeleteMileage = (deleteId) => {
  // Return the fetch promise so that .then can be chained
  return fetch(`${config["baseUrl"]}/api/mileage/${deleteId}`, {
    method: "DELETE",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.apiCallStatus === "success") {
        console.log(res);
        return res; // Resolve with the response data
      } else {
        console.log(res, "error");
        return Promise.reject(res); // Reject with the error response
      }
    });
};

// Fetch Mileage Data
const fetchMileage = async (currentPage = 1, pageSize = 10, filters = {}) => {
   
  console.log("Fetch page", currentPage, pageSize, filters);

  // Construct the query parameters
  const queryParams = new URLSearchParams({
    ...filters,
    page: currentPage.toString(), // Convert to string for URLSearchParams
    limit: pageSize.toString(),
  });

  try {
    const response = await fetch(`${config["baseUrl"]}/api/mileages?${queryParams}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });

    const res = await response.json();

    if (response.ok && res.apiCallStatus === "success") {
      console.log("Mileage Data:", res);
      return res; // Properly resolve with the response data
    } else {
      console.error("Error response:", res);
      throw new Error(res.message || "Failed to fetch mileage data");
    }
  } catch (error) {
    console.error("Fetch mileage error:", error);
    throw error; // Re-throw the error to be caught in the calling function
  }
};

const bulkDeleteMileages = async (ids) => {
    try {
      // Ensure the IDs array is not empty
      if (!ids || ids.length === 0) {
        throw new Error("No mileage IDs provided for deletion");
      }
  
      // Make the API request to delete multiple mileage entries
      const response = await axios.delete(`${config["baseUrl"]}/api/mileage/bulkDelete`, {
        data: { ids },
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },

      });
  
      // Return the success message from the response
      return response.data;
    } catch (error) {
      // Handle errors and return an error message
      console.error("Error deleting mileage entries:", error.message);
      throw new Error(error.response?.data?.message || "Failed to delete mileage entries");
    }
  };
  

export { 
    DeleteMileage,
    fetchMileage,
    bulkDeleteMileages
};
