import React, { useState, useEffect } from "react";
import { availableProperties } from "network/landlord/prospect";
import { formatDate } from "utils";
import {
  selectBoolean,
  selectLeaseTerm,
  selectFrequency,
  selectFrequencyFromThree,
  selectLateFeeFrequency,
} from "utils/dropdownData";
import * as Component from "Components/GeneralComponents";
import { getProperty } from "network/landlord/property";

const LeaseInfo = ({
  form,
  handleChange,
  errors,
//   handlePropertyType,
  handleNext,
  isLoading = false,
}) => {
  const [properties, setProperties] = useState([]);
  const [units, setUnits] = useState([]);
  const [makeEmptyUnit, setMakeEmptyUnit] = useState(false);
  const [data, setData] = useState([]);
  const [isEditDraft, setIsEditDraft] = useState(false);

  // Fetch available properties
  useEffect(() => {
    availableProperties().then((response) => {
      if (response.apiCallStatus == "success") {
        if (form.property.id !== "") {
          setIsEditDraft(true);
          getProperty(form.property.id).then((res) => {
            if (res.apiCallStatus === "success") {
              const selectedProperty = {
                id: res.message.id,
                heading1: res.message.property_type,
                heading2: res.message.title,
                heading3: `${res.message.address.address_line_1}, ${res.message.address.address_line_2}, ${res.message.address.city}, ${res.message.address.state}, ${res.message.address.country}, ${res.message.address.zipcode}`,
              };
              setProperties([
                {
                  _id: res.message.id,
                  title: res.message.title,
                  status: res.message.status,
                  units: res.message.units.map((e) => ({
                    id: e.id,
                    name: e.name,
                    isAssigned: e.isAssigned,
                  })),
                  address: `${res.message.address.address_line_1}, ${res.message.address.address_line_2}, ${res.message.address.city}, ${res.message.address.state}, ${res.message.address.country}, ${res.message.address.zipcode}`,
                  property_type: res.message.property_type,
                  property_sub_type: res.message.property_sub_type,
                },
                ...response.message.propertiesWithUnassignedUnits,
              ]);
              setData([
                selectedProperty,
                ...response.message.propertiesWithUnassignedUnits.map((e) => ({
                  id: e._id,
                  heading1: e.property_type,
                  heading2: e.title,
                  heading3: e.address,
                })),
              ]);
            }
          });
        } else {
          setProperties(response.message.propertiesWithUnassignedUnits);
          setData(
            response.message.propertiesWithUnassignedUnits.map((e) => ({
              id: e._id,
              heading1: e.property_type,
              heading2: e.title,
              heading3: e.address,
            }))
          );
        }
      }
    });
  }, []);

  // Set units based on selected property
  useEffect(() => {
    if (form.property.id && properties.length > 0) {
      const selectedProperty = properties.find(
        (property) =>
          property._id === form.property.id || property.id === form.property.id
      );
    //   selectedProperty && handlePropertyType(selectedProperty.property_type);
      selectedProperty && setUnits(selectedProperty.units);
    }
  }, [properties]);

  // Handle property change
  const handlePropertyChange = (e) => {
    handleChange("property", e);
    const selectedProperty = properties.find(
      (property) => property._id === e.id
    );
    // selectedProperty && handlePropertyType(selectedProperty.property_type);
    selectedProperty && setUnits(selectedProperty.units);
    isEditDraft ? setIsEditDraft(false) : setMakeEmptyUnit(true);
  };

  // Make unit field empty when property changes
  useEffect(() => {
    if (makeEmptyUnit) {
      handleChange("unit", "");
      setMakeEmptyUnit(false);
    }
  }, [form.property]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Component.SelectPropertyField
            label={"Property"}
            value={
              form.property.id === ""
                ? ""
                : `${form.property.heading2} / ${form.property.heading1}`
            }
            placeholder={"Select Property"}
            category={"lease"}
            isRequired={true}
            PropertyData={data}
            selectedPropertyId={form.property.id}
            errorMessage={errors.property}
            onPropertySelect={(selectedProperty) => {
              handlePropertyChange(selectedProperty);
            }}
            disabled={true}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <Component.SelectField
            name={"unit"}
            required={true}
            label={"Unit"}
            TooltipTitle={
              "Unit can't be selected without selecting property first."
            }
            placeholder={"First select property then unit"}
            value={form.unit}
            options={units.map((e) => ({ value: e.id, label: e.name }))}
            handler={(e) => handleChange("unit", e)}
            errorMessage={errors.unit}
            disabled={true}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <Component.SelectField
            name={"lease_term"}
            required={true}
            label={"Lease Term"}
            placeholder={"Select Lease Term"}
            value={form.lease_term}
            options={selectLeaseTerm}
            handler={(e) => handleChange("lease_term", e)}
            errorMessage={errors.lease_term}
          />
        </div>
        <div className="col-md-4">
          <Component.DateField
            required={true}
            label={"Lease Term Start Date"}
            placeholder={"Select Start Date"}
            defaultValue={
              form.lease_term_start_date &&
              formatDate(form.lease_term_start_date)
            }
            handler={(e) => {
              const formattedDate = formatDate(new Date(e));
              handleChange("lease_term_start_date", formattedDate);
            }}
            errorMessage={errors.lease_term_start_date}
            disabled={true}
          />
        </div>
        <div className="col-md-4">
          <Component.DateField
            required={!(form.lease_term === "Month-to-Month")}
            label={"Lease Term End Date"}
            placeholder={"Select End Date"}
            defaultValue={
              form.lease_term_end_date && formatDate(form.lease_term_end_date)
            }
            handler={(e) => {
              const formattedDate = formatDate(new Date(e));
              handleChange("lease_term_end_date", formattedDate);
            }}
            errorMessage={errors.lease_term_end_date}
            disabled={form.lease_term === "Month-to-Month"}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <Component.DateField
            required={true}
            label={"First Rent Date"}
            placeholder={"Select Date"}
            defaultValue={
              form.first_rent_date && formatDate(form.first_rent_date)
            }
            handler={(e) => {
              const formattedDate = formatDate(new Date(e));
              handleChange("first_rent_date", formattedDate);
            }}
            errorMessage={errors.first_rent_date}
          />
        </div>
        <div className="col-md-4">
          <Component.SelectField
            name={"frequency"}
            required={true}
            label={"Frequency"}
            placeholder={"Select Frequency"}
            value={form.frequency}
            options={
              form.lease_term === "Month-to-Month"
                ? selectFrequencyFromThree
                : selectFrequency
            }
            handler={(e) => handleChange("frequency", e)}
            errorMessage={errors.frequency}
          />
        </div>
        <div className="col-md-4">
          <Component.AmountField
            name={"rent_amount"}
            required={true}
            label={"Rent Amount"}
            placeholder={"Enter Rent Amount"}
            value={form.rent_amount}
            handler={(value) => handleChange("rent_amount", value)}
            errorMessage={errors.rent_amount}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6">
          <Component.SelectField
            name={"security_deposit"}
            label={"Security Deposit"}
            placeholder={"Select Security Deposit"}
            value={form.security_deposit}
            options={selectBoolean}
            handler={(e) => handleChange("security_deposit", e)}
          />
        </div>
        <div className="col-md-6">
          <Component.AmountField
            name={"security_deposit_amount"}
            label={"Security Deposit Amount"}
            placeholder={"Enter Security Deposit Amount"}
            value={form.security_amount}
            handler={(value) => handleChange("security_amount", value)}
            disabled={form.security_deposit === "Yes" ? false : true}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6">
          <Component.SelectField
            name={"late_fee"}
            label={"Late Fees"}
            placeholder={"Select Late  Fees"}
            value={form.late_fee}
            options={selectBoolean}
            handler={(e) => handleChange("late_fee", e)}
          />
        </div>

        <div className="col-md-6">
          <Component.InputField
            name={"late_fee_charge"}
            label={"When to Charge Late Fees?"}
            type={"number"}
            placeholder={"Days after rent due"}
            value={form.late_fee_charge}
            handler={(e) => handleChange("late_fee_charge", e.target.value)}
            disabled={form.late_fee === "Yes" ? false : true}
            TooltipTitle={
              "Number of days, after the due date, when late fees should be applied."
            }
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6">
          <Component.SelectField
            name={"late_fee_frequency"}
            label={"Frequency"}
            placeholder={"Select Frequency"}
            value={form.late_fee_frequency}
            options={selectLateFeeFrequency}
            handler={(e) => handleChange("late_fee_frequency", e)}
            disabled={form.late_fee === "Yes" ? false : true}
          />
        </div>

        <div className="col-md-6">
          <Component.AmountField
            name={"late_fees_amount"}
            label={"Late Fee Amount"}
            placeholder={
              form.late_fees_amount.type === "%"
                ? "Enter Late Fees Percentage"
                : "Enter Late Fees Amount"
            }
            value={form.late_fees_amount}
            handler={(value) => handleChange("late_fees_amount", value)}
            amountTypes={["$", "%"]}
            disabled={form.late_fee === "Yes" ? false : true}
          />
        </div>
      </div>
      <div className="stepper-first-btn property-details-next-btn-main d-flex justify-content-between gap-3 mt-5">
        <Component.SubmitButton isLoading={isLoading} handler={handleNext}>
            Renew Lease
        </Component.SubmitButton>
      </div>
    </div>
  );
};

export default LeaseInfo;
