import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import NewFile from "Components/NewFile/NewFile";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import React from "react";

const FilesNew = () => {
  const { id } = UseUrlParamsHook();
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"New File"}
        back={id ? `property-details-view?id=${id}` : `all-files`}
      />
      <NewFile />
    </div>
  );
};

export default FilesNew;
