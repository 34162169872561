import React, { useState } from 'react';
import { ConfigProvider, Radio } from 'antd';
import FileUploader from 'Components/FileUploader/FileUploader';
import Switch from 'antd';
import { SubmitButton } from 'Components/GeneralComponents';
import { updateStepInDatabase } from 'network/tenant/passport';
function ScreeningQuestions({
    form,
      errors,
      handleNext,
      handlePrev,
}) {

    console.log(handleNext)
  // State to hold the values of the questions
  const [formValues, setFormValues] = useState({
    smoke: null,
    bankruptcy: null,
    felony: null,
    eviction: null,
    rentRefusal: null,
  });

  const handleNextQuestions = async() => {
    await updateStepInDatabase(9,formValues)
    handleNext()
  }

  // General onChange function to handle changes for all options
  const handleChange = (question, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [question]: value,
    }));
  };

  return (
    <div>
      <div className="row mt-5">
        <div className="col-md-12">
          <div className="task-info-heading">
            <h4>Screening Questions</h4>
          </div>
          <div className="task-info-lists">
            <div className="row">
              <div className="col-md-12 d-flex justify-content-between">
                <p className="task-info-list-span me-3">
                  Do you or anyone else living in the property smoke?
                  <span className="input-field-imp-star">*</span>
                </p>
                <ConfigProvider
                  theme={{
                    components: {
                      Radio: {
                        colorText: "#344054",
                        colorPrimary: "#EF6B3E",
                        fontFamily: "Montserrat",
                        fontSize: 16,
                      },
                    },
                  }}
                >
                  <Radio.Group
                    onChange={(e) => handleChange('smoke', e.target.value)}
                    value={formValues.smoke}
                    className="screening-questions-options d-flex justify-content-center align-items-center gap-3"
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </ConfigProvider>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 d-flex justify-content-between">
                <p className="task-info-list-span me-3">
                  Have you declared bankruptcy in the last 7 years?
                  <span className="input-field-imp-star">*</span>
                </p>
                <ConfigProvider
                  theme={{
                    components: {
                      Radio: {
                        colorText: "#344054",
                        colorPrimary: "#EF6B3E",
                        fontFamily: "Montserrat",
                        fontSize: 16,
                      },
                    },
                  }}
                >
                  <Radio.Group
                    onChange={(e) => handleChange('bankruptcy', e.target.value)}
                    value={formValues.bankruptcy}
                    className="screening-questions-options d-flex justify-content-center align-items-center gap-3"
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </ConfigProvider>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 d-flex justify-content-between">
                <p className="task-info-list-span me-3">
                  Have you ever been convicted of a felony?
                  <span className="input-field-imp-star">*</span>
                </p>
                <ConfigProvider
                  theme={{
                    components: {
                      Radio: {
                        colorText: "#344054",
                        colorPrimary: "#EF6B3E",
                        fontFamily: "Montserrat",
                        fontSize: 16,
                      },
                    },
                  }}
                >
                  <Radio.Group
                    onChange={(e) => handleChange('felony', e.target.value)}
                    value={formValues.felony}
                    className="screening-questions-options d-flex justify-content-center align-items-center gap-3"
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </ConfigProvider>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 d-flex justify-content-between">
                <p className="task-info-list-span me-3">
                  Have you ever been evicted?
                  <span className="input-field-imp-star">*</span>
                </p>
                <ConfigProvider
                  theme={{
                    components: {
                      Radio: {
                        colorText: "#344054",
                        colorPrimary: "#EF6B3E",
                        fontFamily: "Montserrat",
                        fontSize: 16,
                      },
                    },
                  }}
                >
                  <Radio.Group
                    onChange={(e) => handleChange('eviction', e.target.value)}
                    value={formValues.eviction}
                    className="screening-questions-options d-flex justify-content-center align-items-center gap-3"
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </ConfigProvider>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 d-flex justify-content-between">
                <p className="task-info-list-span me-3">
                  Have you ever refused to pay rent when due?
                  <span className="input-field-imp-star">*</span>
                </p>
                <ConfigProvider
                  theme={{
                    components: {
                      Radio: {
                        colorText: "#344054",
                        colorPrimary: "#EF6B3E",
                        fontFamily: "Montserrat",
                        fontSize: 16,
                      },
                    },
                  }}
                >
                  <Radio.Group
                    onChange={(e) => handleChange('rentRefusal', e.target.value)}
                    value={formValues.rentRefusal}
                    className="screening-questions-options d-flex justify-content-center align-items-center gap-3"
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </ConfigProvider>
              </div>
            </div>
            <div className="stepper-btn d-flex justify-content-between mt-5 gap-1">
        <div style={{ width: "50%" }}>
          <SubmitButton type="contained" handler={handlePrev}>
            Back
          </SubmitButton>
        </div>
        <div style={{ width: "50%" }}>
          <SubmitButton handler={handleNextQuestions}>Next</SubmitButton>
        </div>
      </div>
          </div>
    
        </div>
      </div>
    </div>
  );
}

export default ScreeningQuestions;
