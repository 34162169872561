import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import MileageView from "Components/MileageView/MileageView";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const MilageDetail = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Mileage "}
        back="mileage"
      />
      <MileageView />
    </div>
  );
};

export default MilageDetail;
