import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TenantBoxIcon from "assets/svg/Vector.png";
import LandLordBoxIcon from "assets/svg/fi_11558245.png";
import ServiceProBoxIcon from "assets/svg/Vector2.png";
import * as Components from "Components/GeneralComponents";
import AccountSuspensionModal from "Components/GeneralComponents/Modals/AccountSuspensionModal";

const Onboarding = () => {
  const [selectedCard, setSelectedCard] = useState("");

  const navigate = useNavigate();

  const handleChange = (value, path) => {
    setSelectedCard(value);
    setTimeout(() => {
      navigate(path);
    }, 500);
  };

  return (
    <>
      <Components.AuthLayout>
        <div className="onboarding-right-heading text-center mb-5">
          <h1>Welcome to DigitalTenant</h1>
          <p>Transforming How Landlords and Tenants Connect.</p>
        </div>
        <Components.OnboardingCard
          title={"I am a Landlord"}
          description={"I own or manage rental properties."}
          icon={LandLordBoxIcon}
          handler={() => handleChange("landlord", "/landlord/login")}
          isActive={selectedCard === "landlord"}
        />
        <Components.OnboardingCard
          title={"I am a Tenant"}
          description={"I rent a home, apartment or space."}
          icon={TenantBoxIcon}
          handler={() => handleChange("tenant", "/tenant/login")}
          isActive={selectedCard === "tenant"}
        />
        <Components.OnboardingCard
          title={"I am a Service Professional"}
          description={"I provide valuable services to landlords and tenants."}
          icon={ServiceProBoxIcon}
          handler={() =>
            handleChange("service-provider", "/service-professionals/login")
          }
          isActive={selectedCard === "service-provider"}
        />
      </Components.AuthLayout>
    </>
    
  );
};

export default Onboarding;
