import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import EditVendorDetailsModal from "Modals/EditVendorsDetailsModal/EditVendorsDetailsModal";
import React from "react";
import { useParams } from "react-router-dom";

const VendorsEdit = () => {
  const { id } = useParams();
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Edit Vendor Details"}
        back="all-vendor"
      />
      <EditVendorDetailsModal id={id} />
    </div>
  );
};

export default VendorsEdit;
