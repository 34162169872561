import React from "react";
import * as Component from "Components/GeneralComponents";

const SaveModalButton = ({ loader, addTask }) => {
  return (
    <>
      <div onClick={addTask} className="modal-save-task-btn">
        Save {loader && <Component.SpinnerLoader />}
      </div>
    </>
  );
};

export default SaveModalButton;
