import { useEffect, useMemo, useState } from "react";
import { ConfigProvider, Select, message } from "antd";
// import chevronIcon from "assets/chevron-down.png";
// import uploadIcon from "assets/upload-cloud-02.png";
import infoIcon from "assets/info.png";
import { useNavigate } from "react-router-dom";
import ProfileUploader from "Components/ProfileUploader/ProfileUploader";
// import config from "Helpers/config";
// import UseGetHook from "Hooks/UseGetHook";
import UseUpdateHook from "Hooks/UseUpdateHook";
import ModalLoader from "Components/GeneralComponents/ModalLoader";
import * as Component from "Components/GeneralComponents";
import { getUserRole, getLandlordUserbyid } from "network/landlord/setting";
import { userformvalidationsettings } from "utils/FormDataStructure";
import { usaStates } from "utils/dropdownData";
import { userformrolevalidation } from "utils/validations";
const SettingAddUserDetails = ({ id, onClose, setIsRerender }) => {
  // States start
  // const [file, setFile] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [imageUrl, setImageUrl] = useState();
  // const [formattedNumber, setFormattedNumber] = useState("");
  // const [phone, setphone] = useState("");
  // const [DeletedImages, setDeletedImages] = useState([]);
  const [ProfileImages, setProfileImages] = useState([]);
  const [loader, setLoader] = useState(false);
  const [role, setRole] = useState([]);
  const [errors, setErrors] = useState({});

  // const { FetchEditUserRole, role } = UseGetHook("getAllUserRole");
  const [form, setForm] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    addressLine_1: "",
    city: "",
    phone: "",
    country: "",
    state: "",
    zipcode: "",
    role: "",
  });

  useEffect(() => {
    getLandlordUserbyid(id).then((res) => {
      if (res.apiCallStatus === "success") {
        setForm({
          firstName: res.message.firstName,
          middleName: res.message.middleName,
          lastName: res.message.lastName,
          email: res.message.email,
          addressLine_1: res.message.address?.address_line_1,
          city: res.message.address?.city,
          phone: res.message.phone,
          country: "USA",
          state: res.message.address?.state,
          role: "",
        });
      }
    });
  }, []);

  useEffect(() => {
    getUserRole().then((res) => {
      if (res.apiCallStatus === "success") {
        setRole(res.message);
      }
    });

    // FetchEditUserRole();
    // FetchLandlordUsers()
  }, []);

  const handleFormChange = (fieldName, value) => {
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  const formvalidation = () => {
    const formdata = userformvalidationsettings(form, ProfileImages);
    UseUpdateHook("user", id, formdata, onClose, "", setIsRerender, setLoader);
  };

  const EditUser = () => {
    const newerrors = userformrolevalidation(form);
    setErrors(newerrors);
    if (Object.keys(newerrors).length === 0) {
      formvalidation();
      setLoader(true);
    }
  };
  return (
    <>
      <div className="modal-content-main-section p-41">
        <div className="row mt-3">
          <div className="col-md-3 d-flex flex-column">
            <span className="upload-photo-text">User Photo</span>
            <ProfileUploader
              setProfileImages={setProfileImages}
              ProfileImages={ProfileImages}
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-4">
            <Component.InputField
              name={"firstName"}
              required={true}
              label={"First Name"}
              placeholder={"First Name"}
              value={form.firstName}
              handler={(e) => handleFormChange("firstName", e.target.value)}
              errorMessage={errors.firstName}
            />
          </div>
          <div className="col-md-4">
            <Component.InputField
              name={"middleName"}
              required={true}
              label={"Middle Name"}
              placeholder={"Middle Name"}
              value={form.middleName}
              handler={(e) => handleFormChange("middleName", e.target.value)}
              errorMessage={errors.middleName}
            />
          </div>
          <div className="col-md-4">
            <Component.InputField
              name={"lastName"}
              required={true}
              label={"Last Name"}
              placeholder={"Last Name"}
              value={form.lastName}
              handler={(e) => handleFormChange("lastName", e.target.value)}
              errorMessage={errors.lastName}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <Component.InputField
              name={"email"}
              required={true}
              label={"Email"}
              placeholder={"Email"}
              value={form.email}
              handler={(e) => handleFormChange("email", e.target.value)}
              errorMessage={errors.email}
            />
          </div>
          <div className="col-md-6">
            <Component.PhoneNumberField
              name={"Phone No"}
              required={true}
              label={"Phone No"}
              placeholder={"Phone No"}
              value={form.phone}
              handler={(value) => handleFormChange("phone", value)}
              errorMessage={errors.phone}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <Component.InputField
              name={"Address Line 1"}
              required={true}
              label={"Address Line 1"}
              placeholder={"Address Line 1"}
              value={form.addressLine_1}
              handler={(e) => handleFormChange("addressLine_1", e.target.value)}
              errorMessage={errors.addressLine_1}
            />
          </div>
          <div className="col-md-6">
            <Component.InputField
              name={"City"}
              required={true}
              label={"City"}
              placeholder={"City"}
              value={form.city}
              handler={(e) => handleFormChange("city", e.target.value)}
              errorMessage={errors.city}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <Component.SelectField
              name={"State"}
              required={true}
              label={"State"}
              placeholder={"Select State"}
              value={form.state}
              options={usaStates}
              handler={(e) => handleFormChange("state", e)}
              errorMessage={errors.state}
            />
          </div>
          <div className="col-md-6">
            <Component.SelectField
              name={"Country"}
              required={true}
              label={"Country"}
              placeholder={"Select Country"}
              value={"USA"}
              options={[{ name: "USA" }, { name: "Canada" }]}
              handler={(e) => handleFormChange("country", e)}
              // errorMessage={errors.country}
              disabled={true}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <Component.SelectField
              required={true}
              label={"User Role"}
              placeholder={"Select User Role"}
              value={form.role}
              options={role.map((e) => {
                return { value: e._id, label: e.role };
              })}
              handler={(e) => handleFormChange("role", e)}
              errorMessage={errors.role}
            />
          </div>
          <div className="col-md-6 d-flex align-items-center mt-3">
            <span className="property-details-input-title">
              <img src={infoIcon} alt="" className="cursor info-icon me-2" />
              What parts of DigitalTenant they can access.
            </span>
          </div>
        </div>

        {/* <div className="modal-content-footer-section-scroll p-custom"> */}
        <div className="row mt-4">
          {/* <div className="col-md-12 d-flex justify-content-end responsive-direction-column"> */}
          <div className="task-modal-footer-btn">
            <button
              onClick={(e) => {
                EditUser();
              }}
              className="save-btn d-flex justify-content-center gap-3"
            >
              Save {loader && <ModalLoader />}
            </button>
          </div>
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default SettingAddUserDetails;
