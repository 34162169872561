import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import FileUploader from "Components/FileUploader/FileUploader";
import UseGetHook from "Hooks/UseGetHook";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import UnitEditModal from "Modals/UnitEditModal/UnitEditModal";
import config from "Helpers/config.js";

const EditFile = () => {
  const navigate = useNavigate();
  const { id } = UseUrlParamsHook();
  //   const { FetchFileByID, fileData } = UseGetHook("file", id);
  const [Images, setImages] = useState([]);
  const [DeletedImages, setDeletedImages] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [fileName, setFileName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  //   const file = fileData.filter((e) => e.id === id);
  useEffect(() => {
    // FetchFileByID();
    fetch(`${config["baseUrl"]}/api/file/${id}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.apiCallStatus === "success") {
          let temp = [];
          temp = res.message;
          setFileData([temp]);
          console.log(temp, "file Id success");
        } else {
          //console.log(res, 'error')
        }
      });
  }, []);
  const imageArray = useMemo(() => {
    return fileData[0]?.file || [];
  }, [fileData]);
  useEffect(() => {
    if (imageArray.length !== 0 && imageArray.length !== "") {
      setImages((prev) => [...prev, imageArray]);
    } else if (imageArray.length !== 0 && imageArray.length > 1) {
      setImages((prev) => [...prev, ...imageArray]);
    }
    console.log(imageArray, "sss");
  }, [imageArray]);
  const formData = new FormData();

  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };
  
  const editFile = () => {
    const formData = new FormData();
    if (fileName) formData.append("name", fileName);
    Images.forEach((file) => {
      formData.append("file", file);
    });

    fetch(`${config["baseUrl"]}/api/file/${id}`, {
      method: "PUT",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.apiCallStatus === "success") {
          setOpenModal(true);

          console.log(res);
        } else {
          console.log(res);
        }
      });
  };
  return (
    <>
      {openModal === true ? (
        <UnitEditModal
          onClose={onCloseModal}
          route={`all-files`}
          btnTitle="Go to Files"
          message1="File has been updated successfully"
          message2="Check your files."
        />
      ) : (
        ""
      )}
      <div className="container-fluid bg-white p-3">
        <div className="row mt-3">
          <div className="col-md-12">
            <span className="property-details-input-title">
              File Name<span className="sign-up-imp-star">*</span>
            </span>
            <input
              defaultValue={fileData[0]?.name}
              onChange={(e) => setFileName(e.target.value)}
              type="text"
              className="form-control"
              placeholder="File Name"
            />
          </div>
        </div>
        <div className="row mt-3 text-center">
          <div className="col-md-12" style={{ height: "250px" }}>
            <div className="dragger property-images-file-uploader">
              <FileUploader
                setImages={setImages}
                Images={Images}
                setDeletedImages={setDeletedImages}
                DeletedImages={DeletedImages}
              />
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-12 stepper-btn">
            <span className="d-flex justify-content-between">
              <button
                onClick={() => {
                  navigate("/all-files");
                }}
                className="back-prev-btn mt-3"
              >
                Back
              </button>
              <button
                onClick={() => {
                  editFile();
                }}
                className="primary-btn next-btn-same-class mt-3"
              >
                Save{" "}
              </button>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditFile;
