import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import Payment from "Components/Payment/Payment";
import TenantPayment from "Components/Payment/TenantPayment";
import NoAccessPage from "Pages/NoAccessMainPage/NoAccessPage";
import React from "react";

const PaymentPage = () => {
  const role = localStorage.getItem("role");
  const storedRoles = localStorage.getItem("myUserRoles");
  const myUserRoles =
    storedRoles !== "undefined" ? JSON.parse(storedRoles) : [];
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar topBarTitle="Payments" />
      {!["Owner/Executive", "Property Manager", "Accounting/Finance"].some(
        (role) => myUserRoles?.includes(role)
      ) && myUserRoles?.length !== 0 ? (
        <NoAccessPage />
      ) : role === "tenant" ? (
        <TenantPayment />
      ) : (
        <Payment />
      )}
    </div>
  );
};

export default PaymentPage;
