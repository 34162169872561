import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import AddTenantDetails from "Components/AddTenantDetails/AddTenantDetails";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";

const AddTenantDetailsPage = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail="Add Tenant Details"
        pop={true}
      />
      <AddTenantDetails />
    </div>
  );
};

export default AddTenantDetailsPage;
