import React, { useEffect, useState } from "react";
import * as API from "network/landlord/lease";
import * as Component from "Components/GeneralComponents";

const PetsInfoTab = ({ id }) => {
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [petsinfo, setPetsinfo] = useState([]);

  useEffect(() => {
    setIsTableLoading(true);
    API.getLeaseById(id, { tab: "pets" }).then((res) => {
      if (res.apiCallStatus === "success") {
        setPetsinfo(
          res.message.data?.map((e) => ({
            pettype: e.pet_type ? e.pet_type : "--",
            petname: e.name ? e.name : "--",
            key: e._id ? e._id : "--",
            breed: e.breed ? e.breed : "--",
            weight: e.weight ? e.weight : "--",
          }))
        );
        setIsTableLoading(false);
      }
    });
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "petname",
      render: (text) => <span className="tenants_table_name_text">{text}</span>,
    },
    {
      title: "Pet Type",
      dataIndex: "pettype",
      render: (text) => (
        <>
          <span className="tenants_table_properties_text">{text}</span>
        </>
      ),
    },
    {
      title: "Breed",
      dataIndex: "breed",
      render: (text) => (
        <>
          <span className="tenants_table_email_text">{text}</span>
        </>
      ),
    },
    {
      title: "Weight",
      dataIndex: "weight",
      render: (text) => (
        <>
          <span className="tenants_table_email_text">{text}</span>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="container-fluid bg-white">
        <div className="row">
          <div className="col-md-12">
            <Component.CustomTable
              loading={isTableLoading}
              pagination={true}
              columns={columns}
              dataSource={petsinfo}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PetsInfoTab;
