import React from "react";

const ComercialIcon = ({ handleIconClickImages, selectedIcon }) => {
  return (
    <>
      <svg
        className=""
        onClick={() => handleIconClickImages("commercial")}
        width={27}
        height={27}
        fill="none"
        stroke={selectedIcon === "commercial" ? "#EF6B3E" : "#D0D5DD"}
        background-color="#EF6B3E29"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* <path d="M8.25 19.5v3" /> */}
        <path d="M3.75 1.5h9a1.5 1.5 0 0 1 1.5 1.5v19.313a.188.188 0 0 1-.188.187H2.25V3a1.5 1.5 0 0 1 1.5-1.5v0Z" />
        <path d="M15 9h5.25a1.5 1.5 0 0 1 1.5 1.5v12h-7.5V9.75A.75.75 0 0 1 15 9Z" />
        <path
          fill="currentColor"
          stroke={selectedIcon === "multi_famly" ? "active-property" : ""}
          // d="M4.597 20.244a.75.75 0 1 1-.194-1.487.75.75 0 0 1 .194 1.487Z"
        />
      </svg>
    </>
  );
};

export default ComercialIcon;
