import React from "react";
import { Radio, ConfigProvider } from "antd";

const RadioGroupField = ({
  value,
  handler,
  options,
  direction = "column",
  gap = "3",
  errorMessage,
}) => {
  return (
    <>
      <Radio.Group
        onChange={(e) => handler(e.target.value)}
        value={value}
        className={`d-flex flex-${direction} gap-${gap}`}
      >
        {options?.map((option, index) => (
          <ConfigProvider
            key={index}
            theme={{
              components: {
                Radio: {
                  colorPrimary: "#EF6B3E",
                  radioSize: 18,
                  fontSize: 16,
                  fontFamily: "Montserrat",
                },
              },
            }}
          >
            <Radio value={option.value}>{option.label}</Radio>
          </ConfigProvider>
        ))}
      </Radio.Group>
      {errorMessage && (
        <div className="text-danger fw-bold mt-2" style={{ fontSize: "12px" }}>
          {errorMessage}
        </div>
      )}
    </>
  );
};

export default RadioGroupField;
