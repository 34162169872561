import React from "react";
import { ConfigProvider, Switch } from "antd";

const PricingToggle = ({ interval, setInterval, switchColor = "#EF6B3E" }) => {
  return (
    <div className="d-flex flex-row justify-content-center align-items-center">
      <span
        style={{
          fontFamily: "Montserrat",
          fontSize: "14px",
          fontWeight: "500",
          color: "#344054",
          marginRight: "15px",
        }}
      >
        Monthly
      </span>
      <div>
        <ConfigProvider
          theme={{
            components: {
              Switch: {
                colorPrimary: switchColor,
                colorPrimaryHover: switchColor,
                fontSize: 18,
              },
            },
          }}
        >
          <Switch
            size="large"
            checked={interval === "Yearly"}
            onChange={(checked) => {
              setInterval(checked ? "Yearly" : "Monthly");
            }}
          />
        </ConfigProvider>
      </div>
      <span
        style={{
          fontFamily: "Montserrat",
          fontSize: "14px",
          fontWeight: "500",
          color: "#344054",
          marginLeft: "15px",
        }}
      >
        Annually
      </span>
    </div>
  );
};

export default PricingToggle;
