import axios from "axios";
import config from "Helpers/config.js";

const getNotifications = async () => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/notifications`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const markAllAsRead = async () => {
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/notifications/markallasread`,
      {},
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const markNotificationAsRead = async (notificationId) => {
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/notifications/${notificationId}`,
      {},
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export { getNotifications, markAllAsRead, markNotificationAsRead };
