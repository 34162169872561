import React, { useState, useEffect, useRef } from "react";
import chevronIcon from "assets/chevron-down.png";
import personIcon from "assets/person.jpg";
import { PersonItemCard, ProfileAndButtonCard } from ".";
import "style/selectField.css";

const SelectVendorField = ({
  label,
  placeholder,
  isRequired,
  VendorData,
  errorMessage,
  onVendorChange,
  selectedVendors,
}) => {
  const [VendorSearch, setVendorSearch] = useState("");
  const [VendorDropdown, setVendorDropdown] = useState(false);
  const [vendor, setVendor] = useState([]);
  const [isLandlord, setIsLandlord] = useState(false);
  const dropdownRef = useRef(null);

  const handleAddVendor = (e) => {
    setVendor((prevVendors) => [...prevVendors, e]);
    // console.log("Id ", e);
    // onVendorChange(vendor);
    console.log(vendor.length);
  };

  const handleRemoveVendor = (e) => {
    setVendor((prevVendors) => prevVendors.filter((v) => v.id !== e.id));
    // onVendorChange(vendor);
    console.log(vendor.length);
  };

  useEffect(() => {
    if (selectedVendors) {
      selectedVendors.forEach((v) => {
        handleAddVendor({ id: v._id, type: v.type });
      });
    }
  }, [selectedVendors]);

  useEffect(() => {
    onVendorChange(vendor);
  }, [vendor]);

  useEffect(() => {
    const landlord = localStorage.getItem("isLandlord");
    if (landlord) {
      setIsLandlord(JSON.parse(landlord));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("isLandlord", isLandlord);
  }, [isLandlord]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setVendorDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="row mt-3">
      <div className="col-md-12 text-start">
        <span className="tenant-personal-details-title">
          {label}{" "}
          <span className="sign-up-imp-star">{`${isRequired ? "*" : ""}`}</span>
        </span>
        <div
          className="task-vendor-search-container position-relative"
          ref={dropdownRef}
        >
          <input
            onChange={(e) => setVendorSearch(e.target.value)}
            onFocus={() => setVendorDropdown(true)}
            placeholder={placeholder}
            className={`${errorMessage ? "error-input" : "form-control"}`}
            type="text"
          />
          {errorMessage && (
            <span
              className="text-danger fw-bold mt-2"
              style={{ fontSize: "12px" }}
            >
              {errorMessage}
            </span>
          )}
          <span
            onClick={() => {
              setVendorDropdown(!VendorDropdown);
            }}
            className="multi-chevron cursor d-flex justify-content-center align-items-center"
            // style={{
            //   backgroundColor: "#000",
            //   height: "100%",
            //   width: "40px",
            // }}
          >
            <img src={chevronIcon} alt="" />
          </span>
          {VendorDropdown ? (
            <div className="my-dropdown" style={{ maxHeight: "239px" }}>
              <div>
                {VendorData.length > 0 &&
                  VendorData.filter((e) =>
                    e.name.toLowerCase().includes(VendorSearch.toLowerCase())
                  ).map((item, index) => (
                    <div key={item._id}>
                      <ProfileAndButtonCard
                        imgSrc={item.profileImage || personIcon}
                        name={item.name}
                        buttonText={
                          vendor.some((v) => v.id === item?._id)
                            ? "Remove"
                            : "Add"
                        }
                        buttonHandler={() =>
                          vendor.some((v) => v.id === item?._id)
                            ? handleRemoveVendor({
                                id: item?._id,
                                type: item.type,
                              })
                            : handleAddVendor({
                                id: item?._id,
                                type: item.type,
                              })
                        }
                      />
                    </div>
                  ))}
              </div>
            </div>
          ) : null}
        </div>
        {/* {console.log("VendorData", VendorData)} */}
        {vendor.length > 0 && (
          <div
            className="selected-vendor-container d-flex gap-3 mt-3 align-items-center"
            style={{ overflowX: "auto" }}
          >
            {VendorData?.filter((e) => vendor.some((v) => v.id === e._id)).map(
              (item) => (
                <div key={item._id}>
                  <PersonItemCard
                    imgSrc={item.profileImage || personIcon}
                    name={item.name}
                  />
                </div>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectVendorField;
