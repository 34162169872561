import axios from "axios";
import config from "Helpers/config.js";

const getallTasks = async (property, unit) => {
  const query = property ? `?property=${property}` : "";
  const unitQuery = unit ? `&unit=${unit}` : "";
  try {
    const response = await axios.get(`${config.baseUrl}/api/tenant/tasks${query}${unitQuery}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getTaskById = async (id) => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/tenant/tasks/${id}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateTask = async (id, data) => {
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/tenant/tasks/${id}`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};


const createnewtask = async (data) => {
  try {
    const response = await axios.post(`${config.baseUrl}/api/tenant/tasks`, data, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};





export { getallTasks, getTaskById, updateTask,createnewtask };
