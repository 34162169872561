import TenantPropertyUnitsView from "Components/TenantPropertyUnitsView/TenantPropertyUnitsView";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";

const UnitsDetail = () => {
  const { property_id } = UseUrlParamsHook();
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Unit Details"}
        back={`tenant-property-details-view?id=${property_id}`}
      />
      <TenantPropertyUnitsView />
    </div>
  );
};

export default UnitsDetail;
