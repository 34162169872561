import React from "react";
import validateError from "assets/alert-circle-validate.png";
import { Tooltip } from "antd";
import helpIcon from "assets/help-circle.png";

const InvoiceNoField = ({
  name,
  required = false,
  type = "text",
  label = "",
  placeholder,
  value,
  onClick,
  onBlur,
  onChange,
  isError = false,
  errorMessage = false,
  disabled,
  pattern,
  TooltipTitle,
}) => {
  return (
    <div className="position-relative">
      {label && (
        <span className="tenant-personal-details-title">
          {label}{" "}
          <span className="sign-up-imp-star">{`${required ? "*" : ""}`}</span>
          {TooltipTitle && (
            <Tooltip title={TooltipTitle}>
              <img src={helpIcon} alt="" className="cursor ps-2" />
            </Tooltip>
          )}
        </span>
      )}
      <div style={{ position: "relative" }}>
        <input
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          onClick={onClick}
          onBlur={onBlur}
          className={`${
            isError || errorMessage ? "error-input" : "form-control"
          } ${type === "search" && "search-form-control-task"}`}
          placeholder={placeholder}
          disabled={disabled}
          pattern={pattern}
        />
        {isError && (
          <div className="validate-icon1">
            <img src={validateError} alt="" />
          </div>
        )}
      </div>
      {errorMessage && (
        <div className="text-danger fw-bold mt-2" style={{ fontSize: "12px" }}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default InvoiceNoField;
