import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import TenantPassportEditProfile from "Components/TenantPassportEditProfile/TenantPassportEditProfile";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const PassportEditProfile = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar topBarTitle={"Tenant Passport"} />
      <TenantPassportEditProfile />
    </div>
  );
};

export default PassportEditProfile;
