import React, { useEffect, useState } from "react";
import { recordInvoicePayment } from "network/landlord/Account";
import { message } from "antd";
import { SubmitButton, DateField, SelectField, TextAreaField, InputField } from "Components/GeneralComponents";

const RecordPayment = (props) => {
  const [form, setForm] = useState({
    paymentAmount: "",
    paymentDate: new Date().toISOString().split('T')[0],
    paymentMethod: "Bank Transfer",
    notes: "",
  });
  const [message, setMessage] = useState(null);
  const [displayInvoiceNo, setDisplayInvoiceNo] = useState("");
  const { invoiceNo, handleClose } = props;

  useEffect(() => {
    // Extract the invoice number substring from invoiceNo
    let display_invoice_no = invoiceNo.substring(invoiceNo.indexOf("INV"));
    setDisplayInvoiceNo(display_invoice_no);
  }, [invoiceNo]);

  const handleChange = (e) => {
    console.log(e);
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleBankAccountChange = (e) => {
    console.log(e);
    setForm((prevForm) => ({
     ...prevForm,
     paymentMethod: e,
    }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(form);

    recordInvoicePayment(invoiceNo)
      .then((res) => {
        console.log(res);
        setForm({
          paymentAmount: "",
          paymentDate: new Date().toISOString().split('T')[0],
          paymentMethod: "Bank Transfer",
          notes: "",
        });
        setMessage("Payment recorded successfully");
        message.success("Payment recorded successfully!");
      })
      .catch((e) => {
        setMessage("Failed to record payment!");
        message.error("Failed to record payment!");
        console.error(e);
      });
  };

  return (
    <>
      {message ? (
        <div className="alert d-flex align-items-center shadow-sm mt-3">
          <h5 className="mb-0">{message}</h5>
        </div>
      ) : (
        <div className="container ">
          <div className="card border-0 shadow-sm p-4">
            <form onSubmit={handleSubmit}>
              <div className="row mb-2">
                {/* Invoice No and Amount Due */}
                <div
                  className="col-md-6 mb-3"
                  style={{
                    fontSize: '14px',
                    fontWeight: '400px', // Customize font style for invoice number and amount due
                  }}
                >
                  <label className="form-label">Invoice No</label>
                  <p className="form-control-plaintext">{displayInvoiceNo}</p>
                </div>
                <div
                  className="col-md-6 mb-3"
                  style={{
                    fontSize: '14px',
                    fontWeight: '400px', // Customize font style for invoice number and amount due
                  }}
                >
                  <label className="form-label">Amount Due</label>
                  <p className="form-control-plaintext">$600</p> {/* Replace with actual amount */}
                </div>
              </div>

              <div className="row mb-2">
                {/* Payment Method and Payment Date */}
                <div className="col-md-6 mb-3">
                  <SelectField
                    label="Payment Method"
                    name="paymentMethod"
                    value={form.paymentMethod}
                    handler={handleBankAccountChange}
                    options={[
                      { value: "Bank Transfer", label: "Bank Transfer" },
                      { value: "Credit Card", label: "Credit Card" },
                      { value: "Cash", label: "Cash" },
                    ]}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <DateField
                    name="paymentDate"
                    label="Payment Date"
                    value={form.paymentDate}
                    disabled={true}
                    handler={handleChange}
                    placeholder={new Date().toISOString().split('T')[0]}
                  />
                </div>
              </div>

              <div className="mb-3">
                <TextAreaField
                  label="Notes"
                  name="notes"
                  value={form.notes}
                  handler={handleChange}
                  placeholder="Enter any additional notes"
                  rows={3}
                />
              </div>

              <SubmitButton text="Record Payment" handler={handleSubmit}>
                Record Payment
              </SubmitButton>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default RecordPayment;
