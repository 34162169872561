import React, { useState, useEffect } from "react";
import settingIconOrange from "assets/dots-vertical.png";
import { useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import { OverviewTab, FilesTab, NotesTab } from "./index";
import { CustomTabs, DeleteModal, RecordMenuPopup } from "Components/GeneralComponents";
import { deleteProspect } from "network/landlord/prospect";

const ProspectDetails = () => {
  const { id, tab } = UseUrlParamsHook();
  const navigate = useNavigate();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const items = [
    {key: "1", label: "Overview", children: <OverviewTab id={id} />},
    {key: "2", label: "Notes", children: <NotesTab id={id} category="prospect" />},
    {key: "3", label: "Files", children: <FilesTab id={id} category="prospect" />},
  ]

  useEffect(() => {
    setActiveTab(tab || "1");
  }, [tab]);

  const onChange = (key) => {
    setActiveTab(key);
    searchParams.set("tab", key);
    navigate({ search: searchParams.toString() });
  };

  // Delete Prospect
  const handleDeleteProspect = () => {
    setIsLoading(true);
    deleteProspect(id).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        message.success("Prospect Deleted Successfully");
        setOpenDeleteModal(false);
        navigate("/all-prospect");
      } else {
        message.error(res.message);
      }
    });
  };

  const handleIconClickCustom = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      {/* Delete Prospect Model */}
      <DeleteModal
        isOpen={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        message={"Are you sure you want to delete this prospect?"}
        deleteCall={handleDeleteProspect}
        isLoading={isLoading}
      />

      <div className="container-fluid bg-white p-3">
        <div className="row">
          <div className="col-md-12 text-end">
            <span className="position-relative">
              <img
                onClick={handleIconClickCustom}
                src={settingIconOrange}
                alt="Action Buttons"
                className="cursor"
              />
              <RecordMenuPopup
                isOpen={isDropdownOpen}
                onClose={() => setDropdownOpen(false)}
                fields={{ edit: true, delete: true }}
                handler={{ 
                  handleDeleteRecord: () => setOpenDeleteModal(true),
                  handleEdit: () => navigate(`/edit-prospect-details/${id}`),
                }}
              />
            </span>
          </div>
        </div>
        {activeTab && <CustomTabs
          items={items}
          defaultActiveKey={activeTab}
          onChange={onChange}
        />}
      </div>
    </>
  );
};

export default ProspectDetails;
