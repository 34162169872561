import React, { useState } from "react";
import * as Icon from "assets/icons";
import { Tooltip } from "antd";
import {
  SelectField,
  SubmitButton,
  Checkbox,
  NumberRangeSelector,
} from "Components/GeneralComponents";
import { selectactiveinactivearchive, usaStates } from "utils/dropdownData";

const PropertyFilter = ({ hideFilter, onApply }) => {
  const [filter, setFilter] = useState({
    propertyStatus: "",
    state: "",
    propertyType: "",
    bedrooms: {},
    bathrooms: {},
    rentAmountMin: 1,
    rentAmountMax: 6000,
  });

  const [selectedIcon, setSelectedIcon] = useState("");
  const [sliderValue, setSliderValue] = useState([1, 6000]);
  const [isDisabled, setIsDisabled] = useState(true);

  const beds = ["2", "3", "4", "5"];
  const bath = ["2", "3", "4", "5"];

  // Range slider value change
  const rangeHandleChange = (value) => {
    setIsDisabled(false);
    setSliderValue(value);
    setFilter((prev) => ({
      ...prev,
      rentAmountMin: value[0],
      rentAmountMax: value[1],
    }));
  };

  const handleChange = (fieldName, value) => {
    setIsDisabled(false);
    setFilter((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleIconClickImages = (type) => {
    setIsDisabled(false);
    setSelectedIcon(type);
    handleChange("propertyType", type);
  };

  const handleCheckboxChange = (e, field, label) => {
    setIsDisabled(false);
    console.log("e.target.checked", e.target.checked);
    setFilter((prev) => ({
      ...prev,
      [field]: {
        ...prev[field],
        [label]: e.target.checked,
      },
    }));
  };

  // Apply filter logic
  const handlefilter = () => {
    setIsDisabled(false);

    // Convert bedrooms and bathrooms to comma-separated string for API
    const selectedBedrooms = Object.keys(filter.bedrooms)
      .filter((key) => filter.bedrooms[key])
      .join(",");
    const selectedBathrooms = Object.keys(filter.bathrooms)
      .filter((key) => filter.bathrooms[key])
      .join(",");

    const finalFilter = {
      ...filter,
      bedrooms: selectedBedrooms,
      bathrooms: selectedBathrooms,
    };

    onApply(finalFilter);
    // Call your backend API with finalFilter data as query params
  };

  const handlereset = () => {
    setFilter({
      propertyStatus: "",
      state: "",
      propertyType: "",
      bedrooms: {},
      bathrooms: {},
      rentAmountMin: "",
      rentAmountMax: "",
    });
    setSliderValue([1, 6000]);
    setSelectedIcon("");
    onApply({
      propertyStatus: "",
      state: "",
      propertyType: "",
      bedrooms: "",
      bathrooms: "",
      rentAmountMin: "",
      rentAmountMax: "",
    });
    setIsDisabled(true);
  };

  return (
    <>
      <div
        className={
          hideFilter === true
            ? "col-md-3 d-flex flex-column filter-main-section p-2 w-100"
            : "d-none"
        }
      >
        <div className="filter-dropdown-section">
          <span className="filter-sub-title">Filter By</span>
        </div>
        <div className="row mt-3">
          <SelectField
            name="propertyStatus"
            required={false}
            label="Property Status"
            placeholder="Property Status"
            value={filter.propertyStatus}
            options={selectactiveinactivearchive}
            handler={(e) => handleChange("propertyStatus", e)}
            errorMessage=""
          />
        </div>
        <div className="row mt-3">
          <SelectField
            name="state"
            required={false}
            label="State"
            placeholder="Select State"
            value={filter.state}
            options={usaStates}
            handler={(e) => handleChange("state", e)}
            errorMessage=""
          />
        </div>
        <div className="location-dropdown-section mt-3">
          <span className="filter-sub-title">Property Type</span>
          <br />
          <div className="icon-main-apan">
            <Tooltip title="Residential">
              <span
                className={
                  selectedIcon === "single"
                    ? "active-property property-type-icons p-2 cursor"
                    : "property-type-icons p-2 cursor"
                }
              >
                <Icon.ResdentialIcon
                  handleIconClickImages={handleIconClickImages}
                  selectedIcon={selectedIcon}
                />
              </span>
            </Tooltip>

            <Tooltip title="Commercial">
              <span
                className={
                  selectedIcon === "multi_famly"
                    ? "active-property property-type-icons p-2 cursor"
                    : "property-type-icons p-2 cursor"
                }
              >
                <Icon.ComerecialIcon
                  handleIconClickImages={handleIconClickImages}
                  selectedIcon={selectedIcon}
                />
              </span>
            </Tooltip>
          </div>
        </div>

        <span className="filter-sub-title mt-3">Bedroom</span>
        <div className="location-dropdown-section row">
          {beds.map((bed, index) => (
            <div className="d-flex align-items-center col-md-6" key={index}>
              <Checkbox
                label={bed + " Bedrooms"}
                onChange={(e) => handleCheckboxChange(e, "bedrooms", bed)}
                checked={filter.bedrooms?.[bed] || false}
              />
            </div>
          ))}
        </div>

        <div className="location-dropdown-section mt-3 overflow-auto">
          <span className="filter-sub-title">Rent Amount Range</span>
          <div className="mx-1">
            <NumberRangeSelector
              rangeHandleChange={rangeHandleChange}
              sliderValue={sliderValue}
              handleInputChange={(index, e) =>
                handleChange(
                  ["rentAmountMin", "rentAmountMax"][index],
                  Number(e.target.value)
                )
              }
            />
          </div>
        </div>

        <span className="filter-sub-title mt-3">Bathroom</span>
        <div className="location-dropdown-section row">
          {bath.map((bath, index) => (
            <div className="d-flex align-items-center col-md-6" key={index}>
              <Checkbox
                label={bath + " Bathrooms"}
                onChange={(e) => handleCheckboxChange(e, "bathrooms", bath)}
                checked={filter.bathrooms?.[bath] || false}
              />
            </div>
          ))}
        </div>

        {/* Button container at the bottom */}
        <div className="mt-auto">
          <div className="apply-now-filter-btn mt-5">
            <SubmitButton handler={handlefilter}>Apply Now</SubmitButton>
          </div>

          <div className="apply-now-filter-btn mt-3">
            <button
              onClick={handlereset}
              className={`${
                isDisabled ? "disabledbutton" : "add-new-task-btn"
              }`}
              disabled={isDisabled}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyFilter;
