import React from "react";
import cancelIcon from "assets/x-circle.png";
import alertIcon from "assets/alert-circle.png";

const Warning = ({ isOpen, onClose, message }) => {
  return (
    <>
      {isOpen && (
        <>
          <div className="payment-modal-container">
            <div className="vendor-success-modal position-relative">
              <div className="row">
                <div className="col-md-12 text-center">
                  {/* <DeleteIcon /> */}
                  <img src={alertIcon} alt="" />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12 text-center">
                  <p className="normal-grey-text convert-tenant-modal-text">
                    {message}
                  </p>
                </div>
              </div>
              <div onClick={onClose} className="cancel-modal-icon cursor">
                <img src={cancelIcon} alt="" />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Warning;
