import React, { useState } from "react";
import email from "assets/email.png";
import { SubmitButton } from "..";
import TimerComponent from "./TimerComponent";
import {
  sendVerificationEmail,
  sendVerificationEmailToTenant,
} from "network/landlord/Account";

const VerifyEmailModal = ({ isOpen, onClose, isSkip }) => {
  const [isCounting, setIsCounting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const role = localStorage.getItem("role");

  const handleVerifyClick = () => {
    setIsLoading(true);
    if (role === "tenant") {
      sendVerificationEmailToTenant().then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          setIsCounting(true);
        }
      });
    } else if (role === "landlord") {
      sendVerificationEmail().then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          setIsCounting(true);
        }
      });
    }
  };

  const handleTimerComplete = () => {
    setIsCounting(false);
  };

  return (
    <>
      {isOpen && (
        <div className="container-fluid d-flex flex-column justify-content-center align-items-center min-vh-100 p-4 overflow-hidden">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-12 text-center">
              <img className="img-fluid mb-5" src={email} alt="Verify Email" />
              <p className="fs-2 fw-bold my-4">Verify your email</p>
              <div className="w-100">
                <p className="fw-semibold fs-5 mt-5">
                  Welcome to Digital Tenant
                </p>
                <p className="mb-4 fs-6 w-75 mx-auto">
                  {isCounting
                    ? "We have sent a verification link to your email address. Please check your inbox and click the link to verify your email address."
                    : "Please click the button below to confirm your email address and activate your account."}
                </p>
              </div>

              {/* Updated responsive button width and text wrap */}
              <div className="d-flex justify-content-center w-100 mb-2">
                <div className="w-25">
                  <SubmitButton
                    size="lg"
                    handler={handleVerifyClick}
                    isLoading={isLoading}
                    disabled={isCounting}
                    className="text-wrap p-3 w-25"
                  >
                    {isCounting ? (
                      <TimerComponent
                        initialTime={120}
                        onComplete={handleTimerComplete}
                      />
                    ) : (
                      "Verify Email"
                    )}
                  </SubmitButton>
                </div>
              </div>

              {isSkip && (
                <div className="d-flex justify-content-center w-100">
                  <button
                    className="btn btn-outline w-25 w-md-50 w-lg-25"
                    onClick={onClose}
                  >
                    Skip
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VerifyEmailModal;
