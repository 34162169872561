import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import EditdraftNewLease from "Components/NewLease/EditdraftNewLease";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const LeasesDraft = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail="Edit Draft Lease"
        back="all-lease"
      />
      <EditdraftNewLease />
    </div>
  );
};

export default LeasesDraft;
