import React from "react";

function SendReminder({ width = 21, height = 21 }) {
  return (
    <>
      <svg 
      width={width}
      height={height}
      fill="none"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6"

      id="line" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
        <path d="m50.36267 12.624-14.73627 6.06838a1.64072 1.64072 0 0 0 -.53326 2.66015l1.63965 1.63965.48242 3.37793a1.638 1.638 0 0 0 1.77148 1.3916l2.292-.22265 2.48531 2.48437a1.63005 1.63005 0 0 0 2.65918-.5332l6.06834-14.73823a1.64119 1.64119 0 0 0 -2.12885-2.128zm-5.57813 15.5918-2.21191-2.2119a1.61667 1.61667 0 0 0 -1.15234-.48047c.06115-.0274-2.252.22211-2.27539.21387l-.44922-3.15039a1.64691 1.64691 0 0 0 -.46094-.9209l-1.335-1.335 13.4043-5.51953z" />
        <path d="m54.89874 45.64624c-1.28253.03057-2.57025-.54694-2.57269-2.00019l-.00005-11.92572c11.55571-7.56524 6.14489-25.68233-7.68739-25.71844a13.927 13.927 0 0 0 -2.785.29706 19.69276 19.69276 0 0 0 -28.92405 17.39929v15.94427a1.00112 1.00112 0 0 0 2.0019-.00006l-.00006-15.94421c-.18396-12.23841 12.73522-21.07965 24.08013-16.50324-15.23153 7.06476-7.98779 29.57831 8.50409 26.54309a1.001 1.001 0 0 0 -.3883-1.96362 12.0191 12.0191 0 0 1 -14.49951-11.75983c.62957-15.91174 23.35138-15.95794 24.022.00013a11.90868 11.90868 0 0 1 -5.76312 10.25537 1.02845 1.02845 0 0 0 -.56256.92859v12.44744a3.91221 3.91221 0 0 0 .54919 1.98182h-18.29538a1.001 1.001 0 0 0 .00006 2.00183h22.12946a2.10166 2.10166 0 0 1 -.26972 4.184l-43.61993-.00006a2.11438 2.11438 0 1 1 .1701-4.224 3.94826 3.94826 0 0 0 3.94349-3.94361 1.001 1.001 0 0 0 -2.00177.00007 1.95941 1.95941 0 0 1 -2.11182 1.94165c-5.41437.11029-5.4314 8.0946.00012 8.22772h14.65418c1.27581 8.23889 13.03106 8.25287 14.3114-.00012l14.65429.00012a4.04082 4.04082 0 0 0 2.90406-1.19837 4.11655 4.11655 0 0 0 -2.44312-6.97098zm-22.27093 12.40326a5.23535 5.23535 0 0 1 -5.13007-4.23389h10.26013a5.23533 5.23533 0 0 1 -5.13006 4.23389z" />
        <path d="m18.95013 23.70336a13.65213 13.65213 0 0 1 6.22266-11.46236 1.00008 1.00008 0 0 0 -1.09279-1.67478 15.64561 15.64561 0 0 0 -7.12982 13.13714 1.00019 1.00019 0 0 0 1.99995 0z" />
        <path d="m10.42425 10.95141c-.80825 1.07715-1.78607 5.22259.19175 5.42932a1.0035 1.0035 0 0 0 .97619-1.2218 4.19453 4.19453 0 0 1 3.12647-5.00238 1.00018 1.00018 0 0 0 .74512-1.20221c-.75522-2.13653-4.46842.78717-5.03953 1.99707z" />
        <path d="m7.60687 15.90405a8.11863 8.11863 0 0 1 6.04645-9.67719 1.00032 1.00032 0 0 0 -.44932-1.94928 10.12974 10.12974 0 0 0 -7.54629 12.07672 1.00043 1.00043 0 0 0 1.94916-.45025z" />
      </svg>
    </>
  );
}

export default SendReminder;
