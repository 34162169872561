import {  useState } from "react";
import { Virtual, Navigation, FreeMode, Thumbs } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import propertyDummyImage from "assets/property-dummy-image.png";
import { Skeleton } from "antd";
import * as Icon from "assets/icons";
import "swiper/css/navigation";

const ImageSwiper = ({ data }) => {
  // console.log("Data >>>>>>>>>",data);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  function parseDateString(dateString) {
    const dateObj = new Date(dateString);
    const day = dateObj.getDate();
    const month = dateObj.getMonth(); // Months are zero-indexed
    const year = dateObj.getFullYear();
  
    return { day, month, year };
  }
  
    let monthNames = {
      0: "Jan",
      1: "Feb",
      2: "Mar",
      3: "Apr",
      4: "May",
      5: "Jun",
      6: "Jul",
      7: "Aug",
      8: "Sep",
      9: "Oct",
      10: "Nov",
      11: "Dec",
    };

  return (
    <>
      <div className="row d-flex justify-left-center mt-5">
      <div className="col-md-7 col-xxl-5 mb-4">
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          spaceBetween={10}
          navigation={false}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper2 rounded"
        >
          {(data?.images?.length || 0) > 0 ? (
            data?.images.map((e, index) => (
              <SwiperSlide key={index}>
                <img alt="" src={`${e}`} />
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide>
              <img alt="" src={propertyDummyImage} />
            </SwiperSlide>
          )}
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          navigation={true}
          watchSlidesProgress={true}
          modules={[Virtual, Thumbs, Navigation]}
          style={{height: "15%" }}
          className="property-detail-view-slider"
        >
          {(data?.images || []).map((e, index) => (
            <SwiperSlide key={index} style={{padding: "0px" , backgroundColor: "transparent " }} className="swiperslideimage" >
              <img alt="" src={`${e}`} className="rounded " style={{  }} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="col-md-5 mt-5">
        <div className="property-details-heading mt-3">
          <p className="property-details-heading-title">
            {data?.title || <Skeleton paragraph={{ rows: 0 }} />}
          </p>
        </div>
        <div className="property-details-view-title-content ">
          <div className="d-flex gap-2 pb-3">
            {data?.address? (
              <>
              <Icon.LocationIcon />
                <span className="property-details-view-title-para">{data.address}</span>
              </>
            ) : (
              <Skeleton paragraph={{ rows: 0 }} />
            )}
          </div>
          <div className="d-flex gap-2">
            <Icon.CalendarIcon />
            <span className="property-details-view-title-para">
              Published on{" "}
              {data?.date ? (
                  (() => {
                    const { day, month, year } = parseDateString(data.date);
                    return `${monthNames[month]} ${day}, ${year}`;
                  })()
                ) : (
                  <>N/A</>
                )}
            </span>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default ImageSwiper;
