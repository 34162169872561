const { SidebarAccounting, SidebarChat, SidebarContacts, SidebarDocumentation,SidebarCustomerQueries, SidebarHome, SidebarProperties, SidebarPassport, SidebarPayment, SidebarTask, MaintenanceAsideIcon, SidebarDashboard } = require('assets/icons');

const role = localStorage.getItem('role');

// Function to check if a section should be locked based on roles
const checkIsLocked = (userRoles = [], allowedRoles = []) => {
  // console.log("userRoles isChecked: ", userRoles);
  if (allowedRoles.length === 0) return false;
  return !allowedRoles.some((role) => userRoles.includes(role));
};

// Utility function to determine if the route should be locked based on user roles
const isRouteLocked = (userRoles, requiredRoles) => {
  return checkIsLocked(userRoles, requiredRoles);
};

// Landlord Routes
const landlordRoutes = (userRoles = []) => [
  {
    key: '1',
    icon: <SidebarDashboard />,
    path: '/dashboard',
    label: 'Dashboard',
    isLocked: isRouteLocked(userRoles, []), // No specific roles required
  },
  {
    key: '2',
    icon: <SidebarProperties />,
    path: '/properties-dashboard',
    label: 'Properties',
    isLocked: isRouteLocked(userRoles, role === "user" ? [
      "Owner/Executive",
      "Leasing Agent",
      "Maintenance Technician",
      "Property Manager",
    ] : []), // Check for user role
  },
  {
    key: '3',
    icon: <SidebarContacts />,
    path: '/contacts',
    label: 'Contacts',
    isLocked: isRouteLocked(userRoles, role === "user" ? [
      "Owner/Executive",
      "Property Manager",
      "Leasing Agent",
    ] : []), // Check for user role
    children: [
      {
        key: '3-1', path: '/all-prospect', label: 'Prospects', isLocked: isRouteLocked(userRoles, role === "user" ? [
          "Owner/Executive",
          "Property Manager",
          "Leasing Agent",
        ] : []), // Check for user role
      },
      {
        key: '3-2', path: '/all-tenants', label: 'Tenants', isLocked: isRouteLocked(userRoles, role === "user" ? [
          "Owner/Executive",
          "Property Manager",
          "Leasing Agent",
        ] : []), // Check for user role
      },
      {
        key: '3-3', path: '/all-vendor', label: 'Vendors', isLocked: isRouteLocked(userRoles, role === "user" ? [
          "Owner/Executive",
          "Property Manager",
        ] : []), // Check for user role
      },
      {
        key: '3-4', path: '/all-service-professional', label: 'Service Professional', isLocked: isRouteLocked(userRoles, role === "user" ? [
          "Owner/Executive",
          "Property Manager",
        ] : []), // Check for user role
      },
    ],
  },
  {
    key: '4',
    icon: <MaintenanceAsideIcon />,
    path: '/maintenance',
    label: 'Maintenance',
    isLocked: isRouteLocked(userRoles, role === "user" ? [
      "Owner/Executive",
      "Property Manager",
      "Maintenance Technician",
    ] : []), // Check for user role
    children: [
      {
        key: '4-1', path: '/all-task', label: 'Tasks', isLocked: isRouteLocked(userRoles, role === "user" ? [
          "Owner/Executive",
          "Property Manager",
          "Maintenance Technician",
        ] : []), // Check for user role
      },
      {
        key: '4-2', path: '/all-work-order', label: 'Work Orders', isLocked: isRouteLocked(userRoles, role === "user" ? [
          "Owner/Executive",
          "Property Manager",
          "Maintenance Technician",
        ] : []), // Check for user role
      },
    ],
  },
  {
    key: '5',
    icon: <SidebarChat />,
    path: '/user-chat',
    label: 'Chat',
    isLocked: isRouteLocked(userRoles, role === "user" ? [
      "Owner/Executive",
      "Property Manager",
      "Maintenance Technician",
      "Leasing Agent",
    ] : []), // Check for user role
  },
  {
    key: '6',
    icon: <SidebarDocumentation />,
    path: '/documentation',
    label: 'Documentation',
    isLocked: isRouteLocked(userRoles, role === "user" ? [
      "Owner/Executive",
      "Property Manager",
      "Leasing Agent",
    ] : []), // Check for user role
    children: [
      {
        key: '6-1', path: '/all-reports/property-reports', label: 'Reports', isLocked: isRouteLocked(userRoles, role === "user" ? [
          "Owner/Executive",
          "Property Manager",
        ] : []), // Check for user role
      },
      {
        key: '6-2', path: '/all-files', label: 'Files', isLocked: isRouteLocked(userRoles, role === "user" ? [
          "Owner/Executive",
          "Property Manager",
        ] : []), // Check for user role
      },
      {
        key: '6-3', path: '/all-lease', label: 'Leases', isLocked: isRouteLocked(userRoles, role === "user" ? [
          "Owner/Executive",
          "Property Manager",
          "Leasing Agent",
        ] : []), // Check for user role
      },
    ],
  },
  {
    key: '7',
    icon: <SidebarAccounting />,
    path: '/accounting',
    label: 'Accounting',
    isLocked: isRouteLocked(userRoles, role === "user" ? [
      "Owner/Executive",
      "Property Manager",
      "Accounting/Finance",
    ] : []), // Check for user role
    children: [
      {
        key: '7-1', path: '/all-accounts', label: 'Bank Accounts', isLocked: isRouteLocked(userRoles, role === "user" ? [
          "Owner/Executive",
          "Property Manager",
          "Accounting/Finance",
        ] : []), // Check for user role
      },
      {
        key: '7-2', path: '/mileage', label: 'Mileage', isLocked: isRouteLocked(userRoles, role === "user" ? [
          "Owner/Executive",
          "Property Manager",
          "Accounting/Finance",
        ] : []), // Check for user role
      },
      {
        key: '7-3', path: '/payment', label: 'Payments', isLocked: isRouteLocked(userRoles, role === "user" ? [
          "Owner/Executive",
          "Property Manager",
          "Accounting/Finance",
        ] : []), // Check for user role
      },
    ],
  },
];

// Tenant Routes
const tenantRoutes = () => [
  {
    key: '1',
    icon: <SidebarDashboard />,
    path: '/dashboard',
    label: 'Dashboard',
  },
  {
    key: '2',
    icon: <SidebarProperties />,
    path: '/tenant-properties',
    label: 'Properties',
  },
  {
    key: '3',
    icon: <SidebarTask />,
    path: '/all-task',
    label: 'Tasks',

  },

  {
    key: '4',
    icon: <SidebarPassport />,
    path: '/tenant-passport',
    label: 'Tenant Passport',

  },
  {
    key: '5',
    icon: <SidebarPayment />,
    path: '/payment',
    label: 'Payments',

  },
  {
    key: '6',
    icon: <SidebarChat />,
    path: '/user-chat',
    label: 'Chat',

  },
];

// Tenant Routes
const serviceProfessionalRoutes = () => [
  {
    key: '1',
    icon: <SidebarDashboard />,
    path: '/dashboard',
    label: 'Dashboard',
  },
  {
    key: '2',
    icon: <SidebarProperties />,
    path: '/maintenance',
    label: 'Maintenance',
    children: [
      {
        key: '2-2',
        path: '/all-task',
        label: 'Tasks',
      },
      {
        key: '2-3',
        path: '/work-orders',
        label: 'Work Orders',
    
      }
    ]
  },

  {
    key: '3',
    icon:<SidebarChat />,
    path: '/contacts',
    label: 'Contacts',
    children: [
      {
        key: '3-1',
        path: '/contacts/landlords',
        label: 'Landlords',
      }
    ]

  },
  {
    key: '4',
    icon: <SidebarPayment />,
    path: '/documentation',
    label: 'Documentation',
    children: [
      {
        key: '4-1',
        path: '/reports',
        label: 'Reports',
      },
      {
        key: '4-2',
        path: '/files',
        label: 'Files',
      }
    ]
  },
  {
    key: '6',
    icon: <SidebarChat />,
    path: '/chat',
    label: 'Chat',

  },
  {
    key: '7',
    icon: <SidebarAccounting />,
    path: '/accounting',
    label: 'Accounting'
  },
  {
    key: '8',
    icon: <SidebarCustomerQueries />,
    path: '/customer-queries',
    label: 'Customer Queries'
  }
];

// Function to return routes based on user role
export const getRoutesForRole = (userRoles = [], role) => {
  // console.log('getRoutesForRole ---------------------------------------------------------------->', userRoles);
  if (role === 'landlord') {
    return landlordRoutes(userRoles);
  } else if (role === 'tenant') {
    return tenantRoutes();
  } else if (role === 'serviceprovider') {
    return serviceProfessionalRoutes()
  }
  return [];
};
