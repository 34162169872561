import React, { useState, useEffect } from "react";
const TimerComponent = ({ initialTime, onComplete }) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  useEffect(() => {
    let timer;
    if (timeLeft > 0) {
      timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    } else if (timeLeft === 0) {
      onComplete();
    }
    return () => clearTimeout(timer);
  }, [timeLeft, onComplete]);

  return <span>{formatTime(timeLeft)}</span>;
};

export default TimerComponent;
