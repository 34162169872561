import { useEffect, useState } from "react";
import config from "Helpers/config.js";
import NotFound from "assets/not-found-img.png";
import trashIconWhite from "assets/trash-icon-white.png";
import {
  fetchAccountProperty,
  postclientproperties,
  Deleteproperty,
  fetchClientSecrets,
} from "network/landlord/Account";

import { useParams } from "react-router-dom";
import * as Component from "Components/GeneralComponents";
import { SetProperty } from "./index";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { message } from "antd";
import { fetchAccountbyId } from "network/landlord/Account";
import Loader from "Helpers/Loader";
const AccountDetails = () => {
  // States start
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [connectStripe, setConnectStripe] = useState(null);
  const [isPayNowModelOpen, setIsPayNowModelOpen] = useState(false);
  const [accounts, setAccounts] = useState(null);
  const [errors, setErrors] = useState({});
  const [property, setProperty] = useState([]);
  const [propertydata, setPropertydata] = useState([]);
  const [isproperty, setIsproperty] = useState(true);
  const [isloading, setIsloading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [onopendeletemodel, setOnOpenDeleteModel] = useState(false);
  const [pid, setPid] = useState("");
  const [modalloader, setModalloader] = useState(false);
  const deleteMsg = "Are you sure you want to delete this property?";
  // States end
  const { id, account_id } = useParams();

  useEffect(() => {
    setIsloading(true);
    fetchAccountbyId(id).then((res) => {
      setAccounts(res.message);
      setUpdate(false);
      setIsloading(false);
      setIsproperty(true);
    });
  }, [update]);

  const propertyfunction = () => {
    setIsproperty(false);
    fetchAccountProperty({ id: localStorage.getItem("user_id") }).then(
      (res) => {
        if (res.apiCallStatus === "success") {
          setPropertydata(
            res.message?.map((e) => ({
              id: e.id,
              heading1: "",
              heading2: e.title,
              heading3: "",
            }))
          );
        }
      }
    );
  };

  const addTask = () => {
    if (property.length === 0) {
      setErrors({ property: "Property is required" });
    } else {
      setLoader(true);
      postclientproperties(account_id, property).then((res) => {
        setLoader(false);
        if (res.apiCallStatus === "success") {
          message.success("Properties added successfully");
          setOpenAccountModal(false);
          setTimeout(() => {
            setUpdate(true);
            setProperty([]);
            setErrors({});
            setLoader(false);
          }, 2000);
        } else {
          message.error(res.error.message);
        }
      });
    }
  };

  const DeleteProperty = (id) => {
    const data = {
      properties: [id],
    };
    setModalloader(true);
    Deleteproperty(data, account_id).then((res) => {
      if (res.apiCallStatus === "success") {
        message.success("Property Removed successfully");
      } else {
        setModalloader(false);
        console.log(res);
      }
      setUpdate(true);
      onCloseDeleteModal();
    });
  };

  const functionfordelete = () => {
    setOnOpenDeleteModel(true);
  };
  const onCloseDeleteModal = () => {
    setOnOpenDeleteModel(false);
    setModalloader(false);
  };

  const onOpenAccountModal = () => {
    setOpenAccountModal(true);
  };
  const onCloseModalWorkOrder = () => {
    setOpenAccountModal(false);
  };
  const onOpenModal = () => {};
  const addAccount = () => {
    setIsPayNowModelOpen(true);
    const fetchClientSecret = async () => {
      const response = await fetchClientSecrets(account_id);
      if (response.apiCallStatus === "success") {
        return response.message.client_secret;
      } else {
        console.log("An error occurred: ", response.error.message);
        return undefined;
      }
    };
    const instance = loadConnectAndInitialize({
      publishableKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
      fetchClientSecret: fetchClientSecret,
    });
    setConnectStripe(instance);
  };
  return (
    <>
      {onopendeletemodel && (
        <Component.DeleteModal
          isOpen={onopendeletemodel}
          onClose={onCloseDeleteModal}
          message={deleteMsg}
          deleteCall={() => DeleteProperty(pid)}
          isLoading={modalloader}
        />
      )}
      {openAccountModal && (
        <Component.FormModalLayout
          title={"Add New Property"}
          isOpen={onOpenModal}
          onClose={onCloseModalWorkOrder}
        >
          {isproperty && propertyfunction()}
          <SetProperty
            propertydata={propertydata}
            property={property}
            setProperty={setProperty}
            errors={errors}
            addTask={addTask}
            loader={loader}
          />
        </Component.FormModalLayout>
      )}
      <div className="container-fluid p-3" style={{height:"auto" , position:"relative"}}>
        <div className="container ">
          <div className="d-flex align-items-center justify-content-between mt-2 flex-wrap">
            <div className="flex-grow-2 mb-3">
              <span className="fw-bold ">OPERATING ACCOUNT</span>
            </div>
            <div className="account-info-btn d-flex align-items-center justify-content-center gap-3 flex-grow-1">
              {accounts?.data?.landlordAccount?.stripeConnected ? (
                <button disabled className="back-prev-btn">
                  Connected
                </button>
              ) : (
                <button onClick={addAccount} className="back-prev-btn">
                  Connect to Bank
                </button>
              )}

              <button
                onClick={onOpenAccountModal}
                className="next-btn-same-class"
              >
                {" "}
                <span>
                  <svg
                    width={21}
                    height={21}
                    fill="#fff"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 5v14" />
                    <path d="M5 12h14" />
                  </svg>
                </span>
                Add New Property
              </button>
            </div>
          </div>
          <div className="row mt-5 h-100">
            {isloading ? (
              <Loader />
            ) : accounts?.data?.properties?.length === 0 ? (
              <div className="d-flex align-items-center flex-column justify-content-center" style={{height:"70vh"}} >
                <img src={NotFound} alt="Not Found" width={80} />
                <span className="text-secondary mt-2 " style={{fontSize :"14px"}}>No Properties Found</span>
              </div>
            ) : (
              accounts?.data?.properties?.map((item) => {
                return (
                  <>
                    <div className="col-md-12 content-border position-relative mt-4">
                      <div
                        style={{ position: "absolute", right: "10px" }}
                        className="table-delete-icon mt-3"
                      >
                        <button
                          onClick={() => {
                            setPid(item.id);
                            functionfordelete();
                          }}
                          className="table-delete-btn next-btn-main"
                        >
                          <img src={trashIconWhite} alt="" />
                          Delete
                        </button>
                      </div>
                      <div className="main-accounting-content mt-3">
                        <div className="account-info-lists">
                          <div className="property-head d-flex">
                            <p>
                              <span className="task-info-list-span me-3">
                                Property:
                              </span>{" "}
                            </p>
                            <p>{item.title}</p>
                          </div>
                          <div className="p-address-head d-flex">
                            <span className="task-info-list-span me-3">
                              Property Address:
                            </span>{" "}
                            <p>{item.address.address_line_1} </p>
                          </div>
                          <div className="status-head d-flex">
                            <p>
                              <span className="task-info-list-span me-3">
                                Status:
                              </span>{" "}
                            </p>
                            <p>Active</p>
                          </div>
                          <div className="description-head d-flex">
                            <p>
                              <span className="task-info-list-span me-3">
                                Description:
                              </span>{" "}
                            </p>
                            <p>{item.description ? item.description : "-"}</p>
                          </div>
                          <div className="description-head d-flex">
                            <p>
                              <span className="task-info-list-span me-3">
                                Rent Amount:
                              </span>{" "}
                            </p>
                            <p>{item.rent_amount}</p>
                          </div>
                          <div className="description-head d-flex">
                            <p>
                              <span className="task-info-list-span me-3">
                                Number of units:
                              </span>{" "}
                            </p>
                            <p>{item.unit}</p>
                          </div>
                        </div>
                        <div className="account-info-lists-content"></div>
                      </div>
                    </div>
                  </>
                );
              })
            )}
          </div>
        </div>
      </div>
      {/* Connect again */}
      <Component.FormModalLayout
        title={"Connect to Bank"}
        isOpen={isPayNowModelOpen}
        onClose={() => setIsPayNowModelOpen(false)}
      >
        <div className="stripemodal">
          <ConnectComponentsProvider connectInstance={connectStripe}>
            <ConnectAccountOnboarding
              onExit={() => {
                message.success("Account added");
                fetch(`${config.baseUrl}/api/stripe/connectConfirm`, {
                  method: "PUT",
                  headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                  body: JSON.stringify({
                    account_id: account_id,
                  }),
                })
                  .then((res) => {
                    return res.json();
                  })
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((e) => console.log(e, "error"));
              }}
            />
          </ConnectComponentsProvider>
        </div>
      </Component.FormModalLayout>
      {/* Connect again */}
    </>
  );
};

export default AccountDetails;
