
import React, { useState, useEffect, useRef } from "react";
import chevronIcon from "assets/chevron-down.png";
import "./styles.css";
import { SimpleCard } from ".";
import NotFound from "assets/not-found-img.png";

const SelectLandlordField = ({
  PropertyData,
  category,
  errorMessage = false,
  label,
  placeholder,
  selectedPropertyId,
  value,
  isRequired,
  onPropertySelect,
  disabled = false,
}) => {
  const [propertySearch, setPropertySearch] = useState("");
  const [propertyDropdown, setPropertyDropdown] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [fieldPlaceholder, setFieldPlaceholder] = useState(
    value ? value : placeholder
  );
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (selectedPropertyId && PropertyData.length > 0) {
      const selected = PropertyData.find((e) => e.id === selectedPropertyId);
      if (selected) {
        setSelectedProperties([selected]);
        onPropertySelect([selected]);
      }
    }
  }, [selectedPropertyId, PropertyData]);

  const handlePropertySelect = (item) => {
    const isAlreadySelected = selectedProperties.some((e) => e.id === item.id);

    if (isAlreadySelected) {
      // Remove if already selected
      const filteredSelection = selectedProperties.filter(
        (e) => e.id !== item.id
      );
      setSelectedProperties(filteredSelection);
      onPropertySelect(filteredSelection);
    } else {
      // Add new selection
      const updatedSelection = [...selectedProperties, item];
      setSelectedProperties(updatedSelection);
      onPropertySelect(updatedSelection);
    }
    // Keep the dropdown open after selection
    setPropertySearch("");
  };

  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setPropertyDropdown(false);
        setPropertySearch("");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Display tag-like structure
  const renderTags = () => {
    if (selectedProperties.length <= 3) {
      return selectedProperties.map((item) => (
        <span key={item.id} className="selected-tag">
          {item.heading2}  {item.heading1}
          <span
            className="remove-tag"
            onClick={(e) => {
              e.stopPropagation();
              handlePropertySelect(item);
            }}
          >
            ✕
          </span>
        </span>
      ));
    } else {
      return (
        <>
          {selectedProperties.slice(0, 3).map((item) => (
            <span key={item.id} className="selected-tag">
              {item.heading2}  {item.heading1}
              <span
                className="remove-tag"
                onClick={(e) => {
                  e.stopPropagation();
                  handlePropertySelect(item);
                }}
              >
                ✕
              </span>
            </span>
          ))}
          <span className="selected-tag">+{selectedProperties.length - 3}</span>
        </>
      );
    }
  };

  return (
    <div className="row mt-3">
      <div className="col-md-12">
        <span className="tenant-personal-details-title">
          {label}
          <span className="sign-up-imp-star">{isRequired ? "*" : ""}</span>
        </span>
        <div className="position-relative selectField" ref={dropdownRef}>
          <div
            className={`tags-container expanded-view ${disabled ? "disabled" : ""}`}
            onClick={() => !disabled && setPropertyDropdown(!propertyDropdown)}
          >
            {selectedProperties.length > 0 ? (
              renderTags()
            ) : (
              <span className="placeholder-text">{fieldPlaceholder}</span>
            )}
            <span className="multi-chevron">
              <img src={chevronIcon} alt="chevron icon" />
            </span>
          </div>
          {propertyDropdown && (
            <div className="my-dropdown p-2"
            >
              <input
                value={propertySearch}
                placeholder="Search properties..."
                onChange={(e) => setPropertySearch(e.target.value)}
                className="form-control mb-2"
                type="text"
                autoFocus
              />
              <div style={{
                zIndex: 99999
              }}>
                {PropertyData.length > 0 ? (
                  PropertyData.filter(
                    (e) =>
                      e.heading1
                        .toLowerCase()
                        .includes(propertySearch?.toLowerCase()) ||
                      e.heading2
                        .toLowerCase()
                        .includes(propertySearch?.toLowerCase()) ||
                      e.heading3
                        .toLowerCase()
                        .includes(propertySearch?.toLowerCase())
                  ).map((item) => (
                    <div
                      key={item.id}
                      className="dropdown-item-container"
                      onClick={() => handlePropertySelect(item)}
                    >
                      <SimpleCard
                        line1={item.heading1}
                        line2={item.heading2}
                        line3={item.heading3}
                        cardColor={
                          selectedProperties.some((e) => e.id === item.id)
                            ? "#ef6b3e29"
                            : ""
                        }
                      />
                    </div>
                  ))
                ) : (
                  <div className="d-flex align-items-center justify-content-center">
                    <div>
                      <div className="not-found-img">
                        <img src={NotFound} alt="Not Found" width={80} />
                      </div>
                      <div className="not-found-text mt-2 text-center">
                        No Data
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectLandlordField;
