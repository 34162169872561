import axios from "axios";
import config from "Helpers/config.js";

const login = async (payload) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/auth/landlord/login`,
      payload,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const signup = async (payload) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/auth/landlord/signup`,
      payload,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const resetUserPassword = async (payload) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/auth/landLord/user/resetpassword`,
      payload,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const googleAuthUrl = async () => {
  window.location.href = `${config.baseUrl}/api/auth/google/landlord`;
};

const landLordGoogleRegister = async (payload) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/auth/landlord/register/google`,
      payload,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export { login, signup, resetUserPassword, googleAuthUrl, landLordGoogleRegister };
