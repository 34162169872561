import { useEffect, useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
// import ConvertTenantModal from "Modals/ConvertTenantModal/ConvertTenantModal";
import TempModal from "Modals/TempModal/TempModal";
import {
  validateProspectPersonalDetails,
  validateEmail,
} from "utils/validations";
import { prospectFormData } from "utils/FormDataStructure";
import {
  verifyProspect,
  createProspect,
  updateProspect,
} from "network/landlord/prospect";
import {
  EmailForm,
  PersonalDetailsForm,
  EmergencyInfoForm,
  PetsForm,
  VehicleForm,
  DependentInfoForm,
} from "./index";
import { Stepper } from "Components/GeneralComponents";
import { addProspectDetails } from "utils/stepsData";

const AddProspectDetails = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState("");
  const [DeletedImages, setDeletedImages] = useState([]);
  const [ProfileImages, setProfileImages] = useState([]);
  const [tenant, setTenant] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedPropertyType, setSelectedPropertyType] = useState("");
  const [form, setForm] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    dob: "",
    addressLine_1: "",
    addressLine_2: "",
    country: "USA",
    city: "",
    state: "",
    zipCode: "",
    property: "",
    unit: "",
    phoneType: "",
    company: "",
    jobTitle: "",
    notes: "",
  });
  const [emergencyContacts, setEmergencyContacts] = useState([
    {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phone: "",
      relationship: "",
      description: "",
    },
  ]);
  const [pets, setPets] = useState([
    {
      petType: "",
      petName: "",
      petWeight: "",
      petBreed: "",
    },
  ]);
  const [vehicles, setVehicles] = useState([
    {
      vehicleType: "",
      vehicleMake: "",
      vehicleModel: "",
      vehicleYear: "",
      vehicleColor: "",
      vehicleLicense: "",
    },
  ]);
  const [dependentInfo, setDependentInfo] = useState([
    {
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      email: "",
      phone: "",
      relationship: "",
      description: "",
    },
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    setForm({
      ...form,
      firstName: tenant?.firstName || "",
      middleName: tenant?.middleName || "",
      lastName: tenant?.lastName || "",
      email: tenant?.email || "",
      phone: tenant?.phone || "",
      dob: tenant?.dob || "",
      addressLine_1: tenant?.addressLine_1 || "",
      addressLine_2: tenant?.addressLine_2 || "",
      city: tenant?.city?.name || "",
      state: tenant?.state?.name || "",
      zipCode: tenant?.zipcode?.number ? "" + tenant?.zipcode?.number : "",
      property: tenant?.interestedIn?.property?.id || "",
      unit: tenant?.interestedIn?.unit?.id || "",
      company: tenant?.company || "",
      jobTitle: tenant?.jobTitle || "",
      phoneType: tenant?.phoneType || "",
      notes: tenant?.notes?.description,
    });
  }, [tenant]);

  useEffect(() => {
    if (tenant?.emergencyDetails && tenant?.emergencyDetails.length > 0) {
      setEmergencyContacts(
        tenant.emergencyDetails.map((emergencyData) => ({
          firstName: emergencyData?.firstName || "",
          middleName: emergencyData?.middleName || "",
          lastName: emergencyData?.lastName || "",
          email: emergencyData?.email || "",
          phone: emergencyData?.phone || "",
          relationship: emergencyData?.relationship || "",
          description: emergencyData?.description || "",
        }))
      );
    }

    if (tenant?.pets && tenant?.pets.length > 0) {
      setPets(
        tenant.pets.map((petData) => ({
          petType: petData?.pet_type || "",
          petName: petData?.name || "",
          petWeight: petData?.weight || "",
          petBreed: petData?.breed || "",
        }))
      );
    }

    if (tenant?.vehicles && tenant?.vehicles.length > 0) {
      setVehicles(
        tenant.vehicles.map((vehicleData) => ({
          vehicleType: vehicleData?.vehicle_type || "",
          vehicleMake: vehicleData?.make || "",
          vehicleModel: vehicleData?.model || "",
          vehicleYear: vehicleData?.year || "",
          vehicleColor: vehicleData?.color || "",
          vehicleLicense: vehicleData?.license || "",
        }))
      );
    }

    if (tenant?.dependent && tenant?.dependent.length > 0) {
      setDependentInfo(
        tenant.dependent.map((dependentData) => ({
          firstName: dependentData?.firstName || "",
          middleName: dependentData?.middleName || "",
          lastName: dependentData?.lastName || "",
          dob: dependentData?.dob || "",
          email: dependentData?.email || "",
          phone: dependentData?.phone || "",
          relationship: dependentData?.relationship || "",
          description: dependentData?.description || "",
        }))
      );
    }
  }, [tenant]);

  const handleChange = (fieldName, value) => {
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  const handleEmergencyChange = (field, index, value) => {
    const newEmergencyContacts = [...emergencyContacts];
    newEmergencyContacts[index][field] = value;
    setEmergencyContacts(newEmergencyContacts);
  };

  const handleRemoveContact = (indexToRemove) => {
    if (indexToRemove !== 0) {
      const updatedEmergencyContacts = emergencyContacts.filter(
        (contact, index) => index !== indexToRemove
      );
      setEmergencyContacts(updatedEmergencyContacts);
    }
  };

  const handleAddContact = () => {
    setEmergencyContacts([
      ...emergencyContacts,
      {
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phone: "",
        relationship: "",
        description: "",
      },
    ]);
  };

  const handlePetChange = (field, index, value) => {
    const newPets = [...pets];
    newPets[index][field] = value;
    setPets(newPets);
  };

  const addAnotherPet = () => {
    setPets([
      ...pets,
      { petType: "", petName: "", petWeight: "", petBreed: "" },
    ]);
  };

  const removePet = (indexToRemove) => {
    if (indexToRemove !== 0) {
      const updatedPets = pets.filter((pet, index) => index !== indexToRemove);
      setPets(updatedPets);
    }
  };

  const handleVehicleChange = (field, index, value) => {
    const newVehicles = [...vehicles];
    newVehicles[index][field] = value;
    setVehicles(newVehicles);
  };

  const addAnotherVehicle = () => {
    setVehicles([
      ...vehicles,
      {
        vehicleType: "",
        vehicleMake: "",
        vehicleModel: "",
        vehicleYear: "",
        vehicleColor: "",
        vehicleLicense: "",
      },
    ]);
  };

  const removeVehicle = (indexToRemove) => {
    if (indexToRemove !== 0) {
      const updatedVehicles = vehicles.filter(
        (vehicle, index) => index !== indexToRemove
      );
      setVehicles(updatedVehicles);
    }
  };

  const handleDependentChange = (field, index, value) => {
    const newDependentInfo = [...dependentInfo];
    newDependentInfo[index][field] = value;
    setDependentInfo(newDependentInfo);
  };

  const addAnotherDependent = () => {
    setDependentInfo([
      ...dependentInfo,
      {
        firstName: "",
        middleName: "",
        lastName: "",
        dob: "",
        email: "",
        phone: "",
        relationship: "",
        description: "",
      },
    ]);
  };

  const removeDependent = (indexToRemove) => {
    if (indexToRemove !== 0) {
      const updatedDependentInfo = dependentInfo.filter(
        (dependent, index) => index !== indexToRemove
      );
      setDependentInfo(updatedDependentInfo);
    }
  };

  const handleBackButton = () => {
    if (currentStep === 1) {
      navigate("/all-prospect");
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleEmailFormSubmit = () => {
    const newErrors = validateEmail(email);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setIsLoading(true);
      verifyProspect(email).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          if (res.message?.message === "Email verified successfully.") {
            message.info(res.message?.message);
            setCurrentStep(currentStep + 1);
          } else if (
            res?.message?.message === "Prospect fetched successfully"
          ) {
            setTenant(res.message?.data);
            message.info("Prospect is already exist with this email");
            setCurrentStep(currentStep + 1);
          }
        } else if (res.apiCallStatus === "error") {
          message.error(res?.message);
        }
      });
    }
  };

  const handleAdditionInfoSubmit = () => {
    const newErrors = validateProspectPersonalDetails(form);
    if (Object.keys(newErrors).length === 0) {
      setErrors({});
      setCurrentStep(currentStep + 1);
    } else {
      setErrors(newErrors);
    }
  };

  const handleFormSubmit = () => {
    const formData = prospectFormData(
      form,
      emergencyContacts,
      pets,
      vehicles,
      dependentInfo,
      ProfileImages
    );
    setIsLoading(true);
    if (tenant?.length === 0) {
      createProspect(formData).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          setOpenModal(true);
        }
      });
    } else {
      updateProspect(tenant?.id, formData).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          setOpenModal(true);
        }
      });
    }
  };

  // Stepper Function
  const handleNext = () => {
    switch (currentStep) {
      case 1:
        handleEmailFormSubmit();
        break;
      case 2:
        handleAdditionInfoSubmit();
        break;
      case 3:
        setCurrentStep(currentStep + 1);
        break;
      case 4:
        if (selectedPropertyType === "commercial") {
          handleFormSubmit();
          break;
        }
        setCurrentStep(currentStep + 1);
        break;
      case 5:
        setCurrentStep(currentStep + 1);
        break;
      case 6:
        handleFormSubmit();
        break;
      default:
        break;
    }
  };

  return (
    <>
      {openModal && (
        <TempModal
          onClose={() => setOpenModal(false)}
          title={
            tenant?.length === 0
              ? `Prospect added successfully`
              : `Prospect updated successfully`
          }
          success="Go To Prospects"
          route="all-prospect"
        />
      )}
      <div className="container-fluid bg-white p-3">
        <div className="container">
          <Stepper
            steps={addProspectDetails}
            activeStep={currentStep - 1}
            lockStep={false}
            maxSteps={4} // Customize if desired
            setIsDraft={(isDraft) => console.log("Draft status:", isDraft)}
          />

          <div className="stepper-content-container mt-4">
            {currentStep === 1 && (
              <EmailForm
                email={email}
                setEmail={setEmail}
                errors={errors}
                handleChange={handleChange}
                handleNext={handleNext}
                isLoading={isLoading}
              />
            )}
            {console.log("selectedPropertyType ==> ", selectedPropertyType)}
            {currentStep === 2 && (
              <PersonalDetailsForm
                form={form}
                handleChange={handleChange}
                errors={errors}
                setProfileImages={setProfileImages}
                ProfileImages={ProfileImages}
                setDeletedImages={setDeletedImages}
                DeletedImages={DeletedImages}
                handleNext={handleNext}
                handlePropertyType={setSelectedPropertyType}
              />
            )}
            {currentStep === 3 && (
              <EmergencyInfoForm
                emergencyContacts={emergencyContacts}
                handleChange={handleEmergencyChange}
                handleAddContact={handleAddContact}
                handleRemoveContact={handleRemoveContact}
                handleBackButton={handleBackButton}
                handleNext={handleNext}
              />
            )}
            {selectedPropertyType === "residential" && currentStep === 4 && (
              <DependentInfoForm
                dependentInfo={dependentInfo}
                addAnotherDependent={addAnotherDependent}
                removeDependent={removeDependent}
                handleChange={handleDependentChange}
                handleBackButton={handleBackButton}
                handleNext={handleNext}
              />
            )}
            {selectedPropertyType === "residential" && currentStep === 5 && (
              <PetsForm
                pets={pets}
                handleChange={handlePetChange}
                handleBackButton={handleBackButton}
                handleNext={handleNext}
                addAnotherPet={addAnotherPet}
                removePet={removePet}
                disabled={selectedPropertyType === "commercial"}
              />
            )}
            {((selectedPropertyType === "commercial" && currentStep === 4) ||
              currentStep === 6) && (
              <VehicleForm
                vehicles={vehicles}
                handleChange={handleVehicleChange}
                handleBackButton={handleBackButton}
                handleNext={handleNext}
                addAnotherVehicle={addAnotherVehicle}
                removeVehicle={removeVehicle}
                isSubmitForm={true}
                isLoading={isLoading}
                disabled={selectedPropertyType === "commercial " ? true : false}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProspectDetails;
