import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import WorkOrder from "Components/WorkOrder/WorkOrder";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const WorkOrderPage = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Work Orders"}
        back="all-work-order"
      />
      <WorkOrder />
    </div>
  );
};

export default WorkOrderPage;
