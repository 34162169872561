import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import AddProspectDetailsEditModal from "Modals/AddProspectDetailsEditModal/AddProspectDetailsEditModal";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";

const AddProspectDetailsEditModalPage = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Edit Prospects Details"}
        back="all-prospect"
      />
      <AddProspectDetailsEditModal />
    </div>
  );
};

export default AddProspectDetailsEditModalPage;
