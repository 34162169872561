import React from "react";
import { useState, useEffect } from "react";
import Loader from "Helpers/Loader";
import { Icon } from "leaflet";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
const GOOGLE_MAPS_LIBRARIES = ["places"];

const DescriptionTab = ({ data }) => {
  const containerStyle = { width: "700px", height: "400px" };
  const [center, setCenter] = useState({ lat: null, lng: null });
  const [markers, setMarkers] = useState([]);
  const [addressNotFound, setAddressNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const customIcon = new Icon({
    iconUrl: require("assets/map-icon.png"),
    iconSize: [38, 38],
  });

  // Load Google Maps API once with consistent API key
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES,
  });

  // Fetch coordinates using the Google Geocoding API
  useEffect(() => {
    const getCoords = async () => {
      const formattedAddress = data.address;

      if (formattedAddress) {
        try {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
              formattedAddress
            )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
          );
          const data = await response.json();
          if (data.results.length > 0) {
            const location = data.results[0].geometry.location;
            setCenter(location);
            setMarkers([{ position: location, popUp: formattedAddress }]);
            setAddressNotFound(false); // Address found
          } else {
            setAddressNotFound(true); // Address not found
          }
        } catch (error) {
          console.error("Error fetching geocode data", error);
          setAddressNotFound(true); // Error implies address not found
        }
      }
    };

    getCoords();
  }, [data?.address]);

  if (isLoading || !isLoaded) return <Loader />;

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <pre className="properties_details_view_description_content">
            {data?.description || "N/A"}
          </pre>
        </div>
      </div>
      <div
        className={window.innerWidth <= 980 ? "" : "col-md-4"}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          width: "100%",
          marginTop: "10px",
        }}
      >
        {addressNotFound ? (
          <div
            className="text-center text-white d-flex align-items-center justify-content-center bg-dark mt-5"
            mapContainerStyle={containerStyle}
            style={{ height: "100%" }}
          >
            <h3>Address Not Found</h3>
          </div>
        ) : (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={13}
          >
            {markers.map((marker, index) => (
              <Marker
                key={index}
                position={marker.position}
                title={marker.popUp}
              />
            ))}
          </GoogleMap>
        )}
      </div>
    </>
  );
};

export default DescriptionTab;
