import { useState } from "react";
import { Button, message, Steps, theme, ConfigProvider } from "antd";

// FIXME Steps needs to be responsive on mobile-device
const CustomSteps = () => {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);

  const steps = [
    {
      title: "First",
      content: "First-content",
    },
    {
      title: "Second",
      content: "Second-content",
    },
    {
      title: "Last",
      content: "Last-content",
    },
    {
      title: "Last",
      content: "Last-content",
    },
    {
      title: "Last",
      content: "Last-content",
    },
    {
      title: "Last",
      content: "Last-content",
    },
  ];
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };
  return (
    <>
      {/* <Steps current={1} labelPlacement="vertical" items={items} /> */}
      <div className="container-fluid bg-white p-3">
        <ConfigProvider
          theme={{
            components: {
              Steps: {
                colorPrimary: "#EF6B3E",
              },
              Button: {
                colorPrimary: "#EF6B3E",
                colorPrimaryHover: "#EF6B3E",
                colorPrimaryActive: "#EF6B3E",
              },
            },
          }}
        >
          <Steps
            // responsive={false}
            items={[
              {
                title: <div className="custom-steps-title">First</div>,
                icon: (
                  <div
                    className="custom-steps"
                    style={{
                      backgroundColor:
                        current === 0 ||
                        current === 1 ||
                        current === 2 ||
                        current === 3 ||
                        current === 4 ||
                        current === 5
                          ? "#EF6B3E"
                          : "#EAECF0",
                    }}
                  >
                    1
                  </div>
                ),
              },
              {
                title: <div className="custom-steps-title">Second</div>,
                icon: (
                  <div
                    className="custom-steps"
                    style={{
                      backgroundColor:
                        current === 1 ||
                        current === 2 ||
                        current === 3 ||
                        current === 4 ||
                        current === 5
                          ? "#EF6B3E"
                          : "#EAECF0",
                    }}
                  >
                    2
                  </div>
                ),
              },
              {
                title: <div className="custom-steps-title">Third</div>,
                icon: (
                  <div
                    className="custom-steps"
                    style={{
                      backgroundColor:
                        current === 2 ||
                        current === 3 ||
                        current === 4 ||
                        current === 5
                          ? "#EF6B3E"
                          : "#EAECF0",
                    }}
                  >
                    3
                  </div>
                ),
              },
              {
                title: <div className="custom-steps-title">Fourth</div>,
                icon: (
                  <div
                    className="custom-steps"
                    style={{
                      backgroundColor:
                        current === 3 || current === 4 || current === 5
                          ? "#EF6B3E"
                          : "#EAECF0",
                    }}
                  >
                    4
                  </div>
                ),
              },
              {
                title: <div className="custom-steps-title">Fifth</div>,
                icon: (
                  <div
                    className="custom-steps"
                    style={{
                      backgroundColor:
                        current === 4 || current === 5 ? "#EF6B3E" : "#EAECF0",
                    }}
                  >
                    5
                  </div>
                ),
              },
              {
                title: <div className="custom-steps-title">Sixth</div>,
                icon: (
                  <div
                    className="custom-steps"
                    style={{
                      backgroundColor: current === 5 ? "#EF6B3E" : "#EAECF0",
                    }}
                  >
                    6
                  </div>
                ),
              },
            ]}
            current={current}
            direction="horizontal"
            labelPlacement="vertical"
            className="test-steps responsive-steps"
            // items={items}
          />
          <div style={contentStyle}>{steps[current].content}</div>
          {/* <div>{steps[current].content}</div> */}
          <div
            className="stepper-first-btn property-details-next-btn-main add-property-details-content-btn d-flex justify-content-between gap-1 mt-5"
            style={{
              marginTop: 24,
            }}
          >
            {current > 0 && (
              <Button
                className="back-prev-btn mt-3 cutom-steps-btn"
                onClick={() => prev()}
              >
                <svg
                  width={24}
                  height={24}
                  fill="none"
                  stroke="#98A2B3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m15 18-6-6 6-6" />
                </svg>
                Back
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => next()}
                className={
                  current === 0
                    ? "next-btn-same-class cutom-steps-btn w-100"
                    : "next-btn-same-class cutom-steps-btn"
                }
              >
                Next{" "}
                <svg
                  width={24}
                  height={24}
                  fill="none"
                  stroke="#FFFFFF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m9 18 6-6-6-6" />
                </svg>
              </Button>
            )}

            {current === steps.length - 1 && (
              <Button
                className="next-btn-same-class cutom-steps-btn"
                type="primary"
                onClick={() => message.success("Processing complete!")}
              >
                Finish
              </Button>
            )}
          </div>
        </ConfigProvider>
      </div>
    </>
  );
};

export default CustomSteps;
