import InvoiceReports from "Components/InvoiceReports/InvoiceReports";
import React from "react";

const ReportsInvoice = () => {
  return (
    <div className="container-fluid">
      <InvoiceReports />
    </div>
  );
};

export default ReportsInvoice;
