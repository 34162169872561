import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import TenantPassportProfile from "Components/TenantPassportProfile/TenantPassportProfile";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const PassportProfile = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Tenant Passport"}
        back="tenant-passport"
      />
      <TenantPassportProfile />
    </div>
  );
};

export default PassportProfile;
