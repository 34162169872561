import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import RenewLease from "Components/RenewLease/RenewLease";
import TitleTopBar from "Helpers/TitleTopBar";
import React from "react";

const LeasesRenew = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail="Renew Lease"
        back="all-lease"
      />
      <RenewLease />
    </div>
  );
};

export default LeasesRenew;
