import React from "react";

function StopReminder({ width = 21, height = 21 }) {
  return (
    <>
      <svg
       width={width}
       height={height}
       strokeWidth={1.5}
       stroke="currentColor"
       className="size-6"
        viewBox="0 0 512 512"
        fill="currentColor"

        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Layer_2_00000005252295283530570680000012549818696684199573_">
          <g id="up_hobe_copy_7">
            <g id="_93">
              <path d="m454.3 88.5-3.8-5.9-145.9 145.8 5.9 3.8c41.2 26.5 95.2 20.7 130-13.7 34.5-34.8 40.2-88.8 13.8-130zm-131.6 137.8 125.7-125.7c24.5 44.9 7.9 101.2-37 125.7-27.7 15.1-61.1 15.1-88.7 0z" />
              <path d="m469.6 247.5c56.5-56.5 56.5-148.5 0-205.1-56.2-56.2-147.3-56.5-203.9-1.1-12.4-2.8-25-4.1-37.7-4.1-96.1 0-174.3 78.2-174.3 174.3v87.2c0 7.8-3 15.4-8.4 21.1l-35 36.7c-6.6 6.7-10.3 15.8-10.3 25.2v32.1c0 15.7 12.7 28.4 28.4 28.4h115.1c8.9 46.6 53.8 77.2 100.4 68.3 34.6-6.6 61.7-33.7 68.3-68.3h115.1c15.7 0 28.4-12.7 28.4-28.4v-32.1c0-9.4-3.6-18.5-10.1-25.2l-35-36.7c-5.4-5.7-8.5-13.2-8.4-21.1v-13.1c25.5-6.3 48.8-19.5 67.4-38.1zm-241.7 253.2c-35 0-65.2-24.4-72.8-58.5h145.6c-7.5 34.1-37.8 58.4-72.8 58.5zm174.5-173.1 35 36.7c4.5 4.7 7 10.9 7 17.4v32.1c0 9.4-7.6 17.1-17.1 17.1h-399c-9.4 0-17.1-7.6-17.1-17.1v-32.1c0-6.5 2.5-12.8 7-17.4l35-36.7c7.5-7.8 11.6-18.1 11.6-28.9v-87.2c0-89.9 73.1-163 163-163 9.6 0 19.2.8 28.6 2.5-48.5 56.9-45.8 142.7 7.9 196.4 33.1 33.1 80.2 48.1 126.4 40.4v10.8c.2 10.9 4.3 21.2 11.7 29zm-11.5-51.1c-43.1 7.8-87.4-6-118.4-37-51-51-52.1-133.3-3.3-185.7 1.1-1.2 2.1-2.3 3.3-3.4 2-2 4-3.9 6.1-5.7 53-46.7 133-44.2 183 5.7 52.1 52.1 52.1 137 0 189.1-16.4 16.5-36.9 28.4-59.4 34.4-3.7 1.1-7.5 1.9-11.3 2.6z" />
              <path d="m423.5 57.7c-40.8-26.5-95.5-20.7-130 13.8-34.5 34.8-40.2 88.8-13.7 130l3.8 5.9 145.8-145.9zm-137.8 131.6c-24.5-44.9-7.9-101.2 37-125.7 27.6-15 61-15.1 88.6 0z" />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
}

export default StopReminder;
