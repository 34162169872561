import React from "react";
import { Avatar, Tooltip } from "antd";

const FirstCharacterIcon = ({ name, tooltip = false }) => {
  return (
    <>
      {tooltip && (
        <Tooltip title={name}>
          <Avatar
            style={{
              backgroundColor: "#EF6B3E",
              verticalAlign: "middle",
            }}
            size="large"
          >
            {name.charAt(0).toUpperCase()}
          </Avatar>
        </Tooltip>
      )}
      {!tooltip && (
        <Avatar
          style={{
            backgroundColor: "#EF6B3E",
            verticalAlign: "middle",
          }}
          size="large"
        >
          {name.charAt(0).toUpperCase()}
        </Avatar>
      )}
    </>
  );
};

export default FirstCharacterIcon;
