import React from "react";
import "style/OptionCard.css";

const SimpleCard = ({ line1, line2, line3, cardColor = "#fff" }) => {
  return (
    <div className="my-card" style={{ backgroundColor: cardColor }}>
      <div className="small-text">{line1}</div>
      <div className="large-text">{line2}</div>
      <div className="small-text">{line3}</div>
    </div>
  );
};

export default SimpleCard;
