import React from "react";
import MediaField from "Components/TenantPassportProfile/MediaField";
import {
  selectMultiServiceColumns,
  selectCompanyTypes,
  usaStates,
  selectPhoneType,
} from "utils/dropdownData";
import {
  SelectMultipleColumns,
  InputField,
  SelectField,
  TextAreaField,
  SubmitButton,
  DateField,
  EINField,
  PhoneNumberField,
  SSNField,
  AmountField,
} from "Components/GeneralComponents";

function Profile({
  form,
  handleChange,
  handleSubmit,
  images,
  setImages,
  insuranceDocuments,
  setInsuranceDocuments,
  errors,
  isLoading = false,
}) {
  const handleZipCodeChange = (e) => {
    if (/^\d{0,5}$/.test(e.target.value)) {
      handleChange("zipcode", e.target.value);
    }
  };

  return (
    <div className="modal-content-main-section p-4">
      <div className="mb-3 mt-1">
        <MediaField
          label="Company logo"
          Images={images}
          setImages={setImages}
          errorMessage={errors.companyLogo}
        />
      </div>

      <div className="mb-3">
        <InputField
          name="companyName"
          placeholder={"Company Name"}
          required={true}
          label="Company Name"
          value={form.companyName}
          handler={(e) => handleChange("companyName", e.target.value)}
          errorMessage={errors.companyName}
        />
      </div>

      <div className="mb-3">
        <SelectField
          name="companyType"
          required={true}
          label="Type of Company"
          placeholder="Type of Company"
          value={form.companyType}
          options={selectCompanyTypes}
          handler={(e) => handleChange("companyType", e)}
          errorMessage={errors.companyType}
        />
      </div>

      <div className="mb-3">
        <InputField
          name={"Email"}
          required={true}
          label={"Company Email"}
          placeholder={"Email"}
          value={form.email}
          handler={(e) => handleChange("email", e.target.value)}
          errorMessage={errors.email}
        />
      </div>

      <div className="mb-3">
        <SelectField
          name={"Phone Type"}
          required={true}
          label={"Phone Type"}
          placeholder={"Select Phone Type"}
          value={form.phoneType}
          options={selectPhoneType}
          handler={(e) => handleChange("phoneType", e)}
          errorMessage={errors.phoneType}
        />
      </div>

      <div className="mb-3">
        <PhoneNumberField
          name={"Phone No"}
          label={"Phone No"}
          placeholder={"Phone No"}
          value={form.phone}
          handler={(value) => handleChange("phone", value)}
          required={true}
          errorMessage={errors.phone}
        />
      </div>

      <div className="mb-3">
        {form.companyType === "Sole Proprieter" ? (
          <SSNField
            name="SSN"
            required={true}
            label="SSN Number"
            placeholder="SSN Number"
            value={form.ssn}
            type="text"
            handler={handleChange}
            errorMessage={errors.ssn}
          />
        ) : (
          <EINField
            name="EIN"
            required={true}
            label="EIN Number"
            placeholder="EIN Number"
            value={form.ein}
            type="text"
            handler={handleChange}
            errorMessage={errors.ein}
          />
        )}
      </div>

      <div className="mb-3">
        <InputField
          name="doingBusinessAs"
          required={true}
          label="Doing Business as"
          placeholder="Doing Business as"
          value={form.doingBusinessAs}
          handler={(e) => handleChange("doingBusinessAs", e.target.value)}
          errorMessage={errors.doingBusinessAs}
        />
      </div>

      <div className="mb-3">
        <SelectMultipleColumns
          label="Services"
          placeholder="Select Services"
          values={form.selectMultiServiceColumns}
          options={selectMultiServiceColumns}
          handleChange={(e) => handleChange("services", e)}
          required={true}
          errorMessage={errors.services}
        />
      </div>

      <div className="row mb-3">
        <span className="tenant-personal-details-title">
          Hourly Rate
          <span className="sign-up-imp-star">*</span>
        </span>
        <div className="col-md-5 col-12 mb-3">
          <AmountField
            name={"rateFrom"}
            required={true}
            placeholder={"Starting From"}
            value={form.hourlyRateStart}
            handler={(value) => handleChange("hourlyRateStart", value)}
            errorMessage={errors.hourlyRateStart}
          />
        </div>
        <div className="col-md-2 d-flex justify-content-center align-items-center" style={{ height: "46px" }}>
          -
        </div>
        <div className="col-md-5 col-12">
          <AmountField
            name={"rateTo"}
            required={true}
            placeholder={"To"}
            value={form.hourlyRateEnd}
            handler={(value) => handleChange("hourlyRateEnd", value)}
            errorMessage={errors.hourlyRateEnd}
          />
        </div>
      </div>

      <div className="mb-3">
        <TextAreaField
          name="description"
          label="Company Description"
          placeholder="Company Description"
          value={form.description}
          handler={(e) => handleChange("description", e.target.value)}
          rows={4}
          errorMessage={errors.description}
        />
      </div>

      <div className="row mb-3">
        <div className="col-12">
          <InputField
            name="addressLine_1"
            label="Address"
            placeholder={"Address"}
            value={form.addressLine_1}
            handler={(e) => handleChange("addressLine_1", e.target.value)}
            required={true}
            errorMessage={errors.addressLine_1}
          />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-6 col-12 mb-3">
          <InputField
            name={"city"}
            required={true}
            label={"City"}
            placeholder={"City"}
            value={form.city}
            handler={(e) => handleChange("city", e.target.value)}
            errorMessage={errors.city}
          />
        </div>
        <div className="col-md-6 col-12">
          <SelectField
            name="state"
            required={true}
            label="State"
            placeholder="State"
            value={form.state}
            options={usaStates}
            handler={(e) => handleChange("state", e)}
            errorMessage={errors.state}
          />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-6 col-12 mb-3">
          <InputField
            handler={handleZipCodeChange}
            value={form.zipcode}
            type="text"
            name={"Postal code"}
            placeholder={"Zip Code"}
            errorMessage={errors.zipcode}
            label="Zip Code"
            required={true}
          />
        </div>
        <div className="col-md-6 col-12">
          <SelectField
            name="country"
            required={true}
            label="Country"
            placeholder="Select Country"
            value={form.country}
            options={["USA", "CAN"]}
            handler={(e) => handleChange("country", e)}
            disabled={true}
            errorMessage={errors.country}
          />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-6 col-12 mb-3">
          <InputField
            name="insuranceDetails"
            label="Insurance Details"
            placeholder="Insurance Details"
            value={form.insuranceDetails}
            handler={(e) => handleChange("insuranceDetails", e.target.value)}
            required={true}
            errorMessage={errors.insuranceDetails}
          />
        </div>
        <div className="col-md-6 col-12">
          <DateField
            name="insuranceExpirationDate"
            label="Insurance Expiry"
            placeholder="Insurance Expiry"
            value={form.insuranceExpirationDate}
            handler={(e) => {
              const formattedDate = new Date(e).toLocaleDateString();
              handleChange("insuranceExpirationDate", formattedDate);
            }}
            required={true}
            errorMessage={errors.insuranceExpirationDate}
          />
        </div>
      </div>

      <div className="mb-3">
        <MediaField
          label="Insurance Documents"
          Images={insuranceDocuments}
          setImages={setInsuranceDocuments}
          required={true}
          errorMessage={errors.insuranceDocuments}
        />
      </div>

      <div className="d-flex justify-content-end">
        <div className="col-3 align-items-end mb-3">
          <SubmitButton handler={handleSubmit} isLoading={isLoading}>
            Next
          </SubmitButton>
        </div>
      </div>
    </div>
  );
}

export default Profile;
