import React from "react";
import * as Component from "Components/GeneralComponents";

const radioOptions = [
  { value: false, label: "Do not send 1099 forms to this vendor" },
  { value: true, label: "I want to send 1099 forms to this vendor" },
];

const FederalTaxes = ({
  form,
  handleChange,
  handleBackButton,
  handleNext,
  isLoading = false,
}) => {
  return (
    <div className="container-fluid">
      <div className="row mt-3">
        <div className="col-md-12">
          <Component.RadioGroupField
            value={form.send_1099_forms}
            handler={(value) => handleChange("send_1099_forms", value)}
            options={radioOptions}
          />
        </div>
      </div>
      <div className="row mt-3"></div>
      <div className="stepper-btn d-flex justify-content-between mt-5 gap-1 pb-3">
        <div style={{ width: "50%" }}>
          <Component.SubmitButton type="contained" handler={handleBackButton}>
            Back
          </Component.SubmitButton>
        </div>
        <div style={{ width: "50%" }}>
          <Component.SubmitButton handler={handleNext} isLoading={isLoading}>
            Submit
          </Component.SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default FederalTaxes;
