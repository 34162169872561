import "../../style/subscription-package.css";
import FreeIcon from "assets/free-icon.png";
import ProIcon from "assets/pro-Icon.png";
import React, { useEffect, useState } from "react";
import { Skeleton, Card } from "antd";
import PremiumPackageCard from "./PremiumPackageCard";
const LandLordPackages = ({ data, interval, setSelectedPlan, isLoading }) => {
  const [selected, setSelected] = useState({
    planName: "",
    price: "",
  });

  useEffect(() => {
    console.log("Selected Plan", selected);
  }, [selected]);

  const freePackage = {
    priceMonthly: "Free",
    priceYearly: "Free",
    priceIdMonthly: "",
    productIdMonthly: "",
    priceIdYearly: "",
    productIdYearly: "",
    description:
      "Access to all features, up to 100 properties, up to 10 users, up to 10GB storage, email support",
    features: [
      "Access to all features",
      "Up to 100 properties",
      "Up to 10 users",
      "Up to 10GB storage",
      "Email support",
      "Free",
    ],
  };

  const HandlePlanValue = (planName, price) => {
    setSelected({
      planName: planName,
      price: price,
    });
    setSelectedPlan({
      planName: planName,
      price: price,
    });
  };

  return (
    <div className="subscription-package-container">
      <div className="row py-5 gap-4">
        {isLoading ? (
          Array.from({ length: 4 }).map((_, index) => (
            <div key={index} className="col">
              <Card style={{ backgroundColor: "#f8f4f4", width: "350px" ,}} className="mx-auto">
                <Skeleton.Image style={{ width: "100%", height: "100px" }} />
                <Skeleton active paragraph={{ rows: 4 }} />
              </Card>
            </div>
          ))
        ) : (
          <>
            <div className="col d-flex justify-content-evenly">
              <PremiumPackageCard
                icon={FreeIcon}
                item={freePackage}
                planType={"Free"}
                billingInterval={interval}
                selectedPlan={selected}
                handlePlanValue={HandlePlanValue}
                // UpdateSubscription={UpdateSubscription}
              />
            </div>

            <div className="col d-flex justify-content-evenly">
              <PremiumPackageCard
                icon={ProIcon}
                item={data.Basic}
                planType={"Basic"}
                billingInterval={interval}
                selectedPlan={selected}
                handlePlanValue={HandlePlanValue}
                // UpdateSubscription={UpdateSubscription}
              />
            </div>
            <div className="col d-flex justify-content-evenly">
              <PremiumPackageCard
                icon={ProIcon}
                item={data.Pro}
                planType={"Pro"}
                billingInterval={interval}
                selectedPlan={selected}
                handlePlanValue={HandlePlanValue}
                // UpdateSubscription={UpdateSubscription}
              />
            </div>
            <div className="col d-flex justify-content-evenly">
              <PremiumPackageCard
                icon={ProIcon}
                item={data.Premium}
                planType={"Premium"}
                billingInterval={interval}
                selectedPlan={selected}
                handlePlanValue={HandlePlanValue}
                // UpdateSubscription={UpdateSubscription}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LandLordPackages;
