import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import settingIconOrange from "assets/dots-vertical.png";
import { OverviewTab, FilesTab, NotesTab } from "./index";
import { CustomTabs, DeleteModal, RecordMenuPopup } from "Components/GeneralComponents";
import { deleteTenant, getTenantById } from "network/landlord/tenant";
import Loader from "Helpers/Loader";
import PropertyTab from "./PropertyTab";

const TenantDetailsView = () => {
  const { id, tab } = UseUrlParamsHook();
  const navigate = useNavigate();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [tenant, setTenant] = useState([]);
  const [properties, setProperties] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const items =[
    {key: "1", label: "Overview", children: isLoader ? <Loader /> : <OverviewTab tenant={tenant} />},
    {key: "2", label: "Property", children: <PropertyTab id={id} category="tenant" />},
    {key: "3", label: "Notes", children: <NotesTab id={id} category="tenant" />},
    {key: "4", label: "Files", children: <FilesTab id={id} category="tenant" properties={properties} />},
  ];

  useEffect(() => {
    setActiveTab(tab || "1");
  }, [tab]);

  const onChange = (key) => {
    setActiveTab(key);
    searchParams.set("tab", key);
    navigate({ search: searchParams.toString() },{replace: true});
  };

  // Get Tenant By Id
  useEffect(() => {
    setIsLoader(true);
    getTenantById(id).then((res) => {
      setIsLoader(false);
      if (res.apiCallStatus === "success") {
        setTenant(res.message);
        setProperties(res.message.properties.reduce((acc, current) => {
          const x = acc.find(item => item.id === current._id);
          if (!x) {
            return acc.concat([{
              id: current._id,
              heading1: current.type,
              heading2: current.title,
              heading3: current.address,
            }]);
          } else {
            return acc;
          }
        }, []));

      } else {
        message.error(res.error.message);
      }
    });
  }, []);

  // Delete Tenant
  const handleDeleteTenant = () => {
    setIsLoading(true);
    deleteTenant(id).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        message.success("Tenant Deleted Successfully");
        setOpenDeleteModal(false);
        navigate("/all-tenants");
      } else {
        message.error(res.error.message);
      }
    });
  };

  const handleIconClickCustom = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      <DeleteModal
        isOpen={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        message={"Are you sure you want to delete this tenant?"}
        deleteCall={handleDeleteTenant}
        isLoading={isLoading}
      />

      <div className="container-fluid bg-white p-3">
        <div className="row">
          <div className="col-md-12 text-end">
            <span className="position-relative">
              <img
                onClick={handleIconClickCustom}
                src={settingIconOrange}
                alt="Action Buttons"
                className="cursor"
              />
              <RecordMenuPopup
                isOpen={isDropdownOpen}
                onClose={() => setDropdownOpen(false)}
                fields={{ edit: true, delete: true }}
                handler={{
                  handleDeleteRecord: () => setOpenDeleteModal(true),
                  handleEdit: () => navigate(`/edit-tenant-details/${id}`),
                }}
              />
            </span>
          </div>
        </div>
        {activeTab && <CustomTabs
          items={items}
          defaultActiveKey={activeTab}
          onChange={onChange}
        />}
      </div>
    </>
  );
};

export default TenantDetailsView;
