import React, { useState } from "react";
import { ConfigProvider, DatePicker } from "antd";
import * as Icon from "assets/icons";

const Calender = ({filter, setFilter, onRangeSelected}) => {
  const [isShowCalendar, setIsShowCalendar] = useState(false);

  const handleDateChange = (dates) => {
    setFilter({ 
        ...filter, 
        fromDate: new Date(dates[0]),
        toDate: new Date(dates[1])
    })
    setIsShowCalendar(false);
    onRangeSelected(dates);
    console.log(filter)
  };

  return (
    <div
      className="calendar cursor"
      onClick={() => setIsShowCalendar(!isShowCalendar)}
    >
      <Icon.CalendarIcon />
      {isShowCalendar && (
        <div
          className="calendar-container"
            onClick={(e) => e.stopPropagation()}
        >
          <ConfigProvider
            theme={{
              components: {
                DatePicker: {
                  cellWidth: window.innerWidth <= 500 ? 20 : 36,
                  fontSize: window.innerWidth <= 500 ? 12 : 14,
                  cellHeight: window.innerWidth <= 500 ? 18 : 24,
                  colorPrimary: "#EF6B3E",
                  cellActiveWithRangeBg: "#FFEAD5",
                },
              },
            }}
          >
            <DatePicker.RangePicker
            //   value={defaultValues}
              onChange={handleDateChange}
              open={true}
              className="searchbar-datepicker"
            />
          </ConfigProvider>
        </div>
      )}
    </div>
  );
};

export default Calender;
