import React from "react";
import arrowLeft from "assets/chevron-left.png";
import { CustomTable, SubmitButton } from "Components/GeneralComponents";
import { Tooltip } from "antd";
import helpIcon from "assets/help-circle.png";

const PermissionLevel = ({
  isLoading,
  handleChange,
  handleNext,
  handlePrev,
}) => {

  const columns = [
    {
      title: "Modules",
      dataIndex: "module",
    },
    {
      title: (
        <Tooltip title="A User can always view, You can't change this permission">
          View <img src={helpIcon} alt="" className="cursor ps-2" />
        </Tooltip>
      ),
      render: (head, index) => (
        <input checked={true} type="checkbox" disabled={true} />
      ),
    },
    {
      title: "Create",
      render: (head1, index) => (
        <input
          // checked={check[index.name]?.add || false}
          onChange={(e) => handleChange(index.name, "add", e.target.checked)}
          type="checkbox"
        />
      ),
    },
    {
      title: "Edit",
      render: (head2, index) => (
        <input
          // checked={check[index.name]?.update || false}
          onChange={(e) => handleChange(index.name, "update", e.target.checked)}
          type="checkbox"
        />
      ),
    },
    {
      title: "Delete",
      render: (head3, index) => (
        <input
          // checked={check[index.name]?.delete || false}
          onChange={(e) => handleChange(index.name, "delete", e.target.checked)}
          type="checkbox"
        />
      ),
    },
  ];

  const data = [
    {
      key: "1",
      name: "LL_Dashboard",
      module: "LL Dashboard",
    },
    {
      key: "2",
      name: "properties",
      module: "Properties",
    },
    {
      key: "3",
      name: "units",
      module: "Units",
    },
    {
      key: "4",
      name: "tenants",
      module: "Tenants ",
    },
    {
      key: "5",
      name: "task",
      module: "Tasks ",
    },
    {
      key: "6",
      name: "invoicing",
      module: " Invoices",
    },
    {
      key: "7",
      name: "prospects",
      module: " Prospect",
    },
    {
      key: "8",
      name: "profileManagement",
      module: " Profile Management (LL)",
    },
    {
      key: "9",
      name: "settings_profile",
      module: " Settings - Profile",
    },
    {
      key: "10",
      name: "settings_changePassword",
      module: " Settings - Change Password",
    },

    {
      key: "11",
      name: "settings_Subscription",
      module: "Settings - Subscription ",
    },
    {
      key: "12",
      name: "settings_ManageUsers",
      module: " Settings - Manage Users",
    },
    {
      key: "13",
      name: "reports",
      module: " LL Reports ",
    },
    {
      key: "14",
      name: "workOrders",
      module: " Work Orders ",
    },
    {
      key: "15",
      name: "leases",
      module: " Leases ",
    },
    {
      key: "16",
      name: "accounting",
      module: " Accounting ",
    },
    {
      key: "17",
      name: "mileage",
      module: " Mileage ",
    },
  ];

  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-12">
            <p className="property-accounts-lease-options-text">
              You can set custom access levels.{" "}
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-12">
            <CustomTable columns={columns} dataSource={data} />
          </div>
        </div>

        <div className="row mt-5">
          <button onClick={handlePrev} className="back-prev-btn">
            <img src={arrowLeft} />
            Back
          </button>
          <div className="col-md-6">
            <SubmitButton handler={handleNext} isLoading={isLoading}>
              Save
            </SubmitButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PermissionLevel;
