import React from "react";
import { formatDate } from "utils";
import {
  InputField,
  SelectField,
  SubmitButton,
  PhoneNumberField,
  DateField,
} from "Components/GeneralComponents";
import { selectRelationship } from "utils/dropdownData";
import DropdownLayout from "../GeneralComponents/DropdownLayout";
import "../../style/dropdown.css";

const DependentInfoForm = ({
  dependentInfo,
  handleChange,
  handleBackButton,
  handleNext,
  addAnotherDependent,
  removeDependent,
  isLoading = false,
  isSubmitForm = false,
}) => {
  return (
    <div className="container-fluid">
      {dependentInfo.map((dependent, index) => (
        <DropdownLayout key={index} title={`Dependent ${index + 1}`}>
          <div key={index} className="vehicle-container">
            <div className="row">
              <div className="col-md-4">
                <InputField
                  name={"firstName"}
                  label={"First Name"}
                  placeholder={"First Name"}
                  value={dependent.firstName}
                  handler={(e) =>
                    handleChange(e.target.name, index, e.target.value)
                  }
                />
              </div>
              <div className="col-md-4">
                <InputField
                  name={"middleName"}
                  label={"Middle Name"}
                  placeholder={"Middle Name"}
                  value={dependent.middleName}
                  handler={(e) =>
                    handleChange(e.target.name, index, e.target.value)
                  }
                />
              </div>
              <div className="col-md-4">
                <InputField
                  name={"lastName"}
                  label={"Last Name"}
                  placeholder={"Last Name"}
                  value={dependent.lastName}
                  handler={(e) =>
                    handleChange(e.target.name, index, e.target.value)
                  }
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <InputField
                  name={"email"}
                  type={"email"}
                  label={"Email"}
                  placeholder={"Email"}
                  value={dependent.email}
                  handler={(e) =>
                    handleChange(e.target.name, index, e.target.value)
                  }
                />
              </div>
              <div className="col-md-6">
                <PhoneNumberField
                  name={"phone"}
                  label={"Phone No"}
                  placeholder={"Phone Number"}
                  value={dependent.phone}
                  handler={(value) => handleChange("phone", index, value)}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <DateField
                  label={"Date of Birth"}
                  placeholder={"Select Date"}
                  defaultValue={dependent.dob && formatDate(dependent.dob)}
                  handler={(e) => {
                    const formattedDate = formatDate(new Date(e));
                    handleChange("dob", index, formattedDate);
                  }}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <SelectField
                  name={"relationship"}
                  label={"Relationship"}
                  placeholder={"Select Relationship"}
                  value={dependent.relationship}
                  options={selectRelationship}
                  handler={(value) =>
                    handleChange("relationship", index, value)
                  }
                />
              </div>
              {index !== 0 && (
                <div
                  className="row button"
                  onClick={() => removeDependent(index)}
                >
                  <div className="col-md-12">
                    <button
                      onClick={() => removeDependent(index)}
                      className="primary-orange-text add-new-btn"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </DropdownLayout>
      ))}

      <div className="row mt-3 button" onClick={addAnotherDependent}>
        <div className="col-md-12">
          <button
            onClick={addAnotherDependent}
            className="primary-orange-text add-new-btn"
          >
            + Add Another Dependent
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-between gap-1 mt-5">
        <div style={{ width: "50%" }}>
          <SubmitButton type="contained" handler={handleBackButton}>
            Back
          </SubmitButton>
        </div>
        <div style={{ width: "50%" }}>
          <SubmitButton handler={handleNext} isLoading={isLoading}>
            {isSubmitForm ? "Submit" : "Next"}
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default DependentInfoForm;
