import React, { useState } from "react";
import * as Component from "./index";
import * as Icon from "assets/icons";

const FilterRow = ({
  filter,
  setFilter,
  btnTitle,
  btnHandler,
  filterList,
  handleReset,
  showCalendarFilter,
  handleSubmit,
}) => {
  const [isDisableReset, setIsDisableReset] = useState(true);

  const handleFilterChange = (fildName, value) => {
    console.log("handleFilterChange", fildName, value);
    setFilter({ ...filter, [fildName]: value });
  };

  const handleSubmitFilter = () => {
    handleSubmit();
    setIsDisableReset(false);
  };

  const handleFilterReset = () => {
    setFilter({});
    handleReset();
    setIsDisableReset(true);
  };

  return (
    <div className="d-flex flex-column flex-lg-row  gap-3  ">
      <div className="w-100 position-relative d-flex align-items-center">
        <div className="flex-grow-1">
          <Component.InputField
            name="search"
            type="search"
            placeholder={"Search"}
            value={filter.search}
            handler={(e) => handleFilterChange("search", e.target.value)}
          />
        </div>
        <div className="search-icon-task">
          {!filter.search && <Icon.SearchIcon />}
        </div>
      </div>
      <div className="d-flex gap-2   flex-grow-1 ">
        {showCalendarFilter && (
          <Component.Calender filter={filter} setFilter={setFilter} onRangeSelected={handleSubmitFilter}/>
        )}
        <Component.Filter
          filter={filter}
          hanldleFilter={handleFilterChange}
          filterList={filterList}
          handleSubmit={handleSubmitFilter}
        />
        <button
          onClick={handleFilterReset}
          className="task-filter-dropdown-btn w-100"
          disabled={isDisableReset}
        >
          Reset
        </button>
      </div>
      {btnTitle && (
        <div className="col-12 col-lg-3 flex-grow-1 ">
          <Component.SearchButton handler={btnHandler}>
            <div className="d-flex gap-2 justify-content-center align-items-center">
              <Icon.PlusIcon />
              {btnTitle}
            </div>
          </Component.SearchButton>
        </div>
      )}
    </div>
  );
};

export default FilterRow;
