
import Settings from "./Settings";
function index({ isOpen, onClose }) {
  return (
    <div>
      <Settings isOpen={isOpen} onClose={onClose} />
    </div>
  );
}

export default index;
