import React, { useEffect, useState } from "react";
import { message } from "antd";
import AccountAddSuccessModal from "Modals/AccountAddSuccessModal/AccountAddSuccessModal";
import {  AccountDetails } from "./index";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import { Stepper } from "Components/GeneralComponents";
import * as Component from "Components/GeneralComponents";

import {
  fetchAccountProperty,
  fetchClientSecret,
} from "network/landlord/Account";
import { useNavigate } from "react-router-dom";
import { addAccountDetails } from "utils/stepsData";
// import UseGetHook from "Hooks/UseGetHook";
const AddAccount = () => {
  // States start
  const [currentStep, setCurrentStep] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [account_id, setAccount_id] = useState("");
  const [propertydata, setPropertydata] = useState([]);
  const [property, setProperty] = useState([]);
  const [connectStripe, setConnectStripe] = useState(null);
  const [isloading, setIsloading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  // States end
  // Modal Function
  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };
  // Stepper Function
  const handleNext = () => {
    setIsloading(true);
    if (property.length === 0) {
      setErrors({ property: "Property is required" });
    } else {
      if (currentStep === 1) {
        setCurrentStep(currentStep + 1);

        const callAPI = async () => {
          const res = await fetchClientSecret({ properties: property });
          console.log(res);
          if (res.apiCallStatus === "success") {
            setAccount_id(res.message.account_id);
            setIsloading(false);
            return res.message.client_secret;
          } else {
            setIsloading(false);
            message.error(res.message);
          }
        };
        const instance = loadConnectAndInitialize({
          publishableKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
          fetchClientSecret: callAPI,
        });
        setConnectStripe(instance);
      }
    }
  };
  const handlePrev = () => {
    if (currentStep === 1) {
      navigate("/all-accounts");
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  useEffect(() => {
    fetchAccountProperty({ id: localStorage.getItem("user_id") }).then(
      (res) => {
        if (res.apiCallStatus === "success") {
          setPropertydata(
            res.message?.map((e) => ({
              id: e.id,
              heading1: "",
              heading2: e.title,
              heading3: "",
            }))
          );
        }
      }
    );
  }, []);

  const handlePropertyChange = (e) => {
    console.log("Selected Property", e);
    setProperty(e);
  };

  return (
    <>
      {openModal && (
        <AccountAddSuccessModal
          successAccount="Connect to Bank"
          onClose={onCloseModal}
        />
      )}
      <div className="container bg-white  p-3">
      <Stepper
            steps={addAccountDetails}
            activeStep={currentStep - 1}
            lockStep={false}
            maxSteps={4} // Customize if desired
            setIsDraft={(isDraft) => console.log("Draft status:", isDraft)}
          />
        <div className="stepper-content-container mt-4">
          {currentStep <= 1 && (
            <>
              <Component.SelectField
                mode="multiple"
                name={"properties"}
                required={true}
                label={"Properties"}
                placeholder={"Select Properties"}
                value={property?.map((e) => e)}
                options={propertydata?.map((e) => {
                  return { value: e.id, label: e.heading2 };
                })}
                errorMessage={errors?.property}
                handler={(e) => handlePropertyChange(e)}
              />
              <div className="row">
                <div className="col-md-12">
                  <div className="stepper-btn d-flex justify-content-between mt-5 gap-1 pb-3">
                    <button onClick={handlePrev} className="back-prev-btn mt-3">
                      Back
                    </button>
                    <button
                      onClick={handleNext}
                      className="next-btn-same-class mt-3"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
          {currentStep === 2 && (
            <AccountDetails
              connectStripe={connectStripe}
              message={message}
              account_id={account_id}
              isloading={isloading}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AddAccount;
