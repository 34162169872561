import axios from "axios";
import config from "Helpers/config.js";

const getSignDocumentLink = async (data) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/signnow/document/get-signing-link`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const downloadDocument = async (documentId) => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/signnow/document/${documentId}/download`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        responseType: "blob",
      }
    );

    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: response.data.type })
    );
    const link = document.createElement("a");
    link.href = url;

    const contentDisposition = response.headers["content-disposition"];
    let filename = "signed_document.pdf";

    if (contentDisposition) {
      const matches = /filename="([^"]*)"/.exec(contentDisposition);
      console.log("matches ------> ", matches);
      if (matches != null && matches[1]) filename = matches[1];
    }

    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error("Failed to download document", error);
    return (
      error.response?.data || "Error occurred while downloading the document"
    );
  }
};

export { getSignDocumentLink, downloadDocument };
