import React from "react";
import parkingIcon from "assets/P.png";

const AmenitiesTab = ({ amenities }) => {
  return (
    <div className="row mb-5">
      {amenities.length > 0
        ? amenities?.map((amenity, index) => (
            <div key={index} className="col-md-3">
              <ul className="list-group">
                <li
                  key={index}
                  className="list-group-item icons-list-amenities"
                >
                  <img
                    src={parkingIcon}
                    alt=""
                    className="icons-list-image-unique"
                  />
                  {amenity}
                </li>
              </ul>
            </div>
          ))
        : "Not Set"}
    </div>
  );
};

export default AmenitiesTab;
