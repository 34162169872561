import AccountingMain from "Components/AccountingMain/AccountingMain";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import TitleTopBar from "Helpers/TitleTopBar";
import NoAccessPage from "Pages/NoAccessMainPage/NoAccessPage";
import React from "react";

const Accounting = () => {
  const storedRoles = localStorage.getItem("myUserRoles");
  const myUserRoles =
    storedRoles !== "undefined" ? JSON.parse(storedRoles) : [];
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar topBarTitle="Accounting " />
      {!["Owner/Executive", "Property Manager", "Accounting/Finance"].some(
        (role) => myUserRoles?.includes(role)
      ) && myUserRoles?.length !== 0 ? (
        <NoAccessPage />
      ) : (
        <AccountingMain />
      )}
    </div>
  );
};

export default Accounting;
