import React, { useEffect, useState } from "react";
import { ConfigProvider, Select, message } from "antd";
import { useParams } from "react-router-dom";
import chevronIcon from "assets/chevron-down.png";
import cancelIcon from "assets/x-circle.png";
import UseGetHook from "Hooks/UseGetHook";
import config from "Helpers/config.js";
import * as Component from "Components/GeneralComponents";
import { getAllLandlords } from "network/tenant/passport";
import "./styles.css";
const SharePassportModal = ({ onClose, id }) => {
  const [emails, setEmails] = useState([]);
  const [emailData, setEmailData] = useState([]);
  const { fetchLandlordEmail, landlordEmail } = UseGetHook("getLandlords");
  const { fetchTenantScreening, tenantScreen } = UseGetHook(
    "viewScreeningStatus"
  );
  const { account_id } = useParams();
  const [loading, setLoading] = useState(false);

  const fetchAllLandlords = async () => {
    try {
      const res = await getAllLandlords();
      console.log("Landlord Emails: ", res);
      if (res.apiCallStatus === "success") {
        setEmailData(
          res.message.map((e) => ({
            id: e.id,
            heading1: "",
            heading2: `${e.firstName} ${e.lastName}`,
            heading3: e.email,
            units: "",
          }))
        );
      }
    } catch (error) {
      message.error("Error fetching landlords:", error);
    }
  };
  // Fetch landlord emails and tenant screening data
  useEffect(() => {
    fetchAllLandlords();
    fetchTenantScreening();
  }, []);

  // Share Passport Functionality
  const shareReport = () => {
    const emailArray = emails
      .map((item) => item.heading3)
      .filter(Boolean);

    console.log("Filtered Emails:", emailArray);

    setLoading(true);
    fetch(`${config.baseUrl}/api/renter/transunion/shareReport`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        landlordEmail: emailArray,
        screeningId: id,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.apiCallStatus === "success") {
          setLoading(false);
          message.success("Passport shared successfully");
          onClose();
        } else {
          message.error("Something went wrong. Please try again later.");
          setLoading(false);
          onClose();
        }
      })
      .catch((err) => {
        message.error("Error sharing passport:", err);
        setLoading(false);
        onClose();
      });
  };

  return (
    <div className="">
      <div className=" position-relative">
        <div className="modal-content p-4 position-relative">
          {/* <h5 className="modal-title mb-4">Share Passport</h5> */}
          <div className="mb-3">
            {/* <Component.SelectField
              mode="tags"
              name={"landlordEmails"}
              required={true}
              label={"Landlord's Emails"}
              placeholder={"Select Emails"}
              value={email?.map((e) => e)}
              options={landlordEmail.map((email) => ({
                value: email,
                label: email,
              }))}
              // errorMessage={errors?.property}
              handler={(value) => {
                setEmail(value);
              }}
            /> */}

            {console.log("Email Data: ", emailData)}
            <Component.SelectLandlordField
              label={"Landlord's Emails"}
              mode="multi"
              placeholder={"Select Emails"}
              isRequired={true}
              PropertyData={emailData}
              category={"tenant"}
              //   selectedPropertyId={email}
              onPropertySelect={(selectedProperty) => {
               setEmails(selectedProperty)
              }}
            />

            {/* <Select
                mode="tags"
                placeholder="Select or enter landlord's email"
                suffixIcon={<img src={chevronIcon} alt="" />}
                style={{ width: "100%", height: 50, zIndex: 9999999 }}
                onChange={(value) => setEmail(value)}
                options={landlordEmail.map((email) => ({
                  value: email,
                  label: email,
                }))}
              /> */}
          </div>
          <div className="d-grid mt-4">
            <Component.SubmitButton handler={shareReport} isLoading={loading}>
              Share Passport
            </Component.SubmitButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharePassportModal;
