import React, { useState, useEffect } from "react";
import validateError from "assets/alert-circle-validate.png";
import { Tooltip } from "antd";
import helpIcon from "assets/help-circle.png";

const formatSSNWithMask = (ssn) => {
  const cleanSSN = ssn.replace(/\D/g, "");
  const maskedSSN = cleanSSN.replace(/\d/g, "*");
  const part1 = maskedSSN.slice(0, 3);
  const part2 = maskedSSN.slice(3, 5);
  const part3 = maskedSSN.slice(5, 9);

  if (maskedSSN.length > 5) return `${part1}-${part2}-${part3}`;
  if (maskedSSN.length > 3) return `${part1}-${part2}`;
  return part1;
};

function SSNField({
  type = "text",
  name = "SSN",
  required = true,
  label = "SSN",
  placeholder = "SSN",
  value,
  handler,
  errorMessage = false,
  TooltipTitle,
  disabled,
}) {
  const [displayValue, setDisplayValue] = useState(formatSSNWithMask(value));
  const [originalValue, setOriginalValue] = useState(value);

  useEffect(() => {
    setDisplayValue(formatSSNWithMask(value));
    setOriginalValue(value);
  }, [value]);

  const handleSSNChange = (e) => {
    const inputValue = e.target.value;
    const cleanSSN = inputValue.replace(/\D/g, "");

    if (cleanSSN.length === 0) {
      const updatedValue = originalValue.slice(0, -1);
      setOriginalValue(updatedValue);
      handler && handler("ssn", updatedValue);
    } else if (originalValue.length < 9) {
      const concatenatedValue = originalValue + cleanSSN;
      handler && handler("ssn", concatenatedValue);
    }
  };

  return (
    <div className="position-relative">
      {label && (
        <span className="tenant-personal-details-title">
          {label}{" "}
          <span className="sign-up-imp-star">{required ? "*" : ""}</span>
          {TooltipTitle && (
            <Tooltip title={TooltipTitle}>
              <img src={helpIcon} alt="" className="cursor ps-2" />
            </Tooltip>
          )}
        </span>
      )}
      <div style={{ position: "relative" }}>
        <input
          name={name}
          type={type}
          value={displayValue}
          onChange={handleSSNChange}
          className={errorMessage ? "error-input" : "form-control"}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete="off"
        />
      </div>
      {errorMessage && (
        <div className="text-danger fw-bold mt-2" style={{ fontSize: "12px" }}>
          {errorMessage}
        </div>
      )}
    </div>
  );
}

export default SSNField;
