import React from "react";

function RecordPayment({ width = 21, height = 21 }) {
  return (
    <>
      <svg
      width={width}
      height={height}
        id="Layer_1"
       
        viewBox="0 0 50 50"
        fill="currentColor"

        xmlns="http://www.w3.org/2000/svg"
      >
<g style={{ fill: "none", stroke: "#010101", strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10, strokeWidth: 1.5 }}>
<path d="m10.2 1.8h25.4c1.5 0 2.7 1.2 2.7 2.7v24.5" />
          <path d="m2 10v35.6c0 1.5 1.2 2.7 2.7 2.7h30.9c.3 0 .6-.1.9-.2" />
          <path d="m10.2 1.8-8.2 8.2h6c1.2 0 2.2-1 2.2-2.2z" />
          <path d="m7.3 38.6h6.1v3.8h-6.1z" />
          <path d="m13.4 38.6h6.1v3.8h-6.1z" />
          <path d="m29.5 42.4h-10v-3.8h9.2c0 1.4.3 2.6.8 3.8z" />
          <path d="m7.3 34.7h6.1v3.8h-6.1z" />
          <path d="m13.4 34.7h6.1v3.8h-6.1z" />
          <path d="m29.5 34.7c-.5 1.2-.8 2.5-.8 3.8h-9.2v-3.8z" />
          <path d="m7.3 30.9h6.1v3.8h-6.1z" />
          <path d="m13.4 30.9h6.1v3.8h-6.1z" />
          <path d="m32.6 30.9c-1.3 1-2.4 2.3-3 3.8h-10v-3.8z" />
          <path d="m7.3 27h6.1v3.8h-6.1z" />
          <path d="m13.4 27h6.1v3.8h-6.1z" />
          <path d="m33 27.1v3.5c-.2.1-.3.2-.5.3h-13v-3.8z" />
          <path d="m7.3 23.2h6.1v3.8h-6.1z" />
          <path d="m13.4 23.2h6.1v3.8h-6.1z" />
          <path d="m19.5 23.2h13.5v3.8h-13.5z" />
          <path d="m7.3 19.4h6.1v3.8h-6.1z" />
          <path d="m13.4 19.4h6.1v3.8h-6.1z" />
          <path d="m19.5 19.4h13.5v3.8h-13.5z" />
          <path d="m7.3 15.5h6.1v3.8h-6.1z" />
          <path d="m13.4 15.5h6.1v3.8h-6.1z" />
          <path d="m19.5 15.5h13.5v3.8h-13.5z" />
          <ellipse cx="38.4" cy="38.6" rx="9.6" ry="9.6" />
          <path d="m40.1 38.6h-3.4c-1.3 0-2.4-1.1-2.4-2.4 0-1.3 1.1-2.4 2.4-2.4h3.4c1.3 0 2.4 1.1 2.4 2.4" />
          <path d="m36.7 38.6h3.4c1.3 0 2.4 1.1 2.4 2.4 0 1.3-1.1 2.4-2.4 2.4h-3.4c-1.3 0-2.4-1.1-2.4-2.4" />
          <path d="m38.4 33.8v-1.3" />
          <path d="m38.4 44.7v-1.3" />
          <path d="m44.9 38.2v.9" />
          <path d="m31.9 38.2v.9" />
        </g>
      </svg>
    </>
  );
}

export default RecordPayment;
