import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import ServiceProfessionalTasks from "Components/ServiceProfessionalTask/ServiceProfessionalTask";
import React from "react";

function index() {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar topBarTitle="All Tasks" />
      <ServiceProfessionalTasks/>
    </div>
  );
}

export default index;
