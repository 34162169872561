import React, { useState, useEffect } from "react";
import * as Component from "Components/GeneralComponents";
import * as Icon from "assets/icons";
import activeDot from "assets/_Dot.png";
import inactiveDot from "assets/inactivedot.png";
import { downloadDocument } from "network/tenant/signnow";

const DocumentsTab = ({ data, isLoading }) => {
  const [key, setKey] = useState(null);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (data.signnow_documents?.length > 0) {
      setTableData(
        data.signnow_documents?.map((item) => ({
          key: item.document_id,
          documentName: item.name,
          status: item.status,
          updatedAt: item.updatedAt,
          propertyName: data.property_title,
        }))
      );
    }
  }, [data]);

  const columns = [
    {
      title: "Document Name",
      dataIndex: "documentName",
      render: (text) => (
        <>
          <div className="all-lease-property-text ">{text}</div>
        </>
      ),
    },
    {
      title: "Property Name",
      dataIndex: "propertyName",
      render: (text) => (
        <>
          <div className="all-lease-property-text ">{text}</div>
        </>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      render: (text) => (
        <>
          <div className="assign-date-container">
            <span className="assign-to-date">{text}</span>
          </div>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, status) => (
        <>
          <div
            className={
              text === "signed"
                ? "prospect-active-bar"
                : "prospect-inactive-bar"
            }
          >
            <img src={text === "signed" ? activeDot : inactiveDot} alt="" />{" "}
            <span>{text}</span>
          </div>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative  cursor text-center "
            onClick={() => handleIconClick(setting.key)}
          >
            <Icon.VerticalElipsisIcon width={23} height={23} />
            <Component.RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleDownload: () => {
                  downloadDocument(setting.key);
                },
              }}
              fields={{
                download: true,
              }}
            />
          </div>
        </>
      ),
    },
  ];

  const handleIconClick = (result) => {
    const filterData = data.signnow_documents.filter((item) => {
      return item.document_id === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  return (
    <>
      <div className="table-container mt-5 ">
        <Component.CustomTable
          loading={isLoading}
          pagination={true}
          columns={columns}
          dataSource={tableData}
          rowSelection={false}
        />
      </div>
    </>
  );
};

export default DocumentsTab;
