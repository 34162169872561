import axios from 'axios';
import config from "Helpers/config.js"

const getTenantConversations = async (id, search) => {
  try {
    const response = await axios.get(
      `${config["baseUrl"]}/api/tenant/conversations/${id}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { search: search },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const addOrRemoveTenantArchive = async (id) => {
  try {
    const response = await axios.put(
      `${config["baseUrl"]}/api/tenant/conversation/archive/${id}`,
      {},
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getTenantArchive = async () => {
  try {
    const response = await axios.get(
      `${config["baseUrl"]}/api/tenant/archived/conversations/1`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};


const getTenantMessages = async (id) => {
    try {
      const response = await axios.get(
        `${config["baseUrl"]}/api/tenant/messages/${id}/1`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };

export { getTenantConversations, getTenantArchive, getTenantMessages, addOrRemoveTenantArchive };
